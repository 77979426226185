import { Customer } from '../../models';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Messaging, getToken, onMessage } from '@angular/fire/messaging';
import { Database } from '@angular/fire/database';
import { Storage } from '@angular/fire/storage';
import { Firestore } from '@angular/fire/firestore';


@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    constructor(
        private http: HttpClient,
        @Optional() public messaging: Messaging,
        @Optional() public database: Database,
        @Optional() public storage: Storage,
        @Optional() public firestore: Firestore,
    ) {

    }



    notificationsAreSupported(): boolean {

        if (window?.Notification)
            return true;

        return false;

    }



    async requestPermission(): Promise<boolean> {

        try {

            if (!this.notificationsAreSupported())
                return Promise.reject({ message: 'This browser does not support notifications' });


            const result = await window.Notification.requestPermission();

            console.log(`Notifications permission: ${result}`);

            const permissionGranted = result === 'granted' ? true : false;

            return Promise.resolve(permissionGranted);


        } catch (error) {
            return Promise.reject(error);
        }

    }



    async updateFcmToken(customer?: Customer): Promise<void> {

        try {

            if (!this.notificationsAreSupported())
                return Promise.resolve();

            const notificationsPermissionResult = await this.requestPermission();

            const fcmToken = await getToken(this.messaging);

            // tslint:disable-next-line:curly
            if (fcmToken && customer?.customer_id) {
                const updateFcmTokenResponse = await this.http.post(`${environment.params.host}/api/stay/update-fcm-token`, {
                    fcm_token: fcmToken,
                    device_hash: customer?.fingerprint?.hash || null
                }).toPromise();

            }

            return Promise.resolve();

        } catch (error) {

            if (!environment.production)
                console.log(error);

            return Promise.resolve();

        }

    }

}

