import { Account } from './Account';

export class User {
    id?: number;
    user_id?: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    phone: string;
    language?: 'el' | 'en';
    country?: string; // e.g. 'GR'
    verified?: boolean;
    verification_token?: string;
    verification_token_numeric?: string; // 6-digit code for verifying ownership of email address
    password_reset_token?: string;
    signup_provider?: 'tourmie' | 'google' | 'facebook' | 'linkedin';

    // Extras
    account?: Account;

    constructor(props?: User) {

        this.user_id = props?.user_id || null;
        this.first_name = props?.first_name || null;
        this.last_name = props?.last_name || null;
        this.email = props?.email || null;
        this.password = props?.password || null;
        this.phone = props?.phone || null;
        this.language = props?.language || 'en';
        this.country = props?.country || null;
        this.verified = props?.verified || false;
        this.verification_token = props?.verification_token || null;
        this.verification_token_numeric = props?.verification_token_numeric || null;
        this.password_reset_token = props?.password_reset_token || null;
        this.signup_provider = props?.signup_provider || 'tourmie';
        this.account = props?.account || null;

    }

}
