export const property_categories = [
    {
        label: 'Entire place',
        label_el: 'Ολόκληρος χώρος',
        label_es: 'Casa/apto. entero',
        label_it: 'Intero alloggio',
        label_fr: 'Logement entier',
        label_de: 'Ganze Unterkunft',
        label_ca: 'Allotjament sencer',
        value: 'entire_place'
    },
    {
        label: 'Private room',
        label_el: 'Ιδιωτικό δωμάτιο',
        label_es: 'Habitación privada',
        label_it: 'Camera privata',
        label_fr: 'Chambre privée',
        label_de: 'Privatzimmer',
        label_ca: 'Habitació privada',
        value: 'private_room'
    },
    {
        label: 'Shared room',
        label_el: 'Κοινόχρηστο δωμάτιο',
        label_es: 'Habitación compartida',
        label_it: 'Camera condivisa',
        label_fr: 'Chambre partagée',
        label_de: 'Gemeinsames Zimmer',
        label_ca: 'Habitació compartida',
        value: 'shared_room'
    }
];


export const property_subcategories = [
    { label: 'House', label_el: 'Σπίτι', label_es: 'Casa', label_it: 'Casa', label_fr: 'Maison', label_de: 'Haus', label_ca: 'Casa', value: 'house', selected: false, tempSelected: false, },
    { label: 'Holiday Home', label_el: 'Εξοχικό σπίτι', label_es: 'Casa de vacaciones', label_it: 'Casa vacanze', label_fr: 'Maison de vacances', label_de: 'Ferienhaus', label_ca: 'Casa de vacances', value: 'holiday_home', selected: false, tempSelected: false, },
    { label: 'Mobile Home', label_el: 'Κινητό σπίτι', label_es: 'Casa móvil', label_it: 'Casa mobile', label_fr: 'Mobil-home', label_de: 'Wohnmobil', label_ca: 'Casa mòbil', value: 'mobile_home', selected: false, tempSelected: false, },
    { label: 'Apartment', label_el: 'Διαμέρισμα', label_es: 'Apartamento', label_it: 'Appartamento', label_fr: 'Appartement', label_de: 'Wohnung', label_ca: 'Apartament', value: 'apartment', selected: false, tempSelected: false, },
    { label: 'Deluxe Apartment', label_el: 'Πολυτελές διαμέρισμα', label_es: 'Apartamento de lujo', label_it: 'Appartamento di lusso', label_fr: 'Appartement de luxe', label_de: 'Luxuswohnung', label_ca: 'Apartament de luxe', value: 'deluxe_apartment', selected: false, tempSelected: false, },
    { label: 'Duplex Apartment', label_el: 'Διαμέρισμα δύο ορόφων', label_es: 'Apartamento dúplex', label_it: 'Appartamento duplex', label_fr: 'Appartement en duplex', label_de: 'Maisonette-Wohnung', label_ca: 'Apartament dúplex', value: 'duplex_apartment', selected: false, tempSelected: false, },
    { label: 'Loft', label_el: 'Πατάρι', label_es: 'Ático', label_it: 'Soppalco', label_fr: 'Loft', label_de: 'Loft', label_ca: 'Àtic', value: 'loft', selected: false, tempSelected: false, },
    { label: 'Villa', label_el: 'Βίλα', label_es: 'Villa', label_it: 'Villa', label_fr: 'Villa', label_de: 'Villa', label_ca: 'Vila', value: 'villa', selected: false, tempSelected: false, },
    { label: 'Villa - Beach front', label_el: 'Βίλα μπροστά στην παραλία', label_es: 'Villa en primera línea de playa', label_it: 'Villa fronte mare', label_fr: 'Villa en bord de mer', label_de: 'Strandvilla', label_ca: 'Vila a primera línia de platja', value: 'villa_beach_front', selected: false, tempSelected: false, },
    { label: 'Villa with Sea View', label_el: 'Βίλα με θέα στη θάλασσα', label_es: 'Villa con vistas al mar', label_it: 'Villa con vista mare', label_fr: 'Villa avec vue sur la mer', label_de: 'Villa mit Meerblick', label_ca: 'Vila amb vista al mar', value: 'villa_sea_view', selected: false, tempSelected: false, },
    { label: 'Deluxe Villa', label_el: 'Πολυτελής βίλα', label_es: 'Villa de lujo', label_it: 'Villa di lusso', label_fr: 'Villa de luxe', label_de: 'Luxusvilla', label_ca: 'Vila de luxe', value: 'deluxe_villa', selected: false, tempSelected: false, },
    { label: 'Superior Villa', label_el: 'Ανώτερη βίλα', label_es: 'Villa superior', label_it: 'Villa superiore', label_fr: 'Villa supérieure', label_de: 'Superior Villa', label_ca: 'Vila superior', value: 'superior_villa', selected: false, tempSelected: false, },
    { label: 'Room', label_el: 'Δωμάτιο', label_es: 'Habitación', label_it: 'Camera', label_fr: 'Chambre', label_de: 'Zimmer', label_ca: 'Habitació', value: 'room', selected: false, tempSelected: false, },
    { label: 'Studio', label_el: 'Στούντιο', label_es: 'Estudio', label_it: 'Monolocale', label_fr: 'Studio', label_de: 'Studio', label_ca: 'Estudi', value: 'studio', selected: false, tempSelected: false, },
    { label: 'Penthouse', label_el: 'Ρετιρέ', label_es: 'Ático', label_it: 'Attico', label_fr: 'Penthouse', label_de: 'Penthouse', label_ca: 'Àtic', value: 'penthouse', selected: false, tempSelected: false, },
    { label: 'Chalet', label_el: 'Χαλέ', label_es: 'Chalet', label_it: 'Chalet', label_fr: 'Chalet', label_de: 'Chalet', label_ca: 'Xalet', value: 'chalet', selected: false, tempSelected: false, },
    { label: 'Tent', label_el: 'Σκηνή', label_es: 'Tienda de campaña', label_it: 'Tenda', label_fr: 'Tente', label_de: 'Zelt', label_ca: 'Tenda de campanya', value: 'tent', selected: false, tempSelected: false, },
    { label: 'Maisonette', label_el: 'Μεζονέτα', label_es: 'Maisonette', label_it: 'Maisonette', label_fr: 'Maisonnette', label_de: 'Maisonette', label_ca: 'Maisonette', value: 'maisonette', selected: false, tempSelected: false, },
    { label: 'Bastide', label_el: 'Μεγάλη αγροικία', label_es: 'Bastide', label_it: 'Bastide', label_fr: 'Bastide', label_de: 'Bastide', label_ca: 'Bastide', value: 'bastide', selected: false, tempSelected: false, },
    { label: 'Boat', label_el: 'Σκάφος', label_es: 'Barco', label_it: 'Barca', label_fr: 'Bateau', label_de: 'Boot', label_ca: 'Vaixell', value: 'boat', selected: false, tempSelected: false, },
    { label: 'Bungalow', label_el: 'Μπανγκαλόου', label_es: 'Bungalow', label_it: 'Bungalow', label_fr: 'Bungalow', label_de: 'Bungalow', label_ca: 'Bungalou', value: 'bungalow', selected: false, tempSelected: false, },
    { label: 'Cabin', label_el: 'Καλύβα', label_es: 'Cabaña', label_it: 'Cabina', label_fr: 'Cabane', label_de: 'Hütte', label_ca: 'Cabana', value: 'cabin', selected: false, tempSelected: false, },
    { label: 'Castle', label_el: 'Κάστρο', label_es: 'Castillo', label_it: 'Castello', label_fr: 'Château', label_de: 'Schloss', label_ca: 'Castell', value: 'castle', selected: false, tempSelected: false, },
    { label: 'Cave', label_el: 'Σπήλαιο', label_es: 'Cueva', label_it: 'Grotta', label_fr: 'Grotte', label_de: 'Höhle', label_ca: 'Cova', value: 'cave', selected: false, tempSelected: false, },
    { label: 'Condo', label_el: 'Κοινόκτητο', label_es: 'Condominio', label_it: 'Condominio', label_fr: 'Condo', label_de: 'Eigentumswohnung', label_ca: 'Condomini', value: 'condominium', selected: false, tempSelected: false, },
    { label: 'Cottage', label_el: 'Εξοχικό σπίτι', label_es: 'Casa de campo', label_it: 'Cottage', label_fr: 'Chalet', label_de: 'Ferienhaus', label_ca: 'Casa de camp', value: 'cottage', selected: false, tempSelected: false, },
    { label: 'Dormitory', label_el: 'Κοιτώνας', label_es: 'Dormitorio', label_it: 'Dormitorio', label_fr: 'Dortoir', label_de: 'Schlafsaal', label_ca: 'Dormitori', value: 'dormitory', selected: false, tempSelected: false, },
    { label: 'Earth House', label_el: 'Οικία από γη', label_es: 'Casa de tierra', label_it: 'Casa di terra', label_fr: 'Maison de terre', label_de: 'Lehmhaus', label_ca: 'Casa de terra', value: 'earth_house', selected: false, tempSelected: false, },
    { label: 'Farmhouse', label_el: 'Αγροικία', label_es: 'Casa de campo', label_it: 'Cascina', label_fr: 'Ferme', label_de: 'Bauernhaus', label_ca: 'Masia', value: 'farmhouse', selected: false, tempSelected: false, },
    { label: 'Hut', label_el: 'Καλύβα', label_es: 'Cabaña', label_it: 'Capanna', label_fr: 'Cabane', label_de: 'Hütte', label_ca: 'Cabana', value: 'hut', selected: false, tempSelected: false, },
    { label: 'Igloo', label_el: 'Ιγκλού', label_es: 'Iglú', label_it: 'Igloo', label_fr: 'Igloo', label_de: 'Iglu', label_ca: 'Iglú', value: 'igloo', selected: false, tempSelected: false, },
    { label: 'Island', label_el: 'Νησί', label_es: 'Isla', label_it: 'Isola', label_fr: 'Île', label_de: 'Insel', label_ca: 'Illa', value: 'island', selected: false, tempSelected: false, },
    { label: 'Lighthouse', label_el: 'Φάρος', label_es: 'Faro', label_it: 'Faro', label_fr: 'Phare', label_de: 'Leuchtturm', label_ca: 'Far', value: 'lighthouse', selected: false, tempSelected: false, },
    { label: 'Manor', label_el: 'Έπαυλη', label_es: 'Mansión', label_it: 'Maniero', label_fr: 'Manoir', label_de: 'Herrenhaus', label_ca: 'Mansió', value: 'manor', selected: false, tempSelected: false, },
    { label: 'Mill', label_el: 'Μύλος', label_es: 'Molino', label_it: 'Mulino', label_fr: 'Moulin', label_de: 'Mühle', label_ca: 'Molí', value: 'mill', selected: false, tempSelected: false, },
    { label: 'Tipi', label_el: 'Τίπι', label_es: 'Tipi', label_it: 'Tipi', label_fr: 'Tipi', label_de: 'Tipi', label_ca: 'Tipi', value: 'tipi', selected: false, tempSelected: false, },
    { label: 'Plane', label_el: 'Αεροπλάνο', label_es: 'Avión', label_it: 'Aereo', label_fr: 'Avion', label_de: 'Flugzeug', label_ca: 'Avió', value: 'plane', selected: false, tempSelected: false, },
    { label: 'Train', label_el: 'Τρένο', label_es: 'Tren', label_it: 'Treno', label_fr: 'Train', label_de: 'Zug', label_ca: 'Tren', value: 'train', selected: false, tempSelected: false, },
    { label: 'Yurt', label_el: 'Γιούρτα', label_es: 'Yurta', label_it: 'Yurta', label_fr: 'Yourte', label_de: 'Jurte', label_ca: 'Iurta', value: 'yurt', selected: false, tempSelected: false, },
    { label: 'Treehouse', label_el: 'Σπιτάκι στο δέντρο', label_es: 'Casa del árbol', label_it: 'Casa sull\'albero', label_fr: 'Cabane dans les arbres', label_de: 'Baumhaus', label_ca: 'Casa a l\'arbre', value: 'treehouse', selected: false, tempSelected: false, },
    { label: 'Recreational vehicle', label_el: 'Οχημα αναψυχής', label_es: 'Vehículo recreativo', label_it: 'Veicolo ricreativo', label_fr: 'Véhicule récréatif', label_de: 'Freizeitfahrzeug', label_ca: 'Vehicle recreatiu', value: 'recreational_vehicle', selected: false, tempSelected: false, },
    { label: 'Barn', label_el: 'Στάβλος', label_es: 'Granero', label_it: 'Fienile', label_fr: 'Grange', label_de: 'Scheune', label_ca: 'Graner', value: 'barn', selected: false, tempSelected: false, },
    { label: 'Beach House', label_el: 'Σπίτι στην παραλία', label_es: 'Casa de playa', label_it: 'Casa sulla spiaggia', label_fr: 'Maison de plage', label_de: 'Strandhaus', label_ca: 'Casa de platja', value: 'beach_house', selected: false, tempSelected: false, },
    { label: 'Yacht', label_el: 'Γιοτ', label_es: 'Yate', label_it: 'Yacht', label_fr: 'Yacht', label_de: 'Yacht', label_ca: 'Iot', value: 'yacht', selected: false, tempSelected: false, },
    { label: 'Suite', label_el: 'Σουίτα', label_es: 'Suite', label_it: 'Suite', label_fr: 'Suite', label_de: 'Suite', label_ca: 'Suite', value: 'suite', selected: false, tempSelected: false, },
];



export const property_general_info_items = [
    { label: 'Area', label_el: 'Εβδαδόν', value: 'area_size' },
    { label: 'Beds', label_el: 'Κρεβάτια', value: 'bedrooms' },
    { label: 'Persons', label_el: 'Άτομα', value: 'guests' },
    { label: 'Baths', label_el: 'Μπάνια', value: 'bathrooms' }
];



export const property_amenities_categories = [
    { label: 'Bathroom', label_el: 'Μπάνιο', value: 'bathroom', order: 20 },
    { label: 'Laundry', label_el: 'Χώρος πλυντηρίου', value: 'laundry', order: 40 },
    { label: 'Bedroom', label_el: 'Υπνοδωμάτιο', value: 'bedroom', order: 10 },
    { label: 'Outdoor', label_el: 'Εξωτερικός χώρος', value: 'outdoor', order: 60 },
    { label: 'Indoor', label_el: 'Εσωτερικός χώρος', value: 'interior', order: 50 },
    { label: 'Family', label_el: 'Οικογένεια', value: 'family', order: 90 },
    { label: 'Cooling', label_el: 'Ψύξη', value: 'cooling', order: 70 },
    { label: 'Heating', label_el: 'Θέρμανση', value: 'heating', order: 80 },
    { label: 'Safety', label_el: 'Ασφάλεια', value: 'security', order: 110 },
    { label: 'Work', label_el: 'Εργασία', value: 'work', order: 100 },
    { label: 'Kitchen', label_el: 'Κουζίνα', value: 'kitchen', order: 30 },
    { label: 'Building', label_el: 'Κτίριο', value: 'building', order: 120 },
    { label: 'Entertainment', label_el: 'Ψυχαγωγία', value: 'entertainment', order: 130 },
    { label: 'Parking', label_el: 'Στάθμευση', value: 'parking', order: 140 },
];



export const property_amenities = [
    { label: 'Bath essentials', label_el: 'Είδη μπάνιου', value: 'bath_essentials', type: 'bathroom', icon: 'assets/img/amenities/shampoo.png', order: 100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Hairdryer', label_el: 'Πιστολάκι μαλλιών', value: 'hairdryer', type: 'bathroom', icon: 'assets/img/amenities/hairdryer.png', order: 300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Hot water', label_el: 'Ζεστό νερό', value: 'hot_water', type: 'bathroom', icon: 'assets/img/amenities/hot_water.png', order: 400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Towels', label_el: 'Πετσέτσες', value: 'towels', type: 'bathroom', icon: 'assets/img/amenities/towels.png', order: 600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Βathtub', label_el: 'Μπανιέρα', value: 'bathtub', type: 'bathroom', icon: 'assets/img/amenities/bathtub.png', order: 200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Shower', label_el: 'Ντουζ', value: 'shower', type: 'bathroom', icon: 'assets/img/amenities/shower.png', order: 500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Bed blankets', label_el: 'Κουβέρτες', value: 'bed_blankets', type: 'bedroom', icon: 'assets/img/amenities/bed_blankets.png', order: 700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Bed linen', label_el: 'Κλινοσκεπάσματα', value: 'bed_linen', type: 'bedroom', icon: 'assets/img/amenities/bed_linen_and_blankets.png', order: 800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Boudoir', label_el: 'Μπουντουάρ', value: 'boudoir', type: 'bedroom', icon: 'assets/img/amenities/boudoir.png', order: 900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Hangers', label_el: 'Κρεμάστρες', value: 'hangers', type: 'bedroom', icon: 'assets/img/amenities/hangers.png', order: 1000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Shoe rack', label_el: 'Ράφι παπουτσιών', value: 'shoe_rack', type: 'bedroom', icon: 'assets/img/amenities/shoe_rack.png', order: 1100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Wardrobe', label_el: 'Ντουλάπα', value: 'wardrobe', type: 'bedroom', icon: 'assets/img/amenities/wardrobe.png', order: 1200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Elevator', label_el: 'Ασανσέρ', value: 'elevator', type: 'building', icon: 'assets/img/amenities/elevator.png', order: 1300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Gated property', label_el: 'Περιφραγμένη ιδιοκτησία', value: 'gated_property', type: 'building', icon: 'assets/img/amenities/gated_property.png', order: 1500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Gym', label_el: 'Γυμναστήριο', value: 'gym', type: 'building', icon: 'assets/img/amenities/gym.png', order: 1600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Private entrance', label_el: 'Ιδιωτική είσοδος', value: 'private_entrance', type: 'building', icon: 'assets/img/amenities/private_entrance.png', order: 1700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Shared gym', label_el: 'Κοινόχρηστο γυμναστήριο', value: 'shared_gym', type: 'building', icon: 'assets/img/amenities/gym.png', order: 1800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Stairs', label_el: 'Σκάλες', value: 'stairs', type: 'building', icon: 'assets/img/amenities/stairs.png', order: 1900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Garage', label_el: 'Γκαράζ', value: 'garage', type: 'building', icon: 'assets/img/amenities/garage.png', order: 1400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Storage', label_el: 'Αποθήκη', value: 'storage', type: 'building', icon: 'assets/img/amenities/storage.png', order: 2000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Ceiling fan', label_el: 'Ανεμιστήρας οροφής', value: 'ceiling_fan', type: 'cooling', icon: 'assets/img/amenities/celling_fan.png', order: 2200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Air condition', label_el: 'Κλιματιστικό', value: 'air_condition', type: 'cooling', icon: 'assets/img/amenities/air_condition.png', order: 2100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Bluetooth speaker', label_el: 'Ηχείο bluetooth', value: 'bluetooth_speaker', type: 'entertainment', icon: 'assets/img/amenities/bluetooth_speaker.png', order: 2300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Board games', label_el: 'Επιτραπέζια παιχνίδια', value: 'board_games', type: 'entertainment', icon: 'assets/img/amenities/board_games.png', order: 2400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Books', label_el: 'Βιβλία', value: 'books', type: 'entertainment', icon: 'assets/img/amenities/books.png', order: 2500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Cable TV', label_el: 'Καλωδιακή τηλεόραση', value: 'cable_tv', type: 'entertainment', icon: 'assets/img/amenities/cable_tv.png', order: 2600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Games', label_el: 'Παιχνίδια', value: 'games', type: 'entertainment', icon: 'assets/img/amenities/games.png', order: 2700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Playroom', label_el: 'Δωμάτιο για παιχνίδι', value: 'playroom', type: 'entertainment', icon: 'assets/img/amenities/playroom.png', order: 2800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Radio', label_el: 'Ραδιόφωνο', value: 'radio', type: 'entertainment', icon: 'assets/img/amenities/radio.png', order: 2900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Smart TV', label_el: 'Smart TV', value: 'smart_tv', type: 'entertainment', icon: 'assets/img/amenities/smart_tv.png', order: 3000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'TV', label_el: 'Τηλεόραση', value: 'tv', type: 'entertainment', icon: 'assets/img/amenities/tv.png', order: 3100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Park / travel cot', label_el: 'Πάρκο / κούνια ταξιδιού', value: 'children_park_travel_cot', type: 'family', icon: 'assets/img/amenities/children_park_travel_cot.png', order: 3400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Kids books', label_el: 'Παιδικά βιβλία', value: 'childrens_books', type: 'family', icon: 'assets/img/amenities/childrens_books.png', order: 3500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Kids cutlery', label_el: 'Παιδικά μαχαιροπίρουνα', value: 'cutlery_kids', type: 'family', icon: 'assets/img/amenities/cutlery_kids.png', order: 3700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Kids pool', label_el: 'Παιδική πισίνα', value: 'kids_pool', type: 'family', icon: 'assets/img/amenities/swimming_pool.png', order: 3800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Kids tableware', label_el: 'Παιδικά σερβίτσια', value: 'kids_tableware', type: 'family', icon: 'assets/img/amenities/kids_tableware.png', order: 3900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Wireless intercom', label_el: 'Ασύρματη ενδοεπικοινωνία', value: 'kids_wireless_intercom', type: 'family', icon: 'assets/img/amenities/kids_wireless_intercom.png', order: 4000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Toys', label_el: 'Παιχνίδια', value: 'toys', type: 'family', icon: 'assets/img/amenities/toys_and_tabletop_games.png', order: 4100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Baby bathtub', label_el: 'Βρεφικό μπάνιο', value: 'baby_bathtub', type: 'family', icon: 'assets/img/amenities/baby_bathtub.png', order: 3200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Baby high chair', label_el: 'Καρεκλάκι μωρού', value: 'baby_chair', type: 'family', icon: 'assets/img/amenities/baby_chair.png', order: 3300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Crib', label_el: 'Κούνια', value: 'crib', type: 'family', icon: 'assets/img/amenities/crib.png', order: 3600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Fireplace', label_el: 'Τζάκι', value: 'fireplace', type: 'heating', icon: 'assets/img/amenities/fireplace.png', order: 4200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Heater', label_el: 'Θερμάστρα', value: 'heater', type: 'heating', icon: 'assets/img/amenities/heater.png', order: 4300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Radiator', label_el: 'Καλοριφέρ', value: 'radiator', type: 'heating', icon: 'assets/img/amenities/radiator.png', order: 4400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Coat rack', label_el: 'Καλόγερος', value: 'coat_rack', type: 'interior', icon: 'assets/img/amenities/coat_rack.png', order: 4600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Elevator', label_el: 'Ασανσέρ', value: 'interior_elevator', type: 'interior', icon: 'assets/img/amenities/elevator.png', order: 4700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Gym', label_el: 'Γυμναστήριο', value: 'interior_gym', type: 'interior', icon: 'assets/img/amenities/gym.png', order: 4800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Stairs', label_el: 'Σκάλες', value: 'interior_stairs', type: 'interior', icon: 'assets/img/amenities/stairs.png', order: 4900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Swimming pool', label_el: 'Πισίνα', value: 'interior_swimming_pool', type: 'interior', icon: 'assets/img/amenities/swimming_pool.png', order: 5000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Jacuzzi', label_el: 'Τζακούζι', value: 'jacuzzi', type: 'interior', icon: 'assets/img/amenities/jacuzzi.png', order: 5100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Winter carpets', label_el: 'Χειμερινά χαλιά', value: 'winter_carpets', type: 'interior', icon: 'assets/img/amenities/winter_carpets.png', order: 5400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Bookshelf', label_el: 'Ράφι βιβλίων', value: 'bookshelf', type: 'interior', icon: 'assets/img/amenities/bookshelf.png', order: 4500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Sofa', label_el: 'Καναπές', value: 'sofa', type: 'interior', icon: 'assets/img/amenities/sofa.png', order: 5200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Sofa bed', label_el: 'Καναπές-κρεβάτι', value: 'sofa_bed', type: 'interior', icon: 'assets/img/amenities/sofa_bed.png', order: 5300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Blender', label_el: 'Μίξερ', value: 'blender', type: 'kitchen', icon: 'assets/img/amenities/blender.png', order: 5500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Crockpot', label_el: 'Ηλεκτρική γάστρα', value: 'crockpot', type: 'kitchen', icon: 'assets/img/amenities/crockpot.png', order: 5700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Cutlery', label_el: 'Μαχαιροπίρουνα', value: 'cutlery_silver', type: 'kitchen', icon: 'assets/img/amenities/cutlery_silver.png', order: 5800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Dining table', label_el: 'Τραπεζαρία', value: 'dining_table', type: 'kitchen', icon: 'assets/img/amenities/dining_table.png', order: 5900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Rice cooker', label_el: 'Παρασκευαστής ρυζιού', value: 'rice_cooker', type: 'kitchen', icon: 'assets/img/amenities/rice_cooker.png', order: 6400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Toaster', label_el: 'Τοστιέρα', value: 'toaster', type: 'kitchen', icon: 'assets/img/amenities/toaster.png', order: 6600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Kettle', label_el: 'Βραστήρας νερού', value: 'water_boiler', type: 'kitchen', icon: 'assets/img/amenities/water_boiler.png', order: 6700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Coffee machine', label_el: 'Καφετιέρα', value: 'coffee_machine', type: 'kitchen', icon: 'assets/img/amenities/coffee_machine.png', order: 5600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Dishwasher', label_el: 'Πλυντήριο πιάτων', value: 'dish_washer', type: 'kitchen', icon: 'assets/img/amenities/dish_washer.png', order: 6000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Fridge', label_el: 'Ψυγείο', value: 'fridge', type: 'kitchen', icon: 'assets/img/amenities/fridge.png', order: 6100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Microwave', label_el: 'Φούρνος μικροκυμάτων', value: 'microwave', type: 'kitchen', icon: 'assets/img/amenities/microwave.png', order: 6200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Oven', label_el: 'Φούρνος', value: 'oven', type: 'kitchen', icon: 'assets/img/amenities/oven.png', order: 6300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Stove', label_el: 'Κουζίνα', value: 'stove', type: 'kitchen', icon: 'assets/img/amenities/stove.png', order: 6500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Clothes drying rack', label_el: 'Απλώστρα', value: 'clothes_drying_rack', type: 'laundry', icon: 'assets/img/amenities/clothes_drying_rack.png', order: 6800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Iron', label_el: 'Σίδερο', value: 'iron', type: 'laundry', icon: 'assets/img/amenities/iron.png', order: 7000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Ironing board', label_el: 'Σιδερώστρα', value: 'ironing_board', type: 'laundry', icon: 'assets/img/amenities/ironing_board.png', order: 7100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Laundry essentials', label_el: 'Είδη πλυντηρίου', value: 'laundry_essentials', type: 'laundry', icon: 'assets/img/amenities/essentials.png', order: 7200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Dryer', label_el: 'Στεγνωτήριο', value: 'dryer', type: 'laundry', icon: 'assets/img/amenities/dryer.png', order: 6900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Washing machine', label_el: 'Πλυντήριο', value: 'washing_machine', type: 'laundry', icon: 'assets/img/amenities/washing_machine.png', order: 7300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'BBQ', label_el: 'Μπάρμεκιου', value: 'bbq', type: 'outdoor', icon: 'assets/img/amenities/bbq.png', order: 7500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Jacuzzi', label_el: 'Τζακούζι', value: 'outdoor_jacuzzi', type: 'outdoor', icon: 'assets/img/amenities/jacuzzi.png', order: 7700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Living area', label_el: 'Χώρος καθιστικού', value: 'living_area', type: 'outdoor', icon: 'assets/img/amenities/living_area.png', order: 7800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Open-air shower', label_el: 'Εξωτερικό ντουζ', value: 'outdoor_shower', type: 'outdoor', icon: 'assets/img/amenities/outdoor_shower.png', order: 7900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Dining area', label_el: 'Χώρος τραπεζαρίας', value: 'outdoor_dining_area', type: 'outdoor', icon: 'assets/img/amenities/outdoor_dining_area.png', order: 8000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Private yard', label_el: 'Ιδιωτική αυλή', value: 'private_yard', type: 'outdoor', icon: 'assets/img/amenities/yard.png', order: 8100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Shared garden', label_el: 'Κοινόχρηστος κήπος', value: 'shared_garden', type: 'outdoor', icon: 'assets/img/amenities/garden.png', order: 8200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Shared yard', label_el: 'Κοινόχρηστη αυλή', value: 'shared_yard', type: 'outdoor', icon: 'assets/img/amenities/yard.png', order: 8300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Sun loungers & umbrellas', label_el: 'Ξαπλώστρες & ομπρέλες', value: 'sun_loungers_and_umbrellas', type: 'outdoor', icon: 'assets/img/amenities/sun_loungers_and_umbrellas.png', order: 8400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Balcony', label_el: 'Μπαλκόνι', value: 'balcony', type: 'outdoor', icon: 'assets/img/amenities/balcony.png', order: 7400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Garden', label_el: 'Κήπος', value: 'garden', type: 'outdoor', icon: 'assets/img/amenities/garden.png', order: 7600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Swimming pool', label_el: 'Πισίνα', value: 'swimming_pool', type: 'outdoor', icon: 'assets/img/amenities/swimming_pool.png', order: 8500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Terrace', label_el: 'Βεράντα', value: 'terrace', type: 'outdoor', icon: 'assets/img/amenities/terrace.png', order: 8600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Street parking', label_el: 'Δημόσιος χώρος στάθμευσης', value: 'street_parking', type: 'parking', icon: 'assets/img/amenities/parking_slots.png', order: 8800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Private parking', label_el: 'Ιδιωτικός χώρος στάθμευσης', value: 'parking_slots', type: 'parking', icon: 'assets/img/amenities/parking_slots.png', order: 8700, selected: false, tempSelected: false, chargeable: true, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Carbon monoxide alarm', label_el: 'Ανιχνευτής μονοξειδίου του άνθρακα', value: 'carbon_monoxide_alarm', type: 'security', icon: 'assets/img/amenities/carbon_monoxide_alarm.png', order: 8900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Double-glazed balcony door', label_el: 'Μπαλκονόπορτα με διπλά τζάμια', value: 'double_glazed_doors', type: 'security', icon: 'assets/img/amenities/glazed_windows.png', order: 9000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Double-glazed windows', label_el: 'Παράθυρα με διπλά τζάμια', value: 'double_glazed_windows', type: 'security', icon: 'assets/img/amenities/glazed_doors.png', order: 9100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Fire extinguisher', label_el: 'Πυροσβεστήρες', value: 'fire_extinguisher', type: 'security', icon: 'assets/img/amenities/fire_extinguisher.png', order: 9200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'First aid kit', label_el: 'Κουτί πρώτων βοηθειών', value: 'first_aid_kit', type: 'security', icon: 'assets/img/amenities/first_aid_kit.png', order: 9300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Intercom system', label_el: 'Σύστημα ενδοεπικοινωνίας', value: 'intercom_system', type: 'security', icon: 'assets/img/amenities/intercom_system.png', order: 9400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Lockbox', label_el: 'Χρηματοκιβώτιο', value: 'lockbox', type: 'security', icon: 'assets/img/amenities/lockbox.png', order: 9500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Security alarm system', label_el: 'Σύστημα συναγερμού ασφαλειας', value: 'security_alarm_system', type: 'security', icon: 'assets/img/amenities/security_alarm_system.png', order: 9600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Security cameras', label_el: 'Κάμερες ασφαλειας', value: 'security_cameras', type: 'security', icon: 'assets/img/amenities/security_cameras.png', order: 9700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Security door', label_el: 'Πόρτα ασφαλείας', value: 'security_doors', type: 'security', icon: 'assets/img/amenities/security_doors.png', order: 9800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Smoke detector', label_el: 'Ανιχνευτής καπνού', value: 'smoke_detector', type: 'security', icon: 'assets/img/amenities/smoke_detector.png', order: 9900, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Triple-glazed balcony door', label_el: 'Μπαλκονόπορτα με τριπλά τζάμια', value: 'triple_glazed_doors', type: 'security', icon: 'assets/img/amenities/glazed_doors.png', order: 10000, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Triple-glazed windows', label_el: 'Παράθυρα με τριπλά τζάμια', value: 'triple_glazed_windows', type: 'security', icon: 'assets/img/amenities/glazed_windows.png', order: 10100, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Office lighting', label_el: 'Φωτισμός γραφείου', value: 'office_lighting', type: 'work', icon: 'assets/img/amenities/office_lighting.png', order: 10300, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Office chair', label_el: 'Καρέκλα γραφείου', value: 'office_chair', type: 'work', icon: 'assets/img/amenities/office_chair.png', order: 10400, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Office supplies', label_el: 'Είδη γραφείου', value: 'office_supplies', type: 'work', icon: 'assets/img/amenities/office_supplies.png', order: 10500, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Office desk', label_el: 'Γραφείο', value: 'working_desk', type: 'work', icon: 'assets/img/amenities/office_desk.png', order: 10700, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false },
    { label: 'Ethernet', label_el: 'Προσαρμογέας δικτύου', value: 'ethernet', type: 'work', icon: 'assets/img/amenities/ethernet.png', order: 10200, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Wi-Fi', label_el: 'Ίντερντετ', value: 'wifi', type: 'work', icon: 'assets/img/amenities/wifi.png', order: 10600, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Workspace', label_el: 'Χώρος εργασίας', value: 'workspace', type: 'work', icon: 'assets/img/amenities/working_desk.png', order: 10800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Freezer', label_el: 'Καταψύκτης', value: 'freezer', type: 'kitchen', icon: 'assets/img/amenities/freezer.png', order: 10800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Wine glasses', label_el: 'Ποτήρια κρασιού', value: 'wine_glasses', type: 'kitchen', icon: 'assets/img/amenities/wine-glasses.png', order: 10800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Fan', label_el: 'Ανεμιστήρας', value: 'fan', type: 'cooling', icon: 'assets/img/amenities/fan.png', order: 10800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: true },
    { label: 'Nomad365 Certified', label_el: 'Πιστοποίηση Nomad365', value: 'nomad365_certified', type: 'work', icon: 'assets/img/amenities/nomad365-certified.png', order: 10800, selected: false, tempSelected: false, chargeable: false, is_tourmie_stay_guaranteed: false, is_searchable: false }
];



export const property_license_number_types = [
    { label: 'Property Registration Number (A.M.A.)', label_el: 'Αριθμός Μητρώου Ακινήτου (Α.Μ.Α)', value: 'prn' },
    { label: 'Special Operating Logo (ESL - MHTE) or Notify Business Number (MAG)', label_el: 'Ειδικό Σήμα Λειτουργίας - Αριθμός Μητρώου Τουριστικών Επιχειρήσεων ( ΕΣΛ - MHTE) ή Μοναδικός Αριθμός Γνωστοποίησης (ΜΑΓ)', value: 'esl_mag' },
    { label: 'My property has an exception', label_el: 'Ισχύει εξαίρεση για το ακίνητό μου', value: 'exception' }
];



export const property_bed_types = [
    { label: 'Double bed', label_el: 'Διπλό κρεβάτι', value: 'double_beds' },
    { label: 'Single bed', label_el: 'Μονό κρεβάτι', value: 'single_beds' },
    { label: 'Sofa bed', label_el: 'Καναπές κρεβάτι', value: 'sofa_beds' },
    { label: 'Couch', label_el: 'Καναπές', value: 'couches' },
    { label: 'Child bed', label_el: 'Παιδικό κρεβάτι', value: 'child_beds' },
    { label: 'Queen size bed', label_el: 'Υπέρδιπλο κρεβάτι (queen)', value: 'queen_size_beds' },
    { label: 'King size bed', label_el: 'Υπέρδιπλο κρεβάτι (king)', value: 'king_size_beds' },
];



export const property_extra_costs = [
    // { label_el: 'Ενοίκιο διαμονής', label: 'Accommodation rental', value: 'accommodation_price', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 10 },
    { label: 'Maintenance expenses', label_el: 'Κοινόχρηστα', value: 'maintenance_expenses', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 20 },
    { label: 'Electricity', label_el: 'Ηλεκτρικό ρεύμα', value: 'electricity', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 30 },
    { label: 'Water', label_el: 'Νερό', value: 'water', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 40 },
    { label: 'Heat', label_el: 'Θέρμανση', value: 'heat', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 50 },
    { label: 'Cleaning service', label_el: 'Καθαρισμός χώρου', value: 'cleaning_service', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 60 },
    { label: 'Laundry service', label_el: 'Καθαριστήριο', value: 'laundry_service', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 70 },
    { label: 'Linen & towels', label_el: 'Κλινοσκεπάσματα', value: 'linen_and_towels', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 80 },
    { label: 'Garden maintenance', label_el: 'Καθαρισμός κήπου', value: 'garden_maintenance', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 90 },
    { label: 'Pool maintenance', label_el: 'Καθαρισμός πισίνας', value: 'pool_maintenance', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 100 },
    { label: 'Pets', label_el: 'Κατοικίδια', value: 'pets', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 110 },
    { label: 'WiFi', label_el: 'WiFi', value: 'wifi', service_type: 'accommodation', chargeable: true, optional_to_guest: false, order: 120 },
    { label: 'Storage', label_el: 'Αποθήκη', value: 'storage', service_type: 'accommodation', chargeable: true, optional_to_guest: true, order: 130 },
];
