export class BlogPost {

    id: number;
    author_id: number;
    parent_id: number;
    title: string;
    title_el: string;
    slug: string;
    summary: string;
    summary_el: string;
    published: boolean;
    created_at: Date;
    updated_at: Date;
    published_at: Date;
    content: string;
    content_el: string;
    category: BlogCategory;
    parent: BlogPost;

    constructor(props?: BlogPost) {

        this.id = props?.id || null;
        this.author_id = props?.author_id || null;
        this.parent_id = props?.parent_id || null;
        this.title = props?.title || null;
        this.title_el = props?.title_el || null;
        this.slug = props?.slug || null;
        this.summary = props?.summary || null;
        this.summary_el = props?.summary_el || null;
        this.published = props?.published || false;
        this.created_at = props?.created_at || null;
        this.updated_at = props?.updated_at || null;
        this.published_at = props?.published_at || null;
        this.content = props?.content || null;
        this.content_el = props?.content_el || null;
        this.category = props?.category || null;
        this.parent = props?.parent || null;

    }

}


export class BlogCategory {

    id: number;
    parent_id: number;
    title: string;
    title_el: string;
    slug: string;
    visible: boolean;
    content: string;
    content_el: string;
    total_posts: number;
    posts?: BlogPost[];
    parent?: BlogCategory;

    constructor(props?: BlogCategory) {

        this.id = props?.id || null;
        this.parent_id = props?.parent_id || null;
        this.title = props?.title || null;
        this.title_el = props?.title_el || null;
        this.slug = props?.slug || null;
        this.visible = props?.visible || false;
        this.content = props?.content || null;
        this.content_el = props?.content_el || null;
        this.total_posts = props?.total_posts || 0;
        this.posts = props?.posts || [];
        this.parent = props?.parent || null;

    }

}

