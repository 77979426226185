import { GoogleAnalyticsService } from './google-analytics.service';
import { Directive, Input, HostListener } from '@angular/core';

interface GoogleAnalyticsEventArguments {
  title: string;
  data: {
    category?: string,
    action?: string,
    [key: string]: any
  };
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[gaEvent]'
})
export class GoogleAnalyticsEventDirectiveDirective {

  @Input('gaEvent') arguments: GoogleAnalyticsEventArguments;

  constructor(
    private ga: GoogleAnalyticsService
  ) { }


  @HostListener('click', ['$event']) onClick($event) {

    if (this.arguments.title)
      this.ga.sendEvent(this.arguments.title, this.arguments.data || null);

  }


}
