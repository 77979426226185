import { Customer, Booking } from '../../models';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface CustomersQueryParams {
    customer_id?: string;
    account_id?: string;
    // partner_id?: string;
    // provider_id?: string;
}

interface BookingsQueryParams {
    booking_id?: string;
    customer_id?: string;
    account_id?: string;
}

interface ReviewsQueryParams {
    review_id?: string;
    customer_id?: string;
    account_id?: string;
    service_id?: string;
}


interface HostsQueryParams {
    account_id?: string;
}


interface ProviderQueryParams {
    account_id?: string;
}

@Injectable({
    providedIn: 'root'
})
export class GraphqlService {

    constructor(
        private http: HttpClient
    ) { }





    // Customer Details
    async getCustomerData(params: CustomersQueryParams): Promise<Customer> {
        try {
            const customer = await this.http.get<Customer>(`${environment.params.host}/api/stay/customers/${params.customer_id}`).toPromise();

            return Promise.resolve(customer);
        } catch (error) {

            if (!environment.production)
                console.log(error);

            return Promise.reject(error);
        }
    }





    // Get data for single Booking
    async getBooking(booking_id: string): Promise<Booking> {
        try {

            const booking = await this.http.get<Booking>(`${environment.params.host}/api/stay/bookings/${booking_id}`).toPromise();

            return Promise.resolve(booking);


        } catch (error) {

            if (!environment.production)
                console.log(error);

            return Promise.reject(error);
        }
    }




    // Get array of Bookings[] for the logged in Customer
    async getBookings(): Promise<Booking[]> {
        try {

            const bookings = await this.http.get<Booking[]>(`${environment.params.host}/api/stay/bookings`).toPromise();

            return Promise.resolve(bookings);


        } catch (error) {

            if (!environment.production)
                console.log(error);

            return Promise.reject(error);
        }
    }




    // Reviews List & Single Review Details
    async getReviews(params: ReviewsQueryParams, listView?: boolean): Promise<any> {
        try {
            let graphParams = '';

            if (params) {

                graphParams += '(';

                for (const key in params)
                    graphParams += `${key}: "${params[key]}", `;

                graphParams += ')';

            }


            const graphQuery = `
                {
                    reviews${(graphParams) ? `${graphParams}` : ''}{
                        id
                        review_id
                        service_id
                        booking_id
                        customer_id
                        provider_id
                        partner_id
                        text
                        metric_customer_service
                        metric_accuracy
                        metric_communication
                        metric_value_for_money
                        metric_consistency
                        rating
                        created_at
                        customer{
                            id
                            customer_id
                            first_name
                            last_name
                            email
                            fullname
                        }
                        provider{
                            id
                            account_id
                            business_name
                            title
                            is_car_rental_provider
                            is_transfer_provider
                            is_experience_provider
                            is_food_provider
                        }
                        service{
                            id
                            service_id
                            provider_id
                            title
                            type
                            image_url
                            price
                            inhouse
                            booking_type
                            discount
                            discount_percent
                            created_at
                        }
                        partner{
                            id
                            account_id
                            business_name
                            title
                        }
                    }
                }`;


            const response: any = await this.http.post(`${environment.params.host}/graphql`, { query: graphQuery }).toPromise();

            // tslint:disable:max-line-length
            if (params && listView && (params.review_id || params.service_id || params.customer_id || params.account_id) &&
                response?.data?.reviews && response.data.reviews.length === 1)
                return Promise.resolve(response.data.reviews);
            else if (params && !listView && (params.review_id || params.service_id || params.customer_id || params.account_id) &&
                response?.data?.reviews && response.data.reviews.length === 1)
                return Promise.resolve(response.data.reviews[0]);
            else if (params && (params.review_id || params.service_id || params.customer_id || params.account_id) && response?.data?.reviews && response.data.reviews.length === 0 && !listView)
                return Promise.resolve({});
            else if (params && (params.review_id || params.service_id || params.customer_id || params.account_id) && response?.data?.reviews && response.data.reviews.length === 0 && listView)
                return Promise.resolve([]);
            else
                return Promise.resolve(response.data.reviews);

            // tslint:enable:max-line-length


            // if (params && (params.review_id) &&
            //     response?.data?.reviews && response.data.reviews.length === 1)
            //     return Promise.resolve(response.data.reviews[0]);
            // // tslint:disable-next-line:max-line-length
            // else if (params && (params.review_id || params.customer_id || params.host_id || params.provider_id)
            // && response?.data?.reviews && response.data.reviews.length === 0)
            //     return Promise.resolve({});
            // else
            //     return Promise.resolve(response.data.reviews);

        } catch (error) {

            if (!environment.production)
                console.log(error);

            return Promise.reject(error);
        }
    }





}
