import { Component, OnDestroy, OnInit, ElementRef, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GoogleAnalyticsService } from '../../../services/google-analytics/google-analytics.service';
import { StorageService } from '../../../services/storage/storage.service';
import { SeoService } from '../../../services/seo/seo-service.service';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { SweetAlertOptions } from 'sweetalert2';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Country, Customer, GuestRegistrationData, ViesData } from '../../../models';
import { UtilsService } from '../../../services/utils/utils.service';



@Component({
  selector: 'app-registration-guests',
  templateUrl: './registration-guests.component.html',
  styleUrls: ['./registration-guests.component.scss']
})
export class RegistrationGuestsComponent implements OnInit, OnDestroy {

  public terms: null;

  @ViewChild('addressStreetView', { static: false }) customerAddress: ElementRef; // google place autocomplete

  public isMobile = false;
  private isMobileSubscription: Subscription;

  public termsAgreed;
  public privacyPolicyAgreed;
  public isBusiness;
  public currentStep = 0;
  public euVatValid = true;

  public modalRef: BsModalRef;

  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Greece];

  public userMobilePhone;
  public businessPhone;

  public countries: Country[];
  public identity_verification_types = environment.identity_verification.types;

  public isSubmitting = false;
  public isCheckingCustomerTaxID = false;
  public isCheckingCustomerEmail = false;
  public isCheckingCustomerPhone = false;

  public registrationData = new GuestRegistrationData();

  public queryParams: Params = {};

  // Date of birth
  public minDateOfBirth: string = null;
  public maxDateOfBirth: string = null;


  constructor(
    public utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private http: HttpClient,
    private cdRef: ChangeDetectorRef,
    private ga: GoogleAnalyticsService,
    private modalService: BsModalService,
    private seo: SeoService,
    private storageService: StorageService
  ) {

    this.isMobile = this.utilsService.isMobile;

    this.countries = this.utilsService.countries;

    this.minDateOfBirth = this.utilsService.moment().subtract(100, 'years').format('YYYY-MM-DD');
    this.maxDateOfBirth = this.utilsService.moment().subtract(18, 'years').format('YYYY-MM-DD');

  }

  ngOnInit(): void {

    this.seo.updatePageMetadata({ page: 'registration' });

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    // this.ga.sendScreenName('Registration');


    const customer: Customer = this.storageService.getItem('customer');

    if (customer && customer.id)
      this.router.navigate(['/']);

    this.queryParams = this.route.snapshot.queryParams;

    this.initializeAddressAutocomplete();
  }

  ngOnDestroy(): void {
    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();
  }

  onBusinessCheckboxChange(event: any): void {
    if (event.target.checked)
      this.registrationData.type = 'business';
    else {
      this.registrationData.type = 'individual';
      this.registrationData.business_email = null;
      this.registrationData.business_name = null;
      this.registrationData.business_phone = null;
      this.registrationData.business_title = null;
      // this.registrationData.tax_authority = null;
      // this.registrationData.tax_id = null;
      this.businessPhone = null;
    }

  }

  updateUserMobileNumber() {
    if (this.userMobilePhone?.internationalNumber)
      this.registrationData.phone = this.userMobilePhone.internationalNumber;
    else
      this.registrationData.phone = null;
  }

  updateBusinessMobileNumber() {
    if (this.businessPhone?.internationalNumber)
      this.registrationData.business_phone = this.businessPhone.internationalNumber;
    else
      this.registrationData.business_phone = null;
  }

  onVatNumberChange(): void {
    if (this.registrationData.tax_id)
      this.registrationData.tax_id = this.registrationData.tax_id.replace(/\s/g, '');
  }

  async vatCheck(): Promise<any> {
    try {

      const vatResponse = this.utilsService.checkVatNumber(this.registrationData.address.country, this.registrationData.tax_id);
      if (vatResponse) // VAT number is for EU Business

        if (!vatResponse.isValid && vatResponse.country.vat_prefix && vatResponse.country.name) {

          this.utilsService.swal.fire(
            (await this.translate.get('PAGES.REGISTRATION.ALERTS.INVALID_VAT_NUMBER_FORMAT.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
            (await this.translate.get('PAGES.REGISTRATION.ALERTS.INVALID_VAT_NUMBER_FORMAT.HTML', {
              countryName: vatResponse.country.name,
              vatPrefixForCountry: vatResponse.country.vat_prefix
            }).toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
            'warning');

          this.isSubmitting = false;

          return Promise.reject();

        }

      return Promise.resolve();

    } catch (error) {
      return Promise.reject({ message: (await this.translate.get('PAGES.REGISTRATION.ALERTS.VAT_VERIFICATION_FAILED.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title) });
    }
  }

  async checkIfAccountExistByTaxID(): Promise<any> {
    try {

      const userExist = await this.utilsService.checkIfAccountExistsByTaxID(this.registrationData.tax_id);

      if (userExist) {

        this.utilsService.swal.fire(
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_ALREADY_EXIST_WITH_THIS_TAX_ID.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_ALREADY_EXIST_WITH_THIS_TAX_ID.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          'error'
        );

        return Promise.reject();

      }

    } catch (error) {
      return Promise.reject();
    }
  }

  async checkIfAccountExistByEmail(): Promise<any> {
    try {

      const userExist = await this.utilsService.checkIfAccountExistsByEmail(this.registrationData.email);

      if (userExist) {
        this.utilsService.swal.fire(
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.USER_ALREADY_EXIST_FOR_EMAIL.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.USER_ALREADY_EXIST_FOR_EMAIL.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          'error'
        );

        return Promise.reject();

      }

    } catch (error) {
      return Promise.reject();
    }

  }

  async checkIfAccountExistByPhone(): Promise<any> {

    try {

      const userExist = await this.utilsService.checkIfAccountExistsByPhone(this.registrationData.phone);

      if (userExist) {
        this.utilsService.swal.fire(
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_ALREADY_EXIST_WITH_THIS_PHONE.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_ALREADY_EXIST_WITH_THIS_PHONE.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          'error'
        );

        return Promise.reject();

      }

    } catch (error) {
      return Promise.reject();
    }
  }

  async nextStep(): Promise<void> {

    this.isCheckingCustomerEmail = true;

    try {

      await this.checkIfAccountExistByEmail();


      this.isCheckingCustomerEmail = false;

      const dateOfBirthIsValid = await this.birthDateIsValid();

      if (!dateOfBirthIsValid)
        return Promise.resolve();



      this.currentStep += 1;

      if (this.utilsService.isBrowser)
        window.scrollTo(0, 0);

    } catch (error) {

      this.isCheckingCustomerEmail = false;
      console.log(error);

    }

  }

  previousStep(): void {
    this.currentStep -= 1;
  }

  nullify(): void {
    this.registrationData.driving_license_number = null;
    this.registrationData.identity_card_number = null;
    this.registrationData.passport_number = null;
  }


  async birthDateIsValid(): Promise<boolean> {

    if (this.registrationData.birth_date)
      if (
        this.utilsService.moment(this.registrationData.birth_date).format('YYYY-MM-DD') > this.maxDateOfBirth
        ||
        this.utilsService.moment(this.registrationData.birth_date).format('YYYY-MM-DD') < this.minDateOfBirth
      ) {
        this.utilsService.showAlert({ title: 'Error', html: (await this.translate.get('PAGES.REGISTRATION.ALERTS.INVALID_BIRTH_DATE.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title), icon: 'error' });
        this.registrationData.birth_date = null;
        return Promise.resolve(false);
      }

    return Promise.resolve(true);

  }


  async submitForm(): Promise<void> {

    if (!environment.production)
      console.log(this.registrationData);

    this.isSubmitting = true;

    try {

      const savedLanguage = this.storageService.getItem('appLanguage');
      this.registrationData.language = savedLanguage ? savedLanguage : 'el'; // FIXME: change 'el' to 'en' when terms translation ready

      if (this.isBusiness) {

        await this.vatCheck();

        const vatResponse = this.utilsService.checkVatNumber(this.registrationData.address.country, this.registrationData.tax_id);

        const viesRequestBody = {
          countryCode: vatResponse.country.vat_prefix,
          vatNumber: this.registrationData.tax_id.replace(/^.{2}/g, '') // vat number string without country prefix, e.g. 'EL123456789' -> '123456789'
        };

        const VIESbusinessResponse = await this.http.get<ViesData>(`${environment.params.host}/api/utils/check-vat-number`, { params: viesRequestBody }).toPromise();

        if (VIESbusinessResponse?.valid === false) {

          // this.utilsService.showAlert({
          //   title: 'Invalid VAT number',
          //   html: `No businesses were found with this VAT number: <b>${this.registrationData.account_tax_id}</b>`,
          //   icon: 'error',
          // });

          this.utilsService.showAlert({
            title: (await this.translate.get('PAGES.REGISTRATION.ALERTS.INVALID_BUSINESS_VAT_NUMBER_BUSINESS_NOT_FOUND.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
            html: (await this.translate.get('PAGES.REGISTRATION.ALERTS.INVALID_BUSINESS_VAT_NUMBER_BUSINESS_NOT_FOUND.HTML', {
              accountTaxID: this.registrationData.tax_id
            }).toPromise().toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title)),
            icon: 'error',
          });

          this.isSubmitting = false;

          return Promise.resolve();

        }

        if (VIESbusinessResponse?.valid === true)
          this.registrationData.vies_data = VIESbusinessResponse;

      }
      // 1a. Check if account already exists (by checking Tax ID)
      // await this.checkIfAccountExistByTaxID();

      // 1b. Check if account already exists (by checking Email) -- Checked on step 1
      // await this.checkIfAccountExistByEmail();

      // 1c. check if account already exists (by checking Phone)
      // await this.checkIfAccountExistByPhone();

      // 3. Create Tourmie User & Account

      const newCustomerResponse = await this.http.post<Customer>(`${environment.params.host}/api/stay/auth/register`, this.registrationData).toPromise(); // contains User & its new account

      if (!environment.production)
        console.log({
          registrationData: this.registrationData,
          customerData: newCustomerResponse
        });

      // 6. Send verification email
      try {

        const resendVerificationResponse = await this.http.post(`${environment.params.host}/api/stay/utils/resend-confirmation-email`, { email: this.registrationData.email }).toPromise();

      } catch (error) {

        if (!environment.production)
          console.log(`Error on send verification email`);
      }

      this.isSubmitting = false;

      // Account creation alert
      const alertOptions: SweetAlertOptions = {
        title: (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_CREATED.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
        html: (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_CREATED.HTML', { email: this.registrationData.email }).toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
        icon: 'success',
        allowOutsideClick: false,
        reverseButtons: true,
        showConfirmButton: true,
        confirmButtonText: (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_CREATED.CONFIRM_BUTTON').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
        showCancelButton: true,
        cancelButtonText: (await this.translate.get('PAGES.REGISTRATION.ALERTS.ACCOUNT_CREATED.CANCEL_BUTTON').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
        cancelButtonColor: '#3399cc'
      };


      this.ga.sendEvent('new_tenant_acccount_created');

      const alertResult = await this.utilsService.swal.fire(alertOptions);

      if (alertResult.isConfirmed)
        this.router.navigate(['/login']);
      else
        try {

          const resendVerificationResponse = await this.http.post(`${environment.params.host}/api/stay/utils/resend-confirmation-email`, { email: this.registrationData.email }).toPromise();

          await this.utilsService.showAlert({
            title: (await this.translate.get('PAGES.REGISTRATION.ALERTS.CONFIRMATION_EMAIL_SENT.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
            html: (await this.translate.get('PAGES.REGISTRATION.ALERTS.CONFIRMATION_EMAIL_SENT.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
            icon: 'success'
          });

          this.router.navigate(['/login']);

        } catch (error) {
          return;
        }

    } catch (error) {
      this.isSubmitting = false;
    }
  }


  async initializeAddressAutocomplete(): Promise<void> {

    if (this.utilsService.isBrowser) {

      await this.utilsService.delay(2000);

      const returnLocationAutocomplete = new google.maps.places.Autocomplete(this.customerAddress.nativeElement, {}); // types: ['establishment']  // 'establishment' / 'address' / 'geocode'

      google.maps.event.addListener(returnLocationAutocomplete, 'place_changed', async () => {

        const place = returnLocationAutocomplete.getPlace();
        const addressDetails = await this.utilsService.extractGoogleAddressDetails(place, true); // (city, state, country)

        this.registrationData.address.city = (addressDetails?.city) ? addressDetails.city : null;
        this.registrationData.address.is_city = (addressDetails?.is_city) ? addressDetails.is_city : false;
        this.registrationData.address.state = (addressDetails?.state) ? addressDetails.state : null;
        this.registrationData.address.country = (addressDetails?.country) ? addressDetails.country : null;
        this.registrationData.address.street = (addressDetails?.street) ? addressDetails.street : null;
        this.registrationData.address.street_formatted = (addressDetails.street_formatted) ? addressDetails.street_formatted : null;
        this.registrationData.address.postal_code = (addressDetails?.postal_code) ? addressDetails.postal_code : null;
        this.registrationData.address.latitude = (place?.geometry?.location?.lat() && place.geometry.location.lat()) ? place.geometry.location.lat() : null;
        this.registrationData.address.longitude = (place?.geometry?.location?.lng() && place.geometry.location.lng()) ? place.geometry.location.lng() : null;
        this.registrationData.address.google_place_id = (addressDetails.google_place_id) ? addressDetails.google_place_id : null;
        this.registrationData.address.utc_offset = (addressDetails.utc_offset) ? addressDetails.utc_offset : null;
        this.registrationData.address.url = (addressDetails.url) ? addressDetails.url : null;
        this.registrationData.address.types = (addressDetails.types) ? addressDetails.types : null;

        this.customerAddress.nativeElement.value = (addressDetails?.street) ? addressDetails.street : null;


        this.customerAddress.nativeElement.blur();
        this.customerAddress.nativeElement.focus();
        this.customerAddress.nativeElement.blur();

        this.cdRef.detectChanges();
        return Promise.resolve();

      });

    }

  }


  // Terms of Service html generation
  async getTermsOfService(template: TemplateRef<any>): Promise<void> {
    try {

      if (
        !this.registrationData.address.street ||
        !this.registrationData.address.city ||
        !this.registrationData.address.postal_code ||
        !this.registrationData.address.country ||
        !this.registrationData.tax_authority ||
        !this.registrationData.tax_id ||
        (this.registrationData.type === 'business' && (
          !this.registrationData.business_phone ||
          !this.registrationData.business_email ||
          !this.registrationData.business_name))
      ) {

        this.utilsService.swal.fire(
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.REQUIRED_FIELDS_MISSING.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          (await this.translate.get('PAGES.REGISTRATION.ALERTS.REQUIRED_FIELDS_MISSING.HTML').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          'info'
        );

        return Promise.resolve();
      }

      const termsResponse = await this.http.post<any>(`${environment.params.host}/api/stay/utils/get-terms-tenant?source=registration`, this.registrationData).toPromise();

      this.terms = termsResponse.terms;
      if (termsResponse.terms && this.utilsService.isBrowser) {

        const options: ModalOptions = {
          class: 'modal-lg second',
          backdrop: 'static'
        };

        this.modalRef = this.modalService.show(template, options);

      }

    } catch (error) {

      if (!environment.production)
        console.log(error);

    }
  }
}
