import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailVerificationPageRoutingModule } from './email-verification-routing.module';
import { EmailVerificationPageComponent } from './email-verification.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';


@NgModule({
  declarations: [
    EmailVerificationPageComponent
  ],
  imports: [
    CommonModule,
    EmailVerificationPageRoutingModule,
    TranslateModule,
    PipesModule
  ]
})
export class EmailVerificationModule { }
