export const timezones = [
    {
        country_code: 'AW',
        utc_offset: -4,
        timezone: 'America/Aruba',
        timezone_el: 'Αμερική/Αρούμπα',
        timezone_es: 'América/Aruba',
        timezone_it: 'América/Aruba',
        timezone_fr: 'Amérique/Aruba',
        timezone_de: 'Amerika/Aruba',
        timezone_ca: 'Amèrica/Aruba',
        country_name: 'Aruba',
        country_name_el: 'Αρούμπα',
        country_name_es: 'Aruba',
        country_name_it: 'Aruba',
        country_name_fr: 'Aruba',
        country_name_de: 'Aruba',
        country_name_ca: 'Aruba',
        country_capital: 'Oranjestad',
        country_capital_el: 'Ορανιέστατ',
        country_capital_es: 'Oranjestad',
        country_capital_it: 'Oranjestad',
        country_capital_fr: 'Oranjestad',
        country_capital_de: 'Oranjestad',
        country_capital_ca: 'Oranjestad',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Nord-amèrica'
    },
    {
        country_code: 'AF',
        utc_offset: 44989,
        timezone: 'Asia/Kabul',
        timezone_el: 'Ασία/Καμπούλ',
        timezone_es: 'Asia/Kabul',
        timezone_it: 'Asie/Kaboul',
        timezone_fr: 'Asie/Kaboul',
        timezone_de: 'Asien/Kabul',
        timezone_ca: 'Àsia/Kàbul',
        country_name: 'Afghanistan',
        country_name_el: 'Αφγανιστάν',
        country_name_es: 'Afganistán',
        country_name_it: 'Afghanistan',
        country_name_fr: 'Afghanistan',
        country_name_de: 'Afghanistan',
        country_name_ca: 'Afghanistan',
        country_capital: 'Kabul',
        country_capital_el: 'Καμπούλ',
        country_capital_es: 'Kabul',
        country_capital_it: 'Kabul',
        country_capital_fr: 'Kaboul',
        country_capital_de: 'Kabul',
        country_capital_ca: 'Kabul',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'AO',
        utc_offset: 1,
        timezone: 'Africa/Luanda',
        timezone_el: 'Αφρική/Λουάντα',
        timezone_es: 'África/Luanda',
        timezone_it: 'Afrique/Luanda',
        timezone_fr: 'Afrique/Luanda',
        timezone_de: 'Afrika/Luanda',
        timezone_ca: 'Àfrica/Luanda',
        country_name: 'Angola',
        country_name_el: 'Αγκόλα',
        country_name_es: 'Angola',
        country_name_it: 'Angola',
        country_name_fr: 'Angola',
        country_name_de: 'Angola',
        country_name_ca: 'Angola',
        country_capital: 'Luanda',
        country_capital_el: 'Λουάντα',
        country_capital_es: 'Luanda',
        country_capital_it: 'Luanda',
        country_capital_fr: 'Luanda',
        country_capital_de: 'Luanda',
        country_capital_ca: 'Luanda',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'AI',
        utc_offset: -4,
        timezone: 'America/Anguilla',
        timezone_el: 'Αμερική/Ανγκουίλα',
        timezone_es: 'América/Anguila',
        timezone_it: 'Amérique/Anguilla',
        timezone_fr: 'Amérique/Anguilla',
        timezone_de: 'Amerika/Anguilla',
        timezone_ca: 'Amèrica/Anguilla',
        country_name: 'Anguilla',
        country_name_el: 'Ανγκουίλα',
        country_name_es: 'Anguilla',
        country_name_it: 'Anguilla',
        country_name_fr: 'Anguilla',
        country_name_de: 'Anguilla',
        country_name_ca: 'Anguilla',
        country_capital: 'The Valley',
        country_capital_el: 'Θε Βάλευ',
        country_capital_es: 'The Valley',
        country_capital_it: 'The Valley',
        country_capital_fr: 'The Valley',
        country_capital_de: 'The Valley',
        country_capital_ca: 'The Valley',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Nord-amèrica'
    },
    {
        country_code: 'AX',
        utc_offset: 2,
        timezone: 'Europe/Mariehamn',
        timezone_el: 'Ευρώπη/Μαρίεχαμν',
        timezone_es: 'Europa/Mariehamn',
        timezone_it: 'Europe/Mariehamn',
        timezone_fr: 'Europe/Mariehamn',
        timezone_de: 'Europa/Mariehamn',
        timezone_ca: 'Europa/Mariehamn',
        country_name: 'Åland Islands',
        country_name_el: 'Νήσοι Ώλαντ',
        country_name_es: 'Islas Åland',
        country_name_it: 'Isole Åland',
        country_name_fr: 'Îles Åland',
        country_name_de: 'Åland-Inseln',
        country_name_ca: 'Illes Åland',
        country_capital: 'Mariehamn',
        country_capital_el: 'Μαρίεχαμν',
        country_capital_es: 'Mariehamn',
        country_capital_it: 'Mariehamn',
        country_capital_fr: 'Mariehamn',
        country_capital_de: 'Mariehamn',
        country_capital_ca: 'Mariehamn',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'AL',
        utc_offset: 2,
        timezone: 'Europe/Tirane',
        timezone_el: 'Ευρώπη/Τίρανα',
        timezone_es: 'Europa/Tirana',
        timezone_it: 'Europe/Tirane',
        timezone_fr: 'Europe/Tirana',
        timezone_de: 'Europa/Tirane',
        timezone_ca: 'Europa/Tirana',
        country_name: 'Albania',
        country_name_el: 'Αλβανία',
        country_name_es: 'Albania',
        country_name_it: 'Albania',
        country_name_fr: 'Albanie',
        country_name_de: 'Albanien',
        country_name_ca: 'Albània',
        country_capital: 'Tirana',
        country_capital_el: 'Τίρανα',
        country_capital_es: 'Tirana',
        country_capital_it: 'Tirana',
        country_capital_fr: 'Tirana',
        country_capital_de: 'Tirana',
        country_capital_ca: 'Tirana',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'AD',
        utc_offset: 1,
        timezone: 'Europe/Andorra',
        timezone_el: 'Ευρώπη/Ανδόρρα',
        timezone_es: 'Europa/Andorra',
        timezone_it: 'Europe/Andorre',
        timezone_fr: 'Europe/Andorre',
        timezone_de: 'Europa/Andorra',
        timezone_ca: 'Europa/Andorra',
        country_name: 'Andorra',
        country_name_el: 'Ανδόρρα',
        country_name_es: 'Andorra',
        country_name_it: 'Andorra',
        country_name_fr: 'Andorre',
        country_name_de: 'Andorra',
        country_name_ca: 'Andorra',
        country_capital: 'Andorra la Vella',
        country_capital_el: 'Ανδόρα λα Βέλλα',
        country_capital_es: 'Andorra la Vella',
        country_capital_it: 'Andorra la Vella',
        country_capital_fr: 'Andorre-la-Vieille',
        country_capital_de: 'Andorra la Vella',
        country_capital_ca: 'Andorra la Vella',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'AE',
        utc_offset: 4,
        timezone: 'Asia/Dubai',
        timezone_el: 'Ασία/Ντουμπάι',
        timezone_es: 'Asia/Dubái',
        timezone_it: 'Asie/Dubaï',
        timezone_fr: 'Asie/Dubaï',
        timezone_de: 'Asien/Dubai',
        timezone_ca: 'Àsia/Dubai',
        country_name: 'United Arab Emirates',
        country_name_el: 'Ηνωμένα Αραβικά Εμιράτα',
        country_name_es: 'Emiratos Árabes Unidos',
        country_name_it: 'Emirati Arabi Uniti',
        country_name_fr: 'Émirats arabes unis',
        country_name_de: 'Vereinigte Arabische Emirate',
        country_name_ca: 'Emirats Àrabs Units',
        country_capital: 'Abu Dhabi',
        country_capital_el: 'Αμπού Ντάμπι',
        country_capital_es: 'Abu Dabi',
        country_capital_it: 'Abu Dhabi',
        country_capital_fr: 'Abou Dabi',
        country_capital_de: 'Abu Dhabi',
        country_capital_ca: 'Abu Dhabi',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/Buenos_Aires',
        timezone_el: 'Αμερική/Αργεντινή/Μπουένος_Άιρες',
        timezone_es: 'América/Argentina/Buenos_Aires',
        timezone_it: 'Amérique/Argentine/Buenos_Aires',
        timezone_fr: 'Amérique/Argentine/Buenos_Aires',
        timezone_de: 'Amerika/Argentinien/Buenos_Aires',
        timezone_ca: 'Amèrica/Argentina/Buenos_Aires',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/Cordoba',
        timezone_el: 'Αμερική/Αργεντινή/Κόρδοβα',
        timezone_es: 'América/Argentina/Córdoba',
        timezone_it: 'Amérique/Argentine/Cordoba',
        timezone_fr: 'Amérique/Argentine/Cordoue',
        timezone_de: 'Amerika/Argentinien/Cordoba',
        timezone_ca: 'Amèrica/Argentina/Cordoba',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/Salta',
        timezone_el: 'Αμερική/Αργεντινή/Σάλτα',
        timezone_es: 'América/Argentina/Salta',
        timezone_it: 'Amérique/Argentine/Salta',
        timezone_fr: 'Amérique/Argentine/Salta',
        timezone_de: 'Amerika/Argentinien/Salta',
        timezone_ca: 'Amèrica/Argentina/Salta',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/Jujuy',
        timezone_el: 'Αμερική/Αργεντινή/Χούχουι',
        timezone_es: 'América/Argentina/Jujuy',
        timezone_it: 'Amérique/Argentine/Jujuy',
        timezone_fr: 'Amérique/Argentine/Jujuy',
        timezone_de: 'Amerika/Argentinien/Jujuy',
        timezone_ca: 'Amèrica/Argentina/Jujuy',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/Tucuman',
        timezone_el: 'Αμερική/Αργεντινή/Τούκουμαν',
        timezone_es: 'América/Argentina/Tucumán',
        timezone_it: 'Amérique/Argentine/Tucuman',
        timezone_fr: 'Amérique/Argentine/Tucumán',
        timezone_de: 'Amerika/Argentinien/Tucuman',
        timezone_ca: 'Amèrica/Argentina/Tucuman',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/Catamarca',
        timezone_el: 'Αμερική/Αργεντινή/Καταμάρκα',
        timezone_es: 'América/Argentina/Catamarca',
        timezone_it: 'Amérique/Argentine/Catamarca',
        timezone_fr: 'Amérique/Argentine/Catamarca',
        timezone_de: 'Amerika/Argentinien/Catamarca',
        timezone_ca: 'Amèrica/Argentina/Catamarca',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/La_Rioja',
        timezone_el: 'Αμερική/Αργεντινή/Λα_Ριόχα',
        timezone_es: 'América/Argentina/La_Rioja',
        timezone_it: 'Amérique/Argentine/La_Rioja',
        timezone_fr: 'Amérique/Argentine/La_Rioja',
        timezone_de: 'Amerika/Argentinien/La_Rioja',
        timezone_ca: 'Amèrica/Argentina/La_Rioja',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/San_Juan',
        timezone_el: 'Αμερική/Αργεντινή/Σαν_Χουάν',
        timezone_es: 'América/Argentina/San_Juan',
        timezone_it: 'Amérique/Argentine/San_Juan',
        timezone_fr: 'Amérique/Argentine/San_Juan',
        timezone_de: 'Amerika/Argentinien/San_Juan',
        timezone_ca: 'Amèrica/Argentina/San_Juan',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/Mendoza',
        timezone_el: 'Αμερική/Αργεντινή/Μεντόζα',
        timezone_es: 'América/Argentina/Mendoza',
        timezone_it: 'Amérique/Argentine/Mendoza',
        timezone_fr: 'Amérique/Argentine/Mendoza',
        timezone_de: 'Amerika/Argentinien/Mendoza',
        timezone_ca: 'Amèrica/Argentina/Mendoza',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/San_Luis',
        timezone_el: 'Αμερική/Αργεντινή/Σαν_Λουίς',
        timezone_es: 'América/Argentina/San_Luis',
        timezone_it: 'Amérique/Argentine/San_Luis',
        timezone_fr: 'Amérique/Argentine/San_Luis',
        timezone_de: 'Amerika/Argentinien/San_Luis',
        timezone_ca: 'Amèrica/Argentina/San_Luis',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/Rio_Gallegos',
        timezone_el: 'Αμερική/Αργεντινή/Ρίο_Γκαγιέγκος',
        timezone_es: 'América/Argentina/Río_Gallegos',
        timezone_it: 'Amérique/Argentine/Rio_Gallegos',
        timezone_fr: 'Amérique/Argentine/Rio_Gallegos',
        timezone_de: 'Amerika/Argentinien/Rio_Gallegos',
        timezone_ca: 'Amèrica/Argentina/Rio_Gallegos',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AR',
        utc_offset: -3,
        timezone: 'America/Argentina/Ushuaia',
        timezone_el: 'Αμερική/Αργεντινή/Ουσουάια',
        timezone_es: 'América/Argentina/Ushuaia',
        timezone_it: 'Amérique/Argentine/Ushuaia',
        timezone_fr: 'Amérique/Argentine/Ushuaia',
        timezone_de: 'Amerika/Argentinien/Ushuaia',
        timezone_ca: 'Amèrica/Argentina/Ushuaia',
        country_name: 'Argentina',
        country_name_el: 'Αργεντινή',
        country_name_es: 'Argentina',
        country_name_it: 'Argentina',
        country_name_fr: 'Argentine',
        country_name_de: 'Argentinien',
        country_name_ca: 'Argentina',
        country_capital: 'Buenos Aires',
        country_capital_el: 'Μπουένος Άιρες',
        country_capital_es: 'Buenos Aires',
        country_capital_it: 'Buenos Aires',
        country_capital_fr: 'Buenos Aires',
        country_capital_de: 'Buenos Aires',
        country_capital_ca: 'Buenos Aires',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Sud-amèrica'
    },
    {
        country_code: 'AM',
        utc_offset: 4,
        timezone: 'Asia/Yerevan',
        timezone_el: 'Ασία/Γερεβάν',
        timezone_es: 'Asia/Ereván',
        timezone_it: 'Asie/Yerevan',
        timezone_fr: 'Asie/Erevan',
        timezone_de: 'Asien/Jerevan',
        timezone_ca: 'Àsia/Erevan',
        country_name: 'Armenia',
        country_name_el: 'Αρμενία',
        country_name_es: 'Armenia',
        country_name_it: 'Armenia',
        country_name_fr: 'Arménie',
        country_name_de: 'Armenien',
        country_name_ca: 'Armènia',
        country_capital: 'Yerevan',
        country_capital_el: 'Ερεβάν',
        country_capital_es: 'Ereván',
        country_capital_it: 'Erevan',
        country_capital_fr: 'Erevan',
        country_capital_de: 'Jerewan',
        country_capital_ca: 'Erevan',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'AS',
        utc_offset: -11,
        timezone: 'Pacific/Pago_Pago',
        timezone_el: 'Ειρηνικός/Πάγκο_Πάγκο',
        timezone_es: 'Pacífico/Pago_Pago',
        timezone_it: 'Pacifique/Pago_Pago',
        timezone_fr: 'Pacifique/Pago_Pago',
        timezone_de: 'Pazifik/Pago_Pago',
        timezone_ca: 'Pacífic/Pago_Pago',
        country_name: 'American Samoa',
        country_name_el: 'Αμερικανική Σαμόα',
        country_name_es: 'Samoa Americana',
        country_name_it: 'Samoa Americane',
        country_name_fr: 'Samoa américaines',
        country_name_de: 'Amerikanisch-Samoa',
        country_name_ca: 'Samoa Nord-americana',
        country_capital: 'Pago Pago',
        country_capital_el: 'Πάγκο Πάγκο',
        country_capital_es: 'Pago Pago',
        country_capital_it: 'Pago Pago',
        country_capital_fr: 'Pago Pago',
        country_capital_de: 'Pago Pago',
        country_capital_ca: 'Pago Pago',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AQ',
        utc_offset: 13,
        timezone: 'Antarctica/McMurdo',
        timezone_el: 'Ανταρκτική/ΜακΜέρντο',
        timezone_es: 'Antártida/McMurdo',
        timezone_it: 'Antarctique/McMurdo',
        timezone_fr: 'Antarctique/McMurdo',
        timezone_de: 'Antarktis/McMurdo',
        timezone_ca: 'Antàrtida/McMurdo',
        country_name: 'Antarctica',
        country_name_el: 'Ανταρκτική',
        country_name_es: 'Antártida',
        country_name_it: 'Antartide',
        country_name_fr: 'Antarctique',
        country_name_de: 'Antarktis',
        country_name_ca: 'Antàrtida',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'AQ',
        utc_offset: 13,
        timezone: 'Antarctica/Casey',
        timezone_el: 'Ανταρκτική/Κέισι',
        timezone_es: 'Antártida/Casey',
        timezone_it: 'Antarctique/Casey',
        timezone_fr: 'Antarctique/Casey',
        timezone_de: 'Antarktis/Casey',
        timezone_ca: 'Antàrtida/Casey',
        country_name: 'Antarctica',
        country_name_el: 'Ανταρκτική',
        country_name_es: 'Antártida',
        country_name_it: 'Antartide',
        country_name_fr: 'Antarctique',
        country_name_de: 'Antarktis',
        country_name_ca: 'Antàrtida',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'AQ',
        utc_offset: 13,
        timezone: 'Antarctica/Davis',
        timezone_el: 'Ανταρκτική/Ντέιβις',
        timezone_es: 'Antártida/Davis',
        timezone_it: 'Antarctique/Davis',
        timezone_fr: 'Antarctique/Davis',
        timezone_de: 'Antarktis/Davis',
        timezone_ca: 'Antàrtida/Davis',
        country_name: 'Antarctica',
        country_name_el: 'Ανταρκτική',
        country_name_es: 'Antártida',
        country_name_it: 'Antartide',
        country_name_fr: 'Antarctique',
        country_name_de: 'Antarktis',
        country_name_ca: 'Antàrtida',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'AQ',
        utc_offset: 13,
        timezone: 'Antarctica/DumontDUrville',
        timezone_el: 'Ανταρκτική/Ντουμόν_ντ_Υρβίλ',
        timezone_es: 'Antártida/DumontDUrville',
        timezone_it: 'Antarctique/DumontDUrville',
        timezone_fr: 'Antarctique/DumontDUrville',
        timezone_de: 'Antarktis/DumontDUrville',
        timezone_ca: 'Antàrtida/DumontDUrville',
        country_name: 'Antarctica',
        country_name_el: 'Ανταρκτική',
        country_name_es: 'Antártida',
        country_name_it: 'Antartide',
        country_name_fr: 'Antarctique',
        country_name_de: 'Antarktis',
        country_name_ca: 'Antàrtida',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'AQ',
        utc_offset: 13,
        timezone: 'Antarctica/Mawson',
        timezone_el: 'Ανταρκτική/Μούνσον',
        timezone_es: 'Antártida/Mawson',
        timezone_it: 'Antarctique/Mawson',
        timezone_fr: 'Antarctique/Mawson',
        timezone_de: 'Antarktis/Mawson',
        timezone_ca: 'Antàrtida/Mawson',
        country_name: 'Antarctica',
        country_name_el: 'Ανταρκτική',
        country_name_es: 'Antártida',
        country_name_it: 'Antartide',
        country_name_fr: 'Antarctique',
        country_name_de: 'Antarktis',
        country_name_ca: 'Antàrtida',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'AQ',
        utc_offset: 13,
        timezone: 'Antarctica/Palmer',
        timezone_el: 'Ανταρκτική/Πάλμερ',
        timezone_es: 'Antártida/Palmer',
        timezone_it: 'Antarctique/Palmer',
        timezone_fr: 'Antarctique/Palmer',
        timezone_de: 'Antarktis/Palmer',
        timezone_ca: 'Antàrtida/Palmer',
        country_name: 'Antarctica',
        country_name_el: 'Ανταρκτική',
        country_name_es: 'Antártida',
        country_name_it: 'Antartide',
        country_name_fr: 'Antarctique',
        country_name_de: 'Antarktis',
        country_name_ca: 'Antàrtida',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'AQ',
        utc_offset: 13,
        timezone: 'Antarctica/Rothera',
        timezone_el: 'Ανταρκτική/Ρούθερα',
        timezone_es: 'Antártida/Rothera',
        timezone_it: 'Antarctique/Rothera',
        timezone_fr: 'Antarctique/Rothera',
        timezone_de: 'Antarktis/Rothera',
        timezone_ca: 'Antàrtida/Rothera',
        country_name: 'Antarctica',
        country_name_el: 'Ανταρκτική',
        country_name_es: 'Antártida',
        country_name_it: 'Antartide',
        country_name_fr: 'Antarctique',
        country_name_de: 'Antarktis',
        country_name_ca: 'Antàrtida',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'AQ',
        utc_offset: 13,
        timezone: 'Antarctica/Syowa',
        timezone_el: 'Ανταρκτική/Σιόβα',
        timezone_es: 'Antártida/Syowa',
        timezone_it: 'América/Aruba',
        timezone_fr: 'Antarctique/Syowa',
        timezone_de: 'Antarktis/Syowa',
        timezone_ca: 'Antàrtida/Syowa',
        country_name: 'Antarctica',
        country_name_el: 'Ανταρκτική',
        country_name_es: 'Antártida',
        country_name_it: 'Antartide',
        country_name_fr: 'Antarctique',
        country_name_de: 'Antarktis',
        country_name_ca: 'Antàrtida',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'AQ',
        utc_offset: 13,
        timezone: 'Antarctica/Troll',
        timezone_el: 'Ανταρκτική/Τρολ',
        timezone_es: 'Antártida/Troll',
        timezone_it: 'Asia/Kabul',
        timezone_fr: 'Antarctique/Troll',
        timezone_de: 'Antarktis/Troll',
        timezone_ca: 'Antàrtida/Troll',
        country_name: 'Antarctica',
        country_name_el: 'Ανταρκτική',
        country_name_es: 'Antártida',
        country_name_it: 'Antartide',
        country_name_fr: 'Antarctique',
        country_name_de: 'Antarktis',
        country_name_ca: 'Antàrtida',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'AQ',
        utc_offset: 13,
        timezone: 'Antarctica/Vostok',
        timezone_el: 'Ανταρκτική/Βόστοκ',
        timezone_es: 'Antártida/Vostok',
        timezone_it: 'África/Luanda',
        timezone_fr: 'Antarctique/Vostok',
        timezone_de: 'Antarktis/Vostok',
        timezone_ca: 'Antàrtida/Vostok',
        country_name: 'Antarctica',
        country_name_el: 'Ανταρκτική',
        country_name_es: 'Antártida',
        country_name_it: 'Antartide',
        country_name_fr: 'Antarctique',
        country_name_de: 'Antarktis',
        country_name_ca: 'Antàrtida',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'TF',
        utc_offset: 5,
        timezone: 'Indian/Kerguelen',
        timezone_el: 'Ινδικό/Κέργκελεν',
        timezone_es: 'Islas_Kerguelen',
        timezone_it: 'América/Anguila',
        timezone_fr: 'Indien/Kerguelen',
        timezone_de: 'Indischer/Ozean/Kerguelen',
        timezone_ca: 'Índic/Kerguelen',
        country_name: 'French Southern and Antarctic Lands',
        country_name_el: 'Γαλλικά Νότια και Ανταρκτικά Εδάφη',
        country_name_es: 'Tierras Australes y Antárticas Francesas',
        country_name_it: 'Terre Australi e Antartiche Francesi',
        country_name_fr: 'Terres australes et antarctiques françaises',
        country_name_de: 'Französische Süd- und Antarktisgebiete',
        country_name_ca: 'Terres Australs i Antàrtiques Franceses',
        country_capital: 'Port-aux-Français',
        country_capital_el: 'Πορτ-ο-Φρανσέ',
        country_capital_es: 'Puerto Luis Felipe',
        country_capital_it: 'Port-aux-Français',
        country_capital_fr: 'Port-aux-Français',
        country_capital_de: 'Port-aux-Français',
        country_capital_ca: 'Port-aux-Français',
        country_continent: 'Antarctica',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'AG',
        utc_offset: -4,
        timezone: 'America/Antigua',
        timezone_el: 'Αμερική/Αντίγκουα',
        timezone_es: 'América/Antigua',
        timezone_it: 'Europa/Mariehamn',
        timezone_fr: 'Amérique/Antigua',
        timezone_de: 'Amerika/Antigua',
        timezone_ca: 'Amèrica/Antigua',
        country_name: 'Antigua and Barbuda',
        country_name_el: 'Αντίγκουα και Μπαρμπούντα',
        country_name_es: 'Antigua y Barbuda',
        country_name_it: 'Antigua e Barbuda',
        country_name_fr: 'Antigua-et-Barbuda',
        country_name_de: 'Antigua und Barbuda',
        country_name_ca: 'Antigua i Barbuda',
        country_capital: 'Saint John\'s',
        country_capital_el: 'Αγία Ιωάννα',
        country_capital_es: 'San Juan',
        country_capital_it: 'Saint John\'s',
        country_capital_fr: 'Saint John\'s',
        country_capital_de: 'Saint John\'s',
        country_capital_ca: 'Saint John\'s',
        country_continent: 'North America',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Nord-amèrica'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Lord_Howe',
        timezone_el: 'Αυστραλία/Λορντ_Χάου',
        timezone_es: 'Australia/Lord_Howe',
        timezone_it: 'Europa/Tirana',
        timezone_fr: 'Australie/Lord_Howe',
        timezone_de: 'Australien/Lord_Howe',
        timezone_ca: 'Austràlia/Lord_Howe',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Antarctica/Macquarie',
        timezone_el: 'Ανταρκτική/Μακουάρι',
        timezone_es: 'Antártida/Macquarie',
        timezone_it: 'Europa/Andorra',
        timezone_fr: 'Antarctique/Macquarie',
        timezone_de: 'Antarktis/Macquarie',
        timezone_ca: 'Antàrtida/Macquarie',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Hobart',
        timezone_el: 'Αυστραλία/Χόμπαρτ',
        timezone_es: 'Australia/Hobart',
        timezone_it: 'Asia/Dubái',
        timezone_fr: 'Australie/Hobart',
        timezone_de: 'Australien/Hobart',
        timezone_ca: 'Austràlia/Hobart',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Currie',
        timezone_el: 'Αυστραλία/Κέρι',
        timezone_es: 'Australia/Currie',
        timezone_it: 'América/Argentina/Buenos_Aires',
        timezone_fr: 'Australie/Currie',
        timezone_de: 'Australien/Currie',
        timezone_ca: 'Austràlia/Currie',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Melbourne',
        timezone_el: 'Αυστραλία/Μελβούρνη',
        timezone_es: 'Australia/Melbourne',
        timezone_it: 'América/Argentina/Córdoba',
        timezone_fr: 'Australie/Melbourne',
        timezone_de: 'Australien/Melbourne',
        timezone_ca: 'Austràlia/Melbourne',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Sydney',
        timezone_el: 'Αυστραλία/Σίδνεϊ',
        timezone_es: 'Australia/Sydney',
        timezone_it: 'América/Argentina/Salta',
        timezone_fr: 'Australie/Sydney',
        timezone_de: 'Australien/Sydney',
        timezone_ca: 'Austràlia/Sydney',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Broken_Hill',
        timezone_el: 'Αυστραλία/Μπρόκεν_Χιλ',
        timezone_es: 'Australia/Broken_Hill',
        timezone_it: 'América/Argentina/Jujuy',
        timezone_fr: 'Australie/Broken_Hill',
        timezone_de: 'Australien/Broken_Hill',
        timezone_ca: 'Austràlia/Broken_Hill',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Brisbane',
        timezone_el: 'Αυστραλία/Μπρισμπέιν',
        timezone_es: 'Australia/Brisbane',
        timezone_it: 'América/Argentina/Tucumán',
        timezone_fr: 'Australie/Brisbane',
        timezone_de: 'Australien/Brisbane',
        timezone_ca: 'Austràlia/Brisbane',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Lindeman',
        timezone_el: 'Αυστραλία/Λίντεμαν',
        timezone_es: 'Australia/Lindeman',
        timezone_it: 'América/Argentina/Catamarca',
        timezone_fr: 'Australie/Lindeman',
        timezone_de: 'Australien/Lindeman',
        timezone_ca: 'Austràlia/Lindeman',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Adelaide',
        timezone_el: 'Αυστραλία/Αδελαΐδα',
        timezone_es: 'Australia/Adelaida',
        timezone_it: 'América/Argentina/La_Rioja',
        timezone_fr: 'Australie/Adélaïde',
        timezone_de: 'Australien/Adelaide',
        timezone_ca: 'Austràlia/Adelaide',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Darwin',
        timezone_el: 'Αυστραλία/Ντάργουιν',
        timezone_es: 'Australia/Darwin',
        timezone_it: 'América/Argentina/San_Juan',
        timezone_fr: 'Australie/Darwin',
        timezone_de: 'Australien/Darwin',
        timezone_ca: 'Austràlia/Darwin',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Perth',
        timezone_el: 'Αυστραλία/Περθ',
        timezone_es: 'Australia/Perth',
        timezone_it: 'América/Argentina/Mendoza',
        timezone_fr: 'Australie/Perth',
        timezone_de: 'Australien/Perth',
        timezone_ca: 'Austràlia/Perth',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AU',
        utc_offset: 10,
        timezone: 'Australia/Eucla',
        timezone_el: 'Αυστραλία/Εούκλα',
        timezone_es: 'Australia/Eucla',
        timezone_it: 'América/Argentina/San_Luis',
        timezone_fr: 'Australie/Eucla',
        timezone_de: 'Australien/Eucla',
        timezone_ca: 'Austràlia/Eucla',
        country_name: 'Australia',
        country_name_el: 'Αυστραλία',
        country_name_es: 'Australia',
        country_name_it: 'Australia',
        country_name_fr: 'Australie',
        country_name_de: 'Australien',
        country_name_ca: 'Austràlia',
        country_capital: 'Canberra',
        country_capital_el: 'Κανμπέρα',
        country_capital_es: 'Canberra',
        country_capital_it: 'Canberra',
        country_capital_fr: 'Canberra',
        country_capital_de: 'Canberra',
        country_capital_ca: 'Cambra',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'AT',
        utc_offset: 1,
        timezone: 'Europe/Vienna',
        timezone_el: 'Ευρώπη/Βιέννη',
        timezone_es: 'Europa/Viena',
        timezone_it: 'América/Argentina/Río_Gallegos',
        timezone_fr: 'Europe/Vienne',
        timezone_de: 'Europa/Wien',
        timezone_ca: 'Europa/Viena',
        country_name: 'Austria',
        country_name_el: 'Αυστρία',
        country_name_es: 'Austria',
        country_name_it: 'Austria',
        country_name_fr: 'Autriche',
        country_name_de: 'Österreich',
        country_name_ca: 'Àustria',
        country_capital: 'Vienna',
        country_capital_el: 'Βιέννη',
        country_capital_es: 'Viena',
        country_capital_it: 'Vienna',
        country_capital_fr: 'Vienne',
        country_capital_de: 'Wien',
        country_capital_ca: 'Viena',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'AZ',
        utc_offset: 4,
        timezone: 'Asia/Baku',
        timezone_el: 'Ασία/Μπακού',
        timezone_es: 'Asia/Bakú',
        timezone_it: 'América/Argentina/Ushuaia',
        timezone_fr: 'Asie/Bakou',
        timezone_de: 'Asien/Baku',
        timezone_ca: 'Àsia/Baku',
        country_name: 'Azerbaijan',
        country_name_el: 'Αζερμπαϊτζάν',
        country_name_es: 'Azerbaiyán',
        country_name_it: 'Azerbaigian',
        country_name_fr: 'Azerbaïdjan',
        country_name_de: 'Aserbaidschan',
        country_name_ca: 'Azerbaidjan',
        country_capital: 'Baku',
        country_capital_el: 'Βακού',
        country_capital_es: 'Bakú',
        country_capital_it: 'Baku',
        country_capital_fr: 'Bakou',
        country_capital_de: 'Baku',
        country_capital_ca: 'Bakú',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'BI',
        utc_offset: 2,
        timezone: 'Africa/Bujumbura',
        timezone_el: 'Αφρική/Μπουζουμπούρα',
        timezone_es: 'África/Bujumbura',
        timezone_it: 'Asia/Ereván',
        timezone_fr: 'Afrique/Bujumbura',
        timezone_de: 'Afrika/Bujumbura',
        timezone_ca: 'Àfrica/Bujumbura',
        country_name: 'Burundi',
        country_name_el: 'Μπουρούντι',
        country_name_es: 'Burundi',
        country_name_it: 'Burundi',
        country_name_fr: 'Burundi',
        country_name_de: 'Burundi',
        country_name_ca: 'Burundi',
        country_capital: 'Bujumbura',
        country_capital_el: 'Μπουζουμπούρα',
        country_capital_es: 'Buyumbura',
        country_capital_it: 'Bujumbura',
        country_capital_fr: 'Bujumbura',
        country_capital_de: 'Bujumbura',
        country_capital_ca: 'Bujumbura',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'BE',
        utc_offset: 1,
        timezone: 'Europe/Brussels',
        timezone_el: 'Ευρώπη/Βρυξέλλες',
        timezone_es: 'Europa/Bruselas',
        timezone_it: 'Pacífico/Pago_Pago',
        timezone_fr: 'Europe/Bruxelles',
        timezone_de: 'Europa/Brussel',
        timezone_ca: 'Europa/Brussel·les',
        country_name: 'Belgium',
        country_name_el: 'Βέλγιο',
        country_name_es: 'Bélgica',
        country_name_it: 'Belgio',
        country_name_fr: 'Belgique',
        country_name_de: 'Belgien',
        country_name_ca: 'Bèlgica',
        country_capital: 'Brussels',
        country_capital_el: 'Βρυξέλλες',
        country_capital_es: 'Bruselas',
        country_capital_it: 'Bruxelles',
        country_capital_fr: 'Bruxelles',
        country_capital_de: 'Brüssel',
        country_capital_ca: 'Brussel·les',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'BJ',
        utc_offset: 1,
        timezone: 'Africa/Porto-Novo',
        timezone_el: 'Αφρική/Πόρτο_Νόβο',
        timezone_es: 'África/Porto-Novo',
        timezone_it: 'Antártida/McMurdo',
        timezone_fr: 'Afrique/Porto-Novo',
        timezone_de: 'Afrika/Porto-Novo',
        timezone_ca: 'Àfrica/Porto-Novo',
        country_name: 'Benin',
        country_name_el: 'Μπενίν',
        country_name_es: 'Benín',
        country_name_it: 'Benín',
        country_name_fr: 'Benín',
        country_name_de: 'Benín',
        country_name_ca: 'Benín',
        country_capital: 'Porto-Novo',
        country_capital_el: 'Πόρτο-Νόβο',
        country_capital_es: 'Puerto Novo',
        country_capital_it: 'Porto-Novo',
        country_capital_fr: 'Porto-Novo',
        country_capital_de: 'Porto-Novo',
        country_capital_ca: 'Porto-Novo',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'BF',
        utc_offset: 0,
        timezone: 'Africa/Ouagadougou',
        timezone_el: 'Αφρική/Ουαγκαντούγκου',
        timezone_es: 'África/Ouagadougou',
        timezone_it: 'Antártida/Casey',
        timezone_fr: 'Afrique/Ouagadougou',
        timezone_de: 'Afrika/Ouagadougou',
        timezone_ca: 'Àfrica/Ouagadougou',
        country_name: 'Burkina Faso',
        country_name_el: 'Μπουρκίνα Φάσο',
        country_name_es: 'Burkina Faso',
        country_name_it: 'Burkina Faso',
        country_name_fr: 'Burkina Faso',
        country_name_de: 'Burkina Faso',
        country_name_ca: 'Burkina Faso',
        country_capital: 'Ouagadougou',
        country_capital_el: 'Ουαγκαντούγκου',
        country_capital_es: 'Uagadugú',
        country_capital_it: 'Ouagadougou',
        country_capital_fr: 'Ouagadougou',
        country_capital_de: 'Ouagadougou',
        country_capital_ca: 'Uagadugú',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'BD',
        utc_offset: 6,
        timezone: 'Asia/Dhaka',
        timezone_el: 'Ασία/Ντάκα',
        timezone_es: 'Asia/Daca',
        timezone_it: 'Antártida/Davis',
        timezone_fr: 'Asie/Dacca',
        timezone_de: 'Asien/Dhaka',
        timezone_ca: 'Àsia/Dacca',
        country_name: 'Bangladesh',
        country_name_el: 'Μπανγκλαντές',
        country_name_es: 'Bangladesh',
        country_name_it: 'Bangladesh',
        country_name_fr: 'Bangladesh',
        country_name_de: 'Bangladesh',
        country_name_ca: 'Bangladesh',
        country_capital: 'Dhaka',
        country_capital_el: 'Ντάκα',
        country_capital_es: 'Daca',
        country_capital_it: 'Dhaka',
        country_capital_fr: 'Dacca',
        country_capital_de: 'Dhaka',
        country_capital_ca: 'Dacca',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'BG',
        utc_offset: 2,
        timezone: 'Europe/Sofia',
        timezone_el: 'Ευρώπη/Σόφια',
        timezone_es: 'Europa/Sofía',
        timezone_it: 'Antártida/DumontDUrville',
        timezone_fr: 'Europe/Sofia',
        timezone_de: 'Europa/Sofia',
        timezone_ca: 'Europa/Sofia',
        country_name: 'Bulgaria',
        country_name_el: 'Βουλγαρία',
        country_name_es: 'Bulgaria',
        country_name_it: 'Bulgaria',
        country_name_fr: 'Bulgaria',
        country_name_de: 'Bulgaria',
        country_name_ca: 'Bulgaria',
        country_capital: 'Sofia',
        country_capital_el: 'Σόφια',
        country_capital_es: 'Sofía',
        country_capital_it: 'Sofia',
        country_capital_fr: 'Sofia',
        country_capital_de: 'Sofia',
        country_capital_ca: 'Sofia',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'BH',
        utc_offset: 3,
        timezone: 'Asia/Bahrain',
        timezone_el: 'Ασία/Μπαχρέιν',
        timezone_es: 'Asia/Bahréin',
        timezone_it: 'Antártida/Mawson',
        timezone_fr: 'Asie/Bahreïn',
        timezone_de: 'Asien/Bahrain',
        timezone_ca: 'Àsia/Bahrain',
        country_name: 'Bahrain',
        country_name_el: 'Μπαχρέιν',
        country_name_es: 'Baréin',
        country_name_it: 'Bahrain',
        country_name_fr: 'Bahreïn',
        country_name_de: 'Bahrain',
        country_name_ca: 'Bahrain',
        country_capital: 'Manama',
        country_capital_el: 'Μανάμα',
        country_capital_es: 'Manama',
        country_capital_it: 'Manama',
        country_capital_fr: 'Manama',
        country_capital_de: 'Manama',
        country_capital_ca: 'Manama',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'BS',
        utc_offset: -5,
        timezone: 'America/Nassau',
        timezone_el: 'Αμερική/Νασάου',
        timezone_es: 'América/Nasáu',
        timezone_it: 'Antártida/Palmer',
        timezone_fr: 'Amérique/Nassau',
        timezone_de: 'Amerika/Nassau',
        timezone_ca: 'Amèrica/Nassau',
        country_name: 'Bahamas',
        country_name_el: 'Μπαχάμες',
        country_name_es: 'Bahamas',
        country_name_it: 'Bahamas',
        country_name_fr: 'Bahamas',
        country_name_de: 'Bahamas',
        country_name_ca: 'Bahamas',
        country_capital: 'Nassau',
        country_capital_el: 'Νασάου',
        country_capital_es: 'Nasáu',
        country_capital_it: 'Nassau',
        country_capital_fr: 'Nassau',
        country_capital_de: 'Nassau',
        country_capital_ca: 'Nassau',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Nord-amèrica'
    },
    {
        country_code: 'BA',
        utc_offset: 1,
        timezone: 'Europe/Sarajevo',
        timezone_el: 'Ευρώπη/Σαράγεβο',
        timezone_es: 'Europa/Sarajevo',
        timezone_it: 'Antártida/Rothera',
        timezone_fr: 'Europe/Sarajevo',
        timezone_de: 'Europa/Sarajevo',
        timezone_ca: 'Europa/Sarajevo',
        country_name: 'Bosnia and Herzegovina',
        country_name_el: 'Βοσνία και Ερζεγοβίνη',
        country_name_es: 'Bosnia y Herzegovina',
        country_name_it: 'Bosnia ed Erzegovina',
        country_name_fr: 'Bosnie-Herzégovine',
        country_name_de: 'Bosnien und Herzegowina',
        country_name_ca: 'Bòsnia i Hercegovina',
        country_capital: 'Sarajevo',
        country_capital_el: 'Σαράγεβο',
        country_capital_es: 'Sarajevo',
        country_capital_it: 'Sarajevo',
        country_capital_fr: 'Sarajevo',
        country_capital_de: 'Sarajevo',
        country_capital_ca: 'Sarajevo',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'BL',
        utc_offset: -4,
        timezone: 'America/St_Barthelemy',
        timezone_el: 'Αμερική/Άγιος_Βαρθολομαίος',
        timezone_es: 'América/San_Bartolomé',
        timezone_it: 'Antártida/Syowa',
        timezone_fr: 'Amérique/Saint-Barthélemy',
        timezone_de: 'Amerika/Saint_Barthelemy',
        timezone_ca: 'Amèrica/Saint_Barthelemy',
        country_name: 'Saint Barthélemy',
        country_name_el: 'Άγιος Βαρθολομαίος',
        country_name_es: 'San Bartolomé',
        country_name_it: 'Saint Barthélemy',
        country_name_fr: 'Saint-Barthélemy',
        country_name_de: 'Saint-Barthélemy',
        country_name_ca: 'Sant Bartomeu',
        country_capital: 'Gustavia',
        country_capital_el: 'Γκουστάβια',
        country_capital_es: 'Gustavia',
        country_capital_it: 'Gustavia',
        country_capital_fr: 'Gustavia',
        country_capital_de: 'Gustavia',
        country_capital_ca: 'Gustàvia',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'BY',
        utc_offset: 3,
        timezone: 'Europe/Minsk',
        timezone_el: 'Ευρώπη/Μινσκ',
        timezone_es: 'Europa/Minsk',
        timezone_it: 'Antártida/Troll',
        timezone_fr: 'Europe/Minsk',
        timezone_de: 'Europa/Minsk',
        timezone_ca: 'Europa/Minsk',
        country_name: 'Belarus',
        country_name_el: 'Λευκορωσία',
        country_name_es: 'Bielorrusia',
        country_name_it: 'Bielorussia',
        country_name_fr: 'Biélorussie',
        country_name_de: 'Weißrussland',
        country_name_ca: 'Bielorússia',
        country_capital: 'Minsk',
        country_capital_el: 'Μινσκ',
        country_capital_es: 'Minsk',
        country_capital_it: 'Minsk',
        country_capital_fr: 'Minsk',
        country_capital_de: 'Minsk',
        country_capital_ca: 'Minsk',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'BZ',
        utc_offset: -6,
        timezone: 'America/Belize',
        timezone_el: 'Αμερική/Μπελίζ',
        timezone_es: 'América/Belice',
        timezone_it: 'Antártida/Vostok',
        timezone_fr: 'Amérique/Belize',
        timezone_de: 'Amerika/Belize',
        timezone_ca: 'Amèrica/Belize',
        country_name: 'Belize',
        country_name_el: 'Μπελίζ',
        country_name_es: 'Belice',
        country_name_it: 'Belize',
        country_name_fr: 'Belize',
        country_name_de: 'Belize',
        country_name_ca: 'Belize',
        country_capital: 'Belmopan',
        country_capital_el: 'Μπελμοπάν',
        country_capital_es: 'Belmopán',
        country_capital_it: 'Belmopan',
        country_capital_fr: 'Belmopan',
        country_capital_de: 'Belmopan',
        country_capital_ca: 'Belmopán',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'BM',
        utc_offset: -4,
        timezone: 'Atlantic/Bermuda',
        timezone_el: 'Ατλαντικό/Βερμούδα',
        timezone_es: 'Atlántico/Bermudas',
        timezone_it: 'Índico/Kerguelen',
        timezone_fr: 'Atlantique/Bermudes',
        timezone_de: 'Atlantik/Bermuda',
        timezone_ca: 'Atlàntic/Bermudes',
        country_name: 'Bermuda',
        country_name_el: 'Βερμούδες',
        country_name_es: 'Bermudas',
        country_name_it: 'Bermuda',
        country_name_fr: 'Bermudes',
        country_name_de: 'Bermuda',
        country_name_ca: 'Bermudes',
        country_capital: 'Hamilton',
        country_capital_el: 'Χάμιλτον',
        country_capital_es: 'Hamilton',
        country_capital_it: 'Hamilton',
        country_capital_fr: 'Hamilton',
        country_capital_de: 'Hamilton',
        country_capital_ca: 'Hamilton',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'BO',
        utc_offset: -4,
        timezone: 'America/La_Paz',
        timezone_el: 'Αμερική/Λα_Παζ',
        timezone_es: 'América/La_Paz',
        timezone_it: 'América/Antigua',
        timezone_fr: 'Amérique/La_Paix',
        timezone_de: 'Amerika/La_Paz',
        timezone_ca: 'Amèrica/La_Pau',
        country_name: 'Bolivia',
        country_name_el: 'Βολιβία',
        country_name_es: 'Bolivia',
        country_name_it: 'Bolivia',
        country_name_fr: 'Bolivie',
        country_name_de: 'Bolivien',
        country_name_ca: 'Bolivia',
        country_capital: 'Sucre',
        country_capital_el: 'Σούκρε',
        country_capital_es: 'Sucre',
        country_capital_it: 'Sucre',
        country_capital_fr: 'Sucre',
        country_capital_de: 'Sucre',
        country_capital_ca: 'Sucre',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Noronha',
        timezone_el: 'Αμερική/Νορόνια',
        timezone_es: 'América/Noronha',
        timezone_it: 'Australia/Lord_Howe',
        timezone_fr: 'Amérique/Noronha',
        timezone_de: 'Amerika/Noronha',
        timezone_ca: 'Amèrica/Noronha',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Belem',
        timezone_el: 'Αμερική/Μπέλεμ',
        timezone_es: 'América/Belén',
        timezone_it: 'Antártida/Macquarie',
        timezone_fr: 'Amérique/Belém',
        timezone_de: 'Amerika/Belem',
        timezone_ca: 'Amèrica/Belem',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Fortaleza',
        timezone_el: 'Αμερική/Φορταλέζα',
        timezone_es: 'América/Fortaleza',
        timezone_it: 'Australia/Hobart',
        timezone_fr: 'Amérique/Fortaleza',
        timezone_de: 'Amerika/Fortaleza',
        timezone_ca: 'Amèrica/Fortaleza',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Recife',
        timezone_el: 'Αμερική/Ρεσίφε',
        timezone_es: 'América/Recife',
        timezone_it: 'Australia/Currie',
        timezone_fr: 'Amérique/Recife',
        timezone_de: 'Amerika/Recife',
        timezone_ca: 'Amèrica/Recife',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Araguaina',
        timezone_el: 'Αμερική/Αραγουαίνα',
        timezone_es: 'América/Araguaína',
        timezone_it: 'Australia/Melbourne',
        timezone_fr: 'Amérique/Araguaina',
        timezone_de: 'Amerika/Araguaina',
        timezone_ca: 'Amèrica/Araguaina',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Maceio',
        timezone_el: 'Αμερική/Μασέιο',
        timezone_es: 'América/Maceió',
        timezone_it: 'Australia/Sídney',
        timezone_fr: 'Amérique/Maceio',
        timezone_de: 'Amerika/Maceio',
        timezone_ca: 'Amèrica/Maceio',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Bahia',
        timezone_el: 'Αμερική/Μπαία',
        timezone_es: 'América/Bahía',
        timezone_it: 'Australia/Broken_Hill',
        timezone_fr: 'Amérique/Bahia',
        timezone_de: 'Amerika/Bahia',
        timezone_ca: 'Amèrica/Bahia',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Sao_Paulo',
        timezone_el: 'Αμερική/Σάο_Πάολο',
        timezone_es: 'América/Sao_Paulo',
        timezone_it: 'Australia/Brisbane',
        timezone_fr: 'Amérique/Sao_Paulo',
        timezone_de: 'Amerika/Sao_Paulo',
        timezone_ca: 'Amèrica/Sao_Paulo',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Campo_Grande',
        timezone_el: 'Αμερική/Κάμπο_Γκράντε',
        timezone_es: 'América/Campo_Grande',
        timezone_it: 'Australia/Lindeman',
        timezone_fr: 'Amérique/Campo_Grande',
        timezone_de: 'Amerika/Campo_Grande',
        timezone_ca: 'Amèrica/Campo_Grande',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Cuiaba',
        timezone_el: 'Αμερική/Κουίαμπα',
        timezone_es: 'América/Cuiabá',
        timezone_it: 'Australia/Adelaida',
        timezone_fr: 'Amérique/Cuiaba',
        timezone_de: 'Amerika/Cuiaba',
        timezone_ca: 'Amèrica/Cuiaba',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Santarem',
        timezone_el: 'Αμερική/Σανταρέμ',
        timezone_es: 'América/Santarém',
        timezone_it: 'Australia/Darwin',
        timezone_fr: 'Amérique/Santarem',
        timezone_de: 'Amerika/Santarem',
        timezone_ca: 'Amèrica/Santarem',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Porto_Velho',
        timezone_el: 'Αμερική/Πόρτο_Βέλιο',
        timezone_es: 'América/Porto_Velho',
        timezone_it: 'Australia/Perth',
        timezone_fr: 'Amérique/Porto_Velho',
        timezone_de: 'Amerika/Porto_Velho',
        timezone_ca: 'Amèrica/Porto_Velho',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Boa_Vista',
        timezone_el: 'Αμερική/Μπόα_Βίστα',
        timezone_es: 'América/Boa_Vista',
        timezone_it: 'Australia/Eucla',
        timezone_fr: 'Amérique/Boa_Vista',
        timezone_de: 'Amerika/Boa_Vista',
        timezone_ca: 'Amèrica/Boa_Vista',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Manaus',
        timezone_el: 'Αμερική/Μανάους',
        timezone_es: 'América/Manaus',
        timezone_it: 'Europa/Viena',
        timezone_fr: 'Amérique/Manaus',
        timezone_de: 'Amerika/Manaus',
        timezone_ca: 'Amèrica/Manaus',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'Sud America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Eirunepe',
        timezone_el: 'Αμερική/Ειρουνέπε',
        timezone_es: 'América/Eirunepé',
        timezone_it: 'Asia/Bakú',
        timezone_fr: 'Amérique/Eirunepe',
        timezone_de: 'Amerika/Eirunepe',
        timezone_ca: 'Amèrica/Eirunepe',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'BR',
        utc_offset: -5,
        timezone: 'America/Rio_Branco',
        timezone_el: 'Αμερική/Ρίο_Μπράνκο',
        timezone_es: 'América/Río_Branco',
        timezone_it: 'África/Bujumbura',
        timezone_fr: 'Amérique/Rio_Branco',
        timezone_de: 'Amerika/Rio_Branco',
        timezone_ca: 'Amèrica/Rio_Branco',
        country_name: 'Brazil',
        country_name_el: 'Βραζιλία',
        country_name_es: 'Brasil',
        country_name_it: 'Brasile',
        country_name_fr: 'Brésil',
        country_name_de: 'Brasilien',
        country_name_ca: 'Brasil',
        country_capital: 'Brasília',
        country_capital_el: 'Μπραζίλια',
        country_capital_es: 'Brasilia',
        country_capital_it: 'Brasilia',
        country_capital_fr: 'Brasilia',
        country_capital_de: 'Brasília',
        country_capital_ca: 'Brasília',
        country_continent: 'South America',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'BB',
        utc_offset: -4,
        timezone: 'America/Barbados',
        timezone_el: 'Αμερική/Μπαρμπάντος',
        timezone_es: 'América/Barbados',
        timezone_it: 'Europa/Bruselas',
        timezone_fr: 'Amérique/Barbados',
        timezone_de: 'Amerika/Barbados',
        timezone_ca: 'Amèrica/Barbados',
        country_name: 'Barbados',
        country_name_el: 'Μπαρμπάντος',
        country_name_es: 'Barbados',
        country_name_it: 'Barbados',
        country_name_fr: 'Barbade',
        country_name_de: 'Barbados',
        country_name_ca: 'Barbados',
        country_capital: 'Bridgetown',
        country_capital_el: 'Μπρίτζταουν',
        country_capital_es: 'Bridgetown',
        country_capital_it: 'Bridgetown',
        country_capital_fr: 'Bridgetown',
        country_capital_de: 'Bridgetown',
        country_capital_ca: 'Bridgetown',
        country_continent: 'North America',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'BN',
        utc_offset: 8,
        timezone: 'Asia/Brunei',
        timezone_el: 'Ασία/Μπρούνει',
        timezone_es: 'Asia/Brunei',
        timezone_it: 'África/Porto-Novo',
        timezone_fr: 'Asie/Brunei',
        timezone_de: 'Asien/Brunei',
        timezone_ca: 'Àsia/Brunei',
        country_name: 'Brunei',
        country_name_el: 'Μπρουνέι',
        country_name_es: 'Brunéi',
        country_name_it: 'Brunei',
        country_name_fr: 'Brunei',
        country_name_de: 'Brunei',
        country_name_ca: 'Brunéi',
        country_capital: 'Bandar Seri Begawan',
        country_capital_el: 'Μπαντάρ Σερί Μπεγκαουάν',
        country_capital_es: 'Bandar Seri Begawan',
        country_capital_it: 'Bandar Seri Begawan',
        country_capital_fr: 'Bandar Seri Begawan',
        country_capital_de: 'Bandar Seri Begawan',
        country_capital_ca: 'Bandar Seri Begawan',
        country_continent: 'Asia',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antartide',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'BT',
        utc_offset: 6,
        timezone: 'Asia/Thimphu',
        timezone_el: 'Ασία/Τίμπου',
        timezone_es: 'Asia/Thimphu',
        timezone_it: 'África/Ouagadougou',
        timezone_fr: 'Asie/Thimphu',
        timezone_de: 'Asien/Thimphu',
        timezone_ca: 'Àsia/Thimphu',
        country_name: 'Bhutan',
        country_name_el: 'Μπουτάν',
        country_name_es: 'Bután',
        country_name_it: 'Bhutan',
        country_name_fr: 'Bhoutan',
        country_name_de: 'Bhutan',
        country_name_ca: 'Bhutan',
        country_capital: 'Thimphu',
        country_capital_el: 'Τίμφου',
        country_capital_es: 'Timbu',
        country_capital_it: 'Thimphu',
        country_capital_fr: 'Thimphou',
        country_capital_de: 'Thimphu',
        country_capital_ca: 'Thimphu',
        country_continent: 'Asia',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'BV',
        utc_offset: 1,
        timezone: 'Europe/Oslo',
        timezone_el: 'Ευρώπη/Όσλο',
        timezone_es: 'Europa/Oslo',
        timezone_it: 'Asia/Daca',
        timezone_fr: 'Europe/Oslo',
        timezone_de: 'Europa/Oslo',
        timezone_ca: 'Europa/Oslo',
        country_name: 'Bouvet Island',
        country_name_el: 'Νήσος Μπουβέ',
        country_name_es: 'Isla Bouvet',
        country_name_it: 'Isola Bouvet',
        country_name_fr: 'Île Bouvet',
        country_name_de: 'Bouvetinsel',
        country_name_ca: 'Illa Bouvet',
        country_continent: 'Antarctica',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'BW',
        utc_offset: 2,
        timezone: 'Africa/Gaborone',
        timezone_el: 'Αφρική/Γαμπορόνε',
        timezone_es: 'África/Gaborone',
        timezone_it: 'Europa/Sofía',
        timezone_fr: 'Afrique/Gaborone',
        timezone_de: 'Afrika/Gaborone',
        timezone_ca: 'Àfrica/Gaborone',
        country_name: 'Botswana',
        country_name_el: 'Μποτσουάνα',
        country_name_es: 'Botsuana',
        country_name_it: 'Botswana',
        country_name_fr: 'Botswana',
        country_name_de: 'Botswana',
        country_name_ca: 'Botswana',
        country_capital: 'Gaborone',
        country_capital_el: 'Γκαμπορόνε',
        country_capital_es: 'Gaborone',
        country_capital_it: 'Gaborone',
        country_capital_fr: 'Gaborone',
        country_capital_de: 'Gaborone',
        country_capital_ca: 'Gaborone',
        country_continent: 'Africa',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CF',
        utc_offset: 1,
        timezone: 'Africa/Bangui',
        timezone_el: 'Αφρική/Μπανγκούι',
        timezone_es: 'África/Bangui',
        timezone_it: 'Asia/Bahréin',
        timezone_fr: 'Afrique/Bangui',
        timezone_de: 'Afrika/Bangui',
        timezone_ca: 'Àfrica/Bangui',
        country_name: 'Central African Republic',
        country_name_el: 'Κεντροαφρικανική Δημοκρατία',
        country_name_es: 'República Centroafricana',
        country_name_it: 'Repubblica Centrafricana',
        country_name_fr: 'République centrafricaine',
        country_name_de: 'Zentralafrikanische Republik',
        country_name_ca: 'República Centreafricana',
        country_capital: 'Bangui',
        country_capital_el: 'Μπανγκουί',
        country_capital_es: 'Bangui',
        country_capital_it: 'Bangui',
        country_capital_fr: 'Bangui',
        country_capital_de: 'Bangui',
        country_capital_ca: 'Bangui',
        country_continent: 'Africa',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/St_Johns',
        timezone_el: 'Αμερική/Σεν_Τζονς',
        timezone_es: 'América/San_Juan',
        timezone_it: 'América/Nasáu',
        timezone_fr: 'Amérique/St_Johns',
        timezone_de: 'Amerika/St_Johns',
        timezone_ca: 'Amèrica/St_Johns',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Halifax',
        timezone_el: 'Αμερική/Χάλιφαξ',
        timezone_es: 'América/Halifax',
        timezone_it: 'Europa/Sarajevo',
        timezone_fr: 'Amérique/Halifax',
        timezone_de: 'Amerika/Halifax',
        timezone_ca: 'Amèrica/Halifax',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Glace_Bay',
        timezone_el: 'Αμερική/Γκλέις_Μπέι',
        timezone_es: 'América/Glace_Bay',
        timezone_it: 'América/Glace_Bay',
        timezone_fr: 'Amérique/Glace_Bay',
        timezone_de: 'Amerika/Glace_Bay',
        timezone_ca: 'Amèrica/Glace_Bay',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Moncton',
        timezone_el: 'Αμερική/Μόνκτον',
        timezone_es: 'América/Moncton',
        timezone_it: 'América/Moncton',
        timezone_fr: 'Amérique/Moncton',
        timezone_de: 'Amerika/Moncton',
        timezone_ca: 'Amèrica/Moncton',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Goose_Bay',
        timezone_el: 'Αμερική/Γκους_Μπέι',
        timezone_es: 'América/Goose_Bay',
        timezone_it: 'América/Goose_Bay',
        timezone_fr: 'Amérique/Goose_Bay',
        timezone_de: 'Amerika/Goose_Bay',
        timezone_ca: 'Amèrica/Goose_Bay',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Blanc-Sablon',
        timezone_el: 'Αμερική/Μπλαν_Σαμπλόν',
        timezone_es: 'América/Blanc-Sablon',
        timezone_it: 'América/Blanc-Sablon',
        timezone_fr: 'Amérique/Blanc-Sablon',
        timezone_de: 'Amerika/Blanc-Sablon',
        timezone_ca: 'Amèrica/Blanc-Sablon',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Toronto',
        timezone_el: 'Αμερική/Τορόντο',
        timezone_es: 'América/Toronto',
        timezone_it: 'América/Toronto',
        timezone_fr: 'Amérique/Toronto',
        timezone_de: 'Amerika/Toronto',
        timezone_ca: 'Amèrica/Toronto',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Nipigon',
        timezone_el: 'Αμερική/Νίπιγκον',
        timezone_es: 'América/Nipigon',
        timezone_it: 'América/Nipigon',
        timezone_fr: 'Amérique/Nipigon',
        timezone_de: 'Amerika/Nipigon',
        timezone_ca: 'Amèrica/Nipigon',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Thunder_Bay',
        timezone_el: 'Αμερική/Θάντερ_Μπέι',
        timezone_es: 'América/Thunder_Bay',
        timezone_it: 'América/Thunder_Bay',
        timezone_fr: 'Amérique/Thunder_Bay',
        timezone_de: 'Amerika/Thunder_Bay',
        timezone_ca: 'Amèrica/Thunder_Bay',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Iqaluit',
        timezone_el: 'Αμερική/Ικαλούιτ',
        timezone_es: 'América/Iqaluit',
        timezone_it: 'América/Iqaluit',
        timezone_fr: 'Amérique/Iqaluit',
        timezone_de: 'Amerika/Iqaluit',
        timezone_ca: 'Amèrica/Iqaluit',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Pangnirtung',
        timezone_el: 'Αμερική/Πανγκνίρτουνγκ',
        timezone_es: 'América/Pangnirtung',
        timezone_it: 'América/Pangnirtung',
        timezone_fr: 'Amérique/Pangnirtung',
        timezone_de: 'Amerika/Pangnirtung',
        timezone_ca: 'Amèrica/Pangnirtung',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Atikokan',
        timezone_el: 'Αμερική/Άτικοκαν',
        timezone_es: 'América/Atikokan',
        timezone_it: 'América/Atikokan',
        timezone_fr: 'Amérique/Atikokan',
        timezone_de: 'Amerika/Atikokan',
        timezone_ca: 'Amèrica/Atikokan',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Winnipeg',
        timezone_el: 'Αμερική/Γουίνιπέγκ',
        timezone_es: 'América/Winnipeg',
        timezone_it: 'América/Winnipeg',
        timezone_fr: 'Amérique/Winnipeg',
        timezone_de: 'Amerika/Winnipeg',
        timezone_ca: 'Amèrica/Winnipeg',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Rainy_River',
        timezone_el: 'Αμερική/Ρέινι_Ρίβερ',
        timezone_es: 'América/Rainy_River',
        timezone_it: 'América/Rainy_River',
        timezone_fr: 'Amérique/Rainy_River',
        timezone_de: 'Amerika/Rainy_River',
        timezone_ca: 'Amèrica/Rainy_River',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Resolute',
        timezone_el: 'Αμερική/Ρέζολιουτ',
        timezone_es: 'América/Resolute',
        timezone_it: 'América/Resolute',
        timezone_fr: 'Amérique/Resolute',
        timezone_de: 'Amerika/Resolute',
        timezone_ca: 'Amèrica/Resolute',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Rankin_Inlet',
        timezone_el: 'Αμερική/Ράνκιν_Ίνλετ',
        timezone_es: 'América/Rankin_Inlet',
        timezone_it: 'América/Rankin_Inlet',
        timezone_fr: 'Amérique/Rankin_Inlet',
        timezone_de: 'Amerika/Rankin_Inlet',
        timezone_ca: 'Amèrica/Rankin_Inlet',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Regina',
        timezone_el: 'Αμερική/Ρετζάινα',
        timezone_es: 'América/Regina',
        timezone_it: 'América/Regina',
        timezone_fr: 'Amérique/Régina',
        timezone_de: 'Amerika/Regina',
        timezone_ca: 'Amèrica/Regina',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Swift_Current',
        timezone_el: 'Αμερική/Σουίφτ_Κάρεντ',
        timezone_es: 'América/Swift_Current',
        timezone_it: 'América/Swift_Current',
        timezone_fr: 'Amérique/Swift_Current',
        timezone_de: 'Amerika/Swift_Current',
        timezone_ca: 'Amèrica/Swift_Current',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Edmonton',
        timezone_el: 'Αμερική/Έντμοντον',
        timezone_es: 'América/Edmonton',
        timezone_it: 'América/Edmonton',
        timezone_fr: 'Amérique/Edmonton',
        timezone_de: 'Amerika/Edmonton',
        timezone_ca: 'Amèrica/Edmonton',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Cambridge_Bay',
        timezone_el: 'Αμερική/Κέιμπριτζ_Μπέι',
        timezone_es: 'América/Cambridge_Bay',
        timezone_it: 'América/Cambridge_Bay',
        timezone_fr: 'Amérique/Cambridge_Bay',
        timezone_de: 'Amerika/Cambridge_Bay',
        timezone_ca: 'Amèrica/Cambridge_Bay',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Yellowknife',
        timezone_el: 'Αμερική/Γέλοου_Κνάιφ',
        timezone_es: 'América/Yellowknife',
        timezone_it: 'América/Yellowknife',
        timezone_fr: 'Amérique/Yellowknife',
        timezone_de: 'Amerika/Yellowknife',
        timezone_ca: 'Amèrica/Yellowknife',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Inuvik',
        timezone_el: 'Αμερική/Ίνουβικ',
        timezone_es: 'América/Inuvik',
        timezone_it: 'América/Inuvik',
        timezone_fr: 'Amérique/Inuvik',
        timezone_de: 'Amerika/Inuvik',
        timezone_ca: 'Amèrica/Inuvik',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Creston',
        timezone_el: 'Αμερική/Κρέστον',
        timezone_es: 'América/Creston',
        timezone_it: 'América/Creston',
        timezone_fr: 'Amérique/Creston',
        timezone_de: 'Amerika/Creston',
        timezone_ca: 'Amèrica/Creston',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Dawson_Creek',
        timezone_el: 'Αμερική/Ντόσον_Κρικ',
        timezone_es: 'América/Dawson_Creek',
        timezone_it: 'América/Dawson_Creek',
        timezone_fr: 'Amérique/Dawson_Creek',
        timezone_de: 'Amerika/Dawson_Creek',
        timezone_ca: 'Amèrica/Dawson_Creek',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Fort_Nelson',
        timezone_el: 'Αμερική/Φορτ_Νέλσον',
        timezone_es: 'América/Fort_Nelson',
        timezone_it: 'América/Fort_Nelson',
        timezone_fr: 'Amérique/Fort_Nelson',
        timezone_de: 'Amerika/Fort_Nelson',
        timezone_ca: 'Amèrica/Fort_Nelson',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Vancouver',
        timezone_el: 'Αμερική/Βανκούβερ',
        timezone_es: 'América/Vancouver',
        timezone_it: 'América/Vancouver',
        timezone_fr: 'Amérique/Vancouver',
        timezone_de: 'Amerika/Vancouver',
        timezone_ca: 'Amèrica/Vancouver',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Whitehorse',
        timezone_el: 'Αμερική/Γουάιτχορς',
        timezone_es: 'América/Whitehorse',
        timezone_it: 'América/Whitehorse',
        timezone_fr: 'Amérique/Whitehorse',
        timezone_de: 'Amerika/Whitehorse',
        timezone_ca: 'Amèrica/Whitehorse',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CA',
        utc_offset: -6,
        timezone: 'America/Dawson',
        timezone_el: 'Αμερική/Ντόσον',
        timezone_es: 'América/Dawson',
        timezone_it: 'América/Dawson',
        timezone_fr: 'Amérique/Dawson',
        timezone_de: 'Amerika/Dawson',
        timezone_ca: 'Amèrica/Dawson',
        country_name: 'Canada',
        country_name_el: 'Καναδάς',
        country_name_es: 'Canadá',
        country_name_it: 'Canada',
        country_name_fr: 'Canada',
        country_name_de: 'Kanada',
        country_name_ca: 'Canadà',
        country_capital: 'Ottawa',
        country_capital_el: 'Ότταβα',
        country_capital_es: 'Ottawa',
        country_capital_it: 'Ottawa',
        country_capital_fr: 'Ottawa',
        country_capital_de: 'Ottawa',
        country_capital_ca: 'Ottawa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'Nord America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CC',
        utc_offset: 44991,
        timezone: 'Indian/Cocos',
        timezone_el: 'Ινδικό/Κόκος',
        timezone_es: 'Islas_Cocos',
        timezone_it: 'Indian/Cocos',
        timezone_fr: 'Îles_Cocos',
        timezone_de: 'Indischer/Ozean/Kokosinseln',
        timezone_ca: 'Indic/Cocos',
        country_name: 'Cocos (Keeling) Islands',
        country_name_el: 'Νησιά Κόκος (Κίλινγκ)',
        country_name_es: 'Islas Cocos (Keeling)',
        country_name_it: 'Isole Cocos (Keeling)',
        country_name_fr: 'Îles Cocos (Keeling)',
        country_name_de: 'Kokosinseln (Keeling)',
        country_name_ca: 'Illes Cocos (Keeling)',
        country_capital: 'West Island',
        country_capital_el: 'Δυτική Νήσος',
        country_capital_es: 'Isla del Oeste',
        country_capital_it: 'Isola dell\'Ovest',
        country_capital_fr: 'Île de l\'Ouest',
        country_capital_de: 'Westinsel',
        country_capital_ca: 'Illa de l\'Oest',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'CH',
        utc_offset: 1,
        timezone: 'Europe/Zurich',
        timezone_el: 'Ευρώπη/Ζυρίχη',
        timezone_es: 'Europa/Zúrich',
        timezone_it: 'Europa/Zúrich',
        timezone_fr: 'Europe/Zurich',
        timezone_de: 'Europa/Zurich',
        timezone_ca: 'Europa/Zuric',
        country_name: 'Switzerland',
        country_name_el: 'Ελβετία',
        country_name_es: 'Suiza',
        country_name_it: 'Svizzera',
        country_name_fr: 'Suisse',
        country_name_de: 'Schweiz',
        country_name_ca: 'Suïssa',
        country_capital: 'Bern',
        country_capital_el: 'Βέρνη',
        country_capital_es: 'Berna',
        country_capital_it: 'Berna',
        country_capital_fr: 'Berne',
        country_capital_de: 'Bern',
        country_capital_ca: 'Berna',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'CL',
        utc_offset: -3,
        timezone: 'America/Santiago',
        timezone_el: 'Αμερική/Σαντιάγκο',
        timezone_es: 'América/Santiago',
        timezone_it: 'América/Santiago',
        timezone_fr: 'Amérique/Santiago',
        timezone_de: 'Amerika/Santiago',
        timezone_ca: 'Amèrica/Santiago',
        country_name: 'Chile',
        country_name_el: 'Χιλή',
        country_name_es: 'Chile',
        country_name_it: 'Cile',
        country_name_fr: 'Chili',
        country_name_de: 'Chile',
        country_name_ca: 'Xile',
        country_capital: 'Santiago',
        country_capital_el: 'Σαντιάγο',
        country_capital_es: 'Santiago',
        country_capital_it: 'Santiago',
        country_capital_fr: 'Santiago',
        country_capital_de: 'Santiago',
        country_capital_ca: 'Santiago',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'America del Sud',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'CL',
        utc_offset: -3,
        timezone: 'Pacific/Easter',
        timezone_el: 'Ειρηνικός/Πάσχα',
        timezone_es: 'Pacífico/Isla_de_Pascua',
        timezone_it: 'Pacífico/Isla_de_Pascua',
        timezone_fr: 'Pacifique/Pâques',
        timezone_de: 'Pazifik/Osterinsel',
        timezone_ca: 'Pacífic/Illes_de_Pasqua',
        country_name: 'Chile',
        country_name_el: 'Χιλή',
        country_name_es: 'Chile',
        country_name_it: 'Cile',
        country_name_fr: 'Chili',
        country_name_de: 'Chile',
        country_name_ca: 'Xile',
        country_capital: 'Santiago',
        country_capital_el: 'Σαντιάγο',
        country_capital_es: 'Santiago',
        country_capital_it: 'Santiago',
        country_capital_fr: 'Santiago',
        country_capital_de: 'Santiago',
        country_capital_ca: 'Santiago',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'America del Sud',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'CN',
        utc_offset: 8,
        timezone: 'Asia/Shanghai',
        timezone_el: 'Ασία/Σανγκάι',
        timezone_es: 'Asia/Shanghái',
        timezone_it: 'Asia/Shanghái',
        timezone_fr: 'Asie/Shanghai',
        timezone_de: 'Asien/Shanghai',
        timezone_ca: 'Àsia/Xangai',
        country_name: 'China',
        country_name_el: 'Κίνα',
        country_name_es: 'China',
        country_name_it: 'Cina',
        country_name_fr: 'Chine',
        country_name_de: 'China',
        country_name_ca: 'Xina',
        country_capital: 'Beijing',
        country_capital_el: 'Πεκίνο',
        country_capital_es: 'Pekín',
        country_capital_it: 'Pechino',
        country_capital_fr: 'Pékin',
        country_capital_de: 'Peking',
        country_capital_ca: 'Pequín',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'CN',
        utc_offset: 8,
        timezone: 'Asia/Urumqi',
        timezone_el: 'Ασία/Ουρούμτσι',
        timezone_es: 'Asia/Urumqi',
        timezone_it: 'Asia/Urumqi',
        timezone_fr: 'Asie/Urumqi',
        timezone_de: 'Asien/Urumqi',
        timezone_ca: 'Àsia/Urumqi',
        country_name: 'China',
        country_name_el: 'Κίνα',
        country_name_es: 'China',
        country_name_it: 'Cina',
        country_name_fr: 'Chine',
        country_name_de: 'China',
        country_name_ca: 'Xina',
        country_capital: 'Beijing',
        country_capital_el: 'Πεκίνο',
        country_capital_es: 'Pekín',
        country_capital_it: 'Pechino',
        country_capital_fr: 'Pékin',
        country_capital_de: 'Peking',
        country_capital_ca: 'Pequín',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'CI',
        utc_offset: 0,
        timezone: 'Africa/Abidjan',
        timezone_el: 'Αφρική/Αμπιτζάν',
        timezone_es: 'África/Abiyán',
        timezone_it: 'África/Abiyán',
        timezone_fr: 'Afrique/Abidjan',
        timezone_de: 'Afrika/Abidjan',
        timezone_ca: 'Àfrica/Abidjan',
        country_name: 'Ivory Coast',
        country_name_el: 'Ακτή Ελεφαντοστού',
        country_name_es: 'Costa de Marfil',
        country_name_it: 'Costa d\'Avorio',
        country_name_fr: 'Côte d\'Ivoire',
        country_name_de: 'Elfenbeinküste',
        country_name_ca: 'Costa d\'Ivori',
        country_capital: 'Yamoussoukro',
        country_capital_el: 'Γιαμουσουκρό',
        country_capital_es: 'Yamusukro',
        country_capital_it: 'Yamoussoukro',
        country_capital_fr: 'Yamoussoukro',
        country_capital_de: 'Yamoussoukro',
        country_capital_ca: 'Yamussukro',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'CM',
        utc_offset: 1,
        timezone: 'Africa/Douala',
        timezone_el: 'Αφρική/Ντουάλα',
        timezone_es: 'África/Duala',
        timezone_it: 'África/Duala',
        timezone_fr: 'Afrique/Douala',
        timezone_de: 'Afrika/Douala',
        timezone_ca: 'Àfrica/Douala',
        country_name: 'Cameroon',
        country_name_el: 'Καμερούν',
        country_name_es: 'Camerún',
        country_name_it: 'Camerun',
        country_name_fr: 'Cameroun',
        country_name_de: 'Kamerun',
        country_name_ca: 'Camerun',
        country_capital: 'Yaoundé',
        country_capital_el: 'Γιαουντέ',
        country_capital_es: 'Yaundé',
        country_capital_it: 'Yaoundé',
        country_capital_fr: 'Yaoundé',
        country_capital_de: 'Jaunde',
        country_capital_ca: 'Iaundé',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'CD',
        utc_offset: 1,
        timezone: 'Africa/Kinshasa',
        timezone_el: 'Αφρική/Κινσάσα',
        timezone_es: 'África/Kinshasa',
        timezone_it: 'África/Kinshasa',
        timezone_fr: 'Afrique/Kinshasa',
        timezone_de: 'Afrika/Kinshasa',
        timezone_ca: 'Àfrica/Kinshasa',
        country_name: 'DR Congo',
        country_name_el: 'Λαϊκή Δημοκρατία του Κονγκό',
        country_name_es: 'República Democrática del Congo',
        country_name_it: 'Repubblica Democratica del Congo',
        country_name_fr: 'République démocratique du Congo',
        country_name_de: 'Demokratische Republik Kongo',
        country_name_ca: 'República Democràtica del Congo',
        country_capital: 'Kinshasa',
        country_capital_el: 'Κινσάσα',
        country_capital_es: 'Kinshasa',
        country_capital_it: 'Kinshasa',
        country_capital_fr: 'Kinshasa',
        country_capital_de: 'Kinshasa',
        country_capital_ca: 'Kinsasa',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'CD',
        utc_offset: 1,
        timezone: 'Africa/Lubumbashi',
        timezone_el: 'Αφρική/Λουμπουμπάσι',
        timezone_es: 'África/Lubumbashi',
        timezone_it: 'África/Lubumbashi',
        timezone_fr: 'Afrique/Lubumbashi',
        timezone_de: 'Afrika/Lubumbashi',
        timezone_ca: 'Àfrica/Lubumbashi',
        country_name: 'DR Congo',
        country_name_el: 'Λαϊκή Δημοκρατία του Κονγκό',
        country_name_es: 'República Democrática del Congo',
        country_name_it: 'Repubblica Democratica del Congo',
        country_name_fr: 'République démocratique du Congo',
        country_name_de: 'Demokratische Republik Kongo',
        country_name_ca: 'República Democràtica del Congo',
        country_capital: 'Kinshasa',
        country_capital_el: 'Κινσάσα',
        country_capital_es: 'Kinshasa',
        country_capital_it: 'Kinshasa',
        country_capital_fr: 'Kinshasa',
        country_capital_de: 'Kinshasa',
        country_capital_ca: 'Kinsasa',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'CG',
        utc_offset: 1,
        timezone: 'Africa/Brazzaville',
        timezone_el: 'Αφρική/Μπραζαβίλ',
        timezone_es: 'África/Brazzaville',
        timezone_it: 'África/Brazzaville',
        timezone_fr: 'Afrique/Brazzaville',
        timezone_de: 'Afrika/Brazzaville',
        timezone_ca: 'Àfrica/Brazzaville',
        country_name: 'Republic of the Congo',
        country_name_el: 'Δημοκρατία του Κονγκό',
        country_name_es: 'República del Congo',
        country_name_it: 'Repubblica del Congo',
        country_name_fr: 'République du Congo',
        country_name_de: 'Republik Kongo',
        country_name_ca: 'República del Congo',
        country_capital: 'Brazzaville',
        country_capital_el: 'Μπραζαβίλ',
        country_capital_es: 'Brazaville',
        country_capital_it: 'Brazzaville',
        country_capital_fr: 'Brazzaville',
        country_capital_de: 'Brazzaville',
        country_capital_ca: 'Brazzaville',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'CK',
        utc_offset: -10,
        timezone: 'Pacific/Rarotonga',
        timezone_el: 'Ειρηνικός/Ραροτόνγκα',
        timezone_es: 'Pacífico/Rarotonga',
        timezone_it: 'Pacífico/Rarotonga',
        timezone_fr: 'Pacifique/Rarotonga',
        timezone_de: 'Pazifik/Rarotonga',
        timezone_ca: 'Pacífic/Rarotonga',
        country_name: 'Cook Islands',
        country_name_el: 'Νήσοι Κουκ',
        country_name_es: 'Islas Cook',
        country_name_it: 'Isole Cook',
        country_name_fr: 'Îles Cook',
        country_name_de: 'Cookinseln',
        country_name_ca: 'Illes Cook',
        country_capital: 'Avarua',
        country_capital_el: 'Αβαρούα',
        country_capital_es: 'Avarua',
        country_capital_it: 'Avarua',
        country_capital_fr: 'Avarua',
        country_capital_de: 'Avarua',
        country_capital_ca: 'Avarua',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'CO',
        utc_offset: -5,
        timezone: 'America/Bogota',
        timezone_el: 'Αμερική/Μπογκοτά',
        timezone_es: 'América/Bogotá',
        timezone_it: 'América/Bogotá',
        timezone_fr: 'Amérique/Bogota',
        timezone_de: 'Amerika/Bogota',
        timezone_ca: 'Amèrica/Bogotà',
        country_name: 'Colombia',
        country_name_el: 'Κολομβία',
        country_name_es: 'Colombia',
        country_name_it: 'Colombia',
        country_name_fr: 'Colombie',
        country_name_de: 'Kolumbien',
        country_name_ca: 'Colòmbia',
        country_capital: 'Bogotá',
        country_capital_el: 'Μπογκοτά',
        country_capital_es: 'Bogotá',
        country_capital_it: 'Bogotà',
        country_capital_fr: 'Bogota',
        country_capital_de: 'Bogotá',
        country_capital_ca: 'Bogotà',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'America del Sud',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'KM',
        utc_offset: 3,
        timezone: 'Indian/Comoro',
        timezone_el: 'Ινδικό/Κομόρο',
        timezone_es: 'Comoras/Comoros',
        timezone_it: 'Indico/Comoras',
        timezone_fr: 'Comores',
        timezone_de: 'Indischer/Ozean/Komoren',
        timezone_ca: 'Índic/Comores',
        country_name: 'Comoros',
        country_name_el: 'Κομόρες',
        country_name_es: 'Comoras',
        country_name_it: 'Comore',
        country_name_fr: 'Comores',
        country_name_de: 'Komoren',
        country_name_ca: 'Comores',
        country_capital: 'Moroni',
        country_capital_el: 'Μορόνι',
        country_capital_es: 'Moroni',
        country_capital_it: 'Moroni',
        country_capital_fr: 'Moroni',
        country_capital_de: 'Moroni',
        country_capital_ca: 'Moroni',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'CV',
        utc_offset: -1,
        timezone: 'Atlantic/Cape_Verde',
        timezone_el: 'Ατλαντικό/Πράσινο_Ακρωτήρι',
        timezone_es: 'Atlántico/Cabo_Verde',
        timezone_it: 'Atlántico/Cabo_Verde',
        timezone_fr: 'Atlantique/Cap_Vert',
        timezone_de: 'Atlantik/Kap_Verde',
        timezone_ca: 'Atlàntic/Cabo_Verde',
        country_name: 'Cape Verde',
        country_name_el: 'Πράσινο Ακρωτήριο',
        country_name_es: 'Cabo Verde',
        country_name_it: 'Capo Verde',
        country_name_fr: 'Cap-Vert',
        country_name_de: 'Kap Verde',
        country_name_ca: 'Cap Verd',
        country_capital: 'Praia',
        country_capital_el: 'Πράια',
        country_capital_es: 'Praia',
        country_capital_it: 'Praia',
        country_capital_fr: 'Praia',
        country_capital_de: 'Praia',
        country_capital_ca: 'Praia',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'CR',
        utc_offset: -6,
        timezone: 'America/Costa_Rica',
        timezone_el: 'Αμερική/Κόστα_Ρίκα',
        timezone_es: 'América/Costa_Rica',
        timezone_it: 'América/Costa_Rica',
        timezone_fr: 'Amérique/Costa_Rica',
        timezone_de: 'Amerika/Costa_Rica',
        timezone_ca: 'Amèrica/Costa_Rica',
        country_name: 'Costa Rica',
        country_name_el: 'Κόστα Ρίκα',
        country_name_es: 'Costa Rica',
        country_name_it: 'Costa Rica',
        country_name_fr: 'Costa Rica',
        country_name_de: 'Costa Rica',
        country_name_ca: 'Costa Rica',
        country_capital: 'San José',
        country_capital_el: 'Σαν Χοσέ',
        country_capital_es: 'San José',
        country_capital_it: 'San José',
        country_capital_fr: 'San José',
        country_capital_de: 'San José',
        country_capital_ca: 'San José',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CU',
        utc_offset: -5,
        timezone: 'America/Havana',
        timezone_el: 'Αμερική/Αβάνα',
        timezone_es: 'América/La_Habana',
        timezone_it: 'América/La_Habana',
        timezone_fr: 'Amérique/La_Havane',
        timezone_de: 'Amerika/Havanna',
        timezone_ca: 'Amèrica/La_Havana',
        country_name: 'Cuba',
        country_name_el: 'Κούβα',
        country_name_es: 'Cuba',
        country_name_it: 'Cuba',
        country_name_fr: 'Cuba',
        country_name_de: 'Kuba',
        country_name_ca: 'Cuba',
        country_capital: 'Havana',
        country_capital_el: 'Αβάνα',
        country_capital_es: 'La Habana',
        country_capital_it: 'L\'Avana',
        country_capital_fr: 'La Havane',
        country_capital_de: 'Havanna',
        country_capital_ca: 'L\'Havana',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CW',
        utc_offset: -4,
        timezone: 'America/Curacao',
        timezone_el: 'Αμερική/Κουρασάο',
        timezone_es: 'América/Curazao',
        timezone_it: 'América/Curazao',
        timezone_fr: 'Amérique/Curaçao',
        timezone_de: 'Amerika/Curacao',
        timezone_ca: 'Amèrica/Curaçao',
        country_name: 'Curaçao',
        country_name_el: 'Κουρασάο',
        country_name_es: 'Curazao',
        country_name_it: 'Curaçao',
        country_name_fr: 'Curaçao',
        country_name_de: 'Curaçao',
        country_name_ca: 'Curaçao',
        country_capital: 'Willemstad',
        country_capital_el: 'Ουίλεμσταντ',
        country_capital_es: 'Willemstad',
        country_capital_it: 'Willemstad',
        country_capital_fr: 'Willemstad',
        country_capital_de: 'Willemstad',
        country_capital_ca: 'Willemstad',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'America del Sud',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'CX',
        utc_offset: 7,
        timezone: 'Indian/Christmas',
        timezone_el: 'Ινδικό/Χριστούγεννα',
        timezone_es: 'Isla_de_Navidad',
        timezone_it: 'Indico/Navidad',
        timezone_fr: 'Noël',
        timezone_de: 'Indischer/Ozean/Weihnachtsinsel',
        timezone_ca: 'Índic/Nadal',
        country_name: 'Christmas Island',
        country_name_el: 'Νήσος των Χριστουγέννων',
        country_name_es: 'Isla de Navidad',
        country_name_it: 'Isola di Natale',
        country_name_fr: 'Île Christmas',
        country_name_de: 'Weihnachtsinsel',
        country_name_ca: 'Illa de Nadal',
        country_capital: 'Flying Fish Cove',
        country_capital_el: 'Φλάιινγκ Φις Τσόουβ',
        country_capital_es: 'Flying Fish Cove',
        country_capital_it: 'Flying Fish Cove',
        country_capital_fr: 'Flying Fish Cove',
        country_capital_de: 'Flying Fish Cove',
        country_capital_ca: 'Flying Fish Cove',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'KY',
        utc_offset: -5,
        timezone: 'America/Cayman',
        timezone_el: 'Αμερική/Κέιμαν',
        timezone_es: 'Islas_Caimán',
        timezone_it: 'América/Cayman',
        timezone_fr: 'Amérique/Caïmans',
        timezone_de: 'Amerika/Cayman',
        timezone_ca: 'Amèrica/Caïmans',
        country_name: 'Cayman Islands',
        country_name_el: 'Νησιά Καίμαν',
        country_name_es: 'Islas Caimán',
        country_name_it: 'Isole Cayman',
        country_name_fr: 'Îles Caïmans',
        country_name_de: 'Kaimaninseln',
        country_name_ca: 'Illes Caiman',
        country_capital: 'George Town',
        country_capital_el: 'Τζορτζ Τάουν',
        country_capital_es: 'George Town',
        country_capital_it: 'George Town',
        country_capital_fr: 'George Town',
        country_capital_de: 'George Town',
        country_capital_ca: 'George Town',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'CY',
        utc_offset: 2,
        timezone: 'Asia/Nicosia',
        timezone_el: 'Ασία/Λευκωσία',
        timezone_es: 'Asia/Nicosia',
        timezone_it: 'Asia/Nicosia',
        timezone_fr: 'Asie/Nicosie',
        timezone_de: 'Asien/Nikosia',
        timezone_ca: 'Àsia/Nicosia',
        country_name: 'Cyprus',
        country_name_el: 'Κύπρος',
        country_name_es: 'Chipre',
        country_name_it: 'Cipro',
        country_name_fr: 'Chypre',
        country_name_de: 'Zypern',
        country_name_ca: 'Xipre',
        country_capital: 'Nicosia',
        country_capital_el: 'Λευκωσία',
        country_capital_es: 'Nicosia',
        country_capital_it: 'Nicosia',
        country_capital_fr: 'Nicosie',
        country_capital_de: 'Nikosia',
        country_capital_ca: 'Nicòsia',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'CZ',
        utc_offset: 1,
        timezone: 'Europe/Prague',
        timezone_el: 'Ευρώπη/Πράγα',
        timezone_es: 'Europa/Praga',
        timezone_it: 'Europa/Praga',
        timezone_fr: 'Europe/Prague',
        timezone_de: 'Europa/Prag',
        timezone_ca: 'Europa/Praga',
        country_name: 'Czech Republic',
        country_name_el: 'Τσεχική Δημοκρατία',
        country_name_es: 'República Checa',
        country_name_it: 'Repubblica Ceca',
        country_name_fr: 'République tchèque',
        country_name_de: 'Tschechische Republik',
        country_name_ca: 'República Txeca',
        country_capital: 'Prague',
        country_capital_el: 'Πράγα',
        country_capital_es: 'Praga',
        country_capital_it: 'Praga',
        country_capital_fr: 'Prague',
        country_capital_de: 'Prag',
        country_capital_ca: 'Praga',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'DE',
        utc_offset: 1,
        timezone: 'Europe/Berlin',
        timezone_el: 'Ευρώπη/Βερολίνο',
        timezone_es: 'Europa/Berlín',
        timezone_it: 'Europa/Berlín',
        timezone_fr: 'Europe/Berlin',
        timezone_de: 'Europa/Berlin',
        timezone_ca: 'Europa/Berlín',
        country_name: 'Germany',
        country_name_el: 'Γερμανία',
        country_name_es: 'Alemania',
        country_name_it: 'Germania',
        country_name_fr: 'Allemagne',
        country_name_de: 'Deutschland',
        country_name_ca: 'Alemanya',
        country_capital: 'Berlin',
        country_capital_el: 'Βερολίνο',
        country_capital_es: 'Berlín',
        country_capital_it: 'Berlino',
        country_capital_fr: 'Berlin',
        country_capital_de: 'Berlin',
        country_capital_ca: 'Berlín',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'DE',
        utc_offset: 1,
        timezone: 'Europe/Busingen',
        timezone_el: 'Ευρώπη/Μπιζίνγκεν',
        timezone_es: 'Europa/Busingen',
        timezone_it: 'Europa/Büsingen',
        timezone_fr: 'Europe/Busingen',
        timezone_de: 'Europa/Busingen',
        timezone_ca: 'Europa/Busingen',
        country_name: 'Germany',
        country_name_el: 'Γερμανία',
        country_name_es: 'Alemania',
        country_name_it: 'Germania',
        country_name_fr: 'Allemagne',
        country_name_de: 'Deutschland',
        country_name_ca: 'Alemanya',
        country_capital: 'Berlin',
        country_capital_el: 'Βερολίνο',
        country_capital_es: 'Berlín',
        country_capital_it: 'Berlino',
        country_capital_fr: 'Berlin',
        country_capital_de: 'Berlin',
        country_capital_ca: 'Berlín',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'DJ',
        utc_offset: 3,
        timezone: 'Africa/Djibouti',
        timezone_el: 'Αφρική/Τζιμπουτί',
        timezone_es: 'África/Djibouti',
        timezone_it: 'África/Yibuti',
        timezone_fr: 'Afrique/Djibouti',
        timezone_de: 'Afrika/Dschibuti',
        timezone_ca: 'Àfrica/Djibouti',
        country_name: 'Djibouti',
        country_name_el: 'Τζιμπουτί',
        country_name_es: 'Yibuti',
        country_name_it: 'Gibuti',
        country_name_fr: 'Djibouti',
        country_name_de: 'Dschibuti',
        country_name_ca: 'Djibouti',
        country_capital: 'Djibouti',
        country_capital_el: 'Τζιμπουτί',
        country_capital_es: 'Yibuti',
        country_capital_it: 'Gibuti',
        country_capital_fr: 'Djibouti',
        country_capital_de: 'Dschibuti',
        country_capital_ca: 'Djibouti',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'DM',
        utc_offset: -4,
        timezone: 'America/Dominica',
        timezone_el: 'Αμερική/Ντομίνικα',
        timezone_es: 'América/Dominica',
        timezone_it: 'América/Dominica',
        timezone_fr: 'Amérique/Dominique',
        timezone_de: 'Amerika/Dominica',
        timezone_ca: 'Amèrica/Dominica',
        country_name: 'Dominica',
        country_name_el: 'Δομινίκα',
        country_name_es: 'Dominica',
        country_name_it: 'Dominica',
        country_name_fr: 'Dominique',
        country_name_de: 'Dominica',
        country_name_ca: 'Dominica',
        country_capital: 'Roseau',
        country_capital_el: 'Ρόζο',
        country_capital_es: 'Roseau',
        country_capital_it: 'Roseau',
        country_capital_fr: 'Roseau',
        country_capital_de: 'Roseau',
        country_capital_ca: 'Roseau',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'DK',
        utc_offset: 1,
        timezone: 'Europe/Copenhagen',
        timezone_el: 'Ευρώπη/Κοπεγχάγη',
        timezone_es: 'Europa/Copenhague',
        timezone_it: 'Europa/Copenhague',
        timezone_fr: 'Europe/Copenhague',
        timezone_de: 'Europa/Kopenhagen',
        timezone_ca: 'Europa/Copenhagen',
        country_name: 'Denmark',
        country_name_el: 'Δανία',
        country_name_es: 'Dinamarca',
        country_name_it: 'Danimarca',
        country_name_fr: 'Danemark',
        country_name_de: 'Dänemark',
        country_name_ca: 'Dinamarca',
        country_capital: 'Copenhagen',
        country_capital_el: 'Κοπεγχάγη',
        country_capital_es: 'Copenhague',
        country_capital_it: 'Copenaghen',
        country_capital_fr: 'Copenhague',
        country_capital_de: 'Kopenhagen',
        country_capital_ca: 'Copenhaguen',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'DO',
        utc_offset: -4,
        timezone: 'America/Santo_Domingo',
        timezone_el: 'Αμερική/Σάντο_Δομίνγκο',
        timezone_es: 'América/Santo_Domingo',
        timezone_it: 'América/Santo_Domingo',
        timezone_fr: 'Amérique/Saint-Domingue',
        timezone_de: 'Amerika/Santo_Domingo',
        timezone_ca: 'Amèrica/Santo_Domingo',
        country_name: 'Dominican Republic',
        country_name_el: 'Δομινικανή Δημοκρατία',
        country_name_es: 'República Dominicana',
        country_name_it: 'Repubblica Dominicana',
        country_name_fr: 'République dominicaine',
        country_name_de: 'Dominikanische Republik',
        country_name_ca: 'República Dominicana',
        country_capital: 'Santo Domingo',
        country_capital_el: 'Σάντο Ντομίνγκο',
        country_capital_es: 'Santo Domingo',
        country_capital_it: 'Santo Domingo',
        country_capital_fr: 'Saint-Domingue',
        country_capital_de: 'Santo Domingo',
        country_capital_ca: 'Santo Domingo',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'DZ',
        utc_offset: 2,
        timezone: 'Africa/Algiers',
        timezone_el: 'Αφρική/Αλγέρι',
        timezone_es: 'África/Argel',
        timezone_it: 'África/Argel',
        timezone_fr: 'Afrique/Alger',
        timezone_de: 'Afrika/Algier',
        timezone_ca: 'Àfrica/Alger',
        country_name: 'Algeria',
        country_name_el: 'Αλγερία',
        country_name_es: 'Argelia',
        country_name_it: 'Algeria',
        country_name_fr: 'Algérie',
        country_name_de: 'Algerien',
        country_name_ca: 'Algèria',
        country_capital: 'Algiers',
        country_capital_el: 'Αλγέρι',
        country_capital_es: 'Argel',
        country_capital_it: 'Algeri',
        country_capital_fr: 'Alger',
        country_capital_de: 'Algier',
        country_capital_ca: 'Alger',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'EC',
        utc_offset: -5,
        timezone: 'America/Guayaquil',
        timezone_el: 'Αμερική/Γκουαγιακίλ',
        timezone_es: 'América/Guayaquil',
        timezone_it: 'América/Guayaquil',
        timezone_fr: 'Amérique/Guayaquil',
        timezone_de: 'Amerika/Guayaquil',
        timezone_ca: 'Amèrica/Guayaquil',
        country_name: 'Ecuador',
        country_name_el: 'Ισημερινός',
        country_name_es: 'Ecuador',
        country_name_it: 'Ecuador',
        country_name_fr: 'Équateur',
        country_name_de: 'Ecuador',
        country_name_ca: 'Equador',
        country_capital: 'Quito',
        country_capital_el: 'Κίτο',
        country_capital_es: 'Quito',
        country_capital_it: 'Quito',
        country_capital_fr: 'Quito',
        country_capital_de: 'Quito',
        country_capital_ca: 'Quito',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'America del Sud',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'EC',
        utc_offset: -5,
        timezone: 'Pacific/Galapagos',
        timezone_el: 'Ειρηνικός/Γκάλαπαγκος',
        timezone_es: 'Pacífico/Galápagos',
        timezone_it: 'Pacífico/Galápagos',
        timezone_fr: 'Pacifique/Galapagos',
        timezone_de: 'Pazifik/Galapagos',
        timezone_ca: 'Pacífic/Galápagos',
        country_name: 'Ecuador',
        country_name_el: 'Ισημερινός',
        country_name_es: 'Ecuador',
        country_name_it: 'Ecuador',
        country_name_fr: 'Équateur',
        country_name_de: 'Ecuador',
        country_name_ca: 'Equador',
        country_capital: 'Quito',
        country_capital_el: 'Κίτο',
        country_capital_es: 'Quito',
        country_capital_it: 'Quito',
        country_capital_fr: 'Quito',
        country_capital_de: 'Quito',
        country_capital_ca: 'Quito',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'America del Sud',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'EG',
        utc_offset: 2,
        timezone: 'Africa/Cairo',
        timezone_el: 'Αφρική/Κάιρο',
        timezone_es: 'África/El_Cairo',
        timezone_it: 'África/El_Cairo',
        timezone_fr: 'Afrique/Le_Caire',
        timezone_de: 'Afrika/Kairo',
        timezone_ca: 'Àfrica/El_Cairom',
        country_name: 'Egypt',
        country_name_el: 'Αίγυπτος',
        country_name_es: 'Egipto',
        country_name_it: 'Egitto',
        country_name_fr: 'Égypte',
        country_name_de: 'Ägypten',
        country_name_ca: 'Egipte',
        country_capital: 'Cairo',
        country_capital_el: 'Κάιρο',
        country_capital_es: 'El Cairo',
        country_capital_it: 'Il Cairo',
        country_capital_fr: 'Le Caire',
        country_capital_de: 'Kairo',
        country_capital_ca: 'El Caire',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'ER',
        utc_offset: 3,
        timezone: 'Africa/Asmara',
        timezone_el: 'Αφρική/Άσμαρα',
        timezone_es: 'África/Asmara',
        timezone_it: 'África/Asmara',
        timezone_fr: 'Afrique/Asmara',
        timezone_de: 'Afrika/Asmara',
        timezone_ca: 'Àfrica/Asmara',
        country_name: 'Eritrea',
        country_name_el: 'Ερυθραία',
        country_name_es: 'Eritrea',
        country_name_it: 'Eritrea',
        country_name_fr: 'Érythrée',
        country_name_de: 'Eritrea',
        country_name_ca: 'Eritrea',
        country_capital: 'Asmara',
        country_capital_el: 'Ασμάρα',
        country_capital_es: 'Asmara',
        country_capital_it: 'Asmara',
        country_capital_fr: 'Asmara',
        country_capital_de: 'Asmara',
        country_capital_ca: 'Asmara',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'EH',
        utc_offset: 1,
        timezone: 'Africa/El_Aaiun',
        timezone_el: 'Αφρική/Ελ_Αγκούν',
        timezone_es: 'África/El_Aaiún',
        timezone_it: 'África/El_Aaiún',
        timezone_fr: 'Afrique/El_Aaiun',
        timezone_de: 'Afrika/El_Aaiun',
        timezone_ca: 'Àfrica/El_Aaiún',
        country_name: 'Western Sahara',
        country_name_el: 'Δυτική Σαχάρα',
        country_name_es: 'Sáhara Occidental',
        country_name_it: 'Sahara Occidentale',
        country_name_fr: 'Sahara occidental',
        country_name_de: 'Westsahara',
        country_name_ca: 'Sahara Occidental',
        country_capital: 'El Aaiún',
        country_capital_el: 'Ελ Αγουν',
        country_capital_es: 'El Aaiún',
        country_capital_it: 'El Aaiún',
        country_capital_fr: 'El Aaiún',
        country_capital_de: 'El Aaiún',
        country_capital_ca: 'El Aaiún',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'ES',
        utc_offset: 1,
        timezone: 'Europe/Madrid',
        timezone_el: 'Ευρώπη/Μαδρίτη',
        timezone_es: 'Europa/Madrid',
        timezone_it: 'Europa/Madrid',
        timezone_fr: 'Europe/Madrid',
        timezone_de: 'Europa/Madrid',
        timezone_ca: 'Europa/Madrid',
        country_name: 'Spain',
        country_name_el: 'Ισπανία',
        country_name_es: 'España',
        country_name_it: 'Spagna',
        country_name_fr: 'Espagne',
        country_name_de: 'Spanien',
        country_name_ca: 'Espanya',
        country_capital: 'Madrid',
        country_capital_el: 'Μαδρίτη',
        country_capital_es: 'Madrid',
        country_capital_it: 'Madrid',
        country_capital_fr: 'Madrid',
        country_capital_de: 'Madrid',
        country_capital_ca: 'Madrid',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'ES',
        utc_offset: 1,
        timezone: 'Africa/Ceuta',
        timezone_el: 'Ευρώπη/Θεσσαλονίκη',
        timezone_es: 'África/Ceuta',
        timezone_it: 'África/Ceuta',
        timezone_fr: 'Afrique/Ceuta',
        timezone_de: 'Afrika/Ceuta',
        timezone_ca: 'Àfrica/Ceuta',
        country_name: 'Spain',
        country_name_el: 'Ισπανία',
        country_name_es: 'España',
        country_name_it: 'Spagna',
        country_name_fr: 'Espagne',
        country_name_de: 'Spanien',
        country_name_ca: 'Espanya',
        country_capital: 'Madrid',
        country_capital_el: 'Μαδρίτη',
        country_capital_es: 'Madrid',
        country_capital_it: 'Madrid',
        country_capital_fr: 'Madrid',
        country_capital_de: 'Madrid',
        country_capital_ca: 'Madrid',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'ES',
        utc_offset: 1,
        timezone: 'Atlantic/Canary',
        timezone_el: 'Ατλαντικό/Κανάριες_Νήσοι',
        timezone_es: 'Islas_Canarias',
        timezone_it: 'Atlántico/Canarias',
        timezone_fr: 'Atlantique/Canaries',
        timezone_de: 'Atlantik/Kanarische',
        timezone_ca: 'Atlàntic/Canàries',
        country_name: 'Spain',
        country_name_el: 'Ισπανία',
        country_name_es: 'España',
        country_name_it: 'Spagna',
        country_name_fr: 'Espagne',
        country_name_de: 'Spanien',
        country_name_ca: 'Espanya',
        country_capital: 'Madrid',
        country_capital_el: 'Μαδρίτη',
        country_capital_es: 'Madrid',
        country_capital_it: 'Madrid',
        country_capital_fr: 'Madrid',
        country_capital_de: 'Madrid',
        country_capital_ca: 'Madrid',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'EE',
        utc_offset: 2,
        timezone: 'Europe/Tallinn',
        timezone_el: 'Ευρώπη/Ταλίν',
        timezone_es: 'Europa/Tallin',
        timezone_it: 'Europa/Tallin',
        timezone_fr: 'Europe/Tallinn',
        timezone_de: 'Europa/Tallinn',
        timezone_ca: 'Europa/Tallinn',
        country_name: 'Estonia',
        country_name_el: 'Εσθονία',
        country_name_es: 'Estonia',
        country_name_it: 'Estonia',
        country_name_fr: 'Estonie',
        country_name_de: 'Estland',
        country_name_ca: 'Estònia',
        country_capital: 'Tallinn',
        country_capital_el: 'Ταλίν',
        country_capital_es: 'Tallin',
        country_capital_it: 'Tallinn',
        country_capital_fr: 'Tallinn',
        country_capital_de: 'Tallinn',
        country_capital_ca: 'Tallinn',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'ET',
        utc_offset: 3,
        timezone: 'Africa/Addis_Ababa',
        timezone_el: 'Αφρική/Αντίς_Αμπέμπα',
        timezone_es: 'África/Addis_Abeba',
        timezone_it: 'África/Addis_Abeba',
        timezone_fr: 'Afrique/Addis_Ababa',
        timezone_de: 'Afrika/Addis_Ababa',
        timezone_ca: 'Àfrica/Addis_Ababa',
        country_name: 'Ethiopia',
        country_name_el: 'Αιθιοπία',
        country_name_es: 'Etiopía',
        country_name_it: 'Etiopia',
        country_name_fr: 'Éthiopie',
        country_name_de: 'Äthiopien',
        country_name_ca: 'Etiòpia',
        country_capital: 'Addis Ababa',
        country_capital_el: 'Αντίς Αμπέμπα',
        country_capital_es: 'Adís Abeba',
        country_capital_it: 'Addis Abeba',
        country_capital_fr: 'Addis-Abeba',
        country_capital_de: 'Addis Abeba',
        country_capital_ca: 'Addis Abeba',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'FI',
        utc_offset: 2,
        timezone: 'Europe/Helsinki',
        timezone_el: 'Ευρώπη/Ελσίνκι',
        timezone_es: 'Europa/Helsinki',
        timezone_it: 'Europa/Helsinki',
        timezone_fr: 'Europe/Helsinki',
        timezone_de: 'Europa/Helsinki',
        timezone_ca: 'Europa/Helsinki',
        country_name: 'Finland',
        country_name_el: 'Φινλανδία',
        country_name_es: 'Finlandia',
        country_name_it: 'Finlandia',
        country_name_fr: 'Finlande',
        country_name_de: 'Finnland',
        country_name_ca: 'Finlàndia',
        country_capital: 'Helsinki',
        country_capital_el: 'Ελσίνκι',
        country_capital_es: 'Helsinki',
        country_capital_it: 'Helsinki',
        country_capital_fr: 'Helsinki',
        country_capital_de: 'Helsinki',
        country_capital_ca: 'Hèlsinki',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'FJ',
        utc_offset: 12,
        timezone: 'Pacific/Fiji',
        timezone_el: 'Ειρηνικός/Φίτζι',
        timezone_es: 'Pacífico/Fiyi',
        timezone_it: 'Pacífico/Fiyi',
        timezone_fr: 'Pacifique/Fidji',
        timezone_de: 'Pazifik/Fidschi',
        timezone_ca: 'Pacífic/Fiji',
        country_name: 'Fiji',
        country_name_el: 'Φίτζι',
        country_name_es: 'Fiyi',
        country_name_it: 'Figi',
        country_name_fr: 'Fidji',
        country_name_de: 'Fidschi',
        country_name_ca: 'Fiji',
        country_capital: 'Suva',
        country_capital_el: 'Σούβα',
        country_capital_es: 'Suva',
        country_capital_it: 'Suva',
        country_capital_fr: 'Suva',
        country_capital_de: 'Suva',
        country_capital_ca: 'Suva',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'FK',
        utc_offset: -3,
        timezone: 'Atlantic/Stanley',
        timezone_el: 'Ατλαντικό/Στάνλεϊ',
        timezone_es: 'Atlántico/Islas_Malvinas',
        timezone_it: 'Atlántico/Stanley',
        timezone_fr: 'Atlantique/Stanley',
        timezone_de: 'Atlantik/Stanley',
        timezone_ca: 'Atlàntic/Malvines',
        country_name: 'Falkland Islands',
        country_name_el: 'Νήσοι Φόκλαντ',
        country_name_es: 'Islas Malvinas',
        country_name_it: 'Isole Falkland',
        country_name_fr: 'Îles Malouines',
        country_name_de: 'Falklandinseln',
        country_name_ca: 'Illes Malvines',
        country_capital: 'Stanley',
        country_capital_el: 'Στάνλεϊ',
        country_capital_es: 'Stanley',
        country_capital_it: 'Stanley',
        country_capital_fr: 'Stanley',
        country_capital_de: 'Stanley',
        country_capital_ca: 'Stanley',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'America del Sud',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'FR',
        utc_offset: 1,
        timezone: 'Europe/Paris',
        timezone_el: 'Ευρώπη/Παρίσι',
        timezone_es: 'Europa/París',
        timezone_it: 'Europa/París',
        timezone_fr: 'Europe/Paris',
        timezone_de: 'Europa/Paris',
        timezone_ca: 'Europa/París',
        country_name: 'France',
        country_name_el: 'Γαλλία',
        country_name_es: 'Francia',
        country_name_it: 'Francia',
        country_name_fr: 'France',
        country_name_de: 'Frankreich',
        country_name_ca: 'França',
        country_capital: 'Paris',
        country_capital_el: 'Παρίσι',
        country_capital_es: 'París',
        country_capital_it: 'Parigi',
        country_capital_fr: 'Paris',
        country_capital_de: 'Paris',
        country_capital_ca: 'París',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'FO',
        utc_offset: 0,
        timezone: 'Atlantic/Faroe',
        timezone_el: 'Ατλαντικό/Φερόες',
        timezone_es: 'Atlántico/Feroe',
        timezone_it: 'Atlántico/Faroe',
        timezone_fr: 'Atlantique/Féroé',
        timezone_de: 'Atlantik/Faroe',
        timezone_ca: 'Atlàntic/Faroe',
        country_name: 'Faroe Islands',
        country_name_el: 'Νήσοι Φερόες',
        country_name_es: 'Islas Feroe',
        country_name_it: 'Isole Fær Øer',
        country_name_fr: 'Îles Féroé',
        country_name_de: 'Färöer-Inseln',
        country_name_ca: 'Illes Fèroe',
        country_capital: 'Tórshavn',
        country_capital_el: 'Τόρσαβν',
        country_capital_es: 'Tórshavn',
        country_capital_it: 'Tórshavn',
        country_capital_fr: 'Tórshavn',
        country_capital_de: 'Tórshavn',
        country_capital_ca: 'Tórshavn',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'FM',
        utc_offset: 10,
        timezone: 'Pacific/Chuuk',
        timezone_el: 'Ειρηνικός/Τσουκ',
        timezone_es: 'Pacífico/Chuuk',
        timezone_it: 'Pacífico/Chuuk',
        timezone_fr: 'Pacifique/Chuuk',
        timezone_de: 'Pazifik/Chuuk',
        timezone_ca: 'Pacífic/Chuuk',
        country_name: 'Micronesia',
        country_name_el: 'Μικρονησία',
        country_name_es: 'Micronesia',
        country_name_it: 'Micronesia',
        country_name_fr: 'Micronésie',
        country_name_de: 'Mikronesien',
        country_name_ca: 'Micronèsia',
        country_capital: 'Palikir',
        country_capital_el: 'Παλίκιρ',
        country_capital_es: 'Palikir',
        country_capital_it: 'Palikir',
        country_capital_fr: 'Palikir',
        country_capital_de: 'Palikir',
        country_capital_ca: 'Palikir',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'FM',
        utc_offset: 10,
        timezone: 'Pacific/Pohnpei',
        timezone_el: 'Ειρηνικός/Πονπέι',
        timezone_es: 'Pacífico/Pohnpei',
        timezone_it: 'Pacífico/Pohnpei',
        timezone_fr: 'Pacifique/Pohnpei',
        timezone_de: 'Pazifik/Pohnpei',
        timezone_ca: 'Pacífic/Pohnpei',
        country_name: 'Micronesia',
        country_name_el: 'Μικρονησία',
        country_name_es: 'Micronesia',
        country_name_it: 'Micronesia',
        country_name_fr: 'Micronésie',
        country_name_de: 'Mikronesien',
        country_name_ca: 'Micronèsia',
        country_capital: 'Palikir',
        country_capital_el: 'Παλίκιρ',
        country_capital_es: 'Palikir',
        country_capital_it: 'Palikir',
        country_capital_fr: 'Palikir',
        country_capital_de: 'Palikir',
        country_capital_ca: 'Palikir',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'FM',
        utc_offset: 10,
        timezone: 'Pacific/Kosrae',
        timezone_el: 'Ειρηνικός/Κόσραε',
        timezone_es: 'Pacífico/Kosrae',
        timezone_it: 'Pacífico/Kosrae',
        timezone_fr: 'Pacifique/Kosrae',
        timezone_de: 'Pazifik/Kosrae',
        timezone_ca: 'Pacífic/Kosrae',
        country_name: 'Micronesia',
        country_name_el: 'Μικρονησία',
        country_name_es: 'Micronesia',
        country_name_it: 'Micronesia',
        country_name_fr: 'Micronésie',
        country_name_de: 'Mikronesien',
        country_name_ca: 'Micronèsia',
        country_capital: 'Palikir',
        country_capital_el: 'Παλίκιρ',
        country_capital_es: 'Palikir',
        country_capital_it: 'Palikir',
        country_capital_fr: 'Palikir',
        country_capital_de: 'Palikir',
        country_capital_ca: 'Palikir',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'GA',
        utc_offset: 1,
        timezone: 'Africa/Libreville',
        timezone_el: 'Αφρική/Λιμπρεβίλ',
        timezone_es: 'África/Libreville',
        timezone_it: 'África/Libreville',
        timezone_fr: 'Afrique/Libreville',
        timezone_de: 'Afrika/Libreville',
        timezone_ca: 'Àfrica/Libreville',
        country_name: 'Gabon',
        country_name_el: 'Γαβόν',
        country_name_es: 'Gabón',
        country_name_it: 'Gabon',
        country_name_fr: 'Gabon',
        country_name_de: 'Gabun',
        country_name_ca: 'Gabon',
        country_capital: 'Libreville',
        country_capital_el: 'Λιμπρεβίλ',
        country_capital_es: 'Libreville',
        country_capital_it: 'Libreville',
        country_capital_fr: 'Libreville',
        country_capital_de: 'Libreville',
        country_capital_ca: 'Libreville',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'GB',
        utc_offset: 0,
        timezone: 'Europe/London',
        timezone_el: 'Ευρώπη/Λονδίνο',
        timezone_es: 'Europa/Londres',
        timezone_it: 'Europa/Londres',
        timezone_fr: 'Europe/Londres',
        timezone_de: 'Europa/London',
        timezone_ca: 'Europa/Londres',
        country_name: 'United Kingdom',
        country_name_el: 'Ηνωμένο Βασίλειο',
        country_name_es: 'Reino Unido',
        country_name_it: 'Regno Unito',
        country_name_fr: 'Royaume-Uni',
        country_name_de: 'Vereinigtes Königreich',
        country_name_ca: 'Regne Unit',
        country_capital: 'London',
        country_capital_el: 'Λονδίνο',
        country_capital_es: 'Londres',
        country_capital_it: 'Londra',
        country_capital_fr: 'Londres',
        country_capital_de: 'London',
        country_capital_ca: 'Londres',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'GE',
        utc_offset: 4,
        timezone: 'Asia/Tbilisi',
        timezone_el: 'Ασία/Τμπιλίσι',
        timezone_es: 'Asia/Tbilisi',
        timezone_it: 'Asia/Tbilisi',
        timezone_fr: 'Asie/Tbilissi',
        timezone_de: 'Asien/Tiflis',
        timezone_ca: 'Àsia/Tbilisi',
        country_name: 'Georgia',
        country_name_el: 'Γεωργία',
        country_name_es: 'Georgia',
        country_name_it: 'Georgia',
        country_name_fr: 'Géorgie',
        country_name_de: 'Georgien',
        country_name_ca: 'Geòrgia',
        country_capital: 'Tbilisi',
        country_capital_el: 'Τυφλίδα',
        country_capital_es: 'Tiflis',
        country_capital_it: 'Tbilisi',
        country_capital_fr: 'Tbilissi',
        country_capital_de: 'Tiflis',
        country_capital_ca: 'Tbilisi',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'GG',
        utc_offset: 0,
        timezone: 'Europe/Guernsey',
        timezone_el: 'Ευρώπη/Γκέρνσεϊ',
        timezone_es: 'Europa/Guernsey',
        timezone_it: 'Europa/Guernsey',
        timezone_fr: 'Europe/Guernesey',
        timezone_de: 'Europa/Guernsey',
        timezone_ca: 'Europa/Guernsey',
        country_name: 'Guernsey',
        country_name_el: 'Γκέρνσεϊ',
        country_name_es: 'Guernsey',
        country_name_it: 'Guernsey',
        country_name_fr: 'Guernesey',
        country_name_de: 'Guernsey',
        country_name_ca: 'Guernsey',
        country_capital: 'St. Peter Port',
        country_capital_el: 'Άγιος Πέτρος Πορτ',
        country_capital_es: 'Saint Peter Port',
        country_capital_it: 'Saint Peter Port',
        country_capital_fr: 'Saint-Pierre-et-Miquelon',
        country_capital_de: 'Saint Peter Port',
        country_capital_ca: 'Saint Peter Port',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'GH',
        utc_offset: 0,
        timezone: 'Africa/Accra',
        timezone_el: 'Αφρική/Ακκρα',
        timezone_es: 'África/Accra',
        timezone_it: 'África/Accra',
        timezone_fr: 'Afrique/Accra',
        timezone_de: 'Afrika/Accra',
        timezone_ca: 'Àfrica/Accra',
        country_name: 'Ghana',
        country_name_el: 'Γκάνα',
        country_name_es: 'Ghana',
        country_name_it: 'Ghana',
        country_name_fr: 'Ghana',
        country_name_de: 'Ghana',
        country_name_ca: 'Ghana',
        country_capital: 'Accra',
        country_capital_el: 'Άκκρα',
        country_capital_es: 'Acra',
        country_capital_it: 'Accra',
        country_capital_fr: 'Accra',
        country_capital_de: 'Accra',
        country_capital_ca: 'Accra',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'GI',
        utc_offset: 1,
        timezone: 'Europe/Gibraltar',
        timezone_el: 'Ευρώπη/Γιβραλτάρ',
        timezone_es: 'Europa/Gibraltar',
        timezone_it: 'Europa/Gibraltar',
        timezone_fr: 'Europe/Gibraltar',
        timezone_de: 'Europa/Gibraltar',
        timezone_ca: 'Europa/Gibraltar',
        country_name: 'Gibraltar',
        country_name_el: 'Γιβραλτάρ',
        country_name_es: 'Gibraltar',
        country_name_it: 'Gibilterra',
        country_name_fr: 'Gibraltar',
        country_name_de: 'Gibraltar',
        country_name_ca: 'Gibraltar',
        country_capital: 'Gibraltar',
        country_capital_el: 'Γιβραλτάρ',
        country_capital_es: 'Gibraltar',
        country_capital_it: 'Gibilterra',
        country_capital_fr: 'Gibraltar',
        country_capital_de: 'Gibraltar',
        country_capital_ca: 'Gibraltar',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'GN',
        utc_offset: 0,
        timezone: 'Africa/Conakry',
        timezone_el: 'Αφρική/Κόνακρι',
        timezone_es: 'África/Conakry',
        timezone_it: 'África/Conakry',
        timezone_fr: 'Afrique/Conakry',
        timezone_de: 'Afrika/Conakry',
        timezone_ca: 'Àfrica/Conakry',
        country_name: 'Guinea',
        country_name_el: 'Γουινέα',
        country_name_es: 'Guinea',
        country_name_it: 'Guinea',
        country_name_fr: 'Guinée',
        country_name_de: 'Guinea',
        country_name_ca: 'Guinea',
        country_capital: 'Conakry',
        country_capital_el: 'Κόνακρι',
        country_capital_es: 'Conakry',
        country_capital_it: 'Conakry',
        country_capital_fr: 'Conakry',
        country_capital_de: 'Conakry',
        country_capital_ca: 'Conakry',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'GP',
        utc_offset: -4,
        timezone: 'America/Guadeloupe',
        timezone_el: 'Αμερική/Γουαδελούπη',
        timezone_es: 'América/Guadalupe',
        timezone_it: 'América/Guadalupe',
        timezone_fr: 'Amérique/Guadeloupe',
        timezone_de: 'Amerika/Guadeloupe',
        timezone_ca: 'Amèrica/Guadalupe',
        country_name: 'Guadeloupe',
        country_name_el: 'Γουαδελούπη',
        country_name_es: 'Guadalupe',
        country_name_it: 'Guadalupa',
        country_name_fr: 'Guadeloupe',
        country_name_de: 'Guadeloupe',
        country_name_ca: 'Guadeloupe',
        country_capital: 'Basse-Terre',
        country_capital_el: 'Μπας-Τερ',
        country_capital_es: 'Basse-Terre',
        country_capital_it: 'Basse-Terre',
        country_capital_fr: 'Basse-Terre',
        country_capital_de: 'Basse-Terre',
        country_capital_ca: 'Basse-Terre',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'GM',
        utc_offset: 0,
        timezone: 'Africa/Banjul',
        timezone_el: 'Αφρική/Μπανζούλ',
        timezone_es: 'África/Banjul',
        timezone_it: 'África/Banjul',
        timezone_fr: 'Afrique/Banjul',
        timezone_de: 'Afrika/Banjul',
        timezone_ca: 'Àfrica/Banjul',
        country_name: 'Gambia',
        country_name_el: 'Γκάμπια',
        country_name_es: 'Gambia',
        country_name_it: 'Gambia',
        country_name_fr: 'Gambie',
        country_name_de: 'Gambia',
        country_name_ca: 'Gàmbia',
        country_capital: 'Banjul',
        country_capital_el: 'Μπανζούλ',
        country_capital_es: 'Banjul',
        country_capital_it: 'Banjul',
        country_capital_fr: 'Banjul',
        country_capital_de: 'Banjul',
        country_capital_ca: 'Banjul',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'GW',
        utc_offset: 0,
        timezone: 'Africa/Bissau',
        timezone_el: 'Αφρική/Μπισάου',
        timezone_es: 'África/Bissau',
        timezone_it: 'África/Bissau',
        timezone_fr: 'Afrique/Bissau',
        timezone_de: 'Afrika/Bissau',
        timezone_ca: 'Àfrica/Bissau',
        country_name: 'Guinea-Bissau',
        country_name_el: 'Γουινέα-Μπισάου',
        country_name_es: 'Guinea-Bisáu',
        country_name_it: 'Guinea-Bissau',
        country_name_fr: 'Guinée-Bissau',
        country_name_de: 'Guinea-Bissau',
        country_name_ca: 'Guinea-Bissau',
        country_capital: 'Bissau',
        country_capital_el: 'Μπισάου',
        country_capital_es: 'Bissau',
        country_capital_it: 'Bissau',
        country_capital_fr: 'Bissau',
        country_capital_de: 'Bissau',
        country_capital_ca: 'Bissau',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'GQ',
        utc_offset: 1,
        timezone: 'Africa/Malabo',
        timezone_el: 'Αφρική/Μαλάμπο',
        timezone_es: 'África/Malabo',
        timezone_it: 'África/Malabo',
        timezone_fr: 'Afrique/Malabo',
        timezone_de: 'Afrika/Malabo',
        timezone_ca: 'Àfrica/Malabo',
        country_name: 'Equatorial Guinea',
        country_name_el: 'Ισημερινή Γουινέα',
        country_name_es: 'Guinea Ecuatorial',
        country_name_it: 'Guinea Equatoriale',
        country_name_fr: 'Guinée équatoriale',
        country_name_de: 'Äquatorialguinea',
        country_name_ca: 'Guinea Equatorial',
        country_capital: 'Malabo',
        country_capital_el: 'Μαλάμπο',
        country_capital_es: 'Malabo',
        country_capital_it: 'Malabo',
        country_capital_fr: 'Malabo',
        country_capital_de: 'Malabo',
        country_capital_ca: 'Malabo',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'GR',
        utc_offset: 2,
        timezone: 'Europe/Athens',
        timezone_el: 'Ευρώπη/Αθήνα',
        timezone_es: 'Europa/Atenas',
        timezone_it: 'Europa/Atenas',
        timezone_fr: 'Europe/Athènes',
        timezone_de: 'Europa/Athen',
        timezone_ca: 'Europa/Atenes',
        country_name: 'Greece',
        country_name_el: 'Ελλάδα',
        country_name_es: 'Grecia',
        country_name_it: 'Grecia',
        country_name_fr: 'Grèce',
        country_name_de: 'Griechenland',
        country_name_ca: 'Grècia',
        country_capital: 'Athens',
        country_capital_el: 'Αθήνα',
        country_capital_es: 'Atenas',
        country_capital_it: 'Atene',
        country_capital_fr: 'Athènes',
        country_capital_de: 'Athen',
        country_capital_ca: 'Atenes',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'GD',
        utc_offset: -4,
        timezone: 'America/Grenada',
        timezone_el: 'Αμερική/Γρενάδα',
        timezone_es: 'América/Grenada',
        timezone_it: 'América/Grenada',
        timezone_fr: 'Amérique/Grenade',
        timezone_de: 'Amerika/Grenada',
        timezone_ca: 'Amèrica/Grenada',
        country_name: 'Grenada',
        country_name_el: 'Γρενάδα',
        country_name_es: 'Granada',
        country_name_it: 'Grenada',
        country_name_fr: 'Grenade',
        country_name_de: 'Grenada',
        country_name_ca: 'Grenada',
        country_capital: 'St. George\'s',
        country_capital_el: 'Σεντ Τζόρτζες',
        country_capital_es: 'Saint George\'s',
        country_capital_it: 'Saint George\'s',
        country_capital_fr: 'Saint George\'s',
        country_capital_de: 'Saint George\'s',
        country_capital_ca: 'Saint George\'s',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'GL',
        utc_offset: -3,
        timezone: 'America/Godthab',
        timezone_el: 'Αμερική/Γκοτχόμπ',
        timezone_es: 'América/Nuuk',
        timezone_it: 'América/Godthab',
        timezone_fr: 'Amérique/Godthab',
        timezone_de: 'Amerika/Nuuk',
        timezone_ca: 'Amèrica/Godthab',
        country_name: 'Greenland',
        country_name_el: 'Γροιλανδία',
        country_name_es: 'Groenlandia',
        country_name_it: 'Groenlandia',
        country_name_fr: 'Groenland',
        country_name_de: 'Grönland',
        country_name_ca: 'Groenlàndia',
        country_capital: 'Nuuk',
        country_capital_el: 'Νούουκ',
        country_capital_es: 'Nuuk',
        country_capital_it: 'Nuuk',
        country_capital_fr: 'Nuuk',
        country_capital_de: 'Nuuk',
        country_capital_ca: 'Nuuk',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'GL',
        utc_offset: -3,
        timezone: 'America/Danmarkshavn',
        timezone_el: 'Αμερική/Ντανμάρκσαβν',
        timezone_es: 'América/Danmarkshavn',
        timezone_it: 'América/Danmarkshavn',
        timezone_fr: 'Amérique/Danemarkshavn',
        timezone_de: 'Amerika/Danmarkshavn',
        timezone_ca: 'Amèrica/Danmarkshavn',
        country_name: 'Greenland',
        country_name_el: 'Γροιλανδία',
        country_name_es: 'Groenlandia',
        country_name_it: 'Groenlandia',
        country_name_fr: 'Groenland',
        country_name_de: 'Grönland',
        country_name_ca: 'Groenlàndia',
        country_capital: 'Nuuk',
        country_capital_el: 'Νούουκ',
        country_capital_es: 'Nuuk',
        country_capital_it: 'Nuuk',
        country_capital_fr: 'Nuuk',
        country_capital_de: 'Nuuk',
        country_capital_ca: 'Nuuk',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'GL',
        utc_offset: -3,
        timezone: 'America/Scoresbysund',
        timezone_el: 'Αμερική/Σκόπια',
        timezone_es: 'América/Scoresbysund',
        timezone_it: 'América/Scoresbysund',
        timezone_fr: 'Amérique/Scoresbysund',
        timezone_de: 'Amerika/Ittoqqortoormiit',
        timezone_ca: 'Amèrica/Scoresbysund',
        country_name: 'Greenland',
        country_name_el: 'Γροιλανδία',
        country_name_es: 'Groenlandia',
        country_name_it: 'Groenlandia',
        country_name_fr: 'Groenland',
        country_name_de: 'Grönland',
        country_name_ca: 'Groenlàndia',
        country_capital: 'Nuuk',
        country_capital_el: 'Νούουκ',
        country_capital_es: 'Nuuk',
        country_capital_it: 'Nuuk',
        country_capital_fr: 'Nuuk',
        country_capital_de: 'Nuuk',
        country_capital_ca: 'Nuuk',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'GL',
        utc_offset: -3,
        timezone: 'America/Thule',
        timezone_el: 'Αμερική/Θουλέ',
        timezone_es: 'América/Thule',
        timezone_it: 'América/Thule',
        timezone_fr: 'Amérique/Thulé',
        timezone_de: 'Amerika/Thule',
        timezone_ca: 'Amèrica/Thule',
        country_name: 'Greenland',
        country_name_el: 'Γροιλανδία',
        country_name_es: 'Groenlandia',
        country_name_it: 'Groenlandia',
        country_name_fr: 'Groenland',
        country_name_de: 'Grönland',
        country_name_ca: 'Groenlàndia',
        country_capital: 'Nuuk',
        country_capital_el: 'Νούουκ',
        country_capital_es: 'Nuuk',
        country_capital_it: 'Nuuk',
        country_capital_fr: 'Nuuk',
        country_capital_de: 'Nuuk',
        country_capital_ca: 'Nuuk',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'GT',
        utc_offset: -6,
        timezone: 'America/Guatemala',
        timezone_el: 'Αμερική/Γουατεμάλα',
        timezone_es: 'América/Guatemala',
        timezone_it: 'América/Guatemala',
        timezone_fr: 'Amérique/Guatemala',
        timezone_de: 'Amerika/Guatemala',
        timezone_ca: 'Amèrica/Guatemala',
        country_name: 'Guatemala',
        country_name_el: 'Γουατεμάλα',
        country_name_es: 'Guatemala',
        country_name_it: 'Guatemala',
        country_name_fr: 'Guatemala',
        country_name_de: 'Guatemala',
        country_name_ca: 'Guatemala',
        country_capital: 'Guatemala City',
        country_capital_el: 'Γουατεμάλα Σίτι',
        country_capital_es: 'Ciudad de Guatemala',
        country_capital_it: 'Città del Guatemala',
        country_capital_fr: 'Guatemala',
        country_capital_de: 'Guatemala-Stadt',
        country_capital_ca: 'Ciutat de Guatemala',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'GF',
        utc_offset: -3,
        timezone: 'America/Cayenne',
        timezone_el: 'Αμερική/Καγιέν',
        timezone_es: 'América/Cayena',
        timezone_it: 'América/Cayena',
        timezone_fr: 'Amérique/Cayenne',
        timezone_de: 'Amerika/Cayenne',
        timezone_ca: 'Amèrica/Caiena',
        country_name: 'French Guiana',
        country_name_el: 'Γαλλική Γουιάνα',
        country_name_es: 'Guayana Francesa',
        country_name_it: 'Guyana francese',
        country_name_fr: 'Guyane française',
        country_name_de: 'Französisch-Guayana',
        country_name_ca: 'Guaiana Francesa',
        country_capital: 'Cayenne',
        country_capital_el: 'Καγιέν',
        country_capital_es: 'Cayena',
        country_capital_it: 'Cayenne',
        country_capital_fr: 'Cayenne',
        country_capital_de: 'Cayenne',
        country_capital_ca: 'Caiena',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'America del Sud',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'GU',
        utc_offset: 10,
        timezone: 'Pacific/Guam',
        timezone_el: 'Ειρηνικός/Γκουάμ',
        timezone_es: 'Pacífico/Guam',
        timezone_it: 'Pacífico/Guam',
        timezone_fr: 'Pacifique/Guam',
        timezone_de: 'Pazifik/Guam',
        timezone_ca: 'Pacífic/Guam',
        country_name: 'Guam',
        country_name_el: 'Γκουάμ',
        country_name_es: 'Guam',
        country_name_it: 'Guam',
        country_name_fr: 'Guam',
        country_name_de: 'Guam',
        country_name_ca: 'Guam',
        country_capital: 'Hagåtña',
        country_capital_el: 'Χαγκάτνια',
        country_capital_es: 'Hagåtña',
        country_capital_it: 'Hagåtña',
        country_capital_fr: 'Hagåtña',
        country_capital_de: 'Hagåtña',
        country_capital_ca: 'Hagåtña',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'GY',
        utc_offset: -4,
        timezone: 'America/Guyana',
        timezone_el: 'Αμερική/Γουιάνα',
        timezone_es: 'América/Guyana',
        timezone_it: 'América/Guyana',
        timezone_fr: 'Amérique/Guyana',
        timezone_de: 'Amerika/Guyana',
        timezone_ca: 'Amèrica/Guiana',
        country_name: 'Guyana',
        country_name_el: 'Γουιάνα',
        country_name_es: 'Guyana',
        country_name_it: 'Guyana',
        country_name_fr: 'Guyana',
        country_name_de: 'Guyana',
        country_name_ca: 'Guyana',
        country_capital: 'Georgetown',
        country_capital_el: 'Τζόρτζταουν',
        country_capital_es: 'Georgetown',
        country_capital_it: 'Georgetown',
        country_capital_fr: 'Georgetown',
        country_capital_de: 'Georgetown',
        country_capital_ca: 'Georgetown',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'America del Sud',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'HK',
        utc_offset: 8,
        timezone: 'Asia/Hong_Kong',
        timezone_el: 'Ασία/Χονγκ_Κονγκ',
        timezone_es: 'Asia/Hong_Kong',
        timezone_it: 'Asia/Hong_Kong',
        timezone_fr: 'Asie/Hong_Kong',
        timezone_de: 'Asien/Hongkong',
        timezone_ca: 'Àsia/Hong_Kong',
        country_name: 'Hong Kong',
        country_name_el: 'Χονγκ Κονγκ',
        country_name_es: 'Hong Kong',
        country_name_it: 'Hong Kong',
        country_name_fr: 'Hong Kong',
        country_name_de: 'Hongkong',
        country_name_ca: 'Hong Kong',
        country_capital: 'City of Victoria',
        country_capital_el: 'Πόλη της Βικτόριας',
        country_capital_es: 'Ciudad Victoria',
        country_capital_it: 'Città di Victoria',
        country_capital_fr: 'Ville de Victoria',
        country_capital_de: 'Stadt Victoria',
        country_capital_ca: 'Ciutat de Victòria',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'HN',
        utc_offset: -6,
        timezone: 'America/Tegucigalpa',
        timezone_el: 'Αμερική/Τεγκουσιγκάλπα',
        timezone_es: 'América/Tegucigalpa',
        timezone_it: 'América/Tegucigalpa',
        timezone_fr: 'Amérique/Tegucigalpa',
        timezone_de: 'Amerika/Tegucigalpa',
        timezone_ca: 'Amèrica/Tegucigalpa',
        country_name: 'Honduras',
        country_name_el: 'Ονδούρα',
        country_name_es: 'Honduras',
        country_name_it: 'Honduras',
        country_name_fr: 'Honduras',
        country_name_de: 'Honduras',
        country_name_ca: 'Hondures',
        country_capital: 'Tegucigalpa',
        country_capital_el: 'Τεγκουσιγκάλπα',
        country_capital_es: 'Tegucigalpa',
        country_capital_it: 'Tegucigalpa',
        country_capital_fr: 'Tegucigalpa',
        country_capital_de: 'Tegucigalpa',
        country_capital_ca: 'Tegucigalpa',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'HR',
        utc_offset: 1,
        timezone: 'Europe/Zagreb',
        timezone_el: 'Ευρώπη/Ζάγκρεμπ',
        timezone_es: 'Europa/Zagreb',
        timezone_it: 'Europa/Zagreb',
        timezone_fr: 'Europe/Zagreb',
        timezone_de: 'Europa/Zagreb',
        timezone_ca: 'Europa/Zagreb',
        country_name: 'Croatia',
        country_name_el: 'Κροατία',
        country_name_es: 'Croacia',
        country_name_it: 'Croazia',
        country_name_fr: 'Croatie',
        country_name_de: 'Kroatien',
        country_name_ca: 'Croàcia',
        country_capital: 'Zagreb',
        country_capital_el: 'Ζάγκρεμπ',
        country_capital_es: 'Zagreb',
        country_capital_it: 'Zagabria',
        country_capital_fr: 'Zagreb',
        country_capital_de: 'Zagreb',
        country_capital_ca: 'Zagreb',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'HT',
        utc_offset: -5,
        timezone: 'America/Port-au-Prince',
        timezone_el: 'Αμερική/Πορτ_ο_Πρενς',
        timezone_es: 'América/Puerto_Príncipe',
        timezone_it: 'América/Port-au-Prince',
        timezone_fr: 'Amérique/Port-au-Prince',
        timezone_de: 'Amerika/Port-au-Prince',
        timezone_ca: 'Amèrica/Port-au-Prince',
        country_name: 'Haiti',
        country_name_el: 'Αϊτή',
        country_name_es: 'Haití',
        country_name_it: 'Haiti',
        country_name_fr: 'Haïti',
        country_name_de: 'Haiti',
        country_name_ca: 'Haití',
        country_capital: 'Port-au-Prince',
        country_capital_el: 'Πορτ-ο-Πρενς',
        country_capital_es: 'Puerto Príncipe',
        country_capital_it: 'Port-au-Prince',
        country_capital_fr: 'Port-au-Prince',
        country_capital_de: 'Port-au-Prince',
        country_capital_ca: 'Port-au-Prince',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'HU',
        utc_offset: 1,
        timezone: 'Europe/Budapest',
        timezone_el: 'Ευρώπη/Βουδαπέστη',
        timezone_es: 'Europa/Budapest',
        timezone_it: 'Europa/Budapest',
        timezone_fr: 'Europe/Budapest',
        timezone_de: 'Europa/Budapest',
        timezone_ca: 'Europa/Budapest',
        country_name: 'Hungary',
        country_name_el: 'Ουγγαρία',
        country_name_es: 'Hungría',
        country_name_it: 'Ungheria',
        country_name_fr: 'Hongrie',
        country_name_de: 'Ungarn',
        country_name_ca: 'Hongria',
        country_capital: 'Budapest',
        country_capital_el: 'Βουδαπέστη',
        country_capital_es: 'Budapest',
        country_capital_it: 'Budapest',
        country_capital_fr: 'Budapest',
        country_capital_de: 'Budapest',
        country_capital_ca: 'Budapest',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'ID',
        utc_offset: 7,
        timezone: 'Asia/Jakarta',
        timezone_el: 'Ασία/Τζακάρτα',
        timezone_es: 'Asia/Yakarta',
        timezone_it: 'Asia/Jakarta',
        timezone_fr: 'Asie/Jakarta',
        timezone_de: 'Asien/Jakarta',
        timezone_ca: 'Àsia/Jakarta',
        country_name: 'Indonesia',
        country_name_el: 'Ινδονησία',
        country_name_es: 'Indonesia',
        country_name_it: 'Indonesia',
        country_name_fr: 'Indonésie',
        country_name_de: 'Indonesien',
        country_name_ca: 'Indonèsia',
        country_capital: 'Jakarta',
        country_capital_el: 'Τζακάρτα',
        country_capital_es: 'Yakarta',
        country_capital_it: 'Giacarta',
        country_capital_fr: 'Jakarta',
        country_capital_de: 'Jakarta',
        country_capital_ca: 'Jakarta',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'ID',
        utc_offset: 7,
        timezone: 'Asia/Pontianak',
        timezone_el: 'Ασία/Ποντιανάκ',
        timezone_es: 'Asia/Pontianak',
        timezone_it: 'Asia/Pontianak',
        timezone_fr: 'Asie/Pontianak',
        timezone_de: 'Asien/Pontianak',
        timezone_ca: 'Àsia/Pontianak',
        country_name: 'Indonesia',
        country_name_el: 'Ινδονησία',
        country_name_es: 'Indonesia',
        country_name_it: 'Indonesia',
        country_name_fr: 'Indonésie',
        country_name_de: 'Indonesien',
        country_name_ca: 'Indonèsia',
        country_capital: 'Jakarta',
        country_capital_el: 'Τζακάρτα',
        country_capital_es: 'Yakarta',
        country_capital_it: 'Giacarta',
        country_capital_fr: 'Jakarta',
        country_capital_de: 'Jakarta',
        country_capital_ca: 'Jakarta',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'ID',
        utc_offset: 7,
        timezone: 'Asia/Makassar',
        timezone_el: 'Ασία/Μακασάρ',
        timezone_es: 'Asia/Makassar',
        timezone_it: 'Asia/Makassar',
        timezone_fr: 'Asie/Makassar',
        timezone_de: 'Asien/Makassar',
        timezone_ca: 'Àsia/Makassar',
        country_name: 'Indonesia',
        country_name_el: 'Ινδονησία',
        country_name_es: 'Indonesia',
        country_name_it: 'Indonesia',
        country_name_fr: 'Indonésie',
        country_name_de: 'Indonesien',
        country_name_ca: 'Indonèsia',
        country_capital: 'Jakarta',
        country_capital_el: 'Τζακάρτα',
        country_capital_es: 'Yakarta',
        country_capital_it: 'Giacarta',
        country_capital_fr: 'Jakarta',
        country_capital_de: 'Jakarta',
        country_capital_ca: 'Jakarta',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'ID',
        utc_offset: 7,
        timezone: 'Asia/Jayapura',
        timezone_el: 'Ασία/Τζαγιαπούρα',
        timezone_es: 'Asia/Jayapura',
        timezone_it: 'Asia/Jayapura',
        timezone_fr: 'Asie/Jayapura',
        timezone_de: 'Asien/Jayapura',
        timezone_ca: 'Àsia/Jayapura',
        country_name: 'Indonesia',
        country_name_el: 'Ινδονησία',
        country_name_es: 'Indonesia',
        country_name_it: 'Indonesia',
        country_name_fr: 'Indonésie',
        country_name_de: 'Indonesien',
        country_name_ca: 'Indonèsia',
        country_capital: 'Jakarta',
        country_capital_el: 'Τζακάρτα',
        country_capital_es: 'Yakarta',
        country_capital_it: 'Giacarta',
        country_capital_fr: 'Jakarta',
        country_capital_de: 'Jakarta',
        country_capital_ca: 'Jakarta',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'IM',
        utc_offset: 0,
        timezone: 'Europe/Isle_of_Man',
        timezone_el: 'Ευρώπη/Νήσος_Μαν',
        timezone_es: 'Europa/Isla_de_Man',
        timezone_it: 'Europa/Isla_de_Man',
        timezone_fr: 'Europe/Île_de_Man',
        timezone_de: 'Europa/Isle_of_Man',
        timezone_ca: 'Europa/Illa_de_Man',
        country_name: 'Isle of Man',
        country_name_el: 'Νήσος του Μαν',
        country_name_es: 'Isla de Man',
        country_name_it: 'Isola di Man',
        country_name_fr: 'Île de Man',
        country_name_de: 'Isle of Man',
        country_name_ca: 'Illa de Man',
        country_capital: 'Douglas',
        country_capital_el: 'Ντάγκλας',
        country_capital_es: 'Douglas',
        country_capital_it: 'Douglas',
        country_capital_fr: 'Douglas',
        country_capital_de: 'Douglas',
        country_capital_ca: 'Douglas',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'IN',
        utc_offset: 44990,
        timezone: 'Asia/Kolkata',
        timezone_el: 'Ασία/Καλκούτα',
        timezone_es: 'Asia/Calcuta',
        timezone_it: 'Asia/Calcuta',
        timezone_fr: 'Asie/Calcutta',
        timezone_de: 'Asien/Kalkutta',
        timezone_ca: 'Àsia/Calcuta',
        country_name: 'India',
        country_name_el: 'Ινδία',
        country_name_es: 'India',
        country_name_it: 'India',
        country_name_fr: 'Inde',
        country_name_de: 'Indien',
        country_name_ca: 'Índia',
        country_capital: 'New Delhi',
        country_capital_el: 'Νέο Δελχί',
        country_capital_es: 'Nueva Delhi',
        country_capital_it: 'Nuova Delhi',
        country_capital_fr: 'New Delhi',
        country_capital_de: 'Neu-Delhi',
        country_capital_ca: 'Nova Delhi',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'IO',
        utc_offset: 6,
        timezone: 'Indian/Chagos',
        timezone_el: 'Ινδικό/Τσάγκος',
        timezone_es: 'Territorio_Británico_del_Océano_Índico',
        timezone_it: 'Indico/Chagos',
        timezone_fr: 'Chagos',
        timezone_de: 'Indischer/Ozean/Chagos',
        timezone_ca: 'Índic/Chagos',
        country_name: 'British Indian Ocean Territory',
        country_name_el: 'Βρετανικό Εδάφος του Ινδικού Ωκεανού',
        country_name_es: 'Territorio Británico del Océano Índico',
        country_name_it: 'Territorio Britannico dell\'Oceano Indiano',
        country_name_fr: 'Territoire britannique de l\'océan Indien',
        country_name_de: 'Britisches Territorium im Indischen Ozean',
        country_name_ca: 'Territori Britànic de l\'Oceà Índic',
        country_capital: 'Diego Garcia',
        country_capital_el: 'Διέγκο Γκαρσία',
        country_capital_es: 'Diego García',
        country_capital_it: 'Diego Garcia',
        country_capital_fr: 'Diego Garcia',
        country_capital_de: 'Diego Garcia',
        country_capital_ca: 'Diego García',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'IE',
        utc_offset: 0,
        timezone: 'Europe/Dublin',
        timezone_el: 'Ευρώπη/Δουβλίνο',
        timezone_es: 'Europa/Dublín',
        timezone_it: 'Europa/Dublín',
        timezone_fr: 'Europe/Dublin',
        timezone_de: 'Europa/Dublin',
        timezone_ca: 'Europa/Dublín',
        country_name: 'Ireland',
        country_name_el: 'Ιρλανδία',
        country_name_es: 'Irlanda',
        country_name_it: 'Irlanda',
        country_name_fr: 'Irlande',
        country_name_de: 'Irland',
        country_name_ca: 'Irlanda',
        country_capital: 'Dublin',
        country_capital_el: 'Δουβλίνο',
        country_capital_es: 'Dublín',
        country_capital_it: 'Dublino',
        country_capital_fr: 'Dublin',
        country_capital_de: 'Dublin',
        country_capital_ca: 'Dublín',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'IR',
        utc_offset: 44988,
        timezone: 'Asia/Tehran',
        timezone_el: 'Ασία/Τεχεράνη',
        timezone_es: 'Asia/Teherán',
        timezone_it: 'Asia/Teherán',
        timezone_fr: 'Asie/Téhéran',
        timezone_de: 'Asien/Teheran',
        timezone_ca: 'Àsia/Teheran',
        country_name: 'Iran',
        country_name_el: 'Ιράν',
        country_name_es: 'Irán',
        country_name_it: 'Iran',
        country_name_fr: 'Iran',
        country_name_de: 'Iran',
        country_name_ca: 'Iran',
        country_capital: 'Tehran',
        country_capital_el: 'Τεχεράνη',
        country_capital_es: 'Teherán',
        country_capital_it: 'Teheran',
        country_capital_fr: 'Téhéran',
        country_capital_de: 'Teheran',
        country_capital_ca: 'Teheran',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'IQ',
        utc_offset: 3,
        timezone: 'Asia/Baghdad',
        timezone_el: 'Ασία/Βαγδάτη',
        timezone_es: 'Asia/Bagdad',
        timezone_it: 'Asia/Bagdad',
        timezone_fr: 'Asie/Bagdad',
        timezone_de: 'Asien/Bagdad',
        timezone_ca: 'Àsia/Bagdad',
        country_name: 'Iraq',
        country_name_el: 'Ιράκ',
        country_name_es: 'Irak',
        country_name_it: 'Iraq',
        country_name_fr: 'Irak',
        country_name_de: 'Irak',
        country_name_ca: 'Iraq',
        country_capital: 'Baghdad',
        country_capital_el: 'Βαγδάτη',
        country_capital_es: 'Bagdad',
        country_capital_it: 'Baghdad',
        country_capital_fr: 'Bagdad',
        country_capital_de: 'Bagdad',
        country_capital_ca: 'Bagdad',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'IS',
        utc_offset: 0,
        timezone: 'Atlantic/Reykjavik',
        timezone_el: 'Ατλαντικός/Ρέυκιαβικ',
        timezone_es: 'Atlántico/Reikiavik',
        timezone_it: 'Atlántico/Reikiavik',
        timezone_fr: 'Atlantique/Reykjavik',
        timezone_de: 'Atlantik/Reykjavik',
        timezone_ca: 'Atlàntic/Reykjavik',
        country_name: 'Iceland',
        country_name_el: 'Ισλανδία',
        country_name_es: 'Islandia',
        country_name_it: 'Islanda',
        country_name_fr: 'Islande',
        country_name_de: 'Island',
        country_name_ca: 'Islàndia',
        country_capital: 'Reykjavik',
        country_capital_el: 'Ρέικιαβικ',
        country_capital_es: 'Reikiavik',
        country_capital_it: 'Reykjavik',
        country_capital_fr: 'Reykjavik',
        country_capital_de: 'Reykjavik',
        country_capital_ca: 'Reykjavik',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'IL',
        utc_offset: 2,
        timezone: 'Asia/Jerusalem',
        timezone_el: 'Ασία/Ιερουσαλήμ',
        timezone_es: 'Asia/Jerusalén',
        timezone_it: 'Asia/Jerusalén',
        timezone_fr: 'Asie/Jérusalem',
        timezone_de: 'Asien/Jerusalem',
        timezone_ca: 'Àsia/Jerusalem',
        country_name: 'Israel',
        country_name_el: 'Ισραήλ',
        country_name_es: 'Israel',
        country_name_it: 'Israele',
        country_name_fr: 'Israël',
        country_name_de: 'Israel',
        country_name_ca: 'Israel',
        country_capital: 'Jerusalem',
        country_capital_el: 'Ιερουσαλήμ',
        country_capital_es: 'Jerusalén',
        country_capital_it: 'Gerusalemme',
        country_capital_fr: 'Jérusalem',
        country_capital_de: 'Jerusalem',
        country_capital_ca: 'Jerusalem',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'IT',
        utc_offset: 1,
        timezone: 'Europe/Rome',
        timezone_el: 'Ευρώπη/Ρώμη',
        timezone_es: 'Europa/Roma',
        timezone_it: 'Europa/Roma',
        timezone_fr: 'Europe/Rome',
        timezone_de: 'Europa/Rom',
        timezone_ca: 'Europa/Roma',
        country_name: 'Italy',
        country_name_el: 'Ιταλία',
        country_name_es: 'Italia',
        country_name_it: 'Italia',
        country_name_fr: 'Italie',
        country_name_de: 'Italien',
        country_name_ca: 'Itàlia',
        country_capital: 'Rome',
        country_capital_el: 'Ρώμη',
        country_capital_es: 'Roma',
        country_capital_it: 'Roma',
        country_capital_fr: 'Rome',
        country_capital_de: 'Rom',
        country_capital_ca: 'Roma',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'JM',
        utc_offset: -5,
        timezone: 'America/Jamaica',
        timezone_el: 'Αμερική/Τζαμάικα',
        timezone_es: 'América/Jamaica',
        timezone_it: 'América/Jamaica',
        timezone_fr: 'Amérique/Jamaïque',
        timezone_de: 'Amerika/Jamaika',
        timezone_ca: 'Amèrica/Jamaica',
        country_name: 'Jamaica',
        country_name_el: 'Τζαμάικα',
        country_name_es: 'Jamaica',
        country_name_it: 'Giamaica',
        country_name_fr: 'Jamaïque',
        country_name_de: 'Jamaika',
        country_name_ca: 'Jamaica',
        country_capital: 'Kingston',
        country_capital_el: 'Κίνγκστον',
        country_capital_es: 'Kingston',
        country_capital_it: 'Kingston',
        country_capital_fr: 'Kingston',
        country_capital_de: 'Kingston',
        country_capital_ca: 'Kingston',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'America del Nord',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'JE',
        utc_offset: 0,
        timezone: 'Europe/Jersey',
        timezone_el: 'Ευρώπη/Τζέρσεϊ',
        timezone_es: 'Europa/Jersey',
        timezone_it: 'Europa/Jersey',
        timezone_fr: 'Europe/Jersey',
        timezone_de: 'Europa/Jersey',
        timezone_ca: 'Europa/Jersey',
        country_name: 'Jersey',
        country_name_el: 'Τζέρσεϊ',
        country_name_es: 'Jersey',
        country_name_it: 'Jersey',
        country_name_fr: 'Jersey',
        country_name_de: 'Jersey',
        country_name_ca: 'Jersey',
        country_capital: 'Saint Helier',
        country_capital_el: 'Σεντ Χέλιερ',
        country_capital_es: 'Saint Helier',
        country_capital_it: 'Saint Helier',
        country_capital_fr: 'Saint-Hélier',
        country_capital_de: 'Saint Helier',
        country_capital_ca: 'Saint Helier',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europa',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'JO',
        utc_offset: 2,
        timezone: 'Asia/Amman',
        timezone_el: 'Ασία/Αμμάν',
        timezone_es: 'Asia/Amán',
        timezone_it: 'Asia/Amán',
        timezone_fr: 'Asie/Amman',
        timezone_de: 'Asien/Amman',
        timezone_ca: 'Àsia/Amman',
        country_name: 'Jordan',
        country_name_el: 'Ιορδανία',
        country_name_es: 'Jordania',
        country_name_it: 'Giordania',
        country_name_fr: 'Jordanie',
        country_name_de: 'Jordanien',
        country_name_ca: 'Jordània',
        country_capital: 'Amman',
        country_capital_el: 'Αμμάν',
        country_capital_es: 'Ammán',
        country_capital_it: 'Amman',
        country_capital_fr: 'Amman',
        country_capital_de: 'Amman',
        country_capital_ca: 'Ammà',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'JP',
        utc_offset: 9,
        timezone: 'Asia/Tokyo',
        timezone_el: 'Ασία/Τόκυο',
        timezone_es: 'Asia/Tokio',
        timezone_it: 'Asia/Tokio',
        timezone_fr: 'Asie/Tokyo',
        timezone_de: 'Asien/Tokio',
        timezone_ca: 'Àsia/Tokio',
        country_name: 'Japan',
        country_name_el: 'Ιαπωνία',
        country_name_es: 'Japón',
        country_name_it: 'Giappone',
        country_name_fr: 'Japon',
        country_name_de: 'Japan',
        country_name_ca: 'Japó',
        country_capital: 'Tokyo',
        country_capital_el: 'Τόκιο',
        country_capital_es: 'Tokio',
        country_capital_it: 'Tokyo',
        country_capital_fr: 'Tokyo',
        country_capital_de: 'Tokio',
        country_capital_ca: 'Tòquio',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'KZ',
        utc_offset: 5,
        timezone: 'Asia/Almaty',
        timezone_el: 'Ασία/Αλμάτυ',
        timezone_es: 'Asia/Almaty',
        timezone_it: 'Asia/Almaty',
        timezone_fr: 'Asie/Almaty',
        timezone_de: 'Asien/Almaty',
        timezone_ca: 'Àsia/Almaty',
        country_name: 'Kazakhstan',
        country_name_el: 'Καζακστάν',
        country_name_es: 'Kazajistán',
        country_name_it: 'Kazakistan',
        country_name_fr: 'Kazakhstan',
        country_name_de: 'Kasachstan',
        country_name_ca: 'Kazakhstan',
        country_capital: 'Astana',
        country_capital_el: 'Αστάνα',
        country_capital_es: 'Astaná',
        country_capital_it: 'Astana',
        country_capital_fr: 'Astana',
        country_capital_de: 'Astana',
        country_capital_ca: 'Astana',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'KZ',
        utc_offset: 5,
        timezone: 'Asia/Qyzylorda',
        timezone_el: 'Ασία/Κιζιλ_Ορντά',
        timezone_es: 'Asia/Qyzylorda',
        timezone_it: 'Asia/Qyzylorda',
        timezone_fr: 'Asie/Qyzylorda',
        timezone_de: 'Asien/Qyzylorda',
        timezone_ca: 'Àsia/Qyzylorda',
        country_name: 'Kazakhstan',
        country_name_el: 'Καζακστάν',
        country_name_es: 'Kazajistán',
        country_name_it: 'Kazakistan',
        country_name_fr: 'Kazakhstan',
        country_name_de: 'Kasachstan',
        country_name_ca: 'Kazakhstan',
        country_capital: 'Astana',
        country_capital_el: 'Αστάνα',
        country_capital_es: 'Astaná',
        country_capital_it: 'Astana',
        country_capital_fr: 'Astana',
        country_capital_de: 'Astana',
        country_capital_ca: 'Astana',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'KZ',
        utc_offset: 5,
        timezone: 'Asia/Aqtobe',
        timezone_el: 'Ασία/Ακτάου',
        timezone_es: 'Asia/Aqtöbe',
        timezone_it: 'Asia/Aqtobe',
        timezone_fr: 'Asie/Aqtöbe',
        timezone_de: 'Asien/Aqtobe',
        timezone_ca: 'Àsia/Aqtobe',
        country_name: 'Kazakhstan',
        country_name_el: 'Καζακστάν',
        country_name_es: 'Kazajistán',
        country_name_it: 'Kazakistan',
        country_name_fr: 'Kazakhstan',
        country_name_de: 'Kasachstan',
        country_name_ca: 'Kazakhstan',
        country_capital: 'Astana',
        country_capital_el: 'Αστάνα',
        country_capital_es: 'Astaná',
        country_capital_it: 'Astana',
        country_capital_fr: 'Astana',
        country_capital_de: 'Astana',
        country_capital_ca: 'Astana',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'KZ',
        utc_offset: 5,
        timezone: 'Asia/Aqtau',
        timezone_el: 'Ασία/Ακτάου',
        timezone_es: 'Asia/Aktau',
        timezone_it: 'Asia/Aqtau',
        timezone_fr: 'Asie/Aktaou',
        timezone_de: 'Asien/Aqtau',
        timezone_ca: 'Àsia/Aqtau',
        country_name: 'Kazakhstan',
        country_name_el: 'Καζακστάν',
        country_name_es: 'Kazajistán',
        country_name_it: 'Kazakistan',
        country_name_fr: 'Kazakhstan',
        country_name_de: 'Kasachstan',
        country_name_ca: 'Kazakhstan',
        country_capital: 'Astana',
        country_capital_el: 'Αστάνα',
        country_capital_es: 'Astaná',
        country_capital_it: 'Astana',
        country_capital_fr: 'Astana',
        country_capital_de: 'Astana',
        country_capital_ca: 'Astana',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'KZ',
        utc_offset: 5,
        timezone: 'Asia/Oral',
        timezone_el: 'Ασία/Οράλ',
        timezone_es: 'Asia/Oral',
        timezone_it: 'Asia/Oral',
        timezone_fr: 'Asie/Oral',
        timezone_de: 'Asien/Oral',
        timezone_ca: 'Àsia/Oral',
        country_name: 'Kazakhstan',
        country_name_el: 'Καζακστάν',
        country_name_es: 'Kazajistán',
        country_name_it: 'Kazakistan',
        country_name_fr: 'Kazakhstan',
        country_name_de: 'Kasachstan',
        country_name_ca: 'Kazakhstan',
        country_capital: 'Astana',
        country_capital_el: 'Αστάνα',
        country_capital_es: 'Astaná',
        country_capital_it: 'Astana',
        country_capital_fr: 'Astana',
        country_capital_de: 'Astana',
        country_capital_ca: 'Astana',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'KE',
        utc_offset: 3,
        timezone: 'Africa/Nairobi',
        timezone_el: 'Αφρική/Ναϊρόμπι',
        timezone_es: 'África/Nairobi',
        timezone_it: 'África/Nairobi',
        timezone_fr: 'Afrique/Nairobi',
        timezone_de: 'Afrika/Nairobi',
        timezone_ca: 'Àfrica/Nairobi',
        country_name: 'Kenya',
        country_name_el: 'Κένυα',
        country_name_es: 'Kenia',
        country_name_it: 'Kenya',
        country_name_fr: 'Kenya',
        country_name_de: 'Kenia',
        country_name_ca: 'Kènia',
        country_capital: 'Nairobi',
        country_capital_el: 'Ναϊρόμπι',
        country_capital_es: 'Nairobi',
        country_capital_it: 'Nairobi',
        country_capital_fr: 'Nairobi',
        country_capital_de: 'Nairobi',
        country_capital_ca: 'Nairobi',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'KG',
        utc_offset: 6,
        timezone: 'Asia/Bishkek',
        timezone_el: 'Ασία/Μπισκέκ',
        timezone_es: 'Asia/Biskek',
        timezone_it: 'Asia/Biskek',
        timezone_fr: 'Asie/Bichkek',
        timezone_de: 'Asien/Bischkek',
        timezone_ca: 'Àsia/Bishkek',
        country_name: 'Kyrgyzstan',
        country_name_el: 'Κιργιζιστάν',
        country_name_es: 'Kirguistán',
        country_name_it: 'Kirghizistan',
        country_name_fr: 'Kirghizistan',
        country_name_de: 'Kirgisistan',
        country_name_ca: 'Kirguizistan',
        country_capital: 'Bishkek',
        country_capital_el: 'Μπισκέκ',
        country_capital_es: 'Biskek',
        country_capital_it: 'Bishkek',
        country_capital_fr: 'Bichkek',
        country_capital_de: 'Bischkek',
        country_capital_ca: 'Biškek',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'KH',
        utc_offset: 7,
        timezone: 'Asia/Phnom_Penh',
        timezone_el: 'Ασία/Πνομ_Πεν',
        timezone_es: 'Asia/Phnom_Penh',
        timezone_it: 'Asia/Phnom_Penh',
        timezone_fr: 'Asie/Phnom_Penh',
        timezone_de: 'Asien/Phnom_Penh',
        timezone_ca: 'Àsia/Phnom_Penh',
        country_name: 'Cambodia',
        country_name_el: 'Καμπότζη',
        country_name_es: 'Camboya',
        country_name_it: 'Cambogia',
        country_name_fr: 'Cambodge',
        country_name_de: 'Kambodscha',
        country_name_ca: 'Cambodja',
        country_capital: 'Phnom Penh',
        country_capital_el: 'Πνομ Πεν',
        country_capital_es: 'Nom Pen',
        country_capital_it: 'Phnom Penh',
        country_capital_fr: 'Phnom Penh',
        country_capital_de: 'Phnom Penh',
        country_capital_ca: 'Phnom Penh',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'KI',
        utc_offset: 12,
        timezone: 'Pacific/Tarawa',
        timezone_el: 'Ειρηνικός/Ταράουα',
        timezone_es: 'Pacífico/Tarawa',
        timezone_it: 'Pacífico/Tarawa',
        timezone_fr: 'Pacifique/Tarawa',
        timezone_de: 'Pazifik/Tarawa',
        timezone_ca: 'Pacífic/Tarawa',
        country_name: 'Kiribati',
        country_name_el: 'Κιριμπάτι',
        country_name_es: 'Kiribati',
        country_name_it: 'Kiribati',
        country_name_fr: 'Kiribati',
        country_name_de: 'Kiribati',
        country_name_ca: 'Kiribati',
        country_capital: 'South Tarawa',
        country_capital_el: 'Σάουθ Ταραουά',
        country_capital_es: 'South Tarawa',
        country_capital_it: 'South Tarawa',
        country_capital_fr: 'South Tarawa',
        country_capital_de: 'South Tarawa',
        country_capital_ca: 'South Tarawa',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'KI',
        utc_offset: 12,
        timezone: 'Pacific/Enderbury',
        timezone_el: 'Ειρηνικός/Έντερμπερι',
        timezone_es: 'Pacífico/Enderbury',
        timezone_it: 'Pacífico/Enderbury',
        timezone_fr: 'Pacifique/Enderbury',
        timezone_de: 'Pazifik/Enderbury',
        timezone_ca: 'Pacífic/Enderbury',
        country_name: 'Kiribati',
        country_name_el: 'Κιριμπάτι',
        country_name_es: 'Kiribati',
        country_name_it: 'Kiribati',
        country_name_fr: 'Kiribati',
        country_name_de: 'Kiribati',
        country_name_ca: 'Kiribati',
        country_capital: 'South Tarawa',
        country_capital_el: 'Σάουθ Ταραουά',
        country_capital_es: 'South Tarawa',
        country_capital_it: 'South Tarawa',
        country_capital_fr: 'South Tarawa',
        country_capital_de: 'South Tarawa',
        country_capital_ca: 'South Tarawa',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'KI',
        utc_offset: 12,
        timezone: 'Pacific/Kiritimati',
        timezone_el: 'Ειρηνικός/Κιριτιμάτι',
        timezone_es: 'Pacífico/Kiritimati',
        timezone_it: 'Pacífico/Kiritimati',
        timezone_fr: 'Pacifique/Kiritimati',
        timezone_de: 'Pazifik/Kiritimati',
        timezone_ca: 'Pacífic/Kiritimati',
        country_name: 'Kiribati',
        country_name_el: 'Κιριμπάτι',
        country_name_es: 'Kiribati',
        country_name_it: 'Kiribati',
        country_name_fr: 'Kiribati',
        country_name_de: 'Kiribati',
        country_name_ca: 'Kiribati',
        country_capital: 'South Tarawa',
        country_capital_el: 'Σάουθ Ταραουά',
        country_capital_es: 'South Tarawa',
        country_capital_it: 'South Tarawa',
        country_capital_fr: 'South Tarawa',
        country_capital_de: 'South Tarawa',
        country_capital_ca: 'South Tarawa',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'KN',
        utc_offset: -4,
        timezone: 'America/St_Kitts',
        timezone_el: 'Αμερική/Σεντ_Κιτς',
        timezone_es: 'América/San_Cristóbal_y_Nieves',
        timezone_it: 'América/San_Cristóbal_y_Nieves',
        timezone_fr: 'Amérique/Saint-Christophe-et-Niévès',
        timezone_de: 'Amerika/St_Kitts',
        timezone_ca: 'Amèrica/St_Kitts',
        country_name: 'Saint Kitts and Nevis',
        country_name_el: 'Άγιος Χριστόφορος και Νέβις',
        country_name_es: 'San Cristóbal y Nieves',
        country_name_it: 'Saint Kitts e Nevis',
        country_name_fr: 'Saint-Christophe-et-Niévès',
        country_name_de: 'St. Kitts und Nevis',
        country_name_ca: 'Sant Cristòfol i Nevis',
        country_capital: 'Basseterre',
        country_capital_el: 'Μπασετέρ',
        country_capital_es: 'Basseterre',
        country_capital_it: 'Basseterre',
        country_capital_fr: 'Basseterre',
        country_capital_de: 'Basseterre',
        country_capital_ca: 'Basseterre',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'KR',
        utc_offset: 9,
        timezone: 'Asia/Seoul',
        timezone_el: 'Ασία/Σεούλ',
        timezone_es: 'Asia/Seúl',
        timezone_it: 'Asia/Seúl',
        timezone_fr: 'Asie/Séoul',
        timezone_de: 'Asien/Seoul',
        timezone_ca: 'Àsia/Seül',
        country_name: 'South Korea',
        country_name_el: 'Νότια Κορέα',
        country_name_es: 'Corea del Sur',
        country_name_it: 'Corea del Sud',
        country_name_fr: 'Corée du Sud',
        country_name_de: 'Südkorea',
        country_name_ca: 'Corea del Sud',
        country_capital: 'Seoul',
        country_capital_el: 'Σεούλ',
        country_capital_es: 'Seúl',
        country_capital_it: 'Seul',
        country_capital_fr: 'Séoul',
        country_capital_de: 'Seoul',
        country_capital_ca: 'Seül',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'XK',
        utc_offset: 1,
        timezone: 'Europe/Belgrade',
        timezone_el: 'Ευρώπη/Βελιγράδι',
        timezone_es: 'Europa/Belgrado',
        timezone_it: 'Europa/Belgrado',
        timezone_fr: 'Europe/Belgrade',
        timezone_de: 'Europa/Belgrad',
        timezone_ca: 'Europa/Belgrad',
        country_name: 'Kosovo',
        country_name_el: 'Κόσοβο',
        country_name_es: 'Kosovo',
        country_name_it: 'Kosovo',
        country_name_fr: 'Kosovo',
        country_name_de: 'Kosovo',
        country_name_ca: 'Kosovo',
        country_capital: 'Pristina',
        country_capital_el: 'Πρίστινα',
        country_capital_es: 'Pristina',
        country_capital_it: 'Pristina',
        country_capital_fr: 'Pristina',
        country_capital_de: 'Pristina',
        country_capital_ca: 'Pristina',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'KW',
        utc_offset: 3,
        timezone: 'Asia/Kuwait',
        timezone_el: 'Ασία/Κουβέιτ',
        timezone_es: 'Asia/Kuwait',
        timezone_it: 'Asia/Kuwait',
        timezone_fr: 'Asie/Koweït',
        timezone_de: 'Asien/Kuwait',
        timezone_ca: 'Àsia/Kuwait',
        country_name: 'Kuwait',
        country_name_el: 'Κουβέιτ',
        country_name_es: 'Kuwait',
        country_name_it: 'Kuwait',
        country_name_fr: 'Koweït',
        country_name_de: 'Kuwait',
        country_name_ca: 'Kuwait',
        country_capital: 'Kuwait City',
        country_capital_el: 'Κουβέιτ Σίτι',
        country_capital_es: 'Ciudad de Kuwait',
        country_capital_it: 'Città del Kuwait',
        country_capital_fr: 'Koweït',
        country_capital_de: 'Kuwait Stadt',
        country_capital_ca: 'Ciutat de Kuwait',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'LA',
        utc_offset: 7,
        timezone: 'Asia/Vientiane',
        timezone_el: 'Ασία/Βιεντιάν',
        timezone_es: 'Asia/Vientián',
        timezone_it: 'Asia/Vientián',
        timezone_fr: 'Asie/Vientiane',
        timezone_de: 'Asien/Vientiane',
        timezone_ca: 'Àsia/Vientiane',
        country_name: 'Laos',
        country_name_el: 'Λάος',
        country_name_es: 'Laos',
        country_name_it: 'Laos',
        country_name_fr: 'Laos',
        country_name_de: 'Laos',
        country_name_ca: 'Laos',
        country_capital: 'Vientiane',
        country_capital_el: 'Βιεντιάν',
        country_capital_es: 'Vientián',
        country_capital_it: 'Vientiane',
        country_capital_fr: 'Vientiane',
        country_capital_de: 'Vientiane',
        country_capital_ca: 'Vientià',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'LB',
        utc_offset: 2,
        timezone: 'Asia/Beirut',
        timezone_el: 'Ασία/Βηρυτός',
        timezone_es: 'Asia/Beirut',
        timezone_it: 'Asia/Beirut',
        timezone_fr: 'Asie/Beyrouth',
        timezone_de: 'Asien/Beirut',
        timezone_ca: 'Àsia/Beirut',
        country_name: 'Lebanon',
        country_name_el: 'Λίβανος',
        country_name_es: 'Líbano',
        country_name_it: 'Libano',
        country_name_fr: 'Liban',
        country_name_de: 'Libanon',
        country_name_ca: 'Líban',
        country_capital: 'Beirut',
        country_capital_el: 'Βηρυτός',
        country_capital_es: 'Beirut',
        country_capital_it: 'Beirut',
        country_capital_fr: 'Beyrouth',
        country_capital_de: 'Beirut',
        country_capital_ca: 'Beirut',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'LR',
        utc_offset: 0,
        timezone: 'Africa/Monrovia',
        timezone_el: 'Αφρική/Μονρόβια',
        timezone_es: 'África/Monrovia',
        timezone_it: 'África/Monrovia',
        timezone_fr: 'Afrique/Monrovia',
        timezone_de: 'Afrika/Monrovia',
        timezone_ca: 'Àfrica/Monròvia',
        country_name: 'Liberia',
        country_name_el: 'Λιβερία',
        country_name_es: 'Liberia',
        country_name_it: 'Liberia',
        country_name_fr: 'Libéria',
        country_name_de: 'Liberia',
        country_name_ca: 'Libèria',
        country_capital: 'Monrovia',
        country_capital_el: 'Μονρόβια',
        country_capital_es: 'Monrovia',
        country_capital_it: 'Monrovia',
        country_capital_fr: 'Monrovia',
        country_capital_de: 'Monrovia',
        country_capital_ca: 'Monròvia',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'LY',
        utc_offset: 2,
        timezone: 'Africa/Tripoli',
        timezone_el: 'Αφρική/Τρίπολη',
        timezone_es: 'África/Trípoli',
        timezone_it: 'África/Trípoli',
        timezone_fr: 'Afrique/Tripoli',
        timezone_de: 'Afrika/Tripolis',
        timezone_ca: 'Àfrica/Trípoli',
        country_name: 'Libya',
        country_name_el: 'Λιβύη',
        country_name_es: 'Libia',
        country_name_it: 'Libia',
        country_name_fr: 'Libye',
        country_name_de: 'Libyen',
        country_name_ca: 'Líbia',
        country_capital: 'Tripoli',
        country_capital_el: 'Τρίπολη',
        country_capital_es: 'Trípoli',
        country_capital_it: 'Tripoli',
        country_capital_fr: 'Tripoli',
        country_capital_de: 'Tripolis',
        country_capital_ca: 'Trípoli',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'LC',
        utc_offset: -4,
        timezone: 'America/St_Lucia',
        timezone_el: 'Αμερική/Αγία_Λουκία',
        timezone_es: 'América/Santa_Lucía',
        timezone_it: 'América/Santa_Lucía',
        timezone_fr: 'Amérique/Sainte-Lucie',
        timezone_de: 'Amerika/St_Lucia',
        timezone_ca: 'Amèrica/Santa_Llúcia',
        country_name: 'Saint Lucia',
        country_name_el: 'Άγια Λουκία',
        country_name_es: 'Santa Lucía',
        country_name_it: 'Santa Lucia',
        country_name_fr: 'Sainte-Lucie',
        country_name_de: 'St. Lucia',
        country_name_ca: 'Santa Llúcia',
        country_capital: 'Castries',
        country_capital_el: 'Καστρίς',
        country_capital_es: 'Castries',
        country_capital_it: 'Castries',
        country_capital_fr: 'Castries',
        country_capital_de: 'Castries',
        country_capital_ca: 'Castries',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'LI',
        utc_offset: 1,
        timezone: 'Europe/Vaduz',
        timezone_el: 'Ευρώπη/Βαντούζ',
        timezone_es: 'Europa/Vaduz',
        timezone_it: 'Europa/Vaduz',
        timezone_fr: 'Europe/Vaduz',
        timezone_de: 'Europa/Vaduz',
        timezone_ca: 'Europa/Vaduz',
        country_name: 'Liechtenstein',
        country_name_el: 'Λιχτενστάιν',
        country_name_es: 'Liechtenstein',
        country_name_it: 'Liechtenstein',
        country_name_fr: 'Liechtenstein',
        country_name_de: 'Liechtenstein',
        country_name_ca: 'Liechtenstein',
        country_capital: 'Vaduz',
        country_capital_el: 'Βαντούζ',
        country_capital_es: 'Vaduz',
        country_capital_it: 'Vaduz',
        country_capital_fr: 'Vaduz',
        country_capital_de: 'Vaduz',
        country_capital_ca: 'Vaduz',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'LK',
        utc_offset: 44990,
        timezone: 'Asia/Colombo',
        timezone_el: 'Ασία/Κολόμπο',
        timezone_es: 'Asia/Colombo',
        timezone_it: 'Asia/Colombo',
        timezone_fr: 'Asie/Colombo',
        timezone_de: 'Asien/Colombo',
        timezone_ca: 'Àsia/Colombo',
        country_name: 'Sri Lanka',
        country_name_el: 'Σρι Λάνκα',
        country_name_es: 'Sri Lanka',
        country_name_it: 'Sri Lanka',
        country_name_fr: 'Sri Lanka',
        country_name_de: 'Sri Lanka',
        country_name_ca: 'Sri Lanka',
        country_capital: 'Colombo',
        country_capital_el: 'Κολόμπο',
        country_capital_es: 'Colombo',
        country_capital_it: 'Colombo',
        country_capital_fr: 'Colombo',
        country_capital_de: 'Colombo',
        country_capital_ca: 'Colombo',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'LS',
        utc_offset: 2,
        timezone: 'Africa/Maseru',
        timezone_el: 'Αφρική/Μασέρου',
        timezone_es: 'África/Maseru',
        timezone_it: 'África/Maseru',
        timezone_fr: 'Afrique/Maseru',
        timezone_de: 'Afrika/Maseru',
        timezone_ca: 'Àfrica/Maseru',
        country_name: 'Lesotho',
        country_name_el: 'Λεσότο',
        country_name_es: 'Lesoto',
        country_name_it: 'Lesotho',
        country_name_fr: 'Lesotho',
        country_name_de: 'Lesotho',
        country_name_ca: 'Lesotho',
        country_capital: 'Maseru',
        country_capital_el: 'Μασέρου',
        country_capital_es: 'Maseru',
        country_capital_it: 'Maseru',
        country_capital_fr: 'Maseru',
        country_capital_de: 'Maseru',
        country_capital_ca: 'Maseru',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'LT',
        utc_offset: 2,
        timezone: 'Europe/Vilnius',
        timezone_el: 'Ευρώπη/Βίλνιους',
        timezone_es: 'Europa/Vilna',
        timezone_it: 'Europa/Vilna',
        timezone_fr: 'Europe/Vilnius',
        timezone_de: 'Europa/Vilnius',
        timezone_ca: 'Europa/Vilnius',
        country_name: 'Lithuania',
        country_name_el: 'Λιθουανία',
        country_name_es: 'Lituania',
        country_name_it: 'Lituania',
        country_name_fr: 'Lituanie',
        country_name_de: 'Litauen',
        country_name_ca: 'Lituània',
        country_capital: 'Vilnius',
        country_capital_el: 'Βίλνιους',
        country_capital_es: 'Vilna',
        country_capital_it: 'Vilnius',
        country_capital_fr: 'Vilnius',
        country_capital_de: 'Vilnius',
        country_capital_ca: 'Vilnius',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'LU',
        utc_offset: 1,
        timezone: 'Europe/Luxembourg',
        timezone_el: 'Ευρώπη/Λουξεμβούργο',
        timezone_es: 'Europa/Luxemburgo',
        timezone_it: 'Europa/Luxemburgo',
        timezone_fr: 'Europe/Luxembourg',
        timezone_de: 'Europa/Luxemburg',
        timezone_ca: 'Europa/Luxemburg',
        country_name: 'Luxembourg',
        country_name_el: 'Λουξεμβούργο',
        country_name_es: 'Luxemburgo',
        country_name_it: 'Lussemburgo',
        country_name_fr: 'Luxembourg',
        country_name_de: 'Luxemburg',
        country_name_ca: 'Luxemburg',
        country_capital: 'Luxembourg',
        country_capital_el: 'Λουξεμβούργο',
        country_capital_es: 'Luxemburgo',
        country_capital_it: 'Lussemburgo',
        country_capital_fr: 'Luxembourg',
        country_capital_de: 'Luxemburg',
        country_capital_ca: 'Luxemburg',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'LV',
        utc_offset: 2,
        timezone: 'Europe/Riga',
        timezone_el: 'Ευρώπη/Ρίγα',
        timezone_es: 'Europa/Riga',
        timezone_it: 'Europa/Riga',
        timezone_fr: 'Europe/Riga',
        timezone_de: 'Europa/Riga',
        timezone_ca: 'Europa/Riga',
        country_name: 'Latvia',
        country_name_el: 'Λετονία',
        country_name_es: 'Letonia',
        country_name_it: 'Lettonia',
        country_name_fr: 'Lettonie',
        country_name_de: 'Lettland',
        country_name_ca: 'Letònia',
        country_capital: 'Riga',
        country_capital_el: 'Ρίγα',
        country_capital_es: 'Riga',
        country_capital_it: 'Riga',
        country_capital_fr: 'Riga',
        country_capital_de: 'Riga',
        country_capital_ca: 'Riga',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'MO',
        utc_offset: 8,
        timezone: 'Asia/Macau',
        timezone_el: 'Ασία/Μακάο',
        timezone_es: 'Asia/Macao',
        timezone_it: 'Asia/Macao',
        timezone_fr: 'Asie/Macao',
        timezone_de: 'Asien/Macao',
        timezone_ca: 'Àsia/Macau',
        country_name: 'Macau',
        country_name_el: 'Μακάο',
        country_name_es: 'Macao',
        country_name_it: 'Macao',
        country_name_fr: 'Macao',
        country_name_de: 'Macau',
        country_name_ca: 'Macau',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'MF',
        utc_offset: -4,
        timezone: 'America/Marigot',
        timezone_el: 'Αμερική/Μαριγκώ',
        timezone_es: 'América/Marigot',
        timezone_it: 'América/Marigot',
        timezone_fr: 'Amérique/Marigot',
        timezone_de: 'Amerika/Marigot',
        timezone_ca: 'Amèrica/Marigot',
        country_name: 'Saint Martin',
        country_name_el: 'Άγιος Μαρτίνος',
        country_name_es: 'San Martín',
        country_name_it: 'Saint Martin',
        country_name_fr: 'Saint-Martin',
        country_name_de: 'Saint-Martin',
        country_name_ca: 'Saint Martin',
        country_capital: 'Marigot',
        country_capital_el: 'Μαριγκό',
        country_capital_es: 'Marigot',
        country_capital_it: 'Marigot',
        country_capital_fr: 'Marigot',
        country_capital_de: 'Marigot',
        country_capital_ca: 'Marigot',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MA',
        utc_offset: 0,
        timezone: 'Africa/Casablanca',
        timezone_el: 'Αφρική/Καζαμπλάνκα',
        timezone_es: 'África/Casablanca',
        timezone_it: 'África/Casablanca',
        timezone_fr: 'Afrique/Casablanca',
        timezone_de: 'Afrika/Casablanca',
        timezone_ca: 'Àfrica/Casablanca',
        country_name: 'Morocco',
        country_name_el: 'Μαρόκο',
        country_name_es: 'Marruecos',
        country_name_it: 'Marocco',
        country_name_fr: 'Maroc',
        country_name_de: 'Marokko',
        country_name_ca: 'Marroc',
        country_capital: 'Rabat',
        country_capital_el: 'Ραμπάτ',
        country_capital_es: 'Rabat',
        country_capital_it: 'Rabat',
        country_capital_fr: 'Rabat',
        country_capital_de: 'Rabat',
        country_capital_ca: 'Rabat',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'MC',
        utc_offset: 1,
        timezone: 'Europe/Monaco',
        timezone_el: 'Ευρώπη/Μονακό',
        timezone_es: 'Europa/Mónaco',
        timezone_it: 'Europa/Mónaco',
        timezone_fr: 'Europe/Monaco',
        timezone_de: 'Europa/Monaco',
        timezone_ca: 'Europa/Mònaco',
        country_name: 'Monaco',
        country_name_el: 'Μονακό',
        country_name_es: 'Mónaco',
        country_name_it: 'Monaco',
        country_name_fr: 'Monaco',
        country_name_de: 'Monaco',
        country_name_ca: 'Mònaco',
        country_capital: 'Monaco',
        country_capital_el: 'Μονακό',
        country_capital_es: 'Mónaco',
        country_capital_it: 'Monaco',
        country_capital_fr: 'Monaco',
        country_capital_de: 'Monaco',
        country_capital_ca: 'Mònaco',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'MD',
        utc_offset: 2,
        timezone: 'Europe/Chisinau',
        timezone_el: 'Ευρώπη/Κισινάου',
        timezone_es: 'Europa/Chisináu',
        timezone_it: 'Europa/Chisináu',
        timezone_fr: 'Europe/Chisinau',
        timezone_de: 'Europa/Chisinau',
        timezone_ca: 'Europa/Chisinau',
        country_name: 'Moldova',
        country_name_el: 'Μολδαβία',
        country_name_es: 'Moldavia',
        country_name_it: 'Moldavia',
        country_name_fr: 'Moldavie',
        country_name_de: 'Moldawien',
        country_name_ca: 'Moldàvia',
        country_capital: 'Chișinău',
        country_capital_el: 'Κισινάου',
        country_capital_es: 'Chisináu',
        country_capital_it: 'Chișinău',
        country_capital_fr: 'Chișinău',
        country_capital_de: 'Chișinău',
        country_capital_ca: 'Chișinău',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'MG',
        utc_offset: 3,
        timezone: 'Indian/Antananarivo',
        timezone_el: 'Ινδικός/Ανταναναρίβο',
        timezone_es: 'India/Antananarivo',
        timezone_it: 'Indico/Antananarivo',
        timezone_fr: 'Indien/Antananarivo',
        timezone_de: 'Indischer/Ozean/Antananarivo',
        timezone_ca: 'Índic/Antananarivo',
        country_name: 'Madagascar',
        country_name_el: 'Μαδαγασκάρη',
        country_name_es: 'Madagascar',
        country_name_it: 'Madagascar',
        country_name_fr: 'Madagascar',
        country_name_de: 'Madagaskar',
        country_name_ca: 'Madagascar',
        country_capital: 'Antananarivo',
        country_capital_el: 'Ανταναναρίβου',
        country_capital_es: 'Antananarivo',
        country_capital_it: 'Antananarivo',
        country_capital_fr: 'Antananarivo',
        country_capital_de: 'Antananarivo',
        country_capital_ca: 'Antananarivo',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'MV',
        utc_offset: 5,
        timezone: 'Indian/Maldives',
        timezone_el: 'Ινδικός/Μαλδίβες',
        timezone_es: 'India/Maldivas',
        timezone_it: 'Indico/Maldivas',
        timezone_fr: 'Indien/Maldives',
        timezone_de: 'Indischer/Ozean/Malediven',
        timezone_ca: 'Índic/Maldives',
        country_name: 'Maldives',
        country_name_el: 'Μαλδίβες',
        country_name_es: 'Maldivas',
        country_name_it: 'Maldive',
        country_name_fr: 'Maldives',
        country_name_de: 'Malediven',
        country_name_ca: 'Maldives',
        country_capital: 'Malé',
        country_capital_el: 'Μαλέ',
        country_capital_es: 'Malé',
        country_capital_it: 'Malé',
        country_capital_fr: 'Malé',
        country_capital_de: 'Malé',
        country_capital_ca: 'Malé',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Mexico_City',
        timezone_el: 'Αμερική/Πόλη_του_Μεξικού',
        timezone_es: 'América/Ciudad_de_México',
        timezone_it: 'América/Ciudad_de_México',
        timezone_fr: 'Amérique/Mexico',
        timezone_de: 'Amerika/Mexiko-Stadt',
        timezone_ca: 'Amèrica/Ciutat_de_Mèxic',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Πόλη του Μεξικού',
        country_capital_es: 'Ciudad de México',
        country_capital_it: 'Città del Messico',
        country_capital_fr: 'Mexico',
        country_capital_de: 'Mexiko-Stadt',
        country_capital_ca: 'Ciutat de Mèxic',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Cancun',
        timezone_el: 'Αμερική/Κανκούν',
        timezone_es: 'América/Cancún',
        timezone_it: 'América/Cancún',
        timezone_fr: 'Amérique/Cancún',
        timezone_de: 'Amerika/Cancun',
        timezone_ca: 'Amèrica/Cancun',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Πόλη του Μεξικού',
        country_capital_es: 'Ciudad de México',
        country_capital_it: 'Città del Messico',
        country_capital_fr: 'Mexico',
        country_capital_de: 'Mexiko-Stadt',
        country_capital_ca: 'Ciutat de Mèxic',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Merida',
        timezone_el: 'Αμερική/Μέριδα',
        timezone_es: 'América/Mérida',
        timezone_it: 'América/Mérida',
        timezone_fr: 'Amérique/Mérida',
        timezone_de: 'Amerika/Merida',
        timezone_ca: 'Amèrica/Merida',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Πόλη του Μεξικού',
        country_capital_es: 'Ciudad de México',
        country_capital_it: 'Città del Messico',
        country_capital_fr: 'Mexico',
        country_capital_de: 'Mexiko-Stadt',
        country_capital_ca: 'Ciutat de Mèxic',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Monterrey',
        timezone_el: 'Αμερική/Μοντερέι',
        timezone_es: 'América/Monterrey',
        timezone_it: 'América/Monterrey',
        timezone_fr: 'Amérique/Monterrey',
        timezone_de: 'Amerika/Monterrey',
        timezone_ca: 'Amèrica/Monterrey',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Πόλη του Μεξικού',
        country_capital_es: 'Ciudad de México',
        country_capital_it: 'Città del Messico',
        country_capital_fr: 'Mexico',
        country_capital_de: 'Mexiko-Stadt',
        country_capital_ca: 'Ciutat de Mèxic',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Matamoros',
        timezone_el: 'Αμερική/Ματαμόρος',
        timezone_es: 'América/Matamoros',
        timezone_it: 'América/Matamoros',
        timezone_fr: 'Amérique/Matamoros',
        timezone_de: 'Amerika/Matamoros',
        timezone_ca: 'Amèrica/Matamoros',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Πόλη του Μεξικού',
        country_capital_es: 'Ciudad de México',
        country_capital_it: 'Città del Messico',
        country_capital_fr: 'Mexico',
        country_capital_de: 'Mexiko-Stadt',
        country_capital_ca: 'Ciutat de Mèxic',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Mazatlan',
        timezone_el: 'Αμερική/Μαζατλάν',
        timezone_es: 'América/Mazatlán',
        timezone_it: 'América/Mazatlán',
        timezone_fr: 'Amérique/Mazatlán',
        timezone_de: 'Amerika/Mazatlan',
        timezone_ca: 'Amèrica/Mazatlan',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Πόλη του Μεξικού',
        country_capital_es: 'Ciudad de México',
        country_capital_it: 'Città del Messico',
        country_capital_fr: 'Mexico',
        country_capital_de: 'Mexiko-Stadt',
        country_capital_ca: 'Ciutat de Mèxic',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Chihuahua',
        timezone_el: 'Αμερική/Τσιουάουα',
        timezone_es: 'América/Chihuahua',
        timezone_it: 'América/Chihuahua',
        timezone_fr: 'Amérique/Chihuahua',
        timezone_de: 'Amerika/Chihuahua',
        timezone_ca: 'Amèrica/Chihuahua',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Πόλη του Μεξικού',
        country_capital_es: 'Ciudad de México',
        country_capital_it: 'Città del Messico',
        country_capital_fr: 'Mexico',
        country_capital_de: 'Mexiko-Stadt',
        country_capital_ca: 'Ciutat de Mèxic',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Ojinaga',
        timezone_el: 'Αμερική/Οχινάγκα',
        timezone_es: 'América/Ojinaga',
        timezone_it: 'América/Ojinaga',
        timezone_fr: 'Amérique/Ojinaga',
        timezone_de: 'Amerika/Ojinaga',
        timezone_ca: 'Amèrica/Ojinaga',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Πόλη του Μεξικού',
        country_capital_es: 'Ciudad de México',
        country_capital_it: 'Città del Messico',
        country_capital_fr: 'Mexico',
        country_capital_de: 'Mexiko-Stadt',
        country_capital_ca: 'Ciutat de Mèxic',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Hermosillo',
        timezone_el: 'Αμερική/Ερμοσίγιο',
        timezone_es: 'América/Hermosillo',
        timezone_it: 'América/Hermosillo',
        timezone_fr: 'Amérique/Hermosillo',
        timezone_de: 'Amerika/Hermosillo',
        timezone_ca: 'Amèrica/Hermosillo',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Πόλη του Μεξικού',
        country_capital_es: 'Ciudad de México',
        country_capital_it: 'Città del Messico',
        country_capital_fr: 'Mexico',
        country_capital_de: 'Mexiko-Stadt',
        country_capital_ca: 'Ciutat de Mèxic',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Tijuana',
        timezone_el: 'Αμερική/Τιχουάνα',
        timezone_es: 'América/Tijuana',
        timezone_it: 'América/Tijuana',
        timezone_fr: 'Amérique/Tijuana',
        timezone_de: 'Amerika/Tijuana',
        timezone_ca: 'Amèrica/Tijuana',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Πόλη του Μεξικού',
        country_capital_es: 'Ciudad de México',
        country_capital_it: 'Città del Messico',
        country_capital_fr: 'Mexico',
        country_capital_de: 'Mexiko-Stadt',
        country_capital_ca: 'Ciutat de Mèxic',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MX',
        utc_offset: -6,
        timezone: 'America/Bahia_Banderas',
        timezone_el: 'Αμερική/Μπαχία_Μπαντέρας',
        timezone_es: 'América/Bahía_Banderas',
        timezone_it: 'América/Bahía_Banderas',
        timezone_fr: 'Amérique/Bahia_Banderas',
        timezone_de: 'Amerika/Bahia_Banderas',
        timezone_ca: 'Amèrica/Bahia_Banderas',
        country_name: 'Mexico',
        country_name_el: 'Μεξικό',
        country_name_es: 'México',
        country_name_it: 'Messico',
        country_name_fr: 'Mexique',
        country_name_de: 'Mexiko',
        country_name_ca: 'Mèxic',
        country_capital: 'Mexico City',
        country_capital_el: 'Ματζούρο',
        country_capital_es: 'Majuro',
        country_capital_it: 'Majuro',
        country_capital_fr: 'Majuro',
        country_capital_de: 'Majuro',
        country_capital_ca: 'Majuro',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MH',
        utc_offset: 12,
        timezone: 'Pacific/Majuro',
        timezone_el: 'Ειρηνικός/Ματζούρο',
        timezone_es: 'Pacífico/Majuro',
        timezone_it: 'Pacífico/Majuro',
        timezone_fr: 'Pacifique/Majuro',
        timezone_de: 'Pazifik/Majuro',
        timezone_ca: 'Pacífic/Majuro',
        country_name: 'Marshall Islands',
        country_name_el: 'Νήσοι Μάρσαλ',
        country_name_es: 'Islas Marshall',
        country_name_it: 'Isole Marshall',
        country_name_fr: 'Îles Marshall',
        country_name_de: 'Marshallinseln',
        country_name_ca: 'Illes Marshall',
        country_capital: 'Majuro',
        country_capital_el: 'Ματζούρο',
        country_capital_es: 'Majuro',
        country_capital_it: 'Majuro',
        country_capital_fr: 'Majuro',
        country_capital_de: 'Majuro',
        country_capital_ca: 'Majuro',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'MH',
        utc_offset: 12,
        timezone: 'Pacific/Kwajalein',
        timezone_el: 'Ειρηνικός/Κουατζαλέιν',
        timezone_es: 'Pacífico/Kwajalein',
        timezone_it: 'Pacífico/Kwajalein',
        timezone_fr: 'Pacifique/Kwajalein',
        timezone_de: 'Pazifik/Kwajalein',
        timezone_ca: 'Pacífic/Kwajalein',
        country_name: 'Marshall Islands',
        country_name_el: 'Νήσοι Μάρσαλ',
        country_name_es: 'Islas Marshall',
        country_name_it: 'Isole Marshall',
        country_name_fr: 'Îles Marshall',
        country_name_de: 'Marshallinseln',
        country_name_ca: 'Illes Marshall',
        country_capital: 'Majuro',
        country_capital_el: 'Σκόπια',
        country_capital_es: 'Skopie',
        country_capital_it: 'Scopje',
        country_capital_fr: 'Skopje',
        country_capital_de: 'Skopje',
        country_capital_ca: 'Skopje',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'MK',
        utc_offset: 1,
        timezone: 'Europe/Skopje',
        timezone_el: 'Ευρώπη/Σκόπια',
        timezone_es: 'Europa/Skopie',
        timezone_it: 'Europa/Skopje',
        timezone_fr: 'Europe/Skopje',
        timezone_de: 'Europa/Skopje',
        timezone_ca: 'Europa/Skopje',
        country_name: 'Macedonia',
        country_name_el: 'Βόρεια Μακεδονία',
        country_name_es: 'Macedonia del Norte',
        country_name_it: 'Macedonia del Nord',
        country_name_fr: 'Macédoine du Nord',
        country_name_de: 'Nordmazedonien',
        country_name_ca: 'Macedònia del Nord',
        country_capital: 'Skopje',
        country_capital_el: 'Μπαμάκο',
        country_capital_es: 'Bamako',
        country_capital_it: 'Bamako',
        country_capital_fr: 'Bamako',
        country_capital_de: 'Bamako',
        country_capital_ca: 'Bamako',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'ML',
        utc_offset: 0,
        timezone: 'Africa/Bamako',
        timezone_el: 'Αφρική/Μπαμάκο',
        timezone_es: 'África/Bamako',
        timezone_it: 'África/Bamako',
        timezone_fr: 'Afrique/Bamako',
        timezone_de: 'Afrika/Bamako',
        timezone_ca: 'Àfrica/Bamako',
        country_name: 'Mali',
        country_name_el: 'Μάλι',
        country_name_es: 'Mali',
        country_name_it: 'Mali',
        country_name_fr: 'Mali',
        country_name_de: 'Mali',
        country_name_ca: 'Mali',
        country_capital: 'Bamako',
        country_capital_el: 'Βαλέτα',
        country_capital_es: 'La Valeta',
        country_capital_it: 'La Valletta',
        country_capital_fr: 'La Valette',
        country_capital_de: 'Valletta',
        country_capital_ca: 'La Valeta',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'MT',
        utc_offset: 0,
        timezone: 'Europe/Malta',
        timezone_el: 'Ευρώπη/Μάλτα',
        timezone_es: 'Europa/Malta',
        timezone_it: 'Europa/Malta',
        timezone_fr: 'Europe/Malte',
        timezone_de: 'Europa/Malta',
        timezone_ca: 'Europa/Malta',
        country_name: 'Malta',
        country_name_el: 'Μάλτα',
        country_name_es: 'Malta',
        country_name_it: 'Malta',
        country_name_fr: 'Malte',
        country_name_de: 'Malta',
        country_name_ca: 'Malta',
        country_capital: 'Valletta',
        country_capital_el: 'Ναϊπίδο',
        country_capital_es: 'Naipyidó',
        country_capital_it: 'Naypyidaw',
        country_capital_fr: 'Naypyidaw',
        country_capital_de: 'Naypyidaw',
        country_capital_ca: 'Naipyidó',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'MM',
        utc_offset: 44991,
        timezone: 'Asia/Rangoon',
        timezone_el: 'Ασία/Ρανγκούν',
        timezone_es: 'Asia/Rangún',
        timezone_it: 'Asia/Rangún',
        timezone_fr: 'Asie/Rangoon',
        timezone_de: 'Asien/Rangun',
        timezone_ca: 'Àsia/Rangoon',
        country_name: 'Myanmar',
        country_name_el: 'Μιανμάρ',
        country_name_es: 'Myanmar',
        country_name_it: 'Myanmar',
        country_name_fr: 'Myanmar',
        country_name_de: 'Myanmar',
        country_name_ca: 'Myanmar',
        country_capital: 'Naypyidaw',
        country_capital_el: 'Ποντγκόριτσα',
        country_capital_es: 'Podgorica',
        country_capital_it: 'Podgorica',
        country_capital_fr: 'Podgorica',
        country_capital_de: 'Podgorica',
        country_capital_ca: 'Podgorica',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'ME',
        utc_offset: 1,
        timezone: 'Europe/Podgorica',
        timezone_el: 'Ευρώπη/Ποντγκόριτσα',
        timezone_es: 'Europa/Podgorica',
        timezone_it: 'Europa/Podgorica',
        timezone_fr: 'Europe/Podgorica',
        timezone_de: 'Europa/Podgorica',
        timezone_ca: 'Europa/Podgorica',
        country_name: 'Montenegro',
        country_name_el: 'Μαυροβούνιο',
        country_name_es: 'Montenegro',
        country_name_it: 'Montenegro',
        country_name_fr: 'Monténégro',
        country_name_de: 'Montenegro',
        country_name_ca: 'Montenegro',
        country_capital: 'Podgorica',
        country_capital_el: 'Ουλάν Μπατόρ',
        country_capital_es: 'Ulán Bator',
        country_capital_it: 'Ulan Bator',
        country_capital_fr: 'Oulan-Bator',
        country_capital_de: 'Ulan-Bator',
        country_capital_ca: 'Ulan Bator',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'MN',
        utc_offset: 8,
        timezone: 'Asia/Ulaanbaatar',
        timezone_el: 'Ασία/Ουλάν_Μπατόρ',
        timezone_es: 'Asia/Ulán_Bator',
        timezone_it: 'Asia/Ulan_Bator',
        timezone_fr: 'Asie/Oulan-Bator',
        timezone_de: 'Asien/Ulaanbaatar',
        timezone_ca: 'Àsia/Ulaanbaatar',
        country_name: 'Mongolia',
        country_name_el: 'Μογγολία',
        country_name_es: 'Mongolia',
        country_name_it: 'Mongolia',
        country_name_fr: 'Mongolie',
        country_name_de: 'Mongolei',
        country_name_ca: 'Mongòlia',
        country_capital: 'Ulan Bator',
        country_capital_el: 'Ουλάν Μπατόρ',
        country_capital_es: 'Ulán Bator',
        country_capital_it: 'Ulan Bator',
        country_capital_fr: 'Oulan-Bator',
        country_capital_de: 'Ulan-Bator',
        country_capital_ca: 'Ulan Bator',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'MN',
        utc_offset: 8,
        timezone: 'Asia/Hovd',
        timezone_el: 'Ασία/Χοβντ',
        timezone_es: 'Asia/Hovd',
        timezone_it: 'Asia/Hovd',
        timezone_fr: 'Asie/Hovd',
        timezone_de: 'Asien/Hovd',
        timezone_ca: 'Àsia/Hovd',
        country_name: 'Mongolia',
        country_name_el: 'Μογγολία',
        country_name_es: 'Mongolia',
        country_name_it: 'Mongolia',
        country_name_fr: 'Mongolie',
        country_name_de: 'Mongolei',
        country_name_ca: 'Mongòlia',
        country_capital: 'Ulan Bator',
        country_capital_el: 'Ουλάν Μπατόρ',
        country_capital_es: 'Ulán Bator',
        country_capital_it: 'Ulan Bator',
        country_capital_fr: 'Oulan-Bator',
        country_capital_de: 'Ulan-Bator',
        country_capital_ca: 'Ulan Bator',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'MN',
        utc_offset: 8,
        timezone: 'Asia/Choibalsan',
        timezone_el: 'Ασία/Τσόιμπαλσαν',
        timezone_es: 'Asia/Choibalsan',
        timezone_it: 'Asia/Choibalsan',
        timezone_fr: 'Asie/Choibalsan',
        timezone_de: 'Asien/Choibalsan',
        timezone_ca: 'Àsia/Choibalsan',
        country_name: 'Mongolia',
        country_name_el: 'Μογγολία',
        country_name_es: 'Mongolia',
        country_name_it: 'Mongolia',
        country_name_fr: 'Mongolie',
        country_name_de: 'Mongolei',
        country_name_ca: 'Mongòlia',
        country_capital: 'Ulan Bator',
        country_capital_el: 'Σάιπαν',
        country_capital_es: 'Saipán',
        country_capital_it: 'Saipan',
        country_capital_fr: 'Saipan',
        country_capital_de: 'Saipan',
        country_capital_ca: 'Saipan',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'MP',
        utc_offset: 10,
        timezone: 'Pacific/Saipan',
        timezone_el: 'Ειρηνικός/Σαΐπαν',
        timezone_es: 'Pacífico/Saipán',
        timezone_it: 'Pacífico/Saipán',
        timezone_fr: 'Pacifique/Saipan',
        timezone_de: 'Pazifik/Saipan',
        timezone_ca: 'Pacífic/Saipan',
        country_name: 'Northern Mariana Islands',
        country_name_el: 'Βόρειες Μαριάνες Νήσοι',
        country_name_es: 'Islas Marianas del Norte',
        country_name_it: 'Isole Marianne Settentrionali',
        country_name_fr: 'Îles Mariannes du Nord',
        country_name_de: 'Nördliche Marianen',
        country_name_ca: 'Illes Mariannes Septentrionals',
        country_capital: 'Saipan',
        country_capital_el: 'Μαπούτο',
        country_capital_es: 'Maputo',
        country_capital_it: 'Maputo',
        country_capital_fr: 'Maputo',
        country_capital_de: 'Maputo',
        country_capital_ca: 'Maputo',
        country_continent: 'Oceania',
        country_continent_el: 'Ασία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'MZ',
        utc_offset: 2,
        timezone: 'Africa/Maputo',
        timezone_el: 'Αφρική/Μαπούτο',
        timezone_es: 'África/Maputo',
        timezone_it: 'África/Maputo',
        timezone_fr: 'Afrique/Maputo',
        timezone_de: 'Afrika/Maputo',
        timezone_ca: 'Àfrica/Maputo',
        country_name: 'Mozambique',
        country_name_el: 'Μοζαμβίκη',
        country_name_es: 'Mozambique',
        country_name_it: 'Mozambico',
        country_name_fr: 'Mozambique',
        country_name_de: 'Mosambik',
        country_name_ca: 'Moçambic',
        country_capital: 'Maputo',
        country_capital_el: 'Νουακσότ',
        country_capital_es: 'Nuakchot',
        country_capital_it: 'Nouakchott',
        country_capital_fr: 'Nouakchott',
        country_capital_de: 'Nouakchott',
        country_capital_ca: 'Nuakxot',
        country_continent: 'Africa',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'MR',
        utc_offset: 0,
        timezone: 'Africa/Nouakchott',
        timezone_el: 'Αφρική/Νουακσότο',
        timezone_es: 'África/Nouakchott',
        timezone_it: 'África/Nuakchot',
        timezone_fr: 'Afrique/Nouakchott',
        timezone_de: 'Afrika/Nouakchott',
        timezone_ca: 'Àfrica/Nuakchot',
        country_name: 'Mauritania',
        country_name_el: 'Μαυριτανία',
        country_name_es: 'Mauritania',
        country_name_it: 'Mauritania',
        country_name_fr: 'Mauritanie',
        country_name_de: 'Mauretanien',
        country_name_ca: 'Mauritània',
        country_capital: 'Nouakchott',
        country_capital_el: 'Πλίμουθ',
        country_capital_es: 'Plymouth',
        country_capital_it: 'Plymouth',
        country_capital_fr: 'Plymouth',
        country_capital_de: 'Plymouth',
        country_capital_ca: 'Plymouth',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'MS',
        utc_offset: -4,
        timezone: 'America/Montserrat',
        timezone_el: 'Αμερική/Μονσεράτ',
        timezone_es: 'América/Montserrat',
        timezone_it: 'América/Montserrat',
        timezone_fr: 'Amérique/Montserrat',
        timezone_de: 'Amerika/Montserrat',
        timezone_ca: 'Amèrica/Montserrat',
        country_name: 'Montserrat',
        country_name_el: 'Μονσεράτ',
        country_name_es: 'Montserrat',
        country_name_it: 'Montserrat',
        country_name_fr: 'Montserrat',
        country_name_de: 'Montserrat',
        country_name_ca: 'Montserrat',
        country_capital: 'Plymouth',
        country_capital_el: 'Φορ-ντε-Φρανς',
        country_capital_es: 'Fort-de-France',
        country_capital_it: 'Fort-de-France',
        country_capital_fr: 'Fort-de-France',
        country_capital_de: 'Fort-de-France',
        country_capital_ca: 'Fort-de-France',
        country_continent: 'North America',
        country_continent_el: 'Αφρική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MQ',
        utc_offset: -4,
        timezone: 'America/Martinique',
        timezone_el: 'Αμερική/Μαρτινίκ',
        timezone_es: 'América/Martinica',
        timezone_it: 'América/Martinica',
        timezone_fr: 'Amérique/Martinique',
        timezone_de: 'Amerika/Martinique',
        timezone_ca: 'Amèrica/Martinica',
        country_name: 'Martinique',
        country_name_el: 'Μαρτινίκα',
        country_name_es: 'Martinica',
        country_name_it: 'Martinica',
        country_name_fr: 'Martinique',
        country_name_de: 'Martinique',
        country_name_ca: 'Martinica',
        country_capital: 'Fort-de-France',
        country_capital_el: 'Πορτ Λούι',
        country_capital_es: 'Puerto Luis',
        country_capital_it: 'Port Louis',
        country_capital_fr: 'Port-Louis',
        country_capital_de: 'Port Louis',
        country_capital_ca: 'Port Louis',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'MU',
        utc_offset: 4,
        timezone: 'Indian/Mauritius',
        timezone_el: 'Ινδικός/Μαυρίκιος',
        timezone_es: 'Islas_Mauricio',
        timezone_it: 'Índico/Mauricio',
        timezone_fr: 'Indien/Maurice',
        timezone_de: 'Indischer/Ozean/Mauritius',
        timezone_ca: 'Índic/Maurici',
        country_name: 'Mauritius',
        country_name_el: 'Μαυρίκιος',
        country_name_es: 'Mauricio',
        country_name_it: 'Mauritius',
        country_name_fr: 'Maurice',
        country_name_de: 'Mauritius',
        country_name_ca: 'Maurici',
        country_capital: 'Port Louis',
        country_capital_el: 'Λιλόνγκουε',
        country_capital_es: 'Lilongüe',
        country_capital_it: 'Lilongwe',
        country_capital_fr: 'Lilongwe',
        country_capital_de: 'Lilongwe',
        country_capital_ca: 'Lilongüe',
        country_continent: 'Africa',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'MW',
        utc_offset: 2,
        timezone: 'Africa/Blantyre',
        timezone_el: 'Αφρική/Μπλαντάιρ',
        timezone_es: 'África/Blantyre',
        timezone_it: 'África/Blantire',
        timezone_fr: 'Afrique/Blantyre',
        timezone_de: 'Afrika/Blantyre',
        timezone_ca: 'Àfrica/Blantyre',
        country_name: 'Malawi',
        country_name_el: 'Μαλάουι',
        country_name_es: 'Malaui',
        country_name_it: 'Malawi',
        country_name_fr: 'Malawi',
        country_name_de: 'Malawi',
        country_name_ca: 'Malawi',
        country_capital: 'Lilongwe',
        country_capital_el: 'Κουάλα Λουμπούρ',
        country_capital_es: 'Kuala Lumpur',
        country_capital_it: 'Kuala Lumpur',
        country_capital_fr: 'Kuala Lumpur',
        country_capital_de: 'Kuala Lumpur',
        country_capital_ca: 'Kuala Lumpur',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'MY',
        utc_offset: 8,
        timezone: 'Asia/Kuala_Lumpur',
        timezone_el: 'Ασία/Κουάλα_Λουμπούρ',
        timezone_es: 'Asia/Kuala_Lumpur',
        timezone_it: 'Asia/Kuala_Lumpur',
        timezone_fr: 'Asie/Kuala_Lumpur',
        timezone_de: 'Asien/Kuala_Lumpur',
        timezone_ca: 'Àsia/Kuala_Lumpur',
        country_name: 'Malaysia',
        country_name_el: 'Μαλαισία',
        country_name_es: 'Malasia',
        country_name_it: 'Malaysia',
        country_name_fr: 'Malaisie',
        country_name_de: 'Malaysia',
        country_name_ca: 'Malàisia',
        country_capital: 'Kuala Lumpur',
        country_capital_el: 'Κουάλα Λουμπούρ',
        country_capital_es: 'Kuala Lumpur',
        country_capital_it: 'Kuala Lumpur',
        country_capital_fr: 'Kuala Lumpur',
        country_capital_de: 'Kuala Lumpur',
        country_capital_ca: 'Kuala Lumpur',
        country_continent: 'Asia',
        country_continent_el: 'Αφρική',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'MY',
        utc_offset: 8,
        timezone: 'Asia/Kuching',
        timezone_el: 'Ασία/Κούτσινγκ',
        timezone_es: 'Asia/Kuching',
        timezone_it: 'Asia/Kuching',
        timezone_fr: 'Asie/Kuching',
        timezone_de: 'Asien/Kuching',
        timezone_ca: 'Àsia/Kuching',
        country_name: 'Malaysia',
        country_name_el: 'Μαλαισία',
        country_name_es: 'Malasia',
        country_name_it: 'Malaysia',
        country_name_fr: 'Malaisie',
        country_name_de: 'Malaysia',
        country_name_ca: 'Malàisia',
        country_capital: 'Kuala Lumpur',
        country_capital_el: 'Μαμουντζού',
        country_capital_es: 'Mamoudzou',
        country_capital_it: 'Mamoudzou',
        country_capital_fr: 'Mamoudzou',
        country_capital_de: 'Mamoudzou',
        country_capital_ca: 'Mamoudzou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'YT',
        utc_offset: 3,
        timezone: 'Indian/Mayotte',
        timezone_el: 'Ινδικός/Μαγιότ',
        timezone_es: 'Mayotte',
        timezone_it: 'Índico/Mayotte',
        timezone_fr: 'Indien/Mayotte',
        timezone_de: 'Indischer/Ozean/Mayotte',
        timezone_ca: 'Índic/Mayotte',
        country_name: 'Mayotte',
        country_name_el: 'Μαγιότ',
        country_name_es: 'Mayotte',
        country_name_it: 'Mayotte',
        country_name_fr: 'Mayotte',
        country_name_de: 'Mayotte',
        country_name_ca: 'Mayotte',
        country_capital: 'Mamoudzou',
        country_capital_el: 'Βίντχοεκ',
        country_capital_es: 'Windhoek',
        country_capital_it: 'Windhoek',
        country_capital_fr: 'Windhoek',
        country_capital_de: 'Windhoek',
        country_capital_ca: 'Windhoek',
        country_continent: 'Africa',
        country_continent_el: 'Ασία',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'NA',
        utc_offset: 1,
        timezone: 'Africa/Windhoek',
        timezone_el: 'Αφρική/Βίντχουκ',
        timezone_es: 'África/Windhoek',
        timezone_it: 'África/Windhoek',
        timezone_fr: 'Afrique/Windhoek',
        timezone_de: 'Afrika/Windhuk',
        timezone_ca: 'Àfrica/Windhoek',
        country_name: 'Namibia',
        country_name_el: 'Ναμίμπια',
        country_name_es: 'Namibia',
        country_name_it: 'Namibia',
        country_name_fr: 'Namibie',
        country_name_de: 'Namibia',
        country_name_ca: 'Namíbia',
        country_capital: 'Windhoek',
        country_capital_el: 'Νουμέα',
        country_capital_es: 'Numea',
        country_capital_it: 'Nouméa',
        country_capital_fr: 'Nouméa',
        country_capital_de: 'Nouméa',
        country_capital_ca: 'Numea',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'NC',
        utc_offset: 11,
        timezone: 'Pacific/Noumea',
        timezone_el: 'Ειρηνικός/Νουμέα',
        timezone_es: 'Pacífico/Numea',
        timezone_it: 'Pacífico/Numea',
        timezone_fr: 'Pacifique/Nouméa',
        timezone_de: 'Pazifik/Noumea',
        timezone_ca: 'Pacífic/Noumea',
        country_name: 'New Caledonia',
        country_name_el: 'Νέα Καληδονία',
        country_name_es: 'Nueva Caledonia',
        country_name_it: 'Nuova Caledonia',
        country_name_fr: 'Nouvelle-Calédonie',
        country_name_de: 'Neukaledonien',
        country_name_ca: 'Nova Caledònia',
        country_capital: 'Nouméa',
        country_capital_el: 'Νιαμέυ',
        country_capital_es: 'Niamey',
        country_capital_it: 'Niamey',
        country_capital_fr: 'Niamey',
        country_capital_de: 'Niamey',
        country_capital_ca: 'Niamey',
        country_continent: 'Oceania',
        country_continent_el: 'Αφρική',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'NE',
        utc_offset: 1,
        timezone: 'Africa/Niamey',
        timezone_el: 'Αφρική/Νιαμέι',
        timezone_es: 'África/Niamey',
        timezone_it: 'África/Niamey',
        timezone_fr: 'Afrique/Niamey',
        timezone_de: 'Afrika/Niamey',
        timezone_ca: 'Àfrica/Niamey',
        country_name: 'Niger',
        country_name_el: 'Νίγηρας',
        country_name_es: 'Níger',
        country_name_it: 'Niger',
        country_name_fr: 'Niger',
        country_name_de: 'Niger',
        country_name_ca: 'Níger',
        country_capital: 'Niamey',
        country_capital_el: 'Κίνγκστον',
        country_capital_es: 'Kingston',
        country_capital_it: 'Kingston',
        country_capital_fr: 'Kingston',
        country_capital_de: 'Kingston',
        country_capital_ca: 'Kingston',
        country_continent: 'Africa',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'NF',
        utc_offset: 44996,
        timezone: 'Pacific/Norfolk',
        timezone_el: 'Ειρηνικός/Νόρφολκ',
        timezone_es: 'Isla_Norfolk',
        timezone_it: 'Pacífico/Norfolk',
        timezone_fr: 'Pacifique/Norfolk',
        timezone_de: 'Pazifik/Norfolk',
        timezone_ca: 'Pacífic/Norfolk',
        country_name: 'Norfolk Island',
        country_name_el: 'Νησί Νόρφολκ',
        country_name_es: 'Isla Norfolk',
        country_name_it: 'Isola Norfolk',
        country_name_fr: 'Île Norfolk',
        country_name_de: 'Norfolk-Insel',
        country_name_ca: 'Illa Norfolk',
        country_capital: 'Kingston',
        country_capital_el: 'Αμπούτζα',
        country_capital_es: 'Abuya',
        country_capital_it: 'Abuja',
        country_capital_fr: 'Abuja',
        country_capital_de: 'Abuja',
        country_capital_ca: 'Abuya',
        country_continent: 'Oceania',
        country_continent_el: 'Αφρική',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'NG',
        utc_offset: 1,
        timezone: 'Africa/Lagos',
        timezone_el: 'Αφρική/Λάγος',
        timezone_es: 'África/Lagos',
        timezone_it: 'África/Lagos',
        timezone_fr: 'Afrique/Lagos',
        timezone_de: 'Afrika/Lagos',
        timezone_ca: 'Àfrica/Lagos',
        country_name: 'Nigeria',
        country_name_el: 'Νιγηρία',
        country_name_es: 'Nigeria',
        country_name_it: 'Nigeria',
        country_name_fr: 'Nigeria',
        country_name_de: 'Nigeria',
        country_name_ca: 'Nigèria',
        country_capital: 'Abuja',
        country_capital_el: 'Μανάγκουα',
        country_capital_es: 'Managua',
        country_capital_it: 'Managua',
        country_capital_fr: 'Managua',
        country_capital_de: 'Managua',
        country_capital_ca: 'Managua',
        country_continent: 'Africa',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'NI',
        utc_offset: -6,
        timezone: 'America/Managua',
        timezone_el: 'Αμερική/Μανάγκουα',
        timezone_es: 'América/Managua',
        timezone_it: 'América/Managua',
        timezone_fr: 'Amérique/Managua',
        timezone_de: 'Amerika/Managua',
        timezone_ca: 'Amèrica/Managua',
        country_name: 'Nicaragua',
        country_name_el: 'Νικαράγουα',
        country_name_es: 'Nicaragua',
        country_name_it: 'Nicaragua',
        country_name_fr: 'Nicaragua',
        country_name_de: 'Nicaragua',
        country_name_ca: 'Nicaragua',
        country_capital: 'Managua',
        country_capital_el: 'Αλόφι',
        country_capital_es: 'Alofi',
        country_capital_it: 'Alofi',
        country_capital_fr: 'Alofi',
        country_capital_de: 'Alofi',
        country_capital_ca: 'Alofi',
        country_continent: 'North America',
        country_continent_el: 'Αφρική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'NU',
        utc_offset: -11,
        timezone: 'Pacific/Niue',
        timezone_el: 'Ειρηνικός/Νιούε',
        timezone_es: 'Pacífico/Niue',
        timezone_it: 'Pacífico/Niue',
        timezone_fr: 'Pacifique/Niue',
        timezone_de: 'Pazifik/Niue',
        timezone_ca: 'Pacífic/Niue',
        country_name: 'Niue',
        country_name_el: 'Νιούε',
        country_name_es: 'Niue',
        country_name_it: 'Niue',
        country_name_fr: 'Niue',
        country_name_de: 'Niue',
        country_name_ca: 'Niue',
        country_capital: 'Alofi',
        country_capital_el: 'Άμστερνταμ',
        country_capital_es: 'Ámsterdam',
        country_capital_it: 'Amsterdam',
        country_capital_fr: 'Amsterdam',
        country_capital_de: 'Amsterdam',
        country_capital_ca: 'Amsterdam',
        country_continent: 'Oceania',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'NL',
        utc_offset: 1,
        timezone: 'Europe/Amsterdam',
        timezone_el: 'Ευρώπη/Άμστερνταμ',
        timezone_es: 'Europa/Ámsterdam',
        timezone_it: 'Europa/Ámsterdam',
        timezone_fr: 'Europe/Amsterdam',
        timezone_de: 'Europa/Amsterdam',
        timezone_ca: 'Europa/Amsterdam',
        country_name: 'Netherlands',
        country_name_el: 'Ολλανδία',
        country_name_es: 'Países Bajos',
        country_name_it: 'Paesi Bassi',
        country_name_fr: 'Pays-Bas',
        country_name_de: 'Niederlande',
        country_name_ca: 'Països Baixos',
        country_capital: 'Amsterdam',
        country_capital_el: 'Όσλο',
        country_capital_es: 'Oslo',
        country_capital_it: 'Oslo',
        country_capital_fr: 'Oslo',
        country_capital_de: 'Oslo',
        country_capital_ca: 'Oslo',
        country_continent: 'Europe',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'NO',
        utc_offset: 1,
        timezone: 'Europe/Oslo',
        timezone_el: 'Ευρώπη/Όσλο',
        timezone_es: 'Europa/Oslo',
        timezone_it: 'Europa/Oslo',
        timezone_fr: 'Europe/Oslo',
        timezone_de: 'Europa/Oslo',
        timezone_ca: 'Europa/Oslo',
        country_name: 'Norway',
        country_name_el: 'Νορβηγία',
        country_name_es: 'Noruega',
        country_name_it: 'Norvegia',
        country_name_fr: 'Norvège',
        country_name_de: 'Norwegen',
        country_name_ca: 'Noruega',
        country_capital: 'Oslo',
        country_capital_el: 'Κατμαντού',
        country_capital_es: 'Katmandú',
        country_capital_it: 'Kathmandu',
        country_capital_fr: 'Katmandou',
        country_capital_de: 'Kathmandu',
        country_capital_ca: 'Katmandú',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'NP',
        utc_offset: '5.45',
        timezone: 'Asia/Kathmandu',
        timezone_el: 'Ασία/Κατμαντού',
        timezone_es: 'Asia/Katmandú',
        timezone_it: 'Asia/Kathmandú',
        timezone_fr: 'Asie/Katmandou',
        timezone_de: 'Asien/Kathmandu',
        timezone_ca: 'Àsia/Kathmandu',
        country_name: 'Nepal',
        country_name_el: 'Νεπάλ',
        country_name_es: 'Nepal',
        country_name_it: 'Nepal',
        country_name_fr: 'Népal',
        country_name_de: 'Nepal',
        country_name_ca: 'Nepal',
        country_capital: 'Kathmandu',
        country_capital_el: 'Γιαρεν',
        country_capital_es: 'Yaren',
        country_capital_it: 'Yaren',
        country_capital_fr: 'Yaren',
        country_capital_de: 'Yaren',
        country_capital_ca: 'Yaren',
        country_continent: 'Asia',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'NR',
        utc_offset: 12,
        timezone: 'Pacific/Nauru',
        timezone_el: 'Ειρηνικός/Ναουρού',
        timezone_es: 'Nauru',
        timezone_it: 'Pacífico/Nauru',
        timezone_fr: 'Pacifique/Nauru',
        timezone_de: 'Pazifik/Nauru',
        timezone_ca: 'Pacífic/Nauru',
        country_name: 'Nauru',
        country_name_el: 'Ναουρού',
        country_name_es: 'Nauru',
        country_name_it: 'Nauru',
        country_name_fr: 'Nauru',
        country_name_de: 'Nauru',
        country_name_ca: 'Nauru',
        country_capital: 'Yaren',
        country_capital_el: 'Γουέλινγκτον',
        country_capital_es: 'Wellington',
        country_capital_it: 'Wellington',
        country_capital_fr: 'Wellington',
        country_capital_de: 'Wellington',
        country_capital_ca: 'Wellington',
        country_continent: 'Oceania',
        country_continent_el: 'Ασία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'NZ',
        utc_offset: 12,
        timezone: 'Pacific/Auckland',
        timezone_el: 'Ειρηνικός/Ώκλαντ',
        timezone_es: 'Pacífico/Auckland',
        timezone_it: 'Pacífico/Auckland',
        timezone_fr: 'Pacifique/Auckland',
        timezone_de: 'Pazifik/Auckland',
        timezone_ca: 'Pacífic/Auckland',
        country_name: 'New Zealand',
        country_name_el: 'Νέα Ζηλανδία',
        country_name_es: 'Nueva Zelanda',
        country_name_it: 'Nuova Zelanda',
        country_name_fr: 'Nouvelle-Zélande',
        country_name_de: 'Neuseeland',
        country_name_ca: 'Nova Zelanda',
        country_capital: 'Wellington',
        country_capital_el: 'Γουέλινγκτον',
        country_capital_es: 'Wellington',
        country_capital_it: 'Wellington',
        country_capital_fr: 'Wellington',
        country_capital_de: 'Wellington',
        country_capital_ca: 'Wellington',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'NZ',
        utc_offset: 12,
        timezone: 'Pacific/Chatham',
        timezone_el: 'Ειρηνικός/Τσάθαμ',
        timezone_es: 'Islas/Chatham',
        timezone_it: 'Pacífico/Chatham',
        timezone_fr: 'Pacifique/Chatham',
        timezone_de: 'Pazifik/Chatham',
        timezone_ca: 'Pacífic/Chatham',
        country_name: 'New Zealand',
        country_name_el: 'Νέα Ζηλανδία',
        country_name_es: 'Nueva Zelanda',
        country_name_it: 'Nuova Zelanda',
        country_name_fr: 'Nouvelle-Zélande',
        country_name_de: 'Neuseeland',
        country_name_ca: 'Nova Zelanda',
        country_capital: 'Wellington',
        country_capital_el: 'Μασκάτ',
        country_capital_es: 'Mascate',
        country_capital_it: 'Mascate',
        country_capital_fr: 'Mascate',
        country_capital_de: 'Maskat',
        country_capital_ca: 'Mascate',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'OM',
        utc_offset: 4,
        timezone: 'Asia/Muscat',
        timezone_el: 'Ασία/Μασκάτ',
        timezone_es: 'Asia/Mascate',
        timezone_it: 'Asia/Mascate',
        timezone_fr: 'Asie/Mascate',
        timezone_de: 'Asien/Muscat',
        timezone_ca: 'Àsia/Muscat',
        country_name: 'Oman',
        country_name_el: 'Ομάν',
        country_name_es: 'Omán',
        country_name_it: 'Oman',
        country_name_fr: 'Oman',
        country_name_de: 'Oman',
        country_name_ca: 'Oman',
        country_capital: 'Muscat',
        country_capital_el: 'Μασκάτ',
        country_capital_es: 'Mascate',
        country_capital_it: 'Mascate',
        country_capital_fr: 'Mascate',
        country_capital_de: 'Maskat',
        country_capital_ca: 'Mascate',
        country_continent: 'Asia',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'PK',
        utc_offset: 5,
        timezone: 'Asia/Karachi',
        timezone_el: 'Ασία/Καράτσι',
        timezone_es: 'Asia/Karachi',
        timezone_it: 'Asia/Karachi',
        timezone_fr: 'Asie/Karachi',
        timezone_de: 'Asien/Karachi',
        timezone_ca: 'Àsia/Karachi',
        country_name: 'Pakistan',
        country_name_el: 'Πακιστάν',
        country_name_es: 'Pakistán',
        country_name_it: 'Pakistan',
        country_name_fr: 'Pakistan',
        country_name_de: 'Pakistan',
        country_name_ca: 'Pakistan',
        country_capital: 'Islamabad',
        country_capital_el: 'Ισλαμαμπάντ',
        country_capital_es: 'Islamabad',
        country_capital_it: 'Islamabad',
        country_capital_fr: 'Islamabad',
        country_capital_de: 'Islamabad',
        country_capital_ca: 'Islamabad',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'PA',
        utc_offset: -5,
        timezone: 'America/Panama',
        timezone_el: 'Αμερική/Παναμάς',
        timezone_es: 'América/Panamá',
        timezone_it: 'América/Panamá',
        timezone_fr: 'Amérique/Panama',
        timezone_de: 'Amerika/Panama',
        timezone_ca: 'Amèrica/Panama',
        country_name: 'Panama',
        country_name_el: 'Παναμάς',
        country_name_es: 'Panamá',
        country_name_it: 'Panama',
        country_name_fr: 'Panama',
        country_name_de: 'Panama',
        country_name_ca: 'Panamà',
        country_capital: 'Panama City',
        country_capital_el: 'Πάναμα Σίτι',
        country_capital_es: 'Ciudad de Panamá',
        country_capital_it: 'Città di Panama',
        country_capital_fr: 'Panama',
        country_capital_de: 'Panama-Stadt',
        country_capital_ca: 'Ciutat de Panamà',
        country_continent: 'North America',
        country_continent_el: 'Ασία',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'PN',
        utc_offset: -8,
        timezone: 'Pacific/Pitcairn',
        timezone_el: 'Ειρηνικός/Πίτκερν',
        timezone_es: 'Pacífico/Pitcairn',
        timezone_it: 'Pacífico/Pitcairn',
        timezone_fr: 'Pacifique/Pitcairn',
        timezone_de: 'Pazifik/Pitcairn',
        timezone_ca: 'Pacífic/Pitcairn',
        country_name: 'Pitcairn Islands',
        country_name_el: 'Νήσοι Πίτκερν',
        country_name_es: 'Islas Pitcairn',
        country_name_it: 'Isole Pitcairn',
        country_name_fr: 'Îles Pitcairn',
        country_name_de: 'Pitcairninseln',
        country_name_ca: 'Illes Pitcairn',
        country_capital: 'Adamstown',
        country_capital_el: 'Άνταμσταουν',
        country_capital_es: 'Adamstown',
        country_capital_it: 'Adamstown',
        country_capital_fr: 'Adamstown',
        country_capital_de: 'Adamstown',
        country_capital_ca: 'Adamstown',
        country_continent: 'Oceania',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'PE',
        utc_offset: -5,
        timezone: 'America/Lima',
        timezone_el: 'Αμερική/Λίμα',
        timezone_es: 'América/Lima',
        timezone_it: 'América/Lima',
        timezone_fr: 'Amérique/Lima',
        timezone_de: 'Amerika/Lima',
        timezone_ca: 'Amèrica/Lima',
        country_name: 'Peru',
        country_name_el: 'Περού',
        country_name_es: 'Perú',
        country_name_it: 'Perù',
        country_name_fr: 'Pérou',
        country_name_de: 'Peru',
        country_name_ca: 'Perú',
        country_capital: 'Lima',
        country_capital_el: 'Λίμα',
        country_capital_es: 'Lima',
        country_capital_it: 'Lima',
        country_capital_fr: 'Lima',
        country_capital_de: 'Lima',
        country_capital_ca: 'Lima',
        country_continent: 'South America',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'América del Sur',
        country_continent_it: 'South America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'PH',
        utc_offset: 8,
        timezone: 'Asia/Manila',
        timezone_el: 'Ασία/Μανίλα',
        timezone_es: 'Asia/Manila',
        timezone_it: 'Asia/Manila',
        timezone_fr: 'Asie/Manille',
        timezone_de: 'Asien/Manila',
        timezone_ca: 'Àsia/Manila',
        country_name: 'Philippines',
        country_name_el: 'Φιλιππίνες',
        country_name_es: 'Filipinas',
        country_name_it: 'Filippine',
        country_name_fr: 'Philippines',
        country_name_de: 'Philippinen',
        country_name_ca: 'Filipines',
        country_capital: 'Manila',
        country_capital_el: 'Μανίλα',
        country_capital_es: 'Manila',
        country_capital_it: 'Manila',
        country_capital_fr: 'Manille',
        country_capital_de: 'Manila',
        country_capital_ca: 'Manila',
        country_continent: 'Asia',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'PW',
        utc_offset: 9,
        timezone: 'Pacific/Palau',
        timezone_el: 'Ειρηνικός/Παλάου',
        timezone_es: 'Palau',
        timezone_it: 'Pacífico/Palau',
        timezone_fr: 'Pacifique/Palau',
        timezone_de: 'Pazifik/Palau',
        timezone_ca: 'Pacífic/Palau',
        country_name: 'Palau',
        country_name_el: 'Παλάου',
        country_name_es: 'Palaos',
        country_name_it: 'Palau',
        country_name_fr: 'Palaos',
        country_name_de: 'Palau',
        country_name_ca: 'Palau',
        country_capital: 'Ngerulmud',
        country_capital_el: 'Νγκερούλμουντ',
        country_capital_es: 'Ngerulmud',
        country_capital_it: 'Ngerulmud',
        country_capital_fr: 'Ngerulmud',
        country_capital_de: 'Ngerulmud',
        country_capital_ca: 'Ngerulmud',
        country_continent: 'Oceania',
        country_continent_el: 'Ασία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'PG',
        utc_offset: 10,
        timezone: 'Pacific/Port_Moresby',
        timezone_el: 'Ειρηνικός/Πορτ_Μόρεσμπι',
        timezone_es: 'Pacífico/Port_Moresby',
        timezone_it: 'Pacífico/Port_Moresby',
        timezone_fr: 'Pacifique/Port_Moresby',
        timezone_de: 'Pazifik/Port_Moresby',
        timezone_ca: 'Pacífic/Port_Moresby',
        country_name: 'Papua New Guinea',
        country_name_el: 'Παπούα Νέα Γουινέα',
        country_name_es: 'Papúa Nueva Guinea',
        country_name_it: 'Papua Nuova Guinea',
        country_name_fr: 'Papouasie-Nouvelle-Guinée',
        country_name_de: 'Papua-Neuguinea',
        country_name_ca: 'Papua Nova Guinea',
        country_capital: 'Port Moresby',
        country_capital_el: 'Πορτ Μόρεσμπι',
        country_capital_es: 'Puerto Moresby',
        country_capital_it: 'Port Moresby',
        country_capital_fr: 'Port Moresby',
        country_capital_de: 'Port Moresby',
        country_capital_ca: 'Port Moresby',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'PG',
        utc_offset: 10,
        timezone: 'Pacific/Bougainville',
        timezone_el: 'Ειρηνικός/Μπουγκενβίλ',
        timezone_es: 'Pacífico/Bougainville',
        timezone_it: 'Pacífico/Bougainville',
        timezone_fr: 'Pacifique/Bougainville',
        timezone_de: 'Pazifik/Bougainville',
        timezone_ca: 'Pacífic/Bougainville',
        country_name: 'Papua New Guinea',
        country_name_el: 'Παπούα Νέα Γουινέα',
        country_name_es: 'Papúa Nueva Guinea',
        country_name_it: 'Papua Nuova Guinea',
        country_name_fr: 'Papouasie-Nouvelle-Guinée',
        country_name_de: 'Papua-Neuguinea',
        country_name_ca: 'Papua Nova Guinea',
        country_capital: 'Port Moresby',
        country_capital_el: 'Πορτ Μόρεσμπι',
        country_capital_es: 'Puerto Moresby',
        country_capital_it: 'Port Moresby',
        country_capital_fr: 'Port Moresby',
        country_capital_de: 'Port Moresby',
        country_capital_ca: 'Port Moresby',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'PL',
        utc_offset: 1,
        timezone: 'Europe/Warsaw',
        timezone_el: 'Ευρώπη/Βαρσοβία',
        timezone_es: 'Europa/Varsovia',
        timezone_it: 'Europa/Varsovia',
        timezone_fr: 'Europe/Varsovie',
        timezone_de: 'Europa/Warschau',
        timezone_ca: 'Europa/Varsòvia',
        country_name: 'Poland',
        country_name_el: 'Πολωνία',
        country_name_es: 'Polonia',
        country_name_it: 'Polonia',
        country_name_fr: 'Pologne',
        country_name_de: 'Polen',
        country_name_ca: 'Polònia',
        country_capital: 'Warsaw',
        country_capital_el: 'Βαρσοβία',
        country_capital_es: 'Varsovia',
        country_capital_it: 'Varsavia',
        country_capital_fr: 'Varsovie',
        country_capital_de: 'Warschau',
        country_capital_ca: 'Varsòvia',
        country_continent: 'Europe',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'PR',
        utc_offset: -4,
        timezone: 'America/Puerto_Rico',
        timezone_el: 'Αμερική/Πουέρτο_Ρίκο',
        timezone_es: 'América/Puerto_Rico',
        timezone_it: 'América/Puerto_Rico',
        timezone_fr: 'Amérique/Porto_Rico',
        timezone_de: 'Amerika/Puerto_Rico',
        timezone_ca: 'Amèrica/Puerto_Rico',
        country_name: 'Puerto Rico',
        country_name_el: 'Πουέρτο Ρίκο',
        country_name_es: 'Puerto Rico',
        country_name_it: 'Porto Rico',
        country_name_fr: 'Porto Rico',
        country_name_de: 'Puerto Rico',
        country_name_ca: 'Puerto Rico',
        country_capital: 'San Juan',
        country_capital_el: 'Σαν Χουάν',
        country_capital_es: 'San Juan',
        country_capital_it: 'San Juan',
        country_capital_fr: 'San Juan',
        country_capital_de: 'San Juan',
        country_capital_ca: 'San Juan',
        country_continent: 'North America',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'KP',
        utc_offset: 44993,
        timezone: 'Asia/Pyongyang',
        timezone_el: 'Ασία/Πιονγιάνγκ',
        timezone_es: 'Asia/Pyongyang',
        timezone_it: 'Asia/Pyongyang',
        timezone_fr: 'Asie/Pyongyang',
        timezone_de: 'Asien/Pjöngjang',
        timezone_ca: 'Àsia/Pyongyang',
        country_name: 'North Korea',
        country_name_el: 'Βόρεια Κορέα',
        country_name_es: 'Corea del Norte',
        country_name_it: 'Corea del Nord',
        country_name_fr: 'Corée du Nord',
        country_name_de: 'Nordkorea',
        country_name_ca: 'Corea del Nord',
        country_capital: 'Pyongyang',
        country_capital_el: 'Πιονγιάνγκ',
        country_capital_es: 'Pionyang',
        country_capital_it: 'Pyongyang',
        country_capital_fr: 'Pyongyang',
        country_capital_de: 'Pjöngjang',
        country_capital_ca: 'Pyongyang',
        country_continent: 'Asia',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'PT',
        utc_offset: 0,
        timezone: 'Europe/Lisbon',
        timezone_el: 'Ευρώπη/Λισαβόνα',
        timezone_es: 'Europa/Lisboa',
        timezone_it: 'Europa/Lisboa',
        timezone_fr: 'Europe/Lisbonne',
        timezone_de: 'Europa/Lissabon',
        timezone_ca: 'Europa/Lisboa',
        country_name: 'Portugal',
        country_name_el: 'Πορτογαλία',
        country_name_es: 'Portugal',
        country_name_it: 'Portogallo',
        country_name_fr: 'Portugal',
        country_name_de: 'Portugal',
        country_name_ca: 'Portugal',
        country_capital: 'Lisbon',
        country_capital_el: 'Λισαβόνα',
        country_capital_es: 'Lisboa',
        country_capital_it: 'Lisbona',
        country_capital_fr: 'Lisbonne',
        country_capital_de: 'Lissabon',
        country_capital_ca: 'Lisboa',
        country_continent: 'Europe',
        country_continent_el: 'Ασία',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'PT',
        utc_offset: 0,
        timezone: 'Atlantic/Madeira',
        timezone_el: 'Ατλαντικός/Μαντέιρα',
        timezone_es: 'Madeira',
        timezone_it: 'Atlántico/Madeira',
        timezone_fr: 'Atlantique/Madeira',
        timezone_de: 'Atlantik/Madeira',
        timezone_ca: 'Atlàntic/Madeira',
        country_name: 'Portugal',
        country_name_el: 'Πορτογαλία',
        country_name_es: 'Portugal',
        country_name_it: 'Portogallo',
        country_name_fr: 'Portugal',
        country_name_de: 'Portugal',
        country_name_ca: 'Portugal',
        country_capital: 'Lisbon',
        country_capital_el: 'Λισαβόνα',
        country_capital_es: 'Lisboa',
        country_capital_it: 'Lisbona',
        country_capital_fr: 'Lisbonne',
        country_capital_de: 'Lissabon',
        country_capital_ca: 'Lisboa',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'PT',
        utc_offset: 0,
        timezone: 'Atlantic/Azores',
        timezone_el: 'Ατλαντικός/Αζόρες',
        timezone_es: 'Atlántico/Azores',
        timezone_it: 'Atlántico/Azores',
        timezone_fr: 'Atlantique/Açores',
        timezone_de: 'Atlantik/Azoren',
        timezone_ca: 'Atlàntic/Açores',
        country_name: 'Portugal',
        country_name_el: 'Πορτογαλία',
        country_name_es: 'Portugal',
        country_name_it: 'Portogallo',
        country_name_fr: 'Portugal',
        country_name_de: 'Portugal',
        country_name_ca: 'Portugal',
        country_capital: 'Lisbon',
        country_capital_el: 'Λισαβόνα',
        country_capital_es: 'Lisboa',
        country_capital_it: 'Lisbona',
        country_capital_fr: 'Lisbonne',
        country_capital_de: 'Lissabon',
        country_capital_ca: 'Lisboa',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'PY',
        utc_offset: -4,
        timezone: 'America/Asuncion',
        timezone_el: 'Αμερική/Ασουνσιόν',
        timezone_es: 'América/Asunción',
        timezone_it: 'América/Asunción',
        timezone_fr: 'Amérique/Asunción',
        timezone_de: 'Amerika/Asuncion',
        timezone_ca: 'Amèrica/Asunción',
        country_name: 'Paraguay',
        country_name_el: 'Παραγουάη',
        country_name_es: 'Paraguay',
        country_name_it: 'Paraguay',
        country_name_fr: 'Paraguay',
        country_name_de: 'Paraguay',
        country_name_ca: 'Paraguai',
        country_capital: 'Asunción',
        country_capital_el: 'Ασουνσιόν',
        country_capital_es: 'Asunción',
        country_capital_it: 'Asunción',
        country_capital_fr: 'Asunción',
        country_capital_de: 'Asunción',
        country_capital_ca: 'Asunción',
        country_continent: 'South America',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'América del Sur',
        country_continent_it: 'South America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'PS',
        utc_offset: 2,
        timezone: 'Asia/Gaza',
        timezone_el: 'Ασία/Γάζα',
        timezone_es: 'Asia/Gaza',
        timezone_it: 'Asia/Gaza',
        timezone_fr: 'Asie/Gaza',
        timezone_de: 'Asien/Gaza',
        timezone_ca: 'Àsia/Gaza',
        country_name: 'Palestine',
        country_name_el: 'Παλαιστίνη',
        country_name_es: 'Palestina',
        country_name_it: 'Palestina',
        country_name_fr: 'Palestine',
        country_name_de: 'Palästina',
        country_name_ca: 'Palestina',
        country_capital: 'Ramallah',
        country_capital_el: 'Ραμάλλα',
        country_capital_es: 'Ramala',
        country_capital_it: 'Ramallah',
        country_capital_fr: 'Ramallah',
        country_capital_de: 'Ramallah',
        country_capital_ca: 'Ramallah',
        country_continent: 'Asia',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'PS',
        utc_offset: 2,
        timezone: 'Asia/Hebron',
        timezone_el: 'Ασία/Χεβρώνα',
        timezone_es: 'Asia/Hebrón',
        timezone_it: 'Asia/Hebrón',
        timezone_fr: 'Asie/Hébron',
        timezone_de: 'Asien/Hebron',
        timezone_ca: 'Àsia/Hebron',
        country_name: 'Palestine',
        country_name_el: 'Παλαιστίνη',
        country_name_es: 'Palestina',
        country_name_it: 'Palestina',
        country_name_fr: 'Palestine',
        country_name_de: 'Palästina',
        country_name_ca: 'Palestina',
        country_capital: 'Ramallah',
        country_capital_el: 'Ραμάλλα',
        country_capital_es: 'Ramala',
        country_capital_it: 'Ramallah',
        country_capital_fr: 'Ramallah',
        country_capital_de: 'Ramallah',
        country_capital_ca: 'Ramallah',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'PF',
        utc_offset: -10,
        timezone: 'Pacific/Tahiti',
        timezone_el: 'Ειρηνικός/Ταϊτή',
        timezone_es: 'Pacífico/Tahití',
        timezone_it: 'Pacífico/Tahití',
        timezone_fr: 'Pacifique/Tahiti',
        timezone_de: 'Pazifik/Tahiti',
        timezone_ca: 'Pacífic/Tahití',
        country_name: 'French Polynesia',
        country_name_el: 'Γαλλική Πολυνησία',
        country_name_es: 'Polinesia Francesa',
        country_name_it: 'Polinesia Francese',
        country_name_fr: 'Polynésie française',
        country_name_de: 'Französisch-Polynesien',
        country_name_ca: 'Polinèsia Francesa',
        country_capital: 'Papeetē',
        country_capital_el: 'Παπεετέ',
        country_capital_es: 'Papeete',
        country_capital_it: 'Papeete',
        country_capital_fr: 'Papeete',
        country_capital_de: 'Papeete',
        country_capital_ca: 'Papeete',
        country_continent: 'Oceania',
        country_continent_el: 'Ασία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'PF',
        utc_offset: -10,
        timezone: 'Pacific/Marquesas',
        timezone_el: 'Ειρηνικός/Μαρκέζας',
        timezone_es: 'Pacífico/Marquesas',
        timezone_it: 'Pacífico/Marquesas',
        timezone_fr: 'Pacifique/Marquises',
        timezone_de: 'Pazifik/Marquesas',
        timezone_ca: 'Pacífic/Marqueses',
        country_name: 'French Polynesia',
        country_name_el: 'Γαλλική Πολυνησία',
        country_name_es: 'Polinesia Francesa',
        country_name_it: 'Polinesia Francese',
        country_name_fr: 'Polynésie française',
        country_name_de: 'Französisch-Polynesien',
        country_name_ca: 'Polinèsia Francesa',
        country_capital: 'Papeetē',
        country_capital_el: 'Παπεετέ',
        country_capital_es: 'Papeete',
        country_capital_it: 'Papeete',
        country_capital_fr: 'Papeete',
        country_capital_de: 'Papeete',
        country_capital_ca: 'Papeete',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'PF',
        utc_offset: -10,
        timezone: 'Pacific/Gambier',
        timezone_el: 'Ειρηνικός/Γκάμπιερ',
        timezone_es: 'Pacífico/Gambier',
        timezone_it: 'Pacífico/Gambier',
        timezone_fr: 'Pacifique/Gambier',
        timezone_de: 'Pazifik/Gambier',
        timezone_ca: 'Pacífic/Gambier',
        country_name: 'French Polynesia',
        country_name_el: 'Γαλλική Πολυνησία',
        country_name_es: 'Polinesia Francesa',
        country_name_it: 'Polinesia Francese',
        country_name_fr: 'Polynésie française',
        country_name_de: 'Französisch-Polynesien',
        country_name_ca: 'Polinèsia Francesa',
        country_capital: 'Papeetē',
        country_capital_el: 'Παπεετέ',
        country_capital_es: 'Papeete',
        country_capital_it: 'Papeete',
        country_capital_fr: 'Papeete',
        country_capital_de: 'Papeete',
        country_capital_ca: 'Papeete',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'QA',
        utc_offset: 3,
        timezone: 'Asia/Qatar',
        timezone_el: 'Ασία/Κατάρ',
        timezone_es: 'Asia/Qatar',
        timezone_it: 'Asia/Catar',
        timezone_fr: 'Asie/Qatar',
        timezone_de: 'Asien/Katar',
        timezone_ca: 'Àsia/Qatar',
        country_name: 'Qatar',
        country_name_el: 'Κατάρ',
        country_name_es: 'Catar',
        country_name_it: 'Qatar',
        country_name_fr: 'Qatar',
        country_name_de: 'Katar',
        country_name_ca: 'Qatar',
        country_capital: 'Doha',
        country_capital_el: 'Ντόχα',
        country_capital_es: 'Doha',
        country_capital_it: 'Doha',
        country_capital_fr: 'Doha',
        country_capital_de: 'Doha',
        country_capital_ca: 'Doha',
        country_continent: 'Asia',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RE',
        utc_offset: 4,
        timezone: 'Indian/Reunion',
        timezone_el: 'Ινδικός/Ρεϊνιόν',
        timezone_es: 'Reunión',
        timezone_it: 'Índico/Reunión',
        timezone_fr: 'Indien/Réunion',
        timezone_de: 'Indischer/Ozean/Reunion',
        timezone_ca: 'Índic/Réunion',
        country_name: 'Réunion',
        country_name_el: 'Ρεϊνιόν',
        country_name_es: 'Reunión',
        country_name_it: 'Riunione',
        country_name_fr: 'La Réunion',
        country_name_de: 'Réunion',
        country_name_ca: 'Reunió',
        country_capital: 'Saint-Denis',
        country_capital_el: 'Σαιντ-Ντενί',
        country_capital_es: 'Saint-Denis',
        country_capital_it: 'Saint-Denis',
        country_capital_fr: 'Saint-Denis',
        country_capital_de: 'Saint-Denis',
        country_capital_ca: 'Saint-Denis',
        country_continent: 'Africa',
        country_continent_el: 'Ασία',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'RO',
        utc_offset: 2,
        timezone: 'Europe/Bucharest',
        timezone_el: 'Ευρώπη/Βουκουρέστι',
        timezone_es: 'Europa/Bucarest',
        timezone_it: 'Europa/Bucarest',
        timezone_fr: 'Europe/Bucarest',
        timezone_de: 'Europa/Bukarest',
        timezone_ca: 'Europa/Bucarest',
        country_name: 'Romania',
        country_name_el: 'Ρουμανία',
        country_name_es: 'Rumania',
        country_name_it: 'Romania',
        country_name_fr: 'Roumanie',
        country_name_de: 'Rumänien',
        country_name_ca: 'Romania',
        country_capital: 'Bucharest',
        country_capital_el: 'Βουκουρέστι',
        country_capital_es: 'Bucarest',
        country_capital_it: 'Bucarest',
        country_capital_fr: 'Bucarest',
        country_capital_de: 'Bukarest',
        country_capital_ca: 'Bucarest',
        country_continent: 'Europe',
        country_continent_el: 'Αφρική',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Europe/Kaliningrad',
        timezone_el: 'Ευρώπη/Καλίνινγκραντ',
        timezone_es: 'Europa/Kaliningrado',
        timezone_it: 'Europa/Kaliningrado',
        timezone_fr: 'Europe/Kaliningrad',
        timezone_de: 'Europa/Kaliningrad',
        timezone_ca: 'Europa/Kaliningrad',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Europe/Moscow',
        timezone_el: 'Ευρώπη/Μόσχα',
        timezone_es: 'Europa/Moscú',
        timezone_it: 'Europa/Moscú',
        timezone_fr: 'Europe/Moscou',
        timezone_de: 'Europa/Moskau',
        timezone_ca: 'Europa/Moscú',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Europe/Simferopol',
        timezone_el: 'Ευρώπη/Σιμφερόπολη',
        timezone_es: 'Europa/Simferópol',
        timezone_it: 'Europa/Simferópol',
        timezone_fr: 'Europe/Simferopol',
        timezone_de: 'Europa/Simferopol',
        timezone_ca: 'Europa/Simferopol',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Europe/Volgograd',
        timezone_el: 'Ευρώπη/Βόλγογραντ',
        timezone_es: 'Europa/Volgogrado',
        timezone_it: 'Europa/Volgogrado',
        timezone_fr: 'Europe/Volgograd',
        timezone_de: 'Europa/Wolgograd',
        timezone_ca: 'Europa/Volgograd',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Europe/Kirov',
        timezone_el: 'Ευρώπη/Κίροβ',
        timezone_es: 'Europa/Kiróv',
        timezone_it: 'Europa/Kírov',
        timezone_fr: 'Europe/Kirov',
        timezone_de: 'Europa/Kirov',
        timezone_ca: 'Europa/Kirov',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Europe/Astrakhan',
        timezone_el: 'Ευρώπη/Αστραχάν',
        timezone_es: 'Europa/Astracán',
        timezone_it: 'Europa/Astracán',
        timezone_fr: 'Europe/Astrakhan',
        timezone_de: 'Europa/Astrachan',
        timezone_ca: 'Europa/Astrakhan',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Europe/Samara',
        timezone_el: 'Ευρώπη/Σαμάρα',
        timezone_es: 'Europa/Samara',
        timezone_it: 'Europa/Samara',
        timezone_fr: 'Europe/Samara',
        timezone_de: 'Europa/Samara',
        timezone_ca: 'Europa/Samara',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Europe/Ulyanovsk',
        timezone_el: 'Ευρώπη/Ουλιάνοφσκ',
        timezone_es: 'Europa/Uliánovsk',
        timezone_it: 'Europa/Uliánovsk',
        timezone_fr: 'Europe/Oulianovsk',
        timezone_de: 'Europa/Ulyanovsk',
        timezone_ca: 'Europa/Ulyanovsk',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Yekaterinburg',
        timezone_el: 'Ασία/Εκατερίνμπουργκ',
        timezone_es: 'Asia/Yekaterinburgo',
        timezone_it: 'Asia/Yekaterimburgo',
        timezone_fr: 'Asie/Iekaterinbourg',
        timezone_de: 'Asien/Jekaterinburg',
        timezone_ca: 'Àsia/Yekaterinburg',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Omsk',
        timezone_el: 'Ασία/Ομσκ',
        timezone_es: 'Asia/Omsk',
        timezone_it: 'Asia/Omsk',
        timezone_fr: 'Asie/Omsk',
        timezone_de: 'Asien/Omsk',
        timezone_ca: 'Àsia/Omsk',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Novosibirsk',
        timezone_el: 'Ασία/Νοβοσιμπίρσκ',
        timezone_es: 'Asia/Novosibirsk',
        timezone_it: 'Asia/Novosibirsk',
        timezone_fr: 'Asie/Novossibirsk',
        timezone_de: 'Asien/Nowosibirsk',
        timezone_ca: 'Àsia/Novosibirsk',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Barnaul',
        timezone_el: 'Ασία/Μπαρνάουλ',
        timezone_es: 'Asia/Barnaul',
        timezone_it: 'Asia/Barnaul',
        timezone_fr: 'Asie/Barna',
        timezone_de: 'Asien/Barnaul',
        timezone_ca: 'Àsia/Barnaul',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Tomsk',
        timezone_el: 'Ασία/Τομσκ',
        timezone_es: 'Asia/Tomsk',
        timezone_it: 'Asia/Tomsk',
        timezone_fr: 'Asie/Tomsk',
        timezone_de: 'Asien/Tomsk',
        timezone_ca: 'Àsia/Tomsk',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Novokuznetsk',
        timezone_el: 'Ασία/Νοβοκούζνετσκ',
        timezone_es: 'Asia/Novokuznetsk',
        timezone_it: 'Asia/Novokuznetsk',
        timezone_fr: 'Asie/Novokuznetsk',
        timezone_de: 'Asien/Nowokuznetsk',
        timezone_ca: 'Àsia/Novokuznetsk',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Krasnoyarsk',
        timezone_el: 'Ασία/Κρασνογιάρσκ',
        timezone_es: 'Asia/Krasnoyarsk',
        timezone_it: 'Asia/Krasnoyarsk',
        timezone_fr: 'Asie/Krasnoyarsk',
        timezone_de: 'Asien/Krasnojarsk',
        timezone_ca: 'Àsia/Krasnoyarsk',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Irkutsk',
        timezone_el: 'Ασία/Ιρκούτσκ',
        timezone_es: 'Asia/Irkutsk',
        timezone_it: 'Asia/Irkutsk',
        timezone_fr: 'Asie/Irkoutsk',
        timezone_de: 'Asien/Irkutsk',
        timezone_ca: 'Àsia/Irkutsk',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Chita',
        timezone_el: 'Ασία/Τσίτα',
        timezone_es: 'Asia/Chitá',
        timezone_it: 'Asia/Chitá',
        timezone_fr: 'Asie/Tchita',
        timezone_de: 'Asien/Chita',
        timezone_ca: 'Àsia/Chita',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Yakutsk',
        timezone_el: 'Ασία/Γιακούτσκ',
        timezone_es: 'Asia/Yakutsk',
        timezone_it: 'Asia/Yakutsk',
        timezone_fr: 'Asie/Iakoutsk',
        timezone_de: 'Asien/Jakutsk',
        timezone_ca: 'Àsia/Yakutsk',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Khandyga',
        timezone_el: 'Ασία/Χαντίγκα',
        timezone_es: 'Asia/Khandyga',
        timezone_it: 'Asia/Khandyga',
        timezone_fr: 'Asie/Khandyga',
        timezone_de: 'Asien/Khandyga',
        timezone_ca: 'Àsia/Khandyga',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Vladivostok',
        timezone_el: 'Ασία/Βλαδιβοστόκ',
        timezone_es: 'Asia/Vladivostok',
        timezone_it: 'Asia/Vladivostok',
        timezone_fr: 'Asie/Vladivostok',
        timezone_de: 'Asien/Wladiwostok',
        timezone_ca: 'Àsia/Vladivostok',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Ust-Nera',
        timezone_el: 'Ασία/Ουστ-Νερά',
        timezone_es: 'Asia/Ust-Nera',
        timezone_it: 'Asia/Ust-Nera',
        timezone_fr: 'Asie/Ust-Nera',
        timezone_de: 'Asien/Ust-Nera',
        timezone_ca: 'Àsia/Ust-Nera',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Magadan',
        timezone_el: 'Ασία/Μαγκαντάν',
        timezone_es: 'Asia/Magadán',
        timezone_it: 'Asia/Magadán',
        timezone_fr: 'Asie/Magadan',
        timezone_de: 'Asien/Magadan',
        timezone_ca: 'Àsia/Magadan',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Sakhalin',
        timezone_el: 'Ασία/Σαχαλίν',
        timezone_es: 'Asia/Sajalín',
        timezone_it: 'Asia/Sajalín',
        timezone_fr: 'Asie/Sakhaline',
        timezone_de: 'Asien/Sachalin',
        timezone_ca: 'Àsia/Sakhalin',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Μόσχα',
        country_capital_es: 'Moscú',
        country_capital_it: 'Mosca',
        country_capital_fr: 'Moscou',
        country_capital_de: 'Moskau',
        country_capital_ca: 'Moscou',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Srednekolymsk',
        timezone_el: 'Ασία/Σρεντνεκόλιμσκ',
        timezone_es: 'Asia/Srednekolímsk',
        timezone_it: 'Asia/Srednekolímsk',
        timezone_fr: 'Asie/Srednekolymsk',
        timezone_de: 'Asien/Srednekolymsk',
        timezone_ca: 'Àsia/Srednekolymsk',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Κιγκάλι',
        country_capital_es: 'Kigali',
        country_capital_it: 'Kigali',
        country_capital_fr: 'Kigali',
        country_capital_de: 'Kigali',
        country_capital_ca: 'Kigali',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Kamchatka',
        timezone_el: 'Ασία/Καμτσάτκα',
        timezone_es: 'Asia/Kamchatka',
        timezone_it: 'Asia/Kamchatka',
        timezone_fr: 'Asie/Kamtchatka',
        timezone_de: 'Asien/Kamtschatka',
        timezone_ca: 'Àsia/Kamtxatka',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Ριάντ',
        country_capital_es: 'Riad',
        country_capital_it: 'Riad',
        country_capital_fr: 'Riyad',
        country_capital_de: 'Riad',
        country_capital_ca: 'Riad',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RU',
        utc_offset: 0,
        timezone: 'Asia/Anadyr',
        timezone_el: 'Ασία/Αναντίρ',
        timezone_es: 'Asia/Anádyr',
        timezone_it: 'Asia/Anádyr',
        timezone_fr: 'Asie/Anadyr',
        timezone_de: 'Asien/Anadyr',
        timezone_ca: 'Àsia/Anadyr',
        country_name: 'Russia',
        country_name_el: 'Ρωσία',
        country_name_es: 'Rusia',
        country_name_it: 'Russia',
        country_name_fr: 'Russie',
        country_name_de: 'Russland',
        country_name_ca: 'Rússia',
        country_capital: 'Moscow',
        country_capital_el: 'Χαρτούμ',
        country_capital_es: 'Jartum',
        country_capital_it: 'Khartoum',
        country_capital_fr: 'Khartoum',
        country_capital_de: 'Khartum',
        country_capital_ca: 'Cartum',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'RW',
        utc_offset: 2,
        timezone: 'Africa/Kigali',
        timezone_el: 'Αφρική/Κιγκάλι',
        timezone_es: 'África/Kigali',
        timezone_it: 'África/Kigali',
        timezone_fr: 'Afrique/Kigali',
        timezone_de: 'Afrika/Kigali',
        timezone_ca: 'Àfrica/Kigali',
        country_name: 'Rwanda',
        country_name_el: 'Ρουάντα',
        country_name_es: 'Ruanda',
        country_name_it: 'Ruanda',
        country_name_fr: 'Rwanda',
        country_name_de: 'Ruanda',
        country_name_ca: 'Ruanda',
        country_capital: 'Kigali',
        country_capital_el: 'Ντακάρ',
        country_capital_es: 'Dakar',
        country_capital_it: 'Dakar',
        country_capital_fr: 'Dakar',
        country_capital_de: 'Dakar',
        country_capital_ca: 'Dakar',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'SA',
        utc_offset: 3,
        timezone: 'Asia/Riyadh',
        timezone_el: 'Ασία/Ριάντ',
        timezone_es: 'Asia/Riad',
        timezone_it: 'Asia/Riad',
        timezone_fr: 'Asie/Riyad',
        timezone_de: 'Asien/Riad',
        timezone_ca: 'Àsia/Riyadh',
        country_name: 'Saudi Arabia',
        country_name_el: 'Σαουδική Αραβία',
        country_name_es: 'Arabia Saudita',
        country_name_it: 'Arabia Saudita',
        country_name_fr: 'Arabie Saoudite',
        country_name_de: 'Saudi-Arabien',
        country_name_ca: 'Aràbia Saudita',
        country_capital: 'Riyadh',
        country_capital_el: 'Σιγκαπούρη',
        country_capital_es: 'Singapur',
        country_capital_it: 'Singapore',
        country_capital_fr: 'Singapour',
        country_capital_de: 'Singapur',
        country_capital_ca: 'Singapur',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'SD',
        utc_offset: 3,
        timezone: 'Africa/Khartoum',
        timezone_el: 'Αφρική/Χαρτούμ',
        timezone_es: 'África/Jartum',
        timezone_it: 'África/Jartum',
        timezone_fr: 'Afrique/Khartoum',
        timezone_de: 'Afrika/Khartum',
        timezone_ca: 'Àfrica/Khartoum',
        country_name: 'Sudan',
        country_name_el: 'Σουδάν',
        country_name_es: 'Sudán',
        country_name_it: 'Sudan',
        country_name_fr: 'Soudan',
        country_name_de: 'Sudan',
        country_name_ca: 'Sudan',
        country_capital: 'Khartoum',
        country_capital_el: 'Κινγκ Έντουαρντ Πόιντ',
        country_capital_es: 'King Edward Point',
        country_capital_it: 'King Edward Point',
        country_capital_fr: 'King Edward Point',
        country_capital_de: 'King Edward Point',
        country_capital_ca: 'King Edward Point',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'SN',
        utc_offset: 0,
        timezone: 'Africa/Dakar',
        timezone_el: 'Αφρική/Ντακάρ',
        timezone_es: 'África/Dakar',
        timezone_it: 'África/Dakar',
        timezone_fr: 'Afrique/Dakar',
        timezone_de: 'Afrika/Dakar',
        timezone_ca: 'Àfrica/Dakar',
        country_name: 'Senegal',
        country_name_el: 'Σενεγάλη',
        country_name_es: 'Senegal',
        country_name_it: 'Senegal',
        country_name_fr: 'Sénégal',
        country_name_de: 'Senegal',
        country_name_ca: 'Senegal',
        country_capital: 'Dakar',
        country_capital_el: 'Λόνγκιεαρμπιεν',
        country_capital_es: 'Longyearbyen',
        country_capital_it: 'Longyearbyen',
        country_capital_fr: 'Longyearbyen',
        country_capital_de: 'Longyearbyen',
        country_capital_ca: 'Longyearbyen',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'SG',
        utc_offset: 8,
        timezone: 'Asia/Singapore',
        timezone_el: 'Ασία/Σιγκαπούρη',
        timezone_es: 'Asia/Singapur',
        timezone_it: 'Asia/Singapur',
        timezone_fr: 'Asie/Singapour',
        timezone_de: 'Asien/Singapur',
        timezone_ca: 'Àsia/Singapur',
        country_name: 'Singapore',
        country_name_el: 'Σιγκαπούρη',
        country_name_es: 'Singapur',
        country_name_it: 'Singapore',
        country_name_fr: 'Singapour',
        country_name_de: 'Singapur',
        country_name_ca: 'Singapur',
        country_capital: 'Singapore',
        country_capital_el: 'Χονιάρα',
        country_capital_es: 'Honiara',
        country_capital_it: 'Honiara',
        country_capital_fr: 'Honiara',
        country_capital_de: 'Honiara',
        country_capital_ca: 'Honiara',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'GS',
        utc_offset: -2,
        timezone: 'Atlantic/South_Georgia',
        timezone_el: 'Ατλαντικός/Νότια Γεωργία',
        timezone_es: 'Atlántico/Georgia_del_Sur',
        timezone_it: 'Atlántico/Isla_Georgia_del_Sur',
        timezone_fr: 'Atlantique/Géorgie_du_Sud',
        timezone_de: 'Atlantik/Südgeorgien',
        timezone_ca: 'Atlàntic/Sud_Georgia',
        country_name: 'South Georgia',
        country_name_el: 'Νότια Γεωργία',
        country_name_es: 'Islas Georgias del Sur',
        country_name_it: 'Georgia del Sud',
        country_name_fr: 'Géorgie du Sud',
        country_name_de: 'Südgeorgien',
        country_name_ca: 'Geòrgia del Sud',
        country_capital: 'King Edward Point',
        country_capital_el: 'Φρίταουν',
        country_capital_es: 'Freetown',
        country_capital_it: 'Freetown',
        country_capital_fr: 'Freetown',
        country_capital_de: 'Freetown',
        country_capital_ca: 'Freetown',
        country_continent: 'Antarctica',
        country_continent_el: 'Ανταρκτική',
        country_continent_es: 'Antártida',
        country_continent_it: 'Antarctica',
        country_continent_fr: 'Antarctique',
        country_continent_de: 'Antarktis',
        country_continent_ca: 'Antàrtida'
    },
    {
        country_code: 'SJ',
        utc_offset: 1,
        timezone: 'Arctic/Longyearbyen',
        timezone_el: 'Αρκτικός/Λόνγκιεαρμπιεν',
        timezone_es: 'Ártico/Longyearbyen',
        timezone_it: 'Ártico/Longyearbyen',
        timezone_fr: 'Arctique/Longyearbyen',
        timezone_de: 'Arktis/Longyearbyen',
        timezone_ca: 'Àrtic/Longyearbyen',
        country_name: 'Svalbard and Jan Mayen',
        country_name_el: 'Σβάλμπαρντ και Τζαν Μαγιέν',
        country_name_es: 'Svalbard y Jan Mayen',
        country_name_it: 'Svalbard e Jan Mayen',
        country_name_fr: 'Svalbard et Jan Mayen',
        country_name_de: 'Spitzbergen und Jan Mayen',
        country_name_ca: 'Svalbard i Jan Mayen',
        country_capital: 'Longyearbyen',
        country_capital_el: 'Σαν Σαλβαδόρ',
        country_capital_es: 'San Salvador',
        country_capital_it: 'San Salvador',
        country_capital_fr: 'Saint-Salvador',
        country_capital_de: 'San Salvador',
        country_capital_ca: 'San Salvador',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'SB',
        utc_offset: 11,
        timezone: 'Pacific/Guadalcanal',
        timezone_el: 'Ειρηνικός/Γκουανταλκανάλ',
        timezone_es: 'Pacífico/Guadalcanal',
        timezone_it: 'Pacífico/Guadalcanal',
        timezone_fr: 'Pacifique/Guadalcanal',
        timezone_de: 'Pazifik/Guadalcanal',
        timezone_ca: 'Pacífic/Guadalcanal',
        country_name: 'Solomon Islands',
        country_name_el: 'Νήσοι Σολομώντος',
        country_name_es: 'Islas Salomón',
        country_name_it: 'Isole Salomone',
        country_name_fr: 'Îles Salomon',
        country_name_de: 'Salomonen',
        country_name_ca: 'Illes Salomó',
        country_capital: 'Honiara',
        country_capital_el: 'Πόλη του Σαν Μαρίνο',
        country_capital_es: 'Ciudad de San Marino',
        country_capital_it: 'Città di San Marino',
        country_capital_fr: 'Cité de Saint-Marin',
        country_capital_de: 'Stadt San Marino',
        country_capital_ca: 'Ciutat de Saint Marino',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'SL',
        utc_offset: 0,
        timezone: 'Africa/Freetown',
        timezone_el: 'Αφρική/Φρίταουν',
        timezone_es: 'África/Freetown',
        timezone_it: 'África/Freetown',
        timezone_fr: 'Afrique/Freetown',
        timezone_de: 'Afrika/Freetown',
        timezone_ca: 'Àfrica/Freetown',
        country_name: 'Sierra Leone',
        country_name_el: 'Σιέρα Λεόνε',
        country_name_es: 'Sierra Leona',
        country_name_it: 'Sierra Leone',
        country_name_fr: 'Sierra Leone',
        country_name_de: 'Sierra Leone',
        country_name_ca: 'Sierra Leone',
        country_capital: 'Freetown',
        country_capital_el: 'Φρίταουν',
        country_capital_es: 'Freetown',
        country_capital_it: 'Freetown',
        country_capital_fr: 'Freetown',
        country_capital_de: 'Freetown',
        country_capital_ca: 'Freetown',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'SV',
        utc_offset: -6,
        timezone: 'America/El_Salvador',
        timezone_el: 'Αμερική/Ελ Σαλβαδόρ',
        timezone_es: 'América/El_Salvador',
        timezone_it: 'América/El_Salvador',
        timezone_fr: 'Amérique/El_Salvador',
        timezone_de: 'Amerika/El_Salvador',
        timezone_ca: 'Amèrica/El_Salvador',
        country_name: 'El Salvador',
        country_name_el: 'Ελ Σαλβαδόρ',
        country_name_es: 'El Salvador',
        country_name_it: 'El Salvador',
        country_name_fr: 'Salvador',
        country_name_de: 'El Salvador',
        country_name_ca: 'El Salvador',
        country_capital: 'San Salvador',
        country_capital_el: 'Σαν Σαλβαδόρ',
        country_capital_es: 'San Salvador',
        country_capital_it: 'San Salvador',
        country_capital_fr: 'San Salvador',
        country_capital_de: 'San Salvador',
        country_capital_ca: 'San Salvador',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'SM',
        utc_offset: 1,
        timezone: 'Europe/San_Marino',
        timezone_el: 'Ευρώπη/Άγιος Μαρίνος',
        timezone_es: 'Europa/San_Marino',
        timezone_it: 'Europa/San_Marino',
        timezone_fr: 'Europe/San_Marino',
        timezone_de: 'Europa/San_Marino',
        timezone_ca: 'Europa/San_Marino',
        country_name: 'San Marino',
        country_name_el: 'Άγιος Μαρίνος',
        country_name_es: 'San Marino',
        country_name_it: 'San Marino',
        country_name_fr: 'Saint-Marin',
        country_name_de: 'San Marino',
        country_name_ca: 'San Marino',
        country_capital: 'City of San Marino',
        country_capital_el: 'Πόλη του Αγίου Μαρίνου',
        country_capital_es: 'Ciudad de San Marino',
        country_capital_it: 'Città di San Marino',
        country_capital_fr: 'Cité de Saint-Marin',
        country_capital_de: 'Stadt San Marino',
        country_capital_ca: 'Ciutat de Sant Marino',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'SO',
        utc_offset: 3,
        timezone: 'Africa/Mogadishu',
        timezone_el: 'Αφρική/Μογκαντίσου',
        timezone_es: 'África/Mogadiscio',
        timezone_it: 'África/Mogadiscio',
        timezone_fr: 'Afrique/Mogadiscio',
        timezone_de: 'Afrika/Mogadischu',
        timezone_ca: 'Àfrica/Mogadiscio',
        country_name: 'Somalia',
        country_name_el: 'Σομαλία',
        country_name_es: 'Somalia',
        country_name_it: 'Somalia',
        country_name_fr: 'Somalie',
        country_name_de: 'Somalia',
        country_name_ca: 'Somàlia',
        country_capital: 'Mogadishu',
        country_capital_el: 'Μογκαντίσου',
        country_capital_es: 'Mogadiscio',
        country_capital_it: 'Mogadiscio',
        country_capital_fr: 'Mogadiscio',
        country_capital_de: 'Mogadischu',
        country_capital_ca: 'Mogadiscio',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'PM',
        utc_offset: -3,
        timezone: 'America/Miquelon',
        timezone_el: 'Αμερική/Μικελόν',
        timezone_es: 'América/Miquelón',
        timezone_it: 'América/Miquelón',
        timezone_fr: 'Amérique/Miquelon',
        timezone_de: 'Amerika/Miquelon',
        timezone_ca: 'Amèrica/Miqueló',
        country_name: 'Saint Pierre and Miquelon',
        country_name_el: 'Άγιος Πέτρος και Μικελόν',
        country_name_es: 'San Pedro y Miquelón',
        country_name_it: 'Saint Pierre e Miquelon',
        country_name_fr: 'Saint-Pierre-et-Miquelon',
        country_name_de: 'Saint-Pierre und Miquelon',
        country_name_ca: 'Saint Pierre i Miquelon',
        country_capital: 'Saint-Pierre',
        country_capital_el: 'Σαιντ-Πιέρ',
        country_capital_es: 'Saint-Pierre',
        country_capital_it: 'Saint-Pierre',
        country_capital_fr: 'Saint-Pierre',
        country_capital_de: 'Saint-Pierre',
        country_capital_ca: 'Saint-Pierre',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'RS',
        utc_offset: 1,
        timezone: 'Europe/Belgrade',
        timezone_el: 'Ευρώπη/Βελιγράδι',
        timezone_es: 'Europa/Belgrado',
        timezone_it: 'Europa/Belgrado',
        timezone_fr: 'Europe/Belgrade',
        timezone_de: 'Europa/Belgrad',
        timezone_ca: 'Europa/Belgrad',
        country_name: 'Serbia',
        country_name_el: 'Σερβία',
        country_name_es: 'Serbia',
        country_name_it: 'Serbia',
        country_name_fr: 'Serbie',
        country_name_de: 'Serbien',
        country_name_ca: 'Sèrbia',
        country_capital: 'Belgrade',
        country_capital_el: 'Βελιγράδι',
        country_capital_es: 'Belgrado',
        country_capital_it: 'Belgrado',
        country_capital_fr: 'Belgrade',
        country_capital_de: 'Belgrad',
        country_capital_ca: 'Belgrad',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'SS',
        utc_offset: 2,
        timezone: 'Africa/Juba',
        timezone_el: 'Αφρική/Τζούμπα',
        timezone_es: 'África/Yuba',
        timezone_it: 'África/Yuba',
        timezone_fr: 'Afrique/Juba',
        timezone_de: 'Afrika/Juba',
        timezone_ca: 'Àfrica/Juba',
        country_name: 'South Sudan',
        country_name_el: 'Νότιο Σουδάν',
        country_name_es: 'Sudán del Sur',
        country_name_it: 'Sud Sudan',
        country_name_fr: 'Soudan du Sud',
        country_name_de: 'Südsudan',
        country_name_ca: 'Sud Súdan',
        country_capital: 'Juba',
        country_capital_el: 'Τζούμπα',
        country_capital_es: 'Yuba',
        country_capital_it: 'Juba',
        country_capital_fr: 'Djouba',
        country_capital_de: 'Juba',
        country_capital_ca: 'Juba',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'ST',
        utc_offset: 0,
        timezone: 'Africa/Sao_Tome',
        timezone_el: 'Αφρική/Σάο Τομέ',
        timezone_es: 'África/Santo_Tomé',
        timezone_it: 'África/Santo_Tomé',
        timezone_fr: 'Afrique/Sao_Tome',
        timezone_de: 'Afrika/Sao_Tome',
        timezone_ca: 'Àfrica/Sao_Tome',
        country_name: 'São Tomé and Príncipe',
        country_name_el: 'Σάο Τομέ και Πρίνσιπε',
        country_name_es: 'Santo Tomé y Príncipe',
        country_name_it: 'São Tomé e Príncipe',
        country_name_fr: 'Sao Tomé-et-Principe',
        country_name_de: 'São Tomé und Príncipe',
        country_name_ca: 'São Tomé i Príncipe',
        country_capital: 'São Tomé',
        country_capital_el: 'Σάο Τομέ',
        country_capital_es: 'Santo Tomé',
        country_capital_it: 'São Tomé',
        country_capital_fr: 'São Tomé',
        country_capital_de: 'São Tomé',
        country_capital_ca: 'São Tomé',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'SR',
        utc_offset: -3,
        timezone: 'America/Paramaribo',
        timezone_el: 'Αμερική/Παραμαρίμπο',
        timezone_es: 'América/Paramaribo',
        timezone_it: 'América/Paramaribo',
        timezone_fr: 'Amérique/Paramaribo',
        timezone_de: 'Amerika/Paramaribo',
        timezone_ca: 'Amèrica/Paramaribo',
        country_name: 'Suriname',
        country_name_el: 'Σουρινάμ',
        country_name_es: 'Surinam',
        country_name_it: 'Suriname',
        country_name_fr: 'Suriname',
        country_name_de: 'Suriname',
        country_name_ca: 'Surinam',
        country_capital: 'Paramaribo',
        country_capital_el: 'Παραμαρίμπο',
        country_capital_es: 'Paramaribo',
        country_capital_it: 'Paramaribo',
        country_capital_fr: 'Paramaribo',
        country_capital_de: 'Paramaribo',
        country_capital_ca: 'Paramaribo',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'South America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'SK',
        utc_offset: 1,
        timezone: 'Europe/Bratislava',
        timezone_el: 'Ευρώπη/Μπρατισλάβα',
        timezone_es: 'Europa/Bratislava',
        timezone_it: 'Europa/Bratislava',
        timezone_fr: 'Europe/Bratislava',
        timezone_de: 'Europa/Bratislava',
        timezone_ca: 'Europa/Bratislava',
        country_name: 'Slovakia',
        country_name_el: 'Σλοβακία',
        country_name_es: 'Eslovaquia',
        country_name_it: 'Slovacchia',
        country_name_fr: 'Slovaquie',
        country_name_de: 'Slowakei',
        country_name_ca: 'Eslovàquia',
        country_capital: 'Bratislava',
        country_capital_el: 'Μπρατισλάβα',
        country_capital_es: 'Bratislava',
        country_capital_it: 'Bratislava',
        country_capital_fr: 'Bratislava',
        country_capital_de: 'Bratislava',
        country_capital_ca: 'Bratislava',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'SI',
        utc_offset: 1,
        timezone: 'Europe/Ljubljana',
        timezone_el: 'Ευρώπη/Λιουμπλιάνα',
        timezone_es: 'Europa/Liubliana',
        timezone_it: 'Europa/Liubliana',
        timezone_fr: 'Europe/Ljubljana',
        timezone_de: 'Europa/Ljubljana',
        timezone_ca: 'Europa/Liubliana',
        country_name: 'Slovenia',
        country_name_el: 'Σλοβακία',
        country_name_es: 'Eslovaquia',
        country_name_it: 'Slovacchia',
        country_name_fr: 'Slovaquie',
        country_name_de: 'Slowakei',
        country_name_ca: 'Eslovàquia',
        country_capital: 'Ljubljana',
        country_capital_el: 'Λιουμπλιάνα',
        country_capital_es: 'Liubliana',
        country_capital_it: 'Lubiana',
        country_capital_fr: 'Ljubljana',
        country_capital_de: 'Ljubljana',
        country_capital_ca: 'Liubliana',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'SE',
        utc_offset: 1,
        timezone: 'Europe/Stockholm',
        timezone_el: 'Ευρώπη/Στοκχόλμη',
        timezone_es: 'Europa/Estocolmo',
        timezone_it: 'Europa/Estocolmo',
        timezone_fr: 'Europe/Stockholm',
        timezone_de: 'Europa/Stockholm',
        timezone_ca: 'Europa/Estocolm',
        country_name: 'Sweden',
        country_name_el: 'Σουηδία',
        country_name_es: 'Suecia',
        country_name_it: 'Svezia',
        country_name_fr: 'Suède',
        country_name_de: 'Schweden',
        country_name_ca: 'Suècia',
        country_capital: 'Stockholm',
        country_capital_el: 'Στοκχόλμη',
        country_capital_es: 'Estocolmo',
        country_capital_it: 'Stoccolma',
        country_capital_fr: 'Stockholm',
        country_capital_de: 'Stockholm',
        country_capital_ca: 'Estocolm',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'SZ',
        utc_offset: 2,
        timezone: 'Africa/Mbabane',
        timezone_el: 'Αφρική/Μπαμπάνε',
        timezone_es: 'África/Mbabane',
        timezone_it: 'África/Mbabane',
        timezone_fr: 'Afrique/Mbabane',
        timezone_de: 'Afrika/Mbabane',
        timezone_ca: 'Àfrica/Mbabane',
        country_name: 'Swaziland',
        country_name_el: 'Σουαζιλάνδη',
        country_name_es: 'Suazilandia',
        country_name_it: 'Swaziland',
        country_name_fr: 'Eswatini',
        country_name_de: 'Eswatini',
        country_name_ca: 'Swazilàndia',
        country_capital: 'Lobamba',
        country_capital_el: 'Λομπάμπα',
        country_capital_es: 'Lobamba',
        country_capital_it: 'Lobamba',
        country_capital_fr: 'Lobamba',
        country_capital_de: 'Lobamba',
        country_capital_ca: 'Lobamba',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'SX',
        utc_offset: -4,
        timezone: 'America/Lower_Princes',
        timezone_el: 'Αμερική/Κάτω Πριγκιπάτα',
        timezone_es: 'América/Bajos_Príncipes',
        timezone_it: 'América/Bajos_Principales',
        timezone_fr: 'Amérique/Lower_Princes',
        timezone_de: 'Amerika/Lower_Princes',
        timezone_ca: 'Amèrica/Lower_Princes',
        country_name: 'Sint Maarten',
        country_name_el: 'Άγιος Μαρτίνος',
        country_name_es: 'Sint Maarten',
        country_name_it: 'Sint Maarten',
        country_name_fr: 'Saint-Martin',
        country_name_de: 'Sint Maarten',
        country_name_ca: 'Sint Maarten',
        country_capital: 'Philipsburg',
        country_capital_el: 'Φίλιπσμπεργκ',
        country_capital_es: 'Philipsburg',
        country_capital_it: 'Philipsburg',
        country_capital_fr: 'Philipsburg',
        country_capital_de: 'Philipsburg',
        country_capital_ca: 'Philipsburg',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'SC',
        utc_offset: 4,
        timezone: 'Indian/Mahe',
        timezone_el: 'Ινδικός/Μαέ',
        timezone_es: 'Mahé (Seychelles)',
        timezone_it: 'Indico/Mahe',
        timezone_fr: 'Indien/Mahe',
        timezone_de: 'Indischer/Ozean/Mahe',
        timezone_ca: 'Índic/Mahe',
        country_name: 'Seychelles',
        country_name_el: 'Σεϋχέλλες',
        country_name_es: 'Seychelles',
        country_name_it: 'Seychelles',
        country_name_fr: 'Seychelles',
        country_name_de: 'Seychellen',
        country_name_ca: 'Seychelles',
        country_capital: 'Victoria',
        country_capital_el: 'Βικτώρια',
        country_capital_es: 'Victoria',
        country_capital_it: 'Victoria',
        country_capital_fr: 'Victoria',
        country_capital_de: 'Victoria',
        country_capital_ca: 'Victoria',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'SY',
        utc_offset: 2,
        timezone: 'Asia/Damascus',
        timezone_el: 'Ασία/Δαμασκός',
        timezone_es: 'Asia/Damasco',
        timezone_it: 'Asia/Damasco',
        timezone_fr: 'Asie/Damas',
        timezone_de: 'Asien/Damaskus',
        timezone_ca: 'Àsia/Damas',
        country_name: 'Syria',
        country_name_el: 'Συρία',
        country_name_es: 'Siria',
        country_name_it: 'Siria',
        country_name_fr: 'Syrie',
        country_name_de: 'Syrien',
        country_name_ca: 'Síria',
        country_capital: 'Damascus',
        country_capital_el: 'Δαμασκός',
        country_capital_es: 'Damasco',
        country_capital_it: 'Damasco',
        country_capital_fr: 'Damas',
        country_capital_de: 'Damaskus',
        country_capital_ca: 'Damasc',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'TC',
        utc_offset: -5,
        timezone: 'America/Grand_Turk',
        timezone_el: 'Αμερική/Γκραντ Τουρκ',
        timezone_es: 'América/Gran_Turca',
        timezone_it: 'América/Gran_Turca',
        timezone_fr: 'Amérique/Grand_Turk',
        timezone_de: 'Amerika/Grand_Turk',
        timezone_ca: 'Amèrica/Grand_Turk',
        country_name: 'Turks and Caicos Islands',
        country_name_el: 'Νήσοι Τερκς και Κέικος',
        country_name_es: 'Islas Turcas y Caicos',
        country_name_it: 'Isole Turks e Caicos',
        country_name_fr: 'Îles Turques et Caïques',
        country_name_de: 'Turks- und Caicosinseln',
        country_name_ca: 'Illes Turks i Caicos',
        country_capital: 'Cockburn Town',
        country_capital_el: 'Κόκμπερν Τάουν',
        country_capital_es: 'Cockburn Town',
        country_capital_it: 'Cockburn Town',
        country_capital_fr: 'Cockburn Town',
        country_capital_de: 'Cockburn Town',
        country_capital_ca: 'Cockburn Town',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'TD',
        utc_offset: 1,
        timezone: 'Africa/Ndjamena',
        timezone_el: 'Αφρική/Ντζαμένα',
        timezone_es: 'África/N\'Djamena',
        timezone_it: 'África/N\'Djamena',
        timezone_fr: 'Afrique/Ndjamena',
        timezone_de: 'Afrika/Ndjamena',
        timezone_ca: 'Àfrica/Ndjamena',
        country_name: 'Chad',
        country_name_el: 'Τσαντ',
        country_name_es: 'Chad',
        country_name_it: 'Ciad',
        country_name_fr: 'Tchad',
        country_name_de: 'Tschad',
        country_name_ca: 'Txad',
        country_capital: 'N\'Djamena',
        country_capital_el: 'Ντζαμένα',
        country_capital_es: 'Yamena',
        country_capital_it: 'N\'Djamena',
        country_capital_fr: 'N\'Djaména',
        country_capital_de: 'N\'Djamena',
        country_capital_ca: 'N\'Djamena',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'TG',
        utc_offset: 0,
        timezone: 'Africa/Lome',
        timezone_el: 'Αφρική/Λομέ',
        timezone_es: 'África/Lomé',
        timezone_it: 'África/Lomé',
        timezone_fr: 'Afrique/Lome',
        timezone_de: 'Afrika/Lome',
        timezone_ca: 'Àfrica/Lomé',
        country_name: 'Togo',
        country_name_el: 'Τόγκο',
        country_name_es: 'Togo',
        country_name_it: 'Togo',
        country_name_fr: 'Togo',
        country_name_de: 'Togo',
        country_name_ca: 'Togo',
        country_capital: 'Lomé',
        country_capital_el: 'Λομέ',
        country_capital_es: 'Lomé',
        country_capital_it: 'Lomé',
        country_capital_fr: 'Lomé',
        country_capital_de: 'Lomé',
        country_capital_ca: 'Lomé',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'TH',
        utc_offset: 7,
        timezone: 'Asia/Bangkok',
        timezone_el: 'Ασία/Μπανγκόκ',
        timezone_es: 'Asia/Bangkok',
        timezone_it: 'Asia/Bangkok',
        timezone_fr: 'Asie/Bangkok',
        timezone_de: 'Asien/Bangkok',
        timezone_ca: 'Àsia/Bangkok',
        country_name: 'Thailand',
        country_name_el: 'Ταϊλάνδη',
        country_name_es: 'Tailandia',
        country_name_it: 'Thailandia',
        country_name_fr: 'Thaïlande',
        country_name_de: 'Thailand',
        country_name_ca: 'Tailàndia',
        country_capital: 'Bangkok',
        country_capital_el: 'Μπανγκόκ',
        country_capital_es: 'Bangkok',
        country_capital_it: 'Bangkok',
        country_capital_fr: 'Bangkok',
        country_capital_de: 'Bangkok',
        country_capital_ca: 'Bangkok',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'TJ',
        utc_offset: 5,
        timezone: 'Asia/Dushanbe',
        timezone_el: 'Ασία/Ντουσανμπέ',
        timezone_es: 'Asia/Dusambé',
        timezone_it: 'Asia/Dusambé',
        timezone_fr: 'Asie/Douchanbe',
        timezone_de: 'Asien/Duschanbe',
        timezone_ca: 'Àsia/Dushanbe',
        country_name: 'Tajikistan',
        country_name_el: 'Τατζικιστάν',
        country_name_es: 'Tayikistán',
        country_name_it: 'Tagikistan',
        country_name_fr: 'Tadjikistan',
        country_name_de: 'Tadschikistan',
        country_name_ca: 'Tadjikistan',
        country_capital: 'Dushanbe',
        country_capital_el: 'Ντουσάνμπε',
        country_capital_es: 'Dusambé',
        country_capital_it: 'Dušanbe',
        country_capital_fr: 'Douchanbé',
        country_capital_de: 'Duschanbe',
        country_capital_ca: 'Duchanbé',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'TK',
        utc_offset: 13,
        timezone: 'Pacific/Fakaofo',
        timezone_el: 'Ειρηνικός/Φακάοφο',
        timezone_es: 'Pacífico/Fakaofo',
        timezone_it: 'Pacífico/Fakaofo',
        timezone_fr: 'Pacifique/Fakaofo',
        timezone_de: 'Pazifik/Fakaofo',
        timezone_ca: 'Pacífic/Fakaofo',
        country_name: 'Tokelau',
        country_name_el: 'Τοκελάου',
        country_name_es: 'Tokelau',
        country_name_it: 'Tokelau',
        country_name_fr: 'Tokelau',
        country_name_de: 'Tokelau',
        country_name_ca: 'Tokelau',
        country_capital: 'Fakaofo',
        country_capital_el: 'Φακαόφο',
        country_capital_es: 'Fakaofo',
        country_capital_it: 'Fakaofo',
        country_capital_fr: 'Fakaofo',
        country_capital_de: 'Fakaofo',
        country_capital_ca: 'Fakaofo',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'TM',
        utc_offset: 5,
        timezone: 'Asia/Ashgabat',
        timezone_el: 'Ασία/Ασγκαμπάτ',
        timezone_es: 'Asia/Asjabad',
        timezone_it: 'Asia/Asjabad',
        timezone_fr: 'Asie/Achgabat',
        timezone_de: 'Asien/Aschgabat',
        timezone_ca: 'Àsia/Ashgabat',
        country_name: 'Turkmenistan',
        country_name_el: 'Τουρκμενιστάν',
        country_name_es: 'Turkmenistán',
        country_name_it: 'Turkmenistan',
        country_name_fr: 'Turkménistan',
        country_name_de: 'Turkmenistan',
        country_name_ca: 'Turkmènistan',
        country_capital: 'Ashgabat',
        country_capital_el: 'Ασγκαμπάτ',
        country_capital_es: 'Asjabad',
        country_capital_it: 'Aşgabat',
        country_capital_fr: 'Achgabat',
        country_capital_de: 'Aschgabat',
        country_capital_ca: 'Asgabat',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'TL',
        utc_offset: 9,
        timezone: 'Asia/Dili',
        timezone_el: 'Ασία/Ντίλι',
        timezone_es: 'Asia/Dili',
        timezone_it: 'Asia/Dili',
        timezone_fr: 'Asie/Dili',
        timezone_de: 'Asien/Dili',
        timezone_ca: 'Àsia/Dili',
        country_name: 'Timor-Leste',
        country_name_el: 'Τιμόρ-Λέστε',
        country_name_es: 'Timor Oriental',
        country_name_it: 'Timor Est',
        country_name_fr: 'Timor oriental',
        country_name_de: 'Timor-Leste',
        country_name_ca: 'Timor-Lest',
        country_capital: 'Dili',
        country_capital_el: 'Ντίλι',
        country_capital_es: 'Dili',
        country_capital_it: 'Dili',
        country_capital_fr: 'Dili',
        country_capital_de: 'Dili',
        country_capital_ca: 'Díli',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'TO',
        utc_offset: 13,
        timezone: 'Pacific/Tongatapu',
        timezone_el: 'Ειρηνικός/Τονγκατάπου',
        timezone_es: 'Pacífico/Tongatapu',
        timezone_it: 'Pacífico/Tongatapu',
        timezone_fr: 'Pacifique/Tongatapu',
        timezone_de: 'Pazifik/Tongatapu',
        timezone_ca: 'Pacífic/Tongatapu',
        country_name: 'Tonga',
        country_name_el: 'Τόνγκα',
        country_name_es: 'Tonga',
        country_name_it: 'Tonga',
        country_name_fr: 'Tonga',
        country_name_de: 'Tonga',
        country_name_ca: 'Tonga',
        country_capital: 'Nuku\'alofa',
        country_capital_el: 'Νούκουαλόφα',
        country_capital_es: 'Nuku\'alofa',
        country_capital_it: 'Nuku\'alofa',
        country_capital_fr: 'Nuku\'alofa',
        country_capital_de: 'Nuku\'alofa',
        country_capital_ca: 'Nuku\'alofa',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'TT',
        utc_offset: 13,
        timezone: 'America/Port_of_Spain',
        timezone_el: 'Αμερική/Πορτ οφ Σπέιν',
        timezone_es: 'América/Puerto_de_España',
        timezone_it: 'América/Puerto_de_España',
        timezone_fr: 'Amérique/Port_of_Spain',
        timezone_de: 'Amerika/Port_of_Spain',
        timezone_ca: 'Amèrica/Port_of_Spain',
        country_name: 'Trinidad and Tobago',
        country_name_el: 'Τρινιντάντ και Τομπάγκο',
        country_name_es: 'Trinidad y Tobago',
        country_name_it: 'Trinidad e Tobago',
        country_name_fr: 'Trinité-et-Tobago',
        country_name_de: 'Trinidad und Tobago',
        country_name_ca: 'Trinitat i Tobago',
        country_capital: 'Port of Spain',
        country_capital_el: 'Λιμάν της Ισπανίας',
        country_capital_es: 'Puerto España',
        country_capital_it: 'Port of Spain',
        country_capital_fr: 'Port-d\'Espagne',
        country_capital_de: 'Port of Spain',
        country_capital_ca: 'Port d\'Espanya',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'TN',
        utc_offset: 1,
        timezone: 'Africa/Tunis',
        timezone_el: 'Αφρική/Τυνίδα',
        timezone_es: 'África/Túnez',
        timezone_it: 'África/Túnez',
        timezone_fr: 'Afrique/Tunis',
        timezone_de: 'Afrika/Tunis',
        timezone_ca: 'Àfrica/Túnez',
        country_name: 'Tunisia',
        country_name_el: 'Τυνησία',
        country_name_es: 'Túnez',
        country_name_it: 'Tunisia',
        country_name_fr: 'Tunisie',
        country_name_de: 'Tunesien',
        country_name_ca: 'Tunísia',
        country_capital: 'Tunis',
        country_capital_el: 'Τύνιδα',
        country_capital_es: 'Túnez',
        country_capital_it: 'Tunisi',
        country_capital_fr: 'Tunis',
        country_capital_de: 'Tunis',
        country_capital_ca: 'Túnez',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'TR',
        utc_offset: 2,
        timezone: 'Europe/Istanbul',
        timezone_el: 'Ευρώπη/Κωνσταντινούπολη',
        timezone_es: 'Europa/Estambul',
        timezone_it: 'Europa/Estambul',
        timezone_fr: 'Europe/Istanbul',
        timezone_de: 'Europa/Istanbul',
        timezone_ca: 'Europa/Istanbul',
        country_name: 'Turkey',
        country_name_el: 'Τουρκία',
        country_name_es: 'Turquía',
        country_name_it: 'Turchia',
        country_name_fr: 'Turquie',
        country_name_de: 'Türkei',
        country_name_ca: 'Turquia',
        country_capital: 'Ankara',
        country_capital_el: 'Άγκυρα',
        country_capital_es: 'Ankara',
        country_capital_it: 'Ankara',
        country_capital_fr: 'Ankara',
        country_capital_de: 'Ankara',
        country_capital_ca: 'Ankara',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'TV',
        utc_offset: 12,
        timezone: 'Pacific/Funafuti',
        timezone_el: 'Ειρηνικός/Φουναφούτι',
        timezone_es: 'Pacífico/Funafuti',
        timezone_it: 'Pacífico/Funafuti',
        timezone_fr: 'Pacifique/Funafuti',
        timezone_de: 'Pazifik/Funafuti',
        timezone_ca: 'Pacífic/Funafuti',
        country_name: 'Tuvalu',
        country_name_el: 'Τουβαλού',
        country_name_es: 'Tuvalu',
        country_name_it: 'Tuvalu',
        country_name_fr: 'Tuvalu',
        country_name_de: 'Tuvalu',
        country_name_ca: 'Tuvalu',
        country_capital: 'Funafuti',
        country_capital_el: 'Φουναφούτι',
        country_capital_es: 'Funafuti',
        country_capital_it: 'Funafuti',
        country_capital_fr: 'Funafuti',
        country_capital_de: 'Funafuti',
        country_capital_ca: 'Funafuti',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'TW',
        utc_offset: 8,
        timezone: 'Asia/Taipei',
        timezone_el: 'Ασία/Ταϊπέι',
        timezone_es: 'Asia/Taipei',
        timezone_it: 'Asia/Taipei',
        timezone_fr: 'Asie/Taipei',
        timezone_de: 'Asien/Taipeh',
        timezone_ca: 'Àsia/Taipei',
        country_name: 'Taiwan',
        country_name_el: 'Ταϊβάν',
        country_name_es: 'Taiwán',
        country_name_it: 'Taiwan',
        country_name_fr: 'Taïwan',
        country_name_de: 'Taiwan',
        country_name_ca: 'Taiwan',
        country_capital: 'Taipei',
        country_capital_el: 'Ταϊπέι',
        country_capital_es: 'Taipéi',
        country_capital_it: 'Taipei',
        country_capital_fr: 'Taipei',
        country_capital_de: 'Taipeh',
        country_capital_ca: 'Taipéi',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'TZ',
        utc_offset: 3,
        timezone: 'Africa/Dar_es_Salaam',
        timezone_el: 'Αφρική/Νταρ ες Σαλάμ',
        timezone_es: 'África/Dar_es_Salaam',
        timezone_it: 'África/Dar_es_Salaam',
        timezone_fr: 'Afrique/Dar_es_Salaam',
        timezone_de: 'Afrika/Dar_es_Salaam',
        timezone_ca: 'Àfrica/Dar_es_Salaam',
        country_name: 'Tanzania',
        country_name_el: 'Τανζανία',
        country_name_es: 'Tanzania',
        country_name_it: 'Tanzania',
        country_name_fr: 'Tanzanie',
        country_name_de: 'Tansania',
        country_name_ca: 'Tanzània',
        country_capital: 'Dodoma',
        country_capital_el: 'Ντοντόμα',
        country_capital_es: 'Dodoma',
        country_capital_it: 'Dodoma',
        country_capital_fr: 'Dodoma',
        country_capital_de: 'Dodoma',
        country_capital_ca: 'Dodoma',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'UG',
        utc_offset: 3,
        timezone: 'Africa/Kampala',
        timezone_el: 'Αφρική/Καμπάλα',
        timezone_es: 'África/Kampala',
        timezone_it: 'África/Kampala',
        timezone_fr: 'Afrique/Kampala',
        timezone_de: 'Afrika/Kampala',
        timezone_ca: 'Àfrica/Kampala',
        country_name: 'Uganda',
        country_name_el: 'Ουγκάντα',
        country_name_es: 'Uganda',
        country_name_it: 'Uganda',
        country_name_fr: 'Ouganda',
        country_name_de: 'Uganda',
        country_name_ca: 'Uganda',
        country_capital: 'Kampala',
        country_capital_el: 'Καμπάλα',
        country_capital_es: 'Kampala',
        country_capital_it: 'Kampala',
        country_capital_fr: 'Kampala',
        country_capital_de: 'Kampala',
        country_capital_ca: 'Kampala',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'UA',
        utc_offset: 2,
        timezone: 'Europe/Kiev',
        timezone_el: 'Ευρώπη/Κίεβο',
        timezone_es: 'Europa/Kiev',
        timezone_it: 'Europa/Kiev',
        timezone_fr: 'Europe/Kiev',
        timezone_de: 'Europa/Kiew',
        timezone_ca: 'Europa/Kiev',
        country_name: 'Ukraine',
        country_name_el: 'Ουκρανία',
        country_name_es: 'Ucrania',
        country_name_it: 'Ucraina',
        country_name_fr: 'Ukraine',
        country_name_de: 'Ukraine',
        country_name_ca: 'Ucraïna',
        country_capital: 'Kiev',
        country_capital_el: 'Κίεβο',
        country_capital_es: 'Kiev',
        country_capital_it: 'Kiev',
        country_capital_fr: 'Kiev',
        country_capital_de: 'Kiew',
        country_capital_ca: 'Kiev',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'UA',
        utc_offset: 2,
        timezone: 'Europe/Uzhgorod',
        timezone_el: 'Ευρώπη/Ουζγκορόντ',
        timezone_es: 'Europa/Úzhgorod',
        timezone_it: 'Europa/Uzhgorod',
        timezone_fr: 'Europe/Oujgorod',
        timezone_de: 'Europa/Uzhgorod',
        timezone_ca: 'Europa/Uzhgorod',
        country_name: 'Ukraine',
        country_name_el: 'Ουκρανία',
        country_name_es: 'Ucrania',
        country_name_it: 'Ucraina',
        country_name_fr: 'Ukraine',
        country_name_de: 'Ukraine',
        country_name_ca: 'Ucraïna',
        country_capital: 'Kiev',
        country_capital_el: 'Κίεβο',
        country_capital_es: 'Kiev',
        country_capital_it: 'Kiev',
        country_capital_fr: 'Kiev',
        country_capital_de: 'Kiew',
        country_capital_ca: 'Kiev',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'UA',
        utc_offset: 2,
        timezone: 'Europe/Zaporozhye',
        timezone_el: 'Ευρώπη/Ζαπορίζια',
        timezone_es: 'Europa/Zaporiyia',
        timezone_it: 'Europa/Zaporozhye',
        timezone_fr: 'Europe/Zaporozhye',
        timezone_de: 'Europa/Saporischschja',
        timezone_ca: 'Europa/Zaporozhye',
        country_name: 'Ukraine',
        country_name_el: 'Ουκρανία',
        country_name_es: 'Ucrania',
        country_name_it: 'Ucraina',
        country_name_fr: 'Ukraine',
        country_name_de: 'Ukraine',
        country_name_ca: 'Ucraïna',
        country_capital: 'Kiev',
        country_capital_el: 'Κίεβο',
        country_capital_es: 'Kiev',
        country_capital_it: 'Kiev',
        country_capital_fr: 'Kiev',
        country_capital_de: 'Kiew',
        country_capital_ca: 'Kiev',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'UM',
        utc_offset: -11,
        timezone: 'Pacific/Johnston',
        timezone_el: 'Ειρηνικός/Τζόνστον',
        timezone_es: 'Pacífico/Johnston',
        timezone_it: 'Pacífico/Johnston',
        timezone_fr: 'Pacifique/Johnston',
        timezone_de: 'Pazifik/Johnston',
        timezone_ca: 'Pacífic/Johnston',
        country_name: 'United States Minor Outlying Islands',
        country_name_el: 'Μικρές Απομακρυσμένες Νησίδες των Ηνωμένων Πολιτειών',
        country_name_es: 'Islas Ultramarinas Menores de Estados Unidos',
        country_name_it: 'Isole Minori Esterne degli Stati Uniti',
        country_name_fr: 'Îles mineures éloignées des États-Unis',
        country_name_de: 'United States Minor Outlying Islands',
        country_name_ca: 'Illes Perifèriques Menors dels Estats Units',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'UM',
        utc_offset: -11,
        timezone: 'Pacific/Midway',
        timezone_el: 'Ειρηνικός/Μίντγουει',
        timezone_es: 'Pacífico/Midway',
        timezone_it: 'Pacífico/Midway',
        timezone_fr: 'Pacifique/Midway',
        timezone_de: 'Pazifik/Midway',
        timezone_ca: 'Pacífic/Midway',
        country_name: 'United States Minor Outlying Islands',
        country_name_el: 'Μικρές Απομακρυσμένες Νησίδες των Ηνωμένων Πολιτειών',
        country_name_es: 'Islas Ultramarinas Menores de Estados Unidos',
        country_name_it: 'Isole Minori Esterne degli Stati Uniti',
        country_name_fr: 'Îles mineures éloignées des États-Unis',
        country_name_de: 'United States Minor Outlying Islands',
        country_name_ca: 'Illes Perifèriques Menors dels Estats Units',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'UM',
        utc_offset: -11,
        timezone: 'Pacific/Wake',
        timezone_el: 'Ειρηνικός/Γουέικ',
        timezone_es: 'Pacífico/Wake',
        timezone_it: 'Pacífico/Wake',
        timezone_fr: 'Pacifique/Wake',
        timezone_de: 'Pazifik/Wake',
        timezone_ca: 'Pacífic/Wake',
        country_name: 'United States Minor Outlying Islands',
        country_name_el: 'Μικρές Απομακρυσμένες Νησίδες των Ηνωμένων Πολιτειών',
        country_name_es: 'Islas Ultramarinas Menores de Estados Unidos',
        country_name_it: 'Isole Minori Esterne degli Stati Uniti',
        country_name_fr: 'Îles mineures éloignées des États-Unis',
        country_name_de: 'United States Minor Outlying Islands',
        country_name_ca: 'Illes Perifèriques Menors dels Estats Units',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'UY',
        utc_offset: -3,
        timezone: 'America/Montevideo',
        timezone_el: 'Αμερική/Μοντεβιδέο',
        timezone_es: 'América/Montevideo',
        timezone_it: 'América/Montevideo',
        timezone_fr: 'Amérique/Montevideo',
        timezone_de: 'Amerika/Montevideo',
        timezone_ca: 'Amèrica/Montevideo',
        country_name: 'Uruguay',
        country_name_el: 'Ουρουγουάη',
        country_name_es: 'Uruguay',
        country_name_it: 'Uruguay',
        country_name_fr: 'Uruguay',
        country_name_de: 'Uruguay',
        country_name_ca: 'Uruguai',
        country_capital: 'Montevideo',
        country_capital_el: 'Μοντεβιδέο',
        country_capital_es: 'Montevideo',
        country_capital_it: 'Montevideo',
        country_capital_fr: 'Montevideo',
        country_capital_de: 'Montevideo',
        country_capital_ca: 'Montevideo',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'South America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/New_York',
        timezone_el: 'Αμερική/Νέα Υόρκη',
        timezone_es: 'América/Nueva_York',
        timezone_it: 'América/Nueva_York',
        timezone_fr: 'Amérique/New_York',
        timezone_de: 'Amerika/New_York',
        timezone_ca: 'Amèrica/Nova_York',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Detroit',
        timezone_el: 'Αμερική/Ντιτρόιτ',
        timezone_es: 'América/Detroit',
        timezone_it: 'América/Detroit',
        timezone_fr: 'Amérique/Détroit',
        timezone_de: 'Amerika/Detroit',
        timezone_ca: 'Amèrica/Detroit',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Kentucky/Louisville',
        timezone_el: 'Αμερική/Λούιβιλ',
        timezone_es: 'América/Louisville (Kentucky)',
        timezone_it: 'América/Luisville_Kentucky',
        timezone_fr: 'Amérique/Kentucky/Louisville',
        timezone_de: 'Amerika/Louisville',
        timezone_ca: 'Amèrica/Kentucky/Louisville',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Kentucky/Monticello',
        timezone_el: 'Αμερική/Μοντιτσέλο',
        timezone_es: 'América/Monticello (Kentucky)',
        timezone_it: 'América/Monticello_Kentucky',
        timezone_fr: 'Amérique/Kentucky/Monticello',
        timezone_de: 'Amerika/Monticello',
        timezone_ca: 'Amèrica/Kentucky/Monticello',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Indiana/Indianapolis',
        timezone_el: 'Αμερική/Ιντιανάπολις',
        timezone_es: 'América/Indianápolis (Indiana)',
        timezone_it: 'América/Indianápolis_Indiana',
        timezone_fr: 'Amérique/Indiana/Indianapolis',
        timezone_de: 'Amerika/Indianapolis',
        timezone_ca: 'Amèrica/Indiana/Indianapolis',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Indiana/Vincennes',
        timezone_el: 'Αμερική/Ιντιανά/Βίνσενς',
        timezone_es: 'América/Vincennes (Indiana)',
        timezone_it: 'América/Vincennes_Indiana',
        timezone_fr: 'Amérique/Indiana/Vincennes',
        timezone_de: 'Amerika/Vincennes',
        timezone_ca: 'Amèrica/Indiana/Vincennes',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Indiana/Winamac',
        timezone_el: 'Αμερική/Ιντιανά/Γουίναμακ',
        timezone_es: 'América/Winamac (Indiana)',
        timezone_it: 'América/Winamac_Indiana',
        timezone_fr: 'Amérique/Indiana/Winamac',
        timezone_de: 'Amerika/Winamac',
        timezone_ca: 'Amèrica/Indiana/Winamac',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Indiana/Marengo',
        timezone_el: 'Αμερική/Ιντιανά/Μαρέγκο',
        timezone_es: 'América/Marengo (Indiana)',
        timezone_it: 'América/Marengo_Indiana',
        timezone_fr: 'Amérique/Indiana/Marengo',
        timezone_de: 'Amerika/Marengo',
        timezone_ca: 'Amèrica/Indiana/Marengo',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Indiana/Petersburg',
        timezone_el: 'Αμερική/Ιντιανά/Πίτερσμπεργκ',
        timezone_es: 'América/Petersburg (Indiana)',
        timezone_it: 'América/Petersburg_Indiana',
        timezone_fr: 'Amérique/Indiana/Petersburg',
        timezone_de: 'Amerika/Petersburg',
        timezone_ca: 'Amèrica/Indiana/Petersburg',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Indiana/Vevay',
        timezone_el: 'Αμερική/Ιντιανά/Βέβει',
        timezone_es: 'América/Vevay (Indiana)',
        timezone_it: 'América/Vevay_Indiana',
        timezone_fr: 'Amérique/Indiana/Vevay',
        timezone_de: 'Amerika/Vevay',
        timezone_ca: 'Amèrica/Indiana/Vevay',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Chicago',
        timezone_el: 'Αμερική/Σικάγο',
        timezone_es: 'América/Chicago',
        timezone_it: 'América/Chicago',
        timezone_fr: 'Amérique/Chicago',
        timezone_de: 'Amerika/Chicago',
        timezone_ca: 'Amèrica/Chicago',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Indiana/Tell_City',
        timezone_el: 'Αμερική/Ιντιανά/Τέλ Σίτι',
        timezone_es: 'América/Indiana/Tell_City',
        timezone_it: 'América/Tell_City_Indiana',
        timezone_fr: 'Amérique/Indiana/Tell_City',
        timezone_de: 'Amerika/Tell_City',
        timezone_ca: 'Amèrica/Indiana/Tell_City',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Indiana/Knox',
        timezone_el: 'Αμερική/Ιντιανά/Νοξ',
        timezone_es: 'América/Indiana/Knox',
        timezone_it: 'América/Knox_Indiana',
        timezone_fr: 'Amérique/Indiana/Knox',
        timezone_de: 'Amerika/Knox',
        timezone_ca: 'Amèrica/Indiana/Knox',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Menominee',
        timezone_el: 'Αμερική/Μενομίνι',
        timezone_es: 'América/Menominee',
        timezone_it: 'América/Menominee',
        timezone_fr: 'Amérique/Menominee',
        timezone_de: 'Amerika/Menominee',
        timezone_ca: 'Amèrica/Menominee',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/North_Dakota/Center',
        timezone_el: 'Αμερική/Βόρεια Ντακότα/Σέντερ',
        timezone_es: 'América/North_Dakota/Center',
        timezone_it: 'América/North_Dakota/Centro',
        timezone_fr: 'Amérique/Dakota_du_Nord/Center',
        timezone_de: 'Amerika/Nord_Dakota/Center',
        timezone_ca: 'Amèrica/North_Dakota/Center',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/North_Dakota/New_Salem',
        timezone_el: 'Αμερική/Βόρεια Ντακότα/Νιου Σέιλεμ',
        timezone_es: 'América/North_Dakota/New_Salem',
        timezone_it: 'América/North_Dakota/Nuevo_Salem',
        timezone_fr: 'Amérique/Dakota_du_Nord/New_Salem',
        timezone_de: 'Amerika/Nord_Dakota/New_Salem',
        timezone_ca: 'Amèrica/North_Dakota/New_Salem',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/North_Dakota/Beulah',
        timezone_el: 'Αμερική/Βόρεια Ντακότα/Μπιούλα',
        timezone_es: 'América/North_Dakota/Beulah',
        timezone_it: 'América/North_Dakota/Beulah',
        timezone_fr: 'Amérique/Dakota_du_Nord/Beulah',
        timezone_de: 'Amerika/Nord_Dakota/Beulah',
        timezone_ca: 'Amèrica/North_Dakota/Beulah',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Denver',
        timezone_el: 'Αμερική/Ντένβερ',
        timezone_es: 'América/Denver',
        timezone_it: 'América/Denver',
        timezone_fr: 'Amérique/Denver',
        timezone_de: 'Amerika/Denver',
        timezone_ca: 'Amèrica/Denver',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Boise',
        timezone_el: 'Αμερική/Μπόισε',
        timezone_es: 'América/Boise',
        timezone_it: 'América/Boise',
        timezone_fr: 'Amérique/Boise',
        timezone_de: 'Amerika/Boise',
        timezone_ca: 'Amèrica/Boise',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Phoenix',
        timezone_el: 'Αμερική/Φοίνιξ',
        timezone_es: 'América/Phoenix',
        timezone_it: 'América/Fénix',
        timezone_fr: 'Amérique/Phoenix',
        timezone_de: 'Amerika/Phoenix',
        timezone_ca: 'Amèrica/Phoenix',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Los_Angeles',
        timezone_el: 'Αμερική/Λος Άντζελες',
        timezone_es: 'América/Los_Ángeles',
        timezone_it: 'América/Los_Ángeles',
        timezone_fr: 'Amérique/Los_Angeles',
        timezone_de: 'Amerika/Los_Angeles',
        timezone_ca: 'Amèrica/Los_Angeles',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Anchorage',
        timezone_el: 'Αμερική/Αγκόρντζ',
        timezone_es: 'América/Anchorage',
        timezone_it: 'América/Anchorage',
        timezone_fr: 'Amérique/Anchorage',
        timezone_de: 'Amerika/Anchorage',
        timezone_ca: 'Amèrica/Anchorage',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Juneau',
        timezone_el: 'Αμερική/Τζούνο',
        timezone_es: 'América/Juneau',
        timezone_it: 'América/Juneau',
        timezone_fr: 'Amérique/Juneau',
        timezone_de: 'Amerika/Juneau',
        timezone_ca: 'Amèrica/Juneau',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Sitka',
        timezone_el: 'Αμερική/Σίτκα',
        timezone_es: 'América/Sitka',
        timezone_it: 'América/Sitka',
        timezone_fr: 'Amérique/Sitka',
        timezone_de: 'Amerika/Sitka',
        timezone_ca: 'Amèrica/Sitka',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Metlakatla',
        timezone_el: 'Αμερική/Μετλακάτλα',
        timezone_es: 'América/Metlakatla',
        timezone_it: 'América/Metlakatla',
        timezone_fr: 'Amérique/Metlakatla',
        timezone_de: 'Amerika/Metlakatla',
        timezone_ca: 'Amèrica/Metlakatla',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Yakutat',
        timezone_el: 'Αμερική/Γιακουτάτ',
        timezone_es: 'América/Yakutat',
        timezone_it: 'América/Yakutat',
        timezone_fr: 'Amérique/Yakutat',
        timezone_de: 'Amerika/Yakutat',
        timezone_ca: 'Amèrica/Yakutat',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Nome',
        timezone_el: 'Αμερική/Νομ',
        timezone_es: 'América/Nome',
        timezone_it: 'América/Nome',
        timezone_fr: 'Amérique/Nome',
        timezone_de: 'Amerika/Nome',
        timezone_ca: 'Amèrica/Nome',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'America/Adak',
        timezone_el: 'Αμερική/Άντακ',
        timezone_es: 'América/Adak',
        timezone_it: 'América/Adak',
        timezone_fr: 'Amérique/Adak',
        timezone_de: 'Amerika/Adak',
        timezone_ca: 'Amèrica/Adak',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Washington D.C.',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'US',
        utc_offset: -6,
        timezone: 'Pacific/Honolulu',
        timezone_el: 'Ειρηνικός/Χονολουλού',
        timezone_es: 'Pacífico/Honolulu',
        timezone_it: 'Pacífico/Honolulu',
        timezone_fr: 'Pacifique/Honolulu',
        timezone_de: 'Pazifik/Honolulu',
        timezone_ca: 'Pacífic/Honolulu',
        country_name: 'United States',
        country_name_el: 'Ηνωμένες Πολιτείες',
        country_name_es: 'Estados Unidos',
        country_name_it: 'Stati Uniti',
        country_name_fr: 'États-Unis',
        country_name_de: 'Vereinigte Staaten',
        country_name_ca: 'Estats Units',
        country_capital: 'Washington D.C.',
        country_capital_el: 'Ουάσινγκτον Ντ.Κ.',
        country_capital_es: 'Washington D.C.',
        country_capital_it: 'Washington D.C.',
        country_capital_fr: 'Washington D.C.',
        country_capital_de: 'Washington D.C.',
        country_capital_ca: 'Tashkent',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'UZ',
        utc_offset: 5,
        timezone: 'Asia/Samarkand',
        timezone_el: 'Ασία/Σαμαρκάντ',
        timezone_es: 'Asia/Samarcanda',
        timezone_it: 'Asia/Samarcanda',
        timezone_fr: 'Asie/Samarkand',
        timezone_de: 'Asien/Samarkand',
        timezone_ca: 'Àsia/Samarcanda',
        country_name: 'Uzbekistan',
        country_name_el: 'Ουζμπεκιστάν',
        country_name_es: 'Uzbekistán',
        country_name_it: 'Uzbekistan',
        country_name_fr: 'Ouzbékistan',
        country_name_de: 'Usbekistan',
        country_name_ca: 'Uzbekistan',
        country_capital: 'Tashkent',
        country_capital_el: 'Τασκένδη',
        country_capital_es: 'Tashkent',
        country_capital_it: 'Tashkent',
        country_capital_fr: 'Tachkent',
        country_capital_de: 'Taschkent',
        country_capital_ca: 'Tashkent',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'UZ',
        utc_offset: 5,
        timezone: 'Asia/Tashkent',
        timezone_el: 'Ασία/Τασκένδη',
        timezone_es: 'Asia/Tashkent',
        timezone_it: 'Asia/Tashkent',
        timezone_fr: 'Asie/Tachkent',
        timezone_de: 'Asien/Taschkent',
        timezone_ca: 'Àsia/Tashkent',
        country_name: 'Uzbekistan',
        country_name_el: 'Ουζμπεκιστάν',
        country_name_es: 'Uzbekistán',
        country_name_it: 'Uzbekistan',
        country_name_fr: 'Ouzbékistan',
        country_name_de: 'Usbekistan',
        country_name_ca: 'Uzbekistan',
        country_capital: 'Tashkent',
        country_capital_el: 'Τασκένδη',
        country_capital_es: 'Tashkent',
        country_capital_it: 'Tashkent',
        country_capital_fr: 'Tachkent',
        country_capital_de: 'Taschkent',
        country_capital_ca: 'Ciutat del Vaticà',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'VA',
        utc_offset: 1,
        timezone: 'Europe/Vatican',
        timezone_el: 'Ευρώπη/Βατικανό',
        timezone_es: 'Europa/Ciudad_del_Vaticano',
        timezone_it: 'Europa/Ciudad_del_Vaticano',
        timezone_fr: 'Europe/Vatican',
        timezone_de: 'Europa/Vatikan',
        timezone_ca: 'Europa/Vaticà',
        country_name: 'Vatican City',
        country_name_el: 'Πόλη του Βατικανού',
        country_name_es: 'Ciudad del Vaticano',
        country_name_it: 'Città del Vaticano',
        country_name_fr: 'Cité du Vatican',
        country_name_de: 'Vatikanstadt',
        country_name_ca: 'Ciutat del Vaticà',
        country_capital: 'Vatican City',
        country_capital_el: 'Πόλη του Βατικανού',
        country_capital_es: 'Ciudad del Vaticano',
        country_capital_it: 'Città del Vaticano',
        country_capital_fr: 'Cité du Vatican',
        country_capital_de: 'Vatikanstadt',
        country_capital_ca: 'Kingstown',
        country_continent: 'Europe',
        country_continent_el: 'Ευρώπη',
        country_continent_es: 'Europa',
        country_continent_it: 'Europe',
        country_continent_fr: 'Europe',
        country_continent_de: 'Europa',
        country_continent_ca: 'Europa'
    },
    {
        country_code: 'VC',
        utc_offset: -4,
        timezone: 'America/St_Vincent',
        timezone_el: 'Αμερική/Άγιος Βικέντιος',
        timezone_es: 'América/San_Vicente',
        timezone_it: 'América/San_Vicente',
        timezone_fr: 'Amérique/Saint-Vincent',
        timezone_de: 'Amerika/St._Vincent',
        timezone_ca: 'Amèrica/San_Vicent',
        country_name: 'Saint Vincent and the Grenadines',
        country_name_el: 'Άγιος Βικέντιος και Γρεναδίνες',
        country_name_es: 'San Vicente y las Granadinas',
        country_name_it: 'Saint Vincent e Grenadine',
        country_name_fr: 'Saint-Vincent-et-les Grenadines',
        country_name_de: 'St. Vincent und die Grenadinen',
        country_name_ca: 'Sant Vicent i les Grenadines',
        country_capital: 'Kingstown',
        country_capital_el: 'Κίνγκσταουν',
        country_capital_es: 'Kingstown',
        country_capital_it: 'Kingstown',
        country_capital_fr: 'Kingstown',
        country_capital_de: 'Kingstown',
        country_capital_ca: 'Caracas',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'VE',
        utc_offset: '-4.3',
        timezone: 'America/Caracas',
        timezone_el: 'Αμερική/Καράκας',
        timezone_es: 'América/Caracas',
        timezone_it: 'América/Caracas',
        timezone_fr: 'Amérique/Caracas',
        timezone_de: 'Amerika/Caracas',
        timezone_ca: 'Amèrica/Caracas',
        country_name: 'Venezuela',
        country_name_el: 'Βενεζουέλα',
        country_name_es: 'Venezuela',
        country_name_it: 'Venezuela',
        country_name_fr: 'Venezuela',
        country_name_de: 'Venezuela',
        country_name_ca: 'Veneçuela',
        country_capital: 'Caracas',
        country_capital_el: 'Καράκας',
        country_capital_es: 'Caracas',
        country_capital_it: 'Caracas',
        country_capital_fr: 'Caracas',
        country_capital_de: 'Caracas',
        country_capital_ca: 'Road Town',
        country_continent: 'South America',
        country_continent_el: 'Νότια Αμερική',
        country_continent_es: 'América del Sur',
        country_continent_it: 'South America',
        country_continent_fr: 'Amérique du Sud',
        country_continent_de: 'Südamerika',
        country_continent_ca: 'Amèrica del Sud'
    },
    {
        country_code: 'VG',
        utc_offset: -4,
        timezone: 'America/Tortola',
        timezone_el: 'Αμερική/Τορτόλα',
        timezone_es: 'América/Tórtola',
        timezone_it: 'América/Tórtola',
        timezone_fr: 'Amérique/Tortola',
        timezone_de: 'Amerika/Tortola',
        timezone_ca: 'Amèrica/Tortola',
        country_name: 'British Virgin Islands',
        country_name_el: 'Βρετανικές Παρθένοι Νήσοι',
        country_name_es: 'Islas Vírgenes Británicas',
        country_name_it: 'Isole Vergini britanniche',
        country_name_fr: 'Îles Vierges britanniques',
        country_name_de: 'Britische Jungferninseln',
        country_name_ca: 'Illes Verges Britàniques',
        country_capital: 'Road Town',
        country_capital_el: 'Ρόουντ Τάουν',
        country_capital_es: 'Road Town',
        country_capital_it: 'Road Town',
        country_capital_fr: 'Road Town',
        country_capital_de: 'Road Town',
        country_capital_ca: 'Charlotte Amalie',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'VI',
        utc_offset: -4,
        timezone: 'America/St_Thomas',
        timezone_el: 'Αμερική/Άγιος Θωμάς',
        timezone_es: 'América/San_Tomás',
        timezone_it: 'América/San_Tomás',
        timezone_fr: 'Amérique/Saint-Thomas',
        timezone_de: 'Amerika/St._Thomas',
        timezone_ca: 'Amèrica/St_Thomas',
        country_name: 'United States Virgin Islands',
        country_name_el: 'Αμερικανικές Παρθένοι Νήσοι',
        country_name_es: 'Islas Vírgenes de los Estados Unidos',
        country_name_it: 'Isole Vergini americane',
        country_name_fr: 'Îles Vierges des États-Unis',
        country_name_de: 'Amerikanische Jungferninseln',
        country_name_ca: 'Illes Verges Nord-americanes',
        country_capital: 'Charlotte Amalie',
        country_capital_el: 'Σαρλότ Αμαλίε',
        country_capital_es: 'Charlotte Amalie',
        country_capital_it: 'Charlotte Amalie',
        country_capital_fr: 'Charlotte Amalie',
        country_capital_de: 'Charlotte Amalie',
        country_capital_ca: 'Hanòi',
        country_continent: 'North America',
        country_continent_el: 'Βόρεια Αμερική',
        country_continent_es: 'América del Norte',
        country_continent_it: 'North America',
        country_continent_fr: 'Amérique du Nord',
        country_continent_de: 'Nordamerika',
        country_continent_ca: 'Amèrica del Nord'
    },
    {
        country_code: 'VN',
        utc_offset: 7,
        timezone: 'Asia/Ho_Chi_Minh',
        timezone_el: 'Ασία/Χο Τσι Μινχ',
        timezone_es: 'Asia/Ho_Chi_Minh',
        timezone_it: 'Asia/Ho_Chi_Minh',
        timezone_fr: 'Asie/Hô_Chi_Minh',
        timezone_de: 'Asien/Ho_Chi_Minh',
        timezone_ca: 'Àsia/Ho_Xi_Min',
        country_name: 'Vietnam',
        country_name_el: 'Βιετνάμ',
        country_name_es: 'Vietnam',
        country_name_it: 'Vietnam',
        country_name_fr: 'Viêt Nam',
        country_name_de: 'Vietnam',
        country_name_ca: 'Vietnam',
        country_capital: 'Hanoi',
        country_capital_el: 'Χανόι',
        country_capital_es: 'Hanoi',
        country_capital_it: 'Hanoi',
        country_capital_fr: 'Hanoï',
        country_capital_de: 'Hanoi',
        country_capital_ca: 'Port Vila',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'VU',
        utc_offset: 11,
        timezone: 'Pacific/Efate',
        timezone_el: 'Ειρηνικός/Έφατε',
        timezone_es: 'Pacífico/Efate',
        timezone_it: 'Pacífico/Efate',
        timezone_fr: 'Pacifique/Éfaté',
        timezone_de: 'Pazifik/Éfaté',
        timezone_ca: 'Pacífic/Efate',
        country_name: 'Vanuatu',
        country_name_el: 'Βανουάτου',
        country_name_es: 'Vanuatu',
        country_name_it: 'Vanuatu',
        country_name_fr: 'Vanuatu',
        country_name_de: 'Vanuatu',
        country_name_ca: 'Vanuatu',
        country_capital: 'Port Vila',
        country_capital_el: 'Πορτ Βίλα',
        country_capital_es: 'Puerto Vila',
        country_capital_it: 'Port Vila',
        country_capital_fr: 'Port-Vila',
        country_capital_de: 'Port Vila',
        country_capital_ca: 'Mata-Utu',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'WF',
        utc_offset: 12,
        timezone: 'Pacific/Wallis',
        timezone_el: 'Ειρηνικός/Γουάλις',
        timezone_es: 'Pacífico/Wallis',
        timezone_it: 'Pacífico/Wallis',
        timezone_fr: 'Pacifique/Wallis',
        timezone_de: 'Pazifik/Wallis',
        timezone_ca: 'Pacífic/Wallis',
        country_name: 'Wallis and Futuna',
        country_name_el: 'Γουάλις και Φουτούνα',
        country_name_es: 'Wallis y Futuna',
        country_name_it: 'Wallis e Futuna',
        country_name_fr: 'Wallis-et-Futuna',
        country_name_de: 'Wallis und Futuna',
        country_name_ca: 'Wallis i Futuna',
        country_capital: 'Mata-Utu',
        country_capital_el: 'Ματα-Ούτου',
        country_capital_es: 'Mata-Utu',
        country_capital_it: 'Mata-Utu',
        country_capital_fr: 'Mata-Utu',
        country_capital_de: 'Mata-Utu',
        country_capital_ca: 'Apia',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'WS',
        utc_offset: 13,
        timezone: 'Pacific/Apia',
        timezone_el: 'Ειρηνικός/Άπια',
        timezone_es: 'Pacífico/Apia',
        timezone_it: 'Pacífico/Apia',
        timezone_fr: 'Pacifique/Apia',
        timezone_de: 'Pazifik/Apia',
        timezone_ca: 'Pacífic/Apia',
        country_name: 'Samoa',
        country_name_el: 'Σαμόα',
        country_name_es: 'Samoa',
        country_name_it: 'Samoa',
        country_name_fr: 'Samoa',
        country_name_de: 'Samoa',
        country_name_ca: 'Samoa',
        country_capital: 'Apia',
        country_capital_el: 'Απία',
        country_capital_es: 'Apia',
        country_capital_it: 'Apia',
        country_capital_fr: 'Apia',
        country_capital_de: 'Apia',
        country_capital_ca: 'Sanaa',
        country_continent: 'Oceania',
        country_continent_el: 'Ωκεανία',
        country_continent_es: 'Oceanía',
        country_continent_it: 'Oceania',
        country_continent_fr: 'Océanie',
        country_continent_de: 'Ozeanien',
        country_continent_ca: 'Oceania'
    },
    {
        country_code: 'YE',
        utc_offset: 3,
        timezone: 'Asia/Aden',
        timezone_el: 'Ασία/Άντεν',
        timezone_es: 'Asia/Adén',
        timezone_it: 'Asia/Adén',
        timezone_fr: 'Asie/Aden',
        timezone_de: 'Asien/Aden',
        timezone_ca: 'Àsia/Aden',
        country_name: 'Yemen',
        country_name_el: 'Υεμένη',
        country_name_es: 'Yemen',
        country_name_it: 'Yemen',
        country_name_fr: 'Yémen',
        country_name_de: 'Jemen',
        country_name_ca: 'Iemen',
        country_capital: 'Sana\'a',
        country_capital_el: 'Σαναά',
        country_capital_es: 'Saná',
        country_capital_it: 'Sana\'a',
        country_capital_fr: 'Sanaa',
        country_capital_de: 'Sanaa',
        country_capital_ca: 'Pretòria',
        country_continent: 'Asia',
        country_continent_el: 'Ασία',
        country_continent_es: 'Asia',
        country_continent_it: 'Asia',
        country_continent_fr: 'Asie',
        country_continent_de: 'Asien',
        country_continent_ca: 'Àsia'
    },
    {
        country_code: 'ZA',
        utc_offset: 2,
        timezone: 'Africa/Johannesburg',
        timezone_el: 'Αφρική/Τζοχάνεσμπουργκ',
        timezone_es: 'África/Johannesburgo',
        timezone_it: 'África/Johannesburgo',
        timezone_fr: 'Afrique/Johannesbourg',
        timezone_de: 'Afrika/Johannesburg',
        timezone_ca: 'Àfrica/Joanesburg',
        country_name: 'South Africa',
        country_name_el: 'Νότια Αφρική',
        country_name_es: 'Sudáfrica',
        country_name_it: 'Sudafrica',
        country_name_fr: 'Afrique du Sud',
        country_name_de: 'Südafrika',
        country_name_ca: 'Sud-àfrica',
        country_capital: 'Pretoria',
        country_capital_el: 'Πραιτόρια',
        country_capital_es: 'Pretoria',
        country_capital_it: 'Pretoria',
        country_capital_fr: 'Pretoria',
        country_capital_de: 'Pretoria',
        country_capital_ca: 'Lusaka',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'ZM',
        utc_offset: 1,
        timezone: 'Africa/Lusaka',
        timezone_el: 'Αφρική/Λούσακα',
        timezone_es: 'África/Lusaka',
        timezone_it: 'África/Lusaka',
        timezone_fr: 'Afrique/Lusaka',
        timezone_de: 'Afrika/Lusaka',
        timezone_ca: 'Àfrica/Lusaka',
        country_name: 'Zambia',
        country_name_el: 'Ζάμπια',
        country_name_es: 'Zambia',
        country_name_it: 'Zambia',
        country_name_fr: 'Zambie',
        country_name_de: 'Sambia',
        country_name_ca: 'Zàmbia',
        country_capital: 'Lusaka',
        country_capital_el: 'Λουζάκα',
        country_capital_es: 'Lusaka',
        country_capital_it: 'Lusaka',
        country_capital_fr: 'Lusaka',
        country_capital_de: 'Lusaka',
        country_capital_ca: 'Harare',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    },
    {
        country_code: 'ZW',
        utc_offset: 2,
        timezone: 'Africa/Harare',
        timezone_el: 'Αφρική/Χαράρε',
        timezone_es: 'África/Harare',
        timezone_it: 'África/Harare',
        timezone_fr: 'Afrique/Harare',
        timezone_de: 'Afrika/Harare',
        timezone_ca: 'Àfrica/Harare',
        country_name: 'Zimbabwe',
        country_name_el: 'Ζιμπάμπουε',
        country_name_es: 'Zimbabue',
        country_name_it: 'Zimbabwe',
        country_name_fr: 'Zimbabwe',
        country_name_de: 'Simbabwe',
        country_name_ca: 'Zimbàbue',
        country_capital: 'Harare',
        country_capital_el: 'Χαράρε',
        country_capital_es: 'Harare',
        country_capital_it: 'Harare',
        country_capital_fr: 'Harare',
        country_capital_de: 'Harare',
        country_continent: 'Africa',
        country_continent_el: 'Αφρική',
        country_continent_es: 'África',
        country_continent_it: 'Africa',
        country_continent_fr: 'Afrique',
        country_continent_de: 'Afrika',
        country_continent_ca: 'Àfrica'
    }
];


export const timezonesByCountry = [
    {
        country_code: 'AW',
        timezones: [
            {
                timezone: 'America/Aruba',
                country_name: 'Aruba',
                country_code: 'AW',
                country_capital: 'Oranjestad',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AF',
        timezones: [
            {
                timezone: 'Asia/Kabul',
                country_name: 'Afghanistan',
                country_code: 'AF',
                country_capital: 'Kabul',
                utc_offset: 4.3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AO',
        timezones: [
            {
                timezone: 'Africa/Luanda',
                country_name: 'Angola',
                country_code: 'AO',
                country_capital: 'Luanda',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AI',
        timezones: [
            {
                timezone: 'America/Anguilla',
                country_name: 'Anguilla',
                country_code: 'AI',
                country_capital: 'The Valley',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AX',
        timezones: [
            {
                timezone: 'Europe/Mariehamn',
                country_name: 'Åland Islands',
                country_code: 'AX',
                country_capital: 'Mariehamn',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AL',
        timezones: [
            {
                timezone: 'Europe/Tirane',
                country_name: 'Albania',
                country_code: 'AL',
                country_capital: 'Tirana',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AD',
        timezones: [
            {
                timezone: 'Europe/Andorra',
                country_name: 'Andorra',
                country_code: 'AD',
                country_capital: 'Andorra la Vella',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AE',
        timezones: [
            {
                timezone: 'Asia/Dubai',
                country_name: 'United Arab Emirates',
                country_code: 'AE',
                country_capital: 'Abu Dhabi',
                utc_offset: 4,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AR',
        timezones: [
            {
                timezone: 'America/Argentina/Buenos_Aires',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/Cordoba',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/Salta',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/Jujuy',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/Tucuman',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/Catamarca',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/La_Rioja',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/San_Juan',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/Mendoza',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/San_Luis',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/Rio_Gallegos',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Argentina/Ushuaia',
                country_name: 'Argentina',
                country_code: 'AR',
                country_capital: 'Buenos Aires',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AM',
        timezones: [
            {
                timezone: 'Asia/Yerevan',
                country_name: 'Armenia',
                country_code: 'AM',
                country_capital: 'Yerevan',
                utc_offset: 4,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AS',
        timezones: [
            {
                timezone: 'Pacific/Pago_Pago',
                country_name: 'American Samoa',
                country_code: 'AS',
                country_capital: 'Pago Pago',
                utc_offset: -11,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AQ',
        timezones: [
            {
                timezone: 'Antarctica/McMurdo',
                country_name: 'Antarctica',
                country_code: 'AQ',
                country_capital: null,
                utc_offset: 13,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Antarctica/Casey',
                country_name: 'Antarctica',
                country_code: 'AQ',
                country_capital: null,
                utc_offset: 13,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Antarctica/Davis',
                country_name: 'Antarctica',
                country_code: 'AQ',
                country_capital: null,
                utc_offset: 13,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Antarctica/DumontDUrville',
                country_name: 'Antarctica',
                country_code: 'AQ',
                country_capital: null,
                utc_offset: 13,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Antarctica/Mawson',
                country_name: 'Antarctica',
                country_code: 'AQ',
                country_capital: null,
                utc_offset: 13,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Antarctica/Palmer',
                country_name: 'Antarctica',
                country_code: 'AQ',
                country_capital: null,
                utc_offset: 13,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Antarctica/Rothera',
                country_name: 'Antarctica',
                country_code: 'AQ',
                country_capital: null,
                utc_offset: 13,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Antarctica/Syowa',
                country_name: 'Antarctica',
                country_code: 'AQ',
                country_capital: null,
                utc_offset: 13,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Antarctica/Troll',
                country_name: 'Antarctica',
                country_code: 'AQ',
                country_capital: null,
                utc_offset: 13,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Antarctica/Vostok',
                country_name: 'Antarctica',
                country_code: 'AQ',
                country_capital: null,
                utc_offset: 13,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TF',
        timezones: [
            {
                timezone: 'Indian/Kerguelen',
                country_name: 'French Southern and Antarctic Lands',
                country_code: 'TF',
                country_capital: 'Port-aux-Français',
                utc_offset: 5,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AG',
        timezones: [
            {
                timezone: 'America/Antigua',
                country_name: 'Antigua and Barbuda',
                country_code: 'AG',
                country_capital: 'Saint John\'s',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AU',
        timezones: [
            {
                timezone: 'Australia/Lord_Howe',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Antarctica/Macquarie',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Hobart',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Currie',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Melbourne',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Sydney',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Broken_Hill',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Brisbane',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Lindeman',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Adelaide',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Darwin',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Perth',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Australia/Eucla',
                country_name: 'Australia',
                country_code: 'AU',
                country_capital: 'Canberra',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AT',
        timezones: [
            {
                timezone: 'Europe/Vienna',
                country_name: 'Austria',
                country_code: 'AT',
                country_capital: 'Vienna',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'AZ',
        timezones: [
            {
                timezone: 'Asia/Baku',
                country_name: 'Azerbaijan',
                country_code: 'AZ',
                country_capital: 'Baku',
                utc_offset: 4,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BI',
        timezones: [
            {
                timezone: 'Africa/Bujumbura',
                country_name: 'Burundi',
                country_code: 'BI',
                country_capital: 'Bujumbura',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BE',
        timezones: [
            {
                timezone: 'Europe/Brussels',
                country_name: 'Belgium',
                country_code: 'BE',
                country_capital: 'Brussels',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BJ',
        timezones: [
            {
                timezone: 'Africa/Porto-Novo',
                country_name: 'Benin',
                country_code: 'BJ',
                country_capital: 'Porto-Novo',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BF',
        timezones: [
            {
                timezone: 'Africa/Ouagadougou',
                country_name: 'Burkina Faso',
                country_code: 'BF',
                country_capital: 'Ouagadougou',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BD',
        timezones: [
            {
                timezone: 'Asia/Dhaka',
                country_name: 'Bangladesh',
                country_code: 'BD',
                country_capital: 'Dhaka',
                utc_offset: 6,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BG',
        timezones: [
            {
                timezone: 'Europe/Sofia',
                country_name: 'Bulgaria',
                country_code: 'BG',
                country_capital: 'Sofia',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BH',
        timezones: [
            {
                timezone: 'Asia/Bahrain',
                country_name: 'Bahrain',
                country_code: 'BH',
                country_capital: 'Manama',
                utc_offset: 3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BS',
        timezones: [
            {
                timezone: 'America/Nassau',
                country_name: 'Bahamas',
                country_code: 'BS',
                country_capital: 'Nassau',
                utc_offset: -5,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BA',
        timezones: [
            {
                timezone: 'Europe/Sarajevo',
                country_name: 'Bosnia and Herzegovina',
                country_code: 'BA',
                country_capital: 'Sarajevo',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BL',
        timezones: [
            {
                timezone: 'America/St_Barthelemy',
                country_name: 'Saint Barthélemy',
                country_code: 'BL',
                country_capital: 'Gustavia',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BY',
        timezones: [
            {
                timezone: 'Europe/Minsk',
                country_name: 'Belarus',
                country_code: 'BY',
                country_capital: 'Minsk',
                utc_offset: 3,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BZ',
        timezones: [
            {
                timezone: 'America/Belize',
                country_name: 'Belize',
                country_code: 'BZ',
                country_capital: 'Belmopan',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BM',
        timezones: [
            {
                timezone: 'Atlantic/Bermuda',
                country_name: 'Bermuda',
                country_code: 'BM',
                country_capital: 'Hamilton',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BO',
        timezones: [
            {
                timezone: 'America/La_Paz',
                country_name: 'Bolivia',
                country_code: 'BO',
                country_capital: 'Sucre',
                utc_offset: -4,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BR',
        timezones: [
            {
                timezone: 'America/Noronha',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Belem',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Fortaleza',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Recife',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Araguaina',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Maceio',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Bahia',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Sao_Paulo',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Campo_Grande',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Cuiaba',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Santarem',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Porto_Velho',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Boa_Vista',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Manaus',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Eirunepe',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Rio_Branco',
                country_name: 'Brazil',
                country_code: 'BR',
                country_capital: 'Brasília',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BB',
        timezones: [
            {
                timezone: 'America/Barbados',
                country_name: 'Barbados',
                country_code: 'BB',
                country_capital: 'Bridgetown',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BN',
        timezones: [
            {
                timezone: 'Asia/Brunei',
                country_name: 'Brunei',
                country_code: 'BN',
                country_capital: 'Bandar Seri Begawan',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BT',
        timezones: [
            {
                timezone: 'Asia/Thimphu',
                country_name: 'Bhutan',
                country_code: 'BT',
                country_capital: 'Thimphu',
                utc_offset: 6,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BV',
        timezones: [
            {
                timezone: 'Europe/Oslo',
                country_name: 'Bouvet Island',
                country_code: 'BV',
                country_capital: null,
                utc_offset: 1,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'BW',
        timezones: [
            {
                timezone: 'Africa/Gaborone',
                country_name: 'Botswana',
                country_code: 'BW',
                country_capital: 'Gaborone',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CF',
        timezones: [
            {
                timezone: 'Africa/Bangui',
                country_name: 'Central African Republic',
                country_code: 'CF',
                country_capital: 'Bangui',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CA',
        timezones: [
            {
                timezone: 'America/St_Johns',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Halifax',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Glace_Bay',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Moncton',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Goose_Bay',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Blanc-Sablon',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Toronto',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Nipigon',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Thunder_Bay',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Iqaluit',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Pangnirtung',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Atikokan',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Winnipeg',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Rainy_River',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Resolute',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Rankin_Inlet',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Regina',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Swift_Current',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Edmonton',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Cambridge_Bay',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Yellowknife',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Inuvik',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Creston',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Dawson_Creek',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Fort_Nelson',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Vancouver',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Whitehorse',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Dawson',
                country_name: 'Canada',
                country_code: 'CA',
                country_capital: 'Ottawa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CC',
        timezones: [
            {
                timezone: 'Indian/Cocos',
                country_name: 'Cocos (Keeling) Islands',
                country_code: 'CC',
                country_capital: 'West Island',
                utc_offset: 6.3,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CH',
        timezones: [
            {
                timezone: 'Europe/Zurich',
                country_name: 'Switzerland',
                country_code: 'CH',
                country_capital: 'Bern',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CL',
        timezones: [
            {
                timezone: 'America/Santiago',
                country_name: 'Chile',
                country_code: 'CL',
                country_capital: 'Santiago',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Easter',
                country_name: 'Chile',
                country_code: 'CL',
                country_capital: 'Santiago',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CN',
        timezones: [
            {
                timezone: 'Asia/Shanghai',
                country_name: 'China',
                country_code: 'CN',
                country_capital: 'Beijing',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Urumqi',
                country_name: 'China',
                country_code: 'CN',
                country_capital: 'Beijing',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CI',
        timezones: [
            {
                timezone: 'Africa/Abidjan',
                country_name: 'Ivory Coast',
                country_code: 'CI',
                country_capital: 'Yamoussoukro',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CM',
        timezones: [
            {
                timezone: 'Africa/Douala',
                country_name: 'Cameroon',
                country_code: 'CM',
                country_capital: 'Yaoundé',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CD',
        timezones: [
            {
                timezone: 'Africa/Kinshasa',
                country_name: 'DR Congo',
                country_code: 'CD',
                country_capital: 'Kinshasa',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Africa/Lubumbashi',
                country_name: 'DR Congo',
                country_code: 'CD',
                country_capital: 'Kinshasa',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CG',
        timezones: [
            {
                timezone: 'Africa/Brazzaville',
                country_name: 'Republic of the Congo',
                country_code: 'CG',
                country_capital: 'Brazzaville',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CK',
        timezones: [
            {
                timezone: 'Pacific/Rarotonga',
                country_name: 'Cook Islands',
                country_code: 'CK',
                country_capital: 'Avarua',
                utc_offset: -10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CO',
        timezones: [
            {
                timezone: 'America/Bogota',
                country_name: 'Colombia',
                country_code: 'CO',
                country_capital: 'Bogotá',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KM',
        timezones: [
            {
                timezone: 'Indian/Comoro',
                country_name: 'Comoros',
                country_code: 'KM',
                country_capital: 'Moroni',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CV',
        timezones: [
            {
                timezone: 'Atlantic/Cape_Verde',
                country_name: 'Cape Verde',
                country_code: 'CV',
                country_capital: 'Praia',
                utc_offset: -1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CR',
        timezones: [
            {
                timezone: 'America/Costa_Rica',
                country_name: 'Costa Rica',
                country_code: 'CR',
                country_capital: 'San José',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CU',
        timezones: [
            {
                timezone: 'America/Havana',
                country_name: 'Cuba',
                country_code: 'CU',
                country_capital: 'Havana',
                utc_offset: -5,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CW',
        timezones: [
            {
                timezone: 'America/Curacao',
                country_name: 'Curaçao',
                country_code: 'CW',
                country_capital: 'Willemstad',
                utc_offset: -4,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CX',
        timezones: [
            {
                timezone: 'Indian/Christmas',
                country_name: 'Christmas Island',
                country_code: 'CX',
                country_capital: 'Flying Fish Cove',
                utc_offset: 7,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KY',
        timezones: [
            {
                timezone: 'America/Cayman',
                country_name: 'Cayman Islands',
                country_code: 'KY',
                country_capital: 'George Town',
                utc_offset: -5,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CY',
        timezones: [
            {
                timezone: 'Asia/Nicosia',
                country_name: 'Cyprus',
                country_code: 'CY',
                country_capital: 'Nicosia',
                utc_offset: 2,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'CZ',
        timezones: [
            {
                timezone: 'Europe/Prague',
                country_name: 'Czech Republic',
                country_code: 'CZ',
                country_capital: 'Prague',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'DE',
        timezones: [
            {
                timezone: 'Europe/Berlin',
                country_name: 'Germany',
                country_code: 'DE',
                country_capital: 'Berlin',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Europe/Busingen',
                country_name: 'Germany',
                country_code: 'DE',
                country_capital: 'Berlin',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'DJ',
        timezones: [
            {
                timezone: 'Africa/Djibouti',
                country_name: 'Djibouti',
                country_code: 'DJ',
                country_capital: 'Djibouti',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'DM',
        timezones: [
            {
                timezone: 'America/Dominica',
                country_name: 'Dominica',
                country_code: 'DM',
                country_capital: 'Roseau',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'DK',
        timezones: [
            {
                timezone: 'Europe/Copenhagen',
                country_name: 'Denmark',
                country_code: 'DK',
                country_capital: 'Copenhagen',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'DO',
        timezones: [
            {
                timezone: 'America/Santo_Domingo',
                country_name: 'Dominican Republic',
                country_code: 'DO',
                country_capital: 'Santo Domingo',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'DZ',
        timezones: [
            {
                timezone: 'Africa/Algiers',
                country_name: 'Algeria',
                country_code: 'DZ',
                country_capital: 'Algiers',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'EC',
        timezones: [
            {
                timezone: 'America/Guayaquil',
                country_name: 'Ecuador',
                country_code: 'EC',
                country_capital: 'Quito',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Galapagos',
                country_name: 'Ecuador',
                country_code: 'EC',
                country_capital: 'Quito',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'EG',
        timezones: [
            {
                timezone: 'Africa/Cairo',
                country_name: 'Egypt',
                country_code: 'EG',
                country_capital: 'Cairo',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'ER',
        timezones: [
            {
                timezone: 'Africa/Asmara',
                country_name: 'Eritrea',
                country_code: 'ER',
                country_capital: 'Asmara',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'EH',
        timezones: [
            {
                timezone: 'Africa/El_Aaiun',
                country_name: 'Western Sahara',
                country_code: 'EH',
                country_capital: 'El Aaiún',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'ES',
        timezones: [
            {
                timezone: 'Europe/Madrid',
                country_name: 'Spain',
                country_code: 'ES',
                country_capital: 'Madrid',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Africa/Ceuta',
                country_name: 'Spain',
                country_code: 'ES',
                country_capital: 'Madrid',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Atlantic/Canary',
                country_name: 'Spain',
                country_code: 'ES',
                country_capital: 'Madrid',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'EE',
        timezones: [
            {
                timezone: 'Europe/Tallinn',
                country_name: 'Estonia',
                country_code: 'EE',
                country_capital: 'Tallinn',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'ET',
        timezones: [
            {
                timezone: 'Africa/Addis_Ababa',
                country_name: 'Ethiopia',
                country_code: 'ET',
                country_capital: 'Addis Ababa',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'FI',
        timezones: [
            {
                timezone: 'Europe/Helsinki',
                country_name: 'Finland',
                country_code: 'FI',
                country_capital: 'Helsinki',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'FJ',
        timezones: [
            {
                timezone: 'Pacific/Fiji',
                country_name: 'Fiji',
                country_code: 'FJ',
                country_capital: 'Suva',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'FK',
        timezones: [
            {
                timezone: 'Atlantic/Stanley',
                country_name: 'Falkland Islands',
                country_code: 'FK',
                country_capital: 'Stanley',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'FR',
        timezones: [
            {
                timezone: 'Europe/Paris',
                country_name: 'France',
                country_code: 'FR',
                country_capital: 'Paris',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'FO',
        timezones: [
            {
                timezone: 'Atlantic/Faroe',
                country_name: 'Faroe Islands',
                country_code: 'FO',
                country_capital: 'Tórshavn',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'FM',
        timezones: [
            {
                timezone: 'Pacific/Chuuk',
                country_name: 'Micronesia',
                country_code: 'FM',
                country_capital: 'Palikir',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Pohnpei',
                country_name: 'Micronesia',
                country_code: 'FM',
                country_capital: 'Palikir',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Kosrae',
                country_name: 'Micronesia',
                country_code: 'FM',
                country_capital: 'Palikir',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GA',
        timezones: [
            {
                timezone: 'Africa/Libreville',
                country_name: 'Gabon',
                country_code: 'GA',
                country_capital: 'Libreville',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GB',
        timezones: [
            {
                timezone: 'Europe/London',
                country_name: 'United Kingdom',
                country_code: 'GB',
                country_capital: 'London',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GE',
        timezones: [
            {
                timezone: 'Asia/Tbilisi',
                country_name: 'Georgia',
                country_code: 'GE',
                country_capital: 'Tbilisi',
                utc_offset: 4,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GG',
        timezones: [
            {
                timezone: 'Europe/Guernsey',
                country_name: 'Guernsey',
                country_code: 'GG',
                country_capital: 'St. Peter Port',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GH',
        timezones: [
            {
                timezone: 'Africa/Accra',
                country_name: 'Ghana',
                country_code: 'GH',
                country_capital: 'Accra',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GI',
        timezones: [
            {
                timezone: 'Europe/Gibraltar',
                country_name: 'Gibraltar',
                country_code: 'GI',
                country_capital: 'Gibraltar',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GN',
        timezones: [
            {
                timezone: 'Africa/Conakry',
                country_name: 'Guinea',
                country_code: 'GN',
                country_capital: 'Conakry',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GP',
        timezones: [
            {
                timezone: 'America/Guadeloupe',
                country_name: 'Guadeloupe',
                country_code: 'GP',
                country_capital: 'Basse-Terre',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GM',
        timezones: [
            {
                timezone: 'Africa/Banjul',
                country_name: 'Gambia',
                country_code: 'GM',
                country_capital: 'Banjul',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GW',
        timezones: [
            {
                timezone: 'Africa/Bissau',
                country_name: 'Guinea-Bissau',
                country_code: 'GW',
                country_capital: 'Bissau',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GQ',
        timezones: [
            {
                timezone: 'Africa/Malabo',
                country_name: 'Equatorial Guinea',
                country_code: 'GQ',
                country_capital: 'Malabo',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GR',
        timezones: [
            {
                timezone: 'Europe/Athens',
                country_name: 'Greece',
                country_code: 'GR',
                country_capital: 'Athens',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GD',
        timezones: [
            {
                timezone: 'America/Grenada',
                country_name: 'Grenada',
                country_code: 'GD',
                country_capital: 'St. George\'s',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GL',
        timezones: [
            {
                timezone: 'America/Godthab',
                country_name: 'Greenland',
                country_code: 'GL',
                country_capital: 'Nuuk',
                utc_offset: -3,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Danmarkshavn',
                country_name: 'Greenland',
                country_code: 'GL',
                country_capital: 'Nuuk',
                utc_offset: -3,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Scoresbysund',
                country_name: 'Greenland',
                country_code: 'GL',
                country_capital: 'Nuuk',
                utc_offset: -3,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Thule',
                country_name: 'Greenland',
                country_code: 'GL',
                country_capital: 'Nuuk',
                utc_offset: -3,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GT',
        timezones: [
            {
                timezone: 'America/Guatemala',
                country_name: 'Guatemala',
                country_code: 'GT',
                country_capital: 'Guatemala City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GF',
        timezones: [
            {
                timezone: 'America/Cayenne',
                country_name: 'French Guiana',
                country_code: 'GF',
                country_capital: 'Cayenne',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GU',
        timezones: [
            {
                timezone: 'Pacific/Guam',
                country_name: 'Guam',
                country_code: 'GU',
                country_capital: 'Hagåtña',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GY',
        timezones: [
            {
                timezone: 'America/Guyana',
                country_name: 'Guyana',
                country_code: 'GY',
                country_capital: 'Georgetown',
                utc_offset: -4,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'HK',
        timezones: [
            {
                timezone: 'Asia/Hong_Kong',
                country_name: 'Hong Kong',
                country_code: 'HK',
                country_capital: 'City of Victoria',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'HN',
        timezones: [
            {
                timezone: 'America/Tegucigalpa',
                country_name: 'Honduras',
                country_code: 'HN',
                country_capital: 'Tegucigalpa',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'HR',
        timezones: [
            {
                timezone: 'Europe/Zagreb',
                country_name: 'Croatia',
                country_code: 'HR',
                country_capital: 'Zagreb',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'HT',
        timezones: [
            {
                timezone: 'America/Port-au-Prince',
                country_name: 'Haiti',
                country_code: 'HT',
                country_capital: 'Port-au-Prince',
                utc_offset: -5,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'HU',
        timezones: [
            {
                timezone: 'Europe/Budapest',
                country_name: 'Hungary',
                country_code: 'HU',
                country_capital: 'Budapest',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'ID',
        timezones: [
            {
                timezone: 'Asia/Jakarta',
                country_name: 'Indonesia',
                country_code: 'ID',
                country_capital: 'Jakarta',
                utc_offset: 7,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Pontianak',
                country_name: 'Indonesia',
                country_code: 'ID',
                country_capital: 'Jakarta',
                utc_offset: 7,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Makassar',
                country_name: 'Indonesia',
                country_code: 'ID',
                country_capital: 'Jakarta',
                utc_offset: 7,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Jayapura',
                country_name: 'Indonesia',
                country_code: 'ID',
                country_capital: 'Jakarta',
                utc_offset: 7,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'IM',
        timezones: [
            {
                timezone: 'Europe/Isle_of_Man',
                country_name: 'Isle of Man',
                country_code: 'IM',
                country_capital: 'Douglas',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'IN',
        timezones: [
            {
                timezone: 'Asia/Kolkata',
                country_name: 'India',
                country_code: 'IN',
                country_capital: 'New Delhi',
                utc_offset: 5.3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'IO',
        timezones: [
            {
                timezone: 'Indian/Chagos',
                country_name: 'British Indian Ocean Territory',
                country_code: 'IO',
                country_capital: 'Diego Garcia',
                utc_offset: 6,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'IE',
        timezones: [
            {
                timezone: 'Europe/Dublin',
                country_name: 'Ireland',
                country_code: 'IE',
                country_capital: 'Dublin',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'IR',
        timezones: [
            {
                timezone: 'Asia/Tehran',
                country_name: 'Iran',
                country_code: 'IR',
                country_capital: 'Tehran',
                utc_offset: 3.3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'IQ',
        timezones: [
            {
                timezone: 'Asia/Baghdad',
                country_name: 'Iraq',
                country_code: 'IQ',
                country_capital: 'Baghdad',
                utc_offset: 3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'IS',
        timezones: [
            {
                timezone: 'Atlantic/Reykjavik',
                country_name: 'Iceland',
                country_code: 'IS',
                country_capital: 'Reykjavik',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'IL',
        timezones: [
            {
                timezone: 'Asia/Jerusalem',
                country_name: 'Israel',
                country_code: 'IL',
                country_capital: 'Jerusalem',
                utc_offset: 2,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'IT',
        timezones: [
            {
                timezone: 'Europe/Rome',
                country_name: 'Italy',
                country_code: 'IT',
                country_capital: 'Rome',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'JM',
        timezones: [
            {
                timezone: 'America/Jamaica',
                country_name: 'Jamaica',
                country_code: 'JM',
                country_capital: 'Kingston',
                utc_offset: -5,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'JE',
        timezones: [
            {
                timezone: 'Europe/Jersey',
                country_name: 'Jersey',
                country_code: 'JE',
                country_capital: 'Saint Helier',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'JO',
        timezones: [
            {
                timezone: 'Asia/Amman',
                country_name: 'Jordan',
                country_code: 'JO',
                country_capital: 'Amman',
                utc_offset: 2,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'JP',
        timezones: [
            {
                timezone: 'Asia/Tokyo',
                country_name: 'Japan',
                country_code: 'JP',
                country_capital: 'Tokyo',
                utc_offset: 9,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KZ',
        timezones: [
            {
                timezone: 'Asia/Almaty',
                country_name: 'Kazakhstan',
                country_code: 'KZ',
                country_capital: 'Astana',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Qyzylorda',
                country_name: 'Kazakhstan',
                country_code: 'KZ',
                country_capital: 'Astana',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Aqtobe',
                country_name: 'Kazakhstan',
                country_code: 'KZ',
                country_capital: 'Astana',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Aqtau',
                country_name: 'Kazakhstan',
                country_code: 'KZ',
                country_capital: 'Astana',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Oral',
                country_name: 'Kazakhstan',
                country_code: 'KZ',
                country_capital: 'Astana',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KE',
        timezones: [
            {
                timezone: 'Africa/Nairobi',
                country_name: 'Kenya',
                country_code: 'KE',
                country_capital: 'Nairobi',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KG',
        timezones: [
            {
                timezone: 'Asia/Bishkek',
                country_name: 'Kyrgyzstan',
                country_code: 'KG',
                country_capital: 'Bishkek',
                utc_offset: 6,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KH',
        timezones: [
            {
                timezone: 'Asia/Phnom_Penh',
                country_name: 'Cambodia',
                country_code: 'KH',
                country_capital: 'Phnom Penh',
                utc_offset: 7,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KI',
        timezones: [
            {
                timezone: 'Pacific/Tarawa',
                country_name: 'Kiribati',
                country_code: 'KI',
                country_capital: 'South Tarawa',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Enderbury',
                country_name: 'Kiribati',
                country_code: 'KI',
                country_capital: 'South Tarawa',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Kiritimati',
                country_name: 'Kiribati',
                country_code: 'KI',
                country_capital: 'South Tarawa',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KN',
        timezones: [
            {
                timezone: 'America/St_Kitts',
                country_name: 'Saint Kitts and Nevis',
                country_code: 'KN',
                country_capital: 'Basseterre',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KR',
        timezones: [
            {
                timezone: 'Asia/Seoul',
                country_name: 'South Korea',
                country_code: 'KR',
                country_capital: 'Seoul',
                utc_offset: 9,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'XK',
        timezones: [
            {
                timezone: 'Europe/Belgrade',
                country_name: 'Kosovo',
                country_code: 'XK',
                country_capital: 'Pristina',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KW',
        timezones: [
            {
                timezone: 'Asia/Kuwait',
                country_name: 'Kuwait',
                country_code: 'KW',
                country_capital: 'Kuwait City',
                utc_offset: 3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LA',
        timezones: [
            {
                timezone: 'Asia/Vientiane',
                country_name: 'Laos',
                country_code: 'LA',
                country_capital: 'Vientiane',
                utc_offset: 7,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LB',
        timezones: [
            {
                timezone: 'Asia/Beirut',
                country_name: 'Lebanon',
                country_code: 'LB',
                country_capital: 'Beirut',
                utc_offset: 2,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LR',
        timezones: [
            {
                timezone: 'Africa/Monrovia',
                country_name: 'Liberia',
                country_code: 'LR',
                country_capital: 'Monrovia',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LY',
        timezones: [
            {
                timezone: 'Africa/Tripoli',
                country_name: 'Libya',
                country_code: 'LY',
                country_capital: 'Tripoli',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LC',
        timezones: [
            {
                timezone: 'America/St_Lucia',
                country_name: 'Saint Lucia',
                country_code: 'LC',
                country_capital: 'Castries',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LI',
        timezones: [
            {
                timezone: 'Europe/Vaduz',
                country_name: 'Liechtenstein',
                country_code: 'LI',
                country_capital: 'Vaduz',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LK',
        timezones: [
            {
                timezone: 'Asia/Colombo',
                country_name: 'Sri Lanka',
                country_code: 'LK',
                country_capital: 'Colombo',
                utc_offset: 5.3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LS',
        timezones: [
            {
                timezone: 'Africa/Maseru',
                country_name: 'Lesotho',
                country_code: 'LS',
                country_capital: 'Maseru',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LT',
        timezones: [
            {
                timezone: 'Europe/Vilnius',
                country_name: 'Lithuania',
                country_code: 'LT',
                country_capital: 'Vilnius',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LU',
        timezones: [
            {
                timezone: 'Europe/Luxembourg',
                country_name: 'Luxembourg',
                country_code: 'LU',
                country_capital: 'Luxembourg',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'LV',
        timezones: [
            {
                timezone: 'Europe/Riga',
                country_name: 'Latvia',
                country_code: 'LV',
                country_capital: 'Riga',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MO',
        timezones: [
            {
                timezone: 'Asia/Macau',
                country_name: 'Macau',
                country_code: 'MO',
                country_capital: null,
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MF',
        timezones: [
            {
                timezone: 'America/Marigot',
                country_name: 'Saint Martin',
                country_code: 'MF',
                country_capital: 'Marigot',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MA',
        timezones: [
            {
                timezone: 'Africa/Casablanca',
                country_name: 'Morocco',
                country_code: 'MA',
                country_capital: 'Rabat',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MC',
        timezones: [
            {
                timezone: 'Europe/Monaco',
                country_name: 'Monaco',
                country_code: 'MC',
                country_capital: 'Monaco',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MD',
        timezones: [
            {
                timezone: 'Europe/Chisinau',
                country_name: 'Moldova',
                country_code: 'MD',
                country_capital: 'Chișinău',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MG',
        timezones: [
            {
                timezone: 'Indian/Antananarivo',
                country_name: 'Madagascar',
                country_code: 'MG',
                country_capital: 'Antananarivo',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MV',
        timezones: [
            {
                timezone: 'Indian/Maldives',
                country_name: 'Maldives',
                country_code: 'MV',
                country_capital: 'Malé',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MX',
        timezones: [
            {
                timezone: 'America/Mexico_City',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Cancun',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Merida',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Monterrey',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Matamoros',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Mazatlan',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Chihuahua',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Ojinaga',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Hermosillo',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Tijuana',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Bahia_Banderas',
                country_name: 'Mexico',
                country_code: 'MX',
                country_capital: 'Mexico City',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MH',
        timezones: [
            {
                timezone: 'Pacific/Majuro',
                country_name: 'Marshall Islands',
                country_code: 'MH',
                country_capital: 'Majuro',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Kwajalein',
                country_name: 'Marshall Islands',
                country_code: 'MH',
                country_capital: 'Majuro',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MK',
        timezones: [
            {
                timezone: 'Europe/Skopje',
                country_name: 'Macedonia',
                country_code: 'MK',
                country_capital: 'Skopje',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'ML',
        timezones: [
            {
                timezone: 'Africa/Bamako',
                country_name: 'Mali',
                country_code: 'ML',
                country_capital: 'Bamako',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MT',
        timezones: [
            {
                timezone: 'Europe/Malta',
                country_name: 'Malta',
                country_code: 'MT',
                country_capital: 'Valletta',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MM',
        timezones: [
            {
                timezone: 'Asia/Rangoon',
                country_name: 'Myanmar',
                country_code: 'MM',
                country_capital: 'Naypyidaw',
                utc_offset: 6.3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'ME',
        timezones: [
            {
                timezone: 'Europe/Podgorica',
                country_name: 'Montenegro',
                country_code: 'ME',
                country_capital: 'Podgorica',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MN',
        timezones: [
            {
                timezone: 'Asia/Ulaanbaatar',
                country_name: 'Mongolia',
                country_code: 'MN',
                country_capital: 'Ulan Bator',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Hovd',
                country_name: 'Mongolia',
                country_code: 'MN',
                country_capital: 'Ulan Bator',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Choibalsan',
                country_name: 'Mongolia',
                country_code: 'MN',
                country_capital: 'Ulan Bator',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MP',
        timezones: [
            {
                timezone: 'Pacific/Saipan',
                country_name: 'Northern Mariana Islands',
                country_code: 'MP',
                country_capital: 'Saipan',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MZ',
        timezones: [
            {
                timezone: 'Africa/Maputo',
                country_name: 'Mozambique',
                country_code: 'MZ',
                country_capital: 'Maputo',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MR',
        timezones: [
            {
                timezone: 'Africa/Nouakchott',
                country_name: 'Mauritania',
                country_code: 'MR',
                country_capital: 'Nouakchott',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MS',
        timezones: [
            {
                timezone: 'America/Montserrat',
                country_name: 'Montserrat',
                country_code: 'MS',
                country_capital: 'Plymouth',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MQ',
        timezones: [
            {
                timezone: 'America/Martinique',
                country_name: 'Martinique',
                country_code: 'MQ',
                country_capital: 'Fort-de-France',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MU',
        timezones: [
            {
                timezone: 'Indian/Mauritius',
                country_name: 'Mauritius',
                country_code: 'MU',
                country_capital: 'Port Louis',
                utc_offset: 4,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MW',
        timezones: [
            {
                timezone: 'Africa/Blantyre',
                country_name: 'Malawi',
                country_code: 'MW',
                country_capital: 'Lilongwe',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'MY',
        timezones: [
            {
                timezone: 'Asia/Kuala_Lumpur',
                country_name: 'Malaysia',
                country_code: 'MY',
                country_capital: 'Kuala Lumpur',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Kuching',
                country_name: 'Malaysia',
                country_code: 'MY',
                country_capital: 'Kuala Lumpur',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'YT',
        timezones: [
            {
                timezone: 'Indian/Mayotte',
                country_name: 'Mayotte',
                country_code: 'YT',
                country_capital: 'Mamoudzou',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NA',
        timezones: [
            {
                timezone: 'Africa/Windhoek',
                country_name: 'Namibia',
                country_code: 'NA',
                country_capital: 'Windhoek',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NC',
        timezones: [
            {
                timezone: 'Pacific/Noumea',
                country_name: 'New Caledonia',
                country_code: 'NC',
                country_capital: 'Nouméa',
                utc_offset: 11,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NE',
        timezones: [
            {
                timezone: 'Africa/Niamey',
                country_name: 'Niger',
                country_code: 'NE',
                country_capital: 'Niamey',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NF',
        timezones: [
            {
                timezone: 'Pacific/Norfolk',
                country_name: 'Norfolk Island',
                country_code: 'NF',
                country_capital: 'Kingston',
                utc_offset: 11.3,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NG',
        timezones: [
            {
                timezone: 'Africa/Lagos',
                country_name: 'Nigeria',
                country_code: 'NG',
                country_capital: 'Abuja',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NI',
        timezones: [
            {
                timezone: 'America/Managua',
                country_name: 'Nicaragua',
                country_code: 'NI',
                country_capital: 'Managua',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NU',
        timezones: [
            {
                timezone: 'Pacific/Niue',
                country_name: 'Niue',
                country_code: 'NU',
                country_capital: 'Alofi',
                utc_offset: -11,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NL',
        timezones: [
            {
                timezone: 'Europe/Amsterdam',
                country_name: 'Netherlands',
                country_code: 'NL',
                country_capital: 'Amsterdam',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NO',
        timezones: [
            {
                timezone: 'Europe/Oslo',
                country_name: 'Norway',
                country_code: 'NO',
                country_capital: 'Oslo',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NP',
        timezones: [
            {
                timezone: 'Asia/Kathmandu',
                country_name: 'Nepal',
                country_code: 'NP',
                country_capital: 'Kathmandu',
                utc_offset: 5.45,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NR',
        timezones: [
            {
                timezone: 'Pacific/Nauru',
                country_name: 'Nauru',
                country_code: 'NR',
                country_capital: 'Yaren',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'NZ',
        timezones: [
            {
                timezone: 'Pacific/Auckland',
                country_name: 'New Zealand',
                country_code: 'NZ',
                country_capital: 'Wellington',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Chatham',
                country_name: 'New Zealand',
                country_code: 'NZ',
                country_capital: 'Wellington',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'OM',
        timezones: [
            {
                timezone: 'Asia/Muscat',
                country_name: 'Oman',
                country_code: 'OM',
                country_capital: 'Muscat',
                utc_offset: 4,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PK',
        timezones: [
            {
                timezone: 'Asia/Karachi',
                country_name: 'Pakistan',
                country_code: 'PK',
                country_capital: 'Islamabad',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PA',
        timezones: [
            {
                timezone: 'America/Panama',
                country_name: 'Panama',
                country_code: 'PA',
                country_capital: 'Panama City',
                utc_offset: -5,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PN',
        timezones: [
            {
                timezone: 'Pacific/Pitcairn',
                country_name: 'Pitcairn Islands',
                country_code: 'PN',
                country_capital: 'Adamstown',
                utc_offset: -8,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PE',
        timezones: [
            {
                timezone: 'America/Lima',
                country_name: 'Peru',
                country_code: 'PE',
                country_capital: 'Lima',
                utc_offset: -5,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PH',
        timezones: [
            {
                timezone: 'Asia/Manila',
                country_name: 'Philippines',
                country_code: 'PH',
                country_capital: 'Manila',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PW',
        timezones: [
            {
                timezone: 'Pacific/Palau',
                country_name: 'Palau',
                country_code: 'PW',
                country_capital: 'Ngerulmud',
                utc_offset: 9,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PG',
        timezones: [
            {
                timezone: 'Pacific/Port_Moresby',
                country_name: 'Papua New Guinea',
                country_code: 'PG',
                country_capital: 'Port Moresby',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Bougainville',
                country_name: 'Papua New Guinea',
                country_code: 'PG',
                country_capital: 'Port Moresby',
                utc_offset: 10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PL',
        timezones: [
            {
                timezone: 'Europe/Warsaw',
                country_name: 'Poland',
                country_code: 'PL',
                country_capital: 'Warsaw',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PR',
        timezones: [
            {
                timezone: 'America/Puerto_Rico',
                country_name: 'Puerto Rico',
                country_code: 'PR',
                country_capital: 'San Juan',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'KP',
        timezones: [
            {
                timezone: 'Asia/Pyongyang',
                country_name: 'North Korea',
                country_code: 'KP',
                country_capital: 'Pyongyang',
                utc_offset: 8.3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PT',
        timezones: [
            {
                timezone: 'Europe/Lisbon',
                country_name: 'Portugal',
                country_code: 'PT',
                country_capital: 'Lisbon',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Atlantic/Madeira',
                country_name: 'Portugal',
                country_code: 'PT',
                country_capital: 'Lisbon',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Atlantic/Azores',
                country_name: 'Portugal',
                country_code: 'PT',
                country_capital: 'Lisbon',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PY',
        timezones: [
            {
                timezone: 'America/Asuncion',
                country_name: 'Paraguay',
                country_code: 'PY',
                country_capital: 'Asunción',
                utc_offset: -4,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PS',
        timezones: [
            {
                timezone: 'Asia/Gaza',
                country_name: 'Palestine',
                country_code: 'PS',
                country_capital: 'Ramallah',
                utc_offset: 2,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Hebron',
                country_name: 'Palestine',
                country_code: 'PS',
                country_capital: 'Ramallah',
                utc_offset: 2,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PF',
        timezones: [
            {
                timezone: 'Pacific/Tahiti',
                country_name: 'French Polynesia',
                country_code: 'PF',
                country_capital: 'Papeetē',
                utc_offset: -10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Marquesas',
                country_name: 'French Polynesia',
                country_code: 'PF',
                country_capital: 'Papeetē',
                utc_offset: -10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Gambier',
                country_name: 'French Polynesia',
                country_code: 'PF',
                country_capital: 'Papeetē',
                utc_offset: -10,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'QA',
        timezones: [
            {
                timezone: 'Asia/Qatar',
                country_name: 'Qatar',
                country_code: 'QA',
                country_capital: 'Doha',
                utc_offset: 3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'RE',
        timezones: [
            {
                timezone: 'Indian/Reunion',
                country_name: 'Réunion',
                country_code: 'RE',
                country_capital: 'Saint-Denis',
                utc_offset: 4,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'RO',
        timezones: [
            {
                timezone: 'Europe/Bucharest',
                country_name: 'Romania',
                country_code: 'RO',
                country_capital: 'Bucharest',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'RU',
        timezones: [
            {
                timezone: 'Europe/Kaliningrad',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Europe/Moscow',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Europe/Simferopol',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Europe/Volgograd',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Europe/Kirov',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Europe/Astrakhan',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Europe/Samara',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Europe/Ulyanovsk',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Yekaterinburg',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Omsk',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Novosibirsk',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Barnaul',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Tomsk',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Novokuznetsk',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Krasnoyarsk',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Irkutsk',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Chita',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Yakutsk',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Khandyga',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Vladivostok',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Ust-Nera',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Magadan',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Sakhalin',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Srednekolymsk',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Kamchatka',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Anadyr',
                country_name: 'Russia',
                country_code: 'RU',
                country_capital: 'Moscow',
                utc_offset: 0,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'RW',
        timezones: [
            {
                timezone: 'Africa/Kigali',
                country_name: 'Rwanda',
                country_code: 'RW',
                country_capital: 'Kigali',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SA',
        timezones: [
            {
                timezone: 'Asia/Riyadh',
                country_name: 'Saudi Arabia',
                country_code: 'SA',
                country_capital: 'Riyadh',
                utc_offset: 3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SD',
        timezones: [
            {
                timezone: 'Africa/Khartoum',
                country_name: 'Sudan',
                country_code: 'SD',
                country_capital: 'Khartoum',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SN',
        timezones: [
            {
                timezone: 'Africa/Dakar',
                country_name: 'Senegal',
                country_code: 'SN',
                country_capital: 'Dakar',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SG',
        timezones: [
            {
                timezone: 'Asia/Singapore',
                country_name: 'Singapore',
                country_code: 'SG',
                country_capital: 'Singapore',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'GS',
        timezones: [
            {
                timezone: 'Atlantic/South_Georgia',
                country_name: 'South Georgia',
                country_code: 'GS',
                country_capital: 'King Edward Point',
                utc_offset: -2,
                country_continent: 'Antarctica',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SJ',
        timezones: [
            {
                timezone: 'Arctic/Longyearbyen',
                country_name: 'Svalbard and Jan Mayen',
                country_code: 'SJ',
                country_capital: 'Longyearbyen',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SB',
        timezones: [
            {
                timezone: 'Pacific/Guadalcanal',
                country_name: 'Solomon Islands',
                country_code: 'SB',
                country_capital: 'Honiara',
                utc_offset: 11,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SL',
        timezones: [
            {
                timezone: 'Africa/Freetown',
                country_name: 'Sierra Leone',
                country_code: 'SL',
                country_capital: 'Freetown',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SV',
        timezones: [
            {
                timezone: 'America/El_Salvador',
                country_name: 'El Salvador',
                country_code: 'SV',
                country_capital: 'San Salvador',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SM',
        timezones: [
            {
                timezone: 'Europe/San_Marino',
                country_name: 'San Marino',
                country_code: 'SM',
                country_capital: 'City of San Marino',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SO',
        timezones: [
            {
                timezone: 'Africa/Mogadishu',
                country_name: 'Somalia',
                country_code: 'SO',
                country_capital: 'Mogadishu',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'PM',
        timezones: [
            {
                timezone: 'America/Miquelon',
                country_name: 'Saint Pierre and Miquelon',
                country_code: 'PM',
                country_capital: 'Saint-Pierre',
                utc_offset: -3,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'RS',
        timezones: [
            {
                timezone: 'Europe/Belgrade',
                country_name: 'Serbia',
                country_code: 'RS',
                country_capital: 'Belgrade',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SS',
        timezones: [
            {
                timezone: 'Africa/Juba',
                country_name: 'South Sudan',
                country_code: 'SS',
                country_capital: 'Juba',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'ST',
        timezones: [
            {
                timezone: 'Africa/Sao_Tome',
                country_name: 'São Tomé and Príncipe',
                country_code: 'ST',
                country_capital: 'São Tomé',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SR',
        timezones: [
            {
                timezone: 'America/Paramaribo',
                country_name: 'Suriname',
                country_code: 'SR',
                country_capital: 'Paramaribo',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SK',
        timezones: [
            {
                timezone: 'Europe/Bratislava',
                country_name: 'Slovakia',
                country_code: 'SK',
                country_capital: 'Bratislava',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SI',
        timezones: [
            {
                timezone: 'Europe/Ljubljana',
                country_name: 'Slovenia',
                country_code: 'SI',
                country_capital: 'Ljubljana',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SE',
        timezones: [
            {
                timezone: 'Europe/Stockholm',
                country_name: 'Sweden',
                country_code: 'SE',
                country_capital: 'Stockholm',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SZ',
        timezones: [
            {
                timezone: 'Africa/Mbabane',
                country_name: 'Swaziland',
                country_code: 'SZ',
                country_capital: 'Lobamba',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SX',
        timezones: [
            {
                timezone: 'America/Lower_Princes',
                country_name: 'Sint Maarten',
                country_code: 'SX',
                country_capital: 'Philipsburg',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SC',
        timezones: [
            {
                timezone: 'Indian/Mahe',
                country_name: 'Seychelles',
                country_code: 'SC',
                country_capital: 'Victoria',
                utc_offset: 4,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'SY',
        timezones: [
            {
                timezone: 'Asia/Damascus',
                country_name: 'Syria',
                country_code: 'SY',
                country_capital: 'Damascus',
                utc_offset: 2,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TC',
        timezones: [
            {
                timezone: 'America/Grand_Turk',
                country_name: 'Turks and Caicos Islands',
                country_code: 'TC',
                country_capital: 'Cockburn Town',
                utc_offset: -5,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TD',
        timezones: [
            {
                timezone: 'Africa/Ndjamena',
                country_name: 'Chad',
                country_code: 'TD',
                country_capital: 'N\'Djamena',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TG',
        timezones: [
            {
                timezone: 'Africa/Lome',
                country_name: 'Togo',
                country_code: 'TG',
                country_capital: 'Lomé',
                utc_offset: 0,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TH',
        timezones: [
            {
                timezone: 'Asia/Bangkok',
                country_name: 'Thailand',
                country_code: 'TH',
                country_capital: 'Bangkok',
                utc_offset: 7,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TJ',
        timezones: [
            {
                timezone: 'Asia/Dushanbe',
                country_name: 'Tajikistan',
                country_code: 'TJ',
                country_capital: 'Dushanbe',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TK',
        timezones: [
            {
                timezone: 'Pacific/Fakaofo',
                country_name: 'Tokelau',
                country_code: 'TK',
                country_capital: 'Fakaofo',
                utc_offset: 13,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TM',
        timezones: [
            {
                timezone: 'Asia/Ashgabat',
                country_name: 'Turkmenistan',
                country_code: 'TM',
                country_capital: 'Ashgabat',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TL',
        timezones: [
            {
                timezone: 'Asia/Dili',
                country_name: 'Timor-Leste',
                country_code: 'TL',
                country_capital: 'Dili',
                utc_offset: 9,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TO',
        timezones: [
            {
                timezone: 'Pacific/Tongatapu',
                country_name: 'Tonga',
                country_code: 'TO',
                country_capital: 'Nuku\'alofa',
                utc_offset: 13,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TT',
        timezones: [
            {
                timezone: 'America/Port_of_Spain',
                country_name: 'Trinidad and Tobago',
                country_code: 'TT',
                country_capital: 'Port of Spain',
                utc_offset: 13,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TN',
        timezones: [
            {
                timezone: 'Africa/Tunis',
                country_name: 'Tunisia',
                country_code: 'TN',
                country_capital: 'Tunis',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TR',
        timezones: [
            {
                timezone: 'Europe/Istanbul',
                country_name: 'Turkey',
                country_code: 'TR',
                country_capital: 'Ankara',
                utc_offset: 2,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TV',
        timezones: [
            {
                timezone: 'Pacific/Funafuti',
                country_name: 'Tuvalu',
                country_code: 'TV',
                country_capital: 'Funafuti',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TW',
        timezones: [
            {
                timezone: 'Asia/Taipei',
                country_name: 'Taiwan',
                country_code: 'TW',
                country_capital: 'Taipei',
                utc_offset: 8,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'TZ',
        timezones: [
            {
                timezone: 'Africa/Dar_es_Salaam',
                country_name: 'Tanzania',
                country_code: 'TZ',
                country_capital: 'Dodoma',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'UG',
        timezones: [
            {
                timezone: 'Africa/Kampala',
                country_name: 'Uganda',
                country_code: 'UG',
                country_capital: 'Kampala',
                utc_offset: 3,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'UA',
        timezones: [
            {
                timezone: 'Europe/Kiev',
                country_name: 'Ukraine',
                country_code: 'UA',
                country_capital: 'Kiev',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Europe/Uzhgorod',
                country_name: 'Ukraine',
                country_code: 'UA',
                country_capital: 'Kiev',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Europe/Zaporozhye',
                country_name: 'Ukraine',
                country_code: 'UA',
                country_capital: 'Kiev',
                utc_offset: 2,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'UM',
        timezones: [
            {
                timezone: 'Pacific/Johnston',
                country_name: 'United States Minor Outlying Islands',
                country_code: 'UM',
                country_capital: null,
                utc_offset: -11,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Midway',
                country_name: 'United States Minor Outlying Islands',
                country_code: 'UM',
                country_capital: null,
                utc_offset: -11,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Wake',
                country_name: 'United States Minor Outlying Islands',
                country_code: 'UM',
                country_capital: null,
                utc_offset: -11,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'UY',
        timezones: [
            {
                timezone: 'America/Montevideo',
                country_name: 'Uruguay',
                country_code: 'UY',
                country_capital: 'Montevideo',
                utc_offset: -3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'US',
        timezones: [
            {
                timezone: 'America/New_York',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Detroit',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Kentucky/Louisville',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Kentucky/Monticello',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Indiana/Indianapolis',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Indiana/Vincennes',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Indiana/Winamac',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Indiana/Marengo',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Indiana/Petersburg',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Indiana/Vevay',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Chicago',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Indiana/Tell_City',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Indiana/Knox',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Menominee',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/North_Dakota/Center',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/North_Dakota/New_Salem',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/North_Dakota/Beulah',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Denver',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Boise',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Phoenix',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Los_Angeles',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Anchorage',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Juneau',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Sitka',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Metlakatla',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Yakutat',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Nome',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'America/Adak',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Pacific/Honolulu',
                country_name: 'United States',
                country_code: 'US',
                country_capital: 'Washington D.C.',
                utc_offset: -6,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'UZ',
        timezones: [
            {
                timezone: 'Asia/Samarkand',
                country_name: 'Uzbekistan',
                country_code: 'UZ',
                country_capital: 'Tashkent',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            },
            {
                timezone: 'Asia/Tashkent',
                country_name: 'Uzbekistan',
                country_code: 'UZ',
                country_capital: 'Tashkent',
                utc_offset: 5,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'VA',
        timezones: [
            {
                timezone: 'Europe/Vatican',
                country_name: 'Vatican City',
                country_code: 'VA',
                country_capital: 'Vatican City',
                utc_offset: 1,
                country_continent: 'Europe',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'VC',
        timezones: [
            {
                timezone: 'America/St_Vincent',
                country_name: 'Saint Vincent and the Grenadines',
                country_code: 'VC',
                country_capital: 'Kingstown',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'VE',
        timezones: [
            {
                timezone: 'America/Caracas',
                country_name: 'Venezuela',
                country_code: 'VE',
                country_capital: 'Caracas',
                utc_offset: -4.3,
                country_continent: 'South America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'VG',
        timezones: [
            {
                timezone: 'America/Tortola',
                country_name: 'British Virgin Islands',
                country_code: 'VG',
                country_capital: 'Road Town',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'VI',
        timezones: [
            {
                timezone: 'America/St_Thomas',
                country_name: 'United States Virgin Islands',
                country_code: 'VI',
                country_capital: 'Charlotte Amalie',
                utc_offset: -4,
                country_continent: 'North America',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'VN',
        timezones: [
            {
                timezone: 'Asia/Ho_Chi_Minh',
                country_name: 'Vietnam',
                country_code: 'VN',
                country_capital: 'Hanoi',
                utc_offset: 7,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'VU',
        timezones: [
            {
                timezone: 'Pacific/Efate',
                country_name: 'Vanuatu',
                country_code: 'VU',
                country_capital: 'Port Vila',
                utc_offset: 11,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'WF',
        timezones: [
            {
                timezone: 'Pacific/Wallis',
                country_name: 'Wallis and Futuna',
                country_code: 'WF',
                country_capital: 'Mata-Utu',
                utc_offset: 12,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'WS',
        timezones: [
            {
                timezone: 'Pacific/Apia',
                country_name: 'Samoa',
                country_code: 'WS',
                country_capital: 'Apia',
                utc_offset: 13,
                country_continent: 'Oceania',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'YE',
        timezones: [
            {
                timezone: 'Asia/Aden',
                country_name: 'Yemen',
                country_code: 'YE',
                country_capital: 'Sana\'a',
                utc_offset: 3,
                country_continent: 'Asia',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'ZA',
        timezones: [
            {
                timezone: 'Africa/Johannesburg',
                country_name: 'South Africa',
                country_code: 'ZA',
                country_capital: 'Pretoria',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'ZM',
        timezones: [
            {
                timezone: 'Africa/Lusaka',
                country_name: 'Zambia',
                country_code: 'ZM',
                country_capital: 'Lusaka',
                utc_offset: 1,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    },
    {
        country_code: 'ZW',
        timezones: [
            {
                timezone: 'Africa/Harare',
                country_name: 'Zimbabwe',
                country_code: 'ZW',
                country_capital: 'Harare',
                utc_offset: 2,
                country_continent: 'Africa',
                timezone_el: '',
                timezone_es: '',
                timezone_it: '',
                timezone_fr: '',
                timezone_de: '',
                timezone_ca: '',
                country_name_el: '',
                country_name_es: '',
                country_name_it: '',
                country_name_fr: '',
                country_name_de: '',
                country_name_ca: '',
                country_capital_el: '',
                country_capital_es: '',
                country_capital_it: '',
                country_capital_fr: '',
                country_capital_de: '',
                country_capital_ca: '',
                country_continent_el: '',
                country_continent_es: '',
                country_continent_it: '',
                country_continent_fr: '',
                country_continent_de: '',
                country_continent_ca: ''
            }
        ]
    }
];
