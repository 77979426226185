<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mt-3 mb-2">
                <div class="col-sm-12 text-center">
                    <a [routerLink]="[ '/' ]">
                        <img [src]="utilsService.customDomainSettings?.logo?.dark" class="logo">
                    </a>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3">

                <div class="card">

                    <div class="card-body">

                        <h3 class="text-center mb-3">

                            {{'PAGES.PASSWORD_RESET.TITLE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                            'Tourmie Stay'}}

                        </h3>

                        <div class="row" *ngIf="isLoading">

                            <div class="col-12 text-center">
                                <i class="fas fa-spinner fa-spin mr-2"></i>{{'PAGES.PASSWORD_RESET.LABELS.LOADING' | translate | replaceTourmieStay:
                                utilsService.customDomainSettings.app_title}}
                            </div>

                        </div>


                        <div *ngIf="tokenIsValid && !isLoading">

                            <!-- Password Inputs -->
                            <div class="row">

                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label class="mb-1" for="newPassword">

                                            {{'PAGES.PASSWORD_RESET.LABELS.PASSWORD_NEW' | translate | replaceTourmieStay:
                                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}

                                        </label>
                                        <input type="password" class="form-control" id="newPassword" minlength="6" name="newPassword" placeholder="New password"
                                            #newPassword="ngModel" [(ngModel)]="userNewPassword" aria-describedby="newPasswordHelp" required>

                                        <small id="newPasswordHelp" class="form-text text-danger"
                                            *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
                                            <span *ngIf="newPassword.errors.required">

                                                <app-required-field-label [type]="'input_field'"></app-required-field-label>

                                            </span>

                                            <span *ngIf="newPassword.errors.minlength">

                                                {{'PAGES.PASSWORD_RESET.LABELS.PASSWORD_LENGTH_WARNING' | translate | replaceTourmieStay:
                                                utilsService.customDomainSettings.app_title}}

                                            </span>
                                        </small>

                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label class="mb-1" for="newPasswordConfirm">

                                            {{'PAGES.PASSWORD_RESET.LABELS.PASSWORD_CONFIRM' | translate | replaceTourmieStay:
                                            utilsService.customDomainSettings.app_title}}

                                        </label>
                                        <input type="password" class="form-control" id="newPasswordConfirm" minlength="6" name="newPasswordConfirm"
                                            placeholder="Retype new password" #newPasswordConfirm="ngModel" [(ngModel)]="userNewPasswordConfirm"
                                            aria-describedby="newPasswordConfirmHelp" required>

                                        <small id="newPasswordConfirmHelp" class="form-text text-danger"
                                            *ngIf="newPasswordConfirm.invalid && (newPasswordConfirm.dirty || newPasswordConfirm.touched)">
                                            <span *ngIf="newPasswordConfirm.errors.required">

                                                <app-required-field-label [type]="'input_field'"></app-required-field-label>

                                            </span>
                                            <span *ngIf="newPasswordConfirm.errors.minlength">

                                                {{'PAGES.PASSWORD_RESET.LABELS.PASSWORD_LENGTH_WARNING' | translate | replaceTourmieStay:
                                                utilsService.customDomainSettings.app_title}}

                                            </span>
                                        </small>

                                    </div>
                                </div>

                            </div>


                            <!-- Password error messages -->
                            <div class="row" *ngIf="userNewPassword && userNewPasswordConfirm && (userNewPassword !== userNewPasswordConfirm)">
                                <div class="col-12">
                                    <small class="form-text text-danger">

                                        {{'PAGES.PASSWORD_RESET.LABELS.PASSWORD_MATCH_WARNING' | translate | replaceTourmieStay:
                                        utilsService.customDomainSettings.app_title}}

                                    </small>
                                </div>
                            </div>

                            <!-- ./Password messages -->



                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-6 offset-md-6 offset-lg-6 text-right">

                                    <button type="button" class="btn btn-tourmie-secondary" (click)="resetPassword();" [disabled]="newPassword.invalid
                                        || newPasswordConfirm.invalid
                                        || (userNewPassword !== userNewPasswordConfirm)" [ladda]="isUpdating">

                                        {{'PAGES.PASSWORD_RESET.BUTTONS.UPDATE' | translate | replaceTourmieStay:
                                        utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}

                                    </button>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    </section>
    <!-- /.content -->
</div>