<!-- Information -->
<div class="card shadow-none rounded-lg">

    <div class="card-body">

        <div class="row px-2">

            <div class="col-12">

                <h5 class="font-weight-600">
                    {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.TITLE_1' | translate | replaceTourmieStay:
                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                </h5>

            </div>

        </div>


        <form #profileForm="ngForm" (ngSubmit)="submitProfileUpdate();" autocomplete="off">

            <div class="row px-2 mt-2">

                <div class="col-sm-6 col-md-6">

                    <div class="form-group">

                        <label class="mb-1" for="fullname">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.NAME' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </label>

                        <div id="fullname" class="text-tourmie-darkblue-2">
                            {{customer?.fullname | titlecase}}
                        </div>

                    </div>

                </div>



                <div class="col-sm-6 col-md-6">

                    <div class="form-group">

                        <label class="mb-1" for="registerDate">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.REGISTER_DATE' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </label>

                        <div id="registerDate" class="text-tourmie-darkblue-2">
                            {{customer?.created_at | date: 'dd/MM/yyyy'}}
                        </div>

                    </div>

                </div>



                <div class="col-sm-6 col-md-6">

                    <div class="form-group">

                        <label class="mb-1" for="email">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.EMAIL' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </label>

                        <div id="email" class="text-tourmie-darkblue-2">
                            {{customer?.email}}
                        </div>

                        <!-- <input type="email" class="form-control" id="email" name="email"
                            pattern="[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,50}$" placeholder="Your email" #email="ngModel"
                            [(ngModel)]="profileUpdateData.email"
                            [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}"
                            aria-describedby="emailHelp" required>

                        <small id="emailHelp" class="form-text text-danger"
                            *ngIf="email.invalid && (email.dirty || email.touched)">
                            <span *ngIf="email.errors.required">
                                {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                            </span>
                        </small> -->

                    </div>

                </div>



                <div class="col-sm-6 col-md-6">

                    <div class="form-group">

                        <label class="mb-1 d-block" for="phone">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.PHONE' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </label>

                        <ngx-intl-tel-input
                            [cssClass]="customerTelephone.invalid && (customerTelephone.dirty && customerTelephone.touched) ? 'form-control is-invalid' : 'form-control'"
                            [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
                            [selectedCountryISO]="CountryISO.Greece" [maxLength]="24" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                            [separateDialCode]="true" id="phone" name="phone" #customerTelephone="ngModel" [(ngModel)]="customerPhone"
                            (ngModelChange)="onPhoneChange();" aria-describedby="phoneHelp" required>
                        </ngx-intl-tel-input>


                        <small id="phoneHelp" class="form-text text-danger"
                            *ngIf="customerTelephone.invalid && (customerTelephone.dirty || customerTelephone.touched)">
                            <span *ngIf="customerTelephone.errors.required">
                                {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                            </span>
                        </small>

                    </div>

                </div>



                <div class="col-sm-6 col-md-6">

                    <div class="form-group">

                        <label class="mb-1" for="birthDate">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.BIRTH_DATE' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </label>

                        <!-- onkeydown="return false" -->
                        <div *ngIf="!customer?.birth_date">

                            <input type="date" class="form-control" id="birthDate" name="birthDate" placeholder="" [attr.min]="minDateOfBirth"
                                [attr.max]="maxDateOfBirth" #birthDate="ngModel" [(ngModel)]="profileUpdateData.birth_date"
                                [ngClass]="{'is-invalid': birthDate.invalid && (birthDate.dirty || birthDate.touched)}" aria-describedby="birthDateHelp"
                                required>

                            <small id="birthDateHelp" class="form-text text-danger" *ngIf="birthDate.invalid && (birthDate.dirty || birthDate.touched)">
                                <span *ngIf="birthDate.errors.required">
                                    {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate | replaceTourmieStay:
                                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                                </span>
                            </small>

                        </div>


                        <div id="birthDate" class="text-tourmie-darkblue-2" *ngIf="customer?.birth_date">
                            {{customer?.birth_date | date: 'dd/MM/yyyy'}}
                        </div>

                    </div>

                </div>

            </div>


            <div class="row px-2">

                <div class="col-12 text-right">

                    <button type="submit" class="btn btn-tourmie-primary" [ngClass]="{'btn-block': isMobile}" [disabled]="profileForm.invalid"
                        [ladda]="isSubmittingProfileInfoUpdate">
                        {{'GENERIC.BUTTONS.UPDATE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie
                        Stay'}}
                    </button>


                </div>

            </div>

        </form>

    </div>

</div>



<!-- Identity -->
<div class="card shadow-none rounded-lg">

    <div class="card-body">

        <div class="row px-2">

            <div class="col-12">

                <h5 class="font-weight-600">{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.TITLE_2' | translate | replaceTourmieStay:
                    utilsService.customDomainSettings.app_title}}</h5>

            </div>

        </div>


        <form #identityForm="ngForm" (ngSubmit)="submitIdentityForm();" autocomplete="off">

            <div class="row px-2 mt-2">

                <!-- Identity type -->
                <div class="col-sm-12 col-md-6 col-lg-6">

                    <div class="form-group">

                        <label class="mb-1" for="identityType">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.VERIFY_TYPE' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </label>


                        <div *ngIf="!customer?.identity_type">
                            <!-- bindLabel="label" -->
                            <ng-select id="identityType" [items]="identityTypeOptions" [multiple]="false" bindValue="value" [closeOnSelect]="true"
                                [(ngModel)]="profileUpdateData.identity_type" (change)="onIdentityTypeChange();" name="identityType"
                                placeholder="{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.IDENTITY_SELECT' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                                #identityType="ngModel" aria-describedby="identityTypeHelp" required>
                                <ng-template ng-label-tmp let-item="item">
                                    <div title="item">{{utilsService.getCustomerIdentityTypeLabel(item.value)}}</div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <div title="item">{{utilsService.getCustomerIdentityTypeLabel(item.value)}}</div>
                                </ng-template>
                            </ng-select>

                            <small id="identityTypeHelp" class="form-text text-danger" *ngIf="identityType.invalid">
                                <span *ngIf="identityType.errors.required">
                                    <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                                </span>
                            </small>

                        </div>


                        <div id="identityType" class="text-tourmie-darkblue-2" *ngIf="customer?.identity_type">
                            {{utilsService.getCustomerIdentityTypeLabel(customer.identity_type)}}
                        </div>

                    </div>

                </div>



                <!-- Identity Card -->
                <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="profileUpdateData.identity_type === 'identity_card'">

                    <div class="form-group">

                        <label class="mb-1" for="identityCardNumber">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.CARD_NUMBER.TITLE' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </label>


                        <div *ngIf="!customer?.identity_card_number">

                            <input type="text" class="form-control" id="identityCardNumber" name="identityCardNumber"
                                placeholder="{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.CARD_NUMBER.PLACEHOLDER' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                                #identityCardNumber="ngModel" [(ngModel)]="profileUpdateData.identity_card_number"
                                [ngClass]="{'is-invalid': identityCardNumber.invalid && (identityCardNumber.dirty || identityCardNumber.touched)}"
                                aria-describedby="identityCardNumberHelp" required>

                            <small id="identityCardNumberHelp" class="form-text text-danger" *ngIf="identityCardNumber.invalid">
                                <span *ngIf="identityCardNumber.errors.required">
                                    <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                                </span>
                            </small>

                        </div>


                        <div id="identityCardNumber" class="text-tourmie-darkblue-2" *ngIf="customer?.identity_card_number">
                            {{customer?.identity_card_number}}
                        </div>

                    </div>

                </div>



                <!-- Driving License -->
                <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="profileUpdateData.identity_type === 'driving_license'">
                    <div class="form-group">

                        <label class="mb-1" for="drivingLicenseNumber">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.DRIVER_LICENSE.TITLE' | translate | replaceTourmieStay:
                            utilsService.customDomainSettings.app_title}}
                        </label>

                        <div *ngIf="!customer?.driving_license_number">

                            <input type="text" class="form-control" id="drivingLicenseNumber" name="drivingLicenseNumber"
                                placeholder="{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.DRIVER_LICENSE.PLACEHOLDER' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                                #drivingLicenseNumber="ngModel" [(ngModel)]="profileUpdateData.driving_license_number"
                                [ngClass]="{'is-invalid': drivingLicenseNumber.invalid && (drivingLicenseNumber.dirty || drivingLicenseNumber.touched)}"
                                aria-describedby="drivingLicenseNumberHelp" required>

                            <small id="drivingLicenseNumberHelp" class="form-text text-danger" *ngIf="drivingLicenseNumber.invalid">
                                <span *ngIf="drivingLicenseNumber.errors.required">
                                    <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                                </span>
                            </small>

                        </div>

                        <div id="drivingLicenseNumber" class="text-tourmie-darkblue-2" *ngIf="customer?.driving_license_number">
                            {{customer?.driving_license_number}}
                        </div>

                    </div>
                </div>



                <!-- Passport -->
                <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="profileUpdateData.identity_type === 'passport'">

                    <div class="form-group">

                        <label class="mb-1" for="passportNumber">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.PASSPORT_NUMBER.TITLE' | translate | replaceTourmieStay:
                            utilsService.customDomainSettings.app_title}}
                        </label>

                        <div *ngIf="!customer?.passport_number">


                            <input type="text" class="form-control" id="passportNumber" name="passportNumber"
                                placeholder="{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.PASSPORT_NUMBER.PLACEHOLDER' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                                #passportNumber="ngModel" [(ngModel)]="profileUpdateData.passport_number"
                                [ngClass]="{'is-invalid': passportNumber.invalid && (passportNumber.dirty || passportNumber.touched)}"
                                aria-describedby="passportNumberHelp" required>

                            <small id="passportNumberHelp" class="form-text text-danger" *ngIf="passportNumber.invalid">
                                <span *ngIf="passportNumber.errors.required">
                                    <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                                </span>
                            </small>

                        </div>

                        <div id="passportNumber" class="text-tourmie-darkblue-2" *ngIf="customer?.passport_number">
                            {{customer?.passport_number}}
                        </div>

                    </div>
                </div>


            </div>


            <div *ngIf="profileUpdateData.identity_type && !customer?.identity_verified">

                <div class="row px-2" *ngIf="customer?.identity_docs?.length > 0">

                    <div class="col-12">

                        <div class="alert alert-info">

                            <p class="mb-0">
                                {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.ALERT_REVIEW' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                            </p>

                        </div>

                    </div>

                </div>



                <!-- Required documents per identity type -->
                <div class="row px-2">

                    <div class="col-12">

                        <div class="alert alert-tourmie-primary" *ngIf="profileUpdateData.identity_type === 'identity_card'">

                            <p class="mb-2">
                                {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.CARD_NUMBER.ALERT_VERIFY' | translate | replaceTourmieStay:
                                utilsService.customDomainSettings.app_title}}
                            </p>

                            <ol>
                                <li>{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.CARD_NUMBER.ALERT_VERIFY_STEP_1' |
                                    translate}}</li>
                                <li>{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.CARD_NUMBER.ALERT_VERIFY_STEP_2' |
                                    translate}}</li>
                                <li>{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.CARD_NUMBER.ALERT_VERIFY_STEP_3' |
                                    translate}}</li>
                            </ol>

                        </div>


                        <div class="alert alert-tourmie-primary" *ngIf="profileUpdateData.identity_type === 'passport'">

                            <p class="mb-2">
                                {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.PASSPORT_NUMBER.ALERT_VERIFY' |
                                translate}}
                            </p>

                            <ol>
                                <li>{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.PASSPORT_NUMBER.ALERT_VERIFY_STEP_1' |
                                    translate}}</li>
                                <li>{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.PASSPORT_NUMBER.ALERT_VERIFY_STEP_2' |
                                    translate}}</li>
                            </ol>

                        </div>


                        <div class="alert alert-tourmie-primary" *ngIf="profileUpdateData.identity_type === 'driving_license'">

                            <p class="mb-2">
                                {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.DRIVER_LICENSE.ALERT_VERIFY' |
                                translate}}
                            </p>

                            <ol>
                                <li>{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.DRIVER_LICENSE.ALERT_VERIFY_STEP_1' |
                                    translate}}</li>
                                <li>{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.DRIVER_LICENSE.ALERT_VERIFY_STEP_2' |
                                    translate}}</li>
                            </ol>

                        </div>

                    </div>

                </div>


                <!-- Images upload input (drop) -->
                <div class="row px-2">

                    <div class="col-12">

                        <ngx-dropzone (change)="onIdentityImageSelect($event)" [multiple]="true" accept="image/*" [maxFileSize]="imageMaxFileSize">

                            <ngx-dropzone-label>
                                {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.DROP_IMG_PLACEHOLDER' | translate | replaceTourmieStay:
                                utilsService.customDomainSettings.app_title}}
                            </ngx-dropzone-label>

                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let file of identityImages" [file]="file" [removable]="true"
                                (removed)="onIdentityImageRemove(file)">
                                <ngx-dropzone-label>


                                    <!-- {{ file.name }} -->
                                </ngx-dropzone-label>
                            </ngx-dropzone-image-preview>

                        </ngx-dropzone>

                    </div>

                </div>



                <div class="row px-2 mt-4" *ngIf="identityImages?.length > 0">

                    <div class="col-12">

                        <div class="table-responsive table-identity-images-preview-container">

                            <table class="table table-hover table-identity-images-preview" id="propertyIdentityImagesPreviewTable">

                                <thead>
                                    <tr>
                                        <!-- <th class="text-center">#</th> -->
                                        <th>

                                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.IMAGE' | translate | replaceTourmieStay:
                                            utilsService.customDomainSettings.app_title}}

                                        </th>
                                        <th>

                                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.TYPE' | translate | replaceTourmieStay:
                                            utilsService.customDomainSettings.app_title}}

                                            <app-required-field-label [type]="'asterisk_tooltip'">
                                            </app-required-field-label>
                                        </th>
                                        <th>

                                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.FILE' | translate | replaceTourmieStay:
                                            utilsService.customDomainSettings.app_title}}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr class="identity-image-preview-row" *ngFor="let image of identityImages; let i = index;">

                                        <!-- <td class="text-center">
                                            {{i+1}}
                                        </td> -->


                                        <td class="identity-image-preview-col-image">

                                            <img class="identity-image-preview" src="{{image.url}}" title="{{image.name}}">

                                        </td>


                                        <td class="identity-image-preview-col-type">

                                            <div class="form-group">

                                                <ng-select id="identityImageType" [items]="identityImageTypeOptions" [multiple]="false" bindLabel="label"
                                                    bindValue="value" [closeOnSelect]="true" [(ngModel)]="image.identity_pic_type" name="identityImageType"
                                                    #identityImageType="ngModel" aria-describedby="identityImageTypeHelp" required>
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <div title="item">{{utilsService.getIdentityImageTypeLabel(item.value)}}</div>
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item">
                                                        <div title="item">{{utilsService.getIdentityImageTypeLabel(item.value)}}</div>
                                                    </ng-template>
                                                </ng-select>

                                            </div>

                                        </td>



                                        <td class="identity-image-preview-col-filename">

                                            <span class="text-muted">
                                                {{image.name}}
                                            </span>

                                        </td>

                                    </tr>

                                </tbody>

                            </table>

                        </div>

                    </div>


                </div>



                <div class="row px-2 mt-3">

                    <div class="col-12 text-right">

                        <button type="submit" class="btn btn-tourmie-primary" [ngClass]="{'btn-block': isMobile}"
                            [disabled]="identityForm.invalid || identityImages.length === 0" [ladda]="isSubmittingIdentityData">
                            {{'GENERIC.BUTTONS.SUBMIT' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title ||
                            'Tourmie Stay'}}
                        </button>

                    </div>

                </div>

            </div>

        </form>


        <div class="row" *ngIf="customer?.identity_verified">

            <div class="col-12">

                <div class="alert alert-success">

                    <i class="fas fa-check mr-2"></i>
                    {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.VERIFICATION_CONFIRM_MSG' | translate | replaceTourmieStay:
                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}

                </div>

            </div>

        </div>

    </div>

</div>



<!-- Password -->
<div class="card shadow-none rounded-lg">

    <div class="card-body">

        <div class="row px-2">

            <div class="col-12">

                <h5 class="font-weight-600">
                    {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.CHANGE_PASSWORD' | translate | replaceTourmieStay:
                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                </h5>

            </div>

        </div>


        <form #changePasswordForm="ngForm" (ngSubmit)="submitPasswordUpdate();" autocomplete="off">

            <div class="row px-2 mt-2">

                <div class="col-sm-12 col-md-4 col-lg-4">

                    <div class="form-group">

                        <label class="mb-1" for="oldPassword">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.CURRENT_PASSWORD' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </label>

                        <input id="oldPassword" type="password" class="form-control" minlength="6" name="oldPassword"
                            placeholder="{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.YOUR_PASSWORD' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                            #oldPassword="ngModel" [(ngModel)]="profileUpdateData.old_password"
                            [ngClass]="{'is-invalid': oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)}" aria-describedby="oldPasswordHelp"
                            autocomplete="old-password" required>

                        <small id="oldPasswordHelp" class="form-text text-danger" *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)">
                            <span *ngIf="oldPassword.errors.required">
                                {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                            </span>
                            <span *ngIf="oldPassword.errors.minlength">
                                {{ 'GENERIC.LABELS.PASSWORD_LENGTH_WARNING' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                            </span>
                        </small>

                    </div>

                </div>


                <div class="col-sm-12 col-md-4 col-lg-4">

                    <div class="form-group">

                        <label class="mb-1" for="newPassword">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.NEW_PASSWORD' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </label>

                        <input id="newPassword" type="password" class="form-control" minlength="6" name="newPassword"
                            placeholder="{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.NEW_PASSWORD' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                            #newPassword="ngModel" [(ngModel)]="profileUpdateData.new_password"
                            [ngClass]="{'is-invalid': newPassword.invalid && (newPassword.dirty || newPassword.touched)}" aria-describedby="newPasswordHelp"
                            autocomplete="new-password" required>

                        <small id="newPasswordHelp" class="form-text text-danger" *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
                            <span *ngIf="newPassword.errors.required">
                                {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                            </span>
                            <span *ngIf="newPassword.errors.minlength">
                                {{ 'GENERIC.LABELS.PASSWORD_LENGTH_WARNING' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                            </span>
                        </small>

                    </div>
                </div>



                <div class="col-sm-12 col-md-4 col-lg-4">

                    <div class="form-group">

                        <label class="mb-1" for="confirmPassword">
                            {{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.CONFIRM_PASSWORD' | translate | replaceTourmieStay:
                            utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                        </label>

                        <input id="confirmPassword" type="password" class="form-control" minlength="6" name="confirmPassword"
                            placeholder="{{'PAGES.CUSTOMER_PROFILE.MENU_INFORMATION.CONFIRM_PASSWORD' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}"
                            #confirmPassword="ngModel" [(ngModel)]="profileUpdateData.new_password_confirm"
                            [ngClass]="{'is-invalid': confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)}"
                            aria-describedby="confirmPasswordHelp" autocomplete="confirm-password" required>

                        <small id="confirmPasswordHelp" class="form-text text-danger"
                            *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                            <span *ngIf="confirmPassword.errors.required">
                                {{ 'GENERIC.LABELS.REQUIRED_FIELD' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                            </span>
                            <span *ngIf="confirmPassword.errors.minlength">
                                {{ 'GENERIC.LABELS.PASSWORD_LENGTH_WARNING' | translate | replaceTourmieStay:
                                utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                            </span>
                        </small>

                    </div>
                </div>

            </div>


            <div class="row px-2">

                <div class="col-12">

                    <!-- Password -->
                    <div class="alert alert-danger"
                        *ngIf="profileUpdateData.new_password && profileUpdateData.new_password_confirm && (profileUpdateData.new_password !== profileUpdateData.new_password_confirm) && (!newPassword.errors && !confirmPassword.errors)">
                        {{ 'GENERIC.LABELS.PASSWORDS_NOT_MATCH_WARNING' | translate | replaceTourmieStay:
                        utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                    </div>

                </div>

            </div>


            <div class="row px-2">

                <div class="col-12 text-right">

                    <button type="submit" class="btn btn-tourmie-primary" [ngClass]="{'btn-block': isMobile}" [ladda]="isSubmittingPasswordUpdate" [disabled]="newPassword.invalid
                        || oldPassword.invalid
                        || confirmPassword.invalid
                        || (
                            profileUpdateData.new_password
                            && profileUpdateData.new_password_confirm
                            && (profileUpdateData.new_password !== profileUpdateData.new_password_confirm)
                            && (!newPassword.errors && !confirmPassword.errors)
                        )">
                        {{'GENERIC.BUTTONS.UPDATE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie
                        Stay'}}
                    </button>
                </div>

            </div>

        </form>

    </div>

</div>