export class Country {
    name: string;
    name_el?: string;
    name_es?: string;
    name_it?: string;
    name_fr?: string;
    name_de?: string;
    name_ca?: string;
    code: string;
    vat_prefix: string;

    constructor() {
        this.name = null;
        this.code = null;
        this.vat_prefix = null;
    }
}
