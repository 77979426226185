import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../../services/utils/utils.service';
import { StorageService } from '../../../services/storage/storage.service';
import { Customer, Booking } from '../../../models';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit, OnDestroy {

  public customer: Customer = new Customer();
  public state: 'information' | 'bookings' | 'favorites' | 'reviews' = null;

  public isMobile = false;
  private isMobileSubscription: Subscription;

  public profilePicture: File;
  public profilePictureData: any;
  public isUpdatingProfilePicture = false;


  constructor(
    private http: HttpClient,
    private router: Router,
    public utilsService: UtilsService,
    public translate: TranslateService,
    private storageService: StorageService,
  ) {

    this.isMobile = this.utilsService.isMobile;

  }



  async ngOnInit(): Promise<void> {

    if (this.utilsService.isBrowser)
      window.scrollTo(0, 0);

    if (window.location.pathname.indexOf('/bookings') !== -1)
      this.state = 'bookings';
    else if (window.location.pathname.indexOf('/favorites') !== -1)
      this.state = 'favorites';
    else if (window.location.pathname.indexOf('/reviews') !== -1)
      this.state = 'reviews';
    else
      this.state = 'information';

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    this.customer = this.storageService.getItem('customer');

  }

  ngOnDestroy(): void {

    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

  }




  // Methods for profile picture
  onProfilePictureUploadIconClick(): void {
    document.getElementById('profilePicture').click();
  }



  async onProfilePictureDeleteIconClick(): Promise<void> {

    this.isUpdatingProfilePicture = true;


    try {

      const response: any = await this.http.delete(`${environment.params.host}/api/stay/customer-profile-picture`).toPromise();

      const refreshedCustomer = await this.utilsService.refreshCustomerData();
      this.customer.profile_pic_url = refreshedCustomer.profile_pic_url;

      this.utilsService.showAlert({
        html: (await this.translate.get('PAGES.CUSTOMER_PROFILE.ALERTS.PROFILE_PIC_REMOVED').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
        icon: 'success',
        showConfirmButton: false,
        timer: 2500
      });

      this.isUpdatingProfilePicture = false;

    } catch (error) {

      if (!environment.production)
        console.log(error);

      this.isUpdatingProfilePicture = false;

    }

  }



  async onProfilePictureChange(event) {

    if (event?.target?.files && event.target.files.length > 0) {

      if (event.target.files[0].size > environment.params.imagesMaxFileSize) { // file size exceeded

        const msg = (await this.translate.get('PAGES.CUSTOMER_PROFILE.ALERTS.PROFILE_PIC_SIZE_ERROR').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title);

        this.utilsService.showAlert({
          html: `${msg} ${environment.params.imagesMaxFileSize / 1024 / 1024} MB`,
          icon: 'error'
        });

        this.profilePicture = null;

        // tslint:disable-next-line:no-string-literal
        document.getElementsByName('profilePicture')[0]['value'] = null;

        return;

      }

      this.profilePicture = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async (e) => {

        // const logoDimensionsAreValid = await this.checkLogoDimensions(e.target.result as string);


        this.isUpdatingProfilePicture = true;

        // Upload image
        try {

          const reqHeaders: HttpHeaders = new HttpHeaders();
          reqHeaders.append('Content-Type', 'multipart/form-data');

          const formData = new FormData();

          // Image to send
          if (this.profilePicture)
            formData.append('image', this.profilePicture, this.profilePicture.name);


          formData.append('customer_id', this.customer.customer_id);

          const response: any = await this.http.put(`${environment.params.host}/api/stay/customer-profile-picture`, formData, { headers: reqHeaders }).toPromise();

          const refreshedCustomer = await this.utilsService.refreshCustomerData();
          this.customer.profile_pic_url = refreshedCustomer.profile_pic_url;

          this.utilsService.showAlert({
            html: (await this.translate.get('PAGES.CUSTOMER_PROFILE.ALERTS.PROFILE_PIC_UPDATED').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title), icon: 'success', showConfirmButton: false, timer: 2500
          });

          this.isUpdatingProfilePicture = false;

          // this.router.navigate(['/'], { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['/settings/branding-assets']);
          // });

        } catch (error) {

          if (!environment.production)
            console.log(error);

          this.isUpdatingProfilePicture = false;

        }

      };
    }
    else {

      this.profilePicture = null;

      // tslint:disable-next-line:no-string-literal
      document.getElementsByName('accountLogo')[0]['value'] = null;

    }

  }

  // ./Methods for profile picture


}
