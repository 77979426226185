import { StorageService } from '../storage/storage.service';
import * as FullStory from '@fullstory/browser';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Customer } from '../../models';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';


interface FullStoryParams {
    displayName: string; // fullstory default variable
    email: string; // fullstory default variable
    customer_id: string;
    customer_fullname: string;
    customer_email: string;
    customer_verified: boolean;
    customer_city: string;
    customer_country: string;
    customer_created_at: Date;
    tourmie_stay_customer: boolean;
}

@Injectable({
    providedIn: 'root'
})

export class FullStoryService {

    public fullstory = FullStory;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private storageService: StorageService
    ) {
        if (environment.production)
            this.initialize();
    }

    initialize(): void {

        if (isPlatformBrowser(this.platformId))
            this.fullstory.init({
                orgId: environment.params.fullstory.orgId,
                host: environment.params.fullstory.host,
                namespace: environment.params.fullstory.namespace,
                debug: environment.params.fullstory.debug,
                script: environment.params.fullstory.script,
                // devMode: !environment.production
                devMode: false
            });

    }

    async identifyUser(): Promise<void> {

        try {

            const customer: Customer = await this.storageService.getItem('customer');

            if (customer?.customer_id && environment.production) {

                const fullStoryParams: FullStoryParams = {
                    displayName: `${customer.first_name} ${customer.last_name}`,
                    email: customer.email,
                    customer_id: customer.customer_id,
                    customer_fullname: `${customer.first_name} ${customer.last_name}`,
                    customer_email: `${customer.first_name} ${customer.last_name}`,
                    customer_verified: customer?.verified || null,
                    customer_created_at: customer?.created_at || null,
                    customer_city: customer?.address?.city || null,
                    customer_country: customer?.address?.country || null,
                    tourmie_stay_customer: true
                };

                // this.fullstory.identify(customer.customer_id, fullStoryParams);
                (window as any).FS.identify(customer.customer_id, fullStoryParams);

            }
            else {
                await this.anonymizeUser();
                return Promise.resolve();
            }

            return Promise.resolve();

        } catch (error) {
            return Promise.resolve();
        }

    }

    async anonymizeUser(): Promise<void> {

        try {

            // this.fullstory.anonymize();
            (window as any).FS.anonymize();

            // this.fullstory.setUserVars({});
            (window as any).FS.setUserVars({});

        } catch (error) {
            return Promise.resolve();
        }

    }


}

