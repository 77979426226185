import { Component, OnInit } from '@angular/core';
import { SeoService } from '../../services/seo/seo-service.service';
import { TranslateService } from '@ngx-translate/core';

import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit {

  constructor(
    private seo: SeoService,
    public utilsService: UtilsService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.seo.updatePageMetadata({ page: '404' });

  }

}
