<div class="container px-4">

    <div class="row mt-8" *ngIf="booking">

        <div class="col-sm-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3">

            <div class="card">

                <div class="card-body">

                    <div class="row">

                        <div class="col-12 text-center">

                            <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;">
                                <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                                <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
                                <div class="swal2-success-ring"></div>
                                <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                                <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                            </div>

                        </div>

                    </div>

                    <div class="row">

                        <div class="col-12 text-center">

                            <!-- Booking: Direct -->
                            <div *ngIf="booking?.service_booking_type === 'direct'">

                                <h5 class="mb-4">
                                    {{'PAGES.BOOKING_SUBMISSION.LABELS.RESERVATION_CONFIRMED' | translate | replaceTourmieStay:
                                    utilsService.customDomainSettings.app_title}}
                                </h5>


                                <button class="btn btn-outline-tourmie-primary" (click)="goToMyBookings()">
                                    {{'PAGES.BOOKING_SUBMISSION.LABELS.VIEW_RESERVATIONS' | translate | replaceTourmieStay:
                                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                                </button>

                            </div>

                            <!-- Booking: On request -->
                            <div *ngIf="booking?.service_booking_type === 'request'">

                                <h5>
                                    {{'PAGES.BOOKING_SUBMISSION.LABELS.RESERVATION_SUBMITTED' | translate | replaceTourmieStay:
                                    utilsService.customDomainSettings.app_title}}
                                </h5>

                                <p class="mb-4 text-muted">
                                    {{'PAGES.BOOKING_SUBMISSION.LABELS.PAYMENT_ON_HOLD' | translate | replaceTourmieStay:
                                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                                </p>

                                <button class="btn btn-outline-tourmie-primary" (click)="goToMyBookings()">
                                    {{'PAGES.BOOKING_SUBMISSION.LABELS.VIEW_RESERVATIONS' | translate | replaceTourmieStay:
                                    utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
                                </button>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>