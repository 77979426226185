import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { LaddaModule } from 'angular2-ladda';
import { NgpSortModule } from 'ngp-sort-pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { FullCalendarModule } from '@fullcalendar/angular';

import { TranslateModule } from '@ngx-translate/core';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RatingModule } from 'ngx-bootstrap/rating';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';


import { RequiredFieldLabelComponent } from './required-field-label/required-field-label.component';
import { LanguageSwitchComponent } from './language-switch/language-switch.component';
import { MoveToTopComponent } from './move-to-top/move-to-top.component';
import { NavbarUserItemComponent } from './navbar-user-item/navbar-user-item.component';
import { PropertyItemListComponent } from './property-item-list/property-item-list.component';
import { PlaceAutoCompleteComponent } from './place-auto-complete/place-auto-complete.component';
import { GuestsDropdownFilterComponent } from './guests-dropdown-filter/guests-dropdown-filter.component';

import { LoginModalComponent } from './login-form-modal/login-modal.component';
import { GoogleAnalyticsEventDirectiveDirective } from '../services/google-analytics/google-analytics-event-directive.directive';
import { BookingSubmissionPageComponent } from './booking-submission-page/booking-submission-page.component';

import { TenantPromoModalComponent } from './tenant-promo-modal/tenant-promo-modal.component';
import { GlobalNotificationsComponent } from './global-notifications/global-notifications.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
    declarations: [
        LanguageSwitchComponent,
        MoveToTopComponent,
        NavbarUserItemComponent,
        RequiredFieldLabelComponent,
        GuestsDropdownFilterComponent,
        PlaceAutoCompleteComponent,
        PropertyItemListComponent,
        BookingSubmissionPageComponent,
        LoginModalComponent,
        GoogleAnalyticsEventDirectiveDirective,
        TenantPromoModalComponent,
        GlobalNotificationsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        PipesModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxSpinnerModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        LaddaModule,
        FullCalendarModule,
        DataTablesModule,
        NgpSortModule,
        NgxIntlTelInputModule,
        TranslateModule,
        RatingModule.forRoot(),
        NgxImageGalleryModule,
        NgxYoutubePlayerModule.forRoot(),
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxDropzoneModule
    ],
    exports: [
        LanguageSwitchComponent,
        MoveToTopComponent,
        NavbarUserItemComponent,
        RequiredFieldLabelComponent,
        GuestsDropdownFilterComponent,
        PlaceAutoCompleteComponent,
        PropertyItemListComponent,
        BookingSubmissionPageComponent,
        LoginModalComponent,
        GoogleAnalyticsEventDirectiveDirective,
        GlobalNotificationsComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
