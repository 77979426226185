<div class="row mt-3 justify-content-center">

    <div class="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4 col-xxl-3 px-4 px-sm-0">

        <div class="register-logo mb-3">
            <a [routerLink]="['/']">
                <img [src]="utilsService.customDomainSettings?.logo?.dark" alt="TourmieStay" title="TourmieStay" class="image-logo">
            </a>
        </div>

        <div class="card registration-card">

            <div class="card-header text-center py-3">
                <h4 class="font-size-22 mb-1">
                    {{ 'PAGES.REGISTRATION.LABELS.STEP_TITLE_USER_DETAILS' | translate }}
                </h4>
                <span *ngIf="currentStep === 0" class="text-muted font-size-15">
                    {{currentStep+1}}. {{ 'PAGES.REGISTRATION.LABELS.STEP_SUBTITLE_USER_DETAILS' | translate }}
                </span>
                <span *ngIf="currentStep === 1" class="text-muted font-size-15">
                    {{currentStep+1}}. {{ 'PAGES.REGISTRATION.LABELS.STEP_SUBTITLE_ADDITIONAL_INFORMATION' |
                    translate}}
                </span>

            </div>

            <div class="card-body register-card-body">

                <!-- User details -->
                <div *ngIf="currentStep === 0">
                    <ng-container [ngTemplateOutlet]="stepUserDetails"></ng-container>
                </div>

                <!-- Account details -->
                <div *ngIf="currentStep === 1">
                    <ng-container [ngTemplateOutlet]="stepAccountDetails"></ng-container>
                </div>


            </div>

        </div>


        <div class="container pb-5">

            <div class="row text-center mt-2" [hidden]="currentStep != 0">

                <div class="col-lg-12  text-center">
                    <span>
                        {{ 'PAGES.REGISTRATION.LABELS.DO_YOU_ALREADY_HAVE_ACCOUNT' | translate }}
                    </span>
                </div>

                <div class="col-lg-12 text-center">

                    <a [routerLink]="[ '/login' ]" [queryParams]="{ role: 'landlord' }" class="text-tourmie-secondary">
                        {{ 'PAGES.REGISTRATION.LABELS.CLICK_HERE_TO_LOGIN' | translate }}
                    </a>

                </div>

            </div>

        </div>

    </div>

</div>



<!-- Step 1: User details -->
<ng-template #stepUserDetails>

    <form #userDetailsForm="ngForm" autocomplete="off">

        <div class="row">

            <!-- First Name -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <div class="form-group mb-3">

                    <label class="mb-1" for="firstName">
                        {{ 'PAGES.REGISTRATION.INPUTS.USER_FIRST_NAME.LABEL' | translate }}
                        <app-required-field-label [type]="'asterisk_tooltip'"></app-required-field-label>
                    </label>


                    <input type="text" class="form-control" id="firstName" name="firstName"
                        placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_FIRST_NAME.PLACEHOLDER' | translate }}" #firstName="ngModel"
                        [(ngModel)]="registrationData.user.first_name" [ngClass]="{'is-invalid': firstName.invalid && (firstName.dirty || firstName.touched)}"
                        (change)="onFirstnameChange();" aria-describedby="firstNameHelp" required>

                    <small id="firstNameHelp" class="form-text text-danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                        <span *ngIf="firstName.errors.required">
                            <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                        </span>
                    </small>

                </div>

            </div>


            <!-- Last Name -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <div class="form-group mb-3">

                    <label class="mb-1" for="lastName">
                        {{ 'PAGES.REGISTRATION.INPUTS.USER_LAST_NAME.LABEL' | translate}}
                        <app-required-field-label [type]="'asterisk_tooltip'"></app-required-field-label>
                    </label>


                    <input type="text" class="form-control" id="lastName" name="lastName"
                        placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_LAST_NAME.PLACEHOLDER' | translate }}" #lastName="ngModel"
                        [(ngModel)]="registrationData.user.last_name" [ngClass]="{'is-invalid': lastName.invalid && (lastName.dirty || lastName.touched)}"
                        (change)="onLastnameChange();" aria-describedby="lastNameHelp" required>

                    <small id="lastNameHelp" class="form-text text-danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                        <span *ngIf="lastName.errors.required">
                            <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                        </span>
                    </small>

                </div>

            </div>


            <!-- Email -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <div class="form-group mb-3">

                    <label class="mb-1" for="email">
                        {{ 'PAGES.REGISTRATION.INPUTS.USER_EMAIL.LABEL' | translate}}
                        <app-required-field-label [type]="'asterisk_tooltip'"></app-required-field-label>
                    </label>


                    <input type="email" class="form-control" id="email" name="email" pattern="[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,50}$"
                        placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_EMAIL.PLACEHOLDER' | translate }}" #email="ngModel"
                        [(ngModel)]="registrationData.user.email" [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}"
                        aria-describedby="emailHelp" required>

                    <small id="emailHelp" class="form-text text-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                        {{ 'GENERIC.LABELS.ENTER_A_VALID_EMAIL' | translate}}
                    </small>

                </div>

            </div>


            <!-- Password -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <div class="form-group mb-3">

                    <label class="mb-1" for="password">
                        {{ 'PAGES.REGISTRATION.INPUTS.USER_PASSWORD.LABEL' | translate}}
                        <app-required-field-label [type]="'asterisk_tooltip'"></app-required-field-label>
                    </label>


                    <input type="password" class="form-control" id="password" minlength="6" name="password"
                        placeholder="{{ 'PAGES.REGISTRATION.INPUTS.USER_PASSWORD.PLACEHOLDER' | translate }}" #password="ngModel"
                        [(ngModel)]="registrationData.user.password" (change)="onPasswordChange()"
                        [ngClass]="{'is-invalid': password.invalid && (password.dirty || password.touched)}" aria-describedby="passwordHelp"
                        autocomplete="new-password" required>

                    <small id="passwordHelp" class="form-text text-danger" *ngIf="password.invalid && (password.dirty || password.touched)">
                        <span *ngIf="password.errors.required">
                            <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                        </span>
                        <span *ngIf="password.errors.minlength">
                            {{ 'GENERIC.LABELS.PASSWORD_LENGTH_WARNING' | translate}}
                        </span>
                    </small>

                </div>

            </div>

        </div>




        <!-- Form buttons -->
        <div class="row">

            <div class="col-12 pt-3 d-flex justify-content-end">

                <button type="button" class="btn btn-tourmie-secondary" (click)="nextStep()" [ladda]="isCheckingCustomerEmail"
                    [disabled]="userDetailsForm.invalid">
                    {{ 'GENERIC.BUTTONS.CONTINUE' | translate }} &nbsp;<i class="fas fa-arrow-right"></i>
                </button>

            </div>

        </div>

    </form>

</ng-template>



<!-- Step 2: Account details -->
<ng-template #stepAccountDetails>

    <form #accountDetailsForm="ngForm" autocomplete="off">

        <div class="row">

            <!-- Account category -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <div class="form-group">

                    <label class="mb-1" for="accountCategory">
                        {{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_CATEGORY.LABEL' | translate }}
                        <app-required-field-label [type]="'asterisk_tooltip'"></app-required-field-label>
                    </label>

                    <ng-select id="accountCategory" placeholder="{{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_CATEGORY.PLACEHOLDER' | translate }}"
                        [items]="accountCategories" [multiple]="false" bindLabel="label" bindValue="value" [closeOnSelect]="true"
                        [(ngModel)]="registrationData.account.category" (change)="onAccountCategoryChange()" name="accountCategory" #accountCategory="ngModel"
                        aria-describedby="accountCategoryHelp">
                    </ng-select>

                    <small id="accountCategoryHelp" class="form-text text-danger"
                        *ngIf="accountCategory.invalid && (accountCategory.dirty || accountCategory.touched)">
                        <span *ngIf="accountCategory.errors.required">
                            <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                        </span>
                    </small>
                </div>

            </div>

        </div>



        <div class="row" [hidden]="!registrationData.account.category">

            <!-- Account business name -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="registrationData?.account?.type === 'business'">

                <div class="form-group mb-3">

                    <label class="mb-1" for="businessName">
                        {{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_NAME.LABEL' | translate }}
                        <app-required-field-label [type]="'asterisk_tooltip'"></app-required-field-label>
                    </label>


                    <input type="text" class="form-control" id="businessName" name="businessName"
                        placeholder="{{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_NAME.PLACEHOLDER' | translate }}" #businessName="ngModel"
                        [(ngModel)]="registrationData.account.business_name" aria-describedby="businessNameHelp"
                        [ngClass]="{'is-invalid': businessName.invalid && (businessName.dirty || businessName.touched)}" required>

                    <small id="businessNameHelp" class="form-text text-danger" *ngIf="businessName.invalid && (businessName.dirty || businessName.touched)">
                        <span *ngIf="businessName.errors.required">
                            <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                        </span>
                    </small>

                </div>
            </div>



            <!-- Account title -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <div class="form-group mb-3">

                    <label class="mb-1" for="accountTitle">
                        {{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_TITLE.LABEL' | translate }}
                        <app-required-field-label [type]="'asterisk_tooltip'"></app-required-field-label>
                    </label>


                    <input type="text" class="form-control" id="accountTitle" name="accountTitle"
                        placeholder="{{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_BUSINESS_TITLE.PLACEHOLDER' | translate  }}" #accountTitle="ngModel"
                        [(ngModel)]="registrationData.account.title" aria-describedby="accountTitleHelp"
                        [ngClass]="{'is-invalid': accountTitle.invalid && (accountTitle.dirty || accountTitle.touched)}" required>

                    <small id="accountTitleHelp" class="form-text text-danger" *ngIf="accountTitle.invalid && (accountTitle.dirty || accountTitle.touched)">
                        <span *ngIf="accountTitle.errors.required">
                            <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                        </span>
                    </small>

                </div>

            </div>



            <!-- Country -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <div class="form-group mb-3">

                    <label class="mb-1" for="addressCountry">
                        {{ 'GENERIC.INPUTS.ADDRESS.COUNTRY.LABEL' | translate }}
                        <app-required-field-label [type]="'asterisk_tooltip'"></app-required-field-label>
                    </label>

                    <ng-select id="addressCountry" placeholder="{{ 'GENERIC.INPUTS.ADDRESS.COUNTRY.PLACEHOLDER' | translate }}" [items]="utilsService.countries"
                        [multiple]="false" bindValue="code" [closeOnSelect]="true" [searchFn]="utilsService.multilingualDropdownSearchFunction"
                        [(ngModel)]="registrationData.user.country" (change)="onAddressCountryChange();" name="addressCountry" #addressCountry="ngModel"
                        aria-describedby="addressCountryHelp" required>

                        <ng-template ng-label-tmp let-item="item">
                            <span>{{ item['name_' + utilsService.currentLanguage] || item?.name }}</span>
                        </ng-template>

                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <span>{{ item['name_' + utilsService.currentLanguage] || item?.name }}</span>
                        </ng-template>

                    </ng-select>

                    <small id="addressCountryHelp" class="form-text text-danger"
                        *ngIf="addressCountry.invalid && (addressCountry.dirty || addressCountry.touched)">
                        <span *ngIf="addressCountry.errors.required">
                            <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                        </span>
                    </small>

                </div>

            </div>



            <!-- TAX/VAT ID -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="registrationData.account.type === 'business'">

                <div class="form-group mb-3">

                    <label class="mb-1" for="vatID">
                        {{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_TAX_ID.LABEL' | translate }}
                        <app-required-field-label [type]="'asterisk_tooltip'"></app-required-field-label>
                    </label>


                    <input type="text" class="form-control" id="vatID" name="vatID"
                        placeholder="{{ 'PAGES.REGISTRATION.INPUTS.ACCOUNT_TAX_ID.LABEL' | translate }}"
                        [ngClass]="{'is-invalid': vatID.invalid && (vatID.dirty || vatID.touched)}" #vatID="ngModel"
                        [(ngModel)]="registrationData.account.tax_id" (change)="onVatNumberChange();" aria-describedby="vatIDHelp" required>

                    <small id="vatIDHelp" class="form-text text-danger" *ngIf="vatID.invalid && (vatID.dirty || vatID.touched)">
                        <span *ngIf="vatID.errors.required">
                            <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                        </span>
                    </small>

                </div>

            </div>



            <!-- Phone -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <div class="form-group mb-3 mobile-phone-container">

                    <label class="mb-1" for="mobileTelephone">
                        {{ 'PAGES.REGISTRATION.INPUTS.USER_MOBILE_TELEPHONE.LABEL' | translate }}
                        <app-required-field-label [type]="'asterisk_tooltip'"></app-required-field-label>
                    </label>


                    <ngx-intl-tel-input
                        [cssClass]="mobileTelephone.invalid && (mobileTelephone.dirty && mobileTelephone.touched) ? 'form-control is-invalid' : 'form-control'"
                        [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
                        [selectedCountryISO]="CountryISO.Greece" [maxLength]="24" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                        [separateDialCode]="true" id="mobileTelephone" name="mobileTelephone" #mobileTelephone="ngModel" [(ngModel)]="userMobilePhone"
                        (ngModelChange)="updateUserMobileNumber();" aria-describedby="mobileTelephoneHelp" required>
                    </ngx-intl-tel-input>

                    <small id="mobileTelephoneHelp" class="form-text text-danger"
                        *ngIf="mobileTelephone.invalid && (mobileTelephone.dirty || mobileTelephone.touched)">
                        <span *ngIf="mobileTelephone.errors.required && mobileTelephone.touched">
                            <app-required-field-label [type]="'input_invalid'"></app-required-field-label>
                        </span>
                    </small>

                </div>

            </div>

        </div>



        <!-- Terms checkboxes -->
        <div class="row mt-3" [hidden]="!registrationData.account.category">

            <!-- Terms & Policy checkbox -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <div class="form-group">

                    <div class="icheck-tourmie-secondary">

                        <input type="checkbox" id="agreePrivacyPolicy" name="PrivacyPolicy" value="agree" #agreePrivacyPolicy="ngModel"
                            [(ngModel)]="privacyPolicyAgreed" required>

                        <label class="font-size-14" for="agreePrivacyPolicy">
                            {{ 'PAGES.REGISTRATION.LABELS.I_AGREE_TO_TOURMIE_PRIVACY_POLICY_PREFIX' | translate | replaceTourmieStay:
                            utilsService.customDomainSettings.app_title}}<a [routerLink]="[ '/privacy-policy' ]" target="_blank"> {{
                                'PAGES.REGISTRATION.LABELS.PRIVACY_POLICY' |
                                translate}} </a>
                        </label>

                        <small id="agreePrivacyPolicyHelp" class="form-text text-danger"
                            *ngIf="!privacyPolicyAgreed && (agreePrivacyPolicy.dirty || agreePrivacyPolicy.touched)">
                            <span>
                                {{ 'PAGES.REGISTRATION.LABELS.MUST_AGREE_PRIVACY_POLICY' | translate | replaceTourmieStay:
                                utilsService.customDomainSettings.app_title}}
                            </span>
                        </small>

                    </div>
                </div>

                <div class="form-group">

                    <div class="icheck-tourmie-secondary mt-3">

                        <input type="checkbox" id="agreeTerms" name="terms" value="agree" #agreeTerms="ngModel" [(ngModel)]="termsAgreed" required>

                        <label class="font-size-14" for="agreeTerms">
                            {{ 'PAGES.REGISTRATION.LABELS.I_AGREE_TO_TOURMIE_TERMS_OF_SERVICE_PREFIX' | translate | replaceTourmieStay:
                            utilsService.customDomainSettings.app_title}}
                            <a href="javascript:void(0)" (click)="getTermsOfService(termsOfServiceModal)">
                                {{
                                'PAGES.REGISTRATION.LABELS.I_AGREE_TO_TOURMIE_TERMS_OF_SERVICE_SUFFIx' | translate | replaceTourmieStay:
                                utilsService.customDomainSettings.app_title}}
                            </a>
                        </label>

                        <small id="agreeTermsHelp" class="form-text text-danger" *ngIf="!termsAgreed && (agreeTerms.dirty || agreeTerms.touched)">
                            <span>
                                {{ 'PAGES.REGISTRATION.LABELS.MUST_AGREE_TERMS_OF_SERVICE' | translate | replaceTourmieStay:
                                utilsService.customDomainSettings.app_title}}
                            </span>
                        </small>

                    </div>

                </div>

            </div>

        </div>



        <!-- Form buttons -->
        <div class="row">

            <div class="col-12 pt-3 d-flex justify-content-between">

                <button type="button" class="btn btn-default" (click)="previousStep()"> <i class="fas fa-arrow-left"></i>
                    &nbsp; {{
                    'GENERIC.BUTTONS.BACK' | translate }}
                </button>

                <button type="button" role="button" class="btn btn-tourmie-secondary" (click)="submitForm()" [ladda]="isSubmitting"
                    [disabled]="accountDetailsForm.invalid || !privacyPolicyAgreed || !termsAgreed">
                    {{ 'PAGES.REGISTRATION.BUTTONS.REGISTER' | translate }}
                </button>

            </div>

        </div>

    </form>

</ng-template>





<ng-template #termsOfServiceModal>

    <div class="modal-header">

        <h5 class="modal-title">

            {{'PAGES.TERMS_OF_SERVICE.TITLE' | translate }}

        </h5>
        <span class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true"><i class="fas fa-times fa-xs"></i></span>
        </span>
    </div>

    <div class="modal-body">

        <div class="row">

            <div class="col-12">

                <div [innerHTML]="terms | safe: 'html'"></div>

            </div>

        </div>

    </div>
</ng-template>