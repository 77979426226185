import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';


@Component({
  selector: 'app-move-to-top',
  templateUrl: './move-to-top.component.html',
  styleUrls: ['./move-to-top.component.scss']
})
export class MoveToTopComponent {

  constructor(
    @Inject(PLATFORM_ID) private platformId,
  ) { }

  public windowScrolled = false;

  @HostListener('window:scroll')
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100)
      this.windowScrolled = true;

    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10)
      this.windowScrolled = false;

  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

      if (currentScroll > 0)

        if (isPlatformBrowser(this.platformId)) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - (currentScroll / 8));
        }

    })();

  }
}
