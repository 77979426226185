import { Address } from './Address';
import { ViesData } from './VIES';

export class Account {

    account_id?: string;
    title?: string;
    type?: AccountTypeValue;
    slug?: string;
    created_at?: Date;

    // extra
    email?: string;
    phone?: string;

    // added 9/10/2021
    tax_id?: string;
    business_name?: string;
    address_city?: string;
    address_country?: string;
    address_postal_code?: string;
    address_state?: string;
    address_street?: string;

    // added 2024-10-31
    is_accommodation_provider?: boolean;
    is_tourmie_stay_landlord?: boolean;
    category?: AccountCategoryValue;
    accommodation_category?: AccountAccommodationCategoryValue;
    provider_type?: 'accommodation' | 'service' | string[];
    subscription_properties_max?: number;
    tax_authority?: string;
    address?: Address;
    vies_data?: ViesData | string; // business data from EU VIES
    contact_person: string;
    representative: string;
    website: string;



    constructor(props?: Account) {

        this.account_id = props?.account_id || null;
        this.title = props?.title || null;
        this.type = props?.type || null;
        this.slug = props?.slug || null;
        this.created_at = props?.created_at || null;
        this.email = props?.email || null;
        this.phone = props?.phone || null;
        this.tax_id = props?.tax_id || null;
        this.business_name = props?.business_name || null;
        this.address_city = props?.address_city || null;
        this.address_country = props?.address_country || null;
        this.address_postal_code = props?.address_postal_code || null;
        this.address_state = props?.address_state || null;
        this.address_street = props?.address_street || null;

        this.is_accommodation_provider = props?.is_accommodation_provider ? true : false;
        this.is_tourmie_stay_landlord = props?.is_tourmie_stay_landlord ? true : false;
        this.category = props?.category || null;
        this.accommodation_category = props?.accommodation_category || null;
        this.provider_type = props?.provider_type || null;
        this.subscription_properties_max = props?.subscription_properties_max || null;
        this.tax_authority = props?.tax_authority || null;
        this.address = new Address(props?.address || null);
        this.vies_data = props?.vies_data || null;
        this.contact_person = props?.contact_person || null;
        this.representative = props?.representative || null;
        this.website = props?.website || null;

    }

}


export type AccountTypeValue = 'business' | 'individual';
export type AccountCategoryValue = 'individual_host' | 'professional_host' | 'hotel' | 'car_rental_provider' | 'transfer_provider' | 'experience_provider' | 'food_provider';
export type AccountAccommodationCategoryValue = 'apartment' | 'villa' | 'hotel' | 'boutique_hotel';


export interface AccountCategory {
    label: string;
    label_el?: string;
    label_es?: string;
    label_it?: string;
    label_fr?: string;
    label_de?: string;
    label_ca?: string;
    description: string;
    description_el?: string;
    description_es?: string;
    description_it?: string;
    description_fr?: string;
    description_de?: string;
    description_ca?: string;
    value: AccountCategoryValue;
    image: string;
    types: string[]; // accommodation or service
    selected?: boolean;
}
