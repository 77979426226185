import { environment } from '../../../environments/environment';
import { UtilsService } from '../../services/utils/utils.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

interface TempGuestParams {
  adults: number;
  children: number;
  infants: number;
} // Used as temporary variables in Guests modal, will update Filters only when user clicks apply

@Component({
  selector: 'app-guests-dropdown-filter',
  templateUrl: './guests-dropdown-filter.component.html',
  styleUrls: ['./guests-dropdown-filter.component.scss']
})
export class GuestsDropdownFilterComponent implements OnInit, OnDestroy {

  public isMobile = false;
  private isMobileSubscription: Subscription;

  private clearSubscription: Subscription;

  public tempGuestFilter: TempGuestParams = {
    adults: 1,
    children: 0,
    infants: 0,
  }; // default temp variables for guests modal

  @Input() adults: number;
  @Input() children: number;
  @Input() infants: number;
  @Input() page: string;

  @Input() propertyAdults?: number;
  @Input() propertyChildren?: number;
  @Input() propertyInfants?: number;

  public hasSelection: boolean;
  public total_guests: number;

  public maxAdults: number;
  public minAdults: number = environment.params.filterParams.minAdults;

  public maxChildren: number;
  public minChildren: number = environment.params.filterParams.minChildren;

  public maxInfants: number;
  public minInfants: number = environment.params.filterParams.minInfants;


  constructor(
    public translate: TranslateService,
    private pubSub: NgxPubSubService,
    private utilsService: UtilsService,
  ) {
    this.isMobile = this.utilsService.isMobile;
  }


  ngOnInit(): void {

    if (this.propertyAdults === 0)
      this.maxAdults = 0;
    else
      this.maxAdults = this.propertyAdults || environment.params.filterParams.maxAdults;

    if (this.propertyChildren === 0)
      this.maxChildren = 0;
    else
      this.maxChildren = this.propertyChildren || environment.params.filterParams.maxChildren;

    if (this.propertyInfants === 0)
      this.maxInfants = 0;
    else
      this.maxInfants = this.propertyInfants || environment.params.filterParams.maxInfants;


    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    this.clearSubscription = this.pubSub.subscribe('clearAllFilters', () => {
      this.hasSelection = false;
      this.adults = this.minAdults;
      this.children = this.minChildren;
      this.infants = this.minInfants;
      this.total_guests = this.adults + this.children + this.infants;
    });

    if (this.adults >= 2 || this.children >= 1 || this.infants >= 1)
      this.hasSelection = true;

    this.total_guests = this.adults + this.children + this.infants;
  }

  ngOnDestroy(): void {
    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

    if (this.clearSubscription)
      this.clearSubscription.unsubscribe();
  }

  openGuestsFilter(): void {
    this.tempGuestFilter.adults = this.adults;
    this.tempGuestFilter.children = this.children;
    this.tempGuestFilter.infants = this.infants;
  } // Sets temp guest values to current filter values


  applyGuestFilter(): void {
    this.adults = this.tempGuestFilter.adults;
    this.children = this.tempGuestFilter.children;
    this.infants = this.tempGuestFilter.infants;

    this.total_guests = this.adults + this.children + this.infants;

    if (this.adults >= 2 || this.children >= 1 || this.infants >= 1)
      this.hasSelection = true;
    else
      this.hasSelection = false;

    const guestsFilter = {
      adults: this.adults,
      children: this.children,
      infants: this.infants
    };

    this.pubSub.publishEvent('updateGuestsFilter', guestsFilter);
  } // Applies temp values to actual filter values


  clearGuestsFilter(): void {
    // this.tempGuestFilter = {
    //   adults: 1,
    //   children: 0,
    //   infants: 0,
    // };

    // <<<<<< new behavior >>>>>>
    this.adults = this.minAdults;
    this.children = this.minChildren;
    this.infants = this.minInfants;
    this.total_guests = this.adults + this.children + this.infants;
    this.hasSelection = false;
    const guestsFilter = {
      adults: this.adults,
      children: this.children,
      infants: this.infants
    };
    this.pubSub.publishEvent('updateGuestsFilter', guestsFilter);
  } // Resets but doesnt apply default temp guest values


  incrementPersons(personType: string): void {


    if (personType === 'adults') {
      this.tempGuestFilter.adults += 1;
      return;
    }


    if (personType === 'children') {
      this.tempGuestFilter.children += 1;
      return;
    }

    if (personType === 'infants') {
      this.tempGuestFilter.infants += 1;
      return;
    }
  } // Increase temporary values in Guests Dropdown


  decrementPersons(personType: string): void {
    if (personType === 'adults') {
      this.tempGuestFilter.adults -= 1;
      return;
    }


    if (personType === 'children') {
      this.tempGuestFilter.children -= 1;
      return;
    }

    if (personType === 'infants') {
      this.tempGuestFilter.infants -= 1;
      return;
    }
  } // Decrease temporary values in Guests Dropdown

}
