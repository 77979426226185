import { Country } from './Country';

export interface VatCheckResponse {
    isValid: boolean;
    country: Country;
}

export class ViesData {
    vatNumber: string;
    valid: boolean;
    countryCode: string;
    businessName: string;
    businessAddress: string;
    requestedAt: Date;

    constructor() {
        this.vatNumber = null;
        this.valid = false;
        this.countryCode = null;
        this.businessName = null;
        this.businessAddress = null;
        this.requestedAt = null;
    }
}
