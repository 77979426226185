import { environment } from '../../../environments/environment';
import { StorageService } from '../../services/storage/storage.service';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit {

  @Input() onlyFlag: boolean;
  @Input() hideLabel: boolean;

  // public appLanguages = environment.params.appLanguages;
  public appLanguages = environment.params.appLanguages;
  public appLanguage: 'en' | 'el' = 'en';



  constructor(
    private utilsService: UtilsService,
    private storageService: StorageService,
    private ga: GoogleAnalyticsService,
    public translate: TranslateService,
    private pubSub: NgxPubSubService
  ) { }

  ngOnInit(): void {

    this.initializeAppLanguages();

    this.pubSub.subscribe('environmentFetched', () => {
      this.initializeAppLanguages();
    });

  }


  initializeAppLanguages(): void {

    this.appLanguages = [];

    for (const language of environment.params.appLanguages)
      if (this.utilsService.customDomainSettings.supported_languages.includes(language.code))
        this.appLanguages.push(language);


    const savedLanguage = this.storageService.getItem('appLanguage');

    if (savedLanguage)
      this.appLanguage = savedLanguage;

  }


  onLanguageChange() {
    if (!this.appLanguage)
      this.appLanguage = 'en';

    this.pubSub.publishEvent('changeLanguage', this.appLanguage);

    this.ga.sendEvent('change_language', { language: this.appLanguage });

  }

}
