import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../services/utils/utils.service';
import { StorageService } from '../../services/storage/storage.service';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from '../../models';

@Component({
  selector: 'app-navbar-user-item',
  templateUrl: './navbar-user-item.component.html',
  styleUrls: ['./navbar-user-item.component.scss']
})
export class NavbarUserItemComponent implements OnInit, OnDestroy {

  @Input() bgChange: boolean;

  public customer: Customer = new Customer();
  private customerUpdatedSubscription: Subscription;

  constructor(
    public utilsService: UtilsService,
    private storageService: StorageService,
    private pubSub: NgxPubSubService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.customer = this.storageService.getItem('customer');

    this.customerUpdatedSubscription = this.pubSub.subscribe('customerDataUpdated', async (customer: Customer) => {

      this.customer = customer;

    });

  }

  ngOnDestroy(): void {

    if (this.customerUpdatedSubscription)
      this.customerUpdatedSubscription.unsubscribe();

  }

}
