export const booking_channels = [
    {
        id: 100,
        value: 'tourmie_concierge',
        label: 'Tourmie Concierge',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'service',
        has_booking_reference_id: 0
    },
    {
        id: 200,
        value: 'tourmie_stay',
        label: 'Tourmie Stay',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 300,
        value: 'airbnb.com',
        label: 'Airbnb.com',
        source: 'default',
        smoobu_id: 74,
        smoobu_name: 'Airbnb',
        syncbnb_id: 2,
        syncbnb_name: 'Airbnb',
        hostaway_id: 2018,
        hostaway_name: 'airbnbOfficial',
        hotelavailabilities_id: 28,
        hotelavailabilities_name: 'Airbnb',
        webhotelier_id: 265,
        webhotelier_name: 'Airbnb',
        rentability_id: 11,
        rentability_name: 'Airbnb',
        color_background: '#ff5a5f',
        color_text: '#ffffff',
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 400,
        value: 'booking.com',
        label: 'Booking.com',
        source: 'default',
        smoobu_id: 14,
        smoobu_name: 'Booking.com',
        syncbnb_id: 3,
        syncbnb_name: 'Booking.com',
        hostaway_id: 2005,
        hostaway_name: 'bookingcom',
        hotelavailabilities_id: 1,
        hotelavailabilities_name: 'Booking.com',
        webhotelier_id: 171,
        webhotelier_name: 'Booking.com',
        rentability_id: 3,
        rentability_name: 'Booking.com',
        color_background: '#003b95',
        color_text: '#ffffff',
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 500,
        value: 'business_website',
        label: 'My Website',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 600,
        value: 'vrbo',
        label: 'VRBO',
        source: 'default',
        smoobu_id: 28,
        smoobu_name: 'VRBO',
        syncbnb_id: 8,
        syncbnb_name: 'VRBO',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 264,
        webhotelier_name: 'Vrbo',
        rentability_id: 32,
        rentability_name: 'VRBO',
        color_background: '#1C4695',
        color_text: '#ffffff',
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 700,
        value: 'expedia',
        label: 'Expedia',
        source: 'default',
        smoobu_id: 18,
        smoobu_name: 'Expedia',
        syncbnb_id: 27,
        syncbnb_name: 'Expedia',
        hostaway_id: 2007,
        hostaway_name: 'expedia',
        hotelavailabilities_id: 2,
        hotelavailabilities_name: 'Expedia',
        webhotelier_id: 172,
        webhotelier_name: 'Expedia',
        rentability_id: 27,
        rentability_name: 'Expedia',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 800,
        value: 'phone_call',
        label: 'Phone Call',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: -2,
        webhotelier_name: 'Telephone',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 801,
        value: 'email',
        label: 'Email',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: -1,
        webhotelier_name: 'E-mail',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 802,
        value: 'email',
        label: 'Newsletter',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 484,
        webhotelier_name: 'Newsletter',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 900,
        value: 'other',
        label: 'Other',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 1000,
        rentability_name: 'Other',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 901,
        value: 'shortstayconference',
        label: 'ShortStay',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1000,
        value: 'smoobu',
        label: 'Smoobu',
        source: 'default',
        smoobu_id: null,
        smoobu_name: 'Smoobu',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 1100,
        value: 'syncbnb',
        label: 'Hosthub',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: 'Hosthub',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 1110,
        value: 'hostaway',
        label: 'Hostaway',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2013,
        hostaway_name: 'bookingengine',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1120,
        value: 'ha_booking_engine',
        label: 'Hotel Availabilities',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 26,
        hotelavailabilities_name: 'HA Booking Engine',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1121,
        value: 'primalres_booking_engine',
        label: 'primalRES Booking Engine',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 384,
        webhotelier_name: 'primalRES Booking Engine',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1122,
        value: 'lefkada_rentals',
        label: 'Lefkada-Rentals',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1123,
        value: 'villasthalassa',
        label: 'VillasThalassa',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1124,
        value: 'ionianholidays',
        label: 'IonianHolidays',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1125,
        value: 'webhotelier',
        label: 'WebHotelier',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1126,
        value: 'bookingsync',
        label: 'BookingSync',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1127,
        value: 'lodgify',
        label: 'Lodgify',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1128,
        value: 'rentalwise',
        label: 'RentalWise',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1129,
        value: 'kretaeiendom',
        label: 'Kreta Eiendom',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1130,
        value: 'rentability',
        label: 'Rentability',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1131,
        value: 'hoteliga',
        label: 'Hoteliga',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1200,
        value: '9flats',
        label: '9Flats',
        source: 'smoobu',
        smoobu_id: 16,
        smoobu_name: '9Flats',
        syncbnb_id: 24,
        syncbnb_name: '9flats',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 10,
        rentability_name: '9 Flats',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1300,
        value: 'abritel',
        label: 'Abritel',
        source: 'smoobu',
        smoobu_id: 46,
        smoobu_name: 'Abritel / HomeAway',
        syncbnb_id: 59,
        syncbnb_name: 'Abritel.fr',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 30,
        rentability_name: 'Abritel',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1400,
        value: 'agoda',
        label: 'Agoda',
        source: 'smoobu',
        smoobu_id: 68,
        smoobu_name: 'Agoda',
        syncbnb_id: 23,
        syncbnb_name: 'Agoda',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 8,
        hotelavailabilities_name: 'Agoda',
        webhotelier_id: 176,
        webhotelier_name: 'Agoda',
        rentability_id: 42,
        rentability_name: 'Agoda',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1500,
        value: 'agriturismo.it',
        label: 'Agriturismo.it',
        source: 'smoobu',
        smoobu_id: 71,
        smoobu_name: 'Agriturismo.it',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1600,
        value: 'alterkeys',
        label: 'Alterkeys',
        source: 'smoobu',
        smoobu_id: 36,
        smoobu_name: 'Alterkeys',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1700,
        value: 'alwaysonvacation',
        label: 'AlwaysOnVacation',
        source: 'smoobu',
        smoobu_id: 39,
        smoobu_name: 'AlwaysOnVacation',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1800,
        value: 'apartment.at',
        label: 'Apartment.at',
        source: 'smoobu',
        smoobu_id: 87,
        smoobu_name: 'Apartment.at',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 1900,
        value: 'atraveo_tuivillas',
        label: 'Atraveo / TuiVillas',
        source: 'smoobu',
        smoobu_id: 51,
        smoobu_name: 'Atraveo / TuiVillas',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 35,
        rentability_name: 'TUI Villas',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 2000,
        value: 'bauernhofurlaub.de',
        label: 'Bauernhofurlaub.de',
        source: 'smoobu',
        smoobu_id: 100,
        smoobu_name: 'Bauernhofurlaub.de',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 2100,
        value: 'bayregio',
        label: 'BAYregio',
        source: 'smoobu',
        smoobu_id: 55,
        smoobu_name: 'BAYregio',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 2200,
        value: 'bed_and_breakfast.it',
        label: 'Bed-And-Breakfast.it',
        source: 'smoobu',
        smoobu_id: 92,
        smoobu_name: 'Bed-And-Breakfast.it',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 2300,
        value: 'bedandbreakfast.eu',
        label: 'Bedandbreakfast.eu',
        source: 'smoobu',
        smoobu_id: 33,
        smoobu_name: 'Bedandbreakfast.eu',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 2400,
        value: 'beds_24',
        label: 'Beds 24',
        source: 'smoobu',
        smoobu_id: 99,
        smoobu_name: 'Beds 24',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 2500,
        value: 'bedycasa',
        label: 'BedyCasa',
        source: 'smoobu',
        smoobu_id: 34,
        smoobu_name: 'BedyCasa',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 2600,
        value: 'bellevue_ferienhaus',
        label: 'Bellevue Ferienhaus',
        source: 'smoobu',
        smoobu_id: 78,
        smoobu_name: 'Bellevue Ferienhaus',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 2700,
        value: 'bemate',
        label: 'Bemate',
        source: 'smoobu',
        smoobu_id: 37,
        smoobu_name: 'Bemate',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 2800,
        value: 'bergfex',
        label: 'Bergfex',
        source: 'smoobu',
        smoobu_id: 76,
        smoobu_name: 'Bergfex',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 2900,
        value: 'blindkanal',
        label: 'Blindkanal',
        source: 'smoobu',
        smoobu_id: null,
        smoobu_name: 'Blindkanal',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 3000,
        value: 'bodensee.de',
        label: 'Bodensee.de',
        source: 'smoobu',
        smoobu_id: 107,
        smoobu_name: 'Bodensee.de',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 3100,
        value: 'bodenseeferien',
        label: 'Bodenseeferien',
        source: 'smoobu',
        smoobu_id: 105,
        smoobu_name: 'Bodenseeferien',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 3200,
        value: 'bungalow.net',
        label: 'Bungalow.net',
        source: 'smoobu',
        smoobu_id: 53,
        smoobu_name: 'Bungalow.net',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 3300,
        value: 'canada_stays',
        label: 'Canada Stays',
        source: 'smoobu',
        smoobu_id: 89,
        smoobu_name: 'Canada Stays',
        syncbnb_id: 32,
        syncbnb_name: 'CanadaStays',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 34,
        rentability_name: 'CanadaStays',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 3400,
        value: 'casevacanza.it',
        label: 'Casevacanza.it',
        source: 'smoobu',
        smoobu_id: 60,
        smoobu_name: 'Casevacanza.it',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 3500,
        value: 'contao',
        label: 'Contao',
        source: 'smoobu',
        smoobu_id: 79,
        smoobu_name: 'Contao',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 3600,
        value: 'e_domizil_atraveo',
        label: 'E-domizil / Atraveo',
        source: 'smoobu',
        smoobu_id: 118,
        smoobu_name: 'E-domizil API',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 53,
        rentability_name: 'e-domizil',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 3700,
        value: 'ebab',
        label: 'Ebab',
        source: 'smoobu',
        smoobu_id: 35,
        smoobu_name: 'Ebab',
        syncbnb_id: 34,
        syncbnb_name: 'Ebab',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 3800,
        value: 'feratel_deskline',
        label: 'Feratel Deskline',
        source: 'smoobu',
        smoobu_id: 58,
        smoobu_name: 'Feratel Deskline',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 3900,
        value: 'ferienhausmarkt.com',
        label: 'Ferienhausmarkt.com',
        source: 'smoobu',
        smoobu_id: 81,
        smoobu_name: 'Ferienhausmarkt.com',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 4000,
        value: 'ferienhausmiete.de',
        label: 'Ferienhausmiete.de',
        source: 'smoobu',
        smoobu_id: 56,
        smoobu_name: 'Ferienhausmiete.de',
        syncbnb_id: 45,
        syncbnb_name: 'Ferienhausmiete.de',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 4100,
        value: 'ferienwohnung24_berlin',
        label: 'Ferienwohnung24 Berlin',
        source: 'smoobu',
        smoobu_id: 31,
        smoobu_name: 'Ferienwohnung24 Berlin',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 4200,
        value: 'ferienwohnungen.de',
        label: 'Ferienwohnungen.de',
        source: 'smoobu',
        smoobu_id: null,
        smoobu_name: 'Ferienwohnungen.de',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 4300,
        value: 'feries.com',
        label: 'Feries.com',
        source: 'smoobu',
        smoobu_id: 59,
        smoobu_name: 'Feries.com',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 4400,
        value: 'feriwa.com',
        label: 'Feriwa.com',
        source: 'smoobu',
        smoobu_id: 91,
        smoobu_name: 'Feriwa.com',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 4500,
        value: 'fewo_direkt',
        label: 'FeWo-direkt',
        source: 'smoobu',
        smoobu_id: 23,
        smoobu_name: 'FeWo-direkt / HomeAway',
        syncbnb_id: 69,
        syncbnb_name: 'FeWo-direkt',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 29,
        rentability_name: 'FeWo Direkt',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 4600,
        value: 'flat4day_go_fewo',
        label: 'Flat4day / Go-fewo',
        source: 'smoobu',
        smoobu_id: 45,
        smoobu_name: 'Flat4day / Go-fewo',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 21,
        rentability_name: 'Flat4Day',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 4700,
        value: 'flipkey',
        label: 'Flipkey',
        source: 'smoobu',
        smoobu_id: 65,
        smoobu_name: 'Flipkey',
        syncbnb_id: 36,
        syncbnb_name: 'TripAdvisor (Flipkey)',
        hostaway_id: 2003,
        hostaway_name: 'flipkey',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 37,
        rentability_name: 'Flipkey',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 4800,
        value: 'france_voyage.com',
        label: 'France-Voyage.com',
        source: 'smoobu',
        smoobu_id: 43,
        smoobu_name: 'France-Voyage.com',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 4900,
        value: 'hbook_booking_system',
        label: 'HBook Booking System',
        source: 'smoobu',
        smoobu_id: 61,
        smoobu_name: 'HBook Booking System',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 5000,
        value: 'holidaylettings',
        label: 'Holidaylettings',
        source: 'smoobu',
        smoobu_id: 64,
        smoobu_name: 'Holidaylettings',
        syncbnb_id: 37,
        syncbnb_name: 'Holiday Lettings',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 36,
        rentability_name: 'Holidaylettings',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 5100,
        value: 'holidayrentals.com',
        label: 'HolidayRentals.com',
        source: 'smoobu',
        smoobu_id: 42,
        smoobu_name: 'HolidayRentals.com',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 5200,
        value: 'vrbo',
        label: 'HomeAway',
        source: 'smoobu',
        smoobu_id: 50,
        smoobu_name: 'HomeAway',
        syncbnb_id: 5,
        syncbnb_name: 'Homeaway.com',
        hostaway_id: 2002,
        hostaway_name: 'homeaway',
        hotelavailabilities_id: 92,
        hotelavailabilities_name: 'Home Away',
        webhotelier_id: 262,
        webhotelier_name: 'HomeAway',
        rentability_id: 1,
        rentability_name: 'HomeAway',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 5300,
        value: 'homelidays',
        label: 'Homelidays',
        source: 'smoobu',
        smoobu_id: 57,
        smoobu_name: 'Homelidays',
        syncbnb_id: 11,
        syncbnb_name: 'Homelidays.com',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 31,
        rentability_name: 'Homelidays',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 5400,
        value: 'housetrip',
        label: 'HouseTrip',
        source: 'smoobu',
        smoobu_id: 20,
        smoobu_name: 'HouseTrip',
        syncbnb_id: 39,
        syncbnb_name: 'House Trip',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 38,
        rentability_name: 'HouseTrip',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 5500,
        value: 'hrs',
        label: 'HRS',
        source: 'smoobu',
        smoobu_id: null,
        smoobu_name: 'HRS',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 174,
        webhotelier_name: 'HRS',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 5600,
        value: 'hrs',
        label: 'HRS Destination / ImWeb',
        source: 'smoobu',
        smoobu_id: 67,
        smoobu_name: 'HRS Destination / ImWeb',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 5700,
        value: 'hrs',
        label: 'HRS Destination / ImWeb 2',
        source: 'smoobu',
        smoobu_id: 103,
        smoobu_name: 'HRS Destination / ImWeb 2',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 5800,
        value: 'hrs',
        label: 'HRS Destination / ImWeb 3',
        source: 'smoobu',
        smoobu_id: 104,
        smoobu_name: 'HRS Destination / ImWeb 3',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 5900,
        value: 'hrs',
        label: 'HRS Destination / ImWeb 4',
        source: 'smoobu',
        smoobu_id: 133,
        smoobu_name: 'HRS Destination / ImWeb 4',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 6000,
        value: 'hundeurlaub.de',
        label: 'Hundeurlaub.de',
        source: 'smoobu',
        smoobu_id: 54,
        smoobu_name: 'Hundeurlaub.de',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 6100,
        value: 'intobis',
        label: 'Intobis',
        source: 'smoobu',
        smoobu_id: 66,
        smoobu_name: 'Intobis',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 6200,
        value: 'landreise.de',
        label: 'Landreise.de',
        source: 'smoobu',
        smoobu_id: 85,
        smoobu_name: 'Landreise.de',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 6300,
        value: 'landsichten',
        label: 'Landsichten',
        source: 'smoobu',
        smoobu_id: 101,
        smoobu_name: 'Landsichten',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 6400,
        value: 'lohospo',
        label: 'Lohospo',
        source: 'smoobu',
        smoobu_id: 106,
        smoobu_name: 'Lohospo',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 6500,
        value: 'micazu.nl',
        label: 'Micazu.nl',
        source: 'smoobu',
        smoobu_id: 86,
        smoobu_name: 'Micazu.nl',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 6600,
        value: 'misterbnb',
        label: 'Misterbnb',
        source: 'smoobu',
        smoobu_id: 40,
        smoobu_name: 'Misterbnb',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 39,
        rentability_name: 'Misterb&b',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 6700,
        value: 'monsieurchalets',
        label: 'MonsieurChalets',
        source: 'smoobu',
        smoobu_id: 138,
        smoobu_name: 'MonsieurChalets',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 6800,
        value: 'morningcroissant',
        label: 'MorningCroissant',
        source: 'smoobu',
        smoobu_id: 44,
        smoobu_name: 'MorningCroissant',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 6900,
        value: 'motopress_booking_plugin',
        label: 'Motopress Booking Plugin',
        source: 'smoobu',
        smoobu_id: 93,
        smoobu_name: 'Motopress Booking Plugin',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 7000,
        value: 'mowitania',
        label: 'Mowitania',
        source: 'smoobu',
        smoobu_id: 2,
        smoobu_name: 'Mowitania',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 7100,
        value: 'mwferienwohnungen',
        label: 'MWFerienwohnungen',
        source: 'smoobu',
        smoobu_id: 73,
        smoobu_name: 'MWFerienwohnungen',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 7200,
        value: 'oh_berlin',
        label: 'Oh Berlin',
        source: 'smoobu',
        smoobu_id: 21,
        smoobu_name: 'Oh Berlin',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 7300,
        value: 'only_apartments',
        label: 'Only Apartments',
        source: 'smoobu',
        smoobu_id: 17,
        smoobu_name: 'Only Apartments',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 68,
        hotelavailabilities_name: 'Only-Apartments',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 25,
        rentability_name: 'Only-Apartments',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 7400,
        value: 'open_pro',
        label: 'Open Pro',
        source: 'smoobu',
        smoobu_id: 111,
        smoobu_name: 'Open Pro',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 7500,
        value: 'optimale_präsentation',
        label: 'Optimale Präsentation',
        source: 'smoobu',
        smoobu_id: 108,
        smoobu_name: 'Optimale Präsentation',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 7600,
        value: 'optimale_präsentation',
        label: 'Optimale Präsentation',
        source: 'smoobu',
        smoobu_id: 117,
        smoobu_name: 'Optimale Präsentation',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 7700,
        value: 'ownersdirect.co.uk',
        label: 'Ownersdirect.co.uk',
        source: 'smoobu',
        smoobu_id: 72,
        smoobu_name: 'Ownersdirect.co.uk',
        syncbnb_id: 10,
        syncbnb_name: 'OwnersDirect.co.uk',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 26,
        rentability_name: 'Owners Direct',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 7800,
        value: 'pinpoint_booking_system',
        label: 'Pinpoint Booking System',
        source: 'smoobu',
        smoobu_id: 62,
        smoobu_name: 'Pinpoint Booking System',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 7900,
        value: 'prodess',
        label: 'Prodess',
        source: 'smoobu',
        smoobu_id: 75,
        smoobu_name: 'Prodess',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 8000,
        value: 'pura_vida_travel_oramap',
        label: 'Pura Vida Travel / ORAmap',
        source: 'smoobu',
        smoobu_id: 88,
        smoobu_name: 'Pura Vida Travel / ORAmap',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 8100,
        value: 'red_apple_apartments',
        label: 'Red Apple Apartments',
        source: 'smoobu',
        smoobu_id: 26,
        smoobu_name: 'Red Apple Apartments',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 8200,
        value: 'rentahouseboat.com',
        label: 'Rentahouseboat.com',
        source: 'smoobu',
        smoobu_id: 82,
        smoobu_name: 'Rentahouseboat.com',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 8300,
        value: 'rentalhouses',
        label: 'RentalHouses',
        source: 'smoobu',
        smoobu_id: 41,
        smoobu_name: 'RentalHouses',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 8400,
        value: 'rentxpress',
        label: 'Rentxpress',
        source: 'smoobu',
        smoobu_id: 27,
        smoobu_name: 'Rentxpress',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 8500,
        value: 'safarinow.com',
        label: 'Safarinow.com',
        source: 'smoobu',
        smoobu_id: 119,
        smoobu_name: 'Safarinow.com',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 8600,
        value: 'soho_hotel_plugin',
        label: 'Soho Hotel Plugin',
        source: 'smoobu',
        smoobu_id: 115,
        smoobu_name: 'Soho Hotel Plugin',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 8700,
        value: 'spain_holiday',
        label: 'Spain Holiday',
        source: 'smoobu',
        smoobu_id: 63,
        smoobu_name: 'Spain Holiday',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 56,
        rentability_name: 'spain-holidays',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 8800,
        value: 'tomas',
        label: 'Tomas',
        source: 'smoobu',
        smoobu_id: 84,
        smoobu_name: 'Tomas',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 8900,
        value: 'tourist_online.de',
        label: 'Tourist-online.de',
        source: 'smoobu',
        smoobu_id: 77,
        smoobu_name: 'Tourist-online.de',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 9000,
        value: 'traum_ferienwohnungen.de',
        label: 'Traum-ferienwohnungen.de',
        source: 'smoobu',
        smoobu_id: 80,
        smoobu_name: 'Traum-Ferienwohnungen',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 9100,
        value: 'travanto',
        label: 'Travanto',
        source: 'smoobu',
        smoobu_id: 116,
        smoobu_name: 'Travanto',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 9200,
        value: 'trip.com_ctrip',
        label: 'Trip.com / Ctrip',
        source: 'smoobu',
        smoobu_id: 109,
        smoobu_name: 'Trip.com / Ctrip',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 127,
        hotelavailabilities_name: 'Ctrip',
        webhotelier_id: 472,
        webhotelier_name: 'Trip.com',
        rentability_id: 55,
        rentability_name: 'trip.com',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 9300,
        value: 'tripadvisor',
        label: 'Tripadvisor',
        source: 'smoobu',
        smoobu_id: 24,
        smoobu_name: 'Tripadvisor',
        syncbnb_id: 4,
        syncbnb_name: 'Tripadvisor',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 75,
        hotelavailabilities_name: 'Tripadvisor Rentals',
        webhotelier_id: 1,
        webhotelier_name: 'TripAdvisor',
        rentability_id: 33,
        rentability_name: 'TripAdvisor',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 9400,
        value: 'urlaub_in_florida.net',
        label: 'Urlaub-in-florida.net',
        source: 'smoobu',
        smoobu_id: 102,
        smoobu_name: 'urlaub-in-florida.net',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 9500,
        value: 'vacation_apartments.com',
        label: 'Vacation-Apartments.com',
        source: 'smoobu',
        smoobu_id: 30,
        smoobu_name: 'Vacation-Apartments.com',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 9600,
        value: 'vrbo',
        label: 'VacationRentals.com',
        source: 'smoobu',
        smoobu_id: 38,
        smoobu_name: 'VacationRentals.com / HomeAway',
        syncbnb_id: 25,
        syncbnb_name: 'Vacation Rentals',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 9700,
        value: 'vrbo',
        label: 'VRBO / HomeAway 1',
        source: 'smoobu',
        smoobu_id: 123,
        smoobu_name: 'VRBO / HomeAway 1',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 9800,
        value: 'vrbo',
        label: 'VRBO / HomeAway 2',
        source: 'smoobu',
        smoobu_id: 124,
        smoobu_name: 'VRBO / HomeAway 2',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 9900,
        value: 'vrbo',
        label: 'VRBO / HomeAway 3',
        source: 'smoobu',
        smoobu_id: 125,
        smoobu_name: 'VRBO / HomeAway 3',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 10000,
        value: 'waytostay',
        label: 'Waytostay',
        source: 'smoobu',
        smoobu_id: 32,
        smoobu_name: 'Waytostay',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 48,
        rentability_name: 'WaytoStay',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 10100,
        value: 'wpestate',
        label: 'Wpestate',
        source: 'smoobu',
        smoobu_id: 98,
        smoobu_name: 'wpestate/wprentals Plugin',
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 10200,
        value: 'alargo',
        label: 'Alargo',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 28,
        syncbnb_name: 'Alargo',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 10300,
        value: 'aluguetemporada',
        label: 'AlugueTemporada',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 12,
        syncbnb_name: 'AlugueTemporada',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 10400,
        value: 'amazingaccom',
        label: 'AmazingAccom',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 58,
        syncbnb_name: 'AmazingAccom',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 10500,
        value: 'arendoo',
        label: 'Arendoo',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 71,
        syncbnb_name: 'Arendoo',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 771,
        webhotelier_name: 'Arendoo',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 10600,
        value: 'atraveo',
        label: 'Atraveo',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: 'Atraveo',
        syncbnb_id: 30,
        syncbnb_name: 'Atraveo [1-way]',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 5,
        rentability_name: 'atraveo',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 10700,
        value: 'belgie_vakantiehuis',
        label: 'Belgie-Vakantiehuis',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 85,
        syncbnb_name: 'Belgie-Vakantiehuis [1-way]',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 10800,
        value: 'cities_reference',
        label: 'Cities Reference',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 63,
        syncbnb_name: 'Cities Reference',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 6,
        rentability_name: 'Cities Reference',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 10900,
        value: 'clickstay',
        label: 'ClickStay',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 40,
        syncbnb_name: 'ClickStay',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 62,
        hotelavailabilities_name: 'Clickstay',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 11000,
        value: 'domy_letniskowe.com',
        label: 'Domy-letniskowe.com',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 49,
        syncbnb_name: 'Domy-letniskowe.com',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 11100,
        value: 'glamping_hub',
        label: 'Glamping Hub',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 70,
        syncbnb_name: 'Glamping Hub',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 11200,
        value: 'hawaiichee',
        label: 'HawaiiChee',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 83,
        syncbnb_name: 'HawaiiChee',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 11300,
        value: 'hipcamp',
        label: 'Hipcamp',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 80,
        syncbnb_name: 'Hipcamp',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 11400,
        value: 'holidayhouses.co.nz',
        label: 'Holidayhouses.co.nz',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 77,
        syncbnb_name: 'Holidayhouses.co.nz',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 11500,
        value: 'vrbo',
        label: 'Homeaway.cl',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 87,
        syncbnb_name: 'Homeaway.cl',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 11600,
        value: 'vrbo',
        label: 'Homeaway.co.id',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 88,
        syncbnb_name: 'Homeaway.co.id',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 11700,
        value: 'vrbo',
        label: 'HomeAway.co.in',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 33,
        syncbnb_name: 'HomeAway.co.in',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 11800,
        value: 'vrbo',
        label: 'Homeaway.co.nz',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 75,
        syncbnb_name: 'Homeaway.co.nz',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 11900,
        value: 'vrbo',
        label: 'HomeAway.co.th',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 65,
        syncbnb_name: 'HomeAway.co.th',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 12000,
        value: 'vrbo',
        label: 'HomeAway.co.uk',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 6,
        syncbnb_name: 'HomeAway.co.uk',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 12100,
        value: 'vrbo',
        label: 'Homeaway.com',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 5,
        syncbnb_name: 'Homeaway.com',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 12200,
        value: 'vrbo',
        label: 'Homeaway.com.au',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 57,
        syncbnb_name: 'Homeaway.com.au',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 12300,
        value: 'vrbo',
        label: 'Homeaway.com.mx',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 89,
        syncbnb_name: 'Homeaway.com.mx',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 12400,
        value: 'vrbo',
        label: 'HomeAway.com.ph',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 72,
        syncbnb_name: 'HomeAway.com.ph',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 12500,
        value: 'vrbo',
        label: 'HomeAway.com.sg',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 67,
        syncbnb_name: 'HomeAway.com.sg',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 12600,
        value: 'vrbo',
        label: 'HomeAway.com.uy',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 54,
        syncbnb_name: 'HomeAway.com.uy',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 12700,
        value: 'vrbo',
        label: 'HomeAway.dk',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 74,
        syncbnb_name: 'HomeAway.dk',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 12800,
        value: 'vrbo',
        label: 'Homeaway.es',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 9,
        syncbnb_name: 'Homeaway.es',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 12900,
        value: 'vrbo',
        label: 'Homeaway.jp',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 90,
        syncbnb_name: 'Homeaway.jp',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 13000,
        value: 'vrbo',
        label: 'Homeaway.nl',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 84,
        syncbnb_name: 'Homeaway.nl',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 13100,
        value: 'vrbo',
        label: 'Homeaway.no',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 86,
        syncbnb_name: 'Homeaway.no',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 13200,
        value: 'vrbo',
        label: 'Homeaway.pt',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 73,
        syncbnb_name: 'Homeaway.pt',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 13300,
        value: 'vrbo',
        label: 'HomeAway.se',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 93,
        syncbnb_name: 'HomeAway.se',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 13400,
        value: 'vrbo',
        label: 'Homelidays.it',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 14,
        syncbnb_name: 'Homelidays.it',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 13500,
        value: 'kaguaruoo',
        label: 'Kaguaruoo',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 91,
        syncbnb_name: 'Kaguaruoo',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 13600,
        value: 'kid_and_coe',
        label: 'Kid and Coe',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 76,
        syncbnb_name: 'Kid and Coe',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 13700,
        value: 'magicstay',
        label: 'MagicStay',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 55,
        syncbnb_name: 'MagicStay',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 13800,
        value: 'natuurhuisje.nl',
        label: 'Natuurhuisje.nl',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 53,
        syncbnb_name: 'Natuurhuisje.nl',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 13900,
        value: 'niumba',
        label: 'Niumba',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 41,
        syncbnb_name: 'Niumba',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 14000,
        value: 'other',
        label: 'Offline Booking',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 95,
        syncbnb_name: 'Offline Booking',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 14100,
        value: 'ostrovok',
        label: 'Ostrovok',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 82,
        syncbnb_name: 'Ostrovok [1-way sync]',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 10,
        hotelavailabilities_name: 'Ostrovok',
        webhotelier_id: 408,
        webhotelier_name: 'Ostrovok.ru',
        rentability_id: 45,
        rentability_name: 'Ostrovok',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 14200,
        value: 'ownerdirect.com',
        label: 'OwnerDirect.com',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 31,
        syncbnb_name: 'OwnerDirect.com',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 14300,
        value: 'planyo',
        label: 'Planyo',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 52,
        syncbnb_name: 'Planyo',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 14400,
        value: 'rental_systems',
        label: 'Rental Systems',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 38,
        syncbnb_name: 'Rental Systems',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 14500,
        value: 'resido',
        label: 'Resido.es',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 48,
        syncbnb_name: 'Resido.es',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 14600,
        value: 'resido',
        label: 'Resido.fr',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 46,
        syncbnb_name: 'Resido.fr',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 14700,
        value: 'resido',
        label: 'Resido.hr',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 51,
        syncbnb_name: 'Resido.hr',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 14800,
        value: 'resido',
        label: 'Resido.it',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 47,
        syncbnb_name: 'Resido.it',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 14900,
        value: 'stayz',
        label: 'Stayz',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 13,
        syncbnb_name: 'Stayz',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 15000,
        value: 'theatre_digs_booker',
        label: 'Theatre Digs Booker',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 64,
        syncbnb_name: 'Theatre Digs Booker',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 15100,
        value: 'tourist_paradise',
        label: 'Tourist Paradise',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 44,
        syncbnb_name: 'Tourist Paradise',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 15200,
        value: 'travelmob',
        label: 'Travelmob',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 43,
        syncbnb_name: 'Travelmob',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 15300,
        value: 'tripvillas',
        label: 'TripVillas',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 29,
        syncbnb_name: 'TripVillas',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 41,
        rentability_name: 'Tripvillas',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 15400,
        value: 'tujia_waka',
        label: 'Tujia | Waka',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 92,
        syncbnb_name: 'Tujia | Waka',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 15500,
        value: 'vacation_home_rentals',
        label: 'Vacation Home Rentals',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 42,
        syncbnb_name: 'Vacation Home Rentals',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 15600,
        value: 'vakantiehuisnu.nl',
        label: 'Vakantiehuisnu.nl',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 50,
        syncbnb_name: 'Vakantiehuisnu.nl',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 15700,
        value: 'your_rentals',
        label: 'Your rentals',
        source: 'syncbnb',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: 94,
        syncbnb_name: 'Your rentals',
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 15800,
        value: 'direct',
        label: 'Direct',
        source: 'hostaway',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2000,
        hostaway_name: 'direct',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: -4,
        webhotelier_name: 'Walk-in',
        rentability_id: 64,
        rentability_name: 'Direct',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 15900,
        value: 'marriott',
        label: 'Marriott',
        source: 'hostaway',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2019,
        hostaway_name: 'marriott',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 60,
        rentability_name: 'Marriott Points',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 16000,
        value: 'wordpress',
        label: 'Wordpress',
        source: 'hostaway',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2017,
        hostaway_name: 'wordpress',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 16100,
        value: 'airbnb_unofficial',
        label: 'Airbnb (unofficial)',
        source: 'hostaway',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2001,
        hostaway_name: 'airbnb',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 16200,
        value: 'homeaway_ical',
        label: 'HomeAway iCal',
        source: 'hostaway',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2009,
        hostaway_name: 'homeawayical',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 16300,
        value: 'vrbo_ical',
        label: 'VRBO iCal',
        source: 'hostaway',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2010,
        hostaway_name: 'vrboical',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 16400,
        value: 'ical',
        label: 'iCal',
        source: 'hostaway',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2015,
        hostaway_name: 'customIcal',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 16500,
        value: 'tripadvisor_ical',
        label: 'Tripadvisor iCal',
        source: 'hostaway',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2016,
        hostaway_name: 'tripadvisorical',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 16600,
        value: 'partner',
        label: 'Partner',
        source: 'hostaway',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2020,
        hostaway_name: 'partner',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 16700,
        value: 'gds',
        label: 'GDS',
        source: 'hostaway',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: 2021,
        hostaway_name: 'gds',
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: -6,
        webhotelier_name: 'GDS (Offline)',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 16800,
        value: 'travel_republic',
        label: 'Travel Republic',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 3,
        hotelavailabilities_name: 'TravelRepublic',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 14,
        rentability_name: 'Travel Republic',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 16900,
        value: 'bookonlinenow',
        label: 'BookOnlineNow',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 4,
        hotelavailabilities_name: 'BookOnlineNow',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 17000,
        value: 'welcome_beds',
        label: 'WelcomeBeds',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 5,
        hotelavailabilities_name: 'WelcomeBeds',
        webhotelier_id: 346,
        webhotelier_name: 'WelcomeBeds',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 17100,
        value: 'hotel_beds',
        label: 'Hotel Beds',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 6,
        hotelavailabilities_name: 'HotelBeds',
        webhotelier_id: 175,
        webhotelier_name: 'HotelBeds',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 17200,
        value: 'gta',
        label: 'GTA Travel',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 7,
        hotelavailabilities_name: 'Gta',
        webhotelier_id: 173,
        webhotelier_name: 'GTA',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 17300,
        value: 'sun_hotels',
        label: 'SunHotels',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 9,
        hotelavailabilities_name: 'SunHotels',
        webhotelier_id: 237,
        webhotelier_name: 'SunHotels',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 17400,
        value: 'special_tours',
        label: 'Special Tours',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 11,
        hotelavailabilities_name: 'Special Tours',
        webhotelier_id: 240,
        webhotelier_name: 'Special Tours',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 17500,
        value: 'booking_plan',
        label: 'BookingPlan',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 12,
        hotelavailabilities_name: 'BookingPlan',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 17600,
        value: 'revato_hotelscombined',
        label: 'Revato/HotelsCombined',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 13,
        hotelavailabilities_name: 'Revato/HotelsCombined',
        webhotelier_id: 327,
        webhotelier_name: 'HotelsCombined | Revato',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 17700,
        value: 'hpro_travel',
        label: 'HPro Travel',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 14,
        hotelavailabilities_name: 'HPro Travel',
        webhotelier_id: 243,
        webhotelier_name: 'HPro Travel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 17800,
        value: 'ultranet',
        label: 'UltraNet',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 15,
        hotelavailabilities_name: 'UltraNet',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 17900,
        value: 'omega',
        label: 'Omega Bookings',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 16,
        hotelavailabilities_name: 'Omega',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 18000,
        value: 'hotusa',
        label: 'Hotusa',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 17,
        hotelavailabilities_name: 'Hotusa',
        webhotelier_id: 328,
        webhotelier_name: 'Hotusa',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 18100,
        value: 'traveltripper',
        label: 'Travel Tripper',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 18,
        hotelavailabilities_name: 'TravelTripper',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 18200,
        value: 'splendia',
        label: 'Splendia',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 19,
        hotelavailabilities_name: 'Splendia',
        webhotelier_id: 242,
        webhotelier_name: 'Splendia',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 18300,
        value: 'hostel_world',
        label: 'HostelWorld',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 20,
        hotelavailabilities_name: 'HostelWorld',
        webhotelier_id: 428,
        webhotelier_name: 'HostelWorld.com',
        rentability_id: 46,
        rentability_name: 'Hostelworld',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 18400,
        value: 'hotel_rez',
        label: 'HotelREZ',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 21,
        hotelavailabilities_name: 'HotelRez',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 18500,
        value: 'vervendo',
        label: 'Vervendo',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 22,
        hotelavailabilities_name: 'Vervendo',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 18600,
        value: 'despegar',
        label: 'Despegar',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 23,
        hotelavailabilities_name: 'Despegar',
        webhotelier_id: 335,
        webhotelier_name: 'Juniper (Despegar)',
        rentability_id: 43,
        rentability_name: 'Despegar',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 18700,
        value: 'rec_online',
        label: 'RecOnline',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 24,
        hotelavailabilities_name: 'RecOnline',
        webhotelier_id: 246,
        webhotelier_name: 'Reconline',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 18900,
        value: 'bidroom',
        label: 'Bidroom',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 27,
        hotelavailabilities_name: 'Bidroom',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 19000,
        value: 'alba_travel',
        label: 'Alba Travel',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 29,
        hotelavailabilities_name: 'AlbaTravel',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 19100,
        value: 'bonanza',
        label: 'Bonanza',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 30,
        hotelavailabilities_name: 'Bonanza',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 19200,
        value: 'prestigia',
        label: 'Prestigia',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 31,
        hotelavailabilities_name: 'Prestigia',
        webhotelier_id: 339,
        webhotelier_name: 'Prestigia',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 19300,
        value: 'vas_hotel',
        label: 'Vashotel',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 32,
        hotelavailabilities_name: 'Vashotel',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 19400,
        value: 'dotw',
        label: 'Dotw',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 33,
        hotelavailabilities_name: 'Dotw',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 19500,
        value: 'tobook',
        label: 'ToBook',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 34,
        hotelavailabilities_name: 'ToBook',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 19600,
        value: 'tablet_hotels',
        label: 'Tablet Hotels',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 35,
        hotelavailabilities_name: 'Tablet',
        webhotelier_id: 341,
        webhotelier_name: 'TabletHotels',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 19700,
        value: 'edreams_odigeo',
        label: 'eDreams Odigeo',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 36,
        hotelavailabilities_name: 'eDreamsOdigeo',
        webhotelier_id: 567,
        webhotelier_name: 'eDreams ODIGEO',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 19800,
        value: 'last_minute',
        label: 'Lastminute.com',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 37,
        hotelavailabilities_name: 'LastMinute',
        webhotelier_id: 235,
        webhotelier_name: 'Lastminute',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 19900,
        value: 'mr_and_mrs_smith',
        label: 'Mr & Mrs Smith',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 39,
        hotelavailabilities_name: 'MrAndMrsSmith',
        webhotelier_id: 338,
        webhotelier_name: 'Mr & Mrs Smith',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 20000,
        value: 'ha_manual',
        label: 'Hotel Availabilities (manual)',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 41,
        hotelavailabilities_name: 'HA Manual',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 20100,
        value: 'atrapalo',
        label: 'Atrapalo',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 43,
        hotelavailabilities_name: 'Atrapalo',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 20200,
        value: 'jumbo_beds',
        label: 'Jumbobeds',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 47,
        hotelavailabilities_name: 'Jumbobeds',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 20300,
        value: 'hotel_tonight',
        label: 'HotelTonight',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 51,
        hotelavailabilities_name: 'HotelTonight',
        webhotelier_id: 670,
        webhotelier_name: 'HotelTonight',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 20400,
        value: 'tui_destimo',
        label: 'TUI Destimo',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 74,
        hotelavailabilities_name: 'TUI destimo',
        webhotelier_id: 969,
        webhotelier_name: 'TUI Destimo',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 20500,
        value: 'miki_travel',
        label: 'Miki Travel',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 78,
        hotelavailabilities_name: 'MIKI Travel',
        webhotelier_id: 614,
        webhotelier_name: 'MIKI TRAVEL',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 20600,
        value: 'roibos',
        label: 'Roibos',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 88,
        hotelavailabilities_name: 'ROIBOS',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 20700,
        value: 'travco',
        label: 'Travco',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 109,
        hotelavailabilities_name: 'TRAVCO',
        webhotelier_id: 245,
        webhotelier_name: 'Travco',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 20800,
        value: 'roiback',
        label: 'Roiback',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 120,
        hotelavailabilities_name: 'Roiback',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 20900,
        value: 'mews_pms',
        label: 'Mews PMS',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 123,
        hotelavailabilities_name: 'MEWS PMS',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 30000,
        value: 'mts_globe',
        label: 'MTS',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 126,
        hotelavailabilities_name: 'MTSGlobe',
        webhotelier_id: 238,
        webhotelier_name: 'MTS',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 30100,
        value: 'avoris',
        label: 'Avoris',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 129,
        hotelavailabilities_name: 'Avoris',
        webhotelier_id: 1044,
        webhotelier_name: 'Avoris',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 30200,
        value: 'hyper_guest',
        label: 'HyperGuest',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 130,
        hotelavailabilities_name: 'HyperGuest',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 30300,
        value: 'fast_pay',
        label: 'Fast Pay',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 133,
        hotelavailabilities_name: 'Fast Pay',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 30400,
        value: 'travel_port',
        label: 'Travelport',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 136,
        hotelavailabilities_name: 'Travelport',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 30500,
        value: 'bookwize',
        label: 'Bookwize',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 137,
        hotelavailabilities_name: 'Bookwize',
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 30600,
        value: 'olympia_europe',
        label: 'Olympia Europe',
        source: 'hotelavailabilities',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: 139,
        hotelavailabilities_name: 'Olympia Europe',
        webhotelier_id: 575,
        webhotelier_name: 'Olympia Europe',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 30700,
        value: 'travel_agent',
        label: 'Travel Agent',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: -5,
        webhotelier_name: 'Travel Agent (offline)',
        rentability_id: 63,
        rentability_name: 'Travel Agency',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 30800,
        value: 'phone_call',
        label: 'Call Center',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: -3,
        webhotelier_name: 'Call Center',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 30900,
        value: 'trivago',
        label: 'trivago',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 30,
        webhotelier_name: 'trivago',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 31000,
        value: 'facebook',
        label: 'Facebook',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 42,
        webhotelier_name: 'Facebook',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 31100,
        value: 'twitter',
        label: 'Twitter',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 105,
        webhotelier_name: 'Twitter',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 31200,
        value: 'google',
        label: 'Google+',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 106,
        webhotelier_name: 'Google+',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 31300,
        value: 'google',
        label: 'Google',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 107,
        webhotelier_name: 'Google',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 31400,
        value: 'tripadvisor',
        label: 'TripAdvisor TripConnect',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 147,
        webhotelier_name: 'TripAdvisor TripConnect',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 31500,
        value: 'hotel_with_flight',
        label: 'HotelWithFlight.com',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 201,
        webhotelier_name: 'Hotel with Flight',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 31600,
        value: 'tripadvisor',
        label: 'TripAdvisor Instant Booking',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 223,
        webhotelier_name: 'TripAdvisor Instant Booking',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 31700,
        value: 'venere',
        label: 'Venere',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 233,
        webhotelier_name: 'Venere',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 31800,
        value: 'orbitz',
        label: 'Orbitz',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 234,
        webhotelier_name: 'Orbitz',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 31900,
        value: 'laterooms',
        label: 'LateRooms',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 236,
        webhotelier_name: 'LateRooms',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 32000,
        value: 'dnata',
        label: 'dnata',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 239,
        webhotelier_name: 'dnata',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 32100,
        value: 'unister',
        label: 'Unister',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 241,
        webhotelier_name: 'Unister',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 32200,
        value: 'holidays_4u',
        label: 'Holidays 4U',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 244,
        webhotelier_name: 'Holidays 4U',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 32300,
        value: 'hotel.de',
        label: 'Hotel.de',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 247,
        webhotelier_name: 'Hotel.de',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 32400,
        value: 'expedia_hotel_collect',
        label: 'Expedia Hotel Collect',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 269,
        webhotelier_name: 'Expedia Hotel Collect',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 32500,
        value: 'hotelbeds_hotel_collect',
        label: 'Hotelbeds Hotel Collect',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 270,
        webhotelier_name: 'Hotelbeds Hotel Collect',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 32600,
        value: 'trivago',
        label: 'trivago MyHotelShop',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 285,
        webhotelier_name: 'trivago MyHotelShop',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 32700,
        value: 'expedia_egencia',
        label: 'Expedia Egencia',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 295,
        webhotelier_name: 'Expedia Egencia',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 32800,
        value: 'expedia_egencia_hotel_collect',
        label: 'Expedia Egencia Hotel Collect',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 296,
        webhotelier_name: 'Expedia Egencia Hotel Collect',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 32900,
        value: 'abreuonline',
        label: 'AbreuOnline',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 319,
        webhotelier_name: 'AbreuOnline',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 33000,
        value: 'bestday',
        label: 'BestDay',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 320,
        webhotelier_name: 'BestDay',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 33100,
        value: 'budgetplaces',
        label: 'BudgetPlaces',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 321,
        webhotelier_name: 'BudgetPlaces',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 33200,
        value: 'centralr',
        label: 'CentralR',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 322,
        webhotelier_name: 'CentralR',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 33300,
        value: 'easytobook.com',
        label: 'Easytobook.com',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 323,
        webhotelier_name: 'Easytobook.com',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 33400,
        value: 'escapio',
        label: 'Escapio',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 324,
        webhotelier_name: 'Escapio',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 33500,
        value: 'hoojoozat.com',
        label: 'Hoojoozat.com',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 325,
        webhotelier_name: 'Hoojoozat.com',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 33600,
        value: 'hostelbookers',
        label: 'HostelBookers',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 326,
        webhotelier_name: 'HostelBookers',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 33700,
        value: 'hotwire',
        label: 'Hotwire',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 329,
        webhotelier_name: 'Hotwire',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 33800,
        value: 'icastelli.net',
        label: 'icastelli.net',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 330,
        webhotelier_name: 'icastelli.net',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 33900,
        value: 'initalia',
        label: 'Initalia',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 331,
        webhotelier_name: 'Initalia',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 34000,
        value: 'jactravel',
        label: 'JacTravel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 332,
        webhotelier_name: 'JacTravel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 34100,
        value: 'jdbhotels',
        label: 'JDBHotels',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 333,
        webhotelier_name: 'JDBHotels',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 34200,
        value: 'jetsetter',
        label: 'Jetsetter',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 334,
        webhotelier_name: 'Jetsetter',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 34300,
        value: 'keytel',
        label: 'Keytel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 336,
        webhotelier_name: 'Keytel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 34400,
        value: 'lowcostholidays',
        label: 'LowCostHolidays',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 337,
        webhotelier_name: 'LowCostHolidays',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 34500,
        value: 'rezview',
        label: 'RezView',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 340,
        webhotelier_name: 'RezView',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 34600,
        value: 'touricoholidays',
        label: 'TouricoHolidays',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 342,
        webhotelier_name: 'TouricoHolidays',
        rentability_id: 18,
        rentability_name: 'Tourico Holidays',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 34700,
        value: 'transhotel',
        label: 'Transhotel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 343,
        webhotelier_name: 'Transhotel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 34800,
        value: 'travelocity',
        label: 'Travelocity',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 344,
        webhotelier_name: 'Travelocity',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 34900,
        value: 'unitravel',
        label: 'UniTravel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 345,
        webhotelier_name: 'UniTravel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 35000,
        value: 'whl',
        label: 'WHL',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 347,
        webhotelier_name: 'WHL',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 35100,
        value: 'wotif',
        label: 'Wotif',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 348,
        webhotelier_name: 'Wotif',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 35200,
        value: 'yelp',
        label: 'Yelp',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 353,
        webhotelier_name: 'Yelp',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 35300,
        value: 'google',
        label: 'Google Hotel Ads',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 359,
        webhotelier_name: 'Google Hotel Ads',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 35400,
        value: 'quotelier',
        label: 'Quotelier',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 371,
        webhotelier_name: 'Quotelier',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 35500,
        value: 'trivago',
        label: 'trivago Rate Connect',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 374,
        webhotelier_name: 'trivago Rate Connect',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 35600,
        value: 'skyscanner_metasearch',
        label: 'Skyscanner METAsearch',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 409,
        webhotelier_name: 'Skyscanner METAsearch',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 35700,
        value: 'hotels4u',
        label: 'Hotels4U',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 425,
        webhotelier_name: 'Hotels4U',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 35800,
        value: 'asiatravel',
        label: 'AsiaTravel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 434,
        webhotelier_name: 'AsiaTravel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 35900,
        value: 'restel',
        label: 'Restel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 452,
        webhotelier_name: 'Restel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 36000,
        value: 'traveloka',
        label: 'Traveloka',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 454,
        webhotelier_name: 'Traveloka',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 36100,
        value: 'italcamel',
        label: 'ItalCamel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 478,
        webhotelier_name: 'ItalCamel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 36200,
        value: 'bookcyprus.com',
        label: 'Bookcyprus.com',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 494,
        webhotelier_name: 'Bookcyprus.com',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 36300,
        value: 'message_on',
        label: 'message on',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 500,
        webhotelier_name: 'message on',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 36400,
        value: 'on_the_beach',
        label: 'On The Beach',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 511,
        webhotelier_name: 'On The Beach',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 36500,
        value: 'tiket',
        label: 'Tiket',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 512,
        webhotelier_name: 'Tiket',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 36600,
        value: 'loveholidays.com',
        label: 'loveholidays.com',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 514,
        webhotelier_name: 'loveholidays.com',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 36700,
        value: 'trip.com_b2b',
        label: 'Trip.com B2B',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 526,
        webhotelier_name: 'Trip.com B2B',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 36800,
        value: 'ots_globe',
        label: 'OTS Globe',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 549,
        webhotelier_name: 'OTS Globe',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 36900,
        value: 'british_airways',
        label: 'British Airways',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 554,
        webhotelier_name: 'British Airways',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 37000,
        value: 'destinations_of_the_world',
        label: 'Destinations of the World',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 570,
        webhotelier_name: 'Destinations of the World',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 37100,
        value: 'excite_holidays',
        label: 'Excite Holidays',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 582,
        webhotelier_name: 'Excite Holidays',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 37200,
        value: 'hors',
        label: 'HORS',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 587,
        webhotelier_name: 'HORS',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 37300,
        value: 'i_escape',
        label: 'i-escape',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 596,
        webhotelier_name: 'i-escape',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 37400,
        value: 'affilired',
        label: 'Affilired',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 599,
        webhotelier_name: 'Affilired',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 37500,
        value: 'sojern',
        label: 'Sojern',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 604,
        webhotelier_name: 'Sojern',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 37600,
        value: 'webhotelier_rentals_demo',
        label: 'WebHotelier Rentals Demo',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 608,
        webhotelier_name: 'WebHotelier Rentals Demo',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 37700,
        value: 'pegipegi',
        label: 'PegiPegi',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 613,
        webhotelier_name: 'PegiPegi',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 37800,
        value: 'mouzenidis_travel',
        label: 'Mouzenidis Travel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 615,
        webhotelier_name: 'Mouzenidis Travel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 37900,
        value: 'logitravel_traveltool_traveltino',
        label: 'Logitravel/TravelTool/Traveltino',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 617,
        webhotelier_name: 'Logitravel/TravelTool/Traveltino',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 38000,
        value: 'all_greek_villas',
        label: 'All Greek Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 618,
        webhotelier_name: 'All Greek Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 38100,
        value: 'elite_estates',
        label: 'Elite Estates',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 619,
        webhotelier_name: 'Elite Estates',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 38200,
        value: 'bluevillas_channel',
        label: 'BlueVillas Channel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 620,
        webhotelier_name: 'BlueVillas Channel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 38300,
        value: 'trivago',
        label: 'Trivago Express Booking',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 631,
        webhotelier_name: 'trivago Express Booking',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 38400,
        value: 'luxury_key',
        label: 'Luxury Key',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 635,
        webhotelier_name: 'Luxury Key',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 38500,
        value: 'jet2holidays',
        label: 'Jet2Holidays',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 638,
        webhotelier_name: 'Jet2Holidays',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 38600,
        value: 'google',
        label: 'Google Hotel Ads CPC',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 646,
        webhotelier_name: 'Google Hotel Ads CPC',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 38700,
        value: 'zeloom',
        label: 'Zeloom',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 647,
        webhotelier_name: 'Zeloom',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 38800,
        value: 'world2meet',
        label: 'World2Meet',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 656,
        webhotelier_name: 'World2Meet',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 38900,
        value: 'synxis',
        label: 'SynXis',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 658,
        webhotelier_name: 'SynXis',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 39000,
        value: 'the_king_of_villas',
        label: 'The King of Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 660,
        webhotelier_name: 'The King of Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 39100,
        value: 'mykonos_feelings',
        label: 'Mykonos Feelings',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 662,
        webhotelier_name: 'Mykonos Feelings',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 39200,
        value: 'google',
        label: 'Google Ads',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 665,
        webhotelier_name: 'Google Ads',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 39300,
        value: 'goibibo_makemytrip',
        label: 'Goibibo / MakeMyTrip',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 668,
        webhotelier_name: 'Goibibo / MakeMyTrip',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 39400,
        value: 'belvedere_mykonos_channel',
        label: 'Belvedere Mykonos Channel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 675,
        webhotelier_name: 'Belvedere Mykonos Channel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 39500,
        value: 'vilotel_luxury_villas',
        label: 'Vilotel Luxury Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 692,
        webhotelier_name: 'Vilotel Luxury Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 39600,
        value: 'meeting_point',
        label: 'Meeting Point',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 695,
        webhotelier_name: 'Meeting Point',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 39700,
        value: 'world_hotel_link',
        label: 'World Hotel Link',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 698,
        webhotelier_name: 'World Hotel Link',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 39800,
        value: 'directwithhotels',
        label: 'DirectWithHotels',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 699,
        webhotelier_name: 'DirectWithHotels',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 39900,
        value: 'ryanair_rooms',
        label: 'Ryanair Rooms',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 700,
        webhotelier_name: 'Ryanair Rooms',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 40000,
        value: 'global_united_esales',
        label: 'Global United eSales',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 705,
        webhotelier_name: 'Global United eSales',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 40100,
        value: 'jumbotours',
        label: 'JumboTours',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 707,
        webhotelier_name: 'JumboTours',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 40200,
        value: 'shr',
        label: 'SHR',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 713,
        webhotelier_name: 'SHR',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 40300,
        value: 'lagoon_hotel_thessaloniki',
        label: 'Lagoon Hotel Thessaloniki',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 750,
        webhotelier_name: 'Lagoon Hotel Thessaloniki',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 40400,
        value: 'special_holidays_thessaloniki',
        label: 'Special Holidays Thessaloniki',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 751,
        webhotelier_name: 'Special Holidays Thessaloniki',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 40500,
        value: 'issta',
        label: 'Issta',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 758,
        webhotelier_name: 'Issta',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 40600,
        value: 'aegean_blue',
        label: 'Aegean Blue',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 761,
        webhotelier_name: 'Aegean Blue',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 40700,
        value: 'ecohotels',
        label: 'EcoHotels',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 772,
        webhotelier_name: 'EcoHotels',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 40800,
        value: 'halkidiki_hospitality',
        label: 'Halkidiki Hospitality',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 776,
        webhotelier_name: 'Halkidiki Hospitality',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 40900,
        value: 'linkedin',
        label: 'LinkedIn',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 782,
        webhotelier_name: 'LinkedIn',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 41000,
        value: 'facebook',
        label: 'Facebook Ads',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 784,
        webhotelier_name: 'Facebook Ads',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 41100,
        value: 'google',
        label: 'Google Hotel Free Booking Links',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 785,
        webhotelier_name: 'Google Hotel Free Booking Links',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 41200,
        value: 'instagram',
        label: 'Instagram',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 814,
        webhotelier_name: 'Instagram',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 41300,
        value: 'tiktok',
        label: 'TikTok',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 815,
        webhotelier_name: 'TikTok',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 41400,
        value: 'youtube',
        label: 'YouTube',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 816,
        webhotelier_name: 'YouTube',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 41500,
        value: 'santorini_view',
        label: 'Santorini View',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 822,
        webhotelier_name: 'Santorini View',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 41600,
        value: 'filoxenia_in_blue',
        label: 'Filoxenia in Blue',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 823,
        webhotelier_name: 'Filoxenia in Blue',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 41700,
        value: 'misterfly',
        label: 'MisterFly',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 824,
        webhotelier_name: 'MisterFly',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 41800,
        value: 'google',
        label: 'Google My Business',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 827,
        webhotelier_name: 'Google My Business',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 41900,
        value: 'holiways_villas',
        label: 'Holiways Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 832,
        webhotelier_name: 'Holiways Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 42000,
        value: 'lato_annex_rooms',
        label: 'Lato Annex Rooms',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 833,
        webhotelier_name: 'Lato Annex Rooms',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 42100,
        value: '2beds.com',
        label: '2beds.com',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 842,
        webhotelier_name: '2beds.com',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 42200,
        value: 'villas_reservation',
        label: 'Villas Reservation',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 843,
        webhotelier_name: 'Villas Reservation',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 42300,
        value: 'the_mykonist',
        label: 'The Mykonist',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 848,
        webhotelier_name: 'The Mykonist',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 42400,
        value: 'mg_bedbank',
        label: 'MG bedbank',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 849,
        webhotelier_name: 'MG bedbank',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 42500,
        value: 'mykonos_residence_channel',
        label: 'Mykonos Residence Channel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 851,
        webhotelier_name: 'Mykonos Residence Channel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 42600,
        value: 'the_villa_bookers',
        label: 'The Villa Bookers',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 853,
        webhotelier_name: 'The Villa Bookers',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 42700,
        value: 'bill_and_coo_hotels_channel',
        label: 'Bill & Coo Hotels Channel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 864,
        webhotelier_name: 'Bill & Coo Hotels Channel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 42800,
        value: 'orthodoxou_travel',
        label: 'Orthodoxou Travel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 867,
        webhotelier_name: 'Orthodoxou Travel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 42900,
        value: 'attika_reisen',
        label: 'Attika Reisen',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 868,
        webhotelier_name: 'Attika Reisen',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 43000,
        value: 'cieltech_internal_cm',
        label: 'CielTech Internal CM',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 870,
        webhotelier_name: 'CielTech Internal CM',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 43100,
        value: 'vivestia',
        label: 'Vivestia',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 875,
        webhotelier_name: 'Vivestia',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 43200,
        value: 'webadvisor',
        label: 'WebAdvisor',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 877,
        webhotelier_name: 'WebAdvisor',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 43300,
        value: 'villa_selection_by_travelstaytion',
        label: 'TravelStaytion',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 884,
        webhotelier_name: 'Villa Selection by TravelStaytion',
        rentability_id: 54,
        rentability_name: 'Travelstaytion',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 43400,
        value: 'verychic',
        label: 'VeryChic',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 888,
        webhotelier_name: 'VeryChic',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 43500,
        value: 'chic_retreats',
        label: 'Chic Retreats',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 898,
        webhotelier_name: 'Chic Retreats',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 43600,
        value: 'tui_international_holiday',
        label: 'TUI International Holiday',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 903,
        webhotelier_name: 'TUI International Holiday',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 43700,
        value: 'trivago_cpa',
        label: 'trivago CPA',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 904,
        webhotelier_name: 'trivago CPA',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 0
    },
    {
        id: 43800,
        value: 'sky_tours',
        label: 'Sky-tours',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 922,
        webhotelier_name: 'Sky-tours',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 43900,
        value: '18_24_travel',
        label: '18-24 Travel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 926,
        webhotelier_name: '18-24 Travel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 44000,
        value: 'avra_tours',
        label: 'AVRA Tours',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 927,
        webhotelier_name: 'AVRA Tours',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 44100,
        value: 'the_ace_vip_luxury_villas',
        label: 'The Ace VIP Luxury Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 929,
        webhotelier_name: 'The Ace VIP Luxury Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 44200,
        value: 'mykonos_luxury_villas',
        label: 'Mykonos Luxury Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 931,
        webhotelier_name: 'Mykonos Luxury Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 44300,
        value: 'thomas_cook',
        label: 'Thomas Cook',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 932,
        webhotelier_name: 'Thomas Cook',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 44400,
        value: 'euphoria_club',
        label: 'Euphoria Club',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 934,
        webhotelier_name: 'Euphoria Club',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 44500,
        value: 'olympic_kosma',
        label: 'Olympic Kosma',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 936,
        webhotelier_name: 'Olympic Kosma',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 44600,
        value: 'cielo_e_mare_villas',
        label: 'Cielo e Mare Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 939,
        webhotelier_name: 'Cielo e Mare Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 44700,
        value: 'saint_grec_luxury_concierge',
        label: 'Saint Grec Luxury Concierge',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 945,
        webhotelier_name: 'Saint Grec Luxury Concierge',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 44800,
        value: 'bespoke_villas',
        label: 'Bespoke Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 946,
        webhotelier_name: 'Bespoke Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 44900,
        value: 'philoxenia_collection',
        label: 'Philoxenia Collection',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 948,
        webhotelier_name: 'Philoxenia Collection',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 45000,
        value: 'quality_brand_villas',
        label: 'Quality Brand Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 949,
        webhotelier_name: 'Quality Brand Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 45100,
        value: 'elounda_seafront_villa',
        label: 'Elounda Seafront Villa',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 950,
        webhotelier_name: 'Elounda Seafront Villa',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 45200,
        value: 'villas_in_zante',
        label: 'Villas In Zante',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 953,
        webhotelier_name: 'Villas In Zante',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 45300,
        value: 'luxury_life_villa',
        label: 'Luxury Life Villa',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 954,
        webhotelier_name: 'Luxury Life Villa',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 45400,
        value: 'top_world_hotel',
        label: 'Top World Hotel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 965,
        webhotelier_name: 'Top World Hotel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 45500,
        value: 'mykonos_top_villas',
        label: 'Mykonos Top Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 967,
        webhotelier_name: 'Mykonos Top Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 45600,
        value: 'villas_of_mykonos',
        label: 'Villas of Mykonos',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 980,
        webhotelier_name: 'Villas of Mykonos',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 45700,
        value: 'athens_city_break_by_yonik_travel',
        label: 'Athens City Break by Yonik Travel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 984,
        webhotelier_name: 'Athens City Break by Yonik Travel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 45800,
        value: 'flight_centre_travel_group',
        label: 'Flight Centre Travel Group',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 986,
        webhotelier_name: 'Flight Centre Travel Group',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 45900,
        value: 'thalasso_no1',
        label: 'Thalasso N°1',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 987,
        webhotelier_name: 'Thalasso N°1',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 46000,
        value: 'bright_blue_villas_channel',
        label: 'Bright Blue Villas Channel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 989,
        webhotelier_name: 'Bright Blue Villas Channel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 46100,
        value: 'hommage_villa_collection_channel',
        label: 'Hommage Villa Collection Channel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 998,
        webhotelier_name: 'Hommage Villa Collection Channel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 46200,
        value: 'artemis_suites_santorini_channel',
        label: 'Artemis Suites Santorini Channel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1013,
        webhotelier_name: 'Artemis Suites Santorini Channel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 46300,
        value: 'panormos_bay_suites_channel',
        label: 'Panormos Bay Suites Channel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1016,
        webhotelier_name: 'Panormos Bay Suites Channel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 46400,
        value: 'cicada_suites',
        label: 'Cicada Suites',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1021,
        webhotelier_name: 'Cicada Suites',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 46500,
        value: 'classic_collection',
        label: 'Classic Collection',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1024,
        webhotelier_name: 'Classic Collection',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 46600,
        value: 'live_and_travel_greece',
        label: 'Live and Travel Greece',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1034,
        webhotelier_name: 'Live and Travel Greece',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 46700,
        value: 'beds_with_ease',
        label: 'Beds with Ease',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1035,
        webhotelier_name: 'Beds with Ease',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 46800,
        value: 'falasarna_luxury_villas',
        label: 'Falasarna Luxury Villas',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1036,
        webhotelier_name: 'Falasarna Luxury Villas',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 46900,
        value: 'alpha_mykonos',
        label: 'Alpha Mykonos',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1038,
        webhotelier_name: 'Alpha Mykonos',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 47000,
        value: 'hotelbrain_rentals_channel',
        label: 'HotelBrain Rentals Channel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1039,
        webhotelier_name: 'HotelBrain Rentals Channel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 47100,
        value: 'corfu_perfect_channel',
        label: 'Corfu Perfect Channel',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1046,
        webhotelier_name: 'Corfu Perfect Channel',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 47200,
        value: 'nordbeds',
        label: 'NordBeds',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1050,
        webhotelier_name: 'NordBeds',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 47300,
        value: 'xplore_cyprus',
        label: 'Xplore Cyprus',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1051,
        webhotelier_name: 'Xplore Cyprus',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 47400,
        value: 'easyjet_holidays',
        label: 'easyJet holidays',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1054,
        webhotelier_name: 'easyJet holidays',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 47500,
        value: 'trivago_free_booking_links',
        label: 'trivago Free Booking Links',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1057,
        webhotelier_name: 'trivago Free Booking Links',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 47600,
        value: 'centro_golden_view_villa',
        label: 'Centro Golden View Villa',
        source: 'webhotelier',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: 1060,
        webhotelier_name: 'Centro Golden View Villa',
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 47700,
        value: 'travelopo',
        label: 'Travelopo',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 57,
        rentability_name: 'Travelopo',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 47800,
        value: 'apartmentsapart',
        label: 'ApartmentsApart',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 8,
        rentability_name: 'ApartmentsApart',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 47900,
        value: 'goholidaylets',
        label: 'GoHolidayLets',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 7,
        rentability_name: 'GoHolidayLets',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 48000,
        value: 'holiday_velvet',
        label: 'Holiday Velvet',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 9,
        rentability_name: 'Holiday Velvet',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 48100,
        value: 'hotels.com',
        label: 'Hotels.com',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 44,
        rentability_name: 'Hotels.com',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 48200,
        value: 'short_stay_apartments',
        label: 'ShortStay-Apartments.com',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 40,
        rentability_name: 'ShortStay Apartment',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 48300,
        value: 'stayone',
        label: 'Stayone',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 65,
        rentability_name: 'Stayone',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 48400,
        value: 'airbnb.com',
        label: 'Airbnb Luxe',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 59,
        rentability_name: 'Airbnb Luxe',
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 48500,
        value: 'holidu',
        label: 'Holidu',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 52,
        rentability_name: 'Holidu',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 48600,
        value: 'vacagent',
        label: 'VacAgent',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 15,
        rentability_name: 'VacAgent',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 48700,
        value: 'hometogo',
        label: 'HomeToGo',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 51,
        rentability_name: 'HomeToGo',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 48800,
        value: 'tripping',
        label: 'Tripping',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 49,
        rentability_name: 'Tripping',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 48900,
        value: 'viloxenia',
        label: 'Viloxenia',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 28,
        rentability_name: 'Viloxenia',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 49000,
        value: 'vrbo',
        label: 'Homeaway Pay Per Booking',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 58,
        rentability_name: 'Homeaway Pay Per Booking',
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 49100,
        value: 'propertyfinder',
        label: 'PropertyFinder',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 23,
        rentability_name: 'PropertyFinder',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 49200,
        value: 'justproperty',
        label: 'JustProperty',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 22,
        rentability_name: 'JustProperty',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 49300,
        value: 'marriott',
        label: 'Marriott Credit Card',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 61,
        rentability_name: 'Marriott Credit Card',
        color_background: null,
        color_text: null,
        visible: 0,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 49400,
        value: 'bayut',
        label: 'Bayut',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 19,
        rentability_name: 'Bayut',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 49500,
        value: 'dubizzle',
        label: 'Dubizzle',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 20,
        rentability_name: 'Dubizzle',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 49600,
        value: 'bnbgreece',
        label: 'Bnbgreece',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 62,
        rentability_name: 'Bnbgreece',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 49700,
        value: 'kayak',
        label: 'Kayak',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 50,
        rentability_name: 'Kayak',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 49800,
        value: 'plum_guide',
        label: 'Plum Guide',
        source: 'rentability',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: 66,
        rentability_name: 'Plumguide',
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    },
    {
        id: 49900,
        value: 'tui',
        label: 'TUI',
        source: 'default',
        smoobu_id: null,
        smoobu_name: null,
        syncbnb_id: null,
        syncbnb_name: null,
        hostaway_id: null,
        hostaway_name: null,
        hotelavailabilities_id: null,
        hotelavailabilities_name: null,
        webhotelier_id: null,
        webhotelier_name: null,
        rentability_id: null,
        rentability_name: null,
        color_background: null,
        color_text: null,
        visible: 1,
        type: 'accommodation',
        has_booking_reference_id: 1
    }
];
