import { environment } from '../../../environments/environment';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as SecureStorage from 'secure-web-storage';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })

export class StorageService {

  public storage: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
  ) {


    // tslint:disable-next-line:curly
    if (isPlatformBrowser(this.platformId)) {

      this.storage = new SecureStorage(localStorage, {
        hash: function hash(key) {
          key = CryptoJS.SHA256(key, environment.params.suid);

          return key.toString();
        },

        // Encrypt the localstorage data
        encrypt: function encrypt(data) {
          try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), environment.params.suid).toString();
          } catch (e) {
            // console.log(e);
            return data;
          }


        },
        // Decrypt the encrypted data
        decrypt: function decrypt(data) {

          try {
            const bytes = CryptoJS.AES.decrypt(data, environment.params.suid);
            if (bytes.toString())
              return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return data;

          } catch (e) {
            // console.log(e);
            return data;
          }

        }
      });

    }

  }



  // Set the json data to local storage
  public setItem(key: string, value: any): void {
    localStorage.setItem(this.hash(key), this.encrypt(value));
    return;
  }

  // Get the json value from local storage
  public getItem(key: string) {

    try {
      return this.decrypt(localStorage.getItem(this.hash(key)));
    } catch (error) {
      return error.message;
    }

  }

  // Remove item from storage
  public removeItem(key: string): void {
    localStorage.removeItem(this.hash(key));
    return;
  }

  // Clear the local storage
  public clearStorage() {
    return localStorage.clear();
  }




  // Utils
  // Encrypt the localstorage data
  private encrypt(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), environment.params.suid).toString();
    } catch (e) {
      // console.log(e);
      return data;
    }

  }


  private decrypt(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, environment.params.suid);
      if (bytes.toString())
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      return data;

    } catch (e) {
      // console.log(e);
      return data;
    }

  }

  private hash(key) {
    key = CryptoJS.SHA256(key, environment.params.suid);

    return key.toString();
  }

}
