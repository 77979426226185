export class ServiceOccupancy {

    id?: number;
    service_id: string;
    service_type: 'accommodation';
    persons: number;
    adults: number;
    children: number;
    infants: number;

    constructor(props?: ServiceOccupancy) {
        this.id = props?.id || null;
        this.service_id = props?.service_id || null;
        this.service_type = props?.service_type || null;
        this.persons = props?.persons || 0;
        this.adults = props?.adults || 0;
        this.children = props?.children || 0;
        this.infants = props?.infants || 0;
    }

}
