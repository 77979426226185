import { SeoService } from '../../services/seo/seo-service.service';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { UtilsService } from '../../services/utils/utils.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../services/storage/storage.service';
import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Customer } from '../../models';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {

  private passwordResetToken: string;
  private passwordResetEmailAddress: string;

  public userNewPassword: string;
  public userNewPasswordConfirm: string;

  public isLoading = false;
  public isUpdating = false;
  public tokenIsValid = false;
  public queryParams: Params;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    public utilsService: UtilsService,
    private seo: SeoService,
    private ga: GoogleAnalyticsService,
    public translate: TranslateService
  ) {
    this.passwordResetToken = null;
    this.passwordResetEmailAddress = null;

    this.userNewPassword = null;
    this.userNewPasswordConfirm = null;
  }

  ngOnInit(): void {
    // this.ga.sendScreenName('Password Reset');

    this.seo.updatePageMetadata({ page: 'password_reset' });


    const customer: Customer = this.storageService.getItem('customer');

    if (customer && customer.id)
      this.router.navigate(['/']);


    this.queryParams = this.route.snapshot.queryParams;
    if (this.queryParams
      && this.queryParams.token
      && this.queryParams.token !== ''
      && this.queryParams.address
      && this.queryParams.address !== '') {

      this.passwordResetToken = this.queryParams.token;
      this.passwordResetEmailAddress = this.queryParams.address;

      this.verifyPasswordResetToken();

    }
    else
      this.router.navigate(['/']);

  }


  async verifyPasswordResetToken(): Promise<void> {

    this.isLoading = true;

    try {
      const response: any = await this.http.post(`${environment.params.host}/api/stay/password-reset-verify-token`, {
        token: this.passwordResetToken,
        address: this.passwordResetEmailAddress
      }).toPromise();

      if (response?.message === 'token_is_valid')
        this.tokenIsValid = true;
      else
        this.tokenIsValid = false;

      this.isLoading = false;

    } catch (error) {
      this.isLoading = false;
      this.router.navigate(['/login']);
    }

  }


  async resetPassword(): Promise<void> {

    this.isUpdating = true;

    try {

      const response: any = await this.http.post(`${environment.params.host}/api/stay/password-reset`, {
        password: this.userNewPassword,
        passwordConfirm: this.userNewPasswordConfirm,
        token: this.passwordResetToken,
        address: this.passwordResetEmailAddress
      }).toPromise();

      this.isUpdating = false;

      if (response?.message === 'password_changed')
        this.utilsService.swal.fire('Success', (await this.translate.get('PAGES.PASSWORD_RESET.LABELS.SUCCESS').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title), 'success')
          .then(() => {
            this.router.navigate(['/login']);
          });

    } catch (error) {

      if (!environment.production)
        console.log(error);

      this.isUpdating = false;
    }
  }

}
