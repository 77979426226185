import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-required-field-label',
  templateUrl: './required-field-label.component.html',
  styleUrls: ['./required-field-label.component.scss']
})
export class RequiredFieldLabelComponent implements OnInit {

  @Input() type: 'asterisk_tooltip' | 'input_invalid';

  constructor(
    private translate: TranslateService,
    public utilsService: UtilsService
  ) { }

  ngOnInit(): void {
  }

}
