import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-global-notifications',
  templateUrl: './global-notifications.component.html',
  styleUrls: ['./global-notifications.component.scss']
})
export class GlobalNotificationsComponent implements OnInit {

  @Input() notifications: string[];

  constructor(
    public utilsService: UtilsService,
  ) { }

  ngOnInit(): void {

  }

}
