export class IdentityDocument {
    id?: number;
    customer_id?: string;
    identity_type?: 'identity_card' | 'passport' | 'driving_license';
    identity_pic_filename?: string;
    identity_pic_url?: string;
    identity_pic_type?:
        'identity_card_front'
        | 'identity_card_back'
        | 'identity_card_selfie'
        | 'passport_page'
        | 'passport_selfie'
        | 'driving_license_front'
        | 'driving_license_selfie';
    checked?: boolean;
    checked_at?: Date;
    verified?: boolean;
    meta?: any; // json/string
    uploaded_at?: Date;

    constructor(props?: IdentityDocument) {
        this.customer_id = props?.customer_id || null;
        this.identity_type = props?.identity_type || null;
        this.identity_pic_filename = props?.identity_pic_filename || null;
        this.identity_pic_url = props?.identity_pic_url || null;
        this.identity_pic_type = props?.identity_pic_type || null;
        this.checked = props?.checked || null;
        this.checked_at = props?.checked_at || null;
        this.verified = props?.verified || null;
        this.meta = props?.meta || null;
        this.uploaded_at = props?.uploaded_at || null;
    }
}
