import { StayEnvironmentSettings } from '../../models';
import { DOCUMENT } from '@angular/common';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { environment } from '../../../environments/environment';
import { StorageService } from '../storage/storage.service';
import { Meta, Title } from '@angular/platform-browser';
import { Inject, Injectable } from '@angular/core';
import { PageService } from 'ngx-seo-page';
import { WebPageMetadata, Metadata, defaultKeywordsEN, defaultKeywordsEL } from './Metadata';
import { find } from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class SeoService {

  public appLanguage: 'en' | 'el' = null;
  public pagesMetas: WebPageMetadata[] = Metadata;
  private environmentSettings: StayEnvironmentSettings;

  constructor(
    @Inject(DOCUMENT) private dom,
    private storageService: StorageService,
    private pubSub: NgxPubSubService,
    private pageService: PageService,
    private metaService: Meta,
    private titleService: Title
  ) {

    const language = this.storageService.getItem('appLanguage');
    this.appLanguage = language ? language : 'en';


    this.pubSub.subscribe('changeLanguage', (lang) => { if (lang) this.appLanguage = lang; });
    this.pubSub.subscribe('environmentFetched', () => {
      if (window['trm_domain_config'])
        this.environmentSettings = window['trm_domain_config'];
    });

  }




  initializeMetadata(data: StayEnvironmentSettings): void { // called one time on app open

    this.environmentSettings = data;

    let keywords = defaultKeywordsEN;

    let locale = 'en_US';
    let locale_alt = 'el_GR';

    if (this.appLanguage === 'el') {
      keywords = defaultKeywordsEL;
      locale = 'el_GR';
      locale_alt = 'en_US';
    }

    // Fully furnished apartments for mid-term rent
    this.titleService.setTitle(`${data.app_title} - ${data.seo.subtitle[this.appLanguage]}`);

    this.metaService.addTags([
      // Basic metas
      { name: 'description', content: data.seo.description[this.appLanguage] },
      { name: 'keywords', content: data.seo.keywords[this.appLanguage] },
      { name: 'robots', content: data.seo.robots },

      // OpenGraph
      { property: 'og:title', content: data.seo['og:title'][this.appLanguage] },
      { property: 'og:description', content: data.seo['og:description'][this.appLanguage] },
      { property: 'og:locale', content: data.seo['og:locale'] },
      { property: 'og:locale:alternate', content: data.seo['og:locale:alternate'] },
      { property: 'og:type', content: data.seo['og:type'] },
      { property: 'og:url', content: data.seo['og:url'][this.appLanguage] },
      { property: 'og:site_name', content: data.seo['og:site_name'][this.appLanguage] },
      { property: 'og:image', content: data.seo['og:image'][this.appLanguage] },

      // Twitter
      { name: 'twitter:card', content: data.seo['twitter:card'] },
      { name: 'twitter:description', content: data.seo['twitter:description'][this.appLanguage] },
      { name: 'twitter:title', content: data.seo['twitter:title'][this.appLanguage] },
      // { name: 'twitter:site', content: `@tourmie` }, // enable when we create Twitter profile
      // { name: 'twitter:creator', content: `@tourmie` }, // enable when we create Twitter profile

    ]);

    this.pageService.updatePage({
      schema: {
        '@type': 'WebSite',
        name: data.app_title,
        url: `https://${data.domain}`
      },
      canonical: `https://${data.domain}`
    });


    // set favicon
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = data.favicon.url;
    document.getElementsByTagName('head')[0].appendChild(link);

  }


  updatePageMetadata(data?: WebPageMetadata): void { // called one time on app open

    if (!this.environmentSettings)
      return;

    let keywords = defaultKeywordsEN;

    let locale = 'en_US';
    let locale_alt = 'el_GR';

    if (this.appLanguage === 'el') {
      keywords = defaultKeywordsEL;
      locale = 'el_GR';
      locale_alt = 'en_US';
    }


    let page: WebPageMetadata = null;

    if (data?.page) {
      page = find(this.pagesMetas, (record) => record.page === data.page);

      if (page?.page && this.appLanguage === 'el') {
        page.title = page.title_el || page.title;
        page.description = (page.description_el || page.description).replace(/Tourmie Stay/, this.environmentSettings?.app_title || environment.params.appTitle);
        page.keywords = page.keywords_el || page.keywords;
        page.image = page.image_el || page.image;
      }

    }

    let title = `${page?.title || data?.title || ''} - ${this.environmentSettings?.app_title || environment?.params?.appTitle || 'Tourmie Stay'}`;

    if (data?.page === 'home')
      title = `${this.environmentSettings?.app_title || environment.params.appTitle} - ${page?.title || data?.title || ''}`;


    this.titleService.setTitle(title);

    // Basic metas
    this.metaService.updateTag({ name: 'description', content: (page?.description || data?.description || `Tourmie is an online booking platform for mid-term stays of fully furnished apartments and houses, rented from one month up to one year.`).replace(/Tourmie Stay/, this.environmentSettings?.app_title || environment.params.appTitle) });
    this.metaService.updateTag({ name: 'keywords', content: `${page?.keywords || data?.keywords || keywords}` });
    this.metaService.updateTag({ name: 'robots', content: 'index, follow' });

    // OpenGraph
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: (page?.description || data?.description || `Tourmie is an online booking platform for mid-term stays of fully furnished apartments and houses, rented from one month up to one year.`).replace(/Tourmie Stay/, this.environmentSettings?.app_title || environment.params.appTitle) });
    this.metaService.updateTag({ property: 'og:locale', content: locale });
    this.metaService.updateTag({ property: 'og:locale:alternate', content: locale_alt });
    this.metaService.updateTag({ property: 'og:type', content: `website` });
    this.metaService.updateTag({ property: 'og:url', content: this.environmentSettings?.domain ? `https://${this.environmentSettings.domain}` : environment.params.host_frontend });
    this.metaService.updateTag({ property: 'og:site_name', content: this.environmentSettings?.app_title || environment.params.appTitle });
    this.metaService.updateTag({ property: 'og:image', content: page?.image || data?.image || `${environment.params.host_frontend}/assets/img/seo/og-tourmie-stay.png` });

    // Twitter
    this.metaService.updateTag({ name: 'twitter:card', content: `summary_large_image` });
    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:description', content: (page?.description || data?.description || `Tourmie is an online booking platform for mid-term stays of fully furnished apartments and houses, rented from one month up to one year.`).replace(/Tourmie Stay/, this.environmentSettings?.app_title || environment.params.appTitle) });
    // this.metaService.updateTag({ name: 'twitter:site', content: `@tourmie` }); // enable when we create Twitter profile
    // this.metaService.updateTag({ name: 'twitter:creator', content: `@tourmie` }); // enable when we create Twitter profile


    this.pageService.updatePage({
      title: title,
      schema: {
        '@type': 'WebSite',
        name: this.environmentSettings?.app_title || environment.params.appTitle,
        url: this.environmentSettings?.domain ? `https://${this.environmentSettings.domain}` : environment.params.host_frontend
      },
      canonical: this.environmentSettings?.domain ? `https://${this.environmentSettings.domain}` : environment.params.host_frontend
    });


  }


}
