import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {

    transform(value: string, limit: number, completeWords: boolean = false, ellipsis: string = '...') {
        let limit2;
        if (completeWords)
            limit2 = value.substr(0, limit).lastIndexOf(' ');

        if (limit2 > 0)
            limit = limit2;

        if (limit2 <= 0)
            ellipsis = '';

        return `${value.substr(0, limit)}${ellipsis}`;
    }

}
