<div class="form-group m-0" *ngIf="appLanguages.length > 1">
    <label *ngIf="!hideLabel" class="mb-1" for="language">
        <span>Language</span>
    </label>
    <ng-select id="language" class="language-switch-select" placeholder="Language" [items]="appLanguages" [multiple]="false" bindLabel="language"
        bindValue="code" [closeOnSelect]="true" [(ngModel)]="appLanguage" [searchable]="false" [clearable]="false" name="language" #language="ngModel"
        (change)="onLanguageChange()">
        <ng-template ng-option-tmp let-item="item">
            <img height="24" width="24" [src]="item.flag_url" />
            <span class="ml-2" *ngIf="!onlyFlag">{{item.language}}</span>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
            <img height="24" width="24" [src]="item.flag_url" />
            <span class="ml-2" *ngIf="!onlyFlag">{{item.language}}</span>
        </ng-template>
    </ng-select>
</div>