import { enableProdMode, PLATFORM_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { isPlatformBrowser } from '@angular/common';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production)
  enableProdMode();

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() => {

      // if (environment.production)
      //   if ('serviceWorker' in navigator)
      //     navigator.serviceWorker.register('/combined-sw.js');

    })
    .catch(err => console.error(err));
});
