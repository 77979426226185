import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../services/utils/utils.service';

interface MailChimpResponse {
  result: string;
  msg: string;
}

interface FormData {
  email: string;
  type: '1' | '2';
}

interface NewsletterQueryParams {
  u: string;
  'amp;id': string;
  EMAIL?: string;
  'group[30869]'?: string;
  'b_974a4a43988ba15a188636e07_ab1faa7da3'?: string;
}


@Component({
  selector: 'app-mail-subscription-form',
  templateUrl: './mail-subscription-form.component.html',
  styleUrls: ['./mail-subscription-form.component.scss']
})
export class MailSubscriptionFormComponent implements OnInit, OnDestroy {

  @Input() labelFontSize: string; // font size for heading
  @Input() style: 'footer' | 'modal'; // style: 'footer'

  submitted = false;
  mailChimpEndPoint = 'https://tourmie.us5.list-manage.com/subscribe/post-json';

  public formData: FormData = {
    email: '',
    type: '1'
  };

  private mailchimpParams: NewsletterQueryParams;
  public mailchimpResponse: MailChimpResponse = { result: null, msg: null };

  public isMobile = false;
  private isMobileSubscription: Subscription;


  // private mailChimpURL = 'https://tourmie.us5.list-manage.com/subscribe/post-json?u=974a4a43988ba15a188636e07&amp;id=ab1faa7da3&EMAIL=test@tester.gr&c=ng_jsonp_callback_0';

  constructor(
    private http: HttpClient,
    public utilsService: UtilsService,
    public translate: TranslateService) {

    this.isMobile = this.utilsService.isMobile;

    if (!this.labelFontSize)
      this.labelFontSize = '20';
  }


  ngOnInit(): void {

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

  }



  ngOnDestroy(): void {

    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

  }



  async submitForm(): Promise<void> {

    this.mailchimpResponse = { result: null, msg: null };

    try {

      this.mailchimpParams = {
        u: '974a4a43988ba15a188636e07',
        'amp;id': 'ab1faa7da3',
        EMAIL: this.formData.email,
        'group[30869]': this.formData.type,
        b_974a4a43988ba15a188636e07_ab1faa7da3: ''
      };

      const finalMailChimpURL = `${this.mailChimpEndPoint}/${this.utilsService.qs.stringify(this.mailchimpParams, {
        addQueryPrefix: true,
        charset: 'utf-8',
        charsetSentinel: true
      })}`;

      this.mailchimpResponse = await this.http.jsonp<MailChimpResponse>(finalMailChimpURL, 'c').toPromise();

      return Promise.resolve();

    }
    catch (error) {

      if (!environment.production)
        console.log(error);

      this.mailchimpResponse = { result: 'error', msg: 'Something went wrong try again' };
      return Promise.resolve();

    }
  }

}
