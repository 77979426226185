import { SweetAlertOptions } from 'sweetalert2';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Customer, CustomerProfileUpdate } from '../../../../../models';
import { environment } from '../../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from '../../../../../services/utils/utils.service';
import { GraphqlService } from '../../../../../services/graphql/graphql.service';
import { SeoService } from '../../../../../services/seo/seo-service.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-customer-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit, OnDestroy {

  @Input() customer: Customer;

  public isMobile = false;
  private isMobileSubscription: Subscription;

  public profileUpdateData = new CustomerProfileUpdate();
  public isSubmittingProfileInfoUpdate = false;
  public isSubmittingPasswordUpdate = false;
  public isSubmittingIdentityData = false;

  // Phone input
  public SearchCountryField = SearchCountryField;
  public TooltipLabel = TooltipLabel;
  public CountryISO = CountryISO;
  public preferredCountries: CountryISO[] = [CountryISO.Greece];
  public customerPhone;

  // Date of birth
  public minDateOfBirth: string = null;
  public maxDateOfBirth: string = null;

  // Identity type
  public identityTypeOptions = environment.identity_verification.types;
  public identityImages: File[] | any[] = [];
  public imageMaxFileSize = environment.params.imagesMaxFileSize;
  public identityImageTypeOptions = environment.identity_verification.image_types;

  constructor(
    private http: HttpClient,
    private router: Router,
    public translate: TranslateService,
    private seo: SeoService,
    public utilsService: UtilsService,
    private graphqlService: GraphqlService
  ) {

    this.isMobile = this.utilsService.isMobile;

    this.minDateOfBirth = this.utilsService.moment().subtract(100, 'years').format('YYYY-MM-DD');
    this.maxDateOfBirth = this.utilsService.moment().subtract(18, 'years').format('YYYY-MM-DD');

  }

  async ngOnInit(): Promise<void> {

    this.seo.updatePageMetadata({ page: `profile` });

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    if (this.customer?.customer_id) {
      this.profileUpdateData.email = this.customer.email;
      this.profileUpdateData.birth_date = this.customer.birth_date;
      this.profileUpdateData.identity_type = this.customer.identity_type;
      this.profileUpdateData.identity_card_number = this.customer.identity_card_number;
      this.profileUpdateData.passport_number = this.customer.passport_number;
      this.profileUpdateData.driving_license_number = this.customer.driving_license_number;
      this.customerPhone = this.customer.phone; // for ngx-intl-tel-input

      try {

        const response = await this.graphqlService.getCustomerData({ customer_id: this.customer.customer_id });

        this.customer = response;

      }
      catch (error) {

        if (!environment.production)
          console.log(error);

      }

      // Update from DB
      this.profileUpdateData.birth_date = this.customer.birth_date;
      this.profileUpdateData.customer_id = this.customer.customer_id;
      this.profileUpdateData.first_name = this.customer.first_name;
      this.profileUpdateData.last_name = this.customer.last_name;
      this.profileUpdateData.email = this.customer.email;
      this.profileUpdateData.phone = this.customer.phone;
      this.profileUpdateData.birth_date = this.customer.birth_date;
      this.profileUpdateData.identity_type = this.customer.identity_type;
      this.profileUpdateData.identity_card_number = this.customer.identity_card_number;
      this.profileUpdateData.passport_number = this.customer.passport_number;
      this.profileUpdateData.driving_license_number = this.customer.driving_license_number;
      this.customerPhone = this.customer.phone; // for ngx-intl-tel-input

    }

    this.initIdentityImageTypeOptions();



  }


  ngOnDestroy(): void {

    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

  }



  async submitProfileUpdate(): Promise<void> {

    const dateOfBirthIsValid = await this.birthDateIsValid();
    if (!dateOfBirthIsValid)
      return Promise.resolve();

    this.isSubmittingProfileInfoUpdate = true;

    try {

      const profileUpdateResponse = await this.http.put<Customer>(`${environment.params.host}/api/stay/customer-profile`, {
        phone: this.profileUpdateData.phone,
        birth_date: this.profileUpdateData.birth_date
      }).toPromise();

      this.utilsService.showAlert({
        html: (await this.translate.get('PAGES.CUSTOMER_PROFILE.ALERTS.PROFILE_INFO_UPDATED').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
        icon: 'success',
        showConfirmButton: false,
        timer: 2500
      });

      this.customer = await this.utilsService.refreshCustomerData();

      this.isSubmittingProfileInfoUpdate = false;

      this.router.navigate(['/'], { skipLocationChange: true }).then(() => {
        this.router.navigate(['/profile']);
      });

    }
    catch (error) {

      if (!environment.production)
        console.log(error);

      this.isSubmittingProfileInfoUpdate = false;

    }

  }



  async submitPasswordUpdate(): Promise<void> {

    this.isSubmittingPasswordUpdate = true;

    try {

      const profileUpdateResponse = await this.http.put<Customer>(`${environment.params.host}/api/stay/customer-profile`, {
        old_password: this.profileUpdateData.old_password,
        new_password: this.profileUpdateData.new_password,
        new_password_confirm: this.profileUpdateData.new_password_confirm,
      }).toPromise();

      this.isSubmittingPasswordUpdate = false;

      await this.utilsService.showAlert({
        html: (await this.translate.get('PAGES.CUSTOMER_PROFILE.ALERTS.PROFILE_PASSWORD_UPDATED').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
        icon: 'success',
        showConfirmButton: false,
        timer: 2500
      });

      this.router.navigate(['/'], { skipLocationChange: true }).then(() => {
        this.router.navigate(['/profile']);
      });

    }
    catch (error) {

      if (!environment.production)
        console.log(error);

      this.isSubmittingPasswordUpdate = false;

    }


  }



  async submitIdentityForm(): Promise<void> {

    this.isSubmittingIdentityData = true;

    try {

      // 1. update details in db table
      const response = await this.http.put(`${environment.params.host}/api/stay/customer-identity-details`, {
        identity_type: this.profileUpdateData.identity_type,
        identity_card_number: this.profileUpdateData.identity_card_number,
        passport_number: this.profileUpdateData.passport_number,
        driving_license_number: this.profileUpdateData.driving_license_number,
      }).toPromise();


      // 2. upload identity images
      const imageUploadResponse = await this.uploadIdentityImages();

      if (this.identityImages.length > 0)
        try {
          await this.http.post(`${environment.params.host}/api/stay/verify-customer-identity-docs`, {}).toPromise();
        } catch (error) {

        }

      // 3. update customer data from db
      this.customer = await this.utilsService.refreshCustomerData();

      this.isSubmittingIdentityData = false;


      const alertOptions: SweetAlertOptions = {
        html: (await this.translate.get('PAGES.CUSTOMER_PROFILE.ALERTS.PROFILE_IDENTITY_SUBMIT').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
        icon: 'info',
      };

      const alertResult = await this.utilsService.swal.fire(alertOptions);

      if (alertResult.isConfirmed || alertResult.isDismissed)
        this.router.navigate(['/'], { skipLocationChange: true }).then(() => {
          this.router.navigate(['/profile']);
        });

    } catch (error) {

      if (!environment.production)
        console.log(error);

      this.isSubmittingIdentityData = false;

    }

  }



  async birthDateIsValid(): Promise<boolean> {

    if (this.profileUpdateData.birth_date)
      if (
        this.utilsService.moment(this.profileUpdateData.birth_date).format('YYYY-MM-DD') > this.maxDateOfBirth
        ||
        this.utilsService.moment(this.profileUpdateData.birth_date).format('YYYY-MM-DD') < this.minDateOfBirth
      ) {
        this.utilsService.showAlert({
          title: 'Error',
          html: (await this.translate.get('PAGES.CUSTOMER_PROFILE.ALERTS.PROFILE_INVALID_BIRTH_DATE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          icon: 'error'
        });
        this.profileUpdateData.birth_date = null;
        return Promise.resolve(false);
      }

    return Promise.resolve(true);

  }



  onPhoneChange(): void {
    this.profileUpdateData.phone = this.customerPhone?.internationalNumber ? this.customerPhone.internationalNumber : null;
  }



  onIdentityTypeChange(): void {
    this.profileUpdateData.identity_card_number = null;
    this.profileUpdateData.passport_number = null;
    this.profileUpdateData.driving_license_number = null;

    this.initIdentityImageTypeOptions();


  }



  async onIdentityImageSelect(event: NgxDropzoneChangeEvent): Promise<void> {


    // tslint:disable-next-line:curly
    if (event?.addedFiles && event.addedFiles.length > 0) {

      for (const file of event.addedFiles as any[]) { // for each images selected

        // Check file size
        if (file.size > environment.params.imagesMaxFileSize) { // file size exceeded

          const msg = (await this.translate.get('PAGES.CUSTOMER_PROFILE.ALERTS.PROFILE_IDENTITY_SIZE_ERROR').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title);

          this.utilsService.showAlert({
            html: `${msg} ${environment.params.imagesMaxFileSize / 1024 / 1024} MB (${file.name})`,
            icon: 'error'
          });

          continue;

        }

        const files: any[] = Array.from(event.addedFiles);

        const reader = new FileReader();

        reader.onload = async (e) => {

          // const serviceExtraImageDimensionsAreValid = await this.utilsService.imageIsBelow2K(e.target.result as string);

          // if (serviceExtraImageDimensionsAreValid) {

          file.url = e.target.result;
          this.identityImages.push(file);

          // }
          // else {

          //   this.utilsService.showAlert({
          //     html: `The image is too large. The maximum dimensions are 2560x1440 pixels (${file.name})`,
          //     icon: 'error'
          //   });


          //   for (let i = 0; i < files.length; i++)
          //     if (file.name === files[i].name && file.size === files[i].size)
          //       files.splice(i, 1);

          // }

        };



        reader.readAsDataURL(file);

      } // end of backgrounds loop

    }

  }



  onIdentityImageRemove(file: File): void {
    this.identityImages = this.utilsService.lodash.filter(this.identityImages, (img) => img.name !== file.name);
  }



  async uploadIdentityImages(): Promise<void> {

    try {

      if (this.identityImages.length > 0) {

        if (this.identityImages.length > 10) {

          this.utilsService.showAlert({
            html: (await this.translate.get('PAGES.CUSTOMER_PROFILE.ALERTS.PROFILE_IDENTITY_LIMIT').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
            icon: 'error'
          });

          return Promise.resolve();

        }


        for (const image of this.identityImages) {

          const reqHeaders: HttpHeaders = new HttpHeaders();
          reqHeaders.append('Content-Type', 'multipart/form-data');

          const formData = new FormData();

          // Image to send
          formData.append('image', image, image.name);
          formData.append('identity_pic_type', image.identity_pic_type);
          formData.append('customer_id', this.customer.customer_id);
          formData.append('identity_type', this.profileUpdateData.identity_type);

          const response: any = await this.http.post(`${environment.params.host}/api/stay/customer-identity-document`, formData, { headers: reqHeaders }).toPromise();

        }


      }

      return Promise.resolve();

    } catch (error) {

      if (!environment.production)
        console.log(error);

      return Promise.reject(error);
    }

  }



  initIdentityImageTypeOptions(): void {

    if (this.profileUpdateData?.identity_type === 'identity_card')
      this.identityImageTypeOptions = this.utilsService.lodash.filter(environment.identity_verification.image_types, (type) => type.type === 'identity_card');

    if (this.profileUpdateData?.identity_type === 'passport')
      this.identityImageTypeOptions = this.utilsService.lodash.filter(environment.identity_verification.image_types, (type) => type.type === 'passport');

    if (this.profileUpdateData?.identity_type === 'driving_license')
      this.identityImageTypeOptions = this.utilsService.lodash.filter(environment.identity_verification.image_types, (type) => type.type === 'driving_license');

  }


}
