import { Router } from '@angular/router';
import { UtilsService } from '../../services/utils/utils.service';
import { StorageService } from '../../services/storage/storage.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private storageService: StorageService,
    private translate: TranslateService
  ) { }

  async ngOnInit(): Promise<void> {

    await this.utilsService.logout();
    this.router.navigate(['/']);

    //   this.utilsService.showToast(await this.translate.get('PAGES.LOGOUT.ALERTS.SUCCESS').toPromise(), 'Notification', 'success', 3000);

    return Promise.resolve();

  }

}
