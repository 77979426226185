export const identity_verification_types = [
    { label: 'Identity card', label_el: 'Αστυνομική ταυτότητα', value: 'identity_card' },
    { label: 'Passport', label_el: 'Διαβατήριο', value: 'passport' },
    { label: 'Driving license', label_el: 'Δίπλωμα οδήγησης', value: 'driving_license' },
];



export const image_typesidentity_image_types = [
    { label: 'ID card front side', label_el: 'Μπροστινή πλευρά της αστυνομικής ταυτότητας', value: 'identity_card_front', type: 'identity_card' },
    { label: 'ID card back side', label_el: 'Πίσω πλευρά της αστυνομικής ταυτότητας', value: 'identity_card_back', type: 'identity_card' },
    { label: 'Selfie picture', label_el: 'Selfie φωτογραφία', value: 'identity_card_selfie', type: 'identity_card' },
    { label: 'Passport page', label_el: 'Σελίδα διαβατηρίου', value: 'passport_page', type: 'passport' },
    { label: 'Selfie picture', label_el: 'Selfie φωτογραφία', value: 'passport_selfie', type: 'passport' },
    { label: 'License card front side', label_el: 'Μπροστινή πλευρά του διπλώματους οδήγησης', value: 'driving_license_front', type: 'driving_license' },
    { label: 'Selfie picture', label_el: 'Selfie φωτογραφία', value: 'driving_license_selfie', type: 'driving_license' },
];
