import { environment } from '../../../../environments/environment';
import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../../services/utils/utils.service';
import { StorageService } from '../../../services/storage/storage.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { Customer } from '../../../models';

declare const $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  public hasScrolled: boolean;
  public isAtHome: boolean;

  public customer: Customer = new Customer();

  public isMobile: boolean = window.innerWidth <= 991.98;
  private isMobileSubscription: Subscription;

  private customerUpdatedSubscription: Subscription;
  private routerEventsSubscription: Subscription;

  public environment = environment;


  public globalNotifications: string[] = [];
  private betaNotificationAllowedBannerPages = ['/properties', '/properties?'];
  private betaNotificationBlockedBannerPages = ['/properties/'];

  @HostListener('window:scroll')
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100)
      this.hasScrolled = true;

    else if (this.hasScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10)
      this.hasScrolled = false;
  }

  constructor(
    public utilsService: UtilsService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private pubSub: NgxPubSubService,
    public translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    // this.route.snapshot.url
    if (this.router.url === '/')
      this.isAtHome = true;

    this.globalNotifications = [];
    this.checkBetaNotificationVisibility();


    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    this.pubSub.subscribe('environmentFetched', () => {

    });

    this.customer = this.storageService.getItem('customer');

    this.customerUpdatedSubscription = this.pubSub.subscribe('customerDataUpdated', async (customer: Customer) => {

      this.customer = customer;

    });


    this.routerEventsSubscription = this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd)
        this.checkBetaNotificationVisibility();

    });

  }

  ngOnDestroy(): void {

    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

    if (this.customerUpdatedSubscription)
      this.customerUpdatedSubscription.unsubscribe();

    if (this.routerEventsSubscription)
      this.routerEventsSubscription.unsubscribe();

  }


  @HostListener('click', ['$event.target'])
  onMobileNavbarItemClick(element: HTMLElement) {

    if (this.isMobile && element?.classList.contains('nav-link') || element?.classList.contains('navbar-brand'))
      $('#navbarCollapse').collapse('hide');

  }



  checkBetaNotificationVisibility(): void {

    // tslint:disable-next-line:curly
    if (this.utilsService.isBrowser) {

      if (environment.params.showBetaNotification) {

        const isInAllowed = this.betaNotificationAllowedBannerPages.some(url => this.router.url.indexOf(url) >= 0);
        const isInBlocked = this.betaNotificationBlockedBannerPages.some(url => this.router.url.indexOf(url) >= 0);

        if (((isInAllowed && !isInBlocked) || this.router.url === '/') && !this.globalNotifications.some(n => n === 'beta')) {

          // this.globalNotifications.push('beta');
          // document.body.classList.add('notifications');

        }


      }

    }

  }


}
