import { LaddaModule } from 'angular2-ladda';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordPageComponent } from './reset-password.component';
import { ResetPasswordPageRoutingModule } from './reset-password-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../../components/components.module';

import { PipesModule } from '../../pipes/pipes.module';


@NgModule({
  declarations: [ResetPasswordPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ResetPasswordPageRoutingModule,
    LaddaModule,
    TranslateModule,
    ComponentsModule,
    PipesModule
  ]
})
export class ResetPasswordModule { }
