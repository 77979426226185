import { Injectable } from '@angular/core';
import { Customer, Property } from '../../models';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }


  // See customer reviews on a property doesn't need the user to be signed in
  async getPropertyReviews(): Promise<void> {
    try {


      return Promise.resolve();

    } catch (error) {
      return Promise.reject(error);
    }
  }

  // Submit review for property - must be signed in && have an active/completed booking on property
  async submitPropertyReview(): Promise<void> {
    try {
      const customer: Customer = this.storageService.getItem('customer');

      if (!customer?.customer_id)
        return Promise.resolve();


      return Promise.resolve();

    } catch (error) {
      return Promise.reject(error);
    }
  }


  // Profile display of reviews submitted by a customer
  async getCustomerReviews(): Promise<void> {
    try {
      const customer: Customer = this.storageService.getItem('customer');

      if (!customer?.customer_id)
        return Promise.resolve();


      return Promise.resolve();

    } catch (error) {
      return Promise.reject(error);
    }
  }





}
