import { PipesModule } from '../../../pipes/pipes.module';
import { ComponentsModule } from '../../../components/components.module';
import { NgModule } from '@angular/core';
import { LaddaModule } from 'angular2-ladda';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RegistrationHostsComponent } from './registration-hosts.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RegistrationHostsComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: RegistrationHostsComponent
      }
    ]),
    LaddaModule,
    NgSelectModule,
    TranslateModule,
    ComponentsModule,
    NgxIntlTelInputModule,
    TooltipModule,
    PipesModule,
  ]
})
export class RegistrationHostsPageModule { }
