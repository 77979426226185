import { Address } from './Address';
import { ViesData } from './VIES';
import { Account, AccountCategoryValue, AccountTypeValue } from './Account';
import { User } from './User';

export class GuestRegistrationData {
    // User/customer
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    password?: string;
    password_confirm?: string;
    language?: 'el' | 'en';
    country?: string;
    address?: Address;
    type?: AccountTypeValue;
    business_name?: string;
    business_email?: string;
    business_phone?: string;
    business_title?: string;
    account_category?: AccountCategoryValue;
    tax_id?: string;
    tax_authority?: string;
    vies_data?: ViesData;
    birth_date: Date;
    identity_type?: 'passport' | 'driving_license' | 'identity_card';
    identity_card_number?: string;
    passport_number?: string;
    driving_license_number?: string;


    constructor(props?: GuestRegistrationData) {
        this.first_name = props?.first_name || null;
        this.last_name = props?.last_name || null;
        this.email = props?.email || null;
        this.phone = props?.phone || null;
        this.password = props?.password || null;
        this.password_confirm = props?.password_confirm || null;
        this.language = props?.language || null;
        this.country = props?.country || null;
        this.type = props?.type || 'individual';
        this.tax_id = props?.tax_id || null;
        this.tax_authority = props?.tax_authority || null;
        this.business_name = props?.business_name || null;
        this.business_title = props?.business_title || null;
        this.business_email = props?.business_email || null;
        this.business_phone = props?.business_phone || null;
        this.account_category = props?.account_category || null;
        this.identity_card_number = props?.identity_card_number || null;
        this.passport_number = props?.passport_number || null;
        this.driving_license_number = props?.driving_license_number || null;
        this.birth_date = props?.birth_date || null;
        this.address = new Address(props?.address || null);
    }
}



export class HostRegistrationData {

    user: User;
    account?: Account;
    options?: DemoAccountRegistrationDataOptions;

    constructor(props?: HostRegistrationData) {

        this.user = new User(props?.user || null);
        this.account = new Account(props?.account || null);
        this.options = new DemoAccountRegistrationDataOptions(props?.options || null);

    }

}



export class DemoAccountRegistrationData {

    user: User;
    account?: Account;
    options?: DemoAccountRegistrationDataOptions;

    constructor(props?: DemoAccountRegistrationData) {

        this.user = new User(props?.user || null);
        this.account = new Account(props?.account || null);
        this.options = props?.options || new DemoAccountRegistrationDataOptions();
    }

}



export class DemoAccountRegistrationDataOptions {

    airbnb?: {
        listings?: {
            urls?: string[];
            identifiers?: string[];
        }
    };

    verification?: { code?: string; };

    form?: 'accommodation_provider' | 'service_provider';

    constructor(props?: DemoAccountRegistrationDataOptions) {

        this.airbnb = props?.airbnb || {
            listings: {
                urls: [],
                identifiers: [],
            },
        };

        this.verification = props?.verification || { code: null };

        this.form = props?.form || null;

    }

}



export class UserOnboardingData {
    id?: number;
    uuid?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    password?: string;
    country?: string;
    meta?: DemoAccountRegistrationData;
    email_verified?: boolean;
    user_id?: string; // associated user
    created_at?: string | Date;


    constructor(props?: UserOnboardingData) {

        this.id = props?.id || null;
        this.uuid = props?.uuid || null;
        this.first_name = props?.first_name || null;
        this.last_name = props?.last_name || null;
        this.email = props?.email || null;
        this.phone = props?.phone || null;
        this.password = props?.password || null;
        this.country = props?.country || null;
        this.meta = props?.meta && typeof props?.meta === 'string' ? JSON.parse(props.meta) : props?.meta && typeof props.meta !== 'string' ? props.meta : null;
        this.email_verified = props?.email_verified ? true : false;
        this.user_id = props?.user_id || null;
        this.created_at = props?.created_at || null;

    }

}
