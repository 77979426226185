import { environment } from '../../../../../../environments/environment';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Customer, Property } from '../../../../../models';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../../../../services/utils/utils.service';
import { FavoritesService } from '../../../../../services/favourites/favourites.service';
import { SeoService } from '../../../../../services/seo/seo-service.service';
@Component({
  selector: 'app-customer-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent implements OnInit, OnDestroy {

  @Input() customer: Customer;

  public isMobile = false;
  private isMobileSubscription: Subscription;

  public favorites: Property[];
  public favoritesLoading: boolean;

  public reviewMaxRating: number = environment.params.reviewMaxRating;
  private undoTimerDuration: number = environment.params.favoriteUndoDuration;

  constructor(
    public utilsService: UtilsService,
    private seo: SeoService,
    private favoriteService: FavoritesService
  ) {

    this.isMobile = this.utilsService.isMobile;

  }

  async ngOnInit(): Promise<void> {

    this.seo.updatePageMetadata({ page: `favorites` });

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });


    this.favorites = [];
    this.favoritesLoading = true;

    try {

      this.favorites = await this.favoriteService.getFavorites();

      if (!environment.production)
        console.log(this.favorites);

    } catch (error) {

      if (!environment.production)
        console.log(error);

    }

    this.favoritesLoading = false;
  }

  ngOnDestroy(): void {
    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();
  }


  async removeFromFavorites(e: Event, index: number): Promise<void> {

    if (this.utilsService.isBrowser) {

      e.preventDefault();

      try {

        this.favorites[index].removedFromFavorites = true;

        this.favorites[index].timerRef = setTimeout(async () => {

          await this.favoriteService.removeFavorite(this.favorites[index].service_id);

          this.favorites.splice(index, 1);

        }, this.undoTimerDuration);

      } catch (error) {

        if (!environment.production)
          console.log(error);

      }

    }

  }

  async undoFavoriteRemoval(index: number): Promise<void> {

    if (this.utilsService.isBrowser) {

      clearTimeout(this.favorites[index].timerRef);

      this.favorites[index].removedFromFavorites = false;

    }
  }
}
