import { Language } from '../app/models';

export let languages: Language[] = [
    {
        code: 'aa',
        name: 'Afar',
        name_el: null,
        name_es: 'Afar',
        name_it: 'Afar',
        name_fr: 'Afar',
        name_de: 'Afar',
        name_ca: null,
        native: 'Afar',
        native_el: 'Afar',
        native_es: 'Afar',
        native_it: 'Afar',
        native_fr: 'Afar',
        native_de: 'Afar',
        native_ca: 'Afar',
        rtl: null
    },
    {
        code: 'ab',
        name: 'Abkhazian',
        name_el: null,
        name_es: 'Abjasio',
        name_it: 'Abcaso',
        name_fr: 'Abkhaze',
        name_de: 'Abchasisch',
        name_ca: null,
        native: 'Аҧсуа',
        native_el: 'Аҧсуа',
        native_es: 'Аҧсуа',
        native_it: 'Аҧсуа',
        native_fr: 'Аҧсуа',
        native_de: 'Аҧсуа',
        native_ca: 'Аҧсуа',
        rtl: null
    },
    {
        code: 'af',
        name: 'Afrikaans',
        name_el: null,
        name_es: 'Afrikaans',
        name_it: 'Afrikaans',
        name_fr: 'Afrikaans',
        name_de: 'Afrikaans',
        name_ca: null,
        native: 'Afrikaans',
        native_el: 'Afrikaans',
        native_es: 'Afrikaans',
        native_it: 'Afrikaans',
        native_fr: 'Afrikaans',
        native_de: 'Afrikaans',
        native_ca: 'Afrikaans',
        rtl: null
    },
    {
        code: 'ak',
        name: 'Akan',
        name_el: null,
        name_es: 'Akan',
        name_it: 'Akan',
        name_fr: 'akan',
        name_de: 'Akan',
        name_ca: null,
        native: 'Akana',
        native_el: 'Akana',
        native_es: 'Akana',
        native_it: 'Akana',
        native_fr: 'Akana',
        native_de: 'Akana',
        native_ca: 'Akana',
        rtl: null
    },
    {
        code: 'am',
        name: 'Amharic',
        name_el: null,
        name_es: 'Amárico',
        name_it: 'Amarico',
        name_fr: 'amharique',
        name_de: 'Amharisch',
        name_ca: null,
        native: 'አማርኛ',
        native_el: 'አማርኛ',
        native_es: 'አማርኛ',
        native_it: 'አማርኛ',
        native_fr: 'አማርኛ',
        native_de: 'አማርኛ',
        native_ca: 'አማርኛ',
        rtl: null
    },
    {
        code: 'an',
        name: 'Aragonese',
        name_el: null,
        name_es: 'Aragonés',
        name_it: 'Aragonese',
        name_fr: 'Aragonais',
        name_de: 'Aragonesisch',
        name_ca: null,
        native: 'Aragonés',
        native_el: 'Aragonés',
        native_es: 'Aragonés',
        native_it: 'Aragonés',
        native_fr: 'Aragonés',
        native_de: 'Aragonés',
        native_ca: 'Aragonés',
        rtl: null
    },
    {
        code: 'ar',
        name: 'Arabic',
        name_el: null,
        name_es: 'Árabe',
        name_it: 'Arabo',
        name_fr: 'arabe',
        name_de: 'Arabisch',
        name_ca: null,
        native: 'العربية',
        native_el: 'العربية',
        native_es: 'العربية',
        native_it: 'العربية',
        native_fr: 'العربية',
        native_de: 'العربية',
        native_ca: 'العربية',
        rtl: true
    },
    {
        code: 'as',
        name: 'Assamese',
        name_el: null,
        name_es: 'Asamés',
        name_it: 'Assamese',
        name_fr: 'assamais',
        name_de: 'Assamesisch',
        name_ca: null,
        native: 'অসমীয়া',
        native_el: 'অসমীয়া',
        native_es: 'অসমীয়া',
        native_it: 'অসমীয়া',
        native_fr: 'অসমীয়া',
        native_de: 'অসমীয়া',
        native_ca: 'অসমীয়া',
        rtl: null
    },
    {
        code: 'av',
        name: 'Avar',
        name_el: null,
        name_es: 'Avar',
        name_it: 'Avar',
        name_fr: 'Avar',
        name_de: 'Avar',
        name_ca: null,
        native: 'Авар',
        native_el: 'Авар',
        native_es: 'Авар',
        native_it: 'Авар',
        native_fr: 'Авар',
        native_de: 'Авар',
        native_ca: 'Авар',
        rtl: null
    },
    {
        code: 'ay',
        name: 'Aymara',
        name_el: null,
        name_es: 'Aymara',
        name_it: 'Aymara',
        name_fr: 'aymara',
        name_de: 'Aymara',
        name_ca: null,
        native: 'Aymar',
        native_el: 'Aymar',
        native_es: 'Aymar',
        native_it: 'Aymar',
        native_fr: 'Aymar',
        native_de: 'Aymar',
        native_ca: 'Aymar',
        rtl: null
    },
    {
        code: 'az',
        name: 'Azerbaijani',
        name_el: null,
        name_es: 'Azerbaiyano',
        name_it: 'Azerbaijani',
        name_fr: 'azerbaïdjanais',
        name_de: 'Aserbaidschanisch',
        name_ca: null,
        native: 'Azərbaycanca / آذربايجان',
        native_el: 'Azərbaycanca / آذربايجان',
        native_es: 'Azərbaycanca / آذربايجان',
        native_it: 'Azərbaycanca / آذربايجان',
        native_fr: 'Azərbaycanca / آذربايجان',
        native_de: 'Azərbaycanca / آذربايجان',
        native_ca: 'Azərbaycanca / آذربايجان',
        rtl: null
    },
    {
        code: 'ba',
        name: 'Bashkir',
        name_el: null,
        name_es: 'Baskir',
        name_it: 'Bashkir',
        name_fr: 'bachkir',
        name_de: 'Baschkirisch',
        name_ca: null,
        native: 'Башҡорт',
        native_el: 'Башҡорт',
        native_es: 'Башҡорт',
        native_it: 'Башҡорт',
        native_fr: 'Башҡорт',
        native_de: 'Башҡорт',
        native_ca: 'Башҡорт',
        rtl: null
    },
    {
        code: 'be',
        name: 'Belarusian',
        name_el: null,
        name_es: 'Bielorruso',
        name_it: 'Bielorusso',
        name_fr: 'Biélorusse',
        name_de: 'Weißrussisch',
        name_ca: null,
        native: 'Беларуская',
        native_el: 'Беларуская',
        native_es: 'Беларуская',
        native_it: 'Беларуская',
        native_fr: 'Беларуская',
        native_de: 'Беларуская',
        native_ca: 'Беларуская',
        rtl: null
    },
    {
        code: 'bg',
        name: 'Bulgarian',
        name_el: null,
        name_es: 'Búlgaro',
        name_it: 'bulgaro',
        name_fr: 'Bulgare',
        name_de: 'Bulgarisch',
        name_ca: null,
        native: 'Български',
        native_el: 'Български',
        native_es: 'Български',
        native_it: 'Български',
        native_fr: 'Български',
        native_de: 'Български',
        native_ca: 'Български',
        rtl: null
    },
    {
        code: 'bh',
        name: 'Bihari',
        name_el: null,
        name_es: 'Bihari',
        name_it: 'Bihari',
        name_fr: 'Bihari',
        name_de: 'Bihari',
        name_ca: null,
        native: 'भोजपुरी',
        native_el: 'भोजपुरी',
        native_es: 'भोजपुरी',
        native_it: 'भोजपुरी',
        native_fr: 'भोजपुरी',
        native_de: 'भोजपुरी',
        native_ca: 'भोजपुरी',
        rtl: null
    },
    {
        code: 'bi',
        name: 'Bislama',
        name_el: null,
        name_es: 'Bislama',
        name_it: 'Bislama',
        name_fr: 'Bislama',
        name_de: 'Bislama',
        name_ca: null,
        native: 'Bislama',
        native_el: 'Bislama',
        native_es: 'Bislama',
        native_it: 'Bislama',
        native_fr: 'Bislama',
        native_de: 'Bislama',
        native_ca: 'Bislama',
        rtl: null
    },
    {
        code: 'bm',
        name: 'Bambara',
        name_el: null,
        name_es: 'Bambara',
        name_it: 'Bambara',
        name_fr: 'Bambara',
        name_de: 'Bambara',
        name_ca: null,
        native: 'Bamanankan',
        native_el: 'Bamanankan',
        native_es: 'Bamanankan',
        native_it: 'Bamanankan',
        native_fr: 'Bamanankan',
        native_de: 'Bamanankan',
        native_ca: 'Bamanankan',
        rtl: null
    },
    {
        code: 'bn',
        name: 'Bengali',
        name_el: null,
        name_es: 'Bengalí',
        name_it: 'Bengalese',
        name_fr: 'bengali',
        name_de: 'Bengalisch',
        name_ca: null,
        native: 'বাংলা',
        native_el: 'বাংলা',
        native_es: 'বাংলা',
        native_it: 'বাংলা',
        native_fr: 'বাংলা',
        native_de: 'বাংলা',
        native_ca: 'বাংলা',
        rtl: null
    },
    {
        code: 'bo',
        name: 'Tibetan',
        name_el: null,
        name_es: 'Tibetano',
        name_it: 'Tibetano',
        name_fr: 'tibétain',
        name_de: 'Tibetisch',
        name_ca: null,
        native: 'བོད་ཡིག / Bod skad',
        native_el: 'བོད་ཡིག / Bod skad',
        native_es: 'བོད་ཡིག / Bod skad',
        native_it: 'བོད་ཡིག / Bod skad',
        native_fr: 'བོད་ཡིག / Bod skad',
        native_de: 'བོད་ཡིག / Bod skad',
        native_ca: 'བོད་ཡིག / Bod skad',
        rtl: null
    },
    {
        code: 'br',
        name: 'Breton',
        name_el: null,
        name_es: 'Bretón',
        name_it: 'Bretone',
        name_fr: 'breton',
        name_de: 'Bretonisch',
        name_ca: null,
        native: 'Brezhoneg',
        native_el: 'Brezhoneg',
        native_es: 'Brezhoneg',
        native_it: 'Brezhoneg',
        native_fr: 'Brezhoneg',
        native_de: 'Brezhoneg',
        native_ca: 'Brezhoneg',
        rtl: null
    },
    {
        code: 'bs',
        name: 'Bosnian',
        name_el: null,
        name_es: 'Bosnio',
        name_it: 'bosniaco',
        name_fr: 'bosniaque',
        name_de: 'Bosnisch',
        name_ca: null,
        native: 'Bosanski',
        native_el: 'Bosanski',
        native_es: 'Bosanski',
        native_it: 'Bosanski',
        native_fr: 'Bosanski',
        native_de: 'Bosanski',
        native_ca: 'Bosanski',
        rtl: null
    },
    {
        code: 'ca',
        name: 'Catalan',
        name_el: null,
        name_es: 'Catalán',
        name_it: 'catalano',
        name_fr: 'catalan',
        name_de: 'Katalanisch',
        name_ca: null,
        native: 'Català',
        native_el: 'Català',
        native_es: 'Català',
        native_it: 'Català',
        native_fr: 'Català',
        native_de: 'Català',
        native_ca: 'Català',
        rtl: null
    },
    {
        code: 'ce',
        name: 'Chechen',
        name_el: null,
        name_es: 'Checheno',
        name_it: 'ceceno',
        name_fr: 'Tchétchène',
        name_de: 'Tschetschenisch',
        name_ca: null,
        native: 'Нохчийн',
        native_el: 'Нохчийн',
        native_es: 'Нохчийн',
        native_it: 'Нохчийн',
        native_fr: 'Нохчийн',
        native_de: 'Нохчийн',
        native_ca: 'Нохчийн',
        rtl: null
    },
    {
        code: 'ch',
        name: 'Chamorro',
        name_el: null,
        name_es: 'Chamorro',
        name_it: 'Camorro',
        name_fr: 'chamorro',
        name_de: 'Chamorro',
        name_ca: null,
        native: 'Chamoru',
        native_el: 'Chamoru',
        native_es: 'Chamoru',
        native_it: 'Chamoru',
        native_fr: 'Chamoru',
        native_de: 'Chamoru',
        native_ca: 'Chamoru',
        rtl: null
    },
    {
        code: 'co',
        name: 'Corsican',
        name_el: null,
        name_es: 'Corso',
        name_it: 'Corso',
        name_fr: 'corse',
        name_de: 'Korsisch',
        name_ca: null,
        native: 'Corsu',
        native_el: 'Corsu',
        native_es: 'Corsu',
        native_it: 'Corsu',
        native_fr: 'Corsu',
        native_de: 'Corsu',
        native_ca: 'Corsu',
        rtl: null
    },
    {
        code: 'cr',
        name: 'Cree',
        name_el: null,
        name_es: 'Cree',
        name_it: 'Cree',
        name_fr: 'cri',
        name_de: 'Kretisch',
        name_ca: null,
        native: 'Nehiyaw',
        native_el: 'Nehiyaw',
        native_es: 'Nehiyaw',
        native_it: 'Nehiyaw',
        native_fr: 'Nehiyaw',
        native_de: 'Nehiyaw',
        native_ca: 'Nehiyaw',
        rtl: null
    },
    {
        code: 'cs',
        name: 'Czech',
        name_el: null,
        name_es: 'Checo',
        name_it: 'ceco',
        name_fr: 'Tchèque',
        name_de: 'Tschechisch',
        name_ca: null,
        native: 'Česky',
        native_el: 'Česky',
        native_es: 'Česky',
        native_it: 'Česky',
        native_fr: 'Česky',
        native_de: 'Česky',
        native_ca: 'Česky',
        rtl: null
    },
    {
        code: 'cu',
        name: 'Old Church Slavonic / Old Bulgarian',
        name_el: null,
        name_es: 'Eslavo antiguo / Búlgaro antiguo',
        name_it: 'Slavo ecclesiastico antico / bulgaro antico',
        name_fr: 'Vieux slavon d\'église / vieux bulgare',
        name_de: 'Altkirchenslawisch / Altbulgarisch',
        name_ca: null,
        native: 'словѣньскъ / slověnĭskŭ',
        native_el: 'словѣньскъ / slověnĭskŭ',
        native_es: 'словѣньскъ / slověnĭskŭ',
        native_it: 'словѣньскъ / slověnĭskŭ',
        native_fr: 'словѣньскъ / slověnĭskŭ',
        native_de: 'словѣньскъ / slověnĭskŭ',
        native_ca: 'словѣньскъ / slověnĭskŭ',
        rtl: null
    },
    {
        code: 'cv',
        name: 'Chuvash',
        name_el: null,
        name_es: 'Chuvash',
        name_it: 'Chuvash',
        name_fr: 'Tchouvache',
        name_de: 'Tschuwaschisch',
        name_ca: null,
        native: 'Чăваш',
        native_el: 'Чăваш',
        native_es: 'Чăваш',
        native_it: 'Чăваш',
        native_fr: 'Чăваш',
        native_de: 'Чăваш',
        native_ca: 'Чăваш',
        rtl: null
    },
    {
        code: 'cy',
        name: 'Welsh',
        name_el: null,
        name_es: 'Galés',
        name_it: 'Gallese',
        name_fr: 'gallois',
        name_de: 'Walisisch',
        name_ca: null,
        native: 'Cymraeg',
        native_el: 'Cymraeg',
        native_es: 'Cymraeg',
        native_it: 'Cymraeg',
        native_fr: 'Cymraeg',
        native_de: 'Cymraeg',
        native_ca: 'Cymraeg',
        rtl: null
    },
    {
        code: 'da',
        name: 'Danish',
        name_el: null,
        name_es: 'Danés',
        name_it: 'danese',
        name_fr: 'danois',
        name_de: 'Dänisch',
        name_ca: null,
        native: 'Dansk',
        native_el: 'Dansk',
        native_es: 'Dansk',
        native_it: 'Dansk',
        native_fr: 'Dansk',
        native_de: 'Dansk',
        native_ca: 'Dansk',
        rtl: null
    },
    {
        code: 'de',
        name: 'German',
        name_el: null,
        name_es: 'Alemán',
        name_it: 'Tedesco',
        name_fr: 'allemand',
        name_de: 'Deutsch',
        name_ca: null,
        native: 'Deutsch',
        native_el: 'Deutsch',
        native_es: 'Deutsch',
        native_it: 'Deutsch',
        native_fr: 'Deutsch',
        native_de: 'Deutsch',
        native_ca: 'Deutsch',
        rtl: null
    },
    {
        code: 'dv',
        name: 'Divehi',
        name_el: null,
        name_es: 'Divehi',
        name_it: 'Divehi',
        name_fr: 'Divehi',
        name_de: 'Divehi',
        name_ca: null,
        native: 'ދިވެހިބަސް',
        native_el: 'ދިވެހިބަސް',
        native_es: 'ދިވެހިބަސް',
        native_it: 'ދިވެހިބަސް',
        native_fr: 'ދިވެހިބަސް',
        native_de: 'ދިވެހިބަސް',
        native_ca: 'ދިވެހިބަސް',
        rtl: true
    },
    {
        code: 'dz',
        name: 'Dzongkha',
        name_el: null,
        name_es: 'Dzongkha',
        name_it: 'Dzongkha',
        name_fr: 'Dzongkha',
        name_de: 'Dzongkha',
        name_ca: null,
        native: 'ཇོང་ཁ',
        native_el: 'ཇོང་ཁ',
        native_es: 'ཇོང་ཁ',
        native_it: 'ཇོང་ཁ',
        native_fr: 'ཇོང་ཁ',
        native_de: 'ཇོང་ཁ',
        native_ca: 'ཇོང་ཁ',
        rtl: null
    },
    {
        code: 'ee',
        name: 'Ewe',
        name_el: null,
        name_es: 'Ewe',
        name_it: 'Ewe',
        name_fr: 'Ewe',
        name_de: 'Ewe',
        name_ca: null,
        native: 'Ɛʋɛ',
        native_el: 'Ɛʋɛ',
        native_es: 'Ɛʋɛ',
        native_it: 'Ɛʋɛ',
        native_fr: 'Ɛʋɛ',
        native_de: 'Ɛʋɛ',
        native_ca: 'Ɛʋɛ',
        rtl: null
    },
    {
        code: 'el',
        name: 'Greek',
        name_el: null,
        name_es: 'Griego',
        name_it: 'Greco',
        name_fr: 'grec',
        name_de: 'Griechisch',
        name_ca: null,
        native: 'Ελληνικά',
        native_el: 'Ελληνικά',
        native_es: 'Ελληνικά',
        native_it: 'Ελληνικά',
        native_fr: 'Ελληνικά',
        native_de: 'Ελληνικά',
        native_ca: 'Ελληνικά',
        rtl: null
    },
    {
        code: 'en',
        name: 'English',
        name_el: null,
        name_es: 'Inglés',
        name_it: 'Inglese',
        name_fr: 'Anglais',
        name_de: 'Englisch',
        name_ca: null,
        native: 'English',
        native_el: 'English',
        native_es: 'English',
        native_it: 'English',
        native_fr: 'English',
        native_de: 'English',
        native_ca: 'English',
        rtl: null
    },
    {
        code: 'eo',
        name: 'Esperanto',
        name_el: null,
        name_es: 'Esperanto',
        name_it: 'Esperanto',
        name_fr: 'Espéranto',
        name_de: 'Esperanto',
        name_ca: null,
        native: 'Esperanto',
        native_el: 'Esperanto',
        native_es: 'Esperanto',
        native_it: 'Esperanto',
        native_fr: 'Esperanto',
        native_de: 'Esperanto',
        native_ca: 'Esperanto',
        rtl: null
    },
    {
        code: 'es',
        name: 'Spanish',
        name_el: null,
        name_es: 'Español',
        name_it: 'Spagnolo',
        name_fr: 'Espagnol',
        name_de: 'Spanisch',
        name_ca: null,
        native: 'Español',
        native_el: 'Español',
        native_es: 'Español',
        native_it: 'Español',
        native_fr: 'Español',
        native_de: 'Español',
        native_ca: 'Español',
        rtl: null
    },
    {
        code: 'et',
        name: 'Estonian',
        name_el: null,
        name_es: 'Estonio',
        name_it: 'Estone',
        name_fr: 'Estonien',
        name_de: 'Estnisch',
        name_ca: null,
        native: 'Eesti',
        native_el: 'Eesti',
        native_es: 'Eesti',
        native_it: 'Eesti',
        native_fr: 'Eesti',
        native_de: 'Eesti',
        native_ca: 'Eesti',
        rtl: null
    },
    {
        code: 'eu',
        name: 'Basque',
        name_el: null,
        name_es: 'Vasco',
        name_it: 'Basco',
        name_fr: 'basque',
        name_de: 'Baskisch',
        name_ca: null,
        native: 'Euskara',
        native_el: 'Euskara',
        native_es: 'Euskara',
        native_it: 'Euskara',
        native_fr: 'Euskara',
        native_de: 'Euskara',
        native_ca: 'Euskara',
        rtl: null
    },
    {
        code: 'fa',
        name: 'Persian',
        name_el: null,
        name_es: 'Persa',
        name_it: 'persiano',
        name_fr: 'persan',
        name_de: 'Persisch',
        name_ca: null,
        native: 'فارسی',
        native_el: 'فارسی',
        native_es: 'فارسی',
        native_it: 'فارسی',
        native_fr: 'فارسی',
        native_de: 'فارسی',
        native_ca: 'فارسی',
        rtl: true
    },
    {
        code: 'ff',
        name: 'Peul',
        name_el: null,
        name_es: 'Peul',
        name_it: 'Peul',
        name_fr: 'peul',
        name_de: 'Peul',
        name_ca: null,
        native: 'Fulfulde',
        native_el: 'Fulfulde',
        native_es: 'Fulfulde',
        native_it: 'Fulfulde',
        native_fr: 'Fulfulde',
        native_de: 'Fulfulde',
        native_ca: 'Fulfulde',
        rtl: null
    },
    {
        code: 'fi',
        name: 'Finnish',
        name_el: null,
        name_es: 'Finlandés',
        name_it: 'finlandese',
        name_fr: 'finnois',
        name_de: 'Finnisch',
        name_ca: null,
        native: 'Suomi',
        native_el: 'Suomi',
        native_es: 'Suomi',
        native_it: 'Suomi',
        native_fr: 'Suomi',
        native_de: 'Suomi',
        native_ca: 'Suomi',
        rtl: null
    },
    {
        code: 'fj',
        name: 'Fijian',
        name_el: null,
        name_es: 'Fiyiano',
        name_it: 'Figiano',
        name_fr: 'fidjien',
        name_de: 'Fidschianisch',
        name_ca: null,
        native: 'Na Vosa Vakaviti',
        native_el: 'Na Vosa Vakaviti',
        native_es: 'Na Vosa Vakaviti',
        native_it: 'Na Vosa Vakaviti',
        native_fr: 'Na Vosa Vakaviti',
        native_de: 'Na Vosa Vakaviti',
        native_ca: 'Na Vosa Vakaviti',
        rtl: null
    },
    {
        code: 'fo',
        name: 'Faroese',
        name_el: null,
        name_es: 'Feroés',
        name_it: 'Faroese',
        name_fr: 'Féroïen',
        name_de: 'Färöisch',
        name_ca: null,
        native: 'Føroyskt',
        native_el: 'Føroyskt',
        native_es: 'Føroyskt',
        native_it: 'Føroyskt',
        native_fr: 'Føroyskt',
        native_de: 'Føroyskt',
        native_ca: 'Føroyskt',
        rtl: null
    },
    {
        code: 'fr',
        name: 'French',
        name_el: null,
        name_es: 'Francés',
        name_it: 'Francese',
        name_fr: 'féroïen',
        name_de: 'Französisch',
        name_ca: null,
        native: 'Français',
        native_el: 'Français',
        native_es: 'Français',
        native_it: 'Français',
        native_fr: 'Français',
        native_de: 'Français',
        native_ca: 'Français',
        rtl: null
    },
    {
        code: 'fy',
        name: 'West Frisian',
        name_el: null,
        name_es: 'Frisón occidental',
        name_it: 'Frisone occidentale',
        name_fr: 'Frison occidental',
        name_de: 'Westfriesisch',
        name_ca: null,
        native: 'Frysk',
        native_el: 'Frysk',
        native_es: 'Frysk',
        native_it: 'Frysk',
        native_fr: 'Frysk',
        native_de: 'Frysk',
        native_ca: 'Frysk',
        rtl: null
    },
    {
        code: 'ga',
        name: 'Irish',
        name_el: null,
        name_es: 'Irlandés',
        name_it: 'irlandese',
        name_fr: 'irlandais',
        name_de: 'Irisch',
        name_ca: null,
        native: 'Gaeilge',
        native_el: 'Gaeilge',
        native_es: 'Gaeilge',
        native_it: 'Gaeilge',
        native_fr: 'Gaeilge',
        native_de: 'Gaeilge',
        native_ca: 'Gaeilge',
        rtl: null
    },
    {
        code: 'gd',
        name: 'Scottish Gaelic',
        name_el: null,
        name_es: 'Gaélico escocés',
        name_it: 'Gaelico scozzese',
        name_fr: 'gaélique écossais',
        name_de: 'Schottisch-Gälisch',
        name_ca: null,
        native: 'Gàidhlig',
        native_el: 'Gàidhlig',
        native_es: 'Gàidhlig',
        native_it: 'Gàidhlig',
        native_fr: 'Gàidhlig',
        native_de: 'Gàidhlig',
        native_ca: 'Gàidhlig',
        rtl: null
    },
    {
        code: 'gl',
        name: 'Galician',
        name_el: null,
        name_es: 'Gallego',
        name_it: 'Galiziano',
        name_fr: 'galicien',
        name_de: 'Galicisch',
        name_ca: null,
        native: 'Galego',
        native_el: 'Galego',
        native_es: 'Galego',
        native_it: 'Galego',
        native_fr: 'Galego',
        native_de: 'Galego',
        native_ca: 'Galego',
        rtl: null
    },
    {
        code: 'gn',
        name: 'Guarani',
        name_el: null,
        name_es: 'Guaraní',
        name_it: 'Guarani',
        name_fr: 'Guarani',
        name_de: 'Guarani',
        name_ca: null,
        native: 'Avañe\'ẽ',
        native_el: 'Avañe\'ẽ',
        native_es: 'Avañe\'ẽ',
        native_it: 'Avañe\'ẽ',
        native_fr: 'Avañe\'ẽ',
        native_de: 'Avañe\'ẽ',
        native_ca: 'Avañe\'ẽ',
        rtl: null
    },
    {
        code: 'gu',
        name: 'Gujarati',
        name_el: null,
        name_es: 'Gujarati',
        name_it: 'Gujarati',
        name_fr: 'gujarati',
        name_de: 'Gujarati',
        name_ca: null,
        native: 'ગુજરાતી',
        native_el: 'ગુજરાતી',
        native_es: 'ગુજરાતી',
        native_it: 'ગુજરાતી',
        native_fr: 'ગુજરાતી',
        native_de: 'ગુજરાતી',
        native_ca: 'ગુજરાતી',
        rtl: null
    },
    {
        code: 'gv',
        name: 'Manx',
        name_el: null,
        name_es: 'Manx',
        name_it: 'Manx',
        name_fr: 'Manx',
        name_de: 'Manx',
        name_ca: null,
        native: 'Gaelg',
        native_el: 'Gaelg',
        native_es: 'Gaelg',
        native_it: 'Gaelg',
        native_fr: 'Gaelg',
        native_de: 'Gaelg',
        native_ca: 'Gaelg',
        rtl: null
    },
    {
        code: 'ha',
        name: 'Hausa',
        name_el: null,
        name_es: 'Hausa',
        name_it: 'Hausa',
        name_fr: 'Hausa',
        name_de: 'Hausa',
        name_ca: null,
        native: 'هَوُسَ',
        native_el: 'هَوُسَ',
        native_es: 'هَوُسَ',
        native_it: 'هَوُسَ',
        native_fr: 'هَوُسَ',
        native_de: 'هَوُسَ',
        native_ca: 'هَوُسَ',
        rtl: true
    },
    {
        code: 'he',
        name: 'Hebrew',
        name_el: null,
        name_es: 'Hebreo',
        name_it: 'Ebraico',
        name_fr: 'hébreu',
        name_de: 'Hebräisch',
        name_ca: null,
        native: 'עברית',
        native_el: 'עברית',
        native_es: 'עברית',
        native_it: 'עברית',
        native_fr: 'עברית',
        native_de: 'עברית',
        native_ca: 'עברית',
        rtl: true
    },
    {
        code: 'hi',
        name: 'Hindi',
        name_el: null,
        name_es: 'Hindi',
        name_it: 'Hindi',
        name_fr: 'Hindi',
        name_de: 'Hindi',
        name_ca: null,
        native: 'हिन्दी',
        native_el: 'हिन्दी',
        native_es: 'हिन्दी',
        native_it: 'हिन्दी',
        native_fr: 'हिन्दी',
        native_de: 'हिन्दी',
        native_ca: 'हिन्दी',
        rtl: null
    },
    {
        code: 'ho',
        name: 'Hiri Motu',
        name_el: null,
        name_es: 'Hiri Motu',
        name_it: 'Hiri Motu',
        name_fr: 'Hiri Motu',
        name_de: 'Hiri Motu',
        name_ca: null,
        native: 'Hiri Motu',
        native_el: 'Hiri Motu',
        native_es: 'Hiri Motu',
        native_it: 'Hiri Motu',
        native_fr: 'Hiri Motu',
        native_de: 'Hiri Motu',
        native_ca: 'Hiri Motu',
        rtl: null
    },
    {
        code: 'hr',
        name: 'Croatian',
        name_el: null,
        name_es: 'Croata',
        name_it: 'Croato',
        name_fr: 'Croate',
        name_de: 'Kroatisch',
        name_ca: null,
        native: 'Hrvatski',
        native_el: 'Hrvatski',
        native_es: 'Hrvatski',
        native_it: 'Hrvatski',
        native_fr: 'Hrvatski',
        native_de: 'Hrvatski',
        native_ca: 'Hrvatski',
        rtl: null
    },
    {
        code: 'ht',
        name: 'Haitian',
        name_el: null,
        name_es: 'Haitiano',
        name_it: 'Haitiano',
        name_fr: 'Haïtien',
        name_de: 'Haitianisch',
        name_ca: null,
        native: 'Krèyol ayisyen',
        native_el: 'Krèyol ayisyen',
        native_es: 'Krèyol ayisyen',
        native_it: 'Krèyol ayisyen',
        native_fr: 'Krèyol ayisyen',
        native_de: 'Krèyol ayisyen',
        native_ca: 'Krèyol ayisyen',
        rtl: null
    },
    {
        code: 'hu',
        name: 'Hungarian',
        name_el: null,
        name_es: 'Húngaro',
        name_it: 'ungherese',
        name_fr: 'Hongrois',
        name_de: 'Ungarisch',
        name_ca: null,
        native: 'Magyar',
        native_el: 'Magyar',
        native_es: 'Magyar',
        native_it: 'Magyar',
        native_fr: 'Magyar',
        native_de: 'Magyar',
        native_ca: 'Magyar',
        rtl: null
    },
    {
        code: 'hy',
        name: 'Armenian',
        name_el: null,
        name_es: 'Armenio',
        name_it: 'armeno',
        name_fr: 'Arménien',
        name_de: 'Armenisch',
        name_ca: null,
        native: 'Հայերեն',
        native_el: 'Հայերեն',
        native_es: 'Հայերեն',
        native_it: 'Հայերեն',
        native_fr: 'Հայերեն',
        native_de: 'Հայերեն',
        native_ca: 'Հայերեն',
        rtl: null
    },
    {
        code: 'hz',
        name: 'Herero',
        name_el: null,
        name_es: 'Herero',
        name_it: 'Herero',
        name_fr: 'Herero',
        name_de: 'Herero',
        name_ca: null,
        native: 'Otsiherero',
        native_el: 'Otsiherero',
        native_es: 'Otsiherero',
        native_it: 'Otsiherero',
        native_fr: 'Otsiherero',
        native_de: 'Otsiherero',
        native_ca: 'Otsiherero',
        rtl: null
    },
    {
        code: 'ia',
        name: 'Interlingua',
        name_el: null,
        name_es: 'Interlingua',
        name_it: 'Interlingua',
        name_fr: 'Interlingua',
        name_de: 'Interlingua',
        name_ca: null,
        native: 'Interlingua',
        native_el: 'Interlingua',
        native_es: 'Interlingua',
        native_it: 'Interlingua',
        native_fr: 'Interlingua',
        native_de: 'Interlingua',
        native_ca: 'Interlingua',
        rtl: null
    },
    {
        code: 'id',
        name: 'Indonesian',
        name_el: null,
        name_es: 'Indonesio',
        name_it: 'Indonesiano',
        name_fr: 'indonésien',
        name_de: 'Indonesisch',
        name_ca: null,
        native: 'Bahasa Indonesia',
        native_el: 'Bahasa Indonesia',
        native_es: 'Bahasa Indonesia',
        native_it: 'Bahasa Indonesia',
        native_fr: 'Bahasa Indonesia',
        native_de: 'Bahasa Indonesia',
        native_ca: 'Bahasa Indonesia',
        rtl: null
    },
    {
        code: 'ie',
        name: 'Interlingue',
        name_el: null,
        name_es: 'Interlingue',
        name_it: 'Interlingua',
        name_fr: 'Interlingue',
        name_de: 'Interlingue',
        name_ca: null,
        native: 'Interlingue',
        native_el: 'Interlingue',
        native_es: 'Interlingue',
        native_it: 'Interlingue',
        native_fr: 'Interlingue',
        native_de: 'Interlingue',
        native_ca: 'Interlingue',
        rtl: null
    },
    {
        code: 'ig',
        name: 'Igbo',
        name_el: null,
        name_es: 'Igbo',
        name_it: 'Igbo',
        name_fr: 'Igbo',
        name_de: 'Igbo',
        name_ca: null,
        native: 'Igbo',
        native_el: 'Igbo',
        native_es: 'Igbo',
        native_it: 'Igbo',
        native_fr: 'Igbo',
        native_de: 'Igbo',
        native_ca: 'Igbo',
        rtl: null
    },
    {
        code: 'ii',
        name: 'Sichuan Yi',
        name_el: null,
        name_es: 'Yi de Sichuan',
        name_it: 'Sichuan Yi',
        name_fr: 'Sichuan Yi',
        name_de: 'Sichuanisch Yi',
        name_ca: null,
        native: 'ꆇꉙ / 四川彝语',
        native_el: 'ꆇꉙ / 四川彝语',
        native_es: 'ꆇꉙ / 四川彝语',
        native_it: 'ꆇꉙ / 四川彝语',
        native_fr: 'ꆇꉙ / 四川彝语',
        native_de: 'ꆇꉙ / 四川彝语',
        native_ca: 'ꆇꉙ / 四川彝语',
        rtl: null
    },
    {
        code: 'ik',
        name: 'Inupiak',
        name_el: null,
        name_es: 'Inupiak',
        name_it: 'Inupiak',
        name_fr: 'Inupiak',
        name_de: 'Inupiak',
        name_ca: null,
        native: 'Iñupiak',
        native_el: 'Iñupiak',
        native_es: 'Iñupiak',
        native_it: 'Iñupiak',
        native_fr: 'Iñupiak',
        native_de: 'Iñupiak',
        native_ca: 'Iñupiak',
        rtl: null
    },
    {
        code: 'io',
        name: 'Ido',
        name_el: null,
        name_es: 'Ido',
        name_it: 'Ido',
        name_fr: 'Ido',
        name_de: 'Ido',
        name_ca: null,
        native: 'Ido',
        native_el: 'Ido',
        native_es: 'Ido',
        native_it: 'Ido',
        native_fr: 'Ido',
        native_de: 'Ido',
        native_ca: 'Ido',
        rtl: null
    },
    {
        code: 'is',
        name: 'Icelandic',
        name_el: null,
        name_es: 'Islandés',
        name_it: 'Islandese',
        name_fr: 'islandais',
        name_de: 'Isländisch',
        name_ca: null,
        native: 'Íslenska',
        native_el: 'Íslenska',
        native_es: 'Íslenska',
        native_it: 'Íslenska',
        native_fr: 'Íslenska',
        native_de: 'Íslenska',
        native_ca: 'Íslenska',
        rtl: null
    },
    {
        code: 'it',
        name: 'Italian',
        name_el: null,
        name_es: 'Italiano',
        name_it: 'Italiano',
        name_fr: 'Italien',
        name_de: 'Italienisch',
        name_ca: null,
        native: 'Italiano',
        native_el: 'Italiano',
        native_es: 'Italiano',
        native_it: 'Italiano',
        native_fr: 'Italiano',
        native_de: 'Italiano',
        native_ca: 'Italiano',
        rtl: null
    },
    {
        code: 'iu',
        name: 'Inuktitut',
        name_el: null,
        name_es: 'Inuktitut',
        name_it: 'Inuktitut',
        name_fr: 'Inuktitut',
        name_de: 'Inuktitut',
        name_ca: null,
        native: 'ᐃᓄᒃᑎᑐᑦ',
        native_el: 'ᐃᓄᒃᑎᑐᑦ',
        native_es: 'ᐃᓄᒃᑎᑐᑦ',
        native_it: 'ᐃᓄᒃᑎᑐᑦ',
        native_fr: 'ᐃᓄᒃᑎᑐᑦ',
        native_de: 'ᐃᓄᒃᑎᑐᑦ',
        native_ca: 'ᐃᓄᒃᑎᑐᑦ',
        rtl: null
    },
    {
        code: 'ja',
        name: 'Japanese',
        name_el: null,
        name_es: 'Japonés',
        name_it: 'Giapponese',
        name_fr: 'japonais',
        name_de: 'Japanisch',
        name_ca: null,
        native: '日本語',
        native_el: '日本語',
        native_es: '日本語',
        native_it: '日本語',
        native_fr: '日本語',
        native_de: '日本語',
        native_ca: '日本語',
        rtl: null
    },
    {
        code: 'jv',
        name: 'Javanese',
        name_el: null,
        name_es: 'Javanés',
        name_it: 'Giavanese',
        name_fr: 'Javanais',
        name_de: 'Javanisch',
        name_ca: null,
        native: 'Basa Jawa',
        native_el: 'Basa Jawa',
        native_es: 'Basa Jawa',
        native_it: 'Basa Jawa',
        native_fr: 'Basa Jawa',
        native_de: 'Basa Jawa',
        native_ca: 'Basa Jawa',
        rtl: null
    },
    {
        code: 'ka',
        name: 'Georgian',
        name_el: null,
        name_es: 'Georgiano',
        name_it: 'georgiano',
        name_fr: 'géorgien',
        name_de: 'Georgisch',
        name_ca: null,
        native: 'ქართული',
        native_el: 'ქართული',
        native_es: 'ქართული',
        native_it: 'ქართული',
        native_fr: 'ქართული',
        native_de: 'ქართული',
        native_ca: 'ქართული',
        rtl: null
    },
    {
        code: 'kg',
        name: 'Kongo',
        name_el: null,
        name_es: 'Kongo',
        name_it: 'Kongo',
        name_fr: 'kongo',
        name_de: 'Kongo',
        name_ca: null,
        native: 'KiKongo',
        native_el: 'KiKongo',
        native_es: 'KiKongo',
        native_it: 'KiKongo',
        native_fr: 'KiKongo',
        native_de: 'KiKongo',
        native_ca: 'KiKongo',
        rtl: null
    },
    {
        code: 'ki',
        name: 'Kikuyu',
        name_el: null,
        name_es: 'Kikuyu',
        name_it: 'Kikuyu',
        name_fr: 'Kikuyu',
        name_de: 'Kikuyu',
        name_ca: null,
        native: 'Gĩkũyũ',
        native_el: 'Gĩkũyũ',
        native_es: 'Gĩkũyũ',
        native_it: 'Gĩkũyũ',
        native_fr: 'Gĩkũyũ',
        native_de: 'Gĩkũyũ',
        native_ca: 'Gĩkũyũ',
        rtl: null
    },
    {
        code: 'kj',
        name: 'Kuanyama',
        name_el: null,
        name_es: 'Kuanyama',
        name_it: 'Kuanyama',
        name_fr: 'Kuanyama',
        name_de: 'Kuanyama',
        name_ca: null,
        native: 'Kuanyama',
        native_el: 'Kuanyama',
        native_es: 'Kuanyama',
        native_it: 'Kuanyama',
        native_fr: 'Kuanyama',
        native_de: 'Kuanyama',
        native_ca: 'Kuanyama',
        rtl: null
    },
    {
        code: 'kk',
        name: 'Kazakh',
        name_el: null,
        name_es: 'Kazajo',
        name_it: 'Kazako',
        name_fr: 'kazakh',
        name_de: 'Kasachisch',
        name_ca: null,
        native: 'Қазақша',
        native_el: 'Қазақша',
        native_es: 'Қазақша',
        native_it: 'Қазақша',
        native_fr: 'Қазақша',
        native_de: 'Қазақша',
        native_ca: 'Қазақша',
        rtl: null
    },
    {
        code: 'kl',
        name: 'Greenlandic',
        name_el: null,
        name_es: 'Groenlandés',
        name_it: 'Groenlandese',
        name_fr: 'Groenlandais',
        name_de: 'Grönländisch',
        name_ca: null,
        native: 'Kalaallisut',
        native_el: 'Kalaallisut',
        native_es: 'Kalaallisut',
        native_it: 'Kalaallisut',
        native_fr: 'Kalaallisut',
        native_de: 'Kalaallisut',
        native_ca: 'Kalaallisut',
        rtl: null
    },
    {
        code: 'km',
        name: 'Cambodian',
        name_el: null,
        name_es: 'Camboyano',
        name_it: 'Cambogiano',
        name_fr: 'Cambodgien',
        name_de: 'Kambodschanisch',
        name_ca: null,
        native: 'ភាសាខ្មែរ',
        native_el: 'ភាសាខ្មែរ',
        native_es: 'ភាសាខ្មែរ',
        native_it: 'ភាសាខ្មែរ',
        native_fr: 'ភាសាខ្មែរ',
        native_de: 'ភាសាខ្មែរ',
        native_ca: 'ភាសាខ្មែរ',
        rtl: null
    },
    {
        code: 'kn',
        name: 'Kannada',
        name_el: null,
        name_es: 'Canarés',
        name_it: 'Kannada',
        name_fr: 'Kannada',
        name_de: 'Kannada',
        name_ca: null,
        native: 'ಕನ್ನಡ',
        native_el: 'ಕನ್ನಡ',
        native_es: 'ಕನ್ನಡ',
        native_it: 'ಕನ್ನಡ',
        native_fr: 'ಕನ್ನಡ',
        native_de: 'ಕನ್ನಡ',
        native_ca: 'ಕನ್ನಡ',
        rtl: null
    },
    {
        code: 'ko',
        name: 'Korean',
        name_el: null,
        name_es: 'Coreano',
        name_it: 'Coreano',
        name_fr: 'coréen',
        name_de: 'Koreanisch',
        name_ca: null,
        native: '한국어',
        native_el: '한국어',
        native_es: '한국어',
        native_it: '한국어',
        native_fr: '한국어',
        native_de: '한국어',
        native_ca: '한국어',
        rtl: null
    },
    {
        code: 'kr',
        name: 'Kanuri',
        name_el: null,
        name_es: 'Kanuri',
        name_it: 'Kanuri',
        name_fr: 'Kanuri',
        name_de: 'Kanuri',
        name_ca: null,
        native: 'Kanuri',
        native_el: 'Kanuri',
        native_es: 'Kanuri',
        native_it: 'Kanuri',
        native_fr: 'Kanuri',
        native_de: 'Kanuri',
        native_ca: 'Kanuri',
        rtl: null
    },
    {
        code: 'ks',
        name: 'Kashmiri',
        name_el: null,
        name_es: 'Cachemir',
        name_it: 'Kashmiri',
        name_fr: 'Cachemiri',
        name_de: 'Kaschmirisch',
        name_ca: null,
        native: 'कश्मीरी / كشميري',
        native_el: 'कश्मीरी / كشميري',
        native_es: 'कश्मीरी / كشميري',
        native_it: 'कश्मीरी / كشميري',
        native_fr: 'कश्मीरी / كشميري',
        native_de: 'कश्मीरी / كشميري',
        native_ca: 'कश्मीरी / كشميري',
        rtl: true
    },
    {
        code: 'ku',
        name: 'Kurdish',
        name_el: null,
        name_es: 'Kurdo',
        name_it: 'Curdo',
        name_fr: 'kurde',
        name_de: 'Kurdisch',
        name_ca: null,
        native: 'Kurdî / كوردی',
        native_el: 'Kurdî / كوردی',
        native_es: 'Kurdî / كوردی',
        native_it: 'Kurdî / كوردی',
        native_fr: 'Kurdî / كوردی',
        native_de: 'Kurdî / كوردی',
        native_ca: 'Kurdî / كوردی',
        rtl: true
    },
    {
        code: 'kv',
        name: 'Komi',
        name_el: null,
        name_es: 'Komi',
        name_it: 'Komi',
        name_fr: 'komi',
        name_de: 'Komi',
        name_ca: null,
        native: 'Коми',
        native_el: 'Коми',
        native_es: 'Коми',
        native_it: 'Коми',
        native_fr: 'Коми',
        native_de: 'Коми',
        native_ca: 'Коми',
        rtl: null
    },
    {
        code: 'kw',
        name: 'Cornish',
        name_el: null,
        name_es: 'Córnico',
        name_it: 'Cornovaglia',
        name_fr: 'Cornouaillais',
        name_de: 'Kornisch',
        name_ca: null,
        native: 'Kernewek',
        native_el: 'Kernewek',
        native_es: 'Kernewek',
        native_it: 'Kernewek',
        native_fr: 'Kernewek',
        native_de: 'Kernewek',
        native_ca: 'Kernewek',
        rtl: null
    },
    {
        code: 'ky',
        name: 'Kirghiz',
        name_el: null,
        name_es: 'Kirguís',
        name_it: 'Kirghiz',
        name_fr: 'Kirghiz',
        name_de: 'Kirgisisch',
        name_ca: null,
        native: 'Kırgızca / Кыргызча',
        native_el: 'Kırgızca / Кыргызча',
        native_es: 'Kırgızca / Кыргызча',
        native_it: 'Kırgızca / Кыргызча',
        native_fr: 'Kırgızca / Кыргызча',
        native_de: 'Kırgızca / Кыргызча',
        native_ca: 'Kırgızca / Кыргызча',
        rtl: null
    },
    {
        code: 'la',
        name: 'Latin',
        name_el: null,
        name_es: 'Latín',
        name_it: 'Latino',
        name_fr: 'latin',
        name_de: 'Lateinisch',
        name_ca: null,
        native: 'Latina',
        native_el: 'Latina',
        native_es: 'Latina',
        native_it: 'Latina',
        native_fr: 'Latina',
        native_de: 'Latina',
        native_ca: 'Latina',
        rtl: null
    },
    {
        code: 'lb',
        name: 'Luxembourgish',
        name_el: null,
        name_es: 'Luxemburgués',
        name_it: 'Lussemburghese',
        name_fr: 'luxembourgeois',
        name_de: 'Luxemburgisch',
        name_ca: null,
        native: 'Lëtzebuergesch',
        native_el: 'Lëtzebuergesch',
        native_es: 'Lëtzebuergesch',
        native_it: 'Lëtzebuergesch',
        native_fr: 'Lëtzebuergesch',
        native_de: 'Lëtzebuergesch',
        native_ca: 'Lëtzebuergesch',
        rtl: null
    },
    {
        code: 'lg',
        name: 'Ganda',
        name_el: null,
        name_es: 'Ganda',
        name_it: 'Ganda',
        name_fr: 'Ganda',
        name_de: 'Ganda',
        name_ca: null,
        native: 'Luganda',
        native_el: 'Luganda',
        native_es: 'Luganda',
        native_it: 'Luganda',
        native_fr: 'Luganda',
        native_de: 'Luganda',
        native_ca: 'Luganda',
        rtl: null
    },
    {
        code: 'li',
        name: 'Limburgian',
        name_el: null,
        name_es: 'Limburgués',
        name_it: 'Limburghese',
        name_fr: 'Limbourgeois',
        name_de: 'Limburgisch',
        name_ca: null,
        native: 'Limburgs',
        native_el: 'Limburgs',
        native_es: 'Limburgs',
        native_it: 'Limburgs',
        native_fr: 'Limburgs',
        native_de: 'Limburgs',
        native_ca: 'Limburgs',
        rtl: null
    },
    {
        code: 'ln',
        name: 'Lingala',
        name_el: null,
        name_es: 'Lingala',
        name_it: 'Lingala',
        name_fr: 'lingala',
        name_de: 'Lingala',
        name_ca: null,
        native: 'Lingála',
        native_el: 'Lingála',
        native_es: 'Lingála',
        native_it: 'Lingála',
        native_fr: 'Lingála',
        native_de: 'Lingála',
        native_ca: 'Lingála',
        rtl: null
    },
    {
        code: 'lo',
        name: 'Laotian',
        name_el: null,
        name_es: 'Laosiano',
        name_it: 'Laotiano',
        name_fr: 'laotien',
        name_de: 'Laotisch',
        name_ca: null,
        native: 'ລາວ / Pha xa lao',
        native_el: 'ລາວ / Pha xa lao',
        native_es: 'ລາວ / Pha xa lao',
        native_it: 'ລາວ / Pha xa lao',
        native_fr: 'ລາວ / Pha xa lao',
        native_de: 'ລາວ / Pha xa lao',
        native_ca: 'ລາວ / Pha xa lao',
        rtl: null
    },
    {
        code: 'lt',
        name: 'Lithuanian',
        name_el: null,
        name_es: 'Lituano',
        name_it: 'Lituano',
        name_fr: 'Lituanien',
        name_de: 'Litauisch',
        name_ca: null,
        native: 'Lietuvių',
        native_el: 'Lietuvių',
        native_es: 'Lietuvių',
        native_it: 'Lietuvių',
        native_fr: 'Lietuvių',
        native_de: 'Lietuvių',
        native_ca: 'Lietuvių',
        rtl: null
    },
    {
        code: 'lu',
        name: 'Luba-Katanga',
        name_el: null,
        name_es: 'Luba-Katanga',
        name_it: 'Luba-Katanga',
        name_fr: 'Luba-Katanga',
        name_de: 'Luba-Katanga',
        name_ca: null,
        native: 'Tshiluba',
        native_el: 'Tshiluba',
        native_es: 'Tshiluba',
        native_it: 'Tshiluba',
        native_fr: 'Tshiluba',
        native_de: 'Tshiluba',
        native_ca: 'Tshiluba',
        rtl: null
    },
    {
        code: 'lv',
        name: 'Latvian',
        name_el: null,
        name_es: 'Letón',
        name_it: 'Lettone',
        name_fr: 'letton',
        name_de: 'Lettisch',
        name_ca: null,
        native: 'Latviešu',
        native_el: 'Latviešu',
        native_es: 'Latviešu',
        native_it: 'Latviešu',
        native_fr: 'Latviešu',
        native_de: 'Latviešu',
        native_ca: 'Latviešu',
        rtl: null
    },
    {
        code: 'mg',
        name: 'Malagasy',
        name_el: null,
        name_es: 'Malgache',
        name_it: 'Malgascio',
        name_fr: 'Malgache',
        name_de: 'Madagassisch',
        name_ca: null,
        native: 'Malagasy',
        native_el: 'Malagasy',
        native_es: 'Malagasy',
        native_it: 'Malagasy',
        native_fr: 'Malagasy',
        native_de: 'Malagasy',
        native_ca: 'Malagasy',
        rtl: null
    },
    {
        code: 'mh',
        name: 'Marshallese',
        name_el: null,
        name_es: 'Marshalés',
        name_it: 'Marshallese',
        name_fr: 'Marshallais',
        name_de: 'Marshallisch',
        name_ca: null,
        native: 'Kajin Majel / Ebon',
        native_el: 'Kajin Majel / Ebon',
        native_es: 'Kajin Majel / Ebon',
        native_it: 'Kajin Majel / Ebon',
        native_fr: 'Kajin Majel / Ebon',
        native_de: 'Kajin Majel / Ebon',
        native_ca: 'Kajin Majel / Ebon',
        rtl: null
    },
    {
        code: 'mi',
        name: 'Maori',
        name_el: null,
        name_es: 'Maorí',
        name_it: 'Maori',
        name_fr: 'Maori',
        name_de: 'Maori',
        name_ca: null,
        native: 'Māori',
        native_el: 'Māori',
        native_es: 'Māori',
        native_it: 'Māori',
        native_fr: 'Māori',
        native_de: 'Māori',
        native_ca: 'Māori',
        rtl: null
    },
    {
        code: 'mk',
        name: 'Macedonian',
        name_el: null,
        name_es: 'Macedonio',
        name_it: 'Macedone',
        name_fr: 'Macédonien',
        name_de: 'Mazedonisch',
        name_ca: null,
        native: 'Македонски',
        native_el: 'Македонски',
        native_es: 'Македонски',
        native_it: 'Македонски',
        native_fr: 'Македонски',
        native_de: 'Македонски',
        native_ca: 'Македонски',
        rtl: null
    },
    {
        code: 'ml',
        name: 'Malayalam',
        name_el: null,
        name_es: 'Malayalam',
        name_it: 'Malayalam',
        name_fr: 'malayalam',
        name_de: 'Malayalam',
        name_ca: null,
        native: 'മലയാളം',
        native_el: 'മലയാളം',
        native_es: 'മലയാളം',
        native_it: 'മലയാളം',
        native_fr: 'മലയാളം',
        native_de: 'മലയാളം',
        native_ca: 'മലയാളം',
        rtl: null
    },
    {
        code: 'mn',
        name: 'Mongolian',
        name_el: null,
        name_es: 'Mongol',
        name_it: 'mongolo',
        name_fr: 'mongolien',
        name_de: 'Mongolisch',
        name_ca: null,
        native: 'Монгол',
        native_el: 'Монгол',
        native_es: 'Монгол',
        native_it: 'Монгол',
        native_fr: 'Монгол',
        native_de: 'Монгол',
        native_ca: 'Монгол',
        rtl: null
    },
    {
        code: 'mo',
        name: 'Moldovan',
        name_el: null,
        name_es: 'Moldavo',
        name_it: 'Moldavo',
        name_fr: 'Moldave',
        name_de: 'Moldawisch',
        name_ca: null,
        native: 'Moldovenească',
        native_el: 'Moldovenească',
        native_es: 'Moldovenească',
        native_it: 'Moldovenească',
        native_fr: 'Moldovenească',
        native_de: 'Moldovenească',
        native_ca: 'Moldovenească',
        rtl: null
    },
    {
        code: 'mr',
        name: 'Marathi',
        name_el: null,
        name_es: 'Marathi',
        name_it: 'Marathi',
        name_fr: 'marathi',
        name_de: 'Marathi',
        name_ca: null,
        native: 'मराठी',
        native_el: 'मराठी',
        native_es: 'मराठी',
        native_it: 'मराठी',
        native_fr: 'मराठी',
        native_de: 'मराठी',
        native_ca: 'मराठी',
        rtl: null
    },
    {
        code: 'ms',
        name: 'Malay',
        name_el: null,
        name_es: 'Malayo',
        name_it: 'Malese',
        name_fr: 'malais',
        name_de: 'Malaiisch',
        name_ca: null,
        native: 'Bahasa Melayu',
        native_el: 'Bahasa Melayu',
        native_es: 'Bahasa Melayu',
        native_it: 'Bahasa Melayu',
        native_fr: 'Bahasa Melayu',
        native_de: 'Bahasa Melayu',
        native_ca: 'Bahasa Melayu',
        rtl: null
    },
    {
        code: 'mt',
        name: 'Maltese',
        name_el: null,
        name_es: 'Maltés',
        name_it: 'Maltese',
        name_fr: 'Maltais',
        name_de: 'Maltesisch',
        name_ca: null,
        native: 'bil-Malti',
        native_el: 'bil-Malti',
        native_es: 'bil-Malti',
        native_it: 'bil-Malti',
        native_fr: 'bil-Malti',
        native_de: 'bil-Malti',
        native_ca: 'bil-Malti',
        rtl: null
    },
    {
        code: 'my',
        name: 'Burmese',
        name_el: null,
        name_es: 'Birmano',
        name_it: 'Birmano',
        name_fr: 'birman',
        name_de: 'Birmanisch',
        name_ca: null,
        native: 'မြန်မာစာ',
        native_el: 'မြန်မာစာ',
        native_es: 'မြန်မာစာ',
        native_it: 'မြန်မာစာ',
        native_fr: 'မြန်မာစာ',
        native_de: 'မြန်မာစာ',
        native_ca: 'မြန်မာစာ',
        rtl: null
    },
    {
        code: 'na',
        name: 'Nauruan',
        name_el: null,
        name_es: 'Nauruano',
        name_it: 'Nauruan',
        name_fr: 'Nauruan',
        name_de: 'Nauruisch',
        name_ca: null,
        native: 'Dorerin Naoero',
        native_el: 'Dorerin Naoero',
        native_es: 'Dorerin Naoero',
        native_it: 'Dorerin Naoero',
        native_fr: 'Dorerin Naoero',
        native_de: 'Dorerin Naoero',
        native_ca: 'Dorerin Naoero',
        rtl: null
    },
    {
        code: 'nb',
        name: 'Norwegian Bokmål',
        name_el: null,
        name_es: 'Noruego Bokmål',
        name_it: 'Norvegese Bokmål',
        name_fr: 'Norvégien Bokmål',
        name_de: 'Norwegisch Bokmål',
        name_ca: null,
        native: 'Norsk bokmål',
        native_el: 'Norsk bokmål',
        native_es: 'Norsk bokmål',
        native_it: 'Norsk bokmål',
        native_fr: 'Norsk bokmål',
        native_de: 'Norsk bokmål',
        native_ca: 'Norsk bokmål',
        rtl: null
    },
    {
        code: 'nd',
        name: 'North Ndebele',
        name_el: null,
        name_es: 'Ndebele del Norte',
        name_it: 'Ndebele settentrionale',
        name_fr: 'Ndebele du Nord',
        name_de: 'Nord-Ndebele',
        name_ca: null,
        native: 'Sindebele',
        native_el: 'Sindebele',
        native_es: 'Sindebele',
        native_it: 'Sindebele',
        native_fr: 'Sindebele',
        native_de: 'Sindebele',
        native_ca: 'Sindebele',
        rtl: null
    },
    {
        code: 'ne',
        name: 'Nepali',
        name_el: null,
        name_es: 'Nepalí',
        name_it: 'Nepalese',
        name_fr: 'Népalais',
        name_de: 'Nepalesisch',
        name_ca: null,
        native: 'नेपाली',
        native_el: 'नेपाली',
        native_es: 'नेपाली',
        native_it: 'नेपाली',
        native_fr: 'नेपाली',
        native_de: 'नेपाली',
        native_ca: 'नेपाली',
        rtl: null
    },
    {
        code: 'ng',
        name: 'Ndonga',
        name_el: null,
        name_es: 'Ndonga',
        name_it: 'Ndonga',
        name_fr: 'Ndonga',
        name_de: 'Ndonga',
        name_ca: null,
        native: 'Oshiwambo',
        native_el: 'Oshiwambo',
        native_es: 'Oshiwambo',
        native_it: 'Oshiwambo',
        native_fr: 'Oshiwambo',
        native_de: 'Oshiwambo',
        native_ca: 'Oshiwambo',
        rtl: null
    },
    {
        code: 'nl',
        name: 'Dutch',
        name_el: null,
        name_es: 'Holandés',
        name_it: 'Olandese',
        name_fr: 'Néerlandais',
        name_de: 'Niederländisch',
        name_ca: null,
        native: 'Nederlands',
        native_el: 'Nederlands',
        native_es: 'Nederlands',
        native_it: 'Nederlands',
        native_fr: 'Nederlands',
        native_de: 'Nederlands',
        native_ca: 'Nederlands',
        rtl: null
    },
    {
        code: 'nn',
        name: 'Norwegian Nynorsk',
        name_el: null,
        name_es: 'Noruego Nynorsk',
        name_it: 'Norvegese Nynorsk',
        name_fr: 'Norvégien Nynorsk',
        name_de: 'Norwegisch Nynorsk',
        name_ca: null,
        native: 'Norsk nynorsk',
        native_el: 'Norsk nynorsk',
        native_es: 'Norsk nynorsk',
        native_it: 'Norsk nynorsk',
        native_fr: 'Norsk nynorsk',
        native_de: 'Norsk nynorsk',
        native_ca: 'Norsk nynorsk',
        rtl: null
    },
    {
        code: 'no',
        name: 'Norwegian',
        name_el: null,
        name_es: 'Noruego',
        name_it: 'norvegese',
        name_fr: 'norvégien',
        name_de: 'Norwegisch',
        name_ca: null,
        native: 'Norsk',
        native_el: 'Norsk',
        native_es: 'Norsk',
        native_it: 'Norsk',
        native_fr: 'Norsk',
        native_de: 'Norsk',
        native_ca: 'Norsk',
        rtl: null
    },
    {
        code: 'nr',
        name: 'South Ndebele',
        name_el: null,
        name_es: 'Ndebele del sur',
        name_it: 'Ndebele meridionale',
        name_fr: 'Ndebele du Sud',
        name_de: 'Süd-Ndebele',
        name_ca: null,
        native: 'isiNdebele',
        native_el: 'isiNdebele',
        native_es: 'isiNdebele',
        native_it: 'isiNdebele',
        native_fr: 'isiNdebele',
        native_de: 'isiNdebele',
        native_ca: 'isiNdebele',
        rtl: null
    },
    {
        code: 'nv',
        name: 'Navajo',
        name_el: null,
        name_es: 'Navajo',
        name_it: 'Navajo',
        name_fr: 'Navajo',
        name_de: 'Navajo',
        name_ca: null,
        native: 'Diné bizaad',
        native_el: 'Diné bizaad',
        native_es: 'Diné bizaad',
        native_it: 'Diné bizaad',
        native_fr: 'Diné bizaad',
        native_de: 'Diné bizaad',
        native_ca: 'Diné bizaad',
        rtl: null
    },
    {
        code: 'ny',
        name: 'Chichewa',
        name_el: null,
        name_es: 'Chichewa',
        name_it: 'Chichewa',
        name_fr: 'Chichewa',
        name_de: 'Chichewa',
        name_ca: null,
        native: 'Chi-Chewa',
        native_el: 'Chi-Chewa',
        native_es: 'Chi-Chewa',
        native_it: 'Chi-Chewa',
        native_fr: 'Chi-Chewa',
        native_de: 'Chi-Chewa',
        native_ca: 'Chi-Chewa',
        rtl: null
    },
    {
        code: 'oc',
        name: 'Occitan',
        name_el: null,
        name_es: 'Occitano',
        name_it: 'Occitano',
        name_fr: 'occitan',
        name_de: 'Okzitanisch',
        name_ca: null,
        native: 'Occitan',
        native_el: 'Occitan',
        native_es: 'Occitan',
        native_it: 'Occitan',
        native_fr: 'Occitan',
        native_de: 'Occitan',
        native_ca: 'Occitan',
        rtl: null
    },
    {
        code: 'oj',
        name: 'Ojibwa',
        name_el: null,
        name_es: 'Ojibwa',
        name_it: 'Ojibwa',
        name_fr: 'Ojibwa',
        name_de: 'Ojibwa',
        name_ca: null,
        native: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin',
        native_el: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin',
        native_es: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin',
        native_it: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin',
        native_fr: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin',
        native_de: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin',
        native_ca: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin',
        rtl: null
    },
    {
        code: 'om',
        name: 'Oromo',
        name_el: null,
        name_es: 'Oromo',
        name_it: 'Oromo',
        name_fr: 'Oromo',
        name_de: 'Oromo',
        name_ca: null,
        native: 'Oromoo',
        native_el: 'Oromoo',
        native_es: 'Oromoo',
        native_it: 'Oromoo',
        native_fr: 'Oromoo',
        native_de: 'Oromoo',
        native_ca: 'Oromoo',
        rtl: null
    },
    {
        code: 'or',
        name: 'Oriya',
        name_el: null,
        name_es: 'Oriya',
        name_it: 'Oriya',
        name_fr: 'Oriya',
        name_de: 'Oriya',
        name_ca: null,
        native: 'ଓଡ଼ିଆ',
        native_el: 'ଓଡ଼ିଆ',
        native_es: 'ଓଡ଼ିଆ',
        native_it: 'ଓଡ଼ିଆ',
        native_fr: 'ଓଡ଼ିଆ',
        native_de: 'ଓଡ଼ିଆ',
        native_ca: 'ଓଡ଼ିଆ',
        rtl: null
    },
    {
        code: 'os',
        name: 'Ossetian / Ossetic',
        name_el: null,
        name_es: 'Osetia / Ossetic',
        name_it: 'Osseto / Ossetico',
        name_fr: 'Ossète / Ossetic',
        name_de: 'Ossetisch / Ossetisch',
        name_ca: null,
        native: 'Иронау',
        native_el: 'Иронау',
        native_es: 'Иронау',
        native_it: 'Иронау',
        native_fr: 'Иронау',
        native_de: 'Иронау',
        native_ca: 'Иронау',
        rtl: null
    },
    {
        code: 'pa',
        name: 'Panjabi / Punjabi',
        name_el: null,
        name_es: 'Panyabí / Punjabi',
        name_it: 'Panjabi / Punjabi',
        name_fr: 'Panjabi / Punjabi',
        name_de: 'Panjabi / Punjabi',
        name_ca: null,
        native: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
        native_el: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
        native_es: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
        native_it: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
        native_fr: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
        native_de: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
        native_ca: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
        rtl: null
    },
    {
        code: 'pi',
        name: 'Pali',
        name_el: null,
        name_es: 'Pali',
        name_it: 'Pali',
        name_fr: 'Pali',
        name_de: 'Pali',
        name_ca: null,
        native: 'Pāli / पाऴि',
        native_el: 'Pāli / पाऴि',
        native_es: 'Pāli / पाऴि',
        native_it: 'Pāli / पाऴि',
        native_fr: 'Pāli / पाऴि',
        native_de: 'Pāli / पाऴि',
        native_ca: 'Pāli / पाऴि',
        rtl: null
    },
    {
        code: 'pl',
        name: 'Polish',
        name_el: null,
        name_es: 'Polaco',
        name_it: 'Polacco',
        name_fr: 'Polonais',
        name_de: 'Polnisch',
        name_ca: null,
        native: 'Polski',
        native_el: 'Polski',
        native_es: 'Polski',
        native_it: 'Polski',
        native_fr: 'Polski',
        native_de: 'Polski',
        native_ca: 'Polski',
        rtl: null
    },
    {
        code: 'ps',
        name: 'Pashto',
        name_el: null,
        name_es: 'Pastún',
        name_it: 'Pashto',
        name_fr: 'Pashto',
        name_de: 'Paschtu',
        name_ca: null,
        native: 'پښتو',
        native_el: 'پښتو',
        native_es: 'پښتو',
        native_it: 'پښتو',
        native_fr: 'پښتو',
        native_de: 'پښتو',
        native_ca: 'پښتو',
        rtl: true
    },
    {
        code: 'pt',
        name: 'Portuguese',
        name_el: null,
        name_es: 'Portugués',
        name_it: 'Portoghese',
        name_fr: 'portugais',
        name_de: 'Portugiesisch',
        name_ca: null,
        native: 'Português',
        native_el: 'Português',
        native_es: 'Português',
        native_it: 'Português',
        native_fr: 'Português',
        native_de: 'Português',
        native_ca: 'Português',
        rtl: null
    },
    {
        code: 'qu',
        name: 'Quechua',
        name_el: null,
        name_es: 'Quechua',
        name_it: 'Quechua',
        name_fr: 'Quechua',
        name_de: 'Quechua',
        name_ca: null,
        native: 'Runa Simi',
        native_el: 'Runa Simi',
        native_es: 'Runa Simi',
        native_it: 'Runa Simi',
        native_fr: 'Runa Simi',
        native_de: 'Runa Simi',
        native_ca: 'Runa Simi',
        rtl: null
    },
    {
        code: 'rm',
        name: 'Raeto Romance',
        name_el: null,
        name_es: 'Raeto Romance',
        name_it: 'Raeto romancio',
        name_fr: 'Raeto Romance',
        name_de: 'Rätoromanisch',
        name_ca: null,
        native: 'Rumantsch',
        native_el: 'Rumantsch',
        native_es: 'Rumantsch',
        native_it: 'Rumantsch',
        native_fr: 'Rumantsch',
        native_de: 'Rumantsch',
        native_ca: 'Rumantsch',
        rtl: null
    },
    {
        code: 'rn',
        name: 'Kirundi',
        name_el: null,
        name_es: 'Kirundi',
        name_it: 'Kirundi',
        name_fr: 'Kirundi',
        name_de: 'Kirundisch',
        name_ca: null,
        native: 'Kirundi',
        native_el: 'Kirundi',
        native_es: 'Kirundi',
        native_it: 'Kirundi',
        native_fr: 'Kirundi',
        native_de: 'Kirundi',
        native_ca: 'Kirundi',
        rtl: null
    },
    {
        code: 'ro',
        name: 'Romanian',
        name_el: null,
        name_es: 'Rumano',
        name_it: 'rumeno',
        name_fr: 'Roumain',
        name_de: 'Rumänisch',
        name_ca: null,
        native: 'Română',
        native_el: 'Română',
        native_es: 'Română',
        native_it: 'Română',
        native_fr: 'Română',
        native_de: 'Română',
        native_ca: 'Română',
        rtl: null
    },
    {
        code: 'ru',
        name: 'Russian',
        name_el: null,
        name_es: 'Ruso',
        name_it: 'russo',
        name_fr: 'Russe',
        name_de: 'Russisch',
        name_ca: null,
        native: 'Русский',
        native_el: 'Русский',
        native_es: 'Русский',
        native_it: 'Русский',
        native_fr: 'Русский',
        native_de: 'Русский',
        native_ca: 'Русский',
        rtl: null
    },
    {
        code: 'rw',
        name: 'Rwandi',
        name_el: null,
        name_es: 'Ruandí',
        name_it: 'Rwandi',
        name_fr: 'Rwandi',
        name_de: 'Ruandisch',
        name_ca: null,
        native: 'Kinyarwandi',
        native_el: 'Kinyarwandi',
        native_es: 'Kinyarwandi',
        native_it: 'Kinyarwandi',
        native_fr: 'Kinyarwandi',
        native_de: 'Kinyarwandi',
        native_ca: 'Kinyarwandi',
        rtl: null
    },
    {
        code: 'sa',
        name: 'Sanskrit',
        name_el: null,
        name_es: 'Sánscrito',
        name_it: 'Sanscrito',
        name_fr: 'Sanskrit',
        name_de: 'Sanskrit',
        name_ca: null,
        native: 'संस्कृतम्',
        native_el: 'संस्कृतम्',
        native_es: 'संस्कृतम्',
        native_it: 'संस्कृतम्',
        native_fr: 'संस्कृतम्',
        native_de: 'संस्कृतम्',
        native_ca: 'संस्कृतम्',
        rtl: null
    },
    {
        code: 'sc',
        name: 'Sardinian',
        name_el: null,
        name_es: 'Sardo',
        name_it: 'Sardo',
        name_fr: 'sarde',
        name_de: 'Sardisch',
        name_ca: null,
        native: 'Sardu',
        native_el: 'Sardu',
        native_es: 'Sardu',
        native_it: 'Sardu',
        native_fr: 'Sardu',
        native_de: 'Sardu',
        native_ca: 'Sardu',
        rtl: null
    },
    {
        code: 'sd',
        name: 'Sindhi',
        name_el: null,
        name_es: 'Sindhi',
        name_it: 'Sindhi',
        name_fr: 'Sindhi',
        name_de: 'Sindhi',
        name_ca: null,
        native: 'सिनधि',
        native_el: 'सिनधि',
        native_es: 'सिनधि',
        native_it: 'सिनधि',
        native_fr: 'सिनधि',
        native_de: 'सिनधि',
        native_ca: 'सिनधि',
        rtl: null
    },
    {
        code: 'se',
        name: 'Northern Sami',
        name_el: null,
        name_es: 'Sami septentrional',
        name_it: 'Sami del Nord',
        name_fr: 'Sami du Nord',
        name_de: 'Nördliches Sami',
        name_ca: null,
        native: 'Sámegiella',
        native_el: 'Sámegiella',
        native_es: 'Sámegiella',
        native_it: 'Sámegiella',
        native_fr: 'Sámegiella',
        native_de: 'Sámegiella',
        native_ca: 'Sámegiella',
        rtl: null
    },
    {
        code: 'sg',
        name: 'Sango',
        name_el: null,
        name_es: 'Sango',
        name_it: 'Sango',
        name_fr: 'Sango',
        name_de: 'Sango',
        name_ca: null,
        native: 'Sängö',
        native_el: 'Sängö',
        native_es: 'Sängö',
        native_it: 'Sängö',
        native_fr: 'Sängö',
        native_de: 'Sängö',
        native_ca: 'Sängö',
        rtl: null
    },
    {
        code: 'sh',
        name: 'Serbo-Croatian',
        name_el: null,
        name_es: 'Serbocroata',
        name_it: 'Serbo-croato',
        name_fr: 'Serbo-croate',
        name_de: 'Serbo-Kroatisch',
        name_ca: null,
        native: 'Srpskohrvatski / Српскохрватски',
        native_el: 'Srpskohrvatski / Српскохрватски',
        native_es: 'Srpskohrvatski / Српскохрватски',
        native_it: 'Srpskohrvatski / Српскохрватски',
        native_fr: 'Srpskohrvatski / Српскохрватски',
        native_de: 'Srpskohrvatski / Српскохрватски',
        native_ca: 'Srpskohrvatski / Српскохрватски',
        rtl: null
    },
    {
        code: 'si',
        name: 'Sinhalese',
        name_el: null,
        name_es: 'Cingalés',
        name_it: 'cingalese',
        name_fr: 'Cingalais',
        name_de: 'Singhalesisch',
        name_ca: null,
        native: 'සිංහල',
        native_el: 'සිංහල',
        native_es: 'සිංහල',
        native_it: 'සිංහල',
        native_fr: 'සිංහල',
        native_de: 'සිංහල',
        native_ca: 'සිංහල',
        rtl: null
    },
    {
        code: 'sk',
        name: 'Slovak',
        name_el: null,
        name_es: 'Eslovaco',
        name_it: 'Slovacco',
        name_fr: 'Slovaque',
        name_de: 'Slowakisch',
        name_ca: null,
        native: 'Slovenčina',
        native_el: 'Slovenčina',
        native_es: 'Slovenčina',
        native_it: 'Slovenčina',
        native_fr: 'Slovenčina',
        native_de: 'Slovenčina',
        native_ca: 'Slovenčina',
        rtl: null
    },
    {
        code: 'sl',
        name: 'Slovenian',
        name_el: null,
        name_es: 'Esloveno',
        name_it: 'sloveno',
        name_fr: 'Slovène',
        name_de: 'Slowenisch',
        name_ca: null,
        native: 'Slovenščina',
        native_el: 'Slovenščina',
        native_es: 'Slovenščina',
        native_it: 'Slovenščina',
        native_fr: 'Slovenščina',
        native_de: 'Slovenščina',
        native_ca: 'Slovenščina',
        rtl: null
    },
    {
        code: 'sm',
        name: 'Samoa',
        name_el: null,
        name_es: 'Samoa',
        name_it: 'Samoa',
        name_fr: 'Samoa',
        name_de: 'Samoa',
        name_ca: null,
        native: 'Gagana Samoa',
        native_el: 'Gagana Samoa',
        native_es: 'Gagana Samoa',
        native_it: 'Gagana Samoa',
        native_fr: 'Gagana Samoa',
        native_de: 'Gagana Samoa',
        native_ca: 'Gagana Samoa',
        rtl: null
    },
    {
        code: 'sn',
        name: 'Shona',
        name_el: null,
        name_es: 'Shona',
        name_it: 'Shona',
        name_fr: 'Shona',
        name_de: 'Schona',
        name_ca: null,
        native: 'chiShona',
        native_el: 'chiShona',
        native_es: 'chiShona',
        native_it: 'chiShona',
        native_fr: 'chiShona',
        native_de: 'chiShona',
        native_ca: 'chiShona',
        rtl: null
    },
    {
        code: 'so',
        name: 'Somalia',
        name_el: null,
        name_es: 'Somalia',
        name_it: 'Somalia',
        name_fr: 'Somalie',
        name_de: 'Somalia',
        name_ca: null,
        native: 'Soomaaliga',
        native_el: 'Soomaaliga',
        native_es: 'Soomaaliga',
        native_it: 'Soomaaliga',
        native_fr: 'Soomaaliga',
        native_de: 'Soomaaliga',
        native_ca: 'Soomaaliga',
        rtl: null
    },
    {
        code: 'sq',
        name: 'Albanian',
        name_el: null,
        name_es: 'Albanés',
        name_it: 'Albanese',
        name_fr: 'Albanais',
        name_de: 'Albanisch',
        name_ca: null,
        native: 'Shqip',
        native_el: 'Shqip',
        native_es: 'Shqip',
        native_it: 'Shqip',
        native_fr: 'Shqip',
        native_de: 'Shqip',
        native_ca: 'Shqip',
        rtl: null
    },
    {
        code: 'sr',
        name: 'Serbian',
        name_el: null,
        name_es: 'Serbio',
        name_it: 'Serbo',
        name_fr: 'Serbe',
        name_de: 'Serbisch',
        name_ca: null,
        native: 'Српски',
        native_el: 'Српски',
        native_es: 'Српски',
        native_it: 'Српски',
        native_fr: 'Српски',
        native_de: 'Српски',
        native_ca: 'Српски',
        rtl: null
    },
    {
        code: 'ss',
        name: 'Swati',
        name_el: null,
        name_es: 'Swati',
        name_it: 'Swati',
        name_fr: 'Swati',
        name_de: 'Swati',
        name_ca: null,
        native: 'SiSwati',
        native_el: 'SiSwati',
        native_es: 'SiSwati',
        native_it: 'SiSwati',
        native_fr: 'SiSwati',
        native_de: 'SiSwati',
        native_ca: 'SiSwati',
        rtl: null
    },
    {
        code: 'st',
        name: 'Southern Sotho',
        name_el: null,
        name_es: 'Sotho meridional',
        name_it: 'Sotho meridionale',
        name_fr: 'Sotho du Sud',
        name_de: 'Süd-Sotho',
        name_ca: null,
        native: 'Sesotho',
        native_el: 'Sesotho',
        native_es: 'Sesotho',
        native_it: 'Sesotho',
        native_fr: 'Sesotho',
        native_de: 'Sesotho',
        native_ca: 'Sesotho',
        rtl: null
    },
    {
        code: 'su',
        name: 'Sundanese',
        name_el: null,
        name_es: 'Sundanés',
        name_it: 'Sundanese',
        name_fr: 'Sundanais',
        name_de: 'Sundanisch',
        name_ca: null,
        native: 'Basa Sunda',
        native_el: 'Basa Sunda',
        native_es: 'Basa Sunda',
        native_it: 'Basa Sunda',
        native_fr: 'Basa Sunda',
        native_de: 'Basa Sunda',
        native_ca: 'Basa Sunda',
        rtl: null
    },
    {
        code: 'sv',
        name: 'Swedish',
        name_el: null,
        name_es: 'Sueco',
        name_it: 'Svedese',
        name_fr: 'suédois',
        name_de: 'Schwedisch',
        name_ca: null,
        native: 'Svenska',
        native_el: 'Svenska',
        native_es: 'Svenska',
        native_it: 'Svenska',
        native_fr: 'Svenska',
        native_de: 'Svenska',
        native_ca: 'Svenska',
        rtl: null
    },
    {
        code: 'sw',
        name: 'Swahili',
        name_el: null,
        name_es: 'Suajili',
        name_it: 'Swahili',
        name_fr: 'Swahili',
        name_de: 'Suaheli',
        name_ca: null,
        native: 'Kiswahili',
        native_el: 'Kiswahili',
        native_es: 'Kiswahili',
        native_it: 'Kiswahili',
        native_fr: 'Kiswahili',
        native_de: 'Kiswahili',
        native_ca: 'Kiswahili',
        rtl: null
    },
    {
        code: 'ta',
        name: 'Tamil',
        name_el: null,
        name_es: 'Tamil',
        name_it: 'Tamil',
        name_fr: 'tamoul',
        name_de: 'Tamilisch',
        name_ca: null,
        native: 'தமிழ்',
        native_el: 'தமிழ்',
        native_es: 'தமிழ்',
        native_it: 'தமிழ்',
        native_fr: 'தமிழ்',
        native_de: 'தமிழ்',
        native_ca: 'தமிழ்',
        rtl: null
    },
    {
        code: 'te',
        name: 'Telugu',
        name_el: null,
        name_es: 'Telugu',
        name_it: 'Telugu',
        name_fr: 'Telugu',
        name_de: 'Telugu',
        name_ca: null,
        native: 'తెలుగు',
        native_el: 'తెలుగు',
        native_es: 'తెలుగు',
        native_it: 'తెలుగు',
        native_fr: 'తెలుగు',
        native_de: 'తెలుగు',
        native_ca: 'తెలుగు',
        rtl: null
    },
    {
        code: 'tg',
        name: 'Tajik',
        name_el: null,
        name_es: 'Tayiko',
        name_it: 'tagico',
        name_fr: 'tadjik',
        name_de: 'Tadschikisch',
        name_ca: null,
        native: 'Тоҷикӣ',
        native_el: 'Тоҷикӣ',
        native_es: 'Тоҷикӣ',
        native_it: 'Тоҷикӣ',
        native_fr: 'Тоҷикӣ',
        native_de: 'Тоҷикӣ',
        native_ca: 'Тоҷикӣ',
        rtl: null
    },
    {
        code: 'th',
        name: 'Thai',
        name_el: null,
        name_es: 'Tailandés',
        name_it: 'tailandese',
        name_fr: 'thaïlandais',
        name_de: 'Thailändisch',
        name_ca: null,
        native: 'ไทย / Phasa Thai',
        native_el: 'ไทย / Phasa Thai',
        native_es: 'ไทย / Phasa Thai',
        native_it: 'ไทย / Phasa Thai',
        native_fr: 'ไทย / Phasa Thai',
        native_de: 'ไทย / Phasa Thai',
        native_ca: 'ไทย / Phasa Thai',
        rtl: null
    },
    {
        code: 'ti',
        name: 'Tigrinya',
        name_el: null,
        name_es: 'Tigriña',
        name_it: 'Tigrino',
        name_fr: 'tigrinya',
        name_de: 'Tigrinisch',
        name_ca: null,
        native: 'ትግርኛ',
        native_el: 'ትግርኛ',
        native_es: 'ትግርኛ',
        native_it: 'ትግርኛ',
        native_fr: 'ትግርኛ',
        native_de: 'ትግርኛ',
        native_ca: 'ትግርኛ',
        rtl: null
    },
    {
        code: 'tk',
        name: 'Turkmen',
        name_el: null,
        name_es: 'Turcomano',
        name_it: 'Turkmeno',
        name_fr: 'Turkmène',
        name_de: 'Turkmenisch',
        name_ca: null,
        native: 'Туркмен / تركمن',
        native_el: 'Туркмен / تركمن',
        native_es: 'Туркмен / تركمن',
        native_it: 'Туркмен / تركمن',
        native_fr: 'Туркмен / تركمن',
        native_de: 'Туркмен / تركمن',
        native_ca: 'Туркмен / تركمن',
        rtl: null
    },
    {
        code: 'tl',
        name: 'Tagalog / Filipino',
        name_el: null,
        name_es: 'Tagalo / filipino',
        name_it: 'Tagalog / Filippino',
        name_fr: 'Tagalog / Philippin',
        name_de: 'Tagalog / Filipino',
        name_ca: null,
        native: 'Tagalog',
        native_el: 'Tagalog',
        native_es: 'Tagalog',
        native_it: 'Tagalog',
        native_fr: 'Tagalog',
        native_de: 'Tagalog',
        native_ca: 'Tagalog',
        rtl: null
    },
    {
        code: 'tn',
        name: 'Tswana',
        name_el: null,
        name_es: 'Tswana',
        name_it: 'Tswana',
        name_fr: 'Tswana',
        name_de: 'Tswana',
        name_ca: null,
        native: 'Setswana',
        native_el: 'Setswana',
        native_es: 'Setswana',
        native_it: 'Setswana',
        native_fr: 'Setswana',
        native_de: 'Setswana',
        native_ca: 'Setswana',
        rtl: null
    },
    {
        code: 'to',
        name: 'Tonga',
        name_el: null,
        name_es: 'Tonga',
        name_it: 'Tonga',
        name_fr: 'Tonga',
        name_de: 'Tonga',
        name_ca: null,
        native: 'Lea Faka-Tonga',
        native_el: 'Lea Faka-Tonga',
        native_es: 'Lea Faka-Tonga',
        native_it: 'Lea Faka-Tonga',
        native_fr: 'Lea Faka-Tonga',
        native_de: 'Lea Faka-Tonga',
        native_ca: 'Lea Faka-Tonga',
        rtl: null
    },
    {
        code: 'tr',
        name: 'Turkish',
        name_el: null,
        name_es: 'Turco',
        name_it: 'turco',
        name_fr: 'Turc',
        name_de: 'Türkisch',
        name_ca: null,
        native: 'Türkçe',
        native_el: 'Türkçe',
        native_es: 'Türkçe',
        native_it: 'Türkçe',
        native_fr: 'Türkçe',
        native_de: 'Türkçe',
        native_ca: 'Türkçe',
        rtl: null
    },
    {
        code: 'ts',
        name: 'Tsonga',
        name_el: null,
        name_es: 'Tsonga',
        name_it: 'Tsonga',
        name_fr: 'Tsonga',
        name_de: 'Tsonga',
        name_ca: null,
        native: 'Xitsonga',
        native_el: 'Xitsonga',
        native_es: 'Xitsonga',
        native_it: 'Xitsonga',
        native_fr: 'Xitsonga',
        native_de: 'Xitsonga',
        native_ca: 'Xitsonga',
        rtl: null
    },
    {
        code: 'tt',
        name: 'Tatar',
        name_el: null,
        name_es: 'Tártaro',
        name_it: 'Tatar',
        name_fr: 'Tatar',
        name_de: 'Tatarisch',
        name_ca: null,
        native: 'Tatarça',
        native_el: 'Tatarça',
        native_es: 'Tatarça',
        native_it: 'Tatarça',
        native_fr: 'Tatarça',
        native_de: 'Tatarça',
        native_ca: 'Tatarça',
        rtl: null
    },
    {
        code: 'tw',
        name: 'Twi',
        name_el: null,
        name_es: 'Twi',
        name_it: 'Twi',
        name_fr: 'Twi',
        name_de: 'Twi',
        name_ca: null,
        native: 'Twi',
        native_el: 'Twi',
        native_es: 'Twi',
        native_it: 'Twi',
        native_fr: 'Twi',
        native_de: 'Twi',
        native_ca: 'Twi',
        rtl: null
    },
    {
        code: 'ty',
        name: 'Tahitian',
        name_el: null,
        name_es: 'Tahitiano',
        name_it: 'Tahitiano',
        name_fr: 'Tahitien',
        name_de: 'Tahitisch',
        name_ca: null,
        native: 'Reo Mā`ohi',
        native_el: 'Reo Mā`ohi',
        native_es: 'Reo Mā`ohi',
        native_it: 'Reo Mā`ohi',
        native_fr: 'Reo Mā`ohi',
        native_de: 'Reo Mā`ohi',
        native_ca: 'Reo Mā`ohi',
        rtl: null
    },
    {
        code: 'ug',
        name: 'Uyghur',
        name_el: null,
        name_es: 'Uigur',
        name_it: 'Uiguro',
        name_fr: 'Ouïghour',
        name_de: 'Uighurisch',
        name_ca: null,
        native: 'Uyƣurqə / ئۇيغۇرچە',
        native_el: 'Uyƣurqə / ئۇيغۇرچە',
        native_es: 'Uyƣurqə / ئۇيغۇرچە',
        native_it: 'Uyƣurqə / ئۇيغۇرچە',
        native_fr: 'Uyƣurqə / ئۇيغۇرچە',
        native_de: 'Uyƣurqə / ئۇيغۇرچە',
        native_ca: 'Uyƣurqə / ئۇيغۇرچە',
        rtl: null
    },
    {
        code: 'uk',
        name: 'Ukrainian',
        name_el: null,
        name_es: 'Ucraniano',
        name_it: 'Ucraino',
        name_fr: 'ukrainien',
        name_de: 'Ukrainisch',
        name_ca: null,
        native: 'Українська',
        native_el: 'Українська',
        native_es: 'Українська',
        native_it: 'Українська',
        native_fr: 'Українська',
        native_de: 'Українська',
        native_ca: 'Українська',
        rtl: null
    },
    {
        code: 'ur',
        name: 'Urdu',
        name_el: null,
        name_es: 'Urdu',
        name_it: 'Urdu',
        name_fr: 'ourdou',
        name_de: 'Urdu',
        name_ca: null,
        native: 'اردو',
        native_el: 'اردو',
        native_es: 'اردو',
        native_it: 'اردو',
        native_fr: 'اردو',
        native_de: 'اردو',
        native_ca: 'اردو',
        rtl: true
    },
    {
        code: 'uz',
        name: 'Uzbek',
        name_el: null,
        name_es: 'Uzbeko',
        name_it: 'Uzbeko',
        name_fr: 'Ouzbek',
        name_de: 'Usbekisch',
        name_ca: null,
        native: 'Ўзбек',
        native_el: 'Ўзбек',
        native_es: 'Ўзбек',
        native_it: 'Ўзбек',
        native_fr: 'Ўзбек',
        native_de: 'Ўзбек',
        native_ca: 'Ўзбек',
        rtl: null
    },
    {
        code: 've',
        name: 'Venda',
        name_el: null,
        name_es: 'Venda',
        name_it: 'Venda',
        name_fr: 'Venda',
        name_de: 'Venda',
        name_ca: null,
        native: 'Tshivenḓa',
        native_el: 'Tshivenḓa',
        native_es: 'Tshivenḓa',
        native_it: 'Tshivenḓa',
        native_fr: 'Tshivenḓa',
        native_de: 'Tshivenḓa',
        native_ca: 'Tshivenḓa',
        rtl: null
    },
    {
        code: 'vi',
        name: 'Vietnamese',
        name_el: null,
        name_es: 'Vietnamita',
        name_it: 'vietnamita',
        name_fr: 'vietnamien',
        name_de: 'Vietnamesisch',
        name_ca: null,
        native: 'Tiếng Việt',
        native_el: 'Tiếng Việt',
        native_es: 'Tiếng Việt',
        native_it: 'Tiếng Việt',
        native_fr: 'Tiếng Việt',
        native_de: 'Tiếng Việt',
        native_ca: 'Tiếng Việt',
        rtl: null
    },
    {
        code: 'vo',
        name: 'Volapük',
        name_el: null,
        name_es: 'Volapük',
        name_it: 'Volapük',
        name_fr: 'Volapük',
        name_de: 'Volapük',
        name_ca: null,
        native: 'Volapük',
        native_el: 'Volapük',
        native_es: 'Volapük',
        native_it: 'Volapük',
        native_fr: 'Volapük',
        native_de: 'Volapük',
        native_ca: 'Volapük',
        rtl: null
    },
    {
        code: 'wa',
        name: 'Walloon',
        name_el: null,
        name_es: 'Valón',
        name_it: 'Vallone',
        name_fr: 'Wallon',
        name_de: 'Wallonisch',
        name_ca: null,
        native: 'Walon',
        native_el: 'Walon',
        native_es: 'Walon',
        native_it: 'Walon',
        native_fr: 'Walon',
        native_de: 'Walon',
        native_ca: 'Walon',
        rtl: null
    },
    {
        code: 'wo',
        name: 'Wolof',
        name_el: null,
        name_es: 'Wolof',
        name_it: 'Wolof',
        name_fr: 'wolof',
        name_de: 'Wolof',
        name_ca: null,
        native: 'Wollof',
        native_el: 'Wollof',
        native_es: 'Wollof',
        native_it: 'Wollof',
        native_fr: 'Wollof',
        native_de: 'Wollof',
        native_ca: 'Wollof',
        rtl: null
    },
    {
        code: 'xh',
        name: 'Xhosa',
        name_el: null,
        name_es: 'Xhosa',
        name_it: 'Xhosa',
        name_fr: 'xhosa',
        name_de: 'Xhosa',
        name_ca: null,
        native: 'isiXhosa',
        native_el: 'isiXhosa',
        native_es: 'isiXhosa',
        native_it: 'isiXhosa',
        native_fr: 'isiXhosa',
        native_de: 'isiXhosa',
        native_ca: 'isiXhosa',
        rtl: null
    },
    {
        code: 'yi',
        name: 'Yiddish',
        name_el: null,
        name_es: 'Yiddish',
        name_it: 'Yiddish',
        name_fr: 'yiddish',
        name_de: 'Jiddisch',
        name_ca: null,
        native: 'ייִדיש',
        native_el: 'ייִדיש',
        native_es: 'ייִדיש',
        native_it: 'ייִדיש',
        native_fr: 'ייִדיש',
        native_de: 'ייִדיש',
        native_ca: 'ייִדיש',
        rtl: true
    },
    {
        code: 'yo',
        name: 'Yoruba',
        name_el: null,
        name_es: 'Yoruba',
        name_it: 'Yoruba',
        name_fr: 'yoruba',
        name_de: 'Yoruba',
        name_ca: null,
        native: 'Yorùbá',
        native_el: 'Yorùbá',
        native_es: 'Yorùbá',
        native_it: 'Yorùbá',
        native_fr: 'Yorùbá',
        native_de: 'Yorùbá',
        native_ca: 'Yorùbá',
        rtl: null
    },
    {
        code: 'za',
        name: 'Zhuang',
        name_el: null,
        name_es: 'Zhuang',
        name_it: 'Zhuang',
        name_fr: 'Zhuang',
        name_de: 'Zhuang',
        name_ca: null,
        native: 'Cuengh / Tôô / 壮语',
        native_el: 'Cuengh / Tôô / 壮语',
        native_es: 'Cuengh / Tôô / 壮语',
        native_it: 'Cuengh / Tôô / 壮语',
        native_fr: 'Cuengh / Tôô / 壮语',
        native_de: 'Cuengh / Tôô / 壮语',
        native_ca: 'Cuengh / Tôô / 壮语',
        rtl: null
    },
    {
        code: 'zh',
        name: 'Chinese',
        name_el: null,
        name_es: 'Chino',
        name_it: 'cinese',
        name_fr: 'chinois',
        name_de: 'Chinesisch',
        name_ca: null,
        native: '中文',
        native_el: '中文',
        native_es: '中文',
        native_it: '中文',
        native_fr: '中文',
        native_de: '中文',
        native_ca: '中文',
        rtl: null
    },
    {
        code: 'zu',
        name: 'Zulu',
        name_el: null,
        name_es: 'Zulú',
        name_it: 'Zulu',
        name_fr: 'zoulou',
        name_de: 'Zulu',
        name_ca: null,
        native: 'isiZulu',
        native_el: 'isiZulu',
        native_es: 'isiZulu',
        native_it: 'isiZulu',
        native_fr: 'isiZulu',
        native_de: 'isiZulu',
        native_ca: 'isiZulu',
        rtl: null
    },
];
