import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Page404Component } from './page404.component';
import { Page404RoutingModule } from './page404-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [Page404Component],
  imports: [
    CommonModule,
    Page404RoutingModule,
    TranslateModule,
    PipesModule
  ]
})
export class Page404Module { }
