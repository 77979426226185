export class Language {
    code: string;
    name: string;
    name_el: string;
    name_es: string;
    name_it: string;
    name_fr: string;
    name_de: string;
    name_ca: string;
    native_el: string;
    native_es: string;
    native_it: string;
    native_fr: string;
    native_de: string;
    native_ca: string;
    native: string;
    rtl?: boolean;
}
