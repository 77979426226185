import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-place-auto-complete',
  templateUrl: './place-auto-complete.component.html',
  styleUrls: ['./place-auto-complete.component.scss']
})
export class PlaceAutoCompleteComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() location: string;
  @Input() page: 'home' | 'list';

  @ViewChild('searchLocationView', { static: false }) searchLocation: ElementRef; // google place autocomplete

  public longitude: number;
  public latitude: number;
  public place_id: string;

  private clearSubscription: Subscription;

  constructor(
    public utilsService: UtilsService,
    public cdRef: ChangeDetectorRef,
    private pubSub: NgxPubSubService,
    public translate: TranslateService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    this.clearSubscription = this.pubSub.subscribe('clearAllFilters', () => {
      this.searchLocation.nativeElement.value = '';
    });
  }

  ngOnDestroy(): void {
    if (this.clearSubscription)
      this.clearSubscription.unsubscribe();
  }

  async ngAfterViewInit(): Promise<void> {

    // tslint:disable-next-line:curly
    if (this.utilsService.isBrowser) {

      await this.utilsService.delay(1000);

      if (typeof google === 'undefined' || !google) {

        const DSLScript = document.createElement('script');
        DSLScript.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDzZDFoIXcMXZiTGWQvgZWcguIAXK0W3nk&language=en';
        DSLScript.type = 'text/javascript';
        document.body.appendChild(DSLScript);
        document.body.removeChild(DSLScript);
      }
      this.initializeAddressAutocomplete();
    }
  }

  async initializeAddressAutocomplete(): Promise<void> {

    await this.utilsService.delay(500);

    if (this.location && this.location !== '')
      this.searchLocation.nativeElement.value = this.location;

    const returnLocationAutocomplete = new google.maps.places.Autocomplete(this.searchLocation.nativeElement, {}); // types: ['establishment']  // 'establishment' / 'address' / 'geocode'

    google.maps.event.addListener(returnLocationAutocomplete, 'place_changed', async () => {
      if (this.searchLocation.nativeElement.value !== '') {

        const place = returnLocationAutocomplete.getPlace();
        const addressDetails = await this.utilsService.extractGoogleAddressDetails(place, true); // (city, state, country)

        this.longitude = place.geometry.location.lng();
        this.latitude = place.geometry.location.lat();
        this.place_id = addressDetails.google_place_id;

        let placeName = null;

        const isRoute = (addressDetails.types.split(',')).includes('route');
        // const isPointOfInterest = (addressDetails.types.split(',')).includes('point_of_interest');
        if (addressDetails.types && isRoute)
          placeName = addressDetails?.street ? addressDetails.street : null;
        else if (addressDetails.is_city)
          placeName = (addressDetails?.city) ? addressDetails.city : null;
        // else if (addressDetails.types && isPointOfInterest)
        //   placeName = place.name;
        else
          placeName = place.name;


        this.searchLocation.nativeElement.value = placeName;

        const addressFilter = {
          longitude: this.longitude,
          latitude: this.latitude,
          place_id: this.place_id,
          location: placeName,
          address_details: addressDetails
        }; // Location contains the text which exists in the address text box, passes it to parent, as the template must be instantiated again
        //  if the filters modal is closed and then re-opened
        this.pubSub.publishEvent('updateAddressFilter', addressFilter);

        this.searchLocation.nativeElement.blur();
        this.searchLocation.nativeElement.focus();
        this.searchLocation.nativeElement.blur();

        this.cdRef.detectChanges();
        return Promise.resolve();
      }
    });
  }
}
