import { PipesModule } from './../../../pipes/pipes.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerRoutingModule } from '../customer-routing.module';
import { CustomerDetailsComponent } from './customer-details.component';
import { ComponentsModule } from '../../../components/components.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BookingsComponent } from './components/bookings/bookings.component';
import { FavouritesComponent } from './components/favourites/favourites.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { InformationComponent } from './components/information/information.component';
import { LaddaModule } from 'angular2-ladda';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { RatingModule } from 'ngx-bootstrap/rating';

@NgModule({
  declarations: [
    CustomerDetailsComponent,
    BookingsComponent,
    FavouritesComponent,
    ReviewsComponent,
    InformationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LaddaModule,
    ComponentsModule,
    PipesModule,
    TranslateModule,
    CustomerRoutingModule,
    NgxSkeletonLoaderModule,
    TooltipModule.forRoot(),
    NgxIntlTelInputModule,
    NgSelectModule,
    NgxDropzoneModule,
    RatingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomerDetailsModule { }
