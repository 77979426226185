<div class="main-header" [hidden]="!utilsService.showContent">

  <app-global-notifications [notifications]="globalNotifications"></app-global-notifications>

  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg"
    [ngClass]="{ 'navbar-light bg-white' : isMobile || !isAtHome  || ( isAtHome && hasScrolled ), 'bg-transparent border-0' : isAtHome && !hasScrolled && !isMobile, 'desktop-limiter' : !isMobile  }">

    <!-- [ngClass]="{'container-fluid' : !isAtHome || isMobile || hasScrolled, 'container': isAtHome && !hasScrolled}" -->
    <div class="container">
      <a href="/" class="navbar-brand">

        <img [src]="utilsService.customDomainSettings?.logo?.dark" *ngIf="isAtHome && hasScrolled && !isMobile" alt="Tourmie Stay logo blue"
          class="brand-image">

        <img [src]="utilsService.customDomainSettings?.logo?.light" *ngIf="isAtHome && !hasScrolled && !isMobile" alt="Tourmie Stay logo white"
          class="brand-image">

        <img [src]="utilsService.customDomainSettings?.logo?.dark" *ngIf="!isAtHome || isMobile" alt="Tourmie Stay logo blue"
          [ngClass]="isMobile ? 'title-small' : ''" class="brand-image">

      </a>

      <button class="navbar-toggler order-1 border-0 font-size-15" type="button" data-toggle="collapse" data-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">

        <span class="navbar-toggler-icon"></span>

      </button>

      <div class="collapse navbar-collapse order-3" id="navbarCollapse">

        <ul class="order-1 order-md-3 navbar-nav ml-auto" *ngIf="!isMobile">


          <li class="nav-item">
            <a [routerLink]="['/properties']" class="nav-link" *ngIf="!isAtHome" [gaEvent]="{ title: 'click_findhome_navbar' }">

              {{'GENERIC.BUTTONS.FIND_A_HOME' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie
              Stay'}}

            </a>

          </li>


          <!-- <li class="nav-item">

            <a [routerLink]="[ '/listyourproperty' ]" class="nav-link" [ngClass]="hasScrolled || !isAtHome ? 'text-black' : 'text-white'"
              [gaEvent]="{ title: 'click_listyourproperty_navbar' }">

              {{'GENERIC.BUTTONS.LIST_YOUR_PROPERTY' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie
              Stay'}}

            </a>

          </li> -->





          <li class="nav-item" *ngIf="!customer?.customer_id">

            <a class="btn btn-tourmie-primary py-1 mt-1 ml-2" [routerLink]="[ '/login' ]" [gaEvent]="{ title: 'click_signin_navbar' }">

              {{'GENERIC.BUTTONS.SIGN_IN' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}

            </a>

          </li>

          <li class="nav-item" *ngIf="customer?.customer_id">

            <app-navbar-user-item [bgChange]="!isAtHome || (hasScrolled  && isAtHome)"></app-navbar-user-item>

          </li>

          <li class="nav-item nav-lang">

            <app-language-switch [hideLabel]="true" [onlyFlag]="true"></app-language-switch>

          </li>

        </ul>

        <ul class="order-1 order-md-3 navbar-nav ml-auto p-2" *ngIf="isMobile">


          <li class="nav-item">

            <a [routerLink]="['/properties']" class="nav-link" *ngIf="!isAtHome" [gaEvent]="{ title: 'click_findhome_navbar' }">
              {{'GENERIC.BUTTONS.FIND_A_HOME' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie
              Stay'}}
            </a>

          </li>


          <li class="nav-item">

            <a [routerLink]="[ '/listyourproperty' ]" class="nav-link" [gaEvent]="{ title: 'click_listyourproperty_navbar' }">
              {{'GENERIC.BUTTONS.LIST_YOUR_PROPERTY' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie
              Stay'}}
            </a>

          </li>

          <li class="nav-item" *ngIf="!customer?.customer_id && isMobile">

            <a [routerLink]="[ '/login' ]" class="nav-link" [gaEvent]="{ title: 'click_signin_navbar' }">
              {{'GENERIC.BUTTONS.SIGN_IN' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay' }}
            </a>

          </li>


          <li class="dropdown-divider" *ngIf="customer?.customer_id && isMobile"></li>

          <li class="nav-item" *ngIf="customer?.customer_id && isMobile">

            <a [routerLink]="[ '/profile' ]" class="nav-link" [gaEvent]="{ title: 'click_myprofile_navbar' }">
              {{'GENERIC.BUTTONS.MY_PROFILE' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
            </a>

          </li>


          <!-- <li class="nav-item" *ngIf="customer?.customer_id && isMobile">

            <a [routerLink]="[ '/bookings' ]" class="nav-link" [gaEvent]="{ title: 'click_mybookings_navbar' }">
              {{'GENERIC.BUTTONS.MY_BOOKINGS' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}}
            </a>

          </li> -->


          <li class="nav-item" *ngIf="customer?.customer_id && isMobile">

            <a [routerLink]="[ '/logout' ]" class="nav-link text-danger" [gaEvent]="{ title: 'click_signout_navbar' }">
              {{'GENERIC.BUTTONS.SIGN_OUT' | translate | replaceTourmieStay: utilsService?.customDomainSettings?.app_title || 'Tourmie Stay' }}
            </a>

          </li>


          <li class="dropdown-divider"></li>

          <li class="nav-item nav-lang">

            <app-language-switch [hideLabel]="true" [onlyFlag]="false"></app-language-switch>

          </li>

        </ul>

      </div>

    </div>

  </nav>

  <!-- /.navbar -->
</div>