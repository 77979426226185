import { AccountCategory } from '../app/models';

export const account_categories: AccountCategory[] = [
    {
        label: 'Individual host',
        label_el: 'Ιδιώτης οικοδεσπότης',
        label_es: 'Anfitrión individual',
        label_it: 'Host individuale',
        label_fr: 'Hôte individuel',
        label_de: 'Individueller Gastgeber',
        label_ca: 'Amfitrió individual',
        description: 'For individual owners who rent their own home, apartment or villa.',
        description_el: 'Για ιδιώτες οικοδεσπότες που ενοικιάζουν το δικό τους σπίτι, διαμέρισμα ή βίλα.',
        description_es: 'Para propietarios individuales que alquilan su propia casa, apartamento o villa.',
        description_it: 'Per proprietari individuali che affittano la propria casa, appartamento o villa.',
        description_fr: 'Pour les propriétaires individuels qui louent leur propre maison, appartement ou villa.',
        description_de: 'Für individuelle Eigentümer, die ihr eigenes Haus, Apartment oder Villa vermieten.',
        description_ca: 'Per a propietaris individuals que lloguen la seva pròpia casa, apartament o vila.',
        value: 'individual_host',
        image: 'assets/img/auth/registration/property-owner.png',
        types: ['accommodation'],
    },
    {
        label: 'Professional host',
        label_el: 'Επαγγελματίας οικοδεσπότης',
        label_es: 'Anfitrión profesional',
        label_it: 'Host professionale',
        label_fr: 'Hôte professionnel',
        label_de: 'Professioneller Gastgeber',
        label_ca: 'Amfitrió professional',
        description: 'For professional hosts or property management companies with one or multiple properties.',
        description_el: 'Για επαγγελματίες οικοδεσπότες ή εταιρείες διαχείρισης ακινήτων με ένα ή περισσότερα καταλύματα.',
        description_es: 'Para anfitriones profesionales o empresas de gestión de propiedades con una o varias propiedades.',
        description_it: 'Per host professionali o società di gestione immobiliare con una o più proprietà.',
        description_fr: 'Pour les hôtes professionnels ou les sociétés de gestion immobilière avec une ou plusieurs propriétés.',
        description_de: 'Für professionelle Gastgeber oder Immobilienverwaltungsgesellschaften mit einer oder mehreren Immobilien.',
        description_ca: 'Per a amfitrions professionals o empreses de gestió de propietats amb una o diverses propietats.',
        value: 'professional_host',
        image: 'assets/img/auth/registration/property-manager.png',
        types: ['accommodation'],
    },
    {
        label: 'Hotel',
        label_el: 'Ξενοδοχείο',
        label_es: 'Hotel',
        label_it: 'Hotel',
        label_fr: 'Hôtel',
        label_de: 'Hotel',
        label_ca: 'Hotel',
        description: 'For hotels, resorts or boutique hotels who offer a variety of rooms and suites.',
        description_el: 'Για ξενοδοχεία, θέρετρα ή boutique ξενοδοχεία με μία ποικιλία από δωμάτια και σουίτες.',
        description_es: 'Para hoteles, resorts o hoteles boutique que ofrecen una variedad de habitaciones y suites.',
        description_it: 'Per hotel, resort o hotel boutique che offrono una varietà di camere e suite.',
        description_fr: 'Pour les hôtels, les complexes hôteliers ou les hôtels-boutiques qui offrent une variété de chambres et de suites.',
        description_de: 'Für Hotels, Resorts oder Boutique-Hotels, die eine Vielzahl von Zimmern und Suiten anbieten.',
        description_ca: 'Per a hotels, resorts o hotels boutique que ofereixen una varietat d\'habitacions i suites.',
        value: 'hotel',
        image: 'assets/img/auth/registration/hotel.png',
        types: ['accommodation'],
    },
    {
        label: 'Rent a car',
        label_el: 'Ενοικίαση αυτοκινήτου',
        label_es: 'Alquilar un coche',
        label_it: 'Noleggiare un’auto',
        label_fr: 'Louer une voiture',
        label_de: 'Ein Auto mieten',
        label_ca: 'Llogar un cotxe',
        description: null,
        description_el: null,
        description_es: null,
        description_it: null,
        description_fr: null,
        description_de: null,
        description_ca: null,
        value: 'car_rental_provider',
        image: null,
        types: ['service'],
    },
    {
        label: 'Transfer',
        label_el: 'Μεταφορά',
        label_es: 'Traslado',
        label_it: 'Trasferimento',
        label_fr: 'Transfert',
        label_de: 'Transfer',
        label_ca: 'Trasllat',
        description: null,
        description_el: null,
        description_es: null,
        description_it: null,
        description_fr: null,
        description_de: null,
        description_ca: null,
        value: 'transfer_provider',
        image: null,
        types: ['service'],
    },
    {
        label: 'Experiences',
        label_el: 'Εμπειρίες',
        label_es: 'Experiencias',
        label_it: 'Esperienze',
        label_fr: 'Expériences',
        label_de: 'Erlebnisse',
        label_ca: 'Experiències',
        description: null,
        description_el: null,
        description_es: null,
        description_it: null,
        description_fr: null,
        description_de: null,
        description_ca: null,
        value: 'experience_provider',
        image: null,
        types: ['service'],
    },
    {
        label: 'Food',
        label_el: 'Φαγητό',
        label_es: 'Comida',
        label_it: 'Cibo',
        label_fr: 'Nourriture',
        label_de: 'Essen',
        label_ca: 'Menjar',
        description: null,
        description_el: null,
        description_es: null,
        description_it: null,
        description_fr: null,
        description_de: null,
        description_ca: null,
        value: 'food_provider',
        image: null,
        types: ['service'],
    }
];



export const account_types = [
    {
        label: 'Business',
        label_el: 'Επιχείρηση',
        label_es: 'Negocio',
        label_it: 'Azienda',
        label_fr: 'Entreprise',
        label_de: 'Unternehmen',
        label_ca: 'Negoci',
        value: 'business'
    },
    {
        label: 'Individual',
        label_el: 'Ιδιώτης',
        label_es: 'Individual',
        label_it: 'Individuale',
        label_fr: 'Individuel',
        label_de: 'Einzelunternehmen',
        label_ca: 'Individual',
        value: 'individual'
    }
];



export const account_provider_types = [
    {
        label: 'Accommodation Provider',
        label_el: 'Πάροχος Διαμονής',
        label_es: 'Proveedor de Alojamiento',
        label_it: 'Fornitore di Alloggi',
        label_fr: 'Fournisseur d\'Hébergement',
        label_de: 'Unterkunftsanbieter',
        label_ca: 'Proveedor d\'Allotjament',
        value: 'accommodation'
    },
    {
        label: 'Service Provider',
        label_el: 'Πάροχος Υπηρεσιών',
        label_es: 'Proveedor de Servicios',
        label_it: 'Fornitore di Servizi',
        label_fr: 'Fournisseur de Services',
        label_de: 'Dienstleister',
        label_ca: 'Proveedor de Serveis',
        value: 'service'
    }
];
