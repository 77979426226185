import { environment } from '../../../environments/environment';
import { Customer, Property } from '../../models';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';
import { StorageService } from '../../services/storage/storage.service';
import { FavoritesService } from '../../services/favourites/favourites.service';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-property-item-list',
  templateUrl: './property-item-list.component.html',
  styleUrls: ['./property-item-list.component.scss']
})
export class PropertyItemListComponent implements OnInit, OnDestroy {

  @Input() property: Property;
  @Input() displayTemplate: string;

  @Input() adults: number;
  @Input() children: number;
  @Input() infants: number;
  @Input() startDate: string;
  @Input() endDate: string;

  public customer: Customer = new Customer();
  private customerUpdatedSubscription: Subscription;

  public reviewMaxRating = environment.params.reviewMaxRating;
  public accoGeneralInfoSource: { label: string; label_el: string; value: string; }[];

  public isImgLoaded = false;
  public isFavorite = false;

  public isMobile = false;
  private isMobileSubscription: Subscription;

  constructor(
    private pubSub: NgxPubSubService,
    public utilsService: UtilsService,
    private storageService: StorageService,
    public translate: TranslateService,
    private favoriteService: FavoritesService
  ) {

    this.isMobile = this.utilsService.isMobile;

    this.accoGeneralInfoSource = this.utilsService.propertyGeneralInfoItems;
  }

  ngOnInit(): void {

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    this.customer = this.storageService.getItem('customer');

    this.customerUpdatedSubscription = this.pubSub.subscribe('customerDataUpdated', async (customer: Customer) => {

      this.customer = customer;

    });


    if (this.customer?.favorite_services?.length > 0) {

      const favoriteService = this.utilsService.lodash.find(this.customer.favorite_services, (fs) => fs.service_id === this.property.service_id);

      if (favoriteService?.service_id)
        this.isFavorite = true;

    }

  }

  ngOnDestroy(): void {
    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

    if (this.customerUpdatedSubscription)
      this.customerUpdatedSubscription.unsubscribe();
  }

  async addToFavorites(e: Event, property: Property): Promise<void> {

    e.preventDefault();

    if (!environment.production)
      console.log('added' + property.service_id);

    try {

      await this.favoriteService.addFavorite(property);
      this.isFavorite = true;

    } catch (error) {

      if (!environment.production)
        console.log(error);

    }
  }

  async removeFromFavorites(e: Event, service_id: string): Promise<void> {
    e.preventDefault();

    if (!environment.production)
      console.log('removed' + service_id);


    try {

      await this.favoriteService.removeFavorite(this.property.service_id);
      this.isFavorite = false;

    } catch (error) {

      if (!environment.production)
        console.log(error);

    }
  }

}
