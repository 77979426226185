import { commonEnvironment } from './environment.common';

const env: Partial<typeof commonEnvironment> = {
  production: true,
  environmentName: 'production',
  params: {
    ...commonEnvironment.params,
    host: 'https://api.prod.tourmie.com',
  }
};


// Export all settings of common replaced by prod options
export const environment = { ...commonEnvironment, ...env };
