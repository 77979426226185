export const multilingual_text_fields = {
    services: {
        accommodation: {
            basic: [
                'title',
                'title_internal',
                'description',
                'short_description',
                'parking_instructions',
                'area_description',
                'checkin_instructions',
                'checkout_instructions',
                'luggage_storage_description',
                'local_transport_description',
                'address_directions',
                'special_policies',
                'accessibility',
                'other_important_info',
                'welcome_text',
                'house_rules',
                'lease_agreement',
                'damage_deposit_description',
            ],
            bedroom: ['title'],
            amenity: ['description'],
            image: ['title'],
            check_in_image: ['description'],
            appliance_instructions: ['description'],
            luggage_storage_image: ['description'],
            extra_cost: ['description'],
            faq: ['question', 'answer'],
        },
        rent_car: {
            basic: [
                'title',
                'short_description',
                'description',
                'warranty_description',
                'special_policies',
                'terms_of_service',
            ],
            extra_cost: ['description'],
            feature: ['description'],
            image: ['title']
        },
        experience: {
            basic: [
                'title',
                'short_description',
                'description',
                'special_policies',
                'terms_of_service',
            ],
            route_point: ['description'],
            feature: ['description'],
            image: ['title'],
        },
        food: {
            basic: [
                'title',
                'short_description',
                'description',
                'special_policies',
                'terms_of_service',
            ],
            feature: ['description'],
            image: ['title']
        },
        transfer: {
            basic: [
                'title',
                'short_description',
                'description',
                'special_policies',
                'terms_of_service',
            ],
            feature: ['description'],
            image: ['title']
        }
    }
};
