export class CustomerProfileUpdate {
    customer_id?: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    birth_date: Date;

    old_password: string;
    new_password: string;
    new_password_confirm: string;

    identity_type?: string;
    identity_card_number?: string;
    passport_number?: string;
    driving_license_number?: string;

    constructor(props?: CustomerProfileUpdate) {

        this.customer_id = props?.customer_id || null;
        this.first_name = props?.first_name || null;
        this.last_name = props?.last_name || null;
        this.email = props?.email || null;
        this.phone = props?.phone || null;
        this.birth_date = props?.birth_date || null;
        this.old_password = props?.old_password || null;
        this.new_password = props?.new_password || null;
        this.new_password_confirm = props?.new_password_confirm || null;
        this.identity_type = props?.identity_type || null;
        this.identity_card_number = props?.identity_card_number || null;
        this.passport_number = props?.passport_number || null;
        this.driving_license_number = props?.driving_license_number || null;
    }
}
