import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceTourmieStay'
})
export class ReplaceTourmieStayPipe implements PipeTransform {

  transform(value: string, replacement_string: string): string {

    if (!value)
      return value;



    return value.replace(/Tourmie Stay/gi, replacement_string);


  }

}
