import { ServiceBed } from './ServiceBed';

export class ServiceBedroom {

    id?: number;
    service_id?: string;
    title?: string;

    // extras
    beds?: ServiceBed[];

    constructor(props?: ServiceBedroom) {
        this.id = props?.id || null;
        this.service_id = props?.service_id || null;
        this.title = props?.title || null;
    }

}
