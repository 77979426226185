import { environment } from '../../../../../../environments/environment';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Customer, Booking } from '../../../../../models';
import { BookingsService } from '../../../../../services/bookings/bookings.service';
import { UtilsService } from '../../../../../services/utils/utils.service';
import { SeoService } from '../../../../../services/seo/seo-service.service';


@Component({
  selector: 'app-customer-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit, OnDestroy {

  @Input() customer: Customer;

  public bookings: Booking[];
  public bookingsLoading: boolean;

  public isMobile = false;
  private isMobileSubscription: Subscription;

  constructor(
    public utilsService: UtilsService,
    private seo: SeoService,
    private bookingService: BookingsService,
  ) {

    this.isMobile = this.utilsService.isMobile;

  }

  async ngOnInit(): Promise<void> {

    this.seo.updatePageMetadata({ page: `bookings` });

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });


    this.bookings = [];
    this.bookingsLoading = true;



    try {

      this.bookings = await this.bookingService.getBookings();

      if (!environment.production)
        console.log(this.bookings);

      if (this.utilsService.isBrowser)
        window.scrollTo(0, 0);

    } catch (error) {

      if (!environment.production)
        console.log(error);

    }
    this.bookingsLoading = false;
  }

  ngOnDestroy(): void {
    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

  }
}
