export type MultilingualLanguageCode = 'en' | 'el';

export class MultilingualEntity {

    id: number;
    entity:
        'service'
        | 'service_image'
        | 'service_checkin_image'
        | 'service_appliance_instruction'
        | 'service_extra_cost'
        | 'service_faq'
        | 'service_bedroom'
        | 'service_amenity'
        | 'service_feature'
        | 'service_route'
        | 'area_guide_place';

    record_id: string;
    field: string;
    value: string;
    language: MultilingualLanguageCode;

}



export class MultilingualFieldValues {

    value: string;
    value_en: string;
    value_el: string;
    value_es: string;
    value_it: string;
    value_de: string;
    value_fr: string;
    value_ca: string;

    constructor(props?: MultilingualFieldValues) {

        this.value = props?.value || null;
        this.value_en = props?.value_en || null;
        this.value_el = props?.value_el || null;
        this.value_es = props?.value_es || null;
        this.value_it = props?.value_it || null;
        this.value_de = props?.value_de || null;
        this.value_fr = props?.value_fr || null;
        this.value_ca = props?.value_ca || null;

    }

}
