import { StorageService } from '../storage/storage.service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, Router, NavigationExtras } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private router: Router,
        private storageService: StorageService
    ) { }

    isAuthenticated(): boolean {

        if (isPlatformBrowser(this.platformId)) {

            const customer = this.storageService.getItem('customer');
            return customer?.id ? true : false;

        }
        else
            return true;
    }

    canActivate() {

        if (isPlatformBrowser(this.platformId)) {

            // Check to see if user exist in storage
            if (this.isAuthenticated())
                return true;


            if (window.location.pathname.includes('/logout')) {
                this.router.navigate(['/']);
                return false;
            }

            // If not, redirect to the login page
            const extras: NavigationExtras = {};
            if (!window.location.pathname.includes('/login')
                && !window.location.pathname.includes('/registration')
                && !window.location.pathname.includes('/email-verification')
                && !window.location.pathname.includes('/password-reset'))
                extras.queryParams = {
                    returnUrl: window.location.pathname
                };

            this.router.navigate(['/login'], extras);
            return false;

        }
        else
            return true;

    }

}
