<!-- *ngIf="page === 'home' || (!isMobile && page === 'properties')" -->
<div class="form-group mb-0" dropdown #dropdown="bs-dropdown" [insideClick]="true">

    <button dropdownToggle type="button" class="btn btn-default shadow-none bg-white dropdown-toggle text-left"
        [ngClass]="{'w-100 text-center': page !== 'properties', 'border-tourmie-primary' : hasSelection && page !== 'home'}" (click)="openGuestsFilter()">

        <span>
            {{total_guests}}
            <span *ngIf="total_guests > 1">{{ 'GENERIC.SHARED_COMPONENTS.GUESTS_FILTER.GUEST_MULTIPLE' |
                translate}}</span>
            <span *ngIf="total_guests === 1">{{ 'GENERIC.SHARED_COMPONENTS.GUESTS_FILTER.GUEST_SINGLE' |
                translate}}</span>
        </span>

    </button>

    <ul *dropdownMenu class="dropdown-menu" [ngClass]="{'w-100': page === 'home' || page === 'details', 'list-page': page === 'properties'}" role="menu">

        <li role="menuitem">

            <div class="row">

                <div class="col-12">

                    <!-- Adults -->
                    <div class="row pt-3 px-3">

                        <div class="col-6">
                            <div class="text-tourmie-darkblue mb-2">
                                <label class="h5 mb-0">
                                    {{ 'GENERIC.SHARED_COMPONENTS.GUESTS_FILTER.ADULTS_LABEL' |
                                    translate}}
                                </label>

                                <br>

                                <small class="text-muted">{{
                                    'GENERIC.SHARED_COMPONENTS.GUESTS_FILTER.ADULTS_DESCRIPTION' |
                                    translate}}</small>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="d-flex justify-content-between">

                                <button class="btn font-size-13 btn-outline-tourmie-darkblue rounded-circle" [disabled]="tempGuestFilter.adults === minAdults"
                                    (click)="decrementPersons('adults')">
                                    <i class=" fa fa-minus" aria-hidden="true"></i>
                                </button>

                                <p class="h4"><strong>{{tempGuestFilter.adults}}</strong></p>

                                <button class="btn font-size-13 btn-outline-tourmie-darkblue rounded-circle" [disabled]="tempGuestFilter.adults === maxAdults"
                                    (click)="incrementPersons('adults')">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>

                            </div>


                        </div>


                    </div>


                </div>


            </div>

        </li>

        <li class="divider dropdown-divider"></li>

        <li role="menuitem">
            <div class="row">
                <div class="col-12">
                    <!-- Children -->
                    <div class="row px-3">

                        <div class="col-6">
                            <div class="text-tourmie-darkblue mb-2">
                                <label class="h5 mb-0">
                                    {{
                                    'GENERIC.SHARED_COMPONENTS.GUESTS_FILTER.CHILDREN_LABEL' |
                                    translate}}
                                </label>
                                <br>
                                <small class="text-muted">{{
                                    'GENERIC.SHARED_COMPONENTS.GUESTS_FILTER.CHILDREN_DESCRIPTION' |
                                    translate}}</small>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="d-flex justify-content-between">

                                <button class="btn font-size-13 btn-outline-tourmie-darkblue rounded-circle"
                                    [disabled]="tempGuestFilter.children === minChildren" (click)="decrementPersons('children')">
                                    <i class="fa fa-minus" aria-hidden="true"></i>
                                </button>

                                <p class="h4">
                                    <strong>{{tempGuestFilter.children}}</strong>
                                </p>

                                <button class="btn font-size-13  btn-outline-tourmie-darkblue rounded-circle"
                                    [disabled]="tempGuestFilter.children === maxChildren" (click)="incrementPersons('children')">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>

                            </div>


                        </div>


                    </div>
                </div>
            </div>
        </li>

        <li class="divider dropdown-divider"></li>

        <li role="menuitem">
            <div class="row">
                <div class="col-12">
                    <!-- Infants -->
                    <div class="row px-3">

                        <div class="col-6">
                            <div class="text-tourmie-darkblue mb-2">
                                <label class="h5 mb-0">
                                    {{
                                    'GENERIC.SHARED_COMPONENTS.GUESTS_FILTER.INFANTS_LABEL' |
                                    translate}}
                                </label>
                                <br>
                                <small class="text-muted">{{
                                    'GENERIC.SHARED_COMPONENTS.GUESTS_FILTER.INFANTS_DESCRIPTION' |
                                    translate}}</small>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="d-flex justify-content-between">

                                <button class="btn font-size-13  btn-outline-tourmie-darkblue rounded-circle"
                                    [disabled]="tempGuestFilter.infants === minInfants" (click)="decrementPersons('infants')">
                                    <i class=" fa fa-minus" aria-hidden="true"></i>
                                </button>

                                <p class="h4"><strong>{{tempGuestFilter.infants}}</strong></p>

                                <button class="btn font-size-13 btn-outline-tourmie-darkblue rounded-circle" [disabled]="tempGuestFilter.infants === maxInfants"
                                    (click)="incrementPersons('infants')">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>

                            </div>


                        </div>




                    </div>

                </div>
            </div>
        </li>

        <li class="divider dropdown-divider"></li>


        <li role="menuitem">

            <div class="px-2 d-flex justify-content-end">

                <button type="button" class="btn btn-outline-tourmie-darkblue mr-3" (click)="clearGuestsFilter(); dropdown.hide()">
                    {{
                    'GENERIC.BUTTONS.CLEAR' |
                    translate}}
                </button>

                <button type="button" class="btn btn-outline-tourmie-darkblue" (click)="applyGuestFilter(); dropdown.hide()">
                    {{
                    'GENERIC.BUTTONS.APPLY' |
                    translate}}
                </button>

            </div>
        </li>

    </ul>

</div>