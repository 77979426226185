export class PropertyExtraCost {

    id?: number;
    service_id?: string;
    service_type?: string;
    value?: string;
    quantity?: number;
    pricing_method?: string;
    price?: number;
    provider_id?: string;
    description?: string;
    chargeable?: boolean;
    online_payment?: boolean;
    optional_to_guest?: boolean;

    // Extras (not in db)
    selected_for_booking?: boolean;
    price_per_month?: number;

    constructor(props?: PropertyExtraCost) {

        this.service_id = props?.service_id || null;
        this.service_type = props?.service_type || null;
        this.value = props?.value || null;
        this.quantity = props?.quantity || 1;
        this.pricing_method = props?.pricing_method || null;
        this.price = props?.price || 0;
        this.provider_id = props?.provider_id || null;
        this.description = props?.description || null;
        this.chargeable = props?.chargeable || true;
        this.online_payment = props?.online_payment || true;
        this.optional_to_guest = props?.optional_to_guest || true;

        this.selected_for_booking = props?.selected_for_booking || false;
        this.price_per_month = props?.price_per_month || props?.price || 0;

    }

}
