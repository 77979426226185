import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './truncate.pipe';
import { EnumeratePipe } from './enumerate.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ReplaceTourmieStayPipe } from './replace-tourmie-stay.pipe';


@NgModule({
    declarations: [
        SafeHtmlPipe,
        EnumeratePipe,
        TruncatePipe,
        ReplaceTourmieStayPipe,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        SafeHtmlPipe,
        EnumeratePipe,
        TruncatePipe,
        ReplaceTourmieStayPipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PipesModule { }
