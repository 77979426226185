import { Country } from '../app/models';

export const countries: Country[] = [
    { name: 'Afghanistan', name_el: '', name_es: 'Afganistán', name_it: 'Afghanistan', name_fr: 'Afghanistan', name_de: 'Afghanistan', name_ca: '', code: 'AF', vat_prefix: null },
    { name: 'Åland Islands', name_el: '', name_es: 'Islas Åland', name_it: 'Isole Åland', name_fr: 'Îles Åland', name_de: 'Åland-Inseln', name_ca: '', code: 'AX', vat_prefix: null },
    { name: 'Albania', name_el: '', name_es: 'Albania', name_it: 'Albania', name_fr: 'Albanie', name_de: 'Albanien', name_ca: '', code: 'AL', vat_prefix: null },
    { name: 'Algeria', name_el: '', name_es: 'Argelia', name_it: 'Algeria', name_fr: 'Algérie', name_de: 'Algerien', name_ca: '', code: 'DZ', vat_prefix: null },
    { name: 'American Samoa', name_el: '', name_es: 'Samoa Americana', name_it: 'Samoa Americane', name_fr: 'Samoa américaines', name_de: 'Amerikanisch-Samoa', name_ca: '', code: 'AS', vat_prefix: null },
    { name: 'Andorra', name_el: '', name_es: 'Andorra', name_it: 'Andorra', name_fr: 'Andorre', name_de: 'Andorra', name_ca: '', code: 'AD', vat_prefix: null },
    { name: 'Angola', name_el: '', name_es: 'Angola', name_it: 'Angola', name_fr: 'Angola', name_de: 'Angola', name_ca: '', code: 'AO', vat_prefix: null },
    { name: 'Anguilla', name_el: '', name_es: 'Anguila', name_it: 'Anguilla', name_fr: 'Anguilla', name_de: 'Anguilla', name_ca: '', code: 'AI', vat_prefix: null },
    { name: 'Antarctica', name_el: '', name_es: 'Antártida', name_it: 'Antartide', name_fr: 'Antarctique', name_de: 'Antarktis', name_ca: '', code: 'AQ', vat_prefix: null },
    { name: 'Antigua and Barbuda', name_el: '', name_es: 'Antigua y Barbuda', name_it: 'Antigua e Barbuda', name_fr: 'Antigua et Barbuda', name_de: 'Antigua und Barbuda', name_ca: '', code: 'AG', vat_prefix: null },
    { name: 'Argentina', name_el: '', name_es: 'Argentina', name_it: 'Argentina', name_fr: 'Argentine', name_de: 'Argentinien', name_ca: '', code: 'AR', vat_prefix: null },
    { name: 'Armenia', name_el: '', name_es: 'Armenia', name_it: 'Armenia', name_fr: 'Arménie', name_de: 'Armenien', name_ca: '', code: 'AM', vat_prefix: null },
    { name: 'Aruba', name_el: '', name_es: 'Aruba', name_it: 'Aruba', name_fr: 'Aruba', name_de: 'Aruba', name_ca: '', code: 'AW', vat_prefix: null },
    { name: 'Australia', name_el: '', name_es: 'Australia', name_it: 'Australia', name_fr: 'Australie', name_de: 'Australien', name_ca: '', code: 'AU', vat_prefix: null },
    { name: 'Austria', name_el: '', name_es: 'Austria', name_it: 'Austria', name_fr: 'Autriche', name_de: 'Österreich', name_ca: '', code: 'AT', vat_prefix: 'AT' },
    { name: 'Azerbaijan', name_el: '', name_es: 'Azerbaiyán', name_it: 'Azerbaigian', name_fr: 'Azerbaïdjan', name_de: 'Aserbaidschan', name_ca: '', code: 'AZ', vat_prefix: null },
    { name: 'Bahamas', name_el: '', name_es: 'Bahamas', name_it: 'Bahamas', name_fr: 'Bahamas', name_de: 'Bahamas', name_ca: '', code: 'BS', vat_prefix: null },
    { name: 'Bahrain', name_el: '', name_es: 'Bahréin', name_it: 'Bahrain', name_fr: 'Bahreïn', name_de: 'Bahrain', name_ca: '', code: 'BH', vat_prefix: null },
    { name: 'Bangladesh', name_el: '', name_es: 'Bangladesh', name_it: 'Bangladesh', name_fr: 'Bangladesh', name_de: 'Bangladesch', name_ca: '', code: 'BD', vat_prefix: null },
    { name: 'Barbados', name_el: '', name_es: 'Barbados', name_it: 'Barbados', name_fr: 'Barbade', name_de: 'Barbados', name_ca: '', code: 'BB', vat_prefix: null },
    { name: 'Belarus', name_el: '', name_es: 'Bielorrusia', name_it: 'Bielorussia', name_fr: 'Biélorussie', name_de: 'Weißrussland', name_ca: '', code: 'BY', vat_prefix: null },
    { name: 'Belgium', name_el: '', name_es: 'Bélgica', name_it: 'Belgio', name_fr: 'Belgique', name_de: 'Belgien', name_ca: '', code: 'BE', vat_prefix: 'BE' },
    { name: 'Belize', name_el: '', name_es: 'Belice', name_it: 'Belize', name_fr: 'Belize', name_de: 'Belize', name_ca: '', code: 'BZ', vat_prefix: null },
    { name: 'Benin', name_el: '', name_es: 'Benín', name_it: 'Benin', name_fr: 'Bermudes', name_de: 'Benin', name_ca: '', code: 'BJ', vat_prefix: null },
    { name: 'Bermuda', name_el: '', name_es: 'Bermudas', name_it: 'Bermuda', name_fr: 'Bermudes', name_de: 'Bermuda', name_ca: '', code: 'BM', vat_prefix: null },
    { name: 'Bhutan', name_el: '', name_es: 'Bután', name_it: 'Bhutan', name_fr: 'Bhoutan', name_de: 'Bhutan', name_ca: '', code: 'BT', vat_prefix: null },
    { name: 'Bolivia', name_el: '', name_es: 'Bolivia', name_it: 'Bolivia', name_fr: 'Bolivie', name_de: 'Bolivien', name_ca: '', code: 'BO', vat_prefix: null },
    { name: 'Bosnia and Herzegovina', name_el: '', name_es: 'Bosnia y Hercegovina', name_it: 'Bosnia ed Erzegovina', name_fr: 'Bosnie et Herzégovine', name_de: 'Bosnien und Herzegowina', name_ca: '', code: 'BA', vat_prefix: null },
    { name: 'Botswana', name_el: '', name_es: 'Botsuana', name_it: 'Botswana', name_fr: 'Botswana', name_de: 'Botswana', name_ca: '', code: 'BW', vat_prefix: null },
    { name: 'Bouvet Island', name_el: '', name_es: 'Isla Bouvet', name_it: 'Isola Bouvet', name_fr: 'Bouvet (Île)', name_de: 'Bouvetinsel', name_ca: '', code: 'BV', vat_prefix: null },
    { name: 'Brazil', name_el: '', name_es: 'Brasil', name_it: 'Brasile', name_fr: 'Brésil', name_de: 'Brasilien', name_ca: '', code: 'BR', vat_prefix: null },
    { name: 'British Indian Ocean Territory', name_el: '', name_es: 'Territorio Británico del Océano Índico', name_it: 'Territorio britannico dell\'Oceano Indiano', name_fr: 'Territoire britannique de l\'océan Indien', name_de: 'BritischesTerritorium im Indischen Ozean', name_ca: '', code: 'IO', vat_prefix: null },
    { name: 'Brunei Darussalam', name_el: '', name_es: 'Brunei Darussalam', name_it: 'Brunei Darussalam', name_fr: 'Brunei Darussalam', name_de: 'Brunei Darussalam', name_ca: '', code: 'BN', vat_prefix: null },
    { name: 'Bulgaria', name_el: '', name_es: 'Bulgaria', name_it: 'Bulgaria', name_fr: 'Bulgarie', name_de: 'Bulgarien', name_ca: '', code: 'BG', vat_prefix: 'BG' },
    { name: 'Burkina Faso', name_el: '', name_es: 'Burkina Faso', name_it: 'Burkina Faso', name_fr: 'Burkina Faso', name_de: 'Burkina Faso', name_ca: '', code: 'BF', vat_prefix: null },
    { name: 'Burundi', name_el: '', name_es: 'Burundi', name_it: 'Burundi', name_fr: 'Burundi', name_de: 'Burundi', name_ca: '', code: 'BI', vat_prefix: null },
    { name: 'Cambodia', name_el: '', name_es: 'Camboya', name_it: 'Cambogia', name_fr: 'Cambodge', name_de: 'Kambodscha', name_ca: '', code: 'KH', vat_prefix: null },
    { name: 'Cameroon', name_el: '', name_es: 'Camerún', name_it: 'Camerun', name_fr: 'Cameroun', name_de: 'Kamerun', name_ca: '', code: 'CM', vat_prefix: null },
    { name: 'Canada', name_el: '', name_es: 'Canadá', name_it: 'Canada', name_fr: 'Canada', name_de: 'Kanada', name_ca: '', code: 'CA', vat_prefix: null },
    { name: 'Cape Verde', name_el: '', name_es: 'Cabo Verde', name_it: 'Capo Verde', name_fr: 'Cap Vert', name_de: 'Kap Verde', name_ca: '', code: 'CV', vat_prefix: null },
    { name: 'Cayman Islands', name_el: '', name_es: 'Islas Caimán', name_it: 'Isole Cayman', name_fr: 'Îles Caïmans', name_de: 'Cayman-Inseln', name_ca: '', code: 'KY', vat_prefix: null },
    { name: 'Central African Republic', name_el: '', name_es: 'República Centroafricana', name_it: 'Repubblica Centrafricana', name_fr: 'République Centrafricaine', name_de: 'Zentralafrikanische Republik', name_ca: '', code: 'CF', vat_prefix: null },
    { name: 'Chad', name_el: '', name_es: 'Chad', name_it: 'Ciad', name_fr: 'Tchad', name_de: 'Tschad', name_ca: '', code: 'TD', vat_prefix: null },
    { name: 'Chile', name_el: '', name_es: 'Chile', name_it: 'Cile', name_fr: 'Chili', name_de: 'Chile', name_ca: '', code: 'CL', vat_prefix: null },
    { name: 'China', name_el: '', name_es: 'China', name_it: 'Cina', name_fr: 'Chine', name_de: 'China', name_ca: '', code: 'CN', vat_prefix: null },
    { name: 'Christmas Island', name_el: '', name_es: 'Isla de Navidad', name_it: 'Isola di Natale', name_fr: 'Île Christmas', name_de: 'Weihnachtsinsel', name_ca: '', code: 'CX', vat_prefix: null },
    { name: 'Cocos (Keeling) Islands', name_el: '', name_es: 'Islas Cocos (Keeling)', name_it: 'Isole Cocos (Keeling)', name_fr: 'Îles Cocos (Keeling)', name_de: 'Kokosinseln (Keeling)', name_ca: '', code: 'CC', vat_prefix: null },
    { name: 'Colombia', name_el: '', name_es: 'Colombia', name_it: 'Colombia', name_fr: 'Colombie', name_de: 'Kolumbien', name_ca: '', code: 'CO', vat_prefix: null },
    { name: 'Comoros', name_el: '', name_es: 'Comoras', name_it: 'Comore', name_fr: 'Comores', name_de: 'Komoren', name_ca: '', code: 'KM', vat_prefix: null },
    { name: 'Congo', name_el: '', name_es: 'Congo', name_it: 'Congo', name_fr: 'Congo (République démocratique du)', name_de: 'Kongo', name_ca: '', code: 'CG', vat_prefix: null },
    { name: 'Congo, The Democratic Republic of the', name_el: '', name_es: 'Congo, República Democrática del', name_it: 'Congo, Repubblica Democratica del', name_fr: 'Congo, République Démocratique du', name_de: 'Kongo, die DemokratischeRepublik', name_ca: '', code: 'CD', vat_prefix: null },
    { name: 'Cook Islands', name_el: '', name_es: 'Islas Cook', name_it: 'Isole Cook', name_fr: 'Cook (Îles)', name_de: 'Cook-Inseln', name_ca: '', code: 'CK', vat_prefix: null },
    { name: 'Costa Rica', name_el: '', name_es: 'Costa de Marfil', name_it: 'Costa Rica', name_fr: 'Costa Rica', name_de: 'Costa Rica', name_ca: '', code: 'CR', vat_prefix: null },
    { name: 'Cote D\'Ivoire', name_el: '', name_es: 'Costa de Marfil', name_it: 'Costa d\'Avorio', name_fr: 'Côte d\'Ivoire', name_de: 'Elfenbeinküste', name_ca: '', code: 'CI', vat_prefix: null },
    { name: 'Croatia', name_el: '', name_es: 'Croacia', name_it: 'Croazia, Repubblica Democratica del Congo', name_fr: 'Croatie', name_de: 'Kroatien', name_ca: '', code: 'HR', vat_prefix: 'HR' },
    { name: 'Cuba', name_el: '', name_es: 'Cuba', name_it: 'Cuba', name_fr: 'Cuba', name_de: 'Kuba', name_ca: '', code: 'CU', vat_prefix: null },
    { name: 'Cyprus', name_el: '', name_es: 'Chipre', name_it: 'Cipro', name_fr: 'Chypre', name_de: 'Zypern', name_ca: '', code: 'CY', vat_prefix: 'CY' },
    { name: 'Czech Republic', name_el: '', name_es: 'República Checa', name_it: 'Repubblica Ceca', name_fr: 'République Tchèque', name_de: 'Tschechische Republik', name_ca: '', code: 'CZ', vat_prefix: 'CZ' },
    { name: 'Denmark', name_el: '', name_es: 'Dinamarca', name_it: 'Danimarca', name_fr: 'Danemark', name_de: 'Dänemark', name_ca: '', code: 'DK', vat_prefix: 'DK' },
    { name: 'Djibouti', name_el: '', name_es: 'Yibuti', name_it: 'Gibuti', name_fr: 'Djibouti', name_de: 'Dschibuti', name_ca: '', code: 'DJ', vat_prefix: null },
    { name: 'Dominica', name_el: '', name_es: 'Dominica', name_it: 'Dominica', name_fr: 'Dominique', name_de: 'Dominica', name_ca: '', code: 'DM', vat_prefix: null },
    { name: 'Dominican Republic', name_el: '', name_es: 'República Dominicana', name_it: 'Repubblica Dominicana', name_fr: 'République dominicaine', name_de: 'Dominikanische Republik', name_ca: '', code: 'DO', vat_prefix: null },
    { name: 'Ecuador', name_el: '', name_es: 'Ecuador', name_it: 'Ecuador', name_fr: 'Equateur (République dominicaine)', name_de: 'Ecuador', name_ca: '', code: 'EC', vat_prefix: null },
    { name: 'Egypt', name_el: '', name_es: 'Egipto', name_it: 'Egitto', name_fr: 'Égypte', name_de: 'Ägypten', name_ca: '', code: 'EG', vat_prefix: null },
    { name: 'El Salvador', name_el: '', name_es: 'El Salvador', name_it: 'El Salvador', name_fr: 'El Salvador', name_de: 'El Salvador', name_ca: '', code: 'SV', vat_prefix: null },
    { name: 'Equatorial Guinea', name_el: '', name_es: 'Guinea Ecuatorial', name_it: 'Guinea Equatoriale', name_fr: 'Guinée équatoriale', name_de: 'Äquatorialguinea', name_ca: '', code: 'GQ', vat_prefix: null },
    { name: 'Eritrea', name_el: '', name_es: 'Eritrea', name_it: 'Eritrea', name_fr: 'Erythrée', name_de: 'Eritrea', name_ca: '', code: 'ER', vat_prefix: null },
    { name: 'Estonia', name_el: '', name_es: 'Estonia', name_it: 'Estonia', name_fr: 'Estonie', name_de: 'Estland', name_ca: '', code: 'EE', vat_prefix: 'EE' },
    { name: 'Ethiopia', name_el: '', name_es: 'Etiopía', name_it: 'Etiopia', name_fr: 'Éthiopie', name_de: 'Äthiopien', name_ca: '', code: 'ET', vat_prefix: null },
    { name: 'Falkland Islands (Malvinas)', name_el: '', name_es: 'Islas Malvinas (Falkland Islands)', name_it: 'Isole Falkland (Malvinas)', name_fr: 'Îles Falkland (Malvinas)', name_de: 'Falklandinseln (Malwinen)', name_ca: '', code: 'FK', vat_prefix: null },
    { name: 'Faroe Islands', name_el: '', name_es: 'Islas Feroe', name_it: 'Isole Faroe', name_fr: 'Îles Féroé', name_de: 'Färöer Inseln', name_ca: '', code: 'FO', vat_prefix: null },
    { name: 'Fiji', name_el: '', name_es: 'Fiyi', name_it: 'Figi', name_fr: 'Fiji', name_de: 'Fidschi', name_ca: '', code: 'FJ', vat_prefix: null },
    { name: 'Finland', name_el: '', name_es: 'Finlandia', name_it: 'Finlandia', name_fr: 'Finlande', name_de: 'Finnland', name_ca: '', code: 'FI', vat_prefix: 'FI' },
    { name: 'France', name_el: '', name_es: 'Francia', name_it: 'Francia', name_fr: 'France', name_de: 'Frankreich', name_ca: '', code: 'FR', vat_prefix: 'FR' },
    { name: 'French Guiana', name_el: '', name_es: 'Guayana Francesa', name_it: 'Guiana Francese', name_fr: 'Guyane française', name_de: 'Französisch-Guayana', name_ca: '', code: 'GF', vat_prefix: null },
    { name: 'French Polynesia', name_el: '', name_es: 'Polinesia Francesa', name_it: 'Polinesia Francese', name_fr: 'Polynésie française', name_de: 'Französisch-Polynesien', name_ca: '', code: 'PF', vat_prefix: null },
    { name: 'French Southern Territories', name_el: '', name_es: 'Territorios Australes Franceses', name_it: 'Territori Francesi del Sud', name_fr: 'Terres australes françaises', name_de: 'Französische Südterritorien', name_ca: '', code: 'TF', vat_prefix: null },
    { name: 'Gabon', name_el: '', name_es: 'Gabón', name_it: 'Gabon', name_fr: 'Gabon', name_de: 'Gabun', name_ca: '', code: 'GA', vat_prefix: null },
    { name: 'Gambia', name_el: '', name_es: 'Gambia', name_it: 'Gambia', name_fr: 'Gambie', name_de: 'Gambia', name_ca: '', code: 'GM', vat_prefix: null },
    { name: 'Georgia', name_el: '', name_es: 'Georgia', name_it: 'Georgia', name_fr: 'Géorgie', name_de: 'Georgien', name_ca: '', code: 'GE', vat_prefix: null },
    { name: 'Germany', name_el: '', name_es: 'Alemania', name_it: 'Germania', name_fr: 'Allemagne', name_de: 'Deutschland', name_ca: '', code: 'DE', vat_prefix: 'DE' },
    { name: 'Ghana', name_el: '', name_es: 'Ghana', name_it: 'Ghana', name_fr: 'Ghana', name_de: 'Ghana', name_ca: '', code: 'GH', vat_prefix: null },
    { name: 'Gibraltar', name_el: '', name_es: 'Gibraltar', name_it: 'Gibilterra', name_fr: 'Gibraltar', name_de: 'Gibraltar', name_ca: '', code: 'GI', vat_prefix: null },
    { name: 'Greece', name_el: '', name_es: 'Grecia', name_it: 'Grecia', name_fr: 'Grèce', name_de: 'Griechenland', name_ca: '', code: 'GR', vat_prefix: 'EL' },
    { name: 'Greenland', name_el: '', name_es: 'Groenlandia', name_it: 'Groenlandia', name_fr: 'Groenland', name_de: 'Grönland', name_ca: '', code: 'GL', vat_prefix: null },
    { name: 'Grenada', name_el: '', name_es: 'Granada', name_it: 'Grenada', name_fr: 'Grenade', name_de: 'Grenada', name_ca: '', code: 'GD', vat_prefix: null },
    { name: 'Guadeloupe', name_el: '', name_es: 'Guadalupe', name_it: 'Guadalupa', name_fr: 'Guadeloupe', name_de: 'Guadeloupe', name_ca: '', code: 'GP', vat_prefix: null },
    { name: 'Guam', name_el: '', name_es: 'Guam', name_it: 'Guam', name_fr: 'Guam', name_de: 'Guam', name_ca: '', code: 'GU', vat_prefix: null },
    { name: 'Guatemala', name_el: '', name_es: 'Guatemala', name_it: 'Guatemala', name_fr: 'Guatemala', name_de: 'Guatemala', name_ca: '', code: 'GT', vat_prefix: null },
    { name: 'Guernsey', name_el: '', name_es: 'Guernesey', name_it: 'Guernsey', name_fr: 'Guernesey', name_de: 'Guernsey', name_ca: '', code: 'GG', vat_prefix: null },
    { name: 'Guinea', name_el: '', name_es: 'Guinea', name_it: 'Guinea', name_fr: 'Guinée', name_de: 'Guinea', name_ca: '', code: 'GN', vat_prefix: null },
    { name: 'Guinea-Bissau', name_el: '', name_es: 'Guinea-Bissau', name_it: 'Guinea-Bissau', name_fr: 'Guinée-Bissau', name_de: 'Guinea-Bissau', name_ca: '', code: 'GW', vat_prefix: null },
    { name: 'Guyana', name_el: '', name_es: 'Guyana', name_it: 'Guyana', name_fr: 'Guyana', name_de: 'Guyana', name_ca: '', code: 'GY', vat_prefix: null },
    { name: 'Haiti', name_el: '', name_es: 'Haití', name_it: 'Haiti', name_fr: 'Haïti', name_de: 'Haiti', name_ca: '', code: 'HT', vat_prefix: null },
    { name: 'Heard Island and Mcdonald Islands', name_el: '', name_es: 'Islas Heard y Mcdonald', name_it: 'Isola Heard e Isole Mcdonald', name_fr: 'Heard Island et Mcdonald Islands', name_de: 'Heard-Insel und McDonald-Inseln', name_ca: '', code: 'HM', vat_prefix: null },
    { name: 'Holy See (Vatican City State)', name_el: '', name_es: 'Santa Sede (Estado de la Ciudad del Vaticano)', name_it: 'Santa Sede (Stato della Città del Vaticano)', name_fr: 'Saint-Siège (État de la Cité du Vatican)', name_de: 'Heiliger Stuhl (Staat Vatikanstadt)', name_ca: '', code: 'VA', vat_prefix: null },
    { name: 'Honduras', name_el: '', name_es: 'Honduras', name_it: 'Honduras', name_fr: 'Honduras', name_de: 'Honduras', name_ca: '', code: 'HN', vat_prefix: null },
    { name: 'Hong Kong', name_el: '', name_es: 'Hong Kong', name_it: 'Hong Kong', name_fr: 'Hong Kong', name_de: 'Hongkong', name_ca: '', code: 'HK', vat_prefix: null },
    { name: 'Hungary', name_el: '', name_es: 'Hungría', name_it: 'Ungheria', name_fr: 'Hongrie', name_de: 'Ungarn', name_ca: '', code: 'HU', vat_prefix: 'HU' },
    { name: 'Iceland', name_el: '', name_es: 'Islandia', name_it: 'Islanda', name_fr: 'Islande', name_de: 'Island', name_ca: '', code: 'IS', vat_prefix: null },
    { name: 'India', name_el: '', name_es: 'India', name_it: 'India', name_fr: 'Inde', name_de: 'Indien', name_ca: '', code: 'IN', vat_prefix: null },
    { name: 'Indonesia', name_el: '', name_es: 'Indonesia', name_it: 'Indonesia', name_fr: 'Indonésie', name_de: 'Indonesien', name_ca: '', code: 'ID', vat_prefix: null },
    { name: 'Iran, Islamic Republic Of', name_el: '', name_es: 'Irán, República Islámica del', name_it: 'Iran, Repubblica islamica di', name_fr: 'Iran, République islamique d', name_de: 'Iran, Islamische Republik', name_ca: '', code: 'IR', vat_prefix: null },
    { name: 'Iraq', name_el: '', name_es: 'Iraq', name_it: 'Iraq', name_fr: 'Irak', name_de: 'Irak', name_ca: '', code: 'IQ', vat_prefix: null },
    { name: 'Ireland', name_el: '', name_es: 'Irlanda', name_it: 'Irlanda', name_fr: 'Irlande', name_de: 'Irland', name_ca: '', code: 'IE', vat_prefix: 'IE' },
    { name: 'Isle of Man', name_el: '', name_es: 'Isla de Man', name_it: 'Isola di Man', name_fr: 'Île de Man', name_de: 'Isle of Man', name_ca: '', code: 'IM', vat_prefix: null },
    { name: 'Israel', name_el: '', name_es: 'Israel', name_it: 'Israele', name_fr: 'Israël', name_de: 'Israel', name_ca: '', code: 'IL', vat_prefix: null },
    { name: 'Italy', name_el: '', name_es: 'Italia', name_it: 'Italia', name_fr: 'Italie', name_de: 'Italien', name_ca: '', code: 'IT', vat_prefix: 'IT' },
    { name: 'Jamaica', name_el: '', name_es: 'Jamaica', name_it: 'Giamaica', name_fr: 'Jamaïque', name_de: 'Jamaika', name_ca: '', code: 'JM', vat_prefix: null },
    { name: 'Japan', name_el: '', name_es: 'Japón', name_it: 'Giappone', name_fr: 'Japon', name_de: 'Japan', name_ca: '', code: 'JP', vat_prefix: null },
    { name: 'Jersey', name_el: '', name_es: 'Jersey', name_it: 'Jersey', name_fr: 'Jersey', name_de: 'Jersey', name_ca: '', code: 'JE', vat_prefix: null },
    { name: 'Jordan', name_el: '', name_es: 'Jordania', name_it: 'Giordania', name_fr: 'Jordanie', name_de: 'Jordanien', name_ca: '', code: 'JO', vat_prefix: null },
    { name: 'Kazakhstan', name_el: '', name_es: 'Kazajstán', name_it: 'Kazakistan', name_fr: 'Kazakhstan', name_de: 'Kasachstan', name_ca: '', code: 'KZ', vat_prefix: null },
    { name: 'Kenya', name_el: '', name_es: 'Kenia', name_it: 'Kenya', name_fr: 'Kenya', name_de: 'Kenia', name_ca: '', code: 'KE', vat_prefix: null },
    { name: 'Kiribati', name_el: '', name_es: 'Kiribati', name_it: 'Kiribati', name_fr: 'Kiribati', name_de: 'Kiribati', name_ca: '', code: 'KI', vat_prefix: null },
    { name: 'Korea, Democratic People\'S Republic of', name_el: '', name_es: 'Corea, República Popular Democrática de', name_it: 'Corea, Repubblica Democratica Popolare di', name_fr: 'Corée, République populaire démocratique de', name_de: 'Korea, Demokratische Volksrepublik', name_ca: '', code: 'KP', vat_prefix: null },
    { name: 'Korea, Republic of', name_el: '', name_es: 'Corea, República de', name_it: 'Corea, Repubblica di', name_fr: 'Corée, République de', name_de: 'Korea, Republik', name_ca: '', code: 'KR', vat_prefix: null },
    { name: 'Kosovo', name_el: '', name_es: 'Kosovo', name_it: 'Kosovo', name_fr: 'Kosovo', name_de: 'Kosovo', name_ca: '', code: 'XK', vat_prefix: null },
    { name: 'Kuwait', name_el: '', name_es: 'Kuwait', name_it: 'Kuwait', name_fr: 'Koweït', name_de: 'Kuwait', name_ca: '', code: 'KW', vat_prefix: null },
    { name: 'Kyrgyzstan', name_el: '', name_es: 'Kirguistán', name_it: 'Kirghizistan', name_fr: 'Kyrgyzstan', name_de: 'Kirgisistan', name_ca: '', code: 'KG', vat_prefix: null },
    { name: 'Lao People\'S Democratic Republic', name_el: '', name_es: 'Laos, República Democrática Popular', name_it: 'Repubblica Democratica Popolare del Laos', name_fr: 'République démocratique populaire lao', name_de: 'DemokratischeVolksrepublik Laos', name_ca: '', code: 'LA', vat_prefix: null },
    { name: 'Latvia', name_el: '', name_es: 'Letonia', name_it: 'Lettonia', name_fr: 'Lettonie', name_de: 'Lettland', name_ca: '', code: 'LV', vat_prefix: 'LV' },
    { name: 'Lebanon', name_el: '', name_es: 'Líbano', name_it: 'Libano', name_fr: 'Liban', name_de: 'Libanon', name_ca: '', code: 'LB', vat_prefix: null },
    { name: 'Lesotho', name_el: '', name_es: 'Lesotho', name_it: 'Lesotho', name_fr: 'Lesotho', name_de: 'Lesotho', name_ca: '', code: 'LS', vat_prefix: null },
    { name: 'Liberia', name_el: '', name_es: 'Liberia', name_it: 'Liberia', name_fr: 'Libéria', name_de: 'Liberia', name_ca: '', code: 'LR', vat_prefix: null },
    { name: 'Libyan Arab Jamahiriya', name_el: '', name_es: 'Jamahiriya Árabe Libia', name_it: 'Giamahiria Araba Libica', name_fr: 'Jamahiriya arabe libyenne', name_de: 'Libysch-Arabische Dschamahirija', name_ca: '', code: 'LY', vat_prefix: null },
    { name: 'Liechtenstein', name_el: '', name_es: 'Liechtenstein', name_it: 'Liechtenstein', name_fr: 'Liechtenstein', name_de: 'Liechtenstein', name_ca: '', code: 'LI', vat_prefix: null },
    { name: 'Lithuania', name_el: '', name_es: 'Lituania', name_it: 'Lituania', name_fr: 'Lituanie', name_de: 'Litauen', name_ca: '', code: 'LT', vat_prefix: 'LT' },
    { name: 'Luxembourg', name_el: '', name_es: 'Luxemburgo', name_it: 'Lussemburgo', name_fr: 'Luxembourg (en anglais)', name_de: 'Luxemburg', name_ca: '', code: 'LU', vat_prefix: 'LU' },
    { name: 'Macao', name_el: '', name_es: 'Macao', name_it: 'Macao', name_fr: 'Macao', name_de: 'Macau', name_ca: '', code: 'MO', vat_prefix: null },
    { name: 'Madagascar', name_el: '', name_es: 'Madagascar', name_it: 'Madagascar', name_fr: 'Madagascar', name_de: 'Madagaskar', name_ca: '', code: 'MG', vat_prefix: null },
    { name: 'Malawi', name_el: '', name_es: 'Malaui', name_it: 'Malawi', name_fr: 'Malawi', name_de: 'Malawi', name_ca: '', code: 'MW', vat_prefix: null },
    { name: 'Malaysia', name_el: '', name_es: 'Malasia', name_it: 'Malesia', name_fr: 'Malaisie', name_de: 'Malaysia', name_ca: '', code: 'MY', vat_prefix: null },
    { name: 'Maldives', name_el: '', name_es: 'Maldivas', name_it: 'Maldive', name_fr: 'Maldives', name_de: 'Malediven', name_ca: '', code: 'MV', vat_prefix: null },
    { name: 'Mali', name_el: '', name_es: 'Malí', name_it: 'Mali', name_fr: 'Mali', name_de: 'Mali', name_ca: '', code: 'ML', vat_prefix: null },
    { name: 'Malta', name_el: '', name_es: 'Malta', name_it: 'Malta', name_fr: 'Malte', name_de: 'Malta', name_ca: '', code: 'MT', vat_prefix: 'MT' },
    { name: 'Marshall Islands', name_el: '', name_es: 'Islas Marshall', name_it: 'Isole Marshall', name_fr: 'Marshall (Îles)', name_de: 'Marshall-Inseln', name_ca: '', code: 'MH', vat_prefix: null },
    { name: 'Martinique', name_el: '', name_es: 'Martinica', name_it: 'Martinica', name_fr: 'Martinique', name_de: 'Martinique', name_ca: '', code: 'MQ', vat_prefix: null },
    { name: 'Mauritania', name_el: '', name_es: 'Mauritania', name_it: 'Mauritania', name_fr: 'Mauritanie', name_de: 'Mauretanien', name_ca: '', code: 'MR', vat_prefix: null },
    { name: 'Mauritius', name_el: '', name_es: 'Mauricio', name_it: 'Mauritius', name_fr: 'Mauritanie', name_de: 'Mauretanien', name_ca: '', code: 'MU', vat_prefix: null },
    { name: 'Mayotte', name_el: '', name_es: 'Mayotte', name_it: 'Mayotte', name_fr: 'Mayotte', name_de: 'Mayotte', name_ca: '', code: 'YT', vat_prefix: null },
    { name: 'Mexico', name_el: '', name_es: 'México', name_it: 'Messico', name_fr: 'Mexique', name_de: 'Mexiko', name_ca: '', code: 'MX', vat_prefix: null },
    { name: 'Micronesia, Federated States of', name_el: '', name_es: 'Micronesia, Estados Federados de', name_it: 'Micronesia, Stati Federati di', name_fr: 'Micronésie, États fédérés de', name_de: 'Mikronesien, Föderierte Staaten von', name_ca: '', code: 'FM', vat_prefix: null },
    { name: 'Moldova, Republic of', name_el: '', name_es: 'Moldavia, República de', name_it: 'Moldavia, Repubblica di', name_fr: 'Moldavie, République de', name_de: 'Moldawien, Republik', name_ca: '', code: 'MD', vat_prefix: null },
    { name: 'Monaco', name_el: '', name_es: 'Mónaco', name_it: 'Monaco', name_fr: 'Monaco', name_de: 'Monaco', name_ca: '', code: 'MC', vat_prefix: null },
    { name: 'Mongolia', name_el: '', name_es: 'Mongolia', name_it: 'Mongolia', name_fr: 'Mongolie', name_de: 'Mongolei', name_ca: '', code: 'MN', vat_prefix: null },
    { name: 'Montenegro', name_el: '', name_es: 'Montenegro', name_it: 'Montenegro', name_fr: 'Monténégro', name_de: 'Montenegro', name_ca: '', code: 'ME', vat_prefix: null },
    { name: 'Montserrat', name_el: '', name_es: 'Montserrat', name_it: 'Montserrat', name_fr: 'Montserrat', name_de: 'Montserrat', name_ca: '', code: 'MS', vat_prefix: null },
    { name: 'Morocco', name_el: '', name_es: 'Marruecos', name_it: 'Marocco', name_fr: 'Maroc', name_de: 'Marokko', name_ca: '', code: 'MA', vat_prefix: null },
    { name: 'Mozambique', name_el: '', name_es: 'Mozambique', name_it: 'Mozambico', name_fr: 'Mozambique', name_de: 'Mosambik', name_ca: '', code: 'MZ', vat_prefix: null },
    { name: 'Myanmar', name_el: '', name_es: 'Myanmar', name_it: 'Myanmar', name_fr: 'Myanmar', name_de: 'Myanmar', name_ca: '', code: 'MM', vat_prefix: null },
    { name: 'Namibia', name_el: '', name_es: 'Namibia', name_it: 'Namibia', name_fr: 'Namibie', name_de: 'Namibia', name_ca: '', code: 'NA', vat_prefix: null },
    { name: 'Nauru', name_el: '', name_es: 'Nauru', name_it: 'Nauru', name_fr: 'Nauru', name_de: 'Nauru', name_ca: '', code: 'NR', vat_prefix: null },
    { name: 'Nepal', name_el: '', name_es: 'Nepal', name_it: 'Nepal', name_fr: 'Népal', name_de: 'Nepal', name_ca: '', code: 'NP', vat_prefix: null },
    { name: 'Netherlands', name_el: '', name_es: 'Países Bajos', name_it: 'Paesi Bassi', name_fr: 'Pays-Bas', name_de: 'Niederlande', name_ca: '', code: 'NL', vat_prefix: 'NL' },
    { name: 'Netherlands Antilles', name_el: '', name_es: 'Antillas Neerlandesas', name_it: 'Antille Olandesi', name_fr: 'Antilles néerlandaises', name_de: 'Niederländische Antillen', name_ca: '', code: 'AN', vat_prefix: null },
    { name: 'New Caledonia', name_el: '', name_es: 'Nueva Caledonia', name_it: 'Nuova Caledonia', name_fr: 'Nouvelle Calédonie', name_de: 'Neukaledonien', name_ca: '', code: 'NC', vat_prefix: null },
    { name: 'New Zealand', name_el: '', name_es: 'Nueva Caledonia', name_it: 'Nuova Zelanda', name_fr: 'Nouvelle Zélande', name_de: 'neuseeland', name_ca: '', code: 'NZ', vat_prefix: null },
    { name: 'Nicaragua', name_el: '', name_es: 'Nicaragua', name_it: 'Nicaragua', name_fr: 'Nicaragua', name_de: 'Nicaragua', name_ca: '', code: 'NI', vat_prefix: null },
    { name: 'Niger', name_el: '', name_es: 'Níger', name_it: 'Niger', name_fr: 'Nouvelle-Zélande', name_de: 'Niger', name_ca: '', code: 'NE', vat_prefix: null },
    { name: 'Nigeria', name_el: '', name_es: 'Nigeria', name_it: 'Nigeria', name_fr: 'Nigéria', name_de: 'Nigeria', name_ca: '', code: 'NG', vat_prefix: null },
    { name: 'Niue', name_el: '', name_es: 'Niue', name_it: 'Niue', name_fr: 'Niue', name_de: 'Niue', name_ca: '', code: 'NU', vat_prefix: null },
    { name: 'Norfolk Island', name_el: '', name_es: 'Isla Norfolk', name_it: 'Isola di Norfolk', name_fr: 'Île Norfolk', name_de: 'Norfolkinsel', name_ca: '', code: 'NF', vat_prefix: null },
    { name: 'North Macedonia', name_el: '', name_es: 'Macedonia del Norte', name_it: 'Macedonia del Nord', name_fr: 'Macédoine du Nord', name_de: 'Nord-Mazedonien', name_ca: '', code: 'MK', vat_prefix: null },
    { name: 'Northern Mariana Islands', name_el: '', name_es: 'Islas Marianas del Norte', name_it: 'Isole Marianne Settentrionali', name_fr: 'Mariannes du Nord (Îles)', name_de: 'Nördliche Marianen', name_ca: '', code: 'MP', vat_prefix: null },
    { name: 'Norway', name_el: '', name_es: 'Noruega', name_it: 'Norvegia', name_fr: 'Norvège', name_de: 'Norwegen', name_ca: '', code: 'NO', vat_prefix: null },
    { name: 'Oman', name_el: '', name_es: 'Omán', name_it: 'Oman', name_fr: 'Oman', name_de: 'Oman', name_ca: '', code: 'OM', vat_prefix: null },
    { name: 'Pakistan', name_el: '', name_es: 'Pakistán', name_it: 'Pakistan', name_fr: 'Pakistan (en anglais)', name_de: 'Pakistan', name_ca: '', code: 'PK', vat_prefix: null },
    { name: 'Palau', name_el: '', name_es: 'Palau', name_it: 'Palau', name_fr: 'Palau', name_de: 'Palau', name_ca: '', code: 'PW', vat_prefix: null },
    { name: 'Palestinian Territory, Occupied', name_el: '', name_es: 'Territorio Palestino Ocupado', name_it: 'Territorio palestinese occupato', name_fr: 'Territoire palestinien occupé', name_de: 'Palästinensisches Territorium, besetztesGebiet', name_ca: '', code: 'PS', vat_prefix: null },
    { name: 'Panama', name_el: '', name_es: 'Panamá', name_it: 'Panama', name_fr: 'Panama', name_de: 'Panama', name_ca: '', code: 'PA', vat_prefix: null },
    { name: 'Papua New Guinea', name_el: '', name_es: 'Papúa Nueva Guinea', name_it: 'Papua Nuova Guinea', name_fr: 'Papouasie-Nouvelle-Guinée', name_de: 'Papua-Neuguinea', name_ca: '', code: 'PG', vat_prefix: null },
    { name: 'Paraguay', name_el: '', name_es: 'Paraguay', name_it: 'Paraguay', name_fr: 'Paraguay', name_de: 'Paraguay', name_ca: '', code: 'PY', vat_prefix: null },
    { name: 'Peru', name_el: '', name_es: 'Perú', name_it: 'Perù', name_fr: 'Pérou', name_de: 'Peru', name_ca: '', code: 'PE', vat_prefix: null },
    { name: 'Philippines', name_el: '', name_es: 'Filipinas', name_it: 'Filippine', name_fr: 'Philippines', name_de: 'Philippinen', name_ca: '', code: 'PH', vat_prefix: null },
    { name: 'Pitcairn', name_el: '', name_es: 'Pitcairn', name_it: 'Pitcairn', name_fr: 'Pitcairn', name_de: 'Pitcairn', name_ca: '', code: 'PN', vat_prefix: null },
    { name: 'Poland', name_el: '', name_es: 'Polonia', name_it: 'Polonia', name_fr: 'Pologne', name_de: 'Polen', name_ca: '', code: 'PL', vat_prefix: 'PL' },
    { name: 'Portugal', name_el: '', name_es: 'Portugal', name_it: 'Portogallo', name_fr: 'Portugal', name_de: 'Portugal', name_ca: '', code: 'PT', vat_prefix: 'PT' },
    { name: 'Puerto Rico', name_el: '', name_es: 'Puerto Rico', name_it: 'Porto Rico', name_fr: 'Puerto Rico', name_de: 'Puerto Rico', name_ca: '', code: 'PR', vat_prefix: null },
    { name: 'Qatar', name_el: '', name_es: 'Qatar', name_it: 'Qatar', name_fr: 'Qatar', name_de: 'Katar', name_ca: '', code: 'QA', vat_prefix: null },
    { name: 'Reunion', name_el: '', name_es: 'Reunión', name_it: 'Riunione', name_fr: 'Réunion', name_de: 'Wiedervereinigung', name_ca: '', code: 'RE', vat_prefix: null },
    { name: 'Romania', name_el: '', name_es: 'Rumanía', name_it: 'Romania', name_fr: 'Roumanie', name_de: 'Rumänien', name_ca: '', code: 'RO', vat_prefix: 'RO' },
    { name: 'Russian Federation', name_el: '', name_es: 'Rumanía', name_it: 'Federazione Russa', name_fr: 'Fédération de Russie', name_de: 'Russische Föderation', name_ca: '', code: 'RU', vat_prefix: null },
    { name: 'Rwanda', name_el: '', name_es: 'Ruanda', name_it: 'Ruanda', name_fr: 'Rwanda', name_de: 'Ruanda', name_ca: '', code: 'RW', vat_prefix: null },
    { name: 'Saint Helena', name_el: '', name_es: 'Santa Helena', name_it: 'Sant\'Elena', name_fr: 'Sainte-Hélène', name_de: 'St. Helena', name_ca: '', code: 'SH', vat_prefix: null },
    { name: 'Saint Kitts and Nevis', name_el: '', name_es: 'San Cristóbal y Nieves', name_it: 'Saint Kitts e Nevis', name_fr: 'Saint Kitts et Nevis', name_de: 'St. Kitts und Nevis', name_ca: '', code: 'KN', vat_prefix: null },
    { name: 'Saint Lucia', name_el: '', name_es: 'Santa Lucía', name_it: 'Santa Lucia', name_fr: 'Sainte-Lucie', name_de: 'St. Lucia', name_ca: '', code: 'LC', vat_prefix: null },
    { name: 'Saint Pierre and Miquelon', name_el: '', name_es: 'San Pedro y Miquelón', name_it: 'Saint-Pierre e Miquelon', name_fr: 'Saint Pierre et Miquelon', name_de: 'St. Pierre und Miquelon', name_ca: '', code: 'PM', vat_prefix: null },
    { name: 'Saint Vincent and the Grenadines', name_el: '', name_es: 'San Vicente y las Granadinas', name_it: 'Saint Vincent e Grenadine', name_fr: 'Saint Vincent et les Grenadines', name_de: 'St. Vincent und die Grenadinen', name_ca: '', code: 'VC', vat_prefix: null },
    { name: 'Samoa', name_el: '', name_es: 'Samoa', name_it: 'Samoa', name_fr: 'Samoa', name_de: 'Samoa', name_ca: '', code: 'WS', vat_prefix: null },
    { name: 'San Marino', name_el: '', name_es: 'San Marino', name_it: 'San Marino', name_fr: 'Saint-Marin', name_de: 'San Marino', name_ca: '', code: 'SM', vat_prefix: null },
    { name: 'Sao Tome and Principe', name_el: '', name_es: 'Santo Tomé y Príncipe', name_it: 'São Tomé e Principe', name_fr: 'Sao Tomé et Principe', name_de: 'Sao Tome und Principe', name_ca: '', code: 'ST', vat_prefix: null },
    { name: 'Saudi Arabia', name_el: '', name_es: 'Santo Tomé y Príncipe', name_it: 'Arabia Saudita', name_fr: 'Arabie Saoudite', name_de: 'Saudi-Arabien', name_ca: '', code: 'SA', vat_prefix: null },
    { name: 'Senegal', name_el: '', name_es: 'Senegal', name_it: 'Senegal', name_fr: 'Sénégal', name_de: 'Senegal', name_ca: '', code: 'SN', vat_prefix: null },
    { name: 'Serbia', name_el: '', name_es: 'Serbia', name_it: 'Serbia', name_fr: 'Serbie', name_de: 'Serbien', name_ca: '', code: 'RS', vat_prefix: null },
    { name: 'Seychelles', name_el: '', name_es: 'Seychelles', name_it: 'Seychelles', name_fr: 'Seychelles', name_de: 'Seychellen', name_ca: '', code: 'SC', vat_prefix: null },
    { name: 'Sierra Leone', name_el: '', name_es: 'Sierra Leona', name_it: 'Sierra Leone', name_fr: 'Sierra Leone', name_de: 'Sierra Leone', name_ca: '', code: 'SL', vat_prefix: null },
    { name: 'Singapore', name_el: '', name_es: 'Singapur', name_it: 'Singapore', name_fr: 'Singapour', name_de: 'Singapur', name_ca: '', code: 'SG', vat_prefix: null },
    { name: 'Slovakia', name_el: '', name_es: 'Eslovaquia', name_it: 'Slovacchia', name_fr: 'Slovaquie', name_de: 'Slowakei', name_ca: '', code: 'SK', vat_prefix: 'SK' },
    { name: 'Slovenia', name_el: '', name_es: 'Eslovenia', name_it: 'Slovenia', name_fr: 'Slovénie', name_de: 'Slowenien', name_ca: '', code: 'SI', vat_prefix: 'SI' },
    { name: 'Solomon Islands', name_el: '', name_es: 'Islas Salomón', name_it: 'Isole Salomone', name_fr: 'Salomon (Îles)', name_de: 'Salomonen', name_ca: '', code: 'SB', vat_prefix: null },
    { name: 'Somalia', name_el: '', name_es: 'Somalia', name_it: 'Somalia', name_fr: 'Somalie', name_de: 'Somalia', name_ca: '', code: 'SO', vat_prefix: null },
    { name: 'South Africa', name_el: '', name_es: 'Sudáfrica', name_it: 'Sudafrica', name_fr: 'Afrique du Sud', name_de: 'Süd-Afrika', name_ca: '', code: 'ZA', vat_prefix: null },
    { name: 'South Georgia and the South Sandwich Islands', name_el: '', name_es: 'Georgia del Sur y las Islas Sandwich del Sur', name_it: 'Georgia del Sud e Isole Sandwich del Sud', name_fr: 'Géorgie du Sud et les îles Sandwich du Sud', name_de: 'Südgeorgien und die Südlichen Sandwichinseln', name_ca: '', code: 'GS', vat_prefix: null },
    { name: 'Spain', name_el: '', name_es: 'España', name_it: 'Spagna', name_fr: 'Espagne', name_de: 'Spanien', name_ca: '', code: 'ES', vat_prefix: 'ES' },
    { name: 'Sri Lanka', name_el: '', name_es: 'Sri Lanka', name_it: 'Sri Lanka', name_fr: 'Sri Lanka', name_de: 'Sri Lanka', name_ca: '', code: 'LK', vat_prefix: null },
    { name: 'Sudan', name_el: '', name_es: 'Sudán', name_it: 'Sudan', name_fr: 'Soudan', name_de: 'Sudan', name_ca: '', code: 'SD', vat_prefix: null },
    { name: 'Suriname', name_el: '', name_es: 'Surinam', name_it: 'Suriname', name_fr: 'Suriname', name_de: 'Surinam', name_ca: '', code: 'SR', vat_prefix: null },
    { name: 'Svalbard and Jan Mayen', name_el: '', name_es: 'Svalbard y Jan Mayen', name_it: 'Svalbard e Jan Mayen', name_fr: 'Svalbard et Jan Mayen', name_de: 'Svalbard und Jan Mayen', name_ca: '', code: 'SJ', vat_prefix: null },
    { name: 'Swaziland', name_el: '', name_es: 'Suazilandia', name_it: 'Swaziland', name_fr: 'Swaziland', name_de: 'Swasiland', name_ca: '', code: 'SZ', vat_prefix: null },
    { name: 'Sweden', name_el: '', name_es: 'Suecia', name_it: 'Svezia', name_fr: 'Suède', name_de: 'Schweden', name_ca: '', code: 'SE', vat_prefix: 'SE' },
    { name: 'Switzerland', name_el: '', name_es: 'Suiza', name_it: 'Svizzera', name_fr: 'Suisse', name_de: 'Schweiz', name_ca: '', code: 'CH', vat_prefix: null },
    { name: 'Syrian Arab Republic', name_el: '', name_es: 'República Árabe Siria', name_it: 'Repubblica Araba Siriana', name_fr: 'République arabe syrienne', name_de: 'Arabische Republik Syrien', name_ca: '', code: 'SY', vat_prefix: null },
    { name: 'Taiwan, Province of China', name_el: '', name_es: 'Taiwán, Provincia de China', name_it: 'Taiwan, Provincia di Cina', name_fr: 'Taïwan, Province de Chine', name_de: 'Taiwan, Provinz China', name_ca: '', code: 'TW', vat_prefix: null },
    { name: 'Tajikistan', name_el: '', name_es: 'Tayikistán', name_it: 'Tagikistan', name_fr: 'Tadjikistan', name_de: 'Tadschikistan', name_ca: '', code: 'TJ', vat_prefix: null },
    { name: 'Tanzania, United Republic of', name_el: '', name_es: 'Tanzania, República Unida de', name_it: 'Tanzania, Repubblica Unita di', name_fr: 'Tanzanie, République Unie de', name_de: 'Tansania, Vereinigte Republik', name_ca: '', code: 'TZ', vat_prefix: null },
    { name: 'Thailand', name_el: '', name_es: 'Tailandia', name_it: 'Tailandia', name_fr: 'Thaïlande, République-Unie de', name_de: 'Thailand', name_ca: '', code: 'TH', vat_prefix: null },
    { name: 'Timor-Leste', name_el: '', name_es: 'Timor Oriental', name_it: 'Timor Est', name_fr: 'Timor-Leste', name_de: 'Timor-Leste', name_ca: '', code: 'TL', vat_prefix: null },
    { name: 'Togo', name_el: '', name_es: 'Togo', name_it: 'Togo', name_fr: 'Togo', name_de: 'Togo', name_ca: '', code: 'TG', vat_prefix: null },
    { name: 'Tokelau', name_el: '', name_es: 'Tokelau', name_it: 'Tokelau', name_fr: 'Tokelau', name_de: 'Tokelau', name_ca: '', code: 'TK', vat_prefix: null },
    { name: 'Tonga', name_el: '', name_es: 'Tonga', name_it: 'Tonga', name_fr: 'Tonga', name_de: 'Tonga', name_ca: '', code: 'TO', vat_prefix: null },
    { name: 'Trinidad and Tobago', name_el: '', name_es: 'Trinidad y Tobago', name_it: 'Trinidad e Tobago', name_fr: 'Trinité et Tobago', name_de: 'Trinidad und Tobago', name_ca: '', code: 'TT', vat_prefix: null },
    { name: 'Tunisia', name_el: '', name_es: 'Túnez', name_it: 'Tunisia', name_fr: 'Tunisie', name_de: 'Tunesien', name_ca: '', code: 'TN', vat_prefix: null },
    { name: 'Turkey', name_el: '', name_es: 'Turquía', name_it: 'Turchia', name_fr: 'Turquie', name_de: 'Türkei', name_ca: '', code: 'TR', vat_prefix: null },
    { name: 'Turkmenistan', name_el: '', name_es: 'Turkmenistán', name_it: 'Turkmenistan', name_fr: 'Turkménistan', name_de: 'Turkmenistan', name_ca: '', code: 'TM', vat_prefix: null },
    { name: 'Turks and Caicos Islands', name_el: '', name_es: 'Islas Turcas y Caicos', name_it: 'Isole Turks e Caicos', name_fr: 'Turks et Caicos (Îles)', name_de: 'Turks- und Caicosinseln', name_ca: '', code: 'TC', vat_prefix: null },
    { name: 'Tuvalu', name_el: '', name_es: 'Tuvalu', name_it: 'Tuvalu', name_fr: 'Tuvalu', name_de: 'Tuvalu', name_ca: '', code: 'TV', vat_prefix: null },
    { name: 'Uganda', name_el: '', name_es: 'Uganda', name_it: 'Uganda', name_fr: 'Ouganda', name_de: 'Uganda', name_ca: '', code: 'UG', vat_prefix: null },
    { name: 'Ukraine', name_el: '', name_es: 'Ucrania', name_it: 'Ucraina', name_fr: 'Ukraine', name_de: 'Ukraine', name_ca: '', code: 'UA', vat_prefix: null },
    { name: 'United Arab Emirates', name_el: '', name_es: 'Emiratos Árabes Unidos', name_it: 'Emirati Arabi Uniti', name_fr: 'Emirats Arabes Unis', name_de: 'Vereinigte Arabische Emirate', name_ca: '', code: 'AE', vat_prefix: null },
    { name: 'United Kingdom', name_el: '', name_es: 'Reino Unido', name_it: 'Regno Unito', name_fr: 'Royaume-Uni', name_de: 'Vereinigtes Königreich', name_ca: '', code: 'GB', vat_prefix: null },
    { name: 'United States', name_el: '', name_es: 'Estados Unidos', name_it: 'Stati Uniti d\'America', name_fr: 'Etats-Unis d\'Amérique', name_de: 'Vereinigte Staaten', name_ca: '', code: 'US', vat_prefix: null },
    { name: 'United States Minor Outlying Islands', name_el: '', name_es: 'Islas Periféricas Menores de los Estados Unidos', name_it: 'Isole Minori degli Stati Uniti', name_fr: 'Îles mineures éloignées des États-Unis', name_de: 'VereinigteStaaten Minor Outlying Islands', name_ca: '', code: 'UM', vat_prefix: null },
    { name: 'Uruguay', name_el: '', name_es: 'Uruguay', name_it: 'Uruguay', name_fr: 'Uruguay', name_de: 'Uruguay', name_ca: '', code: 'UY', vat_prefix: null },
    { name: 'Uzbekistan', name_el: '', name_es: 'Uzbekistán', name_it: 'Uzbekistan', name_fr: 'Ouzbékistan', name_de: 'Usbekistan', name_ca: '', code: 'UZ', vat_prefix: null },
    { name: 'Vanuatu', name_el: '', name_es: 'Vanuatu', name_it: 'Vanuatu', name_fr: 'Vanuatu', name_de: 'Vanuatu', name_ca: '', code: 'VU', vat_prefix: null },
    { name: 'Venezuela', name_el: '', name_es: 'Venezuela', name_it: 'Venezuela', name_fr: 'Venezuela', name_de: 'Venezuela', name_ca: '', code: 'VE', vat_prefix: null },
    { name: 'Viet Nam', name_el: '', name_es: 'Vietnam', name_it: 'Viet Nam', name_fr: 'Viet Nam', name_de: 'Vietnam', name_ca: '', code: 'VN', vat_prefix: null },
    { name: 'Virgin Islands, U.S.', name_el: '', name_es: 'Islas Vírgenes, EE.UU.', name_it: 'Isole Vergini, Stati Uniti', name_fr: 'Îles Vierges, U.S.', name_de: 'Jungferninseln, U.S.', name_ca: '', code: 'VI', vat_prefix: null },
    { name: 'Wallis and Futuna', name_el: '', name_es: 'Wallis y Futuna', name_it: 'Wallis e Futuna', name_fr: 'Wallis et Futuna', name_de: 'Wallis und Futuna', name_ca: '', code: 'WF', vat_prefix: null },
    { name: 'Western Sahara', name_el: '', name_es: 'Sahara Occidental', name_it: 'Sahara occidentale', name_fr: 'Sahara occidental', name_de: 'Westsahara', name_ca: '', code: 'EH', vat_prefix: null },
    { name: 'Yemen', name_el: '', name_es: 'Yemen', name_it: 'Yemen', name_fr: 'Yémen', name_de: 'Jemen', name_ca: '', code: 'YE', vat_prefix: null },
    { name: 'Zambia', name_el: '', name_es: 'Zambia', name_it: 'Zambia', name_fr: 'Zambie', name_de: 'Sambia', name_ca: '', code: 'ZM', vat_prefix: null },
    { name: 'Zimbabwe', name_el: '', name_es: 'Zimbabue', name_it: 'Zimbabwe', name_fr: 'Zimbabwe', name_de: 'Simbabwe', name_ca: '', code: 'ZW', vat_prefix: null }

];
