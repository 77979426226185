import { UtilsService } from '../../../services/utils/utils.service';
import { Router } from '@angular/router';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Customer } from '../../../models';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  public isLoading = false;
  public searchTerm: string = null;

  public isMobile = false;
  private isMobileSubscription: Subscription;

  public hideScrollToTop = false;


  constructor(
    private router: Router,
    private pubSub: NgxPubSubService,
    private utilsService: UtilsService
  ) {
    this.isMobile = this.utilsService.isMobile;
  }

  async ngOnInit(): Promise<void> {

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    this.hideScrollToTop = this.router.url.indexOf('/properties') !== -1 ? true : false;

  }



  ngAfterViewInit(): void {

  }



  ngOnDestroy(): void {
    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();
  }



}
