import { PipesModule } from './../../pipes/pipes.module';
import { ComponentsModule } from '../components.module';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { MailSubscriptionFormComponent } from '../mail-subscription-form/mail-subscription-form.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxSpinnerModule,
        RouterModule.forChild([]),
        ComponentsModule,
        PipesModule,
        NgSelectModule,
        BsDropdownModule.forRoot(),
        TranslateModule
    ],
    declarations: [
        MainLayoutComponent,
        NavbarComponent,
        FooterComponent,
        MailSubscriptionFormComponent,
    ],
    exports: [
        MainLayoutComponent,

        NavbarComponent,
        FooterComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class LayoutModule { }
