import { multilingual_text_fields } from '../../environments/_multilingual';
import { ServiceBedroom } from './ServiceBedroom';
import { ServiceOccupancy } from './ServiceOccupancy';
import { Address } from './Address';
import { Amenity } from './Amenity';
import { Account } from './Account';
import { Review } from './Review';
import { PropertyVideo } from './PropertyVideo';
import { PropertyImage } from './PropertyImage';
import { PropertyExtraCost } from './PropertyExtraCost';
import { ServiceBed } from './ServiceBed';
import { MultilingualFieldValues } from './MultilingualEntity';

export class Property {

    id?: number; // not used

    service_id?: string;
    title: string;
    slug: string;

    lease_agreement: string;
    lease_agreement_url: string;
    lease_agreement_use_default_template?: boolean;

    image_url: string;

    type?: 'accommodation';
    category?: 'private_room' | 'entire_place';
    subcategory?: 'house' | 'apartment' | 'loft' | 'villa' | 'room' | 'studio' | 'penthouse' | 'maisonette';

    description?: string;
    short_description?: string;

    provider_id?: string;

    booking_type?: 'direct' | 'request';
    cancelation_policy?: 'simple' | 'flexible' | 'moderate' | 'strict';

    persons: number;
    adults: number;
    children?: number;
    infants?: number;

    min_persons?: number;
    min_days?: number;
    max_days?: number;

    occupancy?: ServiceOccupancy[];

    energy_efficiency_rating?: string;
    smoking_allowed: boolean;
    pets_allowed: boolean;
    bank_account_iban?: string;
    bank_account_beneficiary?: string;
    bank_account_bank_name?: string;
    bank_account_swift?: string;
    property_license_number_type?: string;
    property_license_number_value?: string;
    tax_type?: 'no_tax' | 'tax_percent' | 'tax_amount_per_person_per_day';
    tax_percent?: number;
    tax_amount?: number;

    checkin_time?: string | Date;
    checkout_time?: string | Date;

    checkin_instructions?: string;

    wifi_ssid?: string;
    wifi_password?: string;

    discount?: {
        type?: 'percent' | 'fixed',
        percent?: number;
        fixed?: number;
    };

    damage_deposit?: boolean;
    damage_deposit_description?: string;
    damage_deposit_price?: number;


    terms_of_service?: string;
    special_policies?: string;
    house_rules?: string;


    pricing_method?: 'per_day';
    price?: number; // price per day
    price_per_month: number; // price per month

    // extras
    images?: PropertyImage[];
    removedFromFavorites?: boolean;
    timerRef?: NodeJS.Timer;


    // General info
    bedrooms?: number; // displayed in list-item
    bedroom_types?: ServiceBedroom[];
    beds?: number;
    bed_types?: ServiceBed[];
    garage_spots?: number;
    area_size?: number; // displayed in list-item (square meters)
    bathrooms?: number; // displayed in list-item
    floor?: number;
    floors_number?: number;
    parking_slots?: number;

    amenities?: Amenity[];
    base_price?: number; // for services search
    discounted_price?: number;

    address: Address;
    provider?: Account; // agent


    video?: PropertyVideo;

    availability_slots?: PropertyAvailabilitySlot[];

    nearby_places?: PropertyNearbyPlace[];

    reviews?: Review[];
    reviews_rating?: any; // for services search
    // similiar properties different request - same model

    extra_costs?: PropertyExtraCost[];


    multilingual?: ServiceMultilingualData;

    is_nomad365_certified?: boolean;



    constructor(props?: Property) {

        this.service_id = props?.service_id || null;
        this.title = props?.title || null;
        this.slug = props?.slug || null;
        this.image_url = props?.image_url || null;
        this.type = props?.type || 'accommodation';
        this.category = props?.category || null;
        this.subcategory = props?.subcategory || null;
        this.description = props?.description || null;
        this.short_description = props?.short_description || null;
        this.provider_id = props?.provider_id || null;
        this.booking_type = props?.booking_type || null;
        this.cancelation_policy = props?.cancelation_policy || null;
        this.persons = props?.persons || null;
        this.adults = props?.adults || null;
        this.children = props?.children || null;
        this.infants = props?.infants || null;
        this.min_persons = props?.min_persons || 1;
        this.min_days = props?.min_days || null;
        this.max_days = props?.max_days || null;
        this.occupancy = props?.occupancy || [];
        this.energy_efficiency_rating = props?.energy_efficiency_rating || null;
        this.smoking_allowed = props?.smoking_allowed || null;
        this.pets_allowed = props?.pets_allowed || null;
        this.bank_account_iban = props?.bank_account_iban || null;
        this.bank_account_beneficiary = props?.bank_account_beneficiary || null;
        this.bank_account_bank_name = props?.bank_account_bank_name || null;
        this.bank_account_swift = props?.bank_account_swift || null;
        this.property_license_number_type = props?.property_license_number_type || null;
        this.property_license_number_value = props?.property_license_number_value || null;
        this.tax_type = props?.tax_type || null;
        this.tax_percent = props?.tax_percent || 0;
        this.tax_amount = props?.tax_amount || 0;
        this.checkin_time = props?.checkin_time || null;
        this.checkout_time = props?.checkout_time || null;
        this.checkin_instructions = props?.checkin_instructions || null;
        this.wifi_ssid = props?.wifi_ssid || null;
        this.wifi_password = props?.wifi_password || null;
        this.discount = props?.discount || null;
        this.damage_deposit = props?.damage_deposit || null;
        this.damage_deposit_description = props?.damage_deposit_description || null;
        this.damage_deposit_price = props?.damage_deposit_price || null;
        this.terms_of_service = props?.terms_of_service || null;
        this.special_policies = props?.special_policies || null;
        this.house_rules = props?.house_rules || null;
        this.pricing_method = props?.pricing_method || null;
        this.price = props?.price || null;
        this.price_per_month = props?.price_per_month || null;
        this.images = props?.images || [];
        this.bedrooms = props?.bedrooms || null;
        this.beds = props?.beds || 0;
        this.bed_types = props?.bed_types || [];
        this.garage_spots = props?.garage_spots || null;
        this.area_size = props?.area_size || null;
        this.bathrooms = props?.bathrooms || null;
        this.floor = props?.floor || null;
        this.floors_number = props?.floors_number || null;
        this.parking_slots = props?.parking_slots || null;
        this.amenities = props?.amenities || [];
        this.base_price = props?.base_price || null;
        this.discounted_price = props?.discounted_price || null;
        this.address = props?.address || null;
        this.provider = props?.provider || null;
        this.video = props?.video || null;
        this.availability_slots = props?.availability_slots || [];
        this.nearby_places = props?.nearby_places || [];
        this.reviews = props?.reviews || [];
        this.reviews_rating = props?.reviews_rating || null;
        this.extra_costs = props?.extra_costs || [];
        this.lease_agreement = props?.lease_agreement || null;
        this.lease_agreement_url = props?.lease_agreement_url || null;
        this.lease_agreement_use_default_template = props?.lease_agreement_use_default_template || false;
        this.removedFromFavorites = false;
        this.timerRef = null;
        this.is_nomad365_certified = props?.is_nomad365_certified ? true : false;

    }

}

export class PropertyAvailabilitySlot {

    id?: number;
    service_id?: string;
    provider_id?: string;

    service_type?: 'accommodation';
    service_pricing_method?: 'per_day';


    date?: string;

    available: boolean;


    constructor() { }
}

export class PropertyNearbyPlace {
    id?: number;
    service_id?: string;
    type?: 'blabla' | 'blul';
    distance?: number;
    distance_unit: 'km' | 'm';
}



export class ServiceMultilingualData {

    service_type?: 'accommodation' | 'rent_car' | 'transfer' | 'experience' | 'food';

    fields?: { [key: string]: MultilingualFieldValues };

    constructor(props?: ServiceMultilingualData) {
        this.service_type = props?.service_type || null;
        this.fields = props?.fields || this.getDefaultFields();
    }


    getDefaultFields?(): { [key: string]: MultilingualFieldValues } {

        let propertyFields = {};

        switch (this.service_type) {
            case 'accommodation':

                for (const propertyField of multilingual_text_fields.services.accommodation.basic)
                    propertyFields[propertyField] = new MultilingualFieldValues();

                break;

            case 'rent_car':

                for (const propertyField of multilingual_text_fields.services.rent_car.basic)
                    propertyFields[propertyField] = new MultilingualFieldValues();

                break;

            case 'transfer':

                for (const propertyField of multilingual_text_fields.services.transfer.basic)
                    propertyFields[propertyField] = new MultilingualFieldValues();

                break;

            case 'experience':

                for (const propertyField of multilingual_text_fields.services.experience.basic)
                    propertyFields[propertyField] = new MultilingualFieldValues();

                break;

            case 'food':

                for (const propertyField of multilingual_text_fields.services.food.basic)
                    propertyFields[propertyField] = new MultilingualFieldValues();

                break;

            default:

                propertyFields = {};
                break;
        }



        return propertyFields;

    }

}
