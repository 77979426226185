import { SeoService } from '../../services/seo/seo-service.service';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationPageComponent implements OnInit {
  private verificationToken: string;
  private verificationEmailAddress: string;

  public isLoading = false;
  public accountVerified = false;
  public queryParams: Params;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private seo: SeoService,
    private ga: GoogleAnalyticsService,
    public translate: TranslateService,
    public utilsService: UtilsService
  ) {
    this.verificationToken = null;
    this.verificationEmailAddress = null;
  }

  ngOnInit(): void {

    this.seo.updatePageMetadata({ page: 'email_verification' });

    this.queryParams = this.route.snapshot.queryParams;
    if (this.queryParams
      && this.queryParams.token
      && this.queryParams.token !== ''
      && this.queryParams.address
      && this.queryParams.address !== '') {

      this.verificationToken = this.queryParams.token;
      this.verificationEmailAddress = this.queryParams.address;

      this.verifyEmail();

    }
    else
      this.router.navigate(['/']);

  }

  async verifyEmail(): Promise<void> {

    this.isLoading = true;

    try {
      const response: any = await this.http.post(`${environment.params.host}/api/stay/user-verification`, {
        token: this.verificationToken,
        address: this.verificationEmailAddress
      }).toPromise();

      this.isLoading = false;
      this.accountVerified = true;

    } catch (error) {

      this.isLoading = false;
      this.router.navigate(['/']);

    }
  }

}
