import { Customer, Booking } from '../../models';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class BookingsService {

    constructor(
        private http: HttpClient,
        private storageService: StorageService
    ) {

    }

    async getBookings(): Promise<Booking[]> {

        try {

            const customer: Customer = this.storageService.getItem('customer');

            if (!customer?.customer_id)
                return Promise.resolve([]);

            const bookings = await this.http.get<Booking[]>(`${environment.params.host}/api/stay/bookings`).toPromise();

            return Promise.resolve(bookings);


        } catch (error) {
            return Promise.reject(error);
        }

    }

    async getBooking(booking_id: string): Promise<Booking> {

        try {

            const customer: Customer = this.storageService.getItem('customer');

            if (!customer?.customer_id)
                return Promise.resolve(null);

            const booking = await this.http.get<Booking>(`${environment.params.host}/api/stay/bookings/${booking_id}`).toPromise();

            return Promise.resolve(booking);


        } catch (error) {
            return Promise.reject(error);
        }

    }

}

