import { environment } from '../../../../../../environments/environment';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../../models';
import { UtilsService } from '../../../../../services/utils/utils.service';
import { ReviewsService } from '../../../../../services/reviews/reviews.service';
import { SeoService } from '../../../../../services/seo/seo-service.service';

@Component({
  selector: 'app-customer-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit, OnDestroy {

  @Input() customer: Customer;

  public isMobile = false;
  private isMobileSubscription: Subscription;


  public reviews: string[];
  public reviewsLoading: boolean;

  public reviewMaxRating = environment.params.reviewMaxRating;

  constructor(
    public utilsService: UtilsService,
    private seo: SeoService,
    private reviewService: ReviewsService
  ) {

    this.isMobile = this.utilsService.isMobile;

  }

  async ngOnInit(): Promise<void> {

    this.seo.updatePageMetadata({ page: `reviews` });

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });


    this.reviews = [];
    this.reviewsLoading = true;


    try {

      // this.reviews = this.reviewService.getCustomerReviews();

      if (!environment.production)
        console.log(this.reviews);

    } catch (error) {

      if (!environment.production)
        console.log(error);

    }

    this.reviewsLoading = false;
  }

  ngOnDestroy(): void {
    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();
  }

}
