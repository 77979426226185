export class ServiceBed {
    id?: number;
    bedroom_id?: number;
    service_id?: string;
    value:
        'double_beds' |
        'single_beds' |
        'sofa_beds' |
        'couches' |
        'child_beds' |
        'queen_size_beds' |
        'king_size_beds';
    quantity: number;

    constructor(props?: ServiceBed) {
        this.id = props?.id || null;
        this.bedroom_id = props?.bedroom_id || null;
        this.service_id = props?.service_id || null;
        this.value = props?.value || null;
        this.quantity = props?.quantity || 0;
    }

}
