import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../../services/utils/utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  public isAtPage: boolean;

  public environment = environment;

  public isMobile = false;
  private isMobileSubscription: Subscription;

  private environmentFetchedSubscription: Subscription;

  public socialProfiles: {
    facebook: string,
    instagram: string,
    linkedin: string
  } = { facebook: environment.params.social.facebook, instagram: environment.params.social.instagram, linkedin: environment.params.social.linkedin };

  public today = new Date();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public utilsService: UtilsService,
    private pubSub: NgxPubSubService,
    private translate: TranslateService
  ) {
    this.isMobile = this.utilsService.isMobile;
  }



  ngOnInit(): void {
    if (this.router.url === '/')
      this.isAtPage = true;

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    this.environmentFetchedSubscription = this.pubSub.subscribe('environmentFetched', () => {

    });

  }



  ngOnDestroy(): void {

    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

    if (this.environmentFetchedSubscription)
      this.environmentFetchedSubscription.unsubscribe();

  }

}
