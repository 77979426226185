import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../services/storage/storage.service';
import { SeoService } from '../../services/seo/seo-service.service';
import { UtilsService } from '../../services/utils/utils.service';
import { FirebaseService } from '../../services/firebase/firebase.service';
import { FullStoryService } from '../../services/fullstory/fullstory.service';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';


import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

import { Customer } from '../../models'; // see me ton john ti kai pws sto mellon
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {


  public credentials = { email: null, password: null };
  private returnUrl: string = null;
  public isConnecting = false;

  public isSubmittingPasswordResetRequest = false;
  public passwordResetModalRef: BsModalRef;
  public emailToResetPassword: string = null;

  public isMobile = false;
  private isMobileSubscription: Subscription;

  public role: 'tenant' | 'host' = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private pubSub: NgxPubSubService,
    public translate: TranslateService,
    private modalService: BsModalService,
    private seo: SeoService,
    private ga: GoogleAnalyticsService,
    private storageService: StorageService,
    public utilsService: UtilsService,
    private firebaseService: FirebaseService,
    private fullstoryService: FullStoryService
  ) {

    this.isMobile = this.utilsService.isMobile;

  }



  ngOnInit(): void {

    this.seo.updatePageMetadata({ page: 'login' });

    this.isMobileSubscription = this.utilsService.windowIsMobile().subscribe((isMobile: boolean) => { this.isMobile = isMobile; });

    this.ga.sendEvent('viewed_login');

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    if (this.route.snapshot?.queryParams?.role && (['tenant', 'host']).includes(this.route.snapshot.queryParams.role)) {
      this.role = this.route.snapshot.queryParams.role;
    }

    const customer: Customer = this.storageService.getItem('customer'); // customer?

    if (customer && customer.id)
      this.router.navigate(['/']);



    // const queryParams = this.route.snapshot.queryParams;
    // this.role = (queryParams?.role && queryParams.role === 'tenant') ? 'tenant' : null;

  }




  ngOnDestroy(): void {

    if (this.isMobileSubscription)
      this.isMobileSubscription.unsubscribe();

  }



  async submitForm(): Promise<void> {

    this.isConnecting = true;

    try {

      const customer = await this.http.post<Customer>(`${environment.params.host}/api/stay/auth/login`, this.credentials).toPromise();

      this.storageService.setItem('customer', customer);

      this.ga.setUserID(customer.customer_id);
      this.fullstoryService.identifyUser();
      this.firebaseService.updateFcmToken(customer);
      this.pubSub.publishEvent('customerDataUpdated', customer); // for navbar

      this.isConnecting = false;

      if (this.returnUrl !== '/')
        this.router.navigate([this.returnUrl]);
      else
        this.router.navigate(['/']);


    } catch (error) {

      if (!environment.production)
        console.log(error);

      this.isConnecting = false;
    }
  }


  goToTenantLoginForm(): void {

    this.role = 'tenant';

  }


  goToRegistration() {
    this.router.navigate(['/registration/guests']);
  }



  openPasswordResetModal(template: TemplateRef<any>) {

    if (this.utilsService.isBrowser) {

      this.emailToResetPassword = null;
      this.passwordResetModalRef = this.modalService.show(template, { backdrop: 'static' });

    }

  }



  async resetPasswordRequest(): Promise<void> {
    this.isSubmittingPasswordResetRequest = true;
    try {
      const response: any = await this.http.post(`${environment.params.host}/api/stay/password-reset-request`, { email: this.emailToResetPassword }).toPromise();

      this.isSubmittingPasswordResetRequest = false;

      if (response?.message === 'password_reset_email_sent')
        this.utilsService.swal.fire(
          (await this.translate.get('PAGES.LOGIN.ALERTS.PASSWORD_RESET_EMAIL_SENT.TITLE').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          (await this.translate.get('PAGES.LOGIN.ALERTS.PASSWORD_RESET_EMAIL_SENT.HTML', { emailToResetPassword: this.emailToResetPassword }).toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title),
          'info')
          .then(() => {
            this.passwordResetModalRef.hide();
          });

    } catch (error) {

      if (!environment.production)
        console.log(error);


      this.isSubmittingPasswordResetRequest = false;
    }
  }



}
