export class Address {
    public country: string;
    public state?: string;
    public city: string;
    public street: string;
    public street_number?: string; // not used atm
    public street_formatted?: string;
    public postal_code: string;

    public longitude?: number;
    public latitude?: number;

    public is_city?: boolean;
    public utc_offset?: number;
    public url?: string;
    public google_place_id?: string;
    public types?: string;


    constructor(props?: Address) {
        this.country = props?.country || null;
        this.state = props?.state || null;
        this.city = props?.city || null;
        this.street = props?.street || null;
        this.street_number = props?.street_number || null;
        this.street_formatted = props?.street_formatted || null;
        this.postal_code = props?.postal_code || null;
        this.is_city = props?.is_city ? true : false;
        this.utc_offset = props?.utc_offset || null;
        this.url = props?.url || null;
        this.google_place_id = props?.google_place_id || null;
        this.types = props?.types || null;
        this.longitude = props?.longitude || null;
        this.latitude = props?.latitude || null;
    }
}
