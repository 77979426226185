import { Injectable } from '@angular/core';
import { Customer, Property } from '../../models';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../utils/utils.service';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private utilsService: UtilsService,
    private ga: GoogleAnalyticsService,
    private translate: TranslateService
  ) { }


  async getFavorites(): Promise<Property[]> {

    try {

      const customer: Customer = this.storageService.getItem('customer');

      if (!customer?.customer_id)
        return Promise.resolve([]);

      const favorites = await this.http.get<Property[]>(`${environment.params.host}/api/stay/customer-favorite-services`).toPromise();

      return Promise.resolve(favorites);
    } catch (error) {
      return Promise.reject(error);
    }
  }


  async addFavorite(property: Property): Promise<void> {
    try {
      const customer: Customer = this.storageService.getItem('customer');

      if (!customer?.customer_id)
        return Promise.resolve();

      const addFavorite = await this.http.post(`${environment.params.host}/api/stay/customer-favorite-services`, { service_id: property.service_id }).toPromise();

      this.ga.sendEvent('add_to_wishlist', { // GA ecommerce event
        item_id: property.service_id,
        item_name: property.title,
        currency: 'EUR',
        item_category: property.subcategory,
        item_category2: property.category,
        price: property.price_per_month,
        base_price: property.base_price,
        quantity: 1
      });

      const msg = (await this.translate.get('GENERIC.ALERTS.ADDED_TO_FAVORITES_PREFIX').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title) + property.title + (await this.translate.get('GENERIC.ALERTS.ADDED_TO_FAVORITES_SUFFIX').toPromise()).toString().replace(/Tourmie Stay/, this.utilsService.customDomainSettings.app_title);

      this.utilsService.showToast(msg, null, 'success', 3000);
      this.utilsService.refreshCustomerData();

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }


  async removeFavorite(service_id: string): Promise<void> {
    try {
      const customer: Customer = this.storageService.getItem('customer');

      if (!customer?.customer_id)
        return Promise.resolve();

      const delFavorite = await this.http.delete(`${environment.params.host}/api/stay/customer-favorite-services/?service_id=${service_id}`).toPromise();

      this.utilsService.refreshCustomerData();

      return Promise.resolve();
    }
    catch (error) {
      return Promise.reject(error);
    }
  }
}
