export const booking_types = [
    { label: 'Direct', label_el: 'Άμεση', label_es: 'Directo', label_it: 'Diretto', label_fr: 'Direct', label_de: 'Direkt', label_ca: 'Directe', value: 'direct' },
    { label: 'On request', label_el: 'Κατόπιν Αιτήματος', label_es: 'A petición', label_it: 'Su richiesta', label_fr: 'Sur demande', label_de: 'Auf Anfrage', label_ca: 'Sota demanda', value: 'request' }
];



export const booking_statuses = [
    { label: 'Rejected', label_el: 'Απορρίφθηκε', value: 'rejected' },
    { label: 'Confirmed', label_el: 'Επιβεβαιώθηκε', value: 'confirmed' },
    { label: 'Pending', label_el: 'Εκκρεμεί', value: 'pending' },
    { label: 'Cancelled', label_el: 'Ακυρώθηκε', value: 'canceled' },
    { label: 'Archived', label_el: 'Αρχειοθετήθηκε', value: 'archived' },
];



export const booking_payment_statuses = [
    { label: 'Paid', label_el: 'Πληρώθηκε', value: 'paid' },
    { label: 'Cancelled', label_el: 'Ακυρώθηκε', value: 'canceled' },
    { label: 'Pending', label_el: 'Σε εκρεμμότητα', value: 'pending' },
    { label: 'Requires payment method', label_el: 'Απαιτεί μέθοδο πληρωμής', value: 'requires_payment_method' },
    { label: 'Requires confirmation', label_el: 'Απαιτεί επιβεβαίωση', value: 'requires_confirmation' },
    { label: 'Requires action', label_el: 'Απαιτεί ενέργεια', value: 'requires_action' },
    { label: 'Processing', label_el: 'Σε επεξεργασία', value: 'processing' },
    { label: 'Requires capture', label_el: 'Απαιτεί λήψη', value: 'requires_capture' },
    { label: 'Succeeded', label_el: 'Ολοκληρωμένη', value: 'succeeded' },
];
