<div class="wrapper">

    <!-- Header -->
    <app-navbar></app-navbar>

    <!-- Content -->
    <!-- class="content-wrapper" -->
    <!-- Navbar height = 60px; -->
    <router-outlet></router-outlet>

    <!-- Footer -->
    <div class="layout-footer-fixed">
        <app-footer></app-footer>
    </div>

    <!-- Move to top button -->
    <!-- <app-move-to-top *ngIf="!isMobile && !hideScrollToTop"></app-move-to-top> -->

</div>